<template>
  <div class="info-wrapper">
    <blue-text-info>
      {{title}}
    </blue-text-info>
    <span class="content"> {{ content }}</span>
  </div>
</template>

<script>
import { HeadingThree, BlueTextInfo } from '@/components/atoms/Texts'

export default {
  components: { HeadingThree, BlueTextInfo },
  name: 'TitleDescriptionPair',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
info-wrapper{
  display: flex;
  flex-direction: column;

  .content {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
  }
}

</style>
