<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('responsibilityTerm')}}</span>
      </div>
      <div class="form__info">
        <span class="mb-4">{{$t('readTheFollowingTerm')}}</span>
        <text-warning :text="`considerAllFieldObligatory`" />
        <paragraph class="paragraph">
          {{$t('responsibilityTermParagraph1')}}
          <br />
          <br />
          {{$t('responsibilityTermParagraph2')}}
          <br />
          <br />
          {{$t('responsibilityTermParagraph3')}}
        </paragraph>
        <label :class="{warning: checkErrorIfContains('read_and_agreed')}">
          <input type="checkbox" v-model="read_and_agreed" @change="resetErrors" />
          {{$t('readAndAgree')}}
        </label>
      </div>

      <main class="form__main mt-7">
        <input-default
          :class="[{warning: checkErrorIfContains('coordinator_name')}, 'mb-7']"
          label="resonsablePersonForFillingIn"
          id="coordinator_name"
          placeholder="resonsablePersonForFillingInPlaceholder"
          type="text"
          max=100
          v-model="form.coordinator_name"
          @input="resetErrors"
        />
        <input-default
          :class="[{warning: checkErrorIfContains('coordinator_role')}, 'mb-7']"
          label="positionFunctionAtFair"
          id="coordinator_role"
          placeholder="positionFunctionAtFairPlaceholder"
          type="text"
          max=100
          v-model="form.coordinator_role"
          @input="resetErrors"
        />
      </main>

      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('sendForm')}}</button-default>
      </div>
    </form>
    <modal-send-form
      title="affiliationRequestModalSendFormTitle"
      :onClick="saveForm"
      @closeModal="() => toggleModal('modal')"
      :modal="modal"
    >
      <div class="text">
        <p info="teste1">{{$t('affiliationRequestModalSendFormText')}}</p>
      </div>
    </modal-send-form>
    <modal-default
      title="requestSentSuccessfullyTitle"
      @closeModal="() => toggleModal('modal2')"
      :modal="modal2"
    >
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/email-send.png" alt="Solicitação enviada a MostraTec" />
        </div>
        <div class="text">
          <p slot="text">{{$t('requestSentSuccessfully')}}</p>
        </div>
      </div>
    </modal-default>
    <modal-loading :modal="loading" />
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  Paragraph
} from '@/components/atoms'
import api from '@/services/api'
import {
  ModalSendForm,
  ModalLoading,
  ModalDefault
} from '@/components/organisms/Modals'

export default {
  name: 'FormSchoolDataAddress',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    Paragraph,
    ModalSendForm,
    ModalLoading,
    ModalDefault
  },
  data() {
    return {
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      read_and_agreed: false,
      form: {
        coordinator_name: '',
        coordinator_role: ''
      },
      modal: false,
      modal2: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.responsible_name) {
      this.read_and_agreed = this.fairAffiliation.terms
      this.form.coordinator_name = this.fairAffiliation.responsible_name
      this.form.coordinator_role = this.fairAffiliation.office
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    async checkForm() {
      this.resetErrors()
      this.textWarning = 'warningAllFieldsAreObligatory'
      if (!this.read_and_agreed) {
        this.errors.push('read_and_agreed')
        this.textWarning = 'makeSureToReadAndAgree'
      }
      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
        }
      }
      if (!this.errors.length) {
        this.toggleModal('modal')
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    async saveForm() {
      this.toggleModal('modal')
      this.toggleModal('loading')
      try {
        const formData = this.$store.getters.fairAffiliation
        const newData = {
          ...formData,
          newZipCode: formData.zipcode,
          terms: this.read_and_agreed,
          responsible_name: this.form.coordinator_name,
          office: this.form.coordinator_role,
          last_edition_init: this.formatDates(formData.last_edition_init),
          last_edition_end: this.formatDates(formData.last_edition_end),
          next_edition_init: this.formatDates(formData.next_edition_init),
          next_edition_end: this.formatDates(formData.next_edition_end)
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem(
          'fairAffiliatonData',
          JSON.stringify(newData)
        )
        delete newData.currentScreen
        try {
          await this.$http.post('/fair', newData)
          this.toggleModal('loading')
          this.toggleModal('modal2')
          this.$store.commit('AFILLIATION_REQUEST', {})
          window.localStorage.removeItem('fairAffiliatonData')
          setTimeout(() => {
            this.$router.push('/panel-fair-vacancies')
          }, 4000)
        } catch (error) {
          if (error.response) {
            this.errors.push(1)
            if (error.response.data.message == 'Contact email already in use') {
              this.textWarning = 'emailsAlreadyInUseChangeThemOnForm3'
            }
            this.errors.push(1)
          }
        }
      } catch (error) {
      } finally {
        if (this.loading) this.toggleModal('loading')
      }
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    formatDates(date) {
      const formatedDate = new Date(date)
      return formatedDate.setHours(formatedDate.getHours() + 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-width: 610px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }

    label {
      align-items: center;
      display: flex;
      color: $primaryDark;
      font-size: 16px;
      font-weight: 600;

      input {
        zoom: 1.5;
        margin-right: 5px;
      }
    }

    .warning {
      color: $attention !important;
    }
  }

  .paragraph {
    font-size: 16px;
    line-height: 22px;
    color: $almostBlack;
  }

.paragraphRed {
  color: red;
  margin: 25px 0px 15px 0px;
  text-align: left;
}

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
.text {
  color: #18516f;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  max-width: 90%;
  text-align: left;
  margin-top: 25px;
}
</style>
