import { render, staticRenderFns } from "./ProjectsKindergartenInstitution.vue?vue&type=template&id=ac99c170&scoped=true&"
import script from "./ProjectsKindergartenInstitution.vue?vue&type=script&lang=js&"
export * from "./ProjectsKindergartenInstitution.vue?vue&type=script&lang=js&"
import style0 from "./ProjectsKindergartenInstitution.vue?vue&type=style&index=0&id=ac99c170&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac99c170",
  null
  
)

export default component.exports