export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const INCREMENT = 'INCREMENT'
export const CHANGE_EDITION = 'CHANGE_EDITION'
export const EDITION_VACANCIES_SET = 'EDITION_VACANCIES_SET'
export const AFILLIATION_REQUEST = 'AFILLIATION_REQUEST'
export const PROJECT_SELECTION_REQUEST = 'PROJECT_SELECTION_REQUEST'
export const AFILLIATION_LIST_FILTER = 'AFILLIATION_LIST_FILTER'
export const PROJECT_LIST_FILTER = 'PROJECT_LIST_FILTER'
export const EDITION_LIST_FILTER = 'EDITION_LIST_FILTER'
export const AFILLIATION_SEND_MAILS = 'AFILLIATION_SEND_MAILS'
export const FAIR_AFFILIATION_ANALYSIS_ACCEPT = 'FAIR_AFFILIATION_ANALYSIS_ACCEPT'
export const MOSTRATEC_INFO = 'MOSTRATEC_INFO'
export const PROJECTS_STATUS_CHANGE = 'PROJECTS_STATUS_CHANGE'
export const PROJECT_SEND_MAILS = 'PROJECT_SEND_MAILS'
export const SELECTED_PROJECT = 'SELECTED_PROJECT'
export const PROJECT_LIST = 'PROJECT_LIST'
export const UPDATE_PROJECT_LIST = 'UPDATE_PROJECT_LIST'
