<template>
  <div id="application">
    <v-app class="v-app" v-if="logged">
      <router-view/>
    </v-app>
  </div>
</template>

<script>
import '@/styles/global.css'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      logged: 'user/logged'
    })
  }
}
</script>

<style lang="scss">
.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active {
  animation: slide 0.3s;
}

.v-leave-active {
  animation: slide 0.3s reverse;
}

@keyframes slide {
  from {
    transform: translate3d(100px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
</style>
