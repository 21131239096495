<template>
  <div class="info-wrapper">
    <div v-if="isArea" class="area_wrapper">
      <BadgeDefault
        :name ='list.area'
      />
    </div>
    <div v-if="!isArea" class="subarea_wrapper">
      <div class="subarea_wrapper">
        <div
          v-for="(item, index) in list.sub_areas"
          :key="index"
        >
          <BadgeDefault
            color='lightBlue'
            :name='item'
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BadgeDefault } from '@/components/atoms'

export default {
  name: 'BlueBadge',
  components: { BadgeDefault },
  props: {
    isArea: {
      type: Boolean,
      required: true
    },
    list: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.area {
   display: flex;
    flex-direction: row;
    padding: 5px 19px;
    width: fit-content;
    height: 28px;
    background: $primary;
    border-radius: 49px;
    opacity: 0.8;
    margin-top: 10px;
}

.subarea {
    display: flex;
    flex-direction: row;
    padding: 5px 19px;
    width: fit-content;
    height: 28px;
    background: #319CB4;
    border-radius: 49px;
    opacity: 0.8;
    margin-top: 10px;

    &_name{
      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
    }
  }

.subarea_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

.area_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

info-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
