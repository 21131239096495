<template>
  <img
    class="img"
    @mouseenter="changeImg()"
    @mouseleave="leaveImg()"
    :src="require(`@/assets/${dataUrl}`)"
    :alt="alt">
</template>

<script>
export default {
  name: 'IconDownload',
  data() {
    return {
      oldUrl: '',
      dataUrl: ''
    }
  },
  props: {
    url: {
      type: String,
      required: true
    },
    alt: String
  },
  mounted() {
    this.dataUrl = this.url
  },
  methods: {
    changeImg() {
      this.oldUrl = this.url
      this.dataUrl = 'download.svg'
    },
    leaveImg() {
      this.dataUrl = this.oldUrl
    }

  }
}
</script>

<style lang="scss" scoped>
.img {
  fill:red
}
</style>
