<template>
  <div class="summary-label-text-pair">
    <span class="summary-label mb-1">{{ label }}</span>
    <summary-text
      color="#303030"
      weight="400"
      :style="{textAlign}"
    >
      <slot/>
      {{ cIsSlotEmpty ? '----' : '' }}
    </summary-text>
  </div>
</template>

<script>
import SummaryText from '@/components/atoms/Texts/SummaryText.vue'

export default {
  name: 'SummaryLabelTextPair',
  components: {
    SummaryText
  },
  props: {
    label: String,
    textAlign: {
      type: String,
      default: 'unset'
    }
  },
  computed: {
    cIsSlotEmpty() {
      return !this.$slots.default[0].text.trim().length
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-label-text-pair {
  vertical-align: top;
  & > * {
    display: block;
  }
}
.summary-label {
  width: 100%;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #1B778B;
}
</style>
