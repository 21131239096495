<template>
<div @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <div class="header_modal">
         <p class="header"> {{$t('tc_titulo')}}</p>
      </div>
      <div class="modal__term">
        <div class="modal__term__content">
          <article class="scroll">
            <!-- pre class="text" -->
              <span class="bold_text" style="color:red;">{{$t('tc_aviso')}}</span>
              <p class="lgpd">{{$t('tc_p1')}}</p>
              <p class="lgpd">{{$t('tc_p2')}}</p>
              <p class="lgpd">{{$t('tc_p3')}}</p>
              <p class="lgpd">{{$t('tc_p3')}} <a target="_new" :href="$t('linkLei13709')">{{$t('linkLei13709')}}</a></p>
              <p class="lgpd">{{$t('tc_p5')}}</p>
              <p class="lgpd">{{$t('tc_p6')}}</p>
              <p class="lgpd">{{$t('tc_p7')}}</p>
              <p class="lgpd">{{$t('tc_p8')}}</p>
              <p class="lgpd">{{$t('tc_p9')}}</p>
              <p class="lgpd">{{$t('tc_p10')}}</p>
              <div class="input_checkbox">
                <input type="checkbox" class="checkbox_icon" v-model="checked">{{$t('readDocument')}}
              </div>
          <!-- /pre -->
          </article>

          <button-default
            class="button__cancel"
            :onClick="onGoBack"
          >
            {{$t('back')}}
          </button-default>
          <button-default :onClick="onAccept" class="button">{{$t('yesContinue')}}</button-default>
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="declareWarning"
      text= DeclareAsRead
      :onClick="toggleWarning"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { HeaderModal } from '@/components/organisms/Headers'
import { ButtonDefault, FeedbackWarning } from '@/components/atoms'

export default {
  name: 'ModalLgpdTerm',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    modal: {
      type: Boolean,
      required: true
    },
    onDeny: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      checked: false,
      declareWarning: false
    }
  },
  methods: {
    toggleWarning() {
      this.declareWarning = !this.declareWarning
    },
    async onAccept() {
      if (this.checked) {
        this.$emit('lgpdChecked')
        this.onClick()
      } else this.declareWarning = true
    },
    onGoBack() {
      this.onDeny && this.onDeny()
      this.onClick()
    }
  },
  components: { HeaderModal, ButtonDefault, FeedbackWarning }
}
</script>

<style lang="scss" scoped>
.scroll{
  overflow-x: auto;
  overflow-y:scroll;
  height: 390px;
  width: 100%;
  margin-right: 38px;
  padding-bottom: 20px;
}

/* personalizar a barra em geral, aqui estou definindo 10px de largura para a barra vertical
e 10px de altura para a barra horizontal */
::-webkit-scrollbar {
 width: 4px;
 height: 138px;
}
p.lgpd{
  width: 600px;
}

/* aqui é a alça da barra, que demonstra a altura que você está na página
estou colocando uma cor azul clara nela*/
::-webkit-scrollbar-thumb {
  position: static;
  width: 4px;
  height: 138px;
  left: 595px;
  top: 0px;
  background: #CDCBCB;
  border-radius: 100px;
}

.header{
  margin-left: 8px;
  height: 140px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #0E5671;
}
.modal__term {
  width: 582px;
  display:flex;
  flex-direction: row;
  .img{
    margin-top: 20px;
  }
  .modal__term__content{
    margin-left: 6px;
    height: 30%;
  }

  .text {
    position:relative;
    margin-left:-54px;
    width: 80%;
    height: 60%;
    flex-direction: column;
    font-family: Mulish;
    font-size: 16px;
    color: #0E5671;

  }
  .bold_text{
    font-weight: 650;
  }
  .xred{
     color: red;
  }
  .button{
    margin-top: 30px;
    max-width: 300px;
    width: 244px;
    height: 50px;
    align-self: right;
    &__cancel {
      align-self: left;
      margin-right: 82px;
      color: $primary;
      background-color:#FFFFFF;
    }
    // &__off {
    //   color: $primary;
    //   background-color:#FFFFFF;
    // }
  }
}
.input_checkbox{
  width: 565px;
  margin-top: 10px;
  height: 22px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #1B778B;
}
.bottom_buttons{
  margin-bottom: 500px;
  margin-right: 50px;
  width: 700px;
  height: 120px;
  align-self:center;
}
.checkbox_icon{
  margin-right: 10px;
  width: 22.5px;
  height: 22.5px;
  border: 5px solid #1B778B;
  border-radius: 1.5px;
}

.modal__outer {
  @include d-flex-center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-height: 221px;
    padding: 25px;
    width: 676px;
    height: 643px;
  }
}
</style>
