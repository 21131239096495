<template>
  <aside class="wrapperInvolveds">
    <div class="revision_box"><h3 class="revision_title">{{$t('peopleInvolved')}}</h3></div>
    <ProjectsAdvisorsData
      :projectInfo="projectInfo"
    />
  </aside>
</template>

<script>
import ProjectsAdvisorsData from '@/components/templates/ProjectsAdvisorsData'

export default {
  name: 'ProjectsInvolveds',
  components: {
    ProjectsAdvisorsData
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    allFalse() {
      return this.form.infos[0].active !== true && this.form.infos[1].active !== true
    }
  }
}

</script>

<style lang="scss" scoped>
.info__icon{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.accordion_size{
  width: 95%;
  justify-content: space-between;
}

.icon_padding{
  padding-left: 20px;
}

.firstQuestion{
  width: 380px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
    margin-left: 48.5px;
    margin-right: 60px;
  align-items: center;
  color: #303030;
  &__result{
    margin-left: 50.5px;
  }
}

.secondQuestion{
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  width: 360px;
  height: 40px;
}

.info{
  position: absolute;
  top: 100px;
  left: 20px;
  display: flex;
}

.finish_button {
    position: static;
    margin-top: 156%;
    margin-left: 70%;
    width: 255px;
    height: 50px;
    background: #1B778B;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

.revision_box{
  position: absolute;
  width: 68%;
  height: 57px;
  background: #FFFFFF;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  color: #0E5671;
  padding-bottom: 10px;
  border-radius: 10px 10px 0px 0px;
}

.revision_title{
  position: absolute;
  left: 22px;
  top: 34%;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.wrapperInvolveds{
  height:100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #FDFDFD;
}

</style>
