<template>
  <aside class="inner__wrapper">
    <div class="header">
      <h3>{{$t('projectData')}}</h3>
    </div>
    <main class="form__main mt-4 main_align">
      <div class="topic_title">
        <h4>{{$t('summaryForTheResearcher')}}</h4>
      </div>
      <div class="grey_line"></div>
      <div class="basic__data">
        <div class="basic__data__left">
          <div class="basic__data__row">
            <h4>{{$t('researchProjectStartDate')}}</h4>
            <span>{{ experimentationBeggining | formatDate}}</span>
          </div>
          <div class="basic__data__row">
            <h4>{{$t('isYourProjectContinuity')}}</h4>
            <span>{{form.will_continue || form.infos.length ? 'Sim' : 'Não'}}</span>
          </div>
        </div>
        <div class="basic__data__right">
          <div class="basic__data__row">
            <h4>{{$t('plannedEndDate')}}</h4>
            <span>{{ researchEnd | formatDate }}</span>
          </div>
          <div class="basic__data__row">
            <h4>Data planejada para o início da experimentação/coleta de dados</h4>
            <span>{{ researchInit | formatDate }}</span>
          </div>
        </div>
      </div>
      <div class="grey_line"></div>
      <div class="extra-data">
        <div class="extra-data_item">
          <h4>Você conduzirá sua pesquisa em laboratório, instituto ou indústria regulamentada?</h4>
          <span> {{form.institution ? 'Sim' : 'Não'}} </span>
        </div>
      </div>
      <div class="accordions">
        <input-checkbox-accordion
          v-if="form.institution"
          class="accordion_size"
          label="Instituição de pesquisa ou indústria regulamentada"
          :person_name="form.institutionInfos.institution_name"
          v-model="form.institutionInfos.active"
        />
        <div class="content" v-if="form.institutionInfos.active">
          <div class="contact_info">
            <div class="data__left">
              <div class="data__row">
                <h4>{{$t('name')}}</h4>
                <span>{{form.institutionInfos.institution_name}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('email')}}</h4>
                <span>{{form.institutionInfos.email}}</span>
              </div>
            </div>
            <div class="data__right">
              <div class="data__row">
                <h4>Telefone (fixo ou celular)</h4>
                <span>{{form.institutionInfos.phone}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('locationsWhereTheResearchWillBeConducted')}}</h4>
                <div>
                  <label
                    class="badge_lightblue"
                    v-for="(item, index) in form.institutionInfos.research_locations.locations"
                    :key="index"
                  >
                    <span class="badge_lightblue_name">{{format(item, 'researchLocalOptions')}}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="itens_info">
            <h4>{{$t('itemsThatApplyToYourResearchTrials')}}</h4>
            <div>
              <label
                class="badge_darkblue"
                v-for="(item, index) in form.institutionInfos.experiment_items.items"
                :key="index"
              >
                <span class="badge_darkblue_name">{{format(item, 'researchItensOption')}}</span>
              </label>
            </div>
          </div>
          <div class="content_title">{{$t('address')}}</div>
          <div class="separator"></div>
          <div class="address_info">
            <div class="data__left">
              <div class="data__row">
                <h4>{{$t('country')}}</h4>
                <span>{{form.institutionInfos.country}}</span>
              </div>
              <div class="data__row">
                <h4>UF</h4>
                <span>{{form.institutionInfos.state}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('address')}}</h4>
                <span>{{form.institutionInfos.street ? form.institutionInfos.street : 'Não informado'}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('neighborhood')}}</h4>
                <span>{{form.institutionInfos.district ? form.institutionInfos.district : 'Não informado'}}</span>
              </div>
            </div>
            <div class="data__right">
              <div class="data__row">
                <h4>CEP</h4>
                <span>{{form.institutionInfos.zipcode ? form.institutionInfos.zipcode : 'Não informado'}}</span>
              </div>
              <div class="data__row">
                <h4>Cidade</h4>
                <span>{{form.institutionInfos.city}}</span>
              </div>
              <div class="data__row">
                <h4>Número</h4>
                <span>{{form.institutionInfos.house_number ? form.institutionInfos.house_number : 'Não informado'}}</span>
              </div>
              <div class="data__row">
                <h4>Complemento</h4>
                <span>{{form.institutionInfos.complement ? form.institutionInfos.complement : 'Não informado'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accordions" v-if="form.infos.length">
        <div class="form__item mb-5" v-for="(info, index) in form.infos" :key="index">
          <input-checkbox-accordion
            class="accordion_size"
            :label="`Novo estudante ${ index + 1 }`"
            :person_name="form.infos[index].full_name"
            v-model="form.infos[index].active"
          />
          <div class="content" v-if="form.infos[index].active">
            <p v-if="index == 0" class="form__item--info mb-6">
              Estudante responsável pelo pré-cadastro e pela inscrição do projeto.
            </p>
            <div class="contact_info">
              <div v-if="index === 0" class="data__left">
                <div class="data__row">
                  <h4>Nome completo</h4>
                  <span>{{form.infos[index].full_name}}</span>
                </div>
                <div class="data__row">
                  <h4>Sexo</h4>
                  <span>{{form.infos[index].sex === 'male' ? $t('maleSingle') : $t('femaleSingle')}}</span>
                </div>
                <div class="data__row" v-if="form.infos[index].responsible_name">
                  <h4>Nome do responsável</h4>
                  <span>{{form.infos[index].responsible_name}}</span>
                </div>
                <div class="data__row">
                  <h4>Telefone (fixo ou celular)</h4>
                  <span>{{form.infos[index].phone}}</span>
                </div>
              </div>
              <div v-if="index === 0" class="data__right">
                <div class="data__row">
                  <h4>{{$t('cpf')}}</h4>
                  <span>{{form.infos[index].cpf}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('birthDate')}}</h4>
                  <span>{{form.infos[index].birth_date | formatDate}}</span>
                </div>
                <div class="data__row" v-if="form.infos[index].responsible_kinship">
                  <h4>Parentesco do(a) Responsável</h4>
                  <span>{{form.infos[index].responsible_kinship}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('email')}}</h4>
                  <span>{{form.infos[index].email}}</span>
                </div>
              </div>
              <div v-else-if="index > 0" class="data__left">
                <div class="data__row">
                  <h4>Nome Completo</h4>
                  <span>{{form.infos[index].full_name}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('sex')}}</h4>
                  <span>{{form.infos[index].sex == 'male' ? $t('maleSingle') : $t('femaleSingle')}}</span>
                </div>
                <div v-if="form.infos[index].responsible_name" class="data__row">
                  <h4>{{$t('responsibleName')}}</h4>
                  <span>{{form.infos[index].responsible_name}}</span>
                </div>
                <div class="data__row">
                  <h4>Telefone (fixo ou celular)</h4>
                  <span>{{form.infos[index].phone}}</span>
                </div>
              </div>
              <div v-if="index > 0" class="data__right">
                <div class="data__row">
                  <h4>{{$t('cpf')}}</h4>
                  <span>{{form.infos[index].cpf}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('birthDate')}}</h4>
                  <span>{{form.infos[index].birth_date | formatDate}}</span>
                </div>
                <div v-if="form.infos[index].responsible_kinship" class="data__row">
                  <h4>Parentesco do(a) Responsável</h4>
                  <span>
                    {{form.infos[index].responsible_kinship.charAt(0).toUpperCase() +
                    form.infos[index].responsible_kinship.slice(1)}}
                  </span>
                </div>
                <div class="data__row">
                  <h4>{{$t('email')}}</h4>
                  <span>{{form.infos[index].email}}</span>
                </div>
              </div>
            </div>
            <div class="content_title">{{$t('address')}}</div>
            <div class="separator"></div>
            <div class="address_info">
              <div class="data__left">
                <div class="data__row">
                  <h4>{{$t('country')}}</h4>
                  <span>{{form.infos[index].country}}</span>
                </div>
                <div class="data__row">
                  <h4>UF</h4>
                  <span>{{form.infos[index].state}}</span>
                </div>
                <div class="data__row" v-if="form.infos[index].country === 'Brazil'">
                  <h4>{{$t('address')}}</h4>
                  <span>{{form.infos[index].street}}</span>
                </div>
                <div class="data__row" v-if="form.infos[index].country === 'Brazil'">
                  <h4>{{$t('neighborhood')}}</h4>
                  <span>{{form.infos[index].district}}</span>
                </div>
              </div>
              <div class="data__right">
                <div class="data__row" v-if="form.infos[index].country === 'Brazil'">
                  <h4>CEP</h4>
                  <span>{{form.infos[index].zipcode}}</span>
                </div>
                <div class="data__row">
                  <h4>Cidade</h4>
                  <span>{{form.infos[index].city}}</span>
                </div>
                <div class="data__row" v-if="form.infos[index].country === 'Brazil'">
                  <h4>Número</h4>
                  <span>{{form.infos[index].house_number}}</span>
                </div>
                <div class="data__row">
                  <h4>Complemento</h4>
                  <span>{{form.infos[index].complement ? form.infos[index].complement : 'Não informado'}}</span>
                </div>
              </div>
            </div>
            <div class="content_title">{{$t('registrationData')}}</div>
            <div class="separator"></div>
            <div class="inscription_info">
              <div class="data__left">
                <div class="data__row">
                  <h4>Série</h4>
                  <span>{{format(form.infos[index].educational_level, 'serieOptions')}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('doYouHaveScholarship')}}</h4>
                  <span>{{form.infos[index].schoolarship_holder ? 'Sim' : 'Não'}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('haveYouParticipatedAnyScienceFair')}}</h4>
                  <span>{{form.infos[index].fair_science ? 'Sim' : 'Não'}}</span>
                </div>
                <div class="data__row">
                  <h4>Necessita de alguma atendimento especializado?</h4>
                  <span>
                    {{arrayStringify(form.infos[index].special_service.special_services)}}
                  </span>
                </div>
              </div>
              <div class="data__right">
                <div class="data__row">
                  <h4>Nível de conhecimento da lingua inglesa</h4>
                  <span>{{format(form.infos[index].english_level, 'englishOptions')}}</span>
                </div>
                <div class="data__row" v-if="form.infos[index].schoolarship_holder">
                  <h4>{{$t('whichProgram')}}</h4>
                  <span>{{form.infos[index].which_program.charAt(0) +
                    form.infos[index].which_program.slice(1)}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('doYouHaveAnyKindOfDisability')}}</h4>
                  <span>{{arrayStringify(form.infos[index].deficiency.deficiencies)}}</span>
                </div>
                <div class="data__row">
                  <h4>Opta por qual tipo de camisa?</h4>
                  <span>{{format(form.infos[index].t_shirt, 't_shirtOptions')}}</span>
                </div>
                <div class="data__row" v-if="form.infos[index].t_shirt !== 'no'">
                  <h4>{{$t('whatSizeShirt')}}</h4>
                  <span>{{format(form.infos[index].t_shirt_size, 't_shirt_sizeOptions')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grey_line"></div>
      <div class="extra-data">
        <div class="extra-data_item">
          <h4>A continuidade do projeto terá outra área ou subárea de pesquisa?</h4>
          <span> {{form.continuity_sub_areas.length ? 'Sim' : 'Não'}} </span>
        </div>
        <div class="extra-data_item" v-if="form.continuity_area">
          <h4>Nova {{$t('researchAreaSingle')}}</h4>
          <div>
            <label class="badge_lightblue">
              <span class="badge_lightblue_name">
                {{form.continuity_area.charAt(0) + form.continuity_area.slice(1)}}
                </span>
            </label>
          </div>
        </div>
        <div class="extra-data_item" v-if="form.continuity_sub_areas.length">
          <h4>Nova(s) subárea(s) de pesquisa</h4>
          <div>
            <label
              class="badge_lightblue"
              v-for="(item, index) in form.continuity_sub_areas"
              :key="index"
            >
              <span class="badge_lightblue_name">
                {{item.charAt(0).toUpperCase() + item.slice(1)}}
              </span>
            </label>
          </div>
        </div>
      </div>
    </main>
  </aside>
</template>

<script>
import { InputCheckboxAccordion } from '@/components/atoms'
import { areaOptions, areas } from '@/utils/areas'

export default {
  name: 'ProjectsResearcher',
  components: { InputCheckboxAccordion },
  data() {
    return {
      form: {
        will_continue: null,
        research_init: '',
        research_end: '',
        experimentation_beggining: '',
        continuity_area: '',
        continuity_sub_areas: [],
        institution: null,
        infos: [],
        institutionInfos: {}
      },
      t_shirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' },
        { text: this.$t('notApplicable'), value: 'no' },
        { text: this.$t('maleTShirt'), value: 'masculina' },
        { text: 'Baby look', value: 'baby' },
        { text: this.$t('childreanTShirt'), value: 'infantil' }
      ],
      t_shirt_sizeOptions: [
        { text: 'P', value: 'p' },
        { text: 'M', value: 'm' },
        { text: 'G', value: 'g' },
        { text: 'GG', value: 'gg' }
      ],
      englishOptions: [
        { text: 'Nenhum', value: 'nenhum' },
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intermediario' },
        { text: this.$t('advanced'), value: 'avancado' },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      serieOptions: [
        { text: '1ª série', value: '1' },
        { text: '2ª série', value: '2' },
        { text: '3ª série', value: '3' },
        { text: '4ª série', value: '4' },
        { text: '5ª série', value: '5' },
        { text: '6ª série', value: '6' },
        { text: '7ª série', value: '7' },
        { text: '8ª série', value: '8' },
        { text: '9ª série', value: '9' },
        { text: this.$t('internship'), value: 'estagio' }
      ],
      researchLocalOptions: [
        { text: this.$t('field'), value: 'field' },
        { text: this.$t('atHome'), value: 'inHome' },
        { text: this.$t('school'), value: 'school' },
        { text: this.$t('other'), value: 'other' }
      ],
      researchItensOption: [
        { text: 'Seres Humanos', value: 'humanBeings' },
        { text: 'Animais Vertebrados', value: 'vertebrateAnimals' },
        {
          text: 'Agentes Biológicos Potencialmente Perigosos',
          value: 'potentiallyHazardousBiologicalAgents'
        },
        {
          text: 'Substâncias Químicas, Atividades ou Equipamentos Perigosos',
          value: 'hazardousChemicalSubstancesActivitiesEquipment'
        },
        { text: 'Substâncias Controladas por Lei', value: 'substancesControlledLaw' },
        { text: 'Nenhuma', value: 'none' }
      ]
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  computed: {
    researchEnd() {
      return this.form.research_end
    },
    researchInit() {
      return this.form.research_init
    },
    experimentationBeggining() {
      return this.form.experimentation_beggining
    }
  },
  mounted() {
    if (this.projectInfo) this.getInfos()
  },
  methods: {
    arrayStringify(myArray) {
      if (!myArray.length || myArray.includes('nao')) return 'Não'
      const arrayStringified = []
      myArray.forEach((item) => {
        arrayStringified.push(item.charAt(0).toUpperCase() + item.slice(1))
      })
      return arrayStringified.join(', ')
    },
    format(item, object) {
      const { text } = this[object].find((option) => option.value === item)
      return text
    },
    async getInfos() {
      const { newArea: continuityArea } = this.projectInfo.continuity_sub_areas.form
      this.projectInfo.students.forEach((student) => {
        if (student.is_continuity) this.form.infos.push(student)
      })
      if (continuityArea) {
        const whichArea = this.projectInfo.continuity_sub_areas.form.continuity_area
        const whichSubAreas = this.projectInfo.continuity_sub_areas.form.continuity_sub_areas
        this.form.continuity_area = areaOptions.find((item) => item.value === whichArea)
        this.form.continuity_area = this.form.continuity_area.text
        if (whichSubAreas.length) {
          areas[whichArea].forEach((item) => {
            if (whichSubAreas.includes(item.value)) this.form.continuity_sub_areas.push(item.text)
          })
        }
      }
      this.form = {
        ...this.form,
        quantityStudents: this.projectInfo.students.length,
        will_continue: this.projectInfo.will_continue,
        research_init: this.projectInfo.research_init,
        research_end: this.projectInfo.research_end,
        experimentation_beggining: this.projectInfo.experimentation_beginning,
        institution: !!this.projectInfo.research_institution.institution_name
      }
      if (this.form.institution) {
        this.form.institutionInfos = this.projectInfo.research_institution
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inner__wrapper {
  height:100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #FDFDFD;
  font-family: Mulish, sans-serif;
  margin-bottom: 30px;
  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;
    margin-bottom: 25px;
    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }
  .topic_title{
    font-size: 16px;
    line-height: 20px;
    color: #303030;
    margin: 0px 20px;
    width: 90%;
    margin-left: 65px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .basic__data{
    margin-left: 6.3%;
    display: flex;
    &__row {
      width: 355px;
      margin-bottom: 35px;
      font-size: 16px;
      color: #686868;
      h4 {
        font-weight: 600;
        color: $almostBlack;
        margin-bottom: 10px;
      }
    }
    &__left{
      width: 40%;
      float: left;
    }
    &__right{
      margin-left: 35px;
      float: right;
    }
  }
  .extra-data{
    &_item{
      margin-top: 35px;
      margin-left: 66px;
      margin-bottom: 10px;
      color: #686868;
      h4{
        color: #303030;
        margin-bottom: 10px;
      }
      div{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
  }
  .accordions{
    margin-left: 62px;
    .accordion_size{
      width: 90%;
      padding-left: 24px;
    }
    .content{
      width: 90%;
      height: fit-content;
      border-top: 1px solid #CDCBCB;
      padding-top: 13px;
      padding-left: 12px;
      padding-bottom: 20px;
      margin-top: -33px;
      background-color: #FDFDFD;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      .content_title{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: $primary;
        margin-left: 35px;
      }
      .itens_info{
        h4 {
          font-weight: 600;
          color: $almostBlack;
          margin-bottom: 10px;
        }
        div{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px;
        }
        margin-left: 35px;
        margin-bottom: 35px;
      }
      .contact_info, .address_info, .inscription_info {
        display: flex;
        .data{
          display: flex;
          &__row {
            width: 355px;
            margin-bottom: 35px;
            font-size: 16px;
            color: #686868;
            h4 {
              font-weight: 600;
              color: $almostBlack;
              margin-bottom: 10px;
            }
          }
          &__left{
            width: 45%;
            margin-left: 35px;
            float: left;
          }
          &__right{
            margin-left: 35px;
            float: right;
          }
        }
      }
    }
  }
  .warning {
    color: $attention;
    border-bottom-color: $attention;
  }
  .separator {
    background-color: $primary;
    height: 0.5px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 98%;
  }
  .grey_line{
    width: 90%;
    margin-left: 65px;
    margin-bottom: 35px;
    border-bottom:  0.5px solid #cdcdcd;
  }
  .badge{
    &_lightblue{
      padding: 5px 19px;
      background: #319CB4;
      opacity: 0.8;
      border-radius: 49px;
      &_name{
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
      }
    }
    &_darkblue{
      padding: 5px 19px;
      background-color: #1B778B;
      opacity: 0.8;
      border-radius: 49px;
      &_name{
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
      }
    }
  }
}
</style>
