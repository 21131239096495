<template>
  <div class="container-app">
    <div class="wrapper">
      <welcome-project />
    </div>
  </div>
</template>

<script>
import { WelcomeProject } from '@/components/organisms'

export default {
  name: 'InitialProject',
  components: { WelcomeProject }
}
</script>

<style lang="scss" scoped>
.container-app {
  background-color: #F1F5F6;
  width: 100vw;
  display: flex;
}
</style>
