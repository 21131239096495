const tShirtTypes = {
  camiseta: 'shirt',
  polo: 'poloShirt',
  masculina: 'maleTShirt',
  baby: 'Baby Look',
  infantil: 'childreanTShirt',
  no: 'notApplicable',
  tsShirt01: 'Child - size 8',
  tsShirt02: 'Child - size 10',
  tsShirt03: 'Child - size 14',
  tsShirt04: 'Adult - shirt size S',
  tsShirt05: 'Adult - shirt size M',
  tsShirt06: 'Adult - shirt size L',
  tsShirt07: 'Adult - shirt size XL',
  tsShirt08: 'Adult - shirt size XXL'
}

export default tShirtTypes
