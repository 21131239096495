<template>
  <img class="img" :src="require(`@/assets/${url}`)" :alt="alt">
</template>

<script>
export default {
  name: 'IconDefault',
  props: {
    url: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.img {
}
</style>
