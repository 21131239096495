<template>
  <aside class="wrapperInvolveds">
    <div class="revision_box">
      <h3 class="revision_title">{{$t('peopleInvolved')}}</h3>
    </div>
    <main class="form__main mt-4 main_align">
      <span v-if="!form.infos.length">Não há outros envolvidos neste projeto.</span>
        <div class="form__item mb-5" v-for="(info, index) in form.infos" :key="index">
          <div v-if="index === 0" class="grey_separator">
            {{$t('othersInvolved')}}
          </div>
          <input-checkbox-accordion
            :class="[
              { warning: checkErrorIfContains('active' + index) },
              'mb-7',
              'accordion_size'
            ]"
            :label="isAdvisor(index)"
            :person_name="form.infos[index].full_name"
            v-model="form.infos[index].active"
            @change="resetErrors"
          />
          <div v-if="form.infos[index].active" class="rectangle">
            <div class="content">
                <div class="align_table">
                    <div class="left_side mb-9">
                      <div>
                      </div>
                      <div class="mb-9">
                        <p class="form_info">{{$t('fullName')}}</p>
                        <span class="form_info__subtitle">{{form.infos[index].full_name}}</span>
                      </div>
                      <div class="mb-9">
                        <p class="form_info">{{$t('sex')}}</p>
                        <span class="form_info__subtitle">{{form.infos[index].sex == 'female' ? $t('femaleSingle') : $t('maleSingle')}}</span>
                      </div>
                      <div class="mb-9">
                        <p class="form_info">{{$t('email')}}</p>
                        <span class="form_info__subtitle">{{form.infos[index].email}}</span>
                      </div>
                    </div>
                    <div :class="[{right_side__init_after: index > 1},'right_side', 'mb-9']">
                      <div class="mb-9">
                        <p class="form_info">{{$t('cpf')}}</p>
                        <span class="form_info__subtitle">{{form.infos[index].cpf}}</span>
                      </div>
                    <div class="mb-9">
                      <p class="form_info">{{$t('landlineOrCellphone')}}</p>
                      <span class="form_info__subtitle">{{form.infos[index].phone}}</span>
                    </div>
                    <div class="mb-9">
                      <p class="form_info">{{$t('designation')}}</p>
                      <span class="form_info__subtitle">{{form.infos[index].type}}</span>
                    </div>
                  </div>
                </div>
            </div>
            <div class="middle_content">
              <div class="left_side mb-9">
                <div class="mb-9">
                  <p class="form_info">{{$t('country')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].country)}}
                  </span>
                </div>
                <div class="mb-9">
                  <p class="form_info">{{$t('uf')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].state)}}
                  </span>
                </div>
                <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
                  <p class="form_info">{{$t('address')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].street)}}
                  </span>
                </div>
                <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
                  <p class="form_info">{{$t('neighborhood')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].district)}}
                  </span>
                </div>
              </div>
              <div :class="[{right_side__middle_after: index > 1},'right_side__middle', 'mb-9']">
                <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
                  <p class="form_info">{{$t('zipCode')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].zipcode)}}
                  </span>
                </div>
                <div class="mb-9">
                  <p class="form_info">{{$t('city')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].city)}}
                  </span>
                </div>
                <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
                  <p class="form_info">{{$t('number')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].house_number)}}
                  </span>
                </div>
                <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
                  <p class="form_info">{{$t('complement')}}</p>
                  <span class="form_info__subtitle">
                    {{coalesceInformation(form.infos[index].complement)}}
                  </span>
                </div>
              </div>
              <div class="separator__align">
                <p class="separator">{{$t('address')}}</p>
              </div>
            </div>
            <div v-if="index <= 1" class="end_content">
                  <div class="left_side mb-9">
                    <div
                      v-if="index <= 1 && form.infos[index].formation"
                      class="mb-9"
                    >
                      <p class="form_info">{{$t('graduation')}}</p>
                      <span class="form_info__subtitle">{{form.infos[index].formation}}</span>
                    </div>
                    <div
                      v-if="index <= 1 && (form.infos[index].which_program || form.infos[index].other_program)"
                      class="mb-8"
                    >
                      <p class="form_info">{{$t('whichProgram')}}</p>
                      <span class="form_info__subtitle">
                        {{$t(form.infos[index].other_program || scholarshipHolderPrograms[form.infos[index].which_program])}}
                      </span>
                    </div>
                    <div class="mb-9">
                      <p class="form_info">{{$t('doYouHaveAnyKindOfDisability')}}</p>
                      <span class="form_info__subtitle">{{deficiencyListPicker(form.infos[index].deficiency)}}</span>
                    </div>
                    <div class="mb-9">
                      <p class="form_info">{{$t('typeOfShirt')}}</p>
                      <span class="form_info__subtitle">{{$t(tShirtTypes[form.infos[index].t_shirt])}}</span>
                    </div>
                    <div v-if="form.infos[index].t_shirt != 'no' " class="mb-9">
                      <p class="form_info">{{$t('whatSizeShirt')}}</p>
                      <span class="form_info__subtitle">{{$t(tShirtSizes[form.infos[index].t_shirt_size])}}</span>
                    </div>
                </div>
                <div class="right_side__end mb-8">
                  <div v-if="index <=1" class="mb-9">
                    <p class="form_info">{{$t('doYouHaveScholarship')}}</p>
                    <span class="form_info__subtitle">{{form.infos[index].scholarship_holder ? 'Sim' : 'Não'}}</span>
                  </div>
                  <div class="mb-9">
                    <p class="form_info">{{$t('haveYouParticipatedAnyOtherMostratec')}}</p>
                    <span class="form_info__subtitle">{{form.infos[index].participated_mostratec ? 'Sim' : 'Não'}}</span>
                  </div>
                  <div class="mb-9">
                    <p class="form_info">{{$t('willYouNeedAnySpecializedAssistance')}}</p>
                    <span class="form_info__subtitle">{{serviceListPicker(form.infos[index].special_service)}}</span>
                  </div>
                </div>
                <div class="separator__align">
                  <p class="separator">{{$t('registrationData')}}</p>
              </div>
            </div>
          </div>
      </div>
    </main>

  </aside>
</template>

<script>
import {
  InputCheckboxAccordion
} from '@/components/atoms'

import {
  tShirtSizes,
  tShirtTypes,
  englishLevels,
  scholarshipHolderPrograms
} from '@/utils/dictionaries'

export default {
  name: 'ProjectsInvolveds',
  components: {
    InputCheckboxAccordion
  },
  data() {
    return {
      form: {
        infos: []
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      tShirtSizes,
      tShirtTypes,
      englishLevels,
      scholarshipHolderPrograms
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.getInfos()
  },
  methods: {
    getInfos() {
      this.form.projectCategory = this.projectInfo.project_category
      this.form.quantityStudents = this.projectInfo.students.length

      this.projectInfo.involveds.forEach((involved) => {
        if (involved.type !== 'orientador' && involved.type !== 'coorientador') this.form.infos.push(involved)
      })
    },
    deficiencyListPicker(deficiency) {
      const list = deficiency?.deficiency || deficiency?.deficiencies
      return this.defOrServiceFormatted(list)
    },
    serviceListPicker(service) {
      const list = service?.special_service || service?.special_services
      return this.defOrServiceFormatted(list)
    },

    defOrServiceFormatted(defOrService) {
      if (defOrService.includes('nao') || !defOrService.length) return 'Não'
      const formattedDefOrService = []
      defOrService.forEach((item) => {
        formattedDefOrService.push(item.charAt(0).toUpperCase() + item.slice(1))
      })
      return formattedDefOrService.join(', ')
    },

    isAdvisor(value) {
      if (value === 0) {
        return 'Pessoa 1'
      }
      if (value === 1) {
        return 'Pessoa 2'
      }
      return null
    },

    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },

    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },

    coalesceInformation(info) {
      if (!info && info !== 0) return 'Não informado'
      return info
    }
  }
}

</script>

<style lang="scss" scoped>
.separator {
  font-family:Mulish;
  font-style: normal;
  font-weight: 700;
  width: 300px-55px;
  font-size: 24px;
  position: absolute;
  top: -55px;
  line-height: 33px;
  color: #1B778B;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
  &__align{
    height: 40px;
    position: absolute;
  }
}
.grey_separator{
  margin-left: 4px;
  border-bottom: 0.5px solid #CDCBCB;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  width: 98%;
  color: #303030;
}
.align_table{
  display: flex;
  justify-content: space-between;
}

.left_side{
  max-width: 446px;
}

.right_side{
  margin-right: 67px;
  &__middle{
    margin-right: 143px;

  }
  &__end{
    max-width: 308px;

  }
  &__middle_after{
    right: 171px;
    max-width: 446px;
    position: absolute;

  }
  &__init_after{
    right: 94px;
    max-width: 446px;
    position: absolute;
  }
  &__third_index{
    right: -15px;
    max-width: 446px;
    position: absolute;
  }

}

.info__icon{
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.accordion_size{
  width: 95%;
  justify-content: space-between;
}
.content{
  border-top: 1px solid #CDCBCB;
  margin-top: -33px;
  width: 95%;
  height: fit-content;
  padding-top: 13px;
  display: block;
  padding-left: 12px;
  padding-bottom: 37px;
  background: #FDFDFD;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.middle_content{
  margin-top: -33px;
  width: 95%;
  height: fit-content;
  padding-top: 13px;
  justify-content: space-between;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #FDFDFD;
  border-top: 1px solid #3E778D;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.end_content{
  margin-top: -33px;
  width: 95%;
  height: fit-content;
  padding-top: 13px;
  justify-content: space-between;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #FDFDFD;
  border-top: 1px solid #3E778D;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.main_align{
  padding-top: 70px;
  padding-left: 50px;
  input[type='checkbox']{
    position: absolute;
    left: 351px;
    width: 65%;
  }
  img{
    position: absolute;
    left: 69px;
    top: 56px;
  }
}

.form_info{
  font-family: Mulish;
  font-style: normal;
  margin-bottom: 9px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  margin-left: 1.5px;
  margin-right: 60px;
  &__subtitle{
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 18px;
    margin-left: 2px;
    color: #686868;
  }
}

.icon_padding{
  padding-left: 20px;
}

.firstQuestion{
  width: 380px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
    margin-left: 48.5px;
    margin-right: 60px;
  align-items: center;
  color: #303030;
  &__result{
    margin-left: 50.5px;
  }
}

.secondQuestion{
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  width: 360px;
  height: 40px;
}

.info{
  position: absolute;
  top: 100px;
  left: 20px;
  display: flex;
}

.finish_button {
    position: static;
    margin-top: 156%;
    margin-left: 70%;
    width: 255px;
    height: 50px;
    background: #1B778B;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

.revision_box{
  position: absolute;
  width: 69.3%;
  height: 57px;
  background: #FFFFFF;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  color: #0E5671;
  padding-bottom: 10px;
  border-radius: 10px 10px 0px 0px;
}

.revision_title{
  position: absolute;
  left: 22px;
  top: 34%;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.wrapperInvolveds{
  height:100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #FDFDFD;

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 464px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__main {

    .form__item {
      margin-left:10px;

      .input__space {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      &--info {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        color: $primary;
      }

      .levels {
        display: flex;
        flex-direction: column;

        .title {
          color: #303030;
          display: flex;
          flex-direction: column;
          font-size: 16px!important;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
        }

        label {
          display: flex;
          color: $almostBlack;
          font-size: 16px;
          margin-bottom: 5px;

          input {
            zoom: 1.5;
            margin-right: 5px;
          }
        }

        .warning {
          color: #ff9800;
        }
      }
    }
  }
}
}

</style>
