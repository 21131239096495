<template>
  <div class="container-app">
    <header-default @menuSelected="menuSelected"/>

    <transition appear mode="out-in">
      <keep-alive>
        <component
            v-if="['projectRanking', 'indicators'].includes(fields)"
            :is="fields"
        />
      </keep-alive>
    </transition>

    <router-view />
  </div>
</template>

<script>
import { HeaderDefault } from '@/components/organisms'
import { AdministrativeProjects as ProjectRanking, IndicatorsTemplate as Indicators } from '@/components/templates'

export default {
  name: 'AdministrativePanel',
  components: {
    HeaderDefault,
    ProjectRanking,
    Indicators
  },
  data() {
    return {
      fields: 'projectRanking'
    }
  },
  computed: {
    route() {
      return this.$route.name
    }
  },
  updated() {
    if (!['projectRanking', 'indicators'].includes(this.fields) && this.route == 'administrative-panel') {
      this.fields = 'projectRanking'
    }
  },
  methods: {
    menuSelected(e) {
      this.fields = e
    }
  }
}
</script>
