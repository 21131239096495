<template>
  <aside class="wrapper__analisis-info">
    <div class="revision_box"><h3 class="revision_title">Orientadores</h3></div>
    <ProjectsAdvisorsData
      :projectInfo="projectInfo"
    />
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProjectsAdvisorsData from '@/components/templates/ProjectsAdvisorsData'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  RadioGroup,
  InputCheckboxTitle,
  InputMasked,
  InputSelectLocation,
  InputSelect,
  InputCheckboxAccordion
} from '@/components/atoms'

import {
  tShirtSizes,
  tShirtTypes,
  englishLevels,
  scienceFairs,
  scholarshipHolderPrograms
} from '@/utils/dictionaries'

export default {
  name: 'ProjectsAdvisors',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    RadioGroup,
    InputCheckboxTitle,
    InputMasked,
    InputSelectLocation,
    InputSelect,
    InputCheckboxAccordion,
    ProjectsAdvisorsData
  },
  data() {
    return {
      form: {
        quantityStudents: null,
        livePresentation: null,
        infos: []
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      tShirtSizes,
      tShirtTypes,
      englishLevels,
      scienceFairs,
      scholarshipHolderPrograms
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    if (this.projectInfo) this.getInfos()
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    isAdvisor(value) {
      return value === 0 ? 'advisor' : 'coAdvisor'
    },
    async getInfos() {
      this.projectInfo.involveds.forEach((involved) => {
        if (involved.type == 'orientador' || involved.type == 'coorientador') { this.form.infos.push(involved) }
      })
      this.form.infos.sort((a, b) => {
        if (a.type === 'orientador') return -1
        if (a.type === 'coorientador') return 1
        return 0
      })
    },
    deficiencyListPicker(deficiency) {
      const list = deficiency?.deficiency || deficiency?.deficiencies
      return this.defOrServiceFormatted(list)
    },
    serviceListPicker(service) {
      const list = service?.special_service || service?.special_services
      return this.defOrServiceFormatted(list)
    },
    defOrServiceFormatted(defOrService) {
      if (defOrService.includes('nao') || !defOrService.length) return 'Não'
      const formattedDefOrService = []
      defOrService.forEach((item) => {
        formattedDefOrService.push(
          item.charAt(0).toUpperCase() + item.slice(1)
        )
      })
      return formattedDefOrService.join(', ')
    },
    checkForm() {
      this.resetErrors()

      if (!this.errors.length) {
        this.resetErrors()
        this.saveForm()
      }
    },
    saveForm() {
      try {
        const formData = this.$store.getters.fairAffiliation
        const newData = {
          ...formData,
          studentsInfo: this.form
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem(
          'projectSelectionData',
          JSON.stringify(newData)
        )
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    coalesceInformation(info) {
      if (!info && info !== 0) return 'Não informado'
      return info
    }
  }
}
</script>

<style lang="scss" scoped>
.grey_separator {
  margin-left: 4px;
  border-bottom: 0.5px solid #cdcbcb;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  width: 106%;
  color: #303030;
}
.align_table {
  display: flex;
  justify-content: space-between;
}

.left_side {
  max-width: 446px;
  margin-left: 15px;
}

.right_side {
  margin-right: 212px;
  &__middle {
    margin-right: 292px;
  }
  &__end {
    margin-right: -46px;
  }
}

.info__icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.accordion_size {
  width: 93%;
  padding-right: 24px;
}
.content {
  border-top: 1px solid #cdcbcb;
  margin-top: -33px;
  width: 93%;
  justify-content: space-between;
  height: fit-content;
  padding-top: 13px;
  display: block;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.middle_content {
  margin-top: -33px;
  width: 93%;
  height: fit-content;
  padding-top: 13px;
  justify-content: space-between;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.end_content {
  margin-top: -33px;
  width: 93%;
  height: fit-content;
  justify-content: space-between;
  padding-top: 13px;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}
.main_align {
  margin-top: 8%;
  padding-left: 50px;
  input[type="checkbox"] {
    width: 423px;
  }
  img {
    position: absolute;
    left: 69px;
    top: 56px;
  }
}

.form_info {
  font-family: Mulish;
  font-style: normal;
  margin-bottom: 9px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  margin-left: 1.5px;
  margin-right: 60px;
  &__subtitle {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 18px;
    margin-left: 2px;
    color: #686868;
  }
}

.icon_padding {
  padding-left: 20px;
}

.finish_button {
  position: static;
  margin-top: 156%;
  margin-left: 70%;
  width: 255px;
  height: 50px;
  background: #1b778b;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.revision_box {
  position: absolute;
  width: 68%;
  height: 57px;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  color: #0e5671;
  padding-bottom: 10px;
  border-radius: 10px 10px 0px 0px;
}

.revision_title {
  position: absolute;
  left: 22px;
  top: 34%;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
}

.wrapper__analisis-info {
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #fdfdfd;

  .separator {
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    width: 300px-55px;
    font-size: 24px;
    position: absolute;
    top: -55px;
    line-height: 33px;
    color: #1b778b;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 4px 0px;
    &__align {
      height: 40px;
      position: absolute;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 30px;
      text-align: left;
      max-width: 464px;

      span {
        font-size: 16px;
        line-height: 22px;
        margin: 5px 0px;
        color: $primary;
      }
    }

    &__title {
      font-weight: 900;
      font-size: 40px;
      line-height: 120%;
      color: $primary;
      margin-bottom: 5px;
    }

    &__text {
      font-family: Mulish;
      font-size: 16px;
      line-height: 22px;
      color: $primary;
      margin: 5px 0px;
    }

    &__main {
      .form__item {
        .input__space {
          margin-top: 30px;
          margin-bottom: 30px;
        }

        &--info {
          font-family: Mulish;
          font-size: 16px;
          line-height: 18px;
          color: $primary;
        }

        .levels {
          display: flex;
          flex-direction: column;

          .title {
            color: #303030;
            display: flex;
            flex-direction: column;
            font-size: 16px !important;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
          }

          label {
            display: flex;
            color: $almostBlack;
            font-size: 16px;
            margin-bottom: 5px;

            input {
              zoom: 1.5;
              margin-right: 5px;
            }
          }

          .warning {
            color: #ff9800;
          }
        }
      }
    }
  }
}
</style>
