<template>
  <section>
    <filter-projects
      :anyCheckboxEnabled="anyCheckboxEnabled"
      :tabsEnum="TabsEnum"
      @setList="setList"
      @filterProjectRequestsList="resetPagination"
    />
    <template v-if="openDialogDelete">
      <ModalDelete
        :data="dataEmitDelete"
        :text="'projeto'"
        @close="openDialogDelete=$event"
        @delete="deleteProject"
      />
    </template>
    <div v-if="projectRequests.length">
      <table
        :id="idTable"
        aria-hidden="true"
        :class="activeTab === TabsEnum.DRAFT && 'draft'"
      >
        <thead>
          <tr v-if="anyCheckboxEnabled || selectAll">
            <th class="checkbox" style="display: flex; justify-content: center;">
              <input
                type="checkbox"
                name="header"
                id="header"
                v-model="selectAll"
                @click="selectAll = !selectAll"
              />
            </th>
            <th
              style="display: flex; justify-content: flex-end; font-size: 14px;">{{ $t("choose") }}
            </th>
            <th
              style="display: flex; justify-content: flex-start; font-size: 14px;">{{ $t("all") }}
            </th>
            <th/>
            <th/>
            <th/>
            <th/>
            <th/>
            <th style="width: 250px; margin-left: -170px;">
              <button-default  :onClick="sendEmail" :disabled="!(selectAll || anyCheckboxEnabled)">
                {{$t('sendEmail')}}
                <img
                  class="img-icon"
                  :src="require('@/assets/email-icon.png')"
                  alt="send email"
                />
              </button-default>
            </th>
          </tr>
          <tr v-else>
            <th
              v-if="activeTab === 'aguardando-resposta'"
              class="checkbox" style="display: flex; justify-content: center;">
              <input
                type="checkbox"
                name="header"
                id="header"
                v-model="selectAll"
                @click="selectAll = !selectAll"
              />
            </th>
            <th v-else id="blank_cell"></th>
            <th id="code">{{ $t("code") }}</th>
            <th id="type">{{ $t("type") }}</th>
            <th id="status">{{ $t("requestStatus") }}</th>
            <th id="country">{{ $t("countryState") }}</th>
            <th id="school">{{ $t("schoolInstitution") }}</th>
            <th id="fair">{{ $t("affiliatedFairSingle") }}</th>
            <th id="student">{{ $t("responsibleStudent") }}</th>
            <th v-if="activeTab !== TabsEnum.DRAFT" id="form">{{ $t("form") }}</th>
            <th v-if="activeTab !== TabsEnum.DRAFT && actualRole === 'admin'">{{$t('delete')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in projectRequests" :key="index">
            <td v-if="activeTab === 'aguardando-resposta'" class="checkbox">
              <input
                v-if="item.sentReply === false && !selectAll"
                type="checkbox"
                name="`row1${item.id}`"
                id="`row1${item.id}`"
                v-model="selected[`id${item.id}`]"
                @click="checkboxProjectClicked(item.id, $event.target.checked)"
              />
            </td>
            <td v-else></td>
            <td class="code">
              <strong>#{{ item.project_id ? item.project_id : item.id }}</strong>
            </td>
            <td class="type">
              <span>{{ item.type }}</span>
            </td>
            <td class="status">
              <template v-if="item.isActive !== false">
                <span :class="item.statusClass">{{
                  item.statusName
                }}</span>
                <span
                  class="badge blue_send mr-2"
                  v-if="item.sentReply === true"
                >
                  {{ $t("sentAnswer") }}
                </span>
                <span
                  class="badge purple_waiting mr-2"
                  v-if="item.pendingAnswer === true"
                >
                  {{ $t("pendingAnswer") }}
                </span>
              </template>
              <template v-else>
                <span class="badge orange_inactive mr-2">
                  {{ $t("disabled") }}
                </span>
              </template>
            </td>
            <td class="country">
              <span>{{ item.countryState }}</span>
            </td>
            <td class="school-institution">
              <span>{{ item.schoolInstitution }}</span>
            </td>
            <td class="affiliated-fair">
              <span>{{ item.affiliatedFair }}</span>
            </td>
            <td class="student">
              <span>{{ item.student }}</span>
            </td>
            <td v-if="activeTab !== TabsEnum.DRAFT" class="action">
              <v-icon
                v-if="item.statusSlug !== 'rascunho'"
                color="#1B778B"
                large
                @click="detailItem(item.id)"
              >
                mdi-chevron-right
              </v-icon>
              <v-icon
                v-else
                color="#1B778B"
                large
                class="disabled"
              >
                mdi-chevron-right
              </v-icon>
            </td>
            <td
              v-if="activeTab !== TabsEnum.DRAFT && actualRole === 'admin'"
              @click="openModalDelete(true, item)">
              <v-icon
                color="primary"
                class="delete-icon"
              >
                mdi-delete
              </v-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <v-container>
          <v-row justify="end">
            <v-pagination
              v-model="page"
              class="my-4"
              :length="lastPage"
            ></v-pagination>
          </v-row>
          <v-row class="mr-0" justify="end">
            <span class="pagination__text">
              {{ renderPagination() }}
            </span>
          </v-row>
        </v-container>
      </div>
    </div>
    <span v-else>{{ $t(emptyStateCurrentText) }}</span>
    <modal-default :title="$t('emailSent')" @closeModal="toggleModal" :modal="modal">
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/email-send.png" alt="E-mail enviado" />
        </div>
        <div class="text">
          <p
            slot="text"
          >{{$t('emailSentStudents')}}</p>
          <p slot="text">{{$t('statusUpdateAfterAnalysis')}}</p>
        </div>
      </div>
    </modal-default>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FilterProjects, ModalDefault, ModalDelete } from '@/components/organisms'
import {
  ButtonDefault
} from '@/components/atoms'

const TabsEnum = Object.freeze({
  DRAFT: 'draft',
  ALL: 'todas',
  OPEN: 'aguardando-revisao',
  IN_REVIEW: 'em-revisao',
  WAITING_ANSWER: 'aguardando-resposta',
  WAITING_FOR_PAYMENT: 'aguardando-pagamento',
  EFFECTED: 'inscricao-efetivada',
  WAITING_ACCEPTANCE: 'aguardando-aceite-feira',
  REVIEW_ACCEPTED: 'revisao-aceita',
  REVIEW_DENIED: 'revisao-negada',
  REVIEW_CANCELED: 'inscricao-cancelada',
  DISAPPROVED: 'reprovado',
  SENT_ANSWER: 'resposta-enviada',
  DISABLED: 'desativada'
})

const EmptyStateText = Object.freeze({
  LOADING: 'loading',
  NO_RESULTS: 'noResults'
})

export default {
  name: 'TableListProjectSolicitations',
  components: {
    FilterProjects,
    ButtonDefault,
    ModalDefault,
    ModalDelete
  },
  computed: {
    ...mapGetters({
      count: 'list/countDone',
      lang: 'lang',
      projectRequestFilter: 'projectRequestFilter',
      projectSendMails: 'projectSendMails'
    }),
    projectSendMails: {
      get() {
        return this.projectSendMails
      },
      set(newName) {
        return newName
      }
    },
    idTable() {
      return this.actualRole === 'admin' ? 'tableProjectsAdmin' : 'tableProjects'
    }
  },
  data: () => ({
    EmptyStateText,
    TabsEnum,
    activeTab: TabsEnum.ALL,
    anyCheckboxEnabled: false,
    page: 1,
    total: 0,
    perPage: 50,
    lastPage: 1,
    projectRequests: [],
    emptyStateCurrentText: EmptyStateText.NO_RESULTS,
    status: {},
    selected: {},
    selectAll: false,
    modal: false,
    openDialogDelete: false,
    dataEmitDelete: {},
    actualRole: ''
  }),
  mounted() {
    this.status = {
      rascunho: {
        name: this.$t('sketch'),
        class: 'badge gray_draft'
      },
      'inscricao-efetivada': {
        name: this.$t('registrationComplete'),
        class: 'badge green_accept'
      },
      'aguardando-aceite-feira': {
        name: this.$t('pendingAcceptStatus'),
        class: 'badge warn'
      },
      reprovado: {
        name: this.$t('notAccept'),
        class: 'badge red_reject'
      },
      'aguardando-revisao': {
        name: this.$t('pendingRevision'),
        class: 'badge warn'
      },
      'em-revisao': {
        name: this.$t('underReview'),
        class: 'badge blue_analisis'
      },
      'revisao-aceita': {
        name: this.$t('reviewAccepted'),
        class: 'badge green_accept'
      },
      'revisao-negada': {
        name: this.$t('reviewDenied'),
        class: 'badge red_reject'
      },
      'aguardando-pagamento': {
        name: this.$t('waitingPayment'),
        class: 'badge purple_waiting'
      },
      'aguardando-resposta': {
        name: this.$t('pendingAnswer'),
        class: 'badge purple_waiting'
      },
      'resposta-enviada': {
        name: this.$t('sentAnswer'),
        class: 'badge blue_send'
      },
      'inscricao-cancelada': {
        name: this.$t('registrationCanceled'),
        class: 'badge red_reject'
      }
    }
    this.getProjectRequests()
    this.getRole()
  },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage',
      projectListFilter: 'projectListFilter',
      projectSendMails: 'projectSendMails'
    }),
    detailItem(id) {
      this.$router.push(`project-details/${ id }`)
    },
    buildCountryStateName(country, state) {
      if (country === 'Brazil' && state) {
        return `${ country }-${ state }`
      }
      if (country) return country
      return ''
    },
    buildParam(name, value) {
      return `&${ name }=${ value }`
    },
    async buildFilterRequest() {
      const filters = await this.$store.getters.projectRequestFilter
      const id = filters?.project_code
      const type = filters?.register_type || []
      const paramsObject = {
        level: filters?.teaching_level || [],
        researchArea: filters?.research_area || [],
        city: filters?.city || [],
        country: filters?.country || [],
        state: filters?.state || [],
        fairYearEdition: filters?.fair_edition || [],
        fairName: filters?.fair_name || []
      }
      const params = []

      if (this.activeTab === TabsEnum.DISABLED || this.activeTab === TabsEnum.ALL) {
        paramsObject.internalStatusSlug = filters?.status || []
      }

      if (id) {
        params.push(this.buildParam('id', id))
      }

      Object.keys(paramsObject).forEach((key) => {
        paramsObject[key].forEach((value) => {
          params.push(this.buildParam(key, value))
        })
      })

      if (type.length === 2 || type.length === 0) {
        params.push(this.buildParam('all', 'true'))
      } else {
        type.map((param) => {
          params.push(this.buildParam('type', param))
        })
      }

      return params.join('')
    },
    async buildEndpoint() {
      // NÃO INSERIR ALL=TRUE EM NENHUMA DAS ROTAS COMO PARAMETRO FIXO, VAI QUEBRAR
      const moreParams = await this.buildFilterRequest()
      let endpoint = ''

      if (this.activeTab === TabsEnum.ALL) {
        return `/project?page=${ this.page }&limit=${ this.perPage }${ moreParams }`
      }

      if (this.activeTab === 'aguardando-resposta') {
        endpoint = `/project?sentReply=${ false }&page=${ this.page }&limit=${ this.perPage }`
      }

      if (this.activeTab === 'resposta-enviada') {
        endpoint = `/project?sentReply=${ true }&page=${ this.page }&limit=${ this.perPage }`
      }

      if (this.activeTab !== 'resposta-enviada' && this.activeTab !== 'aguardando-resposta') {
        endpoint = `/project?internalStatusSlug=${ this.activeTab }&page=${ this.page }&limit=${ this.perPage }`
      }

      if (this.activeTab === 'desativada') {
        endpoint = `/project?isActive=${ false }&page=${ this.page }&limit=${ this.perPage }`
      }

      if (this.activeTab === TabsEnum.DRAFT) {
        return endpoint
      }

      return endpoint + moreParams
    },
    async getProjectRequests() {
      this.projectRequests = []
      this.emptyStateCurrentText = EmptyStateText.LOADING
      const endpoint = await this.buildEndpoint()
      try {
        const requests = await this.$http.get(endpoint)
        return this.handleProjectRequests(requests)
      } catch {
        return this.handleProjectRequests(null)
      }
    },
    async sendEmail() {
      if (this.selectAll) {
        try {
          const { data: response } = await this.$http.post('/project/send-all-answer')
          if (response.status === 200) {
            this.toggleModal()
          }
        } catch (error) {
          console.log('erro: ', error)
          this.textWarning = 'failedToSendEmail'
          this.errors.push('emails')
        }
      } else {
        const emails = this.$store.getters.projectSendMails
        if (emails) {
          try {
            const { data: response } = await this.$http.post('/project/send-answer',
              {
                fairProjects: [],
                processSelectionProjects: emails
              })
            if (response.status === 200) {
              this.toggleModal()
            }
          } catch (error) {
            console.log('erro: ', error)
            this.textWarning = 'failedToSendEmail'
            this.errors.push('emails')
          }
        } else {
          this.textWarning = 'failedToSendEmail'
          this.errors.push('emails')
        }
      }
      this.$store.commit('PROJECT_SEND_MAILS', [])
      this.anyCheckboxEnabled = false
      this.selectAll = false
      this.selected = {}
      this.getProjectRequests()
    },
    toggleModal() {
      this.modal = !this.modal
    },
    handleProjectRequests(payload) {
      if (payload?.data) {
        const requestList = payload.data?.data
        this.projectRequests = []
        this.total = requestList?.total
        this.lastPage = requestList?.lastPage
        if (this.activeTab === TabsEnum.DRAFT) {
          this.mapRequests(requestList?.data, this.onMappingDraftRequest)
        } else {
          this.mapRequests(requestList?.data, this.onMappingRegisteredRequest)
        }
      } else {
        this.emptyStateCurrentText = EmptyStateText.NO_RESULTS
      }
    },
    mapRequests(requestList, onMap) {
      const mappedRequests = requestList.map((request) => onMap(request))
      if (!mappedRequests.length) {
        this.emptyStateCurrentText = EmptyStateText.NO_RESULTS
      } else {
        this.projectRequests = mappedRequests
      }
    },
    onMappingDraftRequest(request) {
      const isFromFair = request.type === 'Feira Afiliada'
      const schoolInstitution = !isFromFair ? request.schoolInstitution || 'Não informado' : ''
      const affiliatedFair = isFromFair ? request.affiliatedFair || 'Não informado' : ''
      return {
        id: request.id,
        statusSlug: request.statusSlug,
        statusName: this.getStatusName(request.statusSlug),
        statusClass: this.getStatusClass(request.statusSlug),
        sentReply: request.sentReply,
        type: this.$t(request.type),
        schoolInstitution,
        affiliatedFair,
        student: request.student,
        isActive: true,
        countryState: request.countryState
      }
    },
    onMappingRegisteredRequest(request) {
      const mainStudent = request.students.find((student) => student.main)
      const isFromFair = Boolean(request.fair_id)
      const type = isFromFair ? 'affiliatedFairSingle' : 'selectionProcess'
      const revisionStatus = ['revisao-aceita', 'revisao-negada']
      const countryState = this.buildCountryStateName(
        mainStudent?.country,
        mainStudent?.state
      )
      const schoolInstitution = !isFromFair ? request.schools?.school_name : ''
      const affiliatedFair = isFromFair ? request.fair?.fair_name : ''
      const sentReply = request.sent_reply
      const statusSlug = request.internal_status?.slug
      const pendingAnswer = !request.sent_reply
        && revisionStatus.some((value) => value === statusSlug)
      return {
        id: request?.id,
        project_id: request?.project_id,
        type: this.$t(type),
        statusSlug,
        statusName: this.getStatusName(request.internal_status?.slug),
        countryState,
        schoolInstitution,
        affiliatedFair,
        student: mainStudent?.full_name,
        statusClass: this.getStatusClass(request.internal_status?.slug),
        isActive: request.is_active,
        sentReply,
        pendingAnswer
      }
    },
    getStatusName(stat) {
      return this.status[stat]?.name || this.$t('noStatus')
    },
    getStatusClass(stat) {
      return this.status[stat]?.class || ''
    },
    getSchoolType(type) {
      if (type === 'private') return this.$t('particular')
      return this.$t('public')
    },
    setList(e) {
      this.activeTab = e
    },
    renderPagination() {
      const current = this.page === 1 ? 1 : (this.page - 1) * this.perPage + 1
      const results = this.page * this.perPage
      return `${ current } - ${ results } ${ this.$t('outOf') } ${ this.total }`
    },
    checkboxProjectClicked(id, value) {
      let emails = this.$store.getters.projectSendMails
      if (!emails.includes(id) && value === true) {
        emails.push(id)
        this.$store.commit('PROJECT_SEND_MAILS', emails)
      }
      if (value === false) {
        const filteredEmails = emails.filter((i) => i !== id)
        emails = filteredEmails
        this.$store.commit('PROJECT_SEND_MAILS', filteredEmails)
      }
      if (emails.length !== 0) {
        return (this.anyCheckboxEnabled = true)
      }
      this.anyCheckboxEnabled = false
    },
    resetPagination() {
      this.page = 1
      this.total = 0
      this.perPage = 15
      this.lastPage = 1
      this.getProjectRequests()
    },

    openModalDelete(open, data) {
      this.openDialogDelete = open
      this.dataEmitDelete = data
    },

    async deleteProject() {
      await this.$http.delete(`/project/${ this.dataEmitDelete.id }`)
      this.getProjectRequests()
    },

    getRole() {
      this.actualRole = localStorage.getItem('role')
    }
  },
  watch: {
    page() {
      this.getProjectRequests()
    },
    activeTab() {
      this.resetPagination()
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  cursor: unset;
  opacity: 0.5;
}

#tableProjects {
  display: grid;
  overflow-x: auto;
  border-collapse: collapse;
  grid-template-columns:
    minmax(30px, 30px)
    minmax(50px, 70px)
    minmax(160px, 1fr)
    minmax(160px, 1fr)
    minmax(90px, 90px)
    minmax(180px, 1fr)
    minmax(108px, 1fr)
    minmax(70px, 1fr)
    minmax(80px, 80px);

  &.draft {
    grid-template-columns:
    minmax(30px, 30px)
    minmax(60px, 80px)
    minmax(160px, 1fr)
    minmax(160px, 1fr)
    minmax(90px, 90px)
    minmax(180px, 1fr)
    minmax(108px, 1fr)
    minmax(75px, 1fr)
  }
}

#tableProjectsAdmin {
  display: grid;
  overflow-x: auto;
  border-collapse: collapse;
  grid-template-columns:
    minmax(30px, 30px)
    minmax(50px, 70px)
    minmax(160px, 1fr)
    minmax(160px, 1fr)
    minmax(90px, 90px)
    minmax(180px, 1fr)
    minmax(108px, 1fr)
    minmax(70px, 1fr)
    minmax(80px, 80px)
    minmax(55px, 65px);

  &.draft {
    grid-template-columns:
    minmax(30px, 30px)
    minmax(60px, 80px)
    minmax(160px, 1fr)
    minmax(160px, 1fr)
    minmax(90px, 90px)
    minmax(180px, 1fr)
    minmax(108px, 1fr)
    minmax(75px, 1fr)
  }
}

thead,
tbody,
tr {
  display: contents;
}

th,
td {
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

td.status {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

th {
  align-items: center;
  background-color: #dbe9ec;
  font-weight: 600;
  color: $primary;
  display: flex;
  font-size: 12px;
  position: relative;
  height: 60px;
  text-align: left;
}

th:first-child {
  border-radius: 10px 0px 0px 0px;
}

th:last-child {
  border-radius: 0px 10px 0px 0px;
}

tbody tr td {
  align-items: center;
  display: flex;
  color: $almostBlack;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

tbody tr:nth-child(odd) td {
  background-color: #f6f6f6;
}

tbody tr:nth-child(even) td {
  background-color: $white;
}

tbody tr td:first-child {
  @include d-flex-center;
}

.pagination__text {
  font-size: 14px;
  line-height: 18px;
  color: #6b6b6b;
}

::v-deep .v-pagination__item {
  box-shadow: none !important;
  color: #b5b5b5 !important;
  font-size: 14px !important;
  height: 30px !important;
  line-height: 16px !important;
  min-width: 15px !important;
}

::v-deep .v-pagination__item--active {
  color: $white !important;
}

::v-deep .v-pagination__navigation {
  box-shadow: none !important;
  height: 30px !important;
  line-height: 16px !important;
  width: 15px !important;
  outline: 0;
}

.delete-icon {
  cursor: pointer;
  padding: 10px;
}
</style>
