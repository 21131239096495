<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('aboutYourFair')}} -</span>
        <span class="form__title__complement">{{$t('generalInformation')}}</span>
      </div>
      <div class="form__info mb-3">
        <text-warning
          :text="`considerAllFieldObligatory`"
        />
      </div>
      <input-masked
        label= 'totalOfSchoolsInYourFair'
        :class="[{warning: checkErrorIfContains('schoolsNumber')}, 'mb-7']"
        id="schoolsNumber"
        placeholder="schoolsNumberPlaceholder"
        type="number"
        v-model="form.schoolsNumber"
        :mask="['####']"
        @input="resetErrors"
      />

      <input-masked
        :class="[{warning: checkErrorIfContains('publicSchoolsNumber')}, 'mb-7']"
        label="publicSchoolNumber"
        id="publicSchoolsNumber"
        placeholder= "schoolsNumberPlaceholder"
        type="number"
        :mask="['####']"
        v-model="form.publicSchoolsNumber"
        @input="resetErrors"
      />

      <input-masked
        :class="[{warning: checkErrorIfContains('privateSchoolsNumber')}, 'mb-7']"
        label="privateSchoolNumber"
        id="privateSchoolsNumber"
        placeholder= "schoolsNumberPlaceholder"
        type="number"
        :mask="['####']"
        v-model="form.privateSchoolsNumber"
        @input="resetErrors"
      />
      <RadioGroup
        :class="{warning: checkErrorIfContains('otherFairParticipate')}"
        :options="options"
        v-model="otherFairParticipate"
        label= "DidOthersAffiliatedFairsParticipate"
        @change="resetErrors"
      />
      <div v-if="otherFairParticipate == 'yes'">
        <input-masked
          :class="[{warning: checkErrorIfContains('affiliatedFairsNumber')}, 'mb-7']"
          label="affiliatedFairsNumber"
          id="affiliatedFairsNumber"
          placeholder="affiliatedFairsNumberPlaceholder"
          type="number"
          :mask="['####']"
          v-model="affiliatedFairsNumber"
          @input="resetErrors"
        />
      </div>
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  RadioGroup,
  InputMasked
} from '@/components/atoms'

export default {
  name: 'FormSchoolDataAddress',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    RadioGroup,
    InputMasked
  },
  data: () => ({
    form: {
      schoolsNumber: '',
      publicSchoolsNumber: '',
      privateSchoolsNumber: ''
    },
    otherFairParticipate: '',
    options: [
      { label: 'yes', value: 'yes' },
      { label: 'no', value: 'no' }
    ],
    affiliatedFairsNumber: '',
    errors: [],
    textWarning: 'warningAllFieldsAreObligatory'
  }),
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.about_fairs) {
      this.form.schoolsNumber = this.fairAffiliation.about_fairs.total_participants_fair
      this.form.publicSchoolsNumber = this.fairAffiliation.about_fairs.total_public_school
      this.form.privateSchoolsNumber = this.fairAffiliation.about_fairs.total_private_school
      this.otherFairParticipate = this.fairAffiliation.about_fairs
        .other_participants_fairs
        ? 'yes'
        : 'no'
      if (this.fairAffiliation.about_fairs.other_participants_fairs) {
        this.affiliatedFairsNumber = this.fairAffiliation.about_fairs.number_fairs_affiliate
      } else {
        this.affiliatedFairsNumber = ''
      }
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      this.resetErrors()
      this.textWarning = 'warningAllFieldsAreObligatory'
      for (const key in this.form) {
        if (this.form[key] === '') {
          this.errors.push(key)
        }
      }
      if (!this.otherFairParticipate) {
        this.errors.push('otherFairParticipate')
      }
      if (this.otherFairParticipate == 'yes') {
        if (!this.affiliatedFairsNumber) {
          this.errors.push('affiliatedFairsNumber')
        }
      }
      if (!this.errors.length) {
        this.saveForm()
      }
    },
    saveForm() {
      try {
        let screen
        const formData = this.$store.getters.fairAffiliation
        const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
        if (currentScreen < 7) {
          screen = 7
        } else {
          screen = currentScreen
        }
        const newData = {
          ...formData,
          currentScreen: screen,
          about_fairs: {
            total_participants_fair: +this.form.schoolsNumber,
            total_public_school: +this.form.publicSchoolsNumber,
            total_private_school: +this.form.privateSchoolsNumber,
            other_participants_fairs: this.otherFairParticipate === 'yes',
            number_fairs_affiliate: +this.affiliatedFairsNumber || 0
          }
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem(
          'fairAffiliatonData',
          JSON.stringify(newData)
        )
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-width: 610px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  .form__title__complement {
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-left: 11px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
