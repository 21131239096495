<template>
  <div
    class="wrapper-menu"
    :style="newStyle">
    <template v-for="(item, index) in menus">
      <span
        @click="clickedMenu(index)"
        :class="{'active' : index === active}"
        :style="newStyle"
        :key="index">{{$t(item)}}
      </span>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Menu',
  data() {
    return {
    }
  },
  props: {
    menus: {
      type: Array,
      required: true
    },
    active: {
      type: Number,
      required: true,
      default: 0
    },
    newStyle: {
      type: Object,
      required: false
    }
  },
  methods: {
    clickedMenu(index) {
      this.$emit('selectedMenu', index)
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper-menu {
  display: flex;
  flex-wrap: nowrap;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: pointer;
    padding: 20px 3px;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    text-align: center;
    background-color: $white;
    border-bottom: 2px solid $white;
    transition: .2s ease-in-out;
    &.active {
      border-bottom: 2px solid $blueStrong;
      font-weight: bold;
    }
  }
}
</style>
