<template>
  <div class="input__checkbox">
    <input type="checkbox" name="" :id="id"
      v-bind:checked="checked"
      @change="$emit('change', $event.target.checked)"
    />
    <span><label :for="id">{{$t(label)}}</label></span>
  </div>
</template>

<script>

export default {
  name: 'InputCheckboxtitle',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    label: String,
    type: String,
    id: String,
    placeholder: String,
    checked: Boolean,
    max: Number | String,
    disabled: Boolean
  }
}
</script>

<style lang="scss" scoped>
.input__checkbox {
  align-items: center;
  display: flex;

  input[type='checkbox'] {
    zoom: 1.8;
    margin-top: 1.5px;
  }

  span {
    border-bottom: 1.5px solid $primary;
    color: $primary;
    flex: 1;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    margin-left: 10px;
    max-width: 728px;
  }
}

.warning {
  span {
    color: $attention;
    border-bottom-color: $attention;
  }

}

.disable {
  span {
    border-color: gray;
    color: gray;
  }
}

</style>
