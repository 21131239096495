<template>
  <p class="content">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'BlueText'
}
</script>

<style lang="scss" scoped>
.content {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  width: 47% !important;
  line-height: 20px;
  color: #0E5671;
}
</style>
