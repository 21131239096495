<template>
  <div class="wrapper">
    <img class ="img_default" :src="require(`@/assets/${this.image}`)" />
    <span class="content">
      {{ content }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'ImageInformation',
  props: {
    image: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  text-align: center;
  flex-direction: column;
  height: 65%;
  margin: 3px 20px;
  width: 100%;
  justify-content: center;
;

  & > .img_default {
    width: 408px;
    height: 376px;
    display: flex;
    align-self: center;
    justify-content: center;
  }

  & > .content {
    color: $primaryDark;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    margin: 15px 0px;
    align-self: center;
    width: 640px;
    font-size: 18px;
    height: 60px;
    line-height: 20px;

  }
}

</style>
