<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('aboutStudents')}}</p>
        <p class="form__text">
          {{$t('informTheDataOfTheProject')}}
          <strong>{{$t('numberOfStudentsInTheProject')}}</strong>
        </p>
        <text-warning
          :text="$t('considerAllFieldObligatory')"
        />
      </div>

      <RadioGroup
        :class="[{ warning: checkErrorIfContains('quantityStudents') }, 'mb-2']"
        label=numberOfStudentsInTheProject
        :options="quantityOptions"
        v-model="form.quantityStudents"
        @change="resetErrors"
      />

      <RadioGroup
        :class="[{ warning: checkErrorIfContains('livePresentation') }, 'mb-2']"
        label=ableToParticipateInALivePresentation
        :options="generalOptions"
        v-model="form.livePresentation"
        @change="resetErrors"
      />

      <main class="form__main mt-4">
        <div class="form__item mb-5" v-for="(info, index) of form.infos" :key="index">
          <input-checkbox-title
            v-if="index <= form.quantityStudents - 1"
            :class="[{ warning: checkErrorIfContains('active' + index) }, 'mb-7']"
            :label="$t('student') + String(Number(index + 1))"
            v-model="form.infos[index].active"
            id="student2"
            @change="resetErrors"
          />

          <div v-if="form.infos[index].active">
            <p v-if="index == 0" class="form__item--info">{{$t('studentOneInfo')}}</p>
            <input-default
              label=fullNameStudent
              :class="[{ warning: checkErrorIfContains('full_name' + index) }, 'input__space']"
              placeholder=fullNamePlaceholder
              type="text"
              max="200"
              v-model="form.infos[index].full_name"
              @input="resetErrors"
              :disabled="index == 0"
            />

            <input-masked
              v-if="!form.infos[index].country || form.infos[index].country == 'Brazil'"
              :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
              label=cpfStudent
              id="codigo"
              placeholder="xxx.xxx.xxx-xx"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
              :mask="['###.###.###-##']"
              :disabled="index == 0"
            />

            <input-default
              v-else
              :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
              label="Identification number"
              id="codigo"
              placeholder="xxx.xxx.xxx-xx"
              type="text"
              max="20"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{ warning: checkErrorIfContains('sex' + index) }, 'input__space']"
              label=sex
              :options="genderOptions"
              v-model="form.infos[index].sex"
              @change="resetErrors"
            />

            <input-masked
              :class="[{ warning: checkErrorIfContains('birth_date' + index) }, 'input__space']"
              required
              label=birthDate
              id="birthday"
              placeholder="dd/mm/aaaa"
              type="text"
              v-model="form.infos[index].birth_date"
              @input="resetErrors"
              :mask="['##/##/####']"
            />
            <template
              v-if="
                validateIfBigger(form.infos[index].birth_date, index) || form.infos[index].isBetter
              "
            >
              <input-default
                label=responsibleName
                :class="[
                  { warning: checkErrorIfContains('responsible_name' + index) },
                  'input__space'
                ]"
                placeholder=adultsName
                type="text"
                max="150"
                v-model="form.infos[index].responsible_name"
                @input="resetErrors"
              />
              <input-default
                label=parentageOfResponsible
                :class="[
                  {
                    warning: checkErrorIfContains('responsible_kinship' + index)
                  },
                  'input__space'
                ]"
                placeholder=insertParentage
                type="text"
                max="150"
                v-model="form.infos[index].responsible_kinship"
                @input="resetErrors"
              />
            </template>

            <input-default
              :class="[{ warning: checkErrorIfContains('phone' + index) }, 'input__space']"
              label=enterInstitutionSchoolPhone
              id="phone"
              placeholder="(00) 00000-0000"
              type="text"
              v-model="form.infos[index].phone"
              @input="resetErrors"
               min="1" max="20"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />

            <input-default
              :class="[{ warning: checkErrorIfContains('email' + index) }, 'input__space']"
              label=email
              id="email"
              placeholder=enterTheEmail
              type="text"
              max="200"
              v-model="form.infos[index].email"
              @input="resetErrors"
              :disabled="index == 0"
            />

            <div class="separator">
              <h3>{{$t('address')}}</h3>
            </div>
            <input-select-location
              :class="[{ warning: checkErrorIfContains('country' + index) }, 'input__space']"
              id="country"
              :items="countries"
              v-model="form.infos[index].country"
              label=country
              @input="resetErrors"
              :selected="form.infos[index].country"
            />

            <input-masked
              v-if="form.infos[index].country == 'Brazil'"
              :class="['input__space']"
              required
              label="CEP"
              id="zipCode"
              :placeholder="$t('inputCepPlaceholder')"
              type="text"
              v-model="form.infos[index].zipCode"
              @input="resetErrors"
              :mask="['#####-###']"
            />

            <template v-if="form.infos[index].cepValidate">
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                label=state
                id="state"
                type="text"
                max="150"
                v-model="form.infos[index].state"
                @input="resetErrors"
              />
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                label=city
                id="city"
                type="text"
                max="150"
                v-model="form.infos[index].city"
                @input="resetErrors"
              />
            </template>
            <template v-else>
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                id="serie"
                :items="states"
                v-model="form.infos[index].state"
                label=state
                @input="resetErrors"
                :selected="form.infos[index].state"
              />
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                id="serie"
                :items="cities"
                v-model="form.infos[index].city"
                label=city
                @input="resetErrors"
                :selected="form.infos[index].city"
              />
            </template>
            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('address' + index) }, 'input__space']"
              label=address
              id="address"
              placeholder=addressExample
              type="text"
              max="200"
              v-model="form.infos[index].street"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country === 'Brazil'"
              :class="[{ warning: checkErrorIfContains('neighborhood' + index) }, 'input__space']"
              label=neighborhood
              id="neighborhood"
              placeholder=neighborhoodPlaceholder
              type="text"
              max="150"
              v-model="form.infos[index].district"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('house_number' + index) }, 'input__space']"
              label="Número"
              id="house_number"
              placeholder=numberPlaceholder
              type="text"
              max="20"
              v-model="form.infos[index].house_number"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].house_number"
              :class="[{ warning: checkErrorIfContains('complement' + index) }, 'input__space']"
              label=complementOptional
              id="complement"
              placeholder=complementPlaceholder
              type="text"
              max="150"
              v-model="form.infos[index].complement"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
              label="State/Province"
              id="state"
              placeholder=statePlaceholder
              type="text"
              max="100"
              v-model="form.infos[index].state"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
              label="City"
              id="city"
              placeholder="Input your city"
              type="text"
              max="200"
              v-model="form.infos[index].city"
              @input="resetErrors"
            />

            <div class="separator">
              <h3>{{$t('registrationData')}}</h3>
            </div>

            <input-select
              :class="[
                { warning: checkErrorIfContains('educational_level' + index) },
                'input__space'
              ]"
              id="educational_level"
              :items="serieOptions"
              v-model="form.infos[index].educational_level"
              label=grade
              :selected="form.infos[index].educational_level"
              @input="resetErrors"
            />
            <input-select
              :class="[{ warning: checkErrorIfContains('english_level' + index) }, 'input__space']"
              id="english_level"
              :items="englishOptions"
              v-model="form.infos[index].english_level"
              label=levelOfKnowledgeInEnglish
              :selected="form.infos[index].english_level"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{ warning: checkErrorIfContains('scholarship_holder' + index) }, 'mb-2']"
              label="doYouHaveScholarship"
              :options="generalOptions"
              v-model="form.infos[index].scholarship_holder"
              @change="resetErrors, checkValueInputOthersProgram(index)"
            />

            <RadioGroup
              v-if="form.infos[index].scholarship_holder === 'yes'"
              :class="[{ warning: checkErrorIfContains('which_program' + index) }, 'mb-1']"
              label=whichProgram
              :options="programOptions"
              v-model="form.infos[index].which_program"
              @change="resetErrors"
            />

            <input-default
              v-if="form.infos[index].which_program === 'other' &&
                form.infos[index].scholarship_holder === 'yes'"
              :class="[
                { warning: checkErrorIfContains('other_program' + index) },
                'input__space mt-0'
              ]"
              id="city"
              placeholder=enterTheProgramName
              type="text"
              v-model="form.infos[index].other_program"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{ warning: checkErrorIfContains('attendOtherFair' + index) }, 'mb-1']"
              label=haveYouParticipatedAnyScienceFair
              :options="attendOptions"
              v-model="form.infos[index].attendOtherFair"
              @change="resetErrors"
            />

            <input-default
              v-if="form.infos[index].attendOtherFair === 'other'"
              :class="[
                { warning: checkErrorIfContains('fair_science' + index) },
                'input__space mt-0'
              ]"
              id="city"
              placeholder=enterWhichScienceFair
              type="text"
              max="200"
              v-model="form.infos[index].fair_science"
              @input="resetErrors"
            />

            <div class="levels">
              <p :class="[{ warning: checkErrorIfContains('deficiency' + index) }, 'title']">
                {{$t('doYouHaveAnyKindOfDisability')}}
              </p>
              <label>
                <input
                  @change="resetErrors, validCheckoboxGroup(form.infos[index], index)"
                  value="nao"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="fisica"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('fis')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="intelectual"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('intellectual')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="auditiva"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('auditory')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="visual"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('visual')}}
              </label>
            </div>
            <div class="levels" v-if="!form.infos[index].deficiency.includes('nao')">
              <p
                :class="[
                  { warning: checkErrorIfContains('special_service' + index) },
                  'title mt-3'
                ]"
              >
                {{$t('willYouNeedAnySpecializedAssistance')}}
              </p>
              <label>
                <input
                  @change="resetErrors, validCheckoboxGroup(form.infos[index], index)"
                  value="nao"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="acessibilidade"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                />{{$t('architecturalAccessibility')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="libras"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                />{{$t('libraInterpreter')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="ledor"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                >{{$t('reader')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="outro"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                />{{$t('othersMale')}}
              </label>
              <input-default
                v-if="form.infos[index].special_service.includes('outro')"
                :class="[
                  {
                    warning: checkErrorIfContains('otherSpecialService' + index)
                  },
                  'mt-0'
                ]"
                id="city"
                placeholder="enterTheSpecializedService"
                type="text"
                v-model="form.infos[index].otherSpecialService"
                @input="resetErrors"
              />
            </div>

            <input-select
              :class="[{ warning: checkErrorIfContains('t_shirt' + index) }, 'input__space']"
              id="t_shirt"
              :items="typeShirtOptions"
              v-model="form.infos[index].t_shirt"
              label=typeOfShirt
              @input="resetErrors"
            />

            <input-select
              v-if="form.infos[index].t_shirt === 'no'"
              :class="[{ warning: checkErrorIfContains('t_shirt_size' + index) }, 'input__space']"
              id="english_level"
              :items="sizeShirtOptions"
              v-model="form.infos[index].t_shirt_size"
              label=whatSizeShirt
              @input="resetErrors"
            />
          </div>
        </div>
      </main>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndContinue')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
    {{ cStateSelected }}
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  RadioGroup,
  InputCheckboxTitle,
  InputMasked,
  InputSelectLocation,
  InputSelect,
  InputCalendar
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'

export default {
  name: 'FormProjectHighStudents',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    RadioGroup,
    InputCheckboxTitle,
    InputMasked,
    InputSelectLocation,
    InputSelect,
    InputCalendar
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  data() {
    return {
      studentLogged: {},
      render: 0,
      form: {
        quantityStudents: 1,
        livePresentation: '',
        infos: []
      },
      quantityOptions: [
        { label: this.$t('individual'), value: 1 },
        { label: this.$t('pair'), value: 2 },
        { label: this.$t('trio'), value: 3 }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: 'yes' },
        { label: this.$t('no'), value: 'no' }
      ],
      genderOptions: [
        { label: this.$t('maleSingle'), value: 'male' },
        { label: this.$t('femaleSingle'), value: 'female' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      serieOptions: [
        { text: '1ª', value: '1' },
        { text: '2ª', value: '2' },
        { text: '3ª', value: '3' },
        { text: '4ª', value: '4' },
        { text: this.$t('internship'), value: 'estagio' }
      ],
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: 'avancado' },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' }// ,
        // { text: 'P', value: 'p' },
        // { text: 'M', value: 'm' },
        // { text: 'G', value: 'g' },
        // { text: 'GG', value: 'gg' }
      ],
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    zipCodeStudent1() {
      if (this.form.infos.length) return this.form.infos[0].zipCode
    },
    zipCodeStudent2() {
      if (this.form.infos.length > 1) return this.form.infos[1].zipCode
    },
    zipCodeStudent3() {
      if (this.form.infos.length > 2) return this.form.infos[2].zipCode
    },
    quantity() {
      if (this.form.quantityStudents) {
        return this.form.quantityStudents
      }
      return 1
    },
    cStateSelected() {
      if (this.form.infos[0]) {
        if (this.form.infos[0].state) {
          this.executeGetCities(this.form.infos[0].state)
        }
      }
      return ''
    }
  },

  async created() {
    await this.getStudentLogged()
    await this.createInfo()
    if (this.allDataForm.FormProjectHighStudents) {
      this.form = {
        ...this.allDataForm.FormProjectHighStudents
      }
      this.render = 2
    }
  },
  watch: {
    zipCodeStudent1() {
      if (this.form.infos.length) this.getAddressInfo(0)
    },
    zipCodeStudent2() {
      if (this.form.infos.length > 1) this.getAddressInfo(1)
    },
    zipCodeStudent3() {
      if (this.form.infos.length > 2) this.getAddressInfo(2)
    },
    quantity() {
      this.activeStudents()
    },
    allDataForm() {
      if (this.allDataForm.FormProjectHighStudents) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectHighStudents
        }
        this.form.quantityStudents = this.allDataForm.FormProjectHighStudents.infos.length
      }
    }
  },
  methods: {
    validCheckoboxGroup(actualData, index) {
      if (actualData.deficiency.includes('nao')) {
        this.form.infos[index].deficiency = ['nao']
      }
      if (actualData.special_service.includes('nao')) {
        this.form.infos[index].special_service = ['nao']
      }
    },
    executeGetCities(state) {
      if (this.form.infos[0].country !== 'Brazil') return false
      this.cities = []
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    validateIfBigger(data, index) {
      if (data.length === 10) {
        const dataForm = data
          .split('/')
          .reverse()
          .join('-')
        const dataAtual = new Date()
        const birth = new Date(dataForm)
        const diff = Math.abs(dataAtual.getTime() - birth.getTime())
        this.form.infos[index].isBetter = Math.floor(diff / (1000 * 60 * 60 * 24) / 364) < 18
      } else {
        this.form.infos[index].isBetter = false
      }
    },
    validateDateFormat(date) {
      const [year, month, day] = date.split('-')
      return (Number(month) <= 12 && Number(day) <= 31 && year >= 1900)
    },
    validateDate(birth) {
      const formattedDate = birth
        .split('/')
        .reverse()
        .join('-')

      const actualDate = new Date().setHours(0, 0, 0, 0, 0, 0)
      const birthDate = new Date(formattedDate.concat(' 00:00:00'))

      if (!this.validateDateFormat(formattedDate)) {
        this.textWarning = 'invalidDateFormat'
        return false
      }
      if (actualDate < birthDate) {
        this.textWarning = 'A data de nascimento informada deve ser anterior à data atual.'
        return false
      } return true
    },
    async getStudentLogged() {
      const userLogged = jwt_decode(localStorage.getItem('token'))
      const { data: { user: userInfoUsers } } = await this.$http.get(`/user/${ userLogged.uid }`)
      let cpf = ''
      if (userInfoUsers?.pre_registration_student_fair_affiliates) {
        cpf = userInfoUsers?.pre_registration_student_fair_affiliates.cpf
      }
      if (userInfoUsers?.pre_registration_student_process_selections) {
        cpf = userInfoUsers?.pre_registration_student_process_selections.cpf_process_registration
      }
      this.studentLogged = {
        fullname: userInfoUsers?.name,
        email: userInfoUsers?.email,
        cpf
      }
    },
    validateDateFormat(date) {
      const [year, month, day] = date.split('-')
      return (Number(month) <= 12 && Number(day) <= 31 && year >= 1900)
    },
    validateDate(birth) {
      const formattedDate = birth
        .split('/')
        .reverse()
        .join('-')

      const actualDate = new Date().setHours(0, 0, 0, 0, 0, 0)
      const birthDate = new Date(formattedDate.concat(' 00:00:00'))
      if (!this.validateDateFormat(formattedDate)) {
        this.textWarning = 'invalidDateFormat'
        return false
      }
      if (actualDate < birthDate) {
        this.textWarning = 'A data de nascimento informada deve ser menor do que a data atual.'
        return false
      } return true
    },
    async checkForm() {
      this.resetErrors()
      const emails = []
      if (!this.form.quantityStudents) {
        this.errors.push('quantityStudents')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }
      if (!this.form.livePresentation) {
        this.errors.push('livePresentation')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }
      this.form.infos.map((item, index) => {
        for (const key in item) {
          if (!item.active) {
            continue
          }
          if (typeof item[key] === 'boolean') {
            continue
          }
          if (item[key].length == 0 || !item[key]) {
            if (
              ((key === 'responsible_kinship' || key === 'responsible_name') && !item.isBetter)
              || key === 'complement'
              || (key === 'special_service' && item.deficiency.includes('nao'))
              // || (key === 't_shirt_size' && (item.t_shirt === 'no' || !item.t_shirt))
              || (key === 'which_program' && item.scholarship_holder === 'no')
              || (key === 'attendOtherFair' && item.attendOtherFair !== 'other')
            ) {
            } else {
              this.errors.push(`${ key }${ index }`)
            }
            this.textWarning = this.$t('warningAllFieldsAreObligatory')
          }
        }
        if (item.country != 'Brazil') {
          const indexZipcode = this.errors.indexOf(`zipCode${ index }`)
          if (indexZipcode > -1) {
            this.errors.splice(indexZipcode, 1)
          }
          const indexDistrict = this.errors.indexOf(`district${ index }`)
          if (indexDistrict > -1) {
            this.errors.splice(indexDistrict, 1)
          }
          const indexstreet = this.errors.indexOf(`street${ index }`)
          if (indexstreet > -1) {
            this.errors.splice(indexstreet, 1)
          }
          const indexHouseNumber = this.errors.indexOf(`house_number${ index }`)
          if (indexHouseNumber > -1) {
            this.errors.splice(indexHouseNumber, 1)
          }
          const indexComplement = this.errors.indexOf(`complement${ index }`)
          if (indexComplement > -1) {
            this.errors.splice(indexComplement, 1)
          }
        }
        if (item.which_program != 'other') {
          const indexProgram = this.errors.indexOf(`other_program${ index }`)
          if (indexProgram > -1) {
            this.errors.splice(indexProgram, 1)
          }
        }
        if (item.attendOtherFair !== 'other' && item.attendOtherFair !== null) {
          const indexAttend = this.errors.indexOf(`fair_science${ index }`)
          if (indexAttend > -1) {
            this.errors.splice(indexAttend, 1)
          }
        }
      })
      if (!this.errors.length) {
        this.form.infos.forEach((value, index) => {
          if (value.active) {
            if (!this.validateEmail(value.email, index)) {
              this.errors.push(`email${ index }`)
              this.textWarning = 'E-mail inválido!'
            }
            emails.push(value.email)
            if (!this.validateDate(value.birth_date)) {
              this.errors.push(`birth_date${ index }`)
            }
          }
        })
        if (this.errors.length) {
          return false
        }
        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'E-mails inseridos devem ser distintos!'
          return false
        }
        this.resetErrors()
        await this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      this.allDataForm.FormProjectHighStudents = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        const students = this.form.infos
        students.forEach((item) => {
          if (item.attendOtherFair === 'mostratec') item.fair_science = 'mostratec'
          if (item.otherSpecialService && item.otherSpecialService.length) { item.special_service.push(item.otherSpecialService) }
          item.birth = item.birth_date
          delete item.cepValidate
        })
        this.form.infos = students

        this.$emit('changeElement', {
          direction: 'up',
          FormProjectHighStudents: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmail(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.infos[index].email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    },
    getAddressInfo(index) {
      const cep = String(this.form.infos[index].zipCode).replace(/\D/g, '')
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              if (this.allDataForm.FormProjectHighStudents) {
                this.form.infos[index].cepValidate = true
                return
              }
              this.form.infos[index].cepValidate = false
              this.errors.push('zipCode')
              this.textWarning = 'Informe um CEP válido'
            } else {
              if (data.uf) {
                this.form.infos[index].state = data.uf
              }
              if (data.localidade) {
                this.form.infos[index].city = data.localidade
              }
              if (data.bairro) {
                this.form.infos[index].district = data.bairro
              }
              if (data.logradouro) {
                this.form.infos[index].street = data.logradouro
              }
              this.form.infos[index].cepValidate = true
              this.resetErrors()
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
            }
          })
          .catch(() => {
            if (this.allDataForm.FormProjectHighStudents) {
              const projectStudent = this.allDataForm.FormProjectHighStudents.form.infos[index]

              this.form.infos[index].state = projectStudent.state
              this.form.infos[index].city = projectStudent.city
              this.form.infos[index].district = projectStudent.district
              this.form.infos[index].street = projectStudent.street
            }
            this.form.infos[index].cepValidate = false
            this.errors.push('zipCode')
            this.textWarning = 'Informe um CEP válido'
          })
      }
    },
    createInfo() {
      const max = this.quantityOptions.length
      let i = 0
      for (i; i < max; i += 1) {
        this.form.infos.push(
          {
            cepValidate: false,
            active: i === 0,
            full_name: i === 0 ? this.studentLogged.fullname : '',
            cpf: i === 0 ? this.studentLogged.cpf : '',
            sex: '',
            birth_date: '',
            isBetter: false,
            responsible_name: '',
            responsible_kinship: '',
            phone: '',
            email: i === 0 ? this.studentLogged.email : '',
            country: '',
            state: '',
            city: '',
            zipCode: '',
            district: '',
            street: '',
            house_number: '',
            complement: '',
            educational_level: '',
            english_level: '',
            scholarship_holder: '',
            which_program: '',
            other_program: '',
            attendOtherFair: '',
            fair_science: '',
            deficiency: [],
            special_service: [],
            t_shirt: '',
            t_shirt_size: 'no',
            is_continuity: false
          }
        )
      }
    },
    activeStudents() {
      let i = 0
      for (i; i < this.form.infos.length; i += 1) {
        if (i < this.form.quantityStudents) {
          this.form.infos[i].active = true
        } else {
          this.form.infos[i].active = false
        }
      }
    },
    checkValueInputOthersProgram(index) {
      if (this.form.infos[index].scholarship_holder !== 'yes') {
        this.form.infos[index].other_program = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 494px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__main {
    .form__item {
      .input__space {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .separator {
        border-bottom: 1px solid $primary;
        padding-bottom: 15px;
        margin-top: 30px;

        color: $primaryDark;
        font-family: Mulish;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
      }

      &--info {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        color: $primary;
        max-width: 520px;
      }

      .levels {
        display: flex;
        flex-direction: column;

        .title {
          color: #303030;
          display: flex;
          flex-direction: column;
          font-size: 16px !important;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
        }

        label {
          display: flex;
          color: $almostBlack;
          font-size: 16px;
          margin-bottom: 5px;

          input {
            zoom: 1.5;
            margin-right: 5px;
          }
        }

        .warning {
          color: #ff9800;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
