<template>
  <div class="box-container" :style="newStyle">
    <slot> </slot>
    <div v-if="components" :style="{ margin: margin || 0 }">
      <component :is="components[index]" :projectData="data"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContainerComponents',
  props: {
    components: {
      type: Array,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    data: {
      type: [Object, Array, String, Number],
      required: false
    },
    margin: {
      type: String,
      required: false
    },
    newStyle: {
      type: Object,
      required: false
    }
  }
}
</script>
<style lang="scss" scoped>
.box-container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.button-mercado-pago {
  display: flex;
  justify-content: flex-end;
  font-size: 1.2em;
  height: 3rem;
  margin: 0 5% 2% 0
}
</style>
