<template>
  <div class="wrapper">
    <TitleStatusPair
      :content ='title'
      :statusText ='statusText'
      :theme ='theme'
    />

    <ImageInformationHorizontal
      :image ='slugs[slug].image'
      :cause ='slugs[slug].cause || null'
      :fair_name='slugs[slug].fair_name || null'
    />

  </div>
</template>

<script>
import { TitleStatusPair, ImageInformationHorizontal } from '@/components/molecules'
import { statusDictionary } from '@/utils/dictionaries'

export default {
  name: 'RegistrationSituation',
  components: { TitleStatusPair, ImageInformationHorizontal },
  data() {
    return {
      slugs: {
        ...statusDictionary,
        'revisao-negada': {
          ...statusDictionary['revisao-negada'],
          cause: this.cause
        },
        reprovado: {
          ...statusDictionary.reprovado,
          cause: this.cause
        },
        'aguardando-aceite-feira': {
          ...statusDictionary['aguardando-aceite-feira'],
          fair_name: this.fair_name
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    statusText: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      required: true
    },
    cause: String,
    fair_name: String
  }
}
</script>

<style lang="scss" scoped>
.wrappper {
  width: 35%;
  max-width: 308px;
  padding: 14px 10px;
  flex-direction: column;
  align-items: center;
}
</style>
