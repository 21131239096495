<template>
  <div  @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <header-modal
        @closeClicked="$emit('closeModal')"
        title="Editar situação do projeto"
        class="title mb-3"
      />
      <div class="subtitle mb-5">{{title}}</div>
      <input-switch
        class="switch"
        label="Projeto compareu à Mostratec?"
        id="switch"
        :onChange="() => attended = !attended"
        :defaultState="attended"
        :disable="false"
      />
      <input-default
        :class="[
          {
            disabled: disable,
            warning: checkErrorIfContains('averageScore')
          },
          'average',
          'mb-5'
          ]"
        label="Nota média da banca"
        type="text"
        placeholder="Insira a nota média da banca"
        id="Average"
        v-model="fields.averageScore"
        :disabled="disable"
        @input="resetWarnings"
      />
      <input-default
        :class="[
          {
            disabled: disable,
            warning: checkErrorIfContains('classification')
          },
        'position',
        'mb-5'
        ]"
        label="Classificação"
        type="number"
        placeholder="Insira a classificação"
        id="Position"
        v-model="fields.classification"
        :disabled="disable"
        @input="resetWarnings"
      />
      <input-text-area
        :class="[
        {
          disabled: disable,
          warning: checkErrorIfContains('awarding')
        },
        'awarding',
        'mb-7']"
        label="Premiação"
        placeholder="Insira como os estudantes serão premiados"
        :textMax="500"
        :allText="fields.awarding"
        @input="fields.awarding=$event"
        :disabled="disable"
      />
      <div class="buttonsContainer">
        <button-default
          class="button__cancel"
          :onClick="() => $emit('closeModal')"
        >{{$t('noCancel')}}</button-default>
        <button-default
          class="button"
          :onClick="checkReview"
        >{{$t('yesContinue')}}</button-default>
      </div>
    </div>
    <feedback-warning
      v-if="errors.length || displayPopUp"
      :text="textWarning"
      :onClick="resetWarnings"
      :color="feedbackColor"
    />
  </div>
</template>

<script>
import { HeaderModal } from '@/components/organisms/Headers'
import {
  InputSwitch,
  TextInfo,
  InputDefault,
  InputTextArea,
  ButtonDefault,
  FeedbackWarning
} from '@/components/atoms'

export default {
  name: 'ModalDefault',
  props: [
    'modal',
    'projectData'
  ],
  components: {
    HeaderModal,
    InputDefault,
    InputSwitch,
    TextInfo,
    InputTextArea,
    ButtonDefault,
    FeedbackWarning
  },
  data() {
    return {
      fields: {
        averageScore: null,
        classification: null,
        awarding: ''
      },
      title: null,
      attended: true,
      disable: null,
      feedbackColor: '#ff9800',
      displayPopUp: false,
      errors: [],
      textWarning: 'Todos os campos devem ser preenchidos.'
    }
  },
  mounted() {
    if (this.projectData && Object.keys(this.projectData).length) this.loadInfos()
  },
  watch: {
    projectData() {
      this.loadInfos()
    },
    'fields.awarding': function () {
      this.resetWarnings()
    },
    attended() {
      this.disable = !this.attended
    },
    disable() {
      if (this.disable) {
        this.disableReview()
      }
    }
  },
  methods: {
    // toggler(...itens) {
    //   itens.forEach(item => {
    //     this[item] = !this[item]
    //   })
    // },
    loadInfos() {
      this.attended = this.projectData.attended,
      this.disable = !this.attended
      this.title = this.projectData.project_title
      this.fields = {
        ...this.fields,
        averageScore: this.projectData.bench_score,
        classification: this.projectData.classification,
        awarding: this.projectData.awarding_description
      }
    },
    disableReview() {
      this.fields = {
        ...this.fields,
        averageScore: null,
        classification: null,
        awarding: null
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetWarnings() {
      this.errors = []
      if (this.displayPopUp) this.displayPopUp = true
    },
    checkReview() {
      this.displayPopUp = false
      if (!this.attended) return this.saveReview()
      for (const field in this.fields) {
        if (!this.fields[field]) {
          if (field !== 'awarding') {
            this.errors.push(field)
          }
        }
      }
      if (this.errors.length) {
        this.textWarning = 'Todos os campos devem ser preenchidos.'
        this.feedbackColor = '#ff9800'
        return false
      }
      return this.saveReview()
    },
    async saveReview() {
      try {
        const newData = {
          // ...this.projectData,
          attended: this.attended,
          bench_score: parseFloat(this.fields.averageScore),
          classification: Number(this.fields.classification),
          awarding_description: this.fields.awarding
        }
        const { status } = await this.$http.patch(`/project/${ this.projectData.id }`, newData)
        if (status === 200) {
          this.textWarning = 'Edição salva com sucesso!'
          this.feedbackColor = '#33B850'
          this.displayPopUp = true
          // this.$emit('closeModal')
          return
        }
        this.textWarning = 'Ocorreu um erro ao salvar as alterações.'
        this.feedbackColor = '#ff9800'
        this.displayPopUp = true
        return
      } catch (error) {
        console.error(error)
        return
      } finally {
        setTimeout(() => {
          this.displayPopUp = false
        }, 3000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  @include d-flex-center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 3;
  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-height: 221px;
    padding: 20px;
    width: 649px;
    font-family: Mulish, sans-serif;
    .subtitle{
      color: $primary;
      font-size: 15px;
    }
    .switch{
      margin-left: -2.75%;
    }
    .awarding{
      width: 400px;
    }
    .warning {
      color: $attention;
      border-bottom-color: $attention;
    }
    .disabled {
      filter: grayscale(100%) saturate(0%) opacity(70%);
      cursor: alias;
      color: #686868;
    }
    .buttonsContainer {
      display: flex;
      justify-content: space-between;
      .button {
        line-height: 20, 08px;
        &__cancel {
          color: $primary;
          background-color: white;
        }
      }
    }
  }
}
</style>
