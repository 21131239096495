<template>
  <div class="info-wrapper">
    <div class="info-box">
      <date-information
        :title="this.init"
        :content="this.initDate"
      />
      <date-information
        :title="this.last"
        :content="this.lastDate"
      />
    </div>
  </div>
</template>

<script>
import DateInformation from '@/components/molecules/Content/DateInformation.vue'

export default {
  components: { DateInformation },
  name: 'SolicitationDates',
  props: {
    init: {
      type: String,
      required: true
    },
    initDate: {
      type: String,
      required: true
    },
    last: {
      type: String,
      required: true
    },
    lastDate: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.info-wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  width: fit-content;
  align-items: center;
}

.info-box {
  min-width: 284px;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

</style>
