<template>
  <div class="home">
    <QRCodeTag :params="params" :infoTag="infoTag" />
  </div>
</template>

<script>
import QRCodeTag from '@/components/organisms/Cards/QRCodeTag'

export default {
  name: 'QRCodeTagPanel',
  components: {
    QRCodeTag
  },
  data() {
    return {
      params: {},
      infoTag: []
    }
  },
  mounted() {
    this.getParams()
    if (this.params.id) {
      this.getInfo()
    } else {
      this.getAllInfos()
    }
  },
  methods: {
    getParams() {
      const { id, role } = this.$route.params
      this.params = { id, role }
    },
    async getInfo() {
      const path = this.params.role === 'estudante' ? 'student' : 'involved'
      const {
        data: { message: response }
      } = await this.$http.get(`/${ path }/${ this.params.id }`)
      const fullName = response[0].full_name.split(' ')
      const newName = `${ fullName.shift() } ${ fullName.pop() ?? '' }`

      const info = {
        name: newName,
        city: response[0].city,
        state: response[0].state,
        country: response[0].country
      }
      this.infoTag.push(info)
    },
    async getAllInfos() {
      const {
        data: { message: responseStudents }
      } = await this.$http.get('/all-students')
      const {
        data: { message: responseInvolveds }
      } = await this.$http.get('/all-involveds')
      const arrayStudents = responseStudents.map((student) => {
        if (student.fullName) {
          const fullNameArray = student.fullName.split(' ')
          const newFullName = fullNameArray.filter((name) => name !== '')
          const newName = `${ newFullName.shift() } ${ newFullName.pop() ?? '' }`
          student.fullName = newName
        }
        return student
      })
      const arrayInvolveds = responseInvolveds.filter((involved) => involved.type === 'orientador' || involved.type === 'coorientador')
      arrayInvolveds.map((involved) => {
        if (involved.fullName) {
          const fullNameArray = involved.fullName.split(' ')
          const newFullName = fullNameArray.filter((name) => name !== '')
          const newName = `${ newFullName.shift() } ${ newFullName.pop() ?? '' }`
          involved.fullName = newName
        }
        return false
      })
      this.infoTag = [...arrayStudents, ...arrayInvolveds].sort((x, y) => x.booth - y.booth)
    }
  }
}
</script>
<style scoped lang="scss">
.home {
  max-width: 340px;
  width: 100%;
  margin: 0 auto;
}
</style>
