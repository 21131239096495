<template>
  <div class="card-vacancies">
    <div class="header" :class="{'border': open}">
      <span class="type">{{cText}}</span>
      <div class="vacancies-action">
        <div class="vacancies">
          {{$t('vacanciesListed')}}:
          {{projectsInfo.length ? cVacancies.projectsAccepted : '0'}}
          /
          {{ (cVacancies.quantity_granted || 0) + (cVacancies.quantity_exempt || 0) }}
        </div>
        <div>
          <img
            class="action"
            :class="{'rotate': open}"
            @click="open=!open"
            :src="require('@/assets/openClose.png')"
            alt="toggle"
          />
        </div>
      </div>
    </div>
    <div class="body" v-if="open">
      <div class="vacancies-request">
        <div class="vacancies-quantity">
          <div class="title-card">{{$t('vacanciesRequested')}}</div>
          <div class="quantity">{{cVacancies.quantity_registered || 0}}</div>
        </div>
        <div class="vacancies-quantity">
          <div class="title-card">{{$t('vacanciesGranted')}}</div>
          <div class="quantity">{{cVacancies.quantity_granted || 0}}</div>
        </div>
        <!-- div class="vacancies-quantity">
          <div class="title-card">{{$t('vacanciesExempt')}}</div>
          <div class="quantity">{{cVacancies.quantity_exempt || 0}}</div>
        </div -->
      </div>
      <!-- div class="areas" v-if="vacancies">
        <div class="areas-list"  v-if="vacancies.level !== 'grade'">
          <template v-if="cAreas.length > 0">
            <span class="title-card">{{$t('researchArea')}}:</span>
          </template>
          <template v-if="cAreas.length == 0">
            <span>{{$t('noResearchAreasProvided')}}</span>
          </template>
          <template v-for="(item, index) in cAreas">
            <span class="tag" :key="index">{{item}}</span>
          </template>
        </div>
        <template v-else>
          <span>{{$t('thereAreNoResearchAvaliable')}}</span>
        </template>
      </div -->
      <!-- hr class="separator" -->
      <div class="projects" v-if="projectsInfo.length" style="border-top:1px solid #AFAEAE;">
        <span class="type">{{ $t('projectRegistrationSolicitations') }}</span>
        <div class="projects-list">
          <card-vacancies-project
            v-for="(item, index) in projectsInfo"
            :key="index"
            :projectInfo="item"
            :statusList="statusList"
            class="card_project"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardVacanciesProject from '@/components/organisms/Cards/CardVacanciesProject'

export default {
  name: 'CardVacancies',
  components: { CardVacanciesProject },
  data() {
    return {
      projectsInfo: [],
      statusList: [],
      fairId: null,
      open: false,
      areas: []
    }
  },
  props: {
    vacancies: Object,
    text: String
  },
  computed: {
    cVacancies() {
      if (this.vacancies) {
        return this.vacancies
      }
      return ''
    },
    cText() {
      if (this.text) {
        return this.text
      }
      return ''
    },
    cAreas() {
      if (this.vacancies) {
        if (this.vacancies.biologia) this.areas.push('Biologia')
        if (this.vacancies.bioquimica_e_quimica) {
          this.areas.push(
            'Bioquímica e Química'
          )
        }
        if (this.vacancies.ciencias) {
          this.areas.push(
            'Ciências (ambientais, animais e das plantas)'
          )
        }
        if (this.vacancies.ciencias_da_computacao) {
          this.areas.push(
            'Ciências da Computação'
          )
        }
        if (this.vacancies.ciencias_planetarias_e_terrestres) {
          this.areas.push(
            'Ciências Planetárias e terrestres, Matemática e Física'
          )
        }
        if (this.vacancies.ciencias_sociais) {
          this.areas.push(
            'Ciências Sociais, Comportamento e Arte'
          )
        }
        if (this.vacancies.engenharia) {
          this.areas.push(
            'Engenharia (Elétrica, Eletrônica, Mecânica)'
          )
        }
        if (this.vacancies.engenharia_e_materiais) {
          this.areas.push(
            'Engenharia e Materiais'
          )
        }
        if (this.vacancies.gerenciamento_do_meio_ambiente) {
          this.areas.push(
            'Gerenciamento do Meio Ambiente'
          )
        }
        if (this.vacancies.medicina_e_saude) {
          this.areas.push(
            'Medicina e Saúde'
          )
        }
        if (this.vacancies.other && this.vacancies.other.length) {
          this.areas.push(
            this.vacancies.other
          )
        }

        return this.areas
      }
      return this.areas
    }
  },
  async mounted() {
    this.projectsInfo = await this.getInfos()
    this.statusList = await this.getStatus()
  },
  methods: {
    async getInfos() {
      const role = localStorage.getItem('role')
      const affiliationRequestId = localStorage.getItem('affiliationRequestId')

      if (role === 'admin') {
        const { data: fairInfoId } = await this.$http.get(`/fair/${ affiliationRequestId }`)
        this.fairId = fairInfoId.id
        const { data: { data: { data } } } = await this.$http.get(`/project?fairId=${ this.fairId }&level=${ this.vacancies.level }&limit=65536`)
        return data
      }
      const { data: fairInfo } = await this.$http.get('/fair')
      this.fairId = fairInfo[0].id
      const { data: { data: { data } } } = await this.$http.get(`/project?fairId=${ this.fairId }&level=${ this.vacancies.level }&limit=65536`)

      return data
    },
    async getStatus() {
      const { data: { data } } = await this.$http.get('/status/project?statusType=projeto')
      return data
    }
  }
}
</script>
<style lang="scss" scoped>
.type {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.separator {
  color: #AFAEAE;
  margin: 20px 0 30px 0;
}
.projects {
  display: flex;
  flex-direction: column;
  margin: 10px;

  .projects-list {
    margin-top: 16px
  }
}
.card-vacancies {
  font-family: $font-stack;
  position: relative;
  margin-top: 36px;
  padding: 21px 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  .header {
    display: flex;
    justify-content: space-between;
    &.border {
      border-bottom: 0.5px solid #AFAEAE;
      padding-bottom: 10px;
    }
    .vacancies-action {
      display: flex;
      justify-content: space-between;
      .action {
        padding: 10px 0;
        cursor: pointer;
        margin-left: 41px;
        transition: .2s ease-in-out;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  .body {
    .vacancies-request {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 5%;
      margin-top: 14px;
      .vacancies-quantity {
        display: flex;
        flex-direction: column;
        flex: 1;
        .title-card {
          flex: 1;
          font-family: "Mulish", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: $almostBlack;
        }
        .quantity {
          display: flex;
          align-items: center;
          padding: 0 15px;
          border-radius: 10px;
          height: 35px;
          border: $borderInputCard;
        }
      }
    }
    .areas {
      margin-top: 14px;
      .areas-list{
        display: flex;
        flex-wrap: wrap;
        .title-card {
          font-family: "Mulish", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: $almostBlack;
          margin-right: 5px;
        }
        .tag {
          display: flex;
          align-items: center;
          padding: 0px 16px;
          height: 28px;
          margin: 0 5px 5px 0;
          font-size: 14px;
          font-family: "Mulish", sans-serif;
          background-color: $primary;
          color: $white;
          font-weight: 700;
          border-radius: 49px;
        }
      }
    }
    .projects{
      .card_project{
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 800px) {
      .vacancies-request {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 0;
        & > .vacancies-quantity {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
