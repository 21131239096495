<template>
  <div>
    <div class="box-indicators">
      <div class="fairParticipants">
        <DescriptionNumberInfo
          :title="fairParticipants"
          :infos="fairParticipantsInfos"
        />
      </div>
      <DescriptionNumberInfo
        class="abrangency"
        title="Abrangência"
        :infos="abrangency"
      />
      <LineDefault
      color='gray'
      />
      <div class="align_tables">
          <DescriptionNumberInfo
          class="abrangency"
          title="Quantidade de escolas participantes"
          :infos="infoSchools"
        />
        <DescriptionNumberInfo
          class="abrangency"
          title="Quantidade de estudantes que participaram da feira"
          :infos="studentsInfo"
        />
      </div>
      <LineDefault
      color='gray'
      />
      <div class="align_tables">
        <DescriptionNumberInfo
          :title="title1"
          :infos="infos"
        />
        <DescriptionNumberInfo
          :title="title2"
          :infos="secondInfos"
          :border="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { DescriptionNumberPair } from '@/components/molecules'
import { DescriptionNumberInfo } from '@/components/organisms'
import { LineDefault } from '@/components/atoms/Lines'
import { InputSelect } from '@/components/atoms'

export default {
  name: 'GeneralVision',
  components: {
    DescriptionNumberPair,
    DescriptionNumberInfo,
    LineDefault,
    InputSelect
  },
  data() {
    return {
      infos: [],
      secondInfos: [],
      fairParticipantsInfos: [],
      abrangency: [],
      infoSchools: [],
      studentsInfo: [],
      title1: 'Quantidade de participantes do Ensino Fundamental',
      title2: 'Quantidade de participantes do Ensino Médio / Técnico',
      fairParticipants: 'Quantidade de participantes na feira:',
      fairEdition: '',
      filtersEdition: [],
      filterEditionOptions: []
    }
  },
  props: {
    projectData: {
      type: Object,
      required: true
    }
  },
  watch: {
    projectData() {
      this.createInfos()
    }
  },
  methods: {
    createInfos() {
      this.clear()

      this.infoSchools.push(
        { text: 'Total', value: this.projectData.total_schools || '--', color: '#1B778B' },
        { text: 'Públicas', value: this.projectData.public_schools || '--', color: '#319CB4' },
        { text: 'Privadas', value: this.projectData.private_schools || '--', color: '#1B778B' }
      )
      this.studentsInfo.push(
        { text: 'Total', value: this.projectData.total_students || '--', color: '#1B778B' },
        { text: 'Ed. Infantil', value: this.projectData.grade_students || '--', color: '#05AB5B' },
        { text: 'Ens. Fundamental', value: this.projectData.middle_students || '--', color: '#319CB4' },
        { text: 'Ens. Médio / Técnico', value: this.projectData.high_students || '--', color: '#1B778B' }

      )
      this.fairParticipantsInfos.push(
        { text: 'Feiras participantes', value: this.projectData.total_fairs || '--', color: '#1B778B' },
        { text: 'Total de projetos', value: this.projectData.total_projects || '--', color: '#1B778B' },
        { text: 'Total de professores', value: this.projectData.total_teachers || '--', color: '#1B778B' }
      )
      this.infos.push(
        { text: '1º ano', value: this.projectData.middleStudentsQuantity?.first_grade || '--', color: '#319CB4' },
        { text: '2º ano', value: this.projectData.middleStudentsQuantity?.second_grade || '--', color: '#319CB4' },
        { text: '3º ano', value: this.projectData.middleStudentsQuantity?.third_grade || '--', color: '#319CB4' },
        { text: '4º ano', value: this.projectData.middleStudentsQuantity?.fourth_grade || '--', color: '#319CB4' },
        { text: '5º ano', value: this.projectData.middleStudentsQuantity?.fifth_grade || '--', color: '#319CB4' },
        { text: '6º ano', value: this.projectData.middleStudentsQuantity?.sixth_grade || '--', color: '#319CB4' },
        { text: '7º ano', value: this.projectData.middleStudentsQuantity?.seventh_grade || '--', color: '#319CB4' },
        { text: '8º ano', value: this.projectData.middleStudentsQuantity?.eighth_grade || '--', color: '#319CB4' },
        { text: '9º ano', value: this.projectData.middleStudentsQuantity?.nineth_grade || '--', color: '#319CB4' },
        { text: 'EJA', value: this.projectData.middleStudentsQuantity?.eja || '--', color: '#319CB4' }
      )
      this.secondInfos.push(
        { text: '1º ano', value: this.projectData.highStudentsQuantity?.first_grade_high || '--', color: '#1B778B' },
        { text: '2º ano', value: this.projectData.highStudentsQuantity?.second_grade_high || '--', color: '#1B778B' },
        { text: '3º ano', value: this.projectData.highStudentsQuantity?.third_grade_high || '--', color: '#1B778B' },
        { text: '4º ano', value: this.projectData.highStudentsQuantity?.fourth_grade_high || '--', color: '#1B778B' },
        { text: 'Estágio', value: this.projectData.highStudentsQuantity?.internship || '--', color: '#1B778B' }
      )
      this.abrangency.push(
        { text: 'Cidades participantes', value: this.projectData.num_cities || '--', color: '#1B778B' },
        { text: 'Estados participantes', value: this.projectData.num_states || '--', color: '#1B778B' },
        { text: 'Países participantes', value: this.projectData.num_countries || '--', color: '#1B778B' }
      )
    },
    clear() {
      this.infos = []
      this.secondInfos = []
      this.fairParticipantsInfos = []
      this.abrangency = []
      this.infoSchools = []
      this.studentsInfo = []
    }
  }
}
</script>

<style lang="scss" scoped>
  .align_tables {
    position: relative;
    display: flex;
    justify-content: space-between;
    &:after {
      position: absolute;
      content: '';
      right: 50%;
      height: 88%;
      width: 2px;
      background-color: #CDCBCB;
    }
  }

  .vertical-line {
    border-left: 1px solid #CDCBCB;
  }

  .fairParticipants {
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  .abrangency {
    margin-left: 34px;
    margin-top: 5%;
    margin-bottom: 3%;
  }

</style>
