<template>
  <p>
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'Paragraph'
}
</script>

<style>

</style>
