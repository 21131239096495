<template>
  <div class="breadcrumb">
    <div class="submenu">
      <span>
        {{$t('editionRegistration')}}
      </span>
    </div>
    <div class="items">
      <span :class="{active: active == 'allEditions'}" @click="menuSelected('allEditions')">{{$t('allEditions')}}</span>
      <span :class="{active: active == 'general'}" @click="menuSelected('general')">{{$t('generalData')}}</span>
      <span :class="{active: active == 'vacancies'}" @click="menuSelected('vacancies')">{{$t('vacancies')}}</span>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'BreadcrumbEditionDetails',
  data: () => ({
    active: 'allEditions'
  }),
  computed: {
    ...mapGetters({
      editionId: 'editionId',
      editionVacanciesSet: 'editionVacanciesSet'
    })
  },
  watch: {
    editionId() {
      this.active = 'vacancies'
      this.$emit('menuSelected', 'vacancies')
    },
    editionVacanciesSet() {
      this.active = 'allEditions'
      this.$emit('menuSelected', 'allEditions')
    }
  },
  methods: {
    menuSelected(tab) {
      if (tab === 'vacancies') {
        const edition = this.$store.getters.editionId
        if (edition != null) {
          this.active = tab
          this.$emit('menuSelected', tab)
        } else alert('Defina a feira vinculada antes das vagas!')
      } else {
        this.active = tab
        this.$emit('menuSelected', tab)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  align-items: center;
  background-color: $white;
  border-radius: 0px 29px 29px 0px;
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 20px;
  padding: 10px 20px;

  @media screen and (max-width: 320px) {
    flex-direction: column;
  }

  .submenu {
    @include d-flex-center;

    @media screen and (min-width: 321px) {
      border-right: 0.7px solid #1B778B;
      padding-right: 20px;
    }

    span {
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $primary;
    }
  }

  .items {
    @include d-flex-center;

    @media screen and (min-width: 321px) {
      padding-left: 20px;
    }

    span {
      color: $primary;
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      padding-right: 20px;

      &:hover {
        opacity: 0.7;
      }
    }

    .active {
      font-weight: 600
    }
  }
}
</style>
