<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{ $t("projectData") }}</p>
        <text-warning :text="'considerAllFieldObligatory'" />
      </div>
      <input-default
        :class="[{ warning: cProjectName }, 'mb-7']"
        label="projectName"
        id="projectName"
        placeholder="enterTheProjectName"
        type="text"
        max="250"
        v-model="form.projectName"
        @change="resetErrors"
        :width="800"
      />
      <input-text-area
        :class="[{ warning: cSummary }, 'mb-7']"
        label="summary"
        id="summary"
        :allText="form.summary"
        placeholder="enterTheSummary"
        @input="form.summary = $event"
        @change="resetErrors"
        :textMax="4000"
        info=youCanEditTheAbstract
      />
      <input-text-area
        :class="[{ warning: cTheme }, 'mb-7']"
        label="theme"
        id="theme"
        :allText="form.theme"
        placeholder="enterTheThema"
        @input="form.theme = $event"
        @change="resetErrors"
        :textMax="2000"
        :info="$t('ThemeTextInfo')"
      />

      <input-text-area
        :class="[{ warning: cObjective }, 'mb-7']"
        label=ObjectivesLabel
        id="objective"
        placeholder=ObjectivesPlaceholder
        :allText="form.objective"
        @input="form.objective = $event"
        @change="resetErrors"
        :textMax="2000"
        :info="$t('ObjectivesTextInfo')"
      />

      <input-text-area
        :class="[{ warning: cMethodology }, 'mb-7']"
        label=MethodologyLabel
        id="methodology"
        placeholder=MethodologyPlaceholder
        :allText="form.methodology"
        @input="form.methodology = $event"
        @change="resetErrors"
        :textMax="2000"
        :info="$t('MethodologyTextInfo_parOne') + $t('MethodologyTextInfo_parTwo')"
      />

      <input-text-area
        :class="[{ warning: cJustification }, 'mb-7']"
        label="JustificationLabel"
        id="justification"
        placeholder=JustificationPlaceholder
        :allText="form.justification"
        @input="form.justification = $event"
        @change="resetErrors"
        :textMax="4000"
        :info="$t('JustificationTextInfo_parTwo')"
      />
      <input-text-area
        :class="[{ warning: cResultsConclusion }, 'mb-7']"
        label="ResultsLabel"
        id="resultsConclusion"
        placeholder=resultsAndConclusionsPlaceholder
        :allText="form.resultsConclusion"
        @input="form.resultsConclusion = $event"
        @change="resetErrors"
        :textMax="4000"
        info=resultsAndConclusionsTextInfo
      />
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{ $t("back") }}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndAdvance')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
// TO-DO
// PRECISA FAZER A REGRA DE ENTRADA DE UMA OUTRA AREA QUE N ESTEJA NA LISTA
import {
  InputAutoComplete,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  InputDefault,
  InputCheckbox,
  InputTextArea
} from '@/components/atoms'

export default {
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  name: 'FormProjectChildishData',
  components: {
    InputAutoComplete,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    InputDefault,
    InputCheckbox,
    InputTextArea
  },
  data() {
    return {
      form: {
        projectName: '',
        summary: '',
        theme: '',
        justification: '',
        objective: '',
        methodology: '',
        resultsConclusion: ''
      },
      firstLoader: false,
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory'
    }
  },
  // watch: {
  //   allDataForm() {
  //     if (this.allDataForm["FormProjectData"]) {
  //       this.form = this.allDataForm["FormProjectData"];
  //     }
  //   }
  // },
  computed: {
    cProjectName() {
      return this.firstLoader && !this.form.projectName
    },
    cSummary() {
      return this.firstLoader && !this.form.summary
    },
    cTheme() {
      return this.firstLoader && !this.form.theme
    },
    cJustification() {
      return this.firstLoader && !this.form.justification
    },
    cObjective() {
      return this.firstLoader && !this.form.objective
    },
    cMethodology() {
      return this.firstLoader && !this.form.methodology
    },
    cResultsConclusion() {
      return this.firstLoader && !this.form.resultsConclusion
    }
  },
  mounted() {
    if (this.allDataForm.FormProjectChildishData) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectChildishData
      }
    }
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormProjectChildishData) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectChildishData
        }
      }
    }
  },
  methods: {
    async temporarySave() {
      this.allDataForm.FormProjectData = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async checkForm() {
      this.firstLoader = true
      this.resetErrors()
      // for (const key in this.form) {
      //   if (!this.form[key]) {
      //     this.errors.push(key);
      //   }
      // }
      if (!this.errors.length) {
        if (this.cProjectName) {
          // this.textWarning = 'projectName';
          this.errors.push('projectName')
          return false
        }
        if (this.cSummary) {
          // this.textWarning = 'summary';
          this.errors.push('summary')
          return false
        }
        if (this.cTheme) {
          // this.textWarning = 'theme';
          this.errors.push('theme')
          return false
        }
        if (this.cJustification) {
          // this.textWarning = 'justification';
          this.errors.push('justification')
          return false
        }
        if (this.cObjective) {
          // this.textWarning = 'objective';
          this.errors.push('objective')
          return false
        }
        if (this.cMethodology) {
          // this.textWarning = 'methodology';
          this.errors.push('methodology')
          return false
        }
        if (this.cResultsConclusion) {
          // this.textWarning = 'resultsConclusion';
          this.errors.push('resultsConclusion')
          return false
        }
        await this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      this.allDataForm.FormProjectChildishData = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.$emit('changeElement', {
          direction: 'up',
          FormProjectChildishData: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
        return false
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    back() {
      this.$emit('changeElement', {
        direction: 'down'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
.disabled {
  background-color: #d4d4d4;
}
.input__checkbox {
  display: flex;
  flex-direction: column;

  input[type="checkbox"] {
    zoom: 1.8;
  }
  .center {
    display: flex;
    align-items: center;
    margin: 11px 0;
  }

  span {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 10px;
    max-width: 728px;
  }
}

.warning {
  span {
    color: $attention;
    border-bottom-color: $attention;
  }
}
.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: $almostBlack;
  &.warning {
    color: $attention !important;
  }
}
</style>
