var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[{ disabled: _vm.disabled }, 'button'],style:({
    background: _vm.color,
    maxWidth: _vm.large ? '336px' : '235px',
    height: _vm.height,
    fontSize: _vm.letterSize,
    width: _vm.width,
    margin: _vm.margin,
    color: _vm.letterColor
  }),attrs:{"type":"submit","disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.onClick.apply(null, arguments)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }