<template>
  <header>
    <div class="header">
      <img class="logo" src="@/assets/logo.png" alt="Logo Mostratec">
      <div class="menu">
        <div class="language border">
          <div class="menu__item" @click="openSelectLang= !openSelectLang">
            <icon-default class="header__icon" url="language.png" alt="Ícone de caneta da tela pré-cadastro"/>
            <span>{{langs[this.$store.getters.lang]}}</span>
          </div>
          <template v-if="openSelectLang">
            <LangSelect />
          </template>
        </div>
        <div class="border">
            <a class="menu__item" v-bind:href="$t('regulamentLink')" target="_blank">
            <icon-default class="header__icon" url="paper-write.png" alt="Ícone de caneta da tela pré-cadastro"/>
            <span>{{$t('regulament')}}</span>
          </a>
        </div>
        <div class="border">
          <router-link class="menu__item" :to="{name: 'Login'}" exact>
            <icon-default class="header__icon" url="profile.png" alt="Ícone de caneta da tela pré-cadastro"/>
            <span>{{$t('enter')}}</span>
          </router-link>
        </div>
      </div>
    </div>

    <keep-alive>
      <component @menuSelected="changeTabs" :is="path" />
    </keep-alive>

  </header>
</template>

<script>
import { IconDefault, EditionBreadcrumb, ListBreadcrumb } from '@/components/atoms'
import LangSelect from '@/components/organisms/Headers/LangSelect'

export default {
  name: 'HeaderHome',
  data() {
    return {
      openSelectLang: false,
      langs: {
        pt: 'pt-BR',
        en: 'en-US',
        es: 'es-ES'
      }
    }
  },
  components: {
    IconDefault, EditionBreadcrumb, ListBreadcrumb, LangSelect
  },
  computed: {
    path() {
      const route = this.$route.path.replace('/', '')
      if (route == 'list') return 'ListBreadcrumb'
      if (route == 'edition') return 'EditionBreadcrumb'
    }
  },
  methods: {
    changeTabs(e) {
      this.$emit('menuSelected', e)
    }

  }
}
</script>

<style lang="scss" scoped>
header {
  width: 100%;
}

.header {
  @include d-flex-between;
  flex-wrap: wrap;
  background: #FDFDFD;
  border-radius: 29px;
  padding: 20px 40px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1017px) {
    justify-content: center;
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .menu {
    @include d-flex-center;
    flex-wrap: wrap;
    z-index: 1;
    .border {
      &:not(:last-of-type) {
        @media screen and (min-width: 1015px) {
          border-right: 0.7px solid $primary;
        }
      }
    }

    @media screen and (max-width: 1017px) {
      margin-top: 5px;
    }

    &__item {
      @include d-flex-center;
      cursor: pointer;
      padding-right: 15px;
      padding-left: 15px;
      position: relative;

      @media screen and (max-width: 1017px) {
        padding-bottom: 5px;
      }

      &:hover {
        opacity: 0.7;
      }

      ::v-deep .router-link-active {
        font-weight: 600;
      }

      span {
        color: $primary;
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
  .language {
    position: relative;
  }
}
</style>
