<template>
  <header :style="{width: headerTemplate === 'default' ? '100%' : '475px'}" class="header">
    <div class="header__title">
      <title-default :class="[{header__textLeft: left},{header__text: !left}]">{{$t(title)}}</title-default>
    </div>
    <button-close class="header__icon-close" :onClick="closeClicked"></button-close>
  </header>
</template>

<script>
import ButtonClose from '@/components/atoms/Buttons/ButtonClose'
import TitleDefault from '@/components/atoms/Texts/TitleDefault'

export default {
  name: 'HeaderModal',
  props: {
    title: {
      type: String,
      require: true
    },
    headerTemplate: {
      type: String,
      require: false,
      default: 'default'
    },
    left: Boolean
  },
  components: { ButtonClose, TitleDefault },
  data() {
    return {}
  },
  methods: {
    closeClicked() {
      this.$emit('closeClicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  @include d-flex-between;
  position: relative;

  &__title {
    @include d-flex-center;
  }

  &__text {
    @include H1;
    color: $primaryDark;
    line-height: 30px;
    letter-spacing: 1px;
    transform: translateY(10px);
    max-width: 100%;
  }

  &__textLeft {
    @include H1;
    color: #0E5671;
    font-size: 1.4rem;
    line-height: 30px;
    margin-left: 20px;
    letter-spacing: 1px;
    transform: translateY(10px);
  }

  &__icon-close {
    position: absolute;
    right: 10px;
    top: 0px;
  }
}
</style>
