<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('aboutYourFair')}}</span>
      </div>
      <div class="form__info">
        <span>{{$t('specifyTheFollowingData')}}</span>
        <!-- text-warning :text="`vacanciesNumberRequested`" />
        <text-warning :text="`ifYourFairDoesNotIntendCoverAllTypes`" / -->
      </div>

      <main class="form__main mt-4">
        <div class="form__item mb-5">
          <input-checkbox-title class="mb-7" label= 'childEducationCompleted2024' v-model="activeChild" id="infantil" @change="resetErrors" />

          <div v-if="activeChild">
            <input-masked label= 'howManyProjects'          :class="[{warning: checkErrorIfContains('projectsQuantityChild')}, 'input__space']" placeholder="projectsNumberPlaceholder"  type="number" v-model="formChild.projectsQuantityChild" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'schoolsNumber'          :class="[{warning: checkErrorIfContains('schoolsQuantityChild')}, 'input__space']" placeholder="schoolsNumberPlaceholder"  type="number" v-model="formChild.schoolsQuantityChild" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'teachersNumber'          :class="[{warning: checkErrorIfContains('teachersQuantityChild')}, 'input__space']" placeholder="teachersNumberPlaceholder"  type="number" v-model="formChild.teachersQuantityChild" :mask="['####']"  @input="resetErrors" />
            <p class="pupils__total">{{$t('totalStudents')}}</p>
            <input-masked label= 'male'          :class="[{warning: checkErrorIfContains('maleChild')}, 'input__space']" placeholder="studentsNumberPlaceholder"  type="number" v-model="formChild.maleChild" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'female'          :class="[{warning: checkErrorIfContains('femaleChild')}, 'input__space']" placeholder="studentsNumberPlaceholder"  type="number" v-model="formChild.femaleChild" :mask="['####']"  @input="resetErrors" />
          </div>
        </div>

        <div class="form__item mb-5">
          <input-checkbox-title class="mb-7" label= 'elementarySchoolCompleted' v-model="activeElementary" id="fundamental" @change="resetErrors" />

          <div v-if="activeElementary">
            <input-masked label= 'howManyProjects'          :class="[{warning: checkErrorIfContains('projectsQuantityElementary')}, 'input__space']" placeholder="projectsNumberPlaceholder"  type="number" v-model="formElementary.projectsQuantityElementary" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'schoolsNumber'          :class="[{warning: checkErrorIfContains('schoolsQuantityElementary')}, 'input__space']" placeholder="schoolsNumberPlaceholder"  type="number" v-model="formElementary.schoolsQuantityElementary" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'teachersNumber'          :class="[{warning: checkErrorIfContains('teachersQuantityElementary')}, 'input__space']" placeholder="teachersNumberPlaceholder"  type="number" v-model="formElementary.teachersQuantityElementary" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'projectsWithOneStudent'  :class="[{warning: checkErrorIfContains('oneStudentElementary')}, 'input__space']" placeholder='projectsWithOneStudentPlaceholder' type="number" v-model="formElementary.oneStudentElementary"  :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'projectsWithTwoOrThreeStudent' :class="[{warning: checkErrorIfContains('moreStudentsElementary')}, 'input__space']" placeholder= 'projectsWithTwoOrThreeStudentPaceholder' type="number" v-model="formElementary.moreStudentsElementary"  :mask="['####']"  @input="resetErrors" />
            <p class="pupils__total">{{$t('totalStudents')}}</p>
            <input-masked label= 'male'          :class="[{warning: checkErrorIfContains('maleElementary')}, 'input__space']" placeholder="studentsNumberPlaceholder"  type="number" v-model="formElementary.maleElementary" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'female'          :class="[{warning: checkErrorIfContains('femaleElementary')}, 'input__space']" placeholder="studentsNumberPlaceholder"  type="number" v-model="formElementary.femaleElementary" :mask="['####']"  @input="resetErrors" />
          </div>
        </div>

        <div class="form__item mb-5">
          <input-checkbox-title class="mb-7" label= 'highSchoolAndOrTechnical' v-model="activeHighSchool" id="medio" @change="resetErrors" />

          <div v-if="activeHighSchool">
            <input-masked label= 'howManyProjects'          :class="[{warning: checkErrorIfContains('projectsQuantityHighSchool')}, 'input__space']" placeholder="projectsNumberPlaceholder"  type="number" v-model="formHighSchool.projectsQuantityHighSchool" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'schoolsNumber'          :class="[{warning: checkErrorIfContains('schoolsQuantityHighSchool')}, 'input__space']" placeholder="schoolsNumberPlaceholder"  type="number" v-model="formHighSchool.schoolsQuantityHighSchool" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'teachersNumber'          :class="[{warning: checkErrorIfContains('teachersQuantityHighSchool')}, 'input__space']" placeholder="teachersNumberPlaceholder"  type="number" v-model="formHighSchool.teachersQuantityHighSchool" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'projectsWithOneStudent'  :class="[{warning: checkErrorIfContains('oneStudentHigh')}, 'input__space']" placeholder='projectsWithOneStudentPlaceholder' type="number" v-model="formHighSchool.oneStudentHigh"  :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'projectsWithTwoOrThreeStudent' :class="[{warning: checkErrorIfContains('moreStudentsHigh')}, 'input__space']" placeholder= 'projectsWithTwoOrThreeStudentPaceholder' type="number" v-model="formHighSchool.moreStudentsHigh"  :mask="['####']"  @input="resetErrors" />
            <p class="pupils__total">{{$t('totalStudents')}}</p>
            <input-masked label= 'male'          :class="[{warning: checkErrorIfContains('maleHighSchool')}, 'input__space']" placeholder="studentsNumberPlaceholder"  type="number" v-model="formHighSchool.maleHighSchool" :mask="['####']"  @input="resetErrors" />
            <input-masked label= 'female'          :class="[{warning: checkErrorIfContains('femaleHighSchool')}, 'input__space']" placeholder="studentsNumberPlaceholder"  type="number" v-model="formHighSchool.femaleHighSchool" :mask="['####']"  @input="resetErrors" />

          </div>
        </div>
      </main>
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
      </div>
    </form>
    <feedback-warning v-if="errors.length" :text="textWarning" :onClick="resetErrors" color="#ff9800" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, InputCheckboxTitle, ButtonDefault, FeedbackWarning, TextWarning, InputMasked
} from '@/components/atoms'

export default {
  name: 'FormAboutFair',
  components: {
    InputDefault, InputCheckboxTitle, ButtonDefault, FeedbackWarning, TextWarning, InputMasked
  },
  data() {
    return {
      errors: [],
      textWarning: 'oneLevelIsNecessary',
      activeChild: false,
      formChild: {
        projectsQuantityChild: '',
        schoolsQuantityChild: '',
        teachersQuantityChild: '',
        maleChild: '',
        femaleChild: ''
      },
      activeElementary: false,
      formElementary: {
        projectsQuantityElementary: '',
        schoolsQuantityElementary: '',
        teachersQuantityElementary: '',
        oneStudentElementary: '',
        moreStudentsElementary: '',
        maleElementary: '',
        femaleElementary: ''
      },
      activeHighSchool: false,
      formHighSchool: {
        projectsQuantityHighSchool: '',
        schoolsQuantityHighSchool: '',
        teachersQuantityHighSchool: '',
        oneStudentHigh: '',
        moreStudentsHigh: '',
        maleHighSchool: '',
        femaleHighSchool: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.fair_participants) {
      this.fairAffiliation.fair_participants.forEach((item) => {
        if (item.level === 'participants_grade' && item.projects_quantity) {
          this.activeChild = true
          this.formChild.projectsQuantityChild = item.projects_quantity
          this.formChild.schoolsQuantityChild = item.schools_quantity
          this.formChild.teachersQuantityChild = item.teachers_quantity
          this.formChild.maleChild = item.male_students
          this.formChild.femaleChild = item.female_students
        }
        if (item.level === 'participants_middle' && item.projects_quantity) {
          this.activeElementary = true
          this.formElementary.projectsQuantityElementary = item.projects_quantity
          this.formElementary.schoolsQuantityElementary = item.schools_quantity
          this.formElementary.teachersQuantityElementary = item.teachers_quantity
          this.formElementary.oneStudentElementary = item.one_participants
          this.formElementary.moreStudentsElementary = item.more_participants
          this.formElementary.maleElementary = item.male_students
          this.formElementary.femaleElementary = item.female_students
        }
        if (item.level === 'participants_high' && item.projects_quantity) {
          this.activeHighSchool = true
          this.formHighSchool.projectsQuantityHighSchool = item.projects_quantity
          this.formHighSchool.schoolsQuantityHighSchool = item.schools_quantity
          this.formHighSchool.teachersQuantityHighSchool = item.teachers_quantity
          this.formHighSchool.oneStudentHigh = item.one_participants
          this.formHighSchool.moreStudentsHigh = item.more_participants
          this.formHighSchool.maleHighSchool = item.male_students
          this.formHighSchool.femaleHighSchool = item.female_students
        }
      })
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      this.resetErrors()
      this.textWarning = 'oneLevelIsNecessary'
      /*
      if (!this.activeChild && !this.activeElementary && !this.activeHighSchool) {
        this.errors.push(1)
        return false
      }
      */
      if (this.activeChild) {
        for (const key in this.formChild) {
          if (!this.formChild[key]) {
            this.errors.push(key)
            this.textWarning = 'allFieldsSelectedMustBeCompleted'
          }
        }
      } else {
        this.formChild.projectsQuantityChild = ''
        this.formChild.schoolsQuantityChild = ''
        this.formChild.teachersQuantityChild = ''
        this.formChild.maleChild = ''
        this.formChild.femaleChild = ''
      }
      if (this.activeElementary) {
        for (const key in this.formElementary) {
          if (!this.formElementary[key]) {
            this.errors.push(key)
            this.textWarning = 'allFieldsSelectedMustBeCompleted'
          }
        }
      }
      if (this.activeHighSchool) {
        for (const key in this.formHighSchool) {
          if (!this.formHighSchool[key]) {
            this.errors.push(key)
            this.textWarning = 'allFieldsSelectedMustBeCompleted'
          }
        }
      }
      if (!this.errors.length) {
        this.saveForm()
      }
    },
    saveForm() {
      try {
        let screen
        const formData = this.$store.getters.fairAffiliation
        const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
        if (currentScreen < 6) {
          screen = 6
        } else {
          screen = currentScreen
        }

        const newData = {
          ...formData,
          currentScreen: screen,
          fair_participants: [{
            level: 'participants_grade',
            projects_quantity: +this.formChild.projectsQuantityChild,
            schools_quantity: +this.formChild.schoolsQuantityChild,
            teachers_quantity: +this.formChild.teachersQuantityChild,
            one_participants: 0,
            more_participants: 0,
            male_students: +this.formChild.maleChild,
            female_students: +this.formChild.femaleChild
          },
          {
            level: 'participants_middle',
            projects_quantity: +this.formElementary.projectsQuantityElementary,
            schools_quantity: +this.formElementary.schoolsQuantityElementary,
            teachers_quantity: +this.formElementary.teachersQuantityElementary,
            one_participants: +this.formElementary.oneStudentElementary,
            more_participants: +this.formElementary.moreStudentsElementary,
            male_students: +this.formElementary.maleElementary,
            female_students: +this.formElementary.femaleElementary
          },
          {
            level: 'participants_high',
            projects_quantity: +this.formHighSchool.projectsQuantityHighSchool,
            schools_quantity: +this.formHighSchool.schoolsQuantityHighSchool,
            teachers_quantity: +this.formHighSchool.teachersQuantityHighSchool,
            one_participants: +this.formHighSchool.oneStudentHigh,
            more_participants: +this.formHighSchool.moreStudentsHigh,
            male_students: +this.formHighSchool.maleHighSchool,
            female_students: +this.formHighSchool.femaleHighSchool
          }]
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-width: 610px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}

.input__space {
  margin-top: 20px;
  margin-bottom: 20px;
}

.pupils__total {
  color: $primary;
  font-weight: 600;
  font-size: 16px;
}
</style>
