<template>
  <div class="">
    <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <input
        type="file"
        multiple
        :name="reference"
        :id="reference"
        class="omit"
        @change="onChange"
        :ref="reference"
        :accept="`.${type}`"
      />
      <label
        :for="reference"
        class="container-drag"
        :class="{
          attention: cAttention,
          name_sucess: fileName != '' && !loader,
          error_file: error
        }"
      >
        <template v-if="!error">
          <template v-if="!loader">
            <img :src="require(`@/assets/upload${cImage}.svg`)" />
            <div class="name" v-if="fileName == '' || fileName == notIsPdf">
              {{$t('dragAndDropTheFile')}} <span class="select">{{$t('select')}}</span>
            </div>
            <div class="name" v-if="fileName != '' && fileName != notIsPdf">
              <span class="name_sucess">{{ fileName }}</span>
            </div>
          </template>
          <template v-else>
            <div class="loader">
              <div class="lds-dual-ring"></div>
              <div>{{$t('loading')}}</div>
            </div>
          </template>
        </template>
        <template v-if="error">
          <img :src="require(`@/assets/error.svg`)" />
          <div class="name">
            {{msgFail}}
          </div>
        </template>
      </label>
    </div>
  </div>
</template>
<script>
import ModalLoading from '@/components/organisms/Modals/ModalLoading.vue'

export default {
  name: 'DragFile',
  data() {
    return {
      fileName: '',
      url: '',
      notIsPdf: `Insira um ${ this.type }!`,
      loader: false,
      error: false,
      azureFail: this.$t('azureMessageFail'),
      msgSizeLimit: `Tamanho do arquivo ultrapassa ${ this.limitSize / 1000000 }Mb!`,
      msgFail: ''
    }
  },
  props: {
    attention: Boolean,
    type: String,
    actualData: [Object, String],
    reference: String,
    limitSize: {
      type: Number,
      default: 100000000
    }
  },
  components: {
    ModalLoading
  },
  computed: {
    cNotPdf() {
      return this.fileName === this.notIsPdf
    },
    cAttention() {
      return this.attention
    },
    cImage() {
      if (this.fileName !== '') {
        return '-success'
      }
      return ''
    }
  },
  watch: {
    actualData() {
      this.fileName = this.actualData.name
      this.url = this.actualData.url
    }
  },
  methods: {
    // async convertToBase64(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(file)
    //     reader.onload = () => resolve(reader.result)
    //     reader.onerror = (error) => reject(error)
    //   })
    // },
    async onChange() {
      this.error = false
      const fileNameAux = this.$refs[this.reference].files[0].name.split('.')

      const fData = new FormData()
      fData.append('file', this.$refs[this.reference].files[0])
      console.log(fData)

      if (fileNameAux[fileNameAux.length - 1].toLowerCase() !== this.type) {
        alert(`Formato inválido, insira um ${ this.type }`)
        this.fileName = this.notIsPdf
        this.$emit('file', { url: this.actualData.url, name: this.actualData.name })
      } else {
        try {
          this.loader = true
          const { size } = this.$refs[this.reference].files[0]
          if (size > this.limitSize) {
            this.error = true
            this.msgFail = this.msgSizeLimit
            this.loader = false
            return
          }
          const header = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
          this.fileName = this.$refs[this.reference].files[0].name
          const { data: response } = await this.$http.post('/upload', fData, header)
          this.$emit('file', { url: response.data || 'error', name: this.fileName || 'error' })
          this.error = false
          this.loader = false
        } catch (error) {
          this.loader = false
          this.error = true
          this.msgFail = this.azureFail
        }
      }
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-green-300')
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    },
    drop(event) {
      event.preventDefault()
      this.$refs[this.reference].files = event.dataTransfer.files
      this.onChange() // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100')
      event.currentTarget.classList.remove('bg-green-300')
    }
  }
}
</script>
<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.file-name {
  max-width: 570px;
}
.container-drag {
  cursor: pointer;
  font-family: "Mulish", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 570px;
  min-height: 105px;
  border-radius: 10px;
  border: $borderInputCard;
  padding: 15px 0px;
  &.attention {
    border-color: $attention;
  }
}
.omit {
  display: none;
}
.select {
  color: $primary;
  font-weight: bold;
}
.attention {
  color: $attention;
}
.name_sucess {
  color: $success;
  border-color: $success !important;
}
.error_file {
  color: $warningRed;
  border-color: $warningRed !important;
}
.name {
  max-width: 570px;
  padding: 10px;
  text-align: center;
}
.lds-dual-ring {
  display: inline-block;
  height: 5px;
  margin-bottom: 30px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 3px solid $primary;
  border-color: $primary transparent $primary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
