<template>
  <div class="container-app">
    <div class="wrapper">
      <!--<ModalLgpd
        :modal="warningModal"
        :onClick="() => toggleModal()"
      />
      <ModalLgpdTerm
        @lgpdChecked="toggleChecked"
        :modal="termModal"
        :onClick="() => toggleModal()"
      />-->
      <header-form
        title="preRegistration"
        subtitle="affiliatedFairStudent"
      />
      <paragraph class="paragraph">
        {{$t('initialRegisterHeader1')}}
        {{$t('initialRegisterHeader2')}}
        {{$t('initialRegisterHeader3')}}
        {{$t('initialRegisterHeader4')}}
        {{$t('initialRegisterHeader5')}}
        {{$t('initialRegisterHeader6')}}
      </paragraph>
      <paragraph class="paragraphRed">
        {{$t('warningForMultiple')}}
      </paragraph>
    <FormRegisterProjectFair
      @userEmail="(e) => setEmail(e)"
      @lgpdWarning="toggleWarning"
      :lgpdChecked="lgpdChecked"
      />
    </div>
    <modal-default title="E-mail enviado!" @closeModal="redirect" :modal="modal">
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/email-send.png" alt="E-mail enviado a MostraTec">
        </div>
        <div class="text">
          <p slot="text">{{$t('confirmationEmailSent')}}</p>
        </div>
      </div>
    </modal-default>
    <modal-loading :modal="loading" />
  </div>
</template>

<script>
import { Paragraph } from '@/components/atoms'
import {
  HeaderForm, FormRegisterProjectFair, ModalDefault, ModalLoading
} from '@/components/organisms'
import {
  ModalLgpd,
  ModalLgpdTerm
} from '@/components/organisms/Modals'

export default {
  name: 'InitialRegisterProjectFair',
  components: {
    HeaderForm,
    Paragraph,
    FormRegisterProjectFair,
    ModalDefault,
    ModalLoading,
    ModalLgpd,
    ModalLgpdTerm
  },
  data() {
    return {
      userEmail: '',
      modal: false,
      loading: false,
      termModal: false,
      warningModal: false,
      lgpdChecked: false
    }
  },
  async mounted() {
    const statusRegistration = await this.getCheckRegistration()
    if (!statusRegistration.fairProject) {
      this.$router.push('/')
    }
  },
  methods: {
    redirect() {
      this.$router.push('/login')
    },
    openModal(type, value) {
      this[type] = value
    },
    toggleModal() {
      this.termModal = !this.termModal
      this.warningModal = false
    },
    toggleWarning() {
      this.warningModal = true
    },
    setEmail(e) {
      this.userEmail = e
    },
    async toggleChecked() {
      this.lgpdChecked = true
      this.modal = !this.modal
    },
    async getCheckRegistration() {
      const { data } = await this.$http.get('/check-register')
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
$imgUrl: '../../assets/background-login.png';

.container-app {
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0.01)
    ),
    url($imgUrl);
  background-size: contain;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;

  .wrapper {
    background-color: #fff;
    opacity: 0.9;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 649px;
    padding: 25px 28px;
  }
}

.paragraph {
  margin: 25px 0px 15px 0px;
  text-align: left;
}

.paragraphRed {
  color: red;
  margin: 25px 0px 15px 0px;
  text-align: left;
}

.modal__body {
  @include d-flex-center;
  margin-top: 30px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $almostBlack;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 7%;
    max-width: 326px;
    text-align: left;
  }
}
</style>
