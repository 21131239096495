<template>
  <div class="summary-person-info">
    <div class="image-holder">
      <img src="@/assets/regular-user.svg" alt="-" />
    </div>
    <div class="info-holder-wrapper">
      <div class="info-holder" v-if="type && type === 'student'">
        <div class="info-row">
          <summary-label-text-pair label="Nome">
            {{ info.full_name }}
          </summary-label-text-pair>
          <summary-label-text-pair label="Data de nascimento">
            {{ info.birth_date | formatDate }}
          </summary-label-text-pair>
        </div>
        <div class="info-row mt-4">
          <summary-label-text-pair label="Sexo">
            {{ sex[info.sex] }}
          </summary-label-text-pair>
          <summary-label-text-pair label="Série">
            {{ $t(educationalLevels[info.educational_level]) }}
          </summary-label-text-pair>
        </div>
        <div class="info-row mt-4">
          <summary-label-text-pair label="E-mail">
            {{ info.email }}
          </summary-label-text-pair>
        </div>
      </div>
      <div class="info-holder" v-else-if="type && type === 'advisor'">
        <div class="info-row">
          <summary-label-text-pair label="Nome">
            {{ info.full_name }}
          </summary-label-text-pair>
          <summary-label-text-pair
            label="Função"
            class="capitalize"
          >
            {{ info.type }}
          </summary-label-text-pair>
        </div>
        <div class="info-row mt-4">
          <summary-label-text-pair label="Telefone">
            {{ info.phone }}
          </summary-label-text-pair>
          <summary-label-text-pair label="E-mail">
            {{ info.email }}
          </summary-label-text-pair>
        </div>
      </div>
      <hr class="mt-4" v-if="!isLast"/>
    </div>
  </div>
</template>

<script>
import educationalLevels from '@/utils/dictionaries/educationalLevels.js'
import SummaryLabelTextPair from '../../molecules/Pairs/SummaryLabelTextPair.vue'

export default {
  name: 'SummaryPersonInfo',
  components: {
    SummaryLabelTextPair
  },
  data() {
    return {
      sex: {
        male: $t('maleSingle'),
        female: $t('femaleSingle')
      },
      educationalLevels
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
    isLast: Boolean
  }
}
</script>

<style lang="scss" scoped>
.summary-person-info {
  width: 100%;
  & > div {
    display: inline-block;
  }
}

.capitalize {
  text-transform: capitalize;
}

.image-holder {
  padding-right: 10px;
  vertical-align: top;
}

.info-holder-wrapper {
  width: 90%;
  .info-holder {
    width: 100%;
  }
  hr {
    border: none;
    border-bottom: 1px solid #CDCBCB;
  }
}

.info-row {
  display: block;
  width: 100%;
  & > div {
    vertical-align: top;
    display: inline-block;
    width: 50%;
  }
}
</style>
