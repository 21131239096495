<template>
  <div>
    <div class="inner__wrapper">
      <div class="header">
        <h3>Última edição da feira</h3>
        <div v-if="onlyAdmin === 'admin'">
          <button
            class="button_edit"
            v-if="!open"
            @click="open = !open"
          >
            <strong>
              EDITAR
            </strong>
          </button>
            <div class="cancel-and-save">
          <button class="button_cancel" v-if="open" @click="cancelSave">CANCELAR</button>
          <button-default class="button_save" v-if="open" :onClick="checkForm"
            >Salvar</button-default
          >
        </div>
      </div>
      </div>
      <div class="infos">
        <div class="info__item">
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>Periocidade da feira</h4>
                <span>{{ fairFrequency }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>Período da realização da última feira</h4>
                <span>{{ lastFairDates }}</span>
              </div>
              <div class="data__right">
                <h4>Período da realização da próxima feira</h4>
                <span>{{ nextFairDates }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>A data de realização da próxima feira é confirmada?</h4>
                <span>{{ this.nextConfirmed }}</span>
              </div>
              <div class="data__right">
                <h4>Formato da próxima feira</h4>
                <span>{{ editionFormat }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>Número de edições da feira</h4>
                <span>{{ fairInfo.editions_number }}</span>
              </div>
              <div class="data__right">
                <h4>A feira tem um comitê de revisão científica (CRC)?</h4>
                <span>{{ this.hasCrc }}</span>
              </div>
            </div>
            <div class="last__row">
              <h4 :class="{ warning: checkErrorIfContains('education_level') }">
                Níveis de ensino que participam da feira:
              </h4>
              <div class="areas" v-if="open">
                <div class="areas__options">
                  <label
                    ><input
                      v-model="fairInfo.teaching_level.grade"
                      type="checkbox"
                    />{{ $t("childEducationCompleted") }};</label
                  >
                  <label
                    ><input
                      v-model="fairInfo.teaching_level.middle_cat_1"
                      type="checkbox"
                    />{{ $t("elementarySchoolCategory1") }};</label
                  >
                  <label
                    ><input
                      v-model="fairInfo.teaching_level.middle_cat_2"
                      type="checkbox"
                    />{{ $t("elementarySchoolCategory2") }};</label
                  >
                  <label
                    ><input
                      v-model="fairInfo.teaching_level.middle_cat_3"
                      type="checkbox"
                    />{{ $t("elementarySchoolCategory3") }};</label
                  >
                  <label
                    ><input
                      v-model="fairInfo.teaching_level.middle_cat_eja"
                      type="checkbox"
                    />{{ $t("elementarySchoolCategoryEja") }};</label
                  >
                  <label
                    ><input
                      v-model="fairInfo.teaching_level.high"
                      type="checkbox"
                    />{{ $t("highSchoolAndOrTechnical") }};</label
                  >
                </div>
              </div>
              <div v-else class="badges">
                <span
                  class="badge__info"
                  v-if="this.fairInfo.teaching_level.grade"
                  >Educação Infantil</span
                >
                <span
                  class="badge__info"
                  v-if="this.fairInfo.teaching_level.middle_cat_1"
                  >Ensino Fundamental – Categoria 1 (1º ao 3º ano)</span
                >
                <span
                  class="badge__info"
                  v-if="this.fairInfo.teaching_level.middle_cat_2"
                  >Ensino Fundamental – Categoria 2 (4º ao 6º ano)</span
                >
                <span
                  class="badge__info"
                  v-if="this.fairInfo.teaching_level.middle_cat_3"
                  >Ensino Fundamental – Categoria 3 (7º ao 9º ano)</span
                >
                <span
                  class="badge__info"
                  v-if="this.fairInfo.teaching_level.middle_cat_eja"
                  >Ensino Fundamental – Categoria EJA</span
                >
                <span
                  class="badge__info"
                  v-if="this.fairInfo.teaching_level.high"
                  >Ensino Médio e/ou Educação Técnica de Nível Médio</span
                >
                <span
                  class="badge__info"
                  v-if="this.fairInfo.teaching_level.university"
                  >Ensino Superior</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  ButtonDefault,
  textWarning,
  FeedbackWarning
} from '@/components/atoms'

export default {
  name: 'CurrentFair',
  components: {
    ButtonDefault,
    textWarning,
    FeedbackWarning
  },
  props: ['fairInfo'],
  data() {
    return {
      lastFairDates: '',
      nextFairDates: '',
      hasCrc: '',
      nextConfirmed: '',
      editionFormat: '',
      fairFrequency: '',
      open: false,
      isFilled: false,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      onlyAdmin: ''
    }
  },
  mounted() {
    this.getInfos()
    this.onlyAdmin = localStorage.getItem('role')
  },
  methods: {
    getInfos() {
      this.lastFairDates = `${ this.formatDate(
        this.fairInfo.last_edition_init
      ) } - ${ this.formatDate(this.fairInfo.last_edition_end) }`
      this.nextFairDates = `${ this.formatDate(
        this.fairInfo.next_edition_init
      ) } - ${ this.formatDate(this.fairInfo.next_edition_end) }`

      this.hasCrc = this.fairInfo.has_crc ? 'Sim' : 'Não'
      this.nextConfirmed = this.fairInfo.next_edition_confirmed ? 'Sim' : 'Não'

      const currentEditionFormat = {
        presencial: 'Presencial',
        virtual: 'Virtual',
        hibrida: 'Híbrida'
      }

      const fairFrequencyObject = {
        anual: 'anual',
        bianual: 'bianual',
        trienal: 'trienal'
      }

      this.editionFormat = currentEditionFormat[this.fairInfo.current_edition_format]

      this.fairFrequency = fairFrequencyObject[this.fairInfo.fair_frequency]
    },
    checkForm() {
      this.resetErrors()
      this.isFilled = false
      for (const key in this.fairInfo.teaching_level) {
        if (this.fairInfo.teaching_level[key]) {
          if (
            key === 'created_at'
            || key === 'fair_id'
            || key === 'id'
            || key === 'updated_at'
          ) {
          } else {
            this.isFilled = true
          }
        }
      }

      if (!this.isFilled) {
        this.errors.push('education_level')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }

      if (!this.errors.length) {
        this.editForm()
      }
    },
    async editForm() {
      const fairId = this.fairInfo.id

      const body = {
        teaching_level: {
          id: this.fairInfo.teaching_level.id,
          grade: this.fairInfo.teaching_level.grade,
          middle_cat_1: this.fairInfo.teaching_level.middle_cat_1,
          middle_cat_2: this.fairInfo.teaching_level.middle_cat_2,
          middle_cat_3: this.fairInfo.teaching_level.middle_cat_3,
          middle_cat_eja: this.fairInfo.teaching_level.middle_cat_eja,
          high: this.fairInfo.teaching_level.high,
          university: this.fairInfo.teaching_level.university
        }
      }

      try {
        await this.$http.put(`/edit-fair/${ fairId }`, body)
        location.reload()
      } catch (error) {
        console.log(error.message)
      }
    },
    cancelSave() {
      location.reload()
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    formatDate(date) {
      if (!date) return null

      const dateFormatted = new Date(date).toISOString().substr(0, 10)

      const [year, month, day] = dateFormatted.split('-')
      return `${ day }/${ month }/${ year }`
    }
  }
}
</script>

<style lang="scss" scoped>
.inner__wrapper {
  background: #fdfdfd;
  border-radius: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 10px 22px 10px;

    .info__item {
      margin-top: 25px;

      .data__wrapper {
        .data__row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 30px;
          padding-left: 5%;
          padding-right: 5%;

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: #686868;
          }
        }

        .last__row {
          padding-left: 5%;
          padding-right: 5%;
          margin-bottom: 30px;

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          .badges {
            padding-top: 14px;

            .badge__info {
              align-items: center;
              display: inline-flex;
              justify-content: center;
              background: $primary;
              border-radius: 49px;
              opacity: 0.8;

              color: $white;
              font-size: 14px;
              height: 28px;
              padding: 0px 10px;
              margin-right: 5px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .areas {
    &__options {
      display: flex;
      flex-direction: column;

      label {
        align-items: center;
        display: flex;
        color: $almostBlack;
        font-size: 16px;
        margin-bottom: 5px;

        input {
          zoom: 1.5;
          margin-right: 5px;
        }
      }
    }
  }
}

.button_edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  width: 100px;
  height: 36px;
  left: 1230px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  margin: 0px 10px;

  width: 106px;
  height: 36px;
  left: 1215px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_cancel {
  box-sizing: border-box;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 32px;

  width: 113px;
  height: 36px;
  left: 1105px;
  top: 126px;

  border: 1px solid #26768d;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;

  color: #26768D;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.cancel-and-save {
  display: flex;
  justify-content: space-between;
}
</style>
