module.exports = {
areasEn: {
    materialsEngineering: [
      { text: 'Transport Engineering', value: 'transportEngineer' },
      { text: 'Aerospace and Aeronautical Engineering, Aerodynamics', value: 'aerospaceAeronauticalEngineeringAerodynamics' },
      { text: 'Civil Engineering and Construction', value: 'civilEngineeringConstruction' },
      { text: 'Security Engineering', value: 'securityEngineering' },
      { text: 'Product Development', value: 'productDevelopment' },
      { text: 'Materials Science', value: 'materialsScience' },
      { text: 'Others', value: 'others' }
    ],
  
    eletricEngineering: [
      { text: 'General Electrical', value: 'generalElectrical' },
      { text: 'Generation, Transmission and Transformation of Electricity', value: 'generationTransmissionTransformationElectricity' },
      { text: 'Electric Drives', value: 'electricDrives' },
      { text: 'Renewable Energies', value: 'renewableEnergy' },
      { text: 'Others', value: 'others' }
    ],
  
    eletronicEngineering: [
      { text: 'General Electronics', value: 'electronicGeneral' },
      { text: 'Electronic Systems and Processes', value: 'electronicSystemProcess' },
      { text: 'Communication Systems', value: 'communicationsSystem' },
      { text: 'Industrial Automation', value: 'industrialAutomation' },
      { text: 'Robotics', value: 'robotics' },
      { text: 'Others', value: 'others' }
    ],
  
    mechanicalEngineering: [
      { text: 'General Mechanics', value: 'generalMechanics' },
      { text: 'Industrial Engineering', value: 'industrialEng' },
      { text: 'Vehicle Development', value: 'vehicleDevelopment' },
      { text: 'Product and Process Development', value: 'processProductDevelopment' },
      { text: 'Others', value: 'others' }
    ],
  
    ambientalSciences: [
      { text: 'Air Pollution and Quality', value: 'pollutionAirQuality' },
      { text: 'Water Pollution and Quality', value: 'pollutionWaterQuality' },
      { text: 'Soil Contamination and Quality', value: 'contaminationSoilQuality' },
      { text: 'Environmental Toxicology', value: 'environmentalToxicology' },
      { text: 'Others', value: 'others' }
    ],
  
    animalSciences: [
      { text: 'Biological Development (Human and Animal)', value: 'biologicalDevelopment' },
      { text: 'Ecology', value: 'ecology' },
      { text: 'Animal Husbandry', value: 'animalHusbandry' },
      { text: 'Animal Physiology and Pathology', value: 'animalPhysiologyPathology' },
      { text: 'Agriculture/Agronomy', value: 'agricultureAgronomy' },
      { text: 'Plant Morphology and Development', value: 'plantMorphologyDevelopment' },
      { text: 'Photosynthesis', value: 'photosynthesis' },
      { text: 'Plant Physiology', value: 'plantPhysiology' },
      { text: 'Plant Evolution Theory', value: 'plantEvolutionTheory' },
      { text: 'Others', value: 'others' }
    ],
  
    bioquimics: [
      { text: 'General Biochemistry', value: 'generalBiochemistry' },
      { text: 'Structural Biochemistry', value: 'structuralBiochemistry' },
      { text: 'Metabolism', value: 'metabolism' },
      { text: 'Food Chemistry', value: 'foodChemistry' },
      { text: 'Organic Chemistry', value: 'organicChemistry' },
      { text: 'Inorganic Chemistry', value: 'inorganicChemistry' },
      { text: 'Analytical Chemistry', value: 'analyticalChemistry' },
      { text: 'General Chemistry', value: 'generalChemistry' },
      { text: 'Chemical Engineering', value: 'chemicalEngineering' },
      { text: 'Physicochemical', value: 'physicochemical' },
      { text: 'Others', value: 'others' }
    ],
  
    biology: [
      { text: 'Molecular and Cellular Biology', value: 'molecularCellBiology' },
      { text: 'Genetics', value: 'genetics' },
      { text: 'Immunology', value: 'immunology' },
      { text: 'Antibiotics', value: 'antibiotics' },
      { text: 'Antimicrobials', value: 'antimicrobials' },
      { text: 'Bacteriology', value: 'bacteriology' },
      { text: 'Virology', value: 'virology' },
      { text: 'Mycology', value: 'mycology' },
      { text: 'Others', value: 'others' }
    ],
  
    socialSciences: [
      { text: 'History', value: 'history' },
      { text: 'Psychology', value: 'psychology' },
      { text: 'Sociology', value: 'sociology' },
      { text: 'Anthropology', value: 'anthropology' },
      { text: 'Economics', value: 'economics' },
      { text: 'Politics', value: 'politics' },
      { text: 'Management', value: 'management' },
      { text: 'Others', value: 'others' }
    ],
    planetarySciences: [
      { text: 'Climatology, Climate', value: 'climatologyClimate' },
      { text: 'Geochemistry, Geophysics', value: 'geochemistryGeophysics' },
      { text: 'Mineralogy', value: 'mineralogy' },
      { text: 'Paleontology', value: 'paleontology' },
      { text: 'Planetary Sciences', value: 'planetarySciences' },
      { text: 'Geology', value: 'geology' },
      { text: 'Seismology', value: 'seismology' },
      { text: 'Oceanography', value: 'oceanography' },
      { text: 'Algebra', value: 'algebra' },
      { text: 'Analytical Mathematics', value: 'analyticalMathematics' },
      { text: 'Applied Mathematics', value: 'aplicatedMath' },
      { text: 'Geometry', value: 'geometry' },
      { text: 'Probability and Statistics', value: 'probabilityStatistics' },
      { text: 'Atoms, Molecules, and Solids', value: 'atomsMoleculesSolids' },
      { text: 'Biological Physics', value: 'biologicalPhysics' },
      { text: 'Instrumentation and Electronics', value: 'instrumentationElectronics' },
      { text: 'Magnetism and Electromagnetism', value: 'magnetismElectromagnetism' },
      { text: 'Particle and Nuclear Physics', value: 'particleNuclearPhysics' },
      { text: 'Optics, Laser', value: 'opticsLaser' },
      { text: 'Acoustics', value: 'acoustics' },
      { text: 'Theoretical Physics, Astronomy', value: 'theoreticalPhysicsAstronomy' },
      { text: 'Others', value: 'others' }
    ],
  
    humanities: [
      { text: 'Theories of Knowledge, Methodology, Didactics, Pedagogy', value: 'theoriesknowledgeMethodologyDidacticsPedagogy' },
      { text: 'Linguistics', value: 'Linguistics' },
      { text: 'Artistic Expression', value: 'artisticExpression' },
      { text: 'Philosophy and Theology', value: 'philosophyTheology' },
      { text: 'Others', value: 'others' }
    ],
  
    sanitaryEngineering: [
      { text: 'Bioremediation', value: 'Bioremediation' },
      { text: 'Ecosystem Management', value: 'ecosystemManagement' },
      { text: 'Management of Land, Water, and Forest Resources', value: 'managementLandWaterForestResources' },
      { text: 'Waste Management, Recycling', value: 'wasteManagementRecycling' },
      { text: 'Environmental Policy, Environmental Legislation', value: 'environmentalPolicyEnvironmentalLegislation' },
      { text: 'Environmental Education', value: 'environmentalEducation' },
      { text: 'Others', value: 'others' }
    ],
  
    healthSciences: [
      { text: 'Diagnosis and Treatment of Diseases', value: 'diagnosisTreatmentDiseases' },
      { text: 'Epidemiology', value: 'epidemiology' },
      { text: 'Genetics', value: 'Genetics' },
      { text: 'Physiology and Pathophysiology', value: 'physiologyPathophysiology' },
      { text: 'Occupational Health', value: 'occupationalHealth' },
      { text: 'Prevention, Prophylaxis', value: 'preventionProphylaxis' },
      { text: 'Nutrition', value: 'nutrition' },
      { text: 'Biomedicine', value: 'biomedicine' },
      { text: 'Nursing', value: 'nursing' },
      { text: 'Dentistry', value: 'dentistry' },
      { text: 'Physical Education', value: 'physicalEducation' },
      { text: 'Others', value: 'others' }
    ],
  
    computacionalSciences: [
      { text: 'Computer Engineering', value: 'computerEngineering' },
      { text: 'Algorithms, Databases', value: 'algorithmsDatabase' },
      { text: 'Artificial Intelligence', value: 'artificialIntelligence' },
      { text: 'Communication and Networks', value: 'CommunicationNetworks' },
      { text: 'Computer Science', value: 'computerScience' },
      { text: 'Graphics', value: 'graphics' },
      { text: 'Software Engineering', value: 'softwareEngineering' },
      { text: 'Computer Systems', value: 'computerSystems' },
      { text: 'Programming Languages', value: 'programmingLanguages' },
      { text: 'Others', value: 'others' }
    ]
  },
  areaOptionsEn: [
    { text: 'Cellular and Molecular Biology, Microbiology', value: 'biology' },
    { text: 'Biochemistry and Chemistry', value: 'bioquimics' },
    { text: 'Animal and Plant Sciences', value: 'animalSciences' },
    { text: 'Environmental Sciences', value: 'ambientalSciences' },
    { text: 'Computer Science', value: 'computacionalSciences' },
    { text: 'Health Sciences', value: 'healthSciences' },
    { text: 'Planetary, Terrestrial, Mathematical, and Physical Sciences', value: 'planetarySciences' },
    { text: 'Education and Humanities', value: 'humanities' },
    { text: 'Environmental and Sanitary Engineering', value: 'sanitaryEngineering' },
    { text: 'Engineering and Materials', value: 'materialsEngineering' },
    { text: 'Electrical Engineering', value: 'eletricEngineering' },
    { text: 'Electronic Engineering', value: 'eletronicEngineering' },
    { text: 'Mechanical Engineering', value: 'mechanicalEngineering' },
    { text: 'History and Social Sciences', value: 'socialSciences' }
  ],
  
  areasElementarySchoolEn: [
    { text: 'Arts', value: 'arts' },
    { text: 'Biology', value: 'biology' },
    { text: 'Education and Pedagogy', value: 'education' },
    { text: 'Electronics, Informatics, and Robotics', value: 'eletronic' },
    { text: 'Engineering', value: 'engineering' },
    { text: 'Philosophy', value: 'filosofy' },
    { text: 'Physics', value: 'phisics' },
    { text: 'Geography', value: 'geography' },
    { text: 'History', value: 'history' },
    { text: 'Inclusion, Accessibility, and Assisted Technology', value: 'inclusion' },
    { text: 'Chemistry', value: 'chemistry' },
    { text: 'Portuguese Language, Foreign Language, and Literature', value: 'portuguese' },
    { text: 'Mathematics', value: 'math' },
    { text: 'Environment', value: 'envirornment' },
    { text: 'Health', value: 'health' },
    { text: 'Sociology', value: 'sociology' },
    { text: 'Other', value: 'other' }
  ],
  
  areaOptionsElementaryEn: [
    { text: 'Arts', value: 'arts' },
    { text: 'Biology', value: 'biology' },
    { text: 'Education and Pedagogy', value: 'education' },
    { text: 'Electronics, Informatics, and Robotics', value: 'eletronic' },
    { text: 'Engineering', value: 'engineering' },
    { text: 'Philosophy', value: 'filosofy' },
    { text: 'Physics', value: 'phisics' },
    { text: 'Geography', value: 'geography' },
    { text: 'History', value: 'history' },
    { text: 'Inclusion, Accessibility, and Technology', value: 'inclusion' },
    { text: 'Chemistry', value: 'chemistry' },
    { text: 'Portuguese Language, Foreign Language, and Literature', value: 'portuguese' },
    { text: 'Mathematics', value: 'math' },
    { text: 'Environment', value: 'envirornment' },
    { text: 'Health', value: 'health' },
    { text: 'Sociology', value: 'sociology' },
    { text: 'Insert Other Subarea', value: 'other' }
  ]
}
