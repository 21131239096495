export default {
  formatDate(date) {
    if (!date) return null

    const dateFormatted = new Date(date).toISOString().substr(0, 10)

    const [year, month, day] = dateFormatted.split('-')
    return `${ day }/${ month }/${ year }`
  }
}
