<template>
  <div>
    <div class="header">
      <div class="title">{{$t('vacanciesRequestResponse')}}</div>
      <Status :status="cFairInfo.status" :is_active="cFairInfo.status_is_active" :active="true" :is_legend="false" :active_status="cFairInfo.is_active"/>
    </div>
    <div class="body">
      <template v-if="cAccepted">
        <CardVacancies :vacancies="cFairInfoGrade" :text="'Mostratec Júnior: '+ $t('childEducationCompleted')"/>
        <!-- CardVacancies :vacancies="cFairInfoMiddle" :text="'Mostratec Júnior: '+ $t('elementarySchoolCompleted')"/ -->
        <CardVacancies :vacancies="cFairInfoMiddleCat1" :text="'Mostratec Júnior: '+ $t('elementarySchoolCategory1')"/>
        <CardVacancies :vacancies="cFairInfoMiddleCat2" :text="'Mostratec Júnior: '+ $t('elementarySchoolCategory2')"/>
        <CardVacancies :vacancies="cFairInfoMiddleCat3" :text="'Mostratec Júnior: '+ $t('elementarySchoolCategory3')"/>
        <CardVacancies :vacancies="cFairInfoMiddleCatEja" :text="'Mostratec Júnior: '+ $t('elementarySchoolCategoryEja')"/>
        <CardVacancies :vacancies="cFairInfoHigh" :text="'Mostratec: '+ $t('highSchoolOrTechnicalCompleted')"/>
      </template>
      <template v-if="cNotAccepted">
        <div class="not-accepted">
          <img :src="require('@/assets/notAccepted.png')" alt="not accepted" />
          <div class="text-box">{{$t('panelFairVacanciesDenied')}}</div>
        </div>
        <div class="header">
          <div class="title">{{$t('reason')}}</div>
        </div>
        <div class="reason">
          {{cFairInfo.justification}}
        </div>
      </template>
      <template v-if="cFairInfo.status === 'inscricao em andamento'">
        <div class="enrollment-in-progress">
          <img :src="require('@/assets/enrollmentInProgress.png')" alt="enrollment in progress" />
          <div>
            <div class="text-box">{{$t('panelFairVacanciesUnsignedSubscription')}}</div>
            <ButtonDefault :onClick="go">
              {{$t('clickingHere')}}
            </ButtonDefault>
          </div>
        </div>
      </template>
      <template v-if="cFairInfo.is_active === false">
        <div class="inactive">
          <img :src="require('@/assets/inactive.png')" alt="inactive" />
          <div>
            <div class="text-box">{{$t('deactivatedRequest')}}</div>
          </div>
        </div>
      </template>
      <template v-if="cFairInfo.status === 'pendente de analise' && cFairInfo.is_active !== false">
        <div class="pending-analysis">
          <img :src="require('@/assets/pendingAnalysis.png')" alt="pending analysis" />
          <div>
            <div class="text-box">{{$t('panelFairVacanciesWaitWillBeAnalyzed')}}</div>
          </div>
        </div>
      </template>
      <template v-if="((cFairInfo.status === 'em analise') || (cFairInfo.status === 'aguardando resposta'))">
        <div class="in-analysis">
          <img :src="require('@/assets/inAnalysis.png')" alt="in analysis" />
          <div>
            <div class="text-box">{{$t('panelFairVacanciesWaitUnderAnalysis')}}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>

import { CardVacancies } from '@/components/atoms/Cards'
import { ButtonDefault } from '@/components/atoms/Buttons'
import { Status } from '@/components/atoms/Status'

export default {
  name: 'RequestVacancies',
  components: {
    CardVacancies,
    ButtonDefault,
    Status
  },
  data() {
    return {
      fairInfoFairs: null
    }
  },
  props: {
    fairInfo: Object
  },
  computed: {
    cAccepted() {
      return (this.cFairInfo.status === 'aceita') || (this.cFairInfo.status === 'resposta enviada') && (this.cFairInfo.status_is_active)
    },
    cNotAccepted() {
      return (this.cFairInfo.status === 'nao aceita') || (this.cFairInfo.status === 'resposta enviada') && (!this.cFairInfo.status_is_active)
    },
    cFairInfo() {
      if (this.fairInfo) {
        return this.fairInfo
      }
      return ''
    },
    cFairInfoGrade() {
      if (this.fairInfo && window.location.pathname === '/panel-fair-vacancies') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'grade'),
          projectsAccepted: this.fairInfo.acceptedProjects.grade
        }

        if (!requests.level) {
          return { ...requests, level: 'grade' }
        }

        return requests
      }

      if (this.fairInfoFairs && window.location.pathname === '/list/analisis') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'grade'),
          projectsAccepted: this.fairInfoFairs.acceptedProjects.grade
        }

        if (!requests.level) {
          return { ...requests, level: 'grade' }
        }

        return requests
      }
      return { level: 'grade' }
    },
    cFairInfoMiddle() {
      if (this.fairInfo && window.location.pathname === '/panel-fair-vacancies') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle'),
          projectsAccepted: this.fairInfo.acceptedProjects.middle
        }

        if (!requests.level) {
          return { ...requests, level: 'middle' }
        }

        return requests
      }

      if (this.fairInfoFairs && window.location.pathname === '/list/analisis') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle'),
          projectsAccepted: this.fairInfoFairs.acceptedProjects.middle
        }

        if (!requests.level) {
          return { ...requests, level: 'middle' }
        }

        return requests
      }

      return { level: 'middle' }
    },
    cFairInfoMiddleCat1() {
      if (this.fairInfo && window.location.pathname === '/panel-fair-vacancies') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_1'),
          projectsAccepted: this.fairInfo.acceptedProjects.middle_cat_1
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_1' }
        }
        return requests
      }
      if (this.fairInfoFairs && window.location.pathname === '/list/analisis') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_1'),
          projectsAccepted: this.fairInfoFairs.acceptedProjects.middle_cat_1
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_1' }
        }
        return requests
      }
      return { level: 'middle_cat_1' }
    },
    cFairInfoMiddleCat2() {
      if (this.fairInfo && window.location.pathname === '/panel-fair-vacancies') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_2'),
          projectsAccepted: this.fairInfo.acceptedProjects.middle_cat_2
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_2' }
        }
        return requests
      }
      if (this.fairInfoFairs && window.location.pathname === '/list/analisis') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_2'),
          projectsAccepted: this.fairInfoFairs.acceptedProjects.middle_cat_2
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_2' }
        }
        return requests
      }
      return { level: 'middle_cat_2' }
    },
    cFairInfoMiddleCat3() {
      if (this.fairInfo && window.location.pathname === '/panel-fair-vacancies') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_3'),
          projectsAccepted: this.fairInfo.acceptedProjects.middle_cat_3
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_3' }
        }
        return requests
      }
      if (this.fairInfoFairs && window.location.pathname === '/list/analisis') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_3'),
          projectsAccepted: this.fairInfoFairs.acceptedProjects.middle_cat_3
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_3' }
        }
        return requests
      }
      return { level: 'middle_cat_3' }
    },
    cFairInfoMiddleCatEja() {
      if (this.fairInfo && window.location.pathname === '/panel-fair-vacancies') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_eja'),
          projectsAccepted: this.fairInfo.acceptedProjects.middle_cat_eja
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_eja' }
        }
        return requests
      }
      if (this.fairInfoFairs && window.location.pathname === '/list/analisis') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'middle_cat_eja'),
          projectsAccepted: this.fairInfoFairs.acceptedProjects.middle_cat_eja
        }
        if (!requests.level) {
          return { ...requests, level: 'middle_cat_eja' }
        }
        return requests
      }
      return { level: 'middle_cat_eja' }
    },

    cFairInfoHigh() {
      if (this.fairInfo && window.location.pathname === '/panel-fair-vacancies') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'high'),
          projectsAccepted: this.fairInfo.acceptedProjects.high
        }

        if (!requests.level) {
          return { ...requests, level: 'high' }
        }

        return requests
      }

      if (this.fairInfoFairs && window.location.pathname === '/list/analisis') {
        const requests = {
          ...this.fairInfo.vacancies_requests.find((i) => i.level === 'high'),
          projectsAccepted: this.fairInfoFairs.acceptedProjects.high
        }

        if (!requests.level) {
          return { ...requests, level: 'high' }
        }

        return requests
      }

      return { level: 'high' }
    }
  },
  async mounted() {
    await this.acceptedProjectsFairs(this.fairInfo.id)
  },
  methods: {
    go() {
      this.$router.push({ name: 'FormRequest', params: { currentStatus: 'inscricao em andamento' } })
    },
    async acceptedProjectsFairs(fairId) {
      const { data: response } = await this.$http.get(`/fair/${ fairId }`)
      const { data: response2 } = await this.$http.get(`/fair-projects/${ fairId }`)

      const fairInfo = {
        ...response,
        acceptedProjects: response2
      }

      this.fairInfoFairs = fairInfo
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 0.5px solid $textCard;
}

.not-accepted,
.pending-analysis,
.inactive,
.enrollment-in-progress,
.in-analysis {
  padding: 36px 90px;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  .text-box {
    margin-left: 10px;
  }
}

.reason {
  margin-top: 14px;
}
</style>
