<template>
  <div class="page" v-if="projectData.id">
    <div class="section">
      <img
        src="@/assets/logo.png"
        alt="MOSTRATEC"
        class="logo"
      />
      <summary-text color="#1B778B">Livrão do projeto</summary-text>
      <h2 class="project-title mt-2">
        {{ projectData.project_title }}
      </h2>
      <div class="block-row two mt-2">
        <div class="block-row">
          <summary-text class="mr-1 inline">Estande</summary-text>
          <summary-text class="inline" color="#1B778B">#{{ id }}</summary-text>
        </div>
        <div>
          <summary-text class="right">Mostratec {{projectData.mostratec.year_edition}}</summary-text>
        </div>
      </div>
    </div>
    <div class="section">
      <div
        v-if="projectData.area"
        class="block-row mb-4"
      >
        <div>
          <span class="areas-label mb-1">Area de pesquisa</span>
          <span class="badge">{{ $t(projectAreas[projectData.area]) }}</span>
        </div>
      </div>
      <div
        class="block-row mb-4"
        v-if="projectData.sub_areas.sub_areas && projectData.sub_areas.sub_areas.length"
      >
        <div>
          <span class="areas-label mb-1">Subárea(s) de pesquisa</span>
          <div class="badges-container">
            <span
              v-for="(item, index) of projectData.sub_areas.sub_areas"
              class="badge"
              :key="`badge${index}`"
            >
              {{projectSubAreas[item] || item}}
            </span>
          </div>
        </div>
      </div>
      <div class="block-row two mt-2 mb-4">
        <summary-label-text-pair label="Tipo de processo de inscrição">
          {{ projectData.fair_id ? 'Feira afiliada' : 'Processo de seleção' }}
        </summary-label-text-pair>
        <summary-label-text-pair
          v-if="projectData.research_type"
          label="Tipo de pesquisa"
        >
          {{ researchTypes[projectData.research_type] }}
        </summary-label-text-pair>
      </div>
      <div class="block-row">
        <summary-label-text-pair label="Resumo" textAlign="justify">
          {{ projectData.summary }}
        </summary-label-text-pair>
      </div>
    </div>
    <div class="section">
      <summary-text weight="700" color="#1B778B">
        {{ projectData.fair_id ? 'Dados da feira' : 'Dados da instituição' }}
      </summary-text>
      <div class="block-row two mt-4">
        <summary-label-text-pair
          v-if="projectData.fair_id"
          label=fairName
        >
          {{ projectData.fair.fair_name }}
        </summary-label-text-pair>
        <summary-label-text-pair
          v-else-if="projectData.school_id"
          label="Escola/Instituição"
        >
          {{ projectData.schools.school_name }}
        </summary-label-text-pair>
        <summary-label-text-pair label="Tipo Ensino">
          {{ $t(fairOrSchoolObject.school_type) }}
        </summary-label-text-pair>
      </div>
      <div class="block-row three mt-4">
        <summary-label-text-pair label="País">
          {{ fairOrSchoolObject.country }}
        </summary-label-text-pair>
        <summary-label-text-pair label="Endereço">
          {{ fairOrSchoolObject.address }}
        </summary-label-text-pair>
        <summary-label-text-pair label="Bairro">
          {{ fairOrSchoolObject.neighborhood }}
        </summary-label-text-pair>
      </div>
      <div class="block-row three mt-4">
        <summary-label-text-pair label="Cidade">
          {{ fairOrSchoolObject.city }}
        </summary-label-text-pair>
        <summary-label-text-pair label="Estado">
          {{ fairOrSchoolObject.state }}
        </summary-label-text-pair>
        <summary-label-text-pair label="CEP">
          {{ fairOrSchoolObject.zipCode }}
        </summary-label-text-pair>
      </div>
      <div class="block-row three mt-4">
        <summary-label-text-pair label="Diretor">
          {{ fairOrSchoolObject.principal }}
        </summary-label-text-pair>
        <summary-label-text-pair label="Telefone">
          {{ fairOrSchoolObject.phone }}
        </summary-label-text-pair>
        <summary-label-text-pair label="E-mail">
          {{ fairOrSchoolObject.email }}
        </summary-label-text-pair>
      </div>
    </div>
    <div class="section">
      <summary-text weight="700" color="#1B778B">
        Dados dos estudantes
      </summary-text>
      <template v-if="projectData.students">
        <summary-person-info
          v-for="(item, index) of projectData.students"
          type="student"
          class="mt-4"
          :isLast="index === projectData.students.length -1"
          :info="item"
          :key="`student${index}`"
        />
      </template>
    </div>
    <div class="section">
      <summary-text weight="700" color="#1B778B">
        Dados dos orientadores
      </summary-text>
      <template v-if="advisors">
        <summary-person-info
          v-for="(item, index) of advisors"
          type="advisor"
          class="mt-4"
          :isLast="index === advisors.length -1"
          :info="item"
          :key="`advisor${index}`"
        />
      </template>
    </div>
    <div class="section">
      <summary-text
        weight="700"
        color="#1B778B"
      >
        Formulários enviados
      </summary-text>
      <div class="files-container mt-4">
        <div
          v-for="(form, index) of forms"
          class="file-viewer"
          :key="index"
        >
          <div class="file-icon">
            <img
              v-if="form.icon === 'pdf'"
              src="@/assets/grey_pdf.png"
              alt="form"
            />
            <img
              v-else-if="form.icon === 'mp4'"
              src="@/assets/grey_mp4.png"
              alt="form"
            />
            <img
              v-else-if="form.icon === 'play'"
              src="@/assets/play_gray.svg"
              alt="form"
            />
          </div>
          <div class="file-info">
            <summary-text>
              {{ $t(formTypes[form.type]) }}
            </summary-text>
            <span class="file-name">
              {{ form.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>{{error ? $t('errorLabel') : $t('loading')}}</p>
  </div>
</template>

<script>
import SummaryText from '@/components/atoms/Texts/SummaryText'
import SummaryLabelTextPair from '@/components/molecules/Pairs/SummaryLabelTextPair.vue'
import SummaryPersonInfo from '@/components/organisms/Infos/SummaryPersonInfo.vue'
import projectAreas from '@/utils/dictionaries/projectAreas'
import projectSubAreas from '@/utils/dictionaries/projectSubAreas'
import formTypes from '@/utils/dictionaries/formTypes'

export default {
  name: 'ProjectSummaryDocument',
  components: {
    SummaryText,
    SummaryLabelTextPair,
    SummaryPersonInfo
  },
  data() {
    return {
      id: null,
      projectData: {},
      fairOrSchoolObject: {},
      advisors: [],
      forms: [],
      researchTypes: {
        cientifica: 'Científica',
        tecnologica: 'Tecnológica',
        tecnologia: 'Tecnológica'
      },
      formTypes,
      projectAreas,
      projectSubAreas,
      error: false
    }
  },
  async mounted() {
    const { id } = this.$route.params
    this.id = id
    try {
      const { data: response } = await this.$http.get(`/project/${ id }`)
      if (response) {
        this.handleResponseData(response)
      } else {
        this.error = true
      }
    } catch (e) {
      this.error = true
    }
  },
  methods: {
    handleResponseData(response) {
      if (response?.fair) {
        const {
          school_type,
          city,
          country,
          street,
          neighborhood,
          state,
          zipcode
        } = response.fair

        const mainFairContact = response.fair_contacts.filter((contact) => contact.main)

        const fairObject = {
          school_type,
          city,
          country,
          address: street,
          neighborhood,
          state,
          zipCode: zipcode,
          principal: '',
          phone: '',
          email: ''
        }

        if (mainFairContact?.length) {
          const [{ contact_name, phone, email }] = mainFairContact
          this.fairOrSchoolObject = {
            ...fairObject,
            principal: contact_name,
            phone,
            email
          }
        } else {
          this.fairOrSchoolObject = fairObject
        }
      } else if (response?.schools) {
        const {
          school_type,
          city,
          country,
          cep,
          address,
          district,
          uf,
          responsible_name,
          phone,
          email
        } = response.schools

        this.fairOrSchoolObject = {
          school_type,
          city,
          country,
          address,
          neighborhood: district,
          state: uf,
          zipCode: cep,
          principal: responsible_name,
          phone,
          email
        }
      }
      const { files, presentation_link } = response
      const filesToArray = Object.keys(files).map((file) => {
        if (files[file].url?.length) {
          return {
            type: file,
            name: files[file].name,
            icon: file === 'video' ? 'mp4' : 'pdf'
          }
        }
      })
      this.forms = filesToArray.filter((item) => Boolean(item))
      if (presentation_link) {
        this.forms.push({
          type: 'presentationLink',
          name: presentation_link,
          icon: 'play'
        })
      }
      this.advisors = response.involveds.filter(({ type }) => type === 'orientador' || type === 'coorientador')
      this.projectData = response
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

.project-title {
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 23px;
  letter-spacing: 1px;
  text-align: left;
  color: #1B778B;
  text-transform: uppercase;
}

.logo {
  margin-bottom: 16px;
}

.page {
  padding: 32px 50px 32px 50px;
  width: 100%;

  .section {
    width: 100%;
    display: inline-block;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #CDCBCB;
  }
}

.badges-container {
  display: unset;
}

.areas-label {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #303030;
}

.badge {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 4px 16px;
  border: 1px solid #1B778B;
  color: #1B778B;
  opacity: 0.8;
  border-radius: 49px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
}

.inline {
  display: inline-block;
}

.block-row {
  & > div {
    display: inline-block;
  }
}

.right {
  text-align: right;
}

.two {
  div {
    width: 50%;
  }
}

.three {
  div {
    width: 33%;
  }
}

.files-container {
  & > .file-viewer {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 16px;
    width: 300px;
    white-space: nowrap;
    & > * {
      display: inherit;
      vertical-align: inherit;
    }
    & > .file-icon {
      margin-right: 11px;
      & > img {
        width: 34px;
        height: auto;
      }
    }
    & > .file-info {
      & > * {
        white-space: normal;
        max-width: 250px;
      }
      & > .file-name {
        font-family: Mulish;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        color: #1B778B;
      }
    }
  }
}
</style>
