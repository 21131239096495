<template>
  <div class="wrapper">
    <label class="label" :for="id">
      {{$t(label)}}
    </label>
    <div class="wrapper__switch">
      <span>{{$t('inactive')}}</span>
      <v-switch
        @change="onChange"
        inset
        :disabled="disable || false"
        class="switch"
        :input-value="defaultState"
      />
      <span>{{$t('active')}}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputSwitch',
  props: {
    label: String,
    onChange: Function,
    id: String,
    defaultState: Boolean,
    disable: Boolean
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;

  .label {
    color: $almostBlack;
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  &__switch {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 14px;
    color: $almostBlack;
  }
}

.switch {
  padding: 0px 0px 0px 18px;
}
</style>
