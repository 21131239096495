const checkDeadline = (deadline) => {
  if (typeof (deadline) === 'string') {
    deadline = new Date(deadline)
  }
  const today = new Date()
  // alert('deadline='+deadline + ' -> today='+today)
  // alert("today.getFullYear() "+ today.getFullYear() + " === deadline.getFullYear()" + deadline.getFullYear())
  if (today.getFullYear() === deadline.getFullYear()) {
    // alert("today.getMonth() "+ today.getMonth() + " === deadline.getMonth()" + deadline.getMonth())
    if (today.getMonth() === deadline.getMonth()) {
      // alert("today.getDay() "+ today.getDay() + " === deadline.getDay()" + deadline.getDay() + "today.getDate() "+ today.getDate() + " === deadline.getDate()" + deadline.getDate())
      return today.getDate() <= deadline.getDate()
    } return today.getMonth() < deadline.getMonth()
  } return today.getFullYear() < deadline.getFullYear()
}

export default checkDeadline
