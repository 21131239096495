<template>
<div class="areas mb-4">
  <p class="title_checkbox mb-2">Assinale as subáreas de pesquisa do seu projeto:</p>
  <div  v-for="(item,index) in dataArea" :key ="index" class="areas__options">
    <label><input type="checkbox" v-model="dataArea[index].value">{{dataArea[index].text}}</label>
  </div>
</div>
</template>

<script>

export default {
  name: 'InputCheckbox',
  data() {
    return {
      first: true
    }
  },
  props: {
    dataArea: Object
  },
  watch: {

    selecteds() {
      this.$emit('selecteds', this.selecteds)
    }

  }
}
</script>

<style lang="scss" scoped>
.title_checkbox {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.areas {
  &__label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: $almostBlack;
  }

  &__options {
    display: flex;
    flex-direction: column;

    label {
      align-items: center;
      display: flex;
      color: $almostBlack;
      font-size: 16px;
      margin-bottom: 5px;
      font-family: "Mulish", sans-serif;
      font-weight: 500;

      input {
        zoom: 1.5;
        margin-right: 5px;
      }
    }
  }
}

.warning {
  p {
    color: $attention;
    border-bottom-color: $attention;
  }
}
</style>
