<template>
  <div class="wrapper">
    <GrayLineTitle
      :title="title"
    />
    <input-text-area
      class="text-area"
      :label="label"
      id="text"
      :placeholder="placeholder"
      :textMax="textMax || 4000"
      :allText="vModel"
      @input="vModel=$event"
      @change="resetErrors"
    />
  </div>
</template>

<script>

import { GrayLineTitle } from '@/components/molecules'
import { InputTextArea } from '@/components/atoms'

export default {
  name: 'TitleTextAreaPair',
  components: { InputTextArea, GrayLineTitle },
  data() {
    return {
      errors: [],
      text: ''
    }
  },
  watch: {
    vModel() {
      this.$emit('change', this.vModel)
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    textMax: Number,
    vModel: String
  },
  methods: {
    resetErrors() {
      this.errors = []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: block;
}

.text-area {
  margin-left: 20px;
}
</style>
