<template>
  <aside class="wrapper__analisis-info">
    <div @click="back" class="return">
      <v-icon medium color="#0E5671">mdi-arrow-left</v-icon>
      <h3>Retornar à listagem</h3>
    </div>
    <div class="separator"></div>

    <div class="wrapper__info">
      <div>
        <h1 class="resume_title mb-4">***{{ $t("projectSummary") }}***:</h1>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">Código de inscrição:</span> {{ cod }}
        </h3>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">Tipo de processo:</span> {{ type }}
        </h3>
      </div>

      <div class="status mb-4">
        <span class="mr-1 blue_subtitle">Status:</span>
        <div class="badges">
          <status-with-tooltip v-if="!projectStatus.name" theme="grey_neutral" />

          <status-with-tooltip
            v-if="projectStatus.slug === 'rascunho'"
            statusText="Rascunho"
            theme="grey_neutral"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'em-revisao'"
            statusText="Em revisão"
            theme="blue_analisis"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'reprovado'"
            statusText="Não aceito"
            theme="red_reject"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'aguardando-aceite-feira'"
            statusText="Aguardando aceite"
            theme="warn"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'aguardando-revisao'"
            statusText="Aguardando revisão"
            theme="warn"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'revisao-aceita'"
            statusText="Revisão Aceita"
            theme="green_accept"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'revisao-negada'"
            statusText="Revisão Negada"
            theme="red_reject"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'aguardando-pagamento'"
            statusText="Aguardando Pagamento"
            theme="purple_waiting"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'inscricao-efetivada'"
            statusText="Inscrição Efetivada"
            theme="green_accept"
          />

          <status-with-tooltip
            v-if="
              (projectStatus.slug === 'inscricao-efetivada' ||
                projectStatus.slug === 'aguardando-pagamento' ||
                projectStatus.slug === 'inscricao-cancelada') &&
              projectInfo.exempt_project
            "
            statusText="Isento"
            theme="gray_draft"
          />

          <status-with-tooltip
            v-if="reviewSlug.includes(projectStatus.slug) && sentReply === false"
            statusText="Aguardando resposta"
            theme="purple_waiting"
          />

          <status-with-tooltip
            v-else-if="reviewSlug.includes(projectStatus.slug) && sentReply === true"
            statusText="Resposta enviada"
            theme="blue_send"
          />

          <status-with-tooltip
            v-if="projectStatus.slug === 'inscricao-cancelada'"
            statusText="Inscrição cancelada"
            theme="red_reject"
          />
        </div>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">País/Estado:</span> {{ country }}, {{ state }}
        </h3>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">Área de pesquisa:</span>
          {{
            projectInfo.level === "grade"
              ? "Não há área de pesquisa para este nível de ensino."
              : area
          }}
        </h3>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">Subáreas:</span> {{ subAreasFormat(subareas) }}
        </h3>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">{{
            projectInfo.fair_id ? "Feira:" : "Escola/Instituição:"
          }}</span>
          {{ school_name }}
        </h3>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">Nível de ensino:</span> {{ level }}
        </h3>
      </div>

      <div class="mb-4">
        <h3 class="black_subtitle">
          <span class="blue_subtitle">{{ $t("students") }}:</span>
          <span v-for="(student, index) in students" :key="index" class="students_name_list">
            {{ formatStudentNameDisplay(student, index) }}
          </span>
        </h3>
      </div>
      <div>
        <input-grey-text-area
          class="textarea"
          label="Parecer interno:"
          :allText="internal_feedback"
          @input="internal_feedback = $event"
          :onBlur="saveInternalFeedback"
          placeholder="Deixe aqui suas considerações"
          :textMax="2000"
          info="Parecer registrado internamente ao sistema para
          deixar observações durante o processo de avaliação."
        />
          <!-- div class="mb-4" v-if="projectInfo.internal_feedback !==null">
          <h3 class="black_subtitle">
             <span class="blue_subtitle">{{
            "Parecer Interno:"
          }}</span>
          {{ projectInfo.internal_feedback }}
          </h3 -->
        </div>
        <input-grey-text-area
          class="textarea"
          label="Penalidades:"
          @input="project_penalty = $event"
          :allText="project_penalty"
          :onBlur="saveInternalFeedback"
          placeholder="Deixe aqui suas considerações"
          :textMax="2000"
          info="Penalidade registrada internamente ao sistema
          para deixar observações durante o processo de avaliação."
        />
        <!-- div class="mb-4" v-if="projectInfo.project_penalty !== null">
          <h3 class="black_subtitle">
             <span class="blue_subtitle">{{
            "Penalidades:"
          }}</span>
          {{ projectInfo.project_penalty }}
          </h3>
        </div !-->

      </div>
    <!-- /div -->
    <nav class="navbar">
      <h3 class="black_subtitle black_subtitle__nav">Navegue pelo projeto:</h3>
      <div v-if="projectInfo.level === 'high'" :class="{ blue_bold: tabNavigation === 'revision' }">
        <a @click="setTab('revision')">{{ $t("revision") }}</a>
      </div>
      <div
        v-if="(projectInfo.level === 'middle' || projectInfo.level === 'middle_cat_1' || projectInfo.level === 'middle_cat_2' || projectInfo.level === 'middle_cat_3' || projectInfo.level === 'middle_cat_eja')"
        :class="{ blue_bold: tabNavigation === 'elementaryRevision' }"
      >
        <a @click="setTab('elementaryRevision')">{{ $t("revision") }}</a>
      </div>

      <div
        v-if="projectInfo.level === 'grade'"
        :class="{ blue_bold: tabNavigation === 'kindergartenRevision' }"
      >
        <a @click="setTab('kindergartenRevision')">{{ $t("revision") }}</a>
      </div>
      <!-- div v-if="role === 'crc'" class="separator"></div -->

      <!-- div v-if="role !== 'crc'" -->
      <div class="separator" v-if="type === 'Processo Seleção'"></div>

      <!-- div
        :class="{ blue_bold: tabNavigation === 'institution' }"
        v-if="type === 'Processo Seleção'"
      -->
      <div
        :class="{ blue_bold: tabNavigation === 'institution' }"
      >
        <a @click="setTab('institution')">INSTITUIÇÃO/ESCOLA</a>
      </div>
      <div class="separator"></div>

      <div
        v-if="projectInfo.level === 'high'"
        :class="{'blue_bold': tabNavigation === 'projects'}"
      >
        <a @click="setTab('projects')">PROJETOS</a>
      </div>
      <!-- div v-if="(role != 'crc') && (projectInfo.level === 'middle')" -->
      <div v-if="(projectInfo.level === 'middle' || projectInfo.level === 'middle_cat_1' || projectInfo.level === 'middle_cat_2'|| projectInfo.level === 'middle_cat_3'|| projectInfo.level === 'middle_cat_eja')"
        :class="{'blue_bold': tabNavigation === 'elementaryProjects'}"
      >
        <a @click="setTab('elementaryProjects')">PROJETOS</a>
      </div>
      <!-- div v-if="(role !== 'crc') && (projectInfo.level === 'grade')" -->
      <div v-if="(projectInfo.level === 'grade')"
        :class="{'blue_bold': tabNavigation === 'kindergartenProjects'}"
      >
        <a @click="setTab('kindergartenProjects')">PROJETOS</a>
      </div>
      <div class="separator"></div>

      <div v-if="projectInfo.level === 'high'"
      :class="{ blue_bold: tabNavigation === 'researcher' }">
        <a @click="setTab('researcher')">RESUMO AO PESQUISADOR</a>
      </div>
      <div v-if="projectInfo.level === 'high'" class="separator"></div>

      <div v-if="projectInfo.level === 'high'" :class="{ blue_bold: tabNavigation === 'students' }">
        <a @click="setTab('students')">ESTUDANTES</a>
      </div>
      <div
        v-if="(projectInfo.level === 'middle' || projectInfo.level === 'middle_cat_1' || projectInfo.level === 'middle_cat_2'|| projectInfo.level === 'middle_cat_3'|| projectInfo.level === 'middle_cat_eja')"
        :class="{ blue_bold: tabNavigation === 'elementaryStudents' }"
      >
        <a @click="setTab('elementaryStudents')">ESTUDANTES</a>
      </div>
      <div
        v-if="projectInfo.level === 'grade'"
        :class="{ blue_bold: tabNavigation === 'kindergartenStudents' }"
      >
        <a @click="setTab('kindergartenStudents')">ESTUDANTES</a>
      </div>
      <div class="separator"></div>

      <div v-if="projectInfo.level === 'high'" :class="{ blue_bold: tabNavigation === 'advisors' }">
        <a @click="setTab('advisors')">ORIENTADORES</a>
      </div>
      <div v-if="projectInfo.level === 'high'" class="separator"></div>
      <div
        v-if="projectInfo.level === 'high'" :class="{ blue_bold: tabNavigation === 'involveds' }">
        <a @click="setTab('involveds')">ENVOLVIDOS</a>
      </div>
      <div
        v-if="(projectInfo.level === 'middle' || projectInfo.level === 'middle_cat_1' || projectInfo.level === 'middle_cat_2'|| projectInfo.level === 'middle_cat_3'|| projectInfo.level === 'middle_cat_eja')"
        :class="{ blue_bold: tabNavigation === 'elementaryInvolveds' }"
      >
        <a @click="setTab('elementaryInvolveds')">ENVOLVIDOS</a>
      </div>
      <div
        v-if="projectInfo.level === 'grade'"
        :class="{ blue_bold: tabNavigation === 'kindergartenInvolveds' }"
      >
        <a @click="setTab('kindergartenInvolveds')">ENVOLVIDOS</a>
      </div>
      <!-- /div -->
      <!-- div v-if="role !== 'crc'" class="separator"></div -->
      <div class="separator"></div>

      <div
        v-if="notAppearPayment.includes(projectStatus.slug) && role !== 'crc'"
        :class="{ blue_bold: tabNavigation === 'payment' }"
      >
        <a @click="setTab('payment')">PAGAMENTO</a>
      </div>

      <div v-if="notAppearPayment.includes(projectStatus.slug) && role !== 'crc'"
        class="separator">
      </div>

      <div
        v-if="(projectInfo.level === 'middle' || projectInfo.level === 'middle_cat_1' || projectInfo.level === 'middle_cat_2'|| projectInfo.level === 'middle_cat_3'|| projectInfo.level === 'middle_cat_eja' || this.projectInfo.level === 'grade')"
        :class="{ blue_bold: tabNavigation === 'contract' }"
      >
        <a @click="setTab('contract')">CONTRATO DE CONVIVÊNCIA</a>
      </div>
      <div
      v-if="(projectInfo.level === 'middle' || projectInfo.level === 'middle_cat_1' || projectInfo.level === 'middle_cat_2'|| projectInfo.level === 'middle_cat_3'|| projectInfo.level === 'middle_cat_eja' || this.projectInfo.level === 'grade')"
       class="separator"></div>
        </nav>
  </aside>
</template>

<script>
import {
  InputGreyTextArea,
  StatusWithTooltip
} from '@/components/atoms'

import { states } from '@/utils/locations'
import { areaOptions, areaOptionsElementary, areas } from '@/utils/areas'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectsInfo',
  components: {
    InputGreyTextArea,
    StatusWithTooltip
  },
  data() {
    return {
      notAppearPayment: [
        'aguardando-pagamento',
        'inscricao-efetivada',
        'inscricao-cancelada'
      ],
      initialComponent: '',
      disabled: false,
      cod: null,
      role: '',
      booth: null,
      type: '',
      students: [],
      reviewSlug: ['revisao-aceita', 'revisao-negada'],
      sentReply: '',
      country: '',
      state: '',
      area: '',
      subareas: [],
      school_name: '',
      tabNavigation: this.initialComponent,
      internal_feedback: '',
      project_penalty: '',
      level: '',
      levelOptions: {
        high: 'Ensino Médio/Técnico',
        middle: 'Ensino Fundamental',
        middle_cat_1: 'Ensino Fundamental - Categoria 1',
        middle_cat_2: 'Ensino Fundamental - Categoria 2',
        middle_cat_3: 'Ensino Fundamental - Categoria 3',
        middle_cat_eja: 'Ensino Fundamental - EJA',
        grade: 'Ensino Infantil'
      },
      status: {
        name: '',
        id: '',
        badge_color: {
          1: 'gray_draft',
          2: 'warn',
          3: 'blue_analisis',
          4: 'purple_waiting',
          5: 'green_accept',
          6: 'blue_send',
          10: 'green_accept',
          11: 'red_reject'
        },
        text_color: {
          1: '#303030',
          2: '#A76F00',
          3: '#006076',
          4: '#8E24AA',
          5: '#046619',
          10: '#046619',
          11: '#950000'
        }
      }
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    },
    internal_feedback_empty: {
      type: Boolean,
      required: false
    },
    project_penalty_empty: {
      type: Boolean,
      required: false
    }

  },
  computed: {
    ...mapGetters({
      projectStatus: 'projectsStatusChange'
    })
  },
  watch: {
    'projectInfo.internal_status_id': function () {
      this.getProjectData()
    },
    internal_feedback() {
      this.$emit('internalFeedback', this.internal_feedback)
    },
    internal_feedback_empty() {
      this.internal_feedback_empty ? this.errors.push(this.internal_feedback_empty) : this.errors = []
    },
    project_penalty() {
      this.$emit('projectPenalty', this.project_penalty)
    },
    project_penalty_empty() {
      this.project_penalty_empty ? this.errors.push(this.project_penalty_empty) : this.errors = []
    }
  },
  mounted() {
    //console.log('Object.keys(this.projectInfo).length = ',Object.keys(this.projectInfo).length)
    if (Object.keys(this.projectInfo).length) {
      this.getProjectData()
      this.disabled = this.checkIfDisabled()
    }

    this.role = localStorage.getItem('role')
  },
  methods: {
    switchLevels() {
      if (this.projectInfo.level === 'high') {
        this.initialComponent = 'revision'
      } else if (this.projectInfo.level === 'middle' || this.projectInfo.level === 'middle_cat_1' || this.projectInfo.level === 'middle_cat_2' || this.projectInfo.level === 'middle_cat_3' || this.projectInfo.level === 'middle_cat_eja') {
        this.initialComponent = 'elementaryRevision'
      } else if (this.projectInfo.level === 'grade') {
        this.initialComponent = 'kindergartenRevision'
      }
    },
    checkIfDisabled() {
      return this.projectStatus.slug
        ? this.projectStatus.slug === 'revisao-aceita'
            || this.projectStatus.slug === 'revisao-negada'
        : false
    },
    formatStudentNameDisplay(studentName, index) {
      //console.log(`this.students.length = ${ this.students.length }`)
      return index === this.students.length - 1 ? ` ${ studentName }` : ` ${ studentName },`
    },
    subAreasFormat(subAreas) {
      if (this.projectInfo?.level === 'grade' || this.projectInfo?.level === 'middle' || this.projectInfo?.level === 'middle_cat_1' || this.projectInfo?.level === 'middle_cat_2' || this.projectInfo?.level === 'middle_cat_3' || this.projectInfo?.level === 'middle_cat_eja') {
        return 'Não há subáreas para este nível de ensino.'
      }
      //console.log(`subAreas.length = ${ subAreas.length }`)
      if (!subAreas.length) {
        return 'Não informado'
      }
      const subAreasFormatted = []
      subAreas.forEach((item) => subAreasFormatted.push(item.text))
      return subAreasFormatted.join(', ')
    },
    async back() {
      this.$router.push('/list')
    },
    async saveInternalFeedback() {
      try {
        await this.$http.patch(`/project/${ this.projectInfo.id }`, {
          ...this.projectInfo,
          internal_feedback: this.internal_feedback,
          project_penalty: this.project_penalty
        })
      } catch (err) {
        console.log(err)
      }
    },
    async getProjectData() {
      this.level = this.levelOptions[this.projectInfo.level]
      //console.log(`this.students.length = ${ this.students.length }`)
      if (!this.students.length) {
        const studentMain = this.projectInfo.students.find((student) => student.main)
        this.students.push(studentMain.full_name)
        this.country = studentMain.country
        this.state = states.find((state) => state.value === studentMain.state)
        if (this.country.toLowerCase() !== 'brazil' || !this.state) { this.state = studentMain.state } else this.state = this.state.text

        const studentsAux = this.projectInfo.students.sort((a, b) => {
          if (a.id > b.id) return 1
          if (a.id < b.id) return -1
          return 0
        })
        studentsAux.forEach((student) => {
          if (!student.is_continuity && !student.main) { this.students.push(student.full_name) }
        })
      }

      this.status = {
        ...this.status,
        id: this.projectInfo.internal_status ? this.projectInfo.internal_status.id : null,
        ...this.projectStatus
        // slug: this.projectInfo.internal_status ? this.projectInfo.internal_status.slug : 'N/A',
        // name: this.projectInfo.internal_status ? this.projectInfo.internal_status.name : ''
      }

      this.$store.commit('PROJECTS_STATUS_CHANGE', {
        name: this.projectInfo.internal_status.name || '',
        slug: this.projectInfo.internal_status.slug || 'N/A'
      })

      const { data: { user: response } } = await this.$http.get(`/user/${ this.projectInfo.user_id }`)
      this.type = response.role === 'aluno_feira_afiliada' ? 'Feira Afiliada' : 'Processo Seleção'
      this.cod = this.projectInfo.project_id
      this.internal_feedback = this.projectInfo.internal_feedback
      this.project_penalty = this.projectInfo.project_penalty
      if (this.type === 'Processo Seleção') this.getSchoolData(this.projectInfo.school_id)
      else this.getFairData(this.projectInfo.fair_id)

      let selectedArea
      if (this.projectInfo.level === 'high') {
        selectedArea = areaOptions.find((area) => area.value === this.projectInfo.area)
        this.area = selectedArea?.text
      } else if (this.projectInfo.level === 'middle' || this.projectInfo.level === 'middle_cat_1' || this.projectInfo.level === 'middle_cat_2' || this.projectInfo.level === 'middle_cat_3' || this.projectInfo.level === 'middle_cat_eja') {
        selectedArea = areaOptionsElementary.find((item) => item.value === this.projectInfo.area) || { value: 'other', text: this.projectInfo.area }
        this.area = selectedArea.text || 'Não informado'
      }

      areas[this.projectInfo.area]?.forEach((item) => {
        if (this.projectInfo.sub_areas.sub_areas.includes(item.value) && item.value !== 'others') {
          this.subareas.push(item)
        }
      })

      if (this.projectInfo.sub_areas.sub_areas?.includes('others')) {
        const otherIndex = this.projectInfo.sub_areas.sub_areas.indexOf('others') + 1
        const otherSubArea = this.projectInfo.sub_areas.sub_areas[otherIndex]
        this.subareas.push({ text: otherSubArea, value: otherSubArea })
      }

      this.sentReply = this.projectInfo.sent_reply

      this.switchLevels()
      this.tabNavigation = this.initialComponent
    },
    async getSchoolData(schoolId) {
      const { data: schoolInfo } = await this.$http.get(`/school/${ schoolId }`)
      this.school_name = schoolInfo.school_name
    },
    async getFairData(fairId) {
      if (fairId) {
        const { data: fairInfo } = await this.$http.get(`/fair/${ fairId }`)
        this.school_name = `${ fairInfo.id } - ${ fairInfo.fair_name }`
      }
    },
    setTab(value) {
      this.tabNavigation = value
      this.$emit('navSelected', this.tabNavigation)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper__analisis-info {
  font-family: Mulish;
  background-color: #fdfdfd;
  border-radius: 0px 10px 10px 0px;
  .badges {
    width: 50%;
  }
  .return {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.59px;
    color: #0e5671;
    margin-left: 5%;
    height: 57px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    h3 {
      color: $primaryDark;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      margin-left: 5px;
    }
  }
  .wrapper__info {
    margin-left: 5%;

    .resume_title {
      font-size: 18px;
      font-weight: 600;
      line-height: 22.59px;
    }
    .black_subtitle {
      font-size: 16px;
      color: $almostBlack;
      font-weight: 600;
      line-height: 20.08px;
      width: 95%;

      .blue_subtitle {
        color: $primary;
        font-weight: 700;
      }
    }
    .status {
      display: flex;
      font-size: 16px;
      color: $primary;
      font-weight: 700;
      line-height: 20.08px;

      .green_subscription {
        align-items: center;
        color: $almostBlack;
      }
    }
    .textarea {
      margin-top: 30px;
      margin-bottom: 10px;
      width: 80%;
    }
  }
  .navbar {
    margin-left: 5%;
    width: 80%;
    font-size: 16px;

    .black_subtitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 22.59px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    div,
    .blue_bold {
      margin-bottom: 15px;
    }

    a {
      &:hover {
        opacity: 0.8;
      }
    }

    .blue_bold {
      font-weight: bold;
    }
  }
  .separator {
    background-color: $primary;
    height: 0.5px;
    margin-bottom: 15px;
    width: 100%;
  }
}
</style>
