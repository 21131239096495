<template>
  <div class="home">
    <restaurant-template/>
  </div>
</template>

<script>

import { RestaurantTemplate } from '@/components/templates'

export default {
  name: 'RestaurantPanel',
  components: {
    RestaurantTemplate
  }
}
</script>

<style lang="scss" scoped>

</style>
