const roles = {
  admin: 'Admin',
  aluno_feira_afiliada: 'Aluno FA',
  secretaria: 'Secretaria',
  feira_afiliada: 'Feira Afiliada',
  revisor: 'Revisor',
  crc: 'CRC',
  avaliador: 'Avaliador',
  restaurante: 'Restaurante',
  aluno_processo_selecao: 'Aluno PS',
  credenciamento: 'APM Credenciamento',
  apm_credenciamento: 'APM Credenciamento'
}

export default roles
