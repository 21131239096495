<template>
  <div>
    <label class="label" :for="id">
      {{ $t(label) }}
      <textarea
        class="input"
        :disabled="disabled"
        :id="id"
        :placeholder="$t(placeholder)"
        @change="() => this.$emit('input', this.text)"
        v-model="text"
        :maxlength="textMax"
        @blur="onBlur"
      >
      </textarea>
    </label>
    <div class="text-informations">
      <div class="text" v-if="info">
        <div class="info">
          <img
            :src="require(`@/assets/information-circle.png`)"
            alt="Informação sobre Liberato"
          />
          <p>{{ $t(info) }}</p>
        </div>
      </div>
      <div class="info">{{ text.length }}/{{ textMax }}</div>
    </div>
  </div>
</template>

<script>
import { TextInfo } from '@/components/atoms'

export default {
  name: 'InputGreyTextArea',
  data() {
    return {
      text: ''
    }
  },
  props: {
    label: String,
    id: String,
    placeholder: String,
    disabled: Boolean,
    textMax: Number,
    info: String,
    styleClass: Array,
    allText: String,
    onBlur: Function
  },
  components: {
    TextInfo
  },
  watch: {
    allText() {
      this.text = this.allText
    }
  },
  mounted() {
    if (this.allText) this.text = this.allText
  }
}
</script>

<style lang="scss" scoped>
.text-informations {
  max-width: 580px;
  display: flex;
  .text {
    width: 457px;
  }
  .info {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;

    p {
      font-size: 14px;
      line-height: 18px;
      color: $almostBlack;
      margin-left: 6px;
      max-width: 522px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  &.warning {
    color: $attention !important;
  }
}

.warning {
  color: $attention !important;

  .input {
    border-color: $attention;
  }
}

.input {
  background: #F6F6F6;
  border: 1px solid #686868;
  border-radius: 10px;
  color: $almostBlack;
  font-weight: 500;
  height: 126px;
  width: 100%;
  margin-top: 5px;
  max-width: 580px;
  padding: 8px 15px;
  outline: none;

  &::placeholder {
    color: #afaeae;
    font-size: 14px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}
</style>
