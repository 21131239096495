const subAreas = {
  materialsEngineering: {
    materialsEngineeringTransports: {
      text: 'Eng. dos transportes',
      value: false
    },
    materialsEngineeringAeronautics: {
      text: 'Eng. aeroespacial e aeronáutica, aerodinâmica',
      value: false
    },
    materialsEngineeringConstruction: {
      text: 'Eng. civil e construção',
      value: false
    },
    materialsEngineeringSafety: {
      text: 'Eng. de Segurança',
      value: false
    },
    materialsEngineeringProductDev: {
      text: 'Desenvolvimento de produtos',
      value: false
    },
    materialsEngineeringMaterialsScience: {
      text: 'Ciência dos materiais',
      value: false
    },
    materialsEngineeringOthers: {
      text: 'Outras',
      value: false
    }
  },

  eletricEngineering: {
    eletricEngineeringGeneral: {
      text: 'Elétrica Geral',
      value: false
    },
    eletricEngineeringEnergy: {
      text: 'Geração, transmissão e transformação de energia elétrica',
      value: false
    },
    eletricEngineeringEletricDrives: {
      text: 'Acionamentos elétricos',
      value: false
    },
    eletricEngineeringRenewableEnergy: {
      text: 'Energias Renováveis',
      value: false
    },
    eletricEngineeringOthers: {
      text: 'Outras',
      value: false
    }
  },

  eletronicEngineering: {
    eletronicEngineeringGeneral: {
      text: 'Eletrônica Geral',
      value: false
    },
    eletronicEngineeringSystemProcesses: {
      text: 'Sistemas e Processos Eletrônicos',
      value: false
    },
    eletronicEngineeringComunication: {
      text: 'Sistemas de Comunicação',
      value: false
    },
    eletronicEngineeringIndustrialAutomation: {
      text: 'Automação Industrial',
      value: false
    },
    eletronicEngineeringRobotics: {
      text: 'Robótica',
      value: false
    },
    eletronicEngineeringOthers: {
      text: 'Outras',
      value: false
    }
  },

  mechanicalEngineering: {
    mechanicalEngineeringGeneral: {
      text: 'Mecânica Geral',
      value: false
    },
    mechanicalEngineeringIndustrialEng: {
      text: 'Eng. industrial',
      value: false
    },
    mechanicalEngineeringVehicleDev: {
      text: 'Desenvolvimento de veículos',
      value: false
    },
    mechanicalEngineeringProductDev: {
      text: 'Desenvolvimento de produtos e processos',
      value: false
    },
    mechanicalEngineeringOthers: {
      text: 'Outras',
      value: false
    }
  },

  ambientalSciences: {
    ambientalSciencesAirPolution: {
      text: 'Poluição e qualidade do ar',
      value: false
    },
    ambientalSciencesWaterPoluition: {
      text: 'Poluição e qualidade da água',
      value: false
    },
    ambientalSciencesSoilContamination: {
      text: 'Contaminação e qualidade do solo',
      value: false
    },
    ambientalSciencesAmbientalToxicology: {
      text: 'Toxicologia Ambiental',
      value: false
    },
    ambientalSciencesOthers: {
      text: 'Outras',
      value: false
    }
  },
  animalSciences: {
    animalSciencesBioDev: {
      text: 'Desenvolvimento biológico (humano e animal)',
      value: false
    },
    animalSciencesEco: {
      text: 'Ecologia',
      value: false
    },
    animalSciencesAnimalCreation: {
      text: 'Criação de animais',
      value: false
    },
    animalSciencesAnimalPatologies: {
      text: 'Fisiologia e patologia animal',
      value: false
    },
    animalSciencesAgronomy: {
      text: 'Agricultura/agronomia',
      value: false
    },
    animalSciencesMorfology: {
      text: 'Morfologia e desenvolvimento das plantas',
      value: false
    },
    animalSciencesPhotosynthesis: {
      text: 'Fotossíntese',
      value: false
    },
    animalSciencesPlantFisiology: {
      text: 'Fisiologia das plantas',
      value: false
    },
    animalSciencesPlantEvolutionTeory: {
      text: 'Teoria de evolução das plantas',
      value: false
    },
    animalSciencesOthers: {
      text: 'Outras',
      value: false
    }
  },

  bioquimics: {
    bioquimicsGeneral: {
      text: 'Bioquímica geral',
      value: false
    },
    bioquimicsStructural: {
      text: 'Bioquímica estrutural',
      value: false
    },
    bioquimicsMetabolism: {
      text: 'Metabolismo',
      value: false
    },
    bioquimicsFoods: {
      text: 'Química dos alimentos',
      value: false
    },
    bioquimicsOrganic: {
      text: 'Química orgânica',
      value: false
    },
    bioquimicsInorganic: {
      text: 'Química inorgânica',
      value: false
    },
    bioquimicsAnalytic: {
      text: 'Química analítica',
      value: false
    },
    bioquimicsChemistry: {
      text: 'Química geral',
      value: false
    },
    bioquimicsChemistryEng: {
      text: 'Engenharia química',
      value: false
    },
    bioquimicsFisChem: {
      text: 'Físico-química',
      value: false
    },
    bioquimicsOthers: {
      text: 'Outras',
      value: false
    }
  },

  biology: {
    biologyMolecular: {
      text: 'Biologia molecular e celular',
      value: false
    },
    biologyGenetics: {
      text: 'Genética',
      value: false
    },
    biologyImunology: {
      text: 'Imunologia',
      value: false
    },
    biologyAntibiotics: {
      text: 'Antibióticos',
      value: false
    },
    biologyAntimicrobians: {
      text: 'Antimicrobianos',
      value: false
    },
    biologyBac: {
      text: 'Bacteriologia',
      value: false
    },
    biologyVirus: {
      text: 'Virologia',
      value: false
    },
    biologyMicology: {
      text: 'Micologia',
      value: false
    },
    biologyOthers: {
      text: 'Outras',
      value: false
    }
  },

  socialSciences: {
    socialSciencesHist: {
      text: 'História',
      value: false
    },
    socialSciencesPsic: {
      text: 'Psicologia',
      value: false
    },
    socialSciencesSoc: {
      text: 'Sociologia',
      value: false
    },
    socialSciencesAnt: {
      text: 'Antropologia',
      value: false
    },
    socialSciencesEconomy: {
      text: 'Economia',
      value: false
    },
    socialSciencesPol: {
      text: 'Política',
      value: false
    },
    socialSciencesAdm: {
      text: 'Administração',
      value: false
    },
    socialSciencesOthers: {
      text: 'Outras',
      value: false
    }
  },

  planetarySciences: {
    planetarySciencesClimatology: {
      text: 'Climatologia, clima',
      value: false
    },
    planetarySciencesGeofisics: {
      text: 'Geoquímica, Geofísica',
      value: false
    },
    planetarySciencesMineralogy: {
      text: 'Mineralogia',
      value: false
    },
    planetarySciencesPaleontology: {
      text: 'Paleontologia',
      value: false
    },
    planetarySciencesPlanetary: {
      text: 'Ciências planetárias',
      value: false
    },
    planetarySciencesGeology: {
      text: 'Geologia',
      value: false
    },
    planetarySciencesSismology: {
      text: 'Sismologia',
      value: false
    },
    planetarySciencesOceangraphy: {
      text: 'Oceanografia',
      value: false
    },
    planetarySciencesAlgebra: {
      text: 'Álgebra',
      value: false
    },
    planetarySciencesMathAn: {
      text: 'Matemática analítica',
      value: false
    },
    planetarySciencesApMath: {
      text: 'Matemática aplicada',
      value: false
    },
    planetarySciencesGeometry: {
      text: 'Geometria',
      value: false
    },
    planetarySciencesProb: {
      text: 'Probabilidade e Estatística',
      value: false
    },
    planetarySciencesAtoms: {
      text: 'Átomos, moléculas e sólidos',
      value: false
    },
    planetarySciencesBioFisics: {
      text: 'Física Biológica',
      value: false
    },
    planetarySciencesElectronicInstrumentation: {
      text: 'Instrumentação e eletrônica',
      value: false
    },
    planetarySciencesMagnetism: {
      text: 'Magnetismo e Eletromagnetismo',
      value: false
    },
    planetarySciencesParticleFisics: {
      text: 'Física de partículas e nuclear',
      value: false
    },
    planetarySciencesOptics: {
      text: 'Óptica, Laser',
      value: false
    },
    planetarySciencesAcustics: {
      text: 'Acústica',
      value: false
    },
    planetarySciencesAstronomy: {
      text: 'Física teórica, astronomia',
      value: false
    },
    planetarySciencesOthers: {
      text: 'Outras',
      value: false
    }
  },

  humanities: {
    humanitiesKnowlageTeory: {
      text: 'Teorias do conhecimento, Metodologia, Didática, Pedagogia',
      value: false
    },
    humanitiesLang: {
      text: 'Linguística',
      value: false
    },
    humanitiesArt: {
      text: 'Expressão Artística',
      value: false
    },
    humanitiesFilo: {
      text: 'Filosofia e Teologia',
      value: false
    },
    humanitiesOthers: {
      text: 'Outras',
      value: false
    }
  },

  sanitaryEngineering: {
    sanitaryEngineeringBioremediation: {
      text: 'Biorremediação',
      value: false
    },
    sanitaryEngineeringEcosytem: {
      text: 'Gerenciamento de ecossistemas',
      value: false
    },
    sanitaryEngineeringResourses: {
      text: 'Gerenciamento de recursos terrestres, hídricos e florestais',
      value: false
    },
    sanitaryEngineeringRecycling: {
      text: 'Gerenciamento de resíduos, reciclagem',
      value: false
    },
    sanitaryEngineeringAmbientalPolitcs: {
      text: 'Política ambiental, legislação ambiental',
      value: false
    },
    sanitaryEngineeringAmbientalEducation: {
      text: 'Educação ambiental',
      value: false
    },
    sanitaryEngineeringOthers: {
      text: 'Outras',
      value: false
    }
  },

  healthSciences: {
    healthSciencesDiagnostic: {
      text: 'Diagnóstico e tratamento de doenças',
      value: false
    },
    healthSciencesEpid: {
      text: 'Epidemiologia',
      value: false
    },
    healthSciencesGenetics: {
      text: 'Genética',
      value: false
    },
    healthSciencesFisiology: {
      text: 'Fisiologia e Fisiopatologia',
      value: false
    },
    healthSciencesWorkHealth: {
      text: 'Saúde no Trabalho',
      value: false
    },
    healthSciencesPrevention: {
      text: 'Prevenção, profilaxia',
      value: false
    },
    healthSciencesNutrition: {
      text: 'Nutrição',
      value: false
    },
    healthSciencesBioMedicine: {
      text: 'Biomedicina',
      value: false
    },
    healthSciencesNursing: {
      text: 'Enfermagem',
      value: false
    },
    healthSciencesOdontology: {
      text: 'Odontologia',
      value: false
    },
    healthSciencesFisicEducation: {
      text: 'Educação Física',
      value: false
    },
    healthSciencesOthers: {
      text: 'Outras',
      value: false
    }
  },

  computacionalSciences: {
    computacionalSciencesComputacionalEng: {
      text: 'Engenharia da Computação',
      value: false
    },
    computacionalSciencesAlgoritm: {
      text: 'Algoritmos, base de dados',
      value: false
    },
    computacionalSciencesAI: {
      text: 'Inteligência Artificial',
      value: false
    },
    computacionalSciencesNet: {
      text: 'Comunicação e redes',
      value: false
    },
    computacionalSciencesComputacionalScience: {
      text: 'Ciência Computacional',
      value: false
    },
    computacionalSciencesGraphs: {
      text: 'Gráficos',
      value: false
    },
    computacionalSciencesSoftwareEng: {
      text: 'Engenharia de Software',
      value: false
    },
    computacionalSciencesComputerSystems: {
      text: 'Sistemas de computadores',
      value: false
    },
    computacionalSciencesProgramming: {
      text: 'Linguagens de Programação',
      value: false
    },
    computacionalSciencesOthers: {
      text: 'Outras',
      value: false
    }
  }
}

const typeOptions = [
  { label: 'Científica', value: 'cientifica' },
  { label: 'Tecnológica', value: 'tecnologia' }
]

const generalOptions = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
]

export { subAreas, typeOptions, generalOptions }
