const SESSION_CHANNEL = 'app_session_channel';

export default {
  install(Vue) {
    // Cria um canal de Broadcast
    const bc = new BroadcastChannel(SESSION_CHANNEL);

    // ID único para identificar a aba/janela
    const UNIQUE_ID = Math.random().toString(36).substr(2, 9);
    let isSessionActive = false;

    // Função para verificar se o usuário é admin
    const isAdmin = () => {
      // Ajuste conforme a forma que você armazena a role do usuário
      return localStorage.getItem('role') === 'admin';
    };

    // Função para enviar uma mensagem de presença
    const sendPresence = () => {
      bc.postMessage({ type: 'check_presence', id: UNIQUE_ID });
    };

    // Verifica a resposta e redireciona se outra aba/janela já está ativa
    bc.onmessage = (event) => {
      const { type, id } = event.data;

      // Confirma a presença se a aba/janela atual já está ativa
      if (type === 'check_presence' && id !== UNIQUE_ID) {
        if (isSessionActive) {
          bc.postMessage({ type: 'active', id: UNIQUE_ID });
        }
      } else if (type === 'active' && id !== UNIQUE_ID) {
        // Verifica se não é admin e não está na página `/access-denied`
        if (!isAdmin() && !window.location.pathname.includes('/access-denied')) {
          window.location.href = '/access-denied';
        }
      }
    };

    // Inicia a verificação de sessão na primeira carga
    Vue.prototype.$checkSingleWindow = () => {
      if (!isAdmin()) {
        sendPresence();
      }
      isSessionActive = true;
    };

    // Limpa a sessão e fecha o canal quando a aba é fechada
    window.addEventListener('beforeunload', () => {
      isSessionActive = false;
      bc.close();
    });
  },
};
