<template>
  <div class="container">
    <div class="payment">
      <information
        class="information_text"
        :title="title"
      />
      <money-default
        class="money"
        :color="color"
      >
        {{value}}
      </money-default>
    </div>
  </div>
</template>

<script>
import { MoneyDefault } from '@/components/atoms'
import { Information } from '@/components/molecules/Content'

export default {
  components: { MoneyDefault, Information },
  name: 'TitleDescriptionPair',
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;

  .payment {
  display: flex;
  width: 33%;
  }

  .money {
    margin-top: -7px;
    width: fit-content;
  }
}

</style>
