<template>
  <div @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <header-modal @closeClicked="$emit('closeModal')" :title="title"/>
      <div class="modal__body">
        <div class="search__inputs">
          <div class="search__item">
            <input-default
              id="fairEditionModal"
              placeholder="Busque as solicitações de feira por edição"
              type="text"
              v-model="fairEdition"
              :onEnter="addFairEdition"
            />
            <div class="badges" v-if="filtersEdition">
              <span class="badgeFilter" v-for="(item, index) in filtersEdition" :key="`${item}-${index}`">
                {{item}}
                <button @click="removeFilter('filtersEdition', index)"><v-icon medium color="white">mdi-close-circle</v-icon></button>
              </span>
            </div>
          </div>
        </div>
        <label class="checkboxAll"><input type="checkbox" v-model="allEditions">Buscar todas as edições</label>
        <button-default :onClick="checkForm" class="button">Buscar</button-default>
      </div>
    </div>
  </div>
</template>

<script>
import { InputDefault, ButtonDefault } from '@/components/atoms'
import { HeaderModal } from '@/components/organisms'

export default {
  name: 'ModalFilter',
  props: ['modal', 'title'],
  components: { HeaderModal, InputDefault, ButtonDefault },
  data: () => ({
    fairEdition: '',
    filtersEdition: [],
    allEditions: false
  }),
  methods: {
    addFairEdition() {
      this.filtersEdition.push(this.fairEdition)
      this.fairEdition = ''
    },
    removeFilter(array, index) {
      this[array].splice(index, 1)
    },
    checkForm() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  @include d-flex-center;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-height: 221px;
    padding: 25px;
    width: 649px;
  }
}

.modal__body {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;

  .search__inputs {
    margin: 5px 0px 15px 0px;

    .search__item {
      .badges {
        margin-top: 15px;

        span {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          background: $primary;
          border-radius: 49px;
          opacity: 0.8;
          color: $white;
          font-weight: bold;
          font-size: 14px;
          min-width: 79px;
          height: 28px;
          padding: 0px 8px;

          &:not(:last-of-type) {
            margin-right: 10px;
          }

          button {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .checkboxAll {
    align-items: center;
    display: flex;
    color: $almostBlack;
    font-size: 14px;
    margin-top: 10px;

    input {
      zoom: 1.5;
      margin-right: 5px;
    }
  }

  .button {
    align-self: flex-end;
    margin-top: 10px;
  }
}

</style>
