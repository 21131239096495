<template>
  <router-link class="button" :to="{name: 'Home'}" exact>
    <img class="icon-back" src="@/assets/backbutton.png" alt="Back">
  </router-link>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    onClick: {
      type: Function,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  align-items: center;
  background: white;
  border: none;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.219);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 204px;
  justify-content: center;
  max-width: 229px;
  outline: none;
  width: 61px;

  color: #FFFFFF;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 800px) {
    height: 120px;
  }
}
</style>
