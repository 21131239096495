<template>
  <div>
    <template v-if="is_legend">
      <template v-if="active">
        <div class="status" :class="cColor">
          <img :src="require(`@/assets/info-status-${text}.svg`)" />
          {{$t(cText)}}
        </div>
      </template>
      <template v-else>
        <div class="status">
          <img :src="require(`@/assets/info-status-default.svg`)" />
          {{$t(cText)}}
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="active && active_status === true">
        <div class="status" :class="cColor">
          <img :src="require(`@/assets/info-status-${text}.svg`)" />
          {{$t(cText)}}
        </div>
      </template>
      <template v-else>
        <div class="status">
          <img :src="require(`@/assets/info-status-default.svg`)" />
          {{$t(cText)}}
        </div>
      </template>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Status',
  data() {
    return {
      text: 'default',
      slug: {
        'aguardando-resposta': 'aguardando resposta',
        desativada: 'desativada',
        'inscricao-cancelada': 'inscricao cancelada',
        'resposta-enviada': 'resposta enviada',
        'em-analise': 'em analise',
        'pendente-de-analise': 'pendente de analise',
        aceito: 'aceito',
        aceita: 'aceita',
        'nao-aceito': 'nao aceito',
        'nao-aceita': 'nao aceita',
        'inscricao-em-andamento': 'inscricao em andamento'
      }
    }
  },
  props: {
    active_status: {
      type: Boolean,
      default: true
    },
    status: {
      type: String
    },
    active: {
      type: Boolean
    },
    is_active: {
      type: Boolean
    },
    is_legend: {
      type: Boolean
    }
  },
  computed: {
    cText() {
      if (this.active_status === false && this.status === 'inscricao em andamento') {
        return this.textTranslate('inscricao em andamento')
      }
      if (this.active_status === false) {
        return this.textTranslate('desativada')
      }
      if (this.status === 'aguardando resposta') {
        return this.textTranslate('em análise')
      }
      if (this.status === 'inscricao cancelada') {
        return this.textTranslate('inscrição cancelada')
      }
      if (this.status === 'resposta enviada') {
        if (this.is_active === null) {
          return this.textTranslate('em análise')
        }
        if (this.is_active) {
          return this.textTranslate('aceito')
        }
        return this.textTranslate('nao aceito')
      }
      if (this.status) {
        return this.textTranslate(this.status)
      }
      return ''
    },
    cColor() {
      if ((this.active_status === false)) {
        this.text = 'desativada'
        return '__desativada'
      }
      if (this.status && this.active_status !== false) {
        if (this.status === this.slug['pendente-de-analise']) {
          this.text = 'pendente'
          return '__pendente'
        }
        if ((this.status === this.slug['em-analise'])) {
          this.text = 'analise'
          return '__analise'
        }
        if ((this.status === this.slug['inscricao-cancelada'])) {
          this.text = 'nao-aceito'
          return '__nao-aceita'
        }
        if ((this.status === this.slug['aguardando-resposta'])) {
          this.text = 'analise'
          return '__analise'
        }
        if (this.status === this.slug['nao-aceita']) {
          this.text = 'nao-aceito'
          return '__nao-aceita'
        }
        if (this.status === this.slug.aceita) {
          this.text = 'aceito'
          return '__aceita'
        }
        if (this.status === this.slug['inscricao-em-andamento']) {
          this.text = 'andamento'
          return '__andamento'
        }
        if (this.status === this.slug['resposta-enviada']) {
          if (this.is_active === null) {
            this.text = 'analise'
            return '__analise'
          }
          if (this.is_active) {
            this.text = 'aceito'
            return '__aceita'
          }
          this.text = 'nao-aceito'
          return '__nao-aceita'
        }
      }
      return false
    }
  },
  methods: {
    textTranslate(text) {
      if (text === 'desativada') {
        return 'disabled'
      }
      if (text === 'em análise' || text === this.slug['em-analise']) {
        return 'underAnalysis'
      }
      if (text === this.slug.aceito || text === this.slug.aceita) {
        return 'accepted'
      }
      if (text === this.slug['nao-aceito'] || text === this.slug['nao-aceita']) {
        return 'notAccepted'
      }
      if (text === this.slug['pendente-de-analise']) {
        return 'pendingReview'
      }
      if (text === this.slug['inscricao-cancelada'] || text == 'inscrição cancelada') {
        return 'reviewCanceled'
      }
      if (text === this.slug['inscricao-em-andamento']) {
        return 'registrationInProgress'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.status {
  font-weight: bold;
  display: flex;
  align-items: center;
  font-family: "Mulish", sans-serif;
  height: 28px;
  text-transform: capitalize;
  font-size: 14px;
  padding: 5px 14px;
  background-color: #CDCBCB;
  color: #686868;
  border-radius: 43px;
  white-space: nowrap;
  &.__pendente {
    background-color: #FBE265;
    color: #A76F00;
  }
  &.__analise {
    background-color: #98DFEF;
    color: #006076;
  }
  &.__nao-aceita {
    background-color: #EA8084;
    color: #950000;
  }
  &.__aceita {
    background-color: #33B850;
    color: #046619;
  }
  &.__andamento {
    background-color: #E5E5E5;
    color: #303030;
  }
  img {
    margin-right: 5px;
  }
}
</style>
