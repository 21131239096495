<template>
  <div class="search__item">
    <input-select
      :id="id"
      :items="items"
      :label="$t(label)"
      :placeholder="$t(placeholder)"
      :value="value"
      @input="$emit('input', $event)"
    />
    <div class="badges" v-if="selectedItems">
      <span
        class="badgeFilter"
        v-for="(item, index) in selectedItems"
        :key="`${item}-${index}`"
      >
        {{getBadgeLabel(items, item)}}
        <button @click="$emit('onRemoveSelected', index)">
          <v-icon medium color="white">mdi-close-circle</v-icon>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import InputSelect from '@/components/atoms/Inputs/InputSelect.vue'

export default {
  name: 'InputMultiSelect',
  components: {
    InputSelect
  },
  props: {
    id: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    value: [String, Boolean],
    selectedItems: Array,
    label: String,
    placeholder: String
  },
  methods: {
    getBadgeLabel(list, item) {
      return list.filter((object) => object?.value === item)[0]?.text || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.search__item {
  .badges {
    margin-top: 15px;

    span {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      background: $primary;
      border-radius: 49px;
      opacity: 0.8;
      color: $white;
      font-weight: bold;
      font-size: 14px;
      min-width: 79px;
      height: 28px;
      padding: 0px 8px;

      &:not(:last-of-type) {
        margin-right: 10px;
      }

      button {
        margin-left: 5px;
      }
    }
  }
}
</style>
