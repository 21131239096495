import { INCREMENT } from '../mutation-types'

const state = () => ({
  count: 12,
  logged: true
})

const getters = {
  countDone: (state) => state.count,
  logged: (state) => state.logged
}

const actions = {
  add(context) {
    context.commit(INCREMENT)
  }
}

const mutations = {
  [INCREMENT](state) {
    state.count++
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
