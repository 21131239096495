<template>
  <div class="wrapper__analisis">
    <div class="left">
      <analisis-info
        @navSelected="navSelected"
        @reloadInfos="getInfos"
        :fairInfo="fairInfo"
        :userRole="userRole"
      />
    </div>
    <div class="right">
      <div v-show="navOpen" class="right__top mb-6">
        <component
          @vacanciesRequest="(e) => checkVacanciesRequest(e)"
          @vacanciesRequestNumber="(e) => setVacanciesRequestNumber(e)"
          :is="navOpen"
          :navSwitch="navSwitch"
          :fairInfo="fairInfo"
          :errors="errors"
        />
      </div>
      <div
        :v-if="showManagerSolicitations"
        class="right__bottom"
      >
        <manager-solicitation
          @reloadInfos="getInfos"
          :fairInfo="fairInfo"
          :vacanciesRequestFilled="vacanciesRequestFilled"
          :vacanciesRequestNumber="vacanciesRequestNumber"
          @errors="(e) => setErrors(e)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import { AnalisisInfo } from '@/components/organisms'
// eslint-disable-next-line camelcase
import {
  ManagerSolicitation,
  Request,
  SchoolData,
  CurrentFair,
  LastEdition,
  AboutFair,
  Term
} from '@/components/organisms/Consumers'

import { RequestVacancies } from '@/components/organisms/Panels'

export default {
  name: 'AnalisisSolicitationsView',
  components: {
    AnalisisInfo,
    ManagerSolicitation,
    Request,
    SchoolData,
    CurrentFair,
    LastEdition,
    AboutFair,
    Term,
    RequestVacancies
  },
  data: () => ({
    navOpen: 'request',
    fairInfo: {},
    vacanciesRequestFilled: false,
    vacanciesRequestNumber: [],
    navSwitch: 0,
    errors: [],
    showManagerSolicitations: false,
    userRole: ''
  }),
  async created() {
    await this.getInfos()
  },
  mounted() {
    const { data: userInfo } = jwt_decode(localStorage.getItem('token'))
    this.userRole = userInfo.role
  },
  methods: {
    async getInfos() {
      let requestData = localStorage.getItem('affiliationRequestId')
      requestData = requestData || this.$route.params.id
      try {
        requestData = await this.$http.get(`/fair/${ requestData }`)
        this.fairInfo = requestData.data
        this.showManagerSolicitations = (this.fairInfo.status !== 'pendente de analise' && this.fairInfo.is_active)
      } catch (error) {
        console.log('Erro api get: ', error)
      }
    },
    navSelected(e) {
      this.navSwitch += 1
      this.navOpen = e
    },
    checkVacanciesRequest(e) {
      this.vacanciesRequestFilled = e
    },
    setVacanciesRequestNumber(e) {
      this.vacanciesRequestNumber = e
    },
    setSolicitationStatus(e) {
      this.solicitationRefused = e
    },
    setAccepted(e) {
      this.checkBoxAccepted = e
    },
    setErrors(e) {
      if (e == 'resetErrors') { this.errors = [] } else { this.errors.push(e) }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper__analisis {
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 16px;
  margin-left: -40px;
  margin-top: 25px;
  width: 100%;
  min-height: 100vh;

  .left {
    background: #fdfdfd;
    border-radius: 0px 10px 10px 0px;
    align-self: stretch;
  }
}
</style>
