<template>
  <div class="term">
    <div class="left">
      <h3>Termo de responsabilidade</h3>
      <p>Estou ciente dos dados informados no formulário de afiliação, sendo que os mesmos estão de acordo com a feira realizada.</p>
      <p>Assumo o compromisso de credenciar o(s) projeto(s) conforme os critérios de participação e o regulamento da Mostratec e/ou Mostratec Júnior. </p>
      <p>Estou ciente de que, caso a feira selecione um projeto que esteja em desacordo com o estabelecido acima, o projeto não será aceito e a feira afiliada poderá perder o direito de renovar a afiliação no ano seguinte.</p>
    </div>
    <div class="right">
      <div>
        <h4>Nome do responsável pelo preenchimento</h4>
        <span>{{fairInfo.responsible_name}}</span>
      </div>
      <div class="office">
        <h4>Cargo / Função na feira:</h4>
        <span>{{officePosition}}</span>
      </div>
    </div>
    <!-- <div class="inner__wrapper">
      <div class="header">
        <h3>Termo de responsabilidade</h3>
      </div>
      <div class="infos">
        <div class="info__item">
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>Nome do responsável pelo preenchimento</h4>
                <span>{{fairInfo.coordinator_name}}</span>
              </div>
              <div class="data__right">
                <h4>Cargo / Função na feira:</h4>
                <span>{{officePosition}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Term',
  props: ['fairInfo'],
  data: () => ({
    officePosition: ''
  }),
  mounted() {
    this.getInfos()
  },
  watch: {
    fairInfo() {
      this.getInfos()
    }
  },
  methods: {
    getInfos() {
      this.officePosition = this.fairInfo.office
    }
  }
}
</script>

<style lang="scss" scoped>
.term {
  font-family: "Mulish", sans-serif;
  display: flex;
  background: #FDFDFD;
  border-radius: 10px;
  .left {
    flex: 1;
      padding-right: 30px;
    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primary;
    }
    p {
      margin-top: 30px;
      font-family: "OpenSans", sans-serif;
    }
  }
  .right {
    flex: 1;
    padding-left: 30px;
    border-left: 1px solid #CDCBCB;
    .office {
      margin-top: 39px;
    }
    span {
      margin-top: 13px;
    }
  }
}
// .inner__wrapper {
//   background: #FDFDFD;
//   border-radius: 10px;

//   .header {
//     box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
//     border-radius: 10px 0px 0px 10px;
//     height: 57px;
//     padding: 18px 22px;

//     h3 {
//       font-size: 18px;
//       line-height: 23px;
//       color: $primaryDark;
//     }
//   }

//   .infos {
//     padding: 10px 22px 10px;

//     .info__item {
//       margin-top: 25px;

//       .data__wrapper {

//         .data__row {
//           display: grid;
//           grid-template-columns: 1fr 1fr;
//           margin-bottom: 30px;
//           padding-left: 8%;

//           h4 {
//             font-weight: 600;
//             font-size: 15px;
//             line-height: 20px;
//             color: $almostBlack;
//           }

//           span {
//             font-size: 14px;
//             line-height: 18px;
//             color: #686868;
//           }
//         }
//       }
//     }
//   }
// }
</style>
