<template>
  <div class="container-app">
    <div class="inner__wrapper">
      <div class="main">
        <div class="card__top">
          <div class="img">
            <img v-if="type === 'selection'" src="@/assets/project-image-1.png" alt="">
            <img v-else src="@/assets/project-image-2.png" alt="">
          </div>
          <div class="card__top--text">
            <h3>{{$t('projectRegistration')}}</h3>
            <p v-if="type === 'selection'">{{$t('selectionProcess')}}</p>
            <p v-else>{{$t('byAffiliatedFair')}}</p>
          </div>
        </div>
        <p :class="[{'mt-6': type == 'selection'}]">{{$t('fillOutTheFormBelow')}}</p>
        <button @click="() => validateInscription()">{{$t('toStartButton')}}</button>
      </div>
      <div class="image__right">
        <img src="@/assets/bg-projects.png" alt="">
      </div>
    </div>

    <modal-default title="autoSave" @closeModal="toggleModal" :modal="modal">
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/autosave.png" alt="Salvamento automático">
        </div>
        <div class="text">
          <p slot="text">{{$t('automaticSavingModalText1')}}<br>
          {{$t('automaticSavingModalText2')}}</p>
        </div>
      </div>
      <div class="buttonModal">
        <button-default :onClick="goForm">{{$t('yesContinue')}}</button-default>
      </div>
    </modal-default>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { ButtonDefault, FeedbackWarning } from '@/components/atoms'
import { WelcomeProject, ModalDefault } from '@/components/organisms'
import checkDeadline from '@/utils/checkDeadline.js'

export default {
  name: 'InitialStartProject',
  components: {
    WelcomeProject, ModalDefault, ButtonDefault, FeedbackWarning
  },
  data: () => ({
    type: 'selection',
    modal: false,
    errors: []
  }),
  created() {
    this.type = this.$route.params.type
  },
  methods: {
    toggleModal() {
      this.modal = !this.modal
    },
    async validateInscription() {
      const { data: mostratec } = await this.$http.get('/current-mostratec')
      const deadLine = this.type !== 'selection' ? new Date(mostratec.project_date_end) : new Date(mostratec.project_selection_date_end)
      // lert("Type= " + this.type + " / Project_date_end - " + mostratec.project_date_end + " / Project_selection_date_end - " + mostratec.project_selection_date_end + " / DeadLine " + deadLine)
      if (!checkDeadline(deadLine)) {
        this.textWarning = this.$t('projectApplicationPeriodClosed')
        this.errors.push(1)
        return false
      }
      this.modal = true
      return true
    },
    resetErrors() {
      this.errors = []
    },
    goForm() {
      if (this.type == 'selection') {
        this.$router.push('/form-selection')
      } else {
        this.$router.push('/form-via-fair')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$imgUrl: '../../assets/bg-projects.png';

.container-app {
  background-color: #F1F5F6;
  margin-top: 5%;
  width: 100vw;

  .inner__wrapper {
    width: 100%;

    .main {
      padding-left: 70px;
      position: relative;
      z-index: 3;

      .card__top {
        align-items: center;
        display: flex;

        .img {
          width: 250px;
          height: 175px;
        }

        &--text {
          h3 {
            font-family: Mulish;
            font-weight: 900;
            font-size: 40px;
            line-height: 50px;
            color: $primary;
          }
          p {
            font-family: Mulish;
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 1px;
            color: $primaryDark;
            margin-top: 10px;
          }
        }
      }

      p {
        color: $primary;
        font-family: Mulish;
        line-height: 22px;
        max-width: 470px;
      }

      button {
        width: 229px;
        height: 50px;
        background: #FFFFFF;
        box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin-top: 30px;

        font-family: Mulish;
        font-weight: 900;
        letter-spacing: 1.25px;
        color: $primary;
        transition: all 0.3s;

        &:hover {
          box-shadow: 0px 4px 28px rgba(255, 188, 3, 0.699);
        }
      }
    }

    .image__right {
      position: absolute;
      right: 0;
      bottom: 0;

      img {
        max-width: 100%;
      }
    }
  }
}

.modal__body {
  display: flex;
  margin-top: 30px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    font-family: Mulish;
    color: $almostBlack;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-left: 4%;
    text-align: left;
  }
}

.buttonModal {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
