<template>
  <tr :key="data.id">
    <template v-for="(un, indexItem) in data">
        <td  :key="indexItem">{{un || '-'}}</td>
    </template>
    <slot>
      <template v-for="(action, indexAction) in actions">
        <td v-if="action.img" class="center" :key="indexAction">
          <img
            v-if="action.img !== 'downer.png'"
            :src="require(`@/assets/${action.img}`)"
            @click="action.func(data.id)"
          />
          <img
            v-else-if="action.img === 'downer.png'"
            :src="require(`@/assets/${action.img}`)"
            @click="seeMore"
          />
          <div class="showMore" v-if="action.img === 'downer.png' && modal">
            <span class="id">#{{data.id}}</span>
            <heading-three :left="true">
              {{cProjectsInfo.project_title}}
            </heading-three>
            <!-- {{cProjectsInfo}} -->
            <TitleSwitchPair
              :title='switchTitle'
              firstLabel='Não'
              secondLabel='Sim'
              color='primary'
              :switchValue="cProjectsInfo.attended"
            />
            <div class="mostratecInfo">
              <information
              class="premiation"
              color="blue"
              :title="infos[0].text"
              :content="infos[0].value"
              />
              <information
                class="premiation"
                color="blue"
                :title="infos[1].text"
                :content="cProjectsInfo.fair_id ? cProjectsInfo.fair.fair_name : cProjectsInfo.schools.school_name"
              />
            </div>
            <GenericTable
              :header="header"
              :data="studentsInfo"
            />
            <information
              class="premiation"
              color="blue"
              title="Premiação"
              :content="cProjectsInfo.awarding_description || $t('noAwards')"
            />
          </div>
        </td>
      </template>
    </slot>
  </tr>
</template>

<script>
import { InfoDefault } from '@/components/organisms/Infos'
import GenericTable from '@/components/organisms/Tables/GenericTable.vue'
import { HeadingThree } from '@/components/atoms'
import { TitleSwitchPair, Information } from '@/components/molecules'

export default {
  name: 'TableLineAdm',
  components: {
    InfoDefault,
    HeadingThree,
    TitleSwitchPair,
    Information,
    GenericTable
  },
  props: {
    allProjects: {
      type: [Array, Object],
      required: false
    },
    students: {
      type: Array,
      required: true
    },
    switchTitle: {
      type: String,
      required: false
    },
    actions: {
      type: Array,
      required: false
    },
    data: {
      type: Object,
      required: true
    },
    infos: {
      type: Array,
      required: true
    },
    title: {
      type: [String, Array],
      required: false
    }
  },
  mounted() {
    this.studentsInfo = this.getStudentsInfo()
  },
  data() {
    return {
      noActions: null,
      studentsInfo: [],
      projectsInfo: [],
      header: [
        'Nome',
        'Data de nascimento',
        'Série'
      ],
      modal: false,
      wasOnMostratec: true
    }
  },
  computed: {
    cProjectsInfo() {
      return this.allProjects.find((item) => item.id === this.data.id)
    }
  },
  methods: {
    seeMore() {
      this.modal = !this.modal
    },
    closeModal() {
      this.modal = false
    },
    getStudentsInfo() {
      return this.students.map((item) => ({
        full_name: item.full_name,
        birth_date: this.birthDate(item),
        educational_level: item.educational_level
      }))
    },
    birthDate(values) {
      const date = values.birth_date.split('T')
      const formattedDate = date[0].split('-')
      return `${ formattedDate[2] }/${ formattedDate[1] }/${ formattedDate[0] }`
    }
    // checkImage(image) {
    //   if (image === )
    // }
  }

}
</script>

<style lang="scss" scoped>

    tr {

      background-color: #F6F6F6;
      &:nth-child(even) {
        background-color: #FFF;
      }
    }

td {
  position: relative;
}

 tr td, tr th {
  padding: 0 17px;
  height: 61px!important;
}

.center {
  text-align: center;
  cursor: pointer;

  img {
    max-width: 24px;
    width: 100%;
  }
}

.id {
  display: flex;
  color:#1B778B;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  margin-left: 20px;
}

.premiation {
  display: block;
  width: fit-content;
}

.showMore {
  padding: 20px;
  position:absolute;
  z-index: 5;
  width: 514px;
  height: auto;
  right:4%;
  top: 50px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.13);
  border-radius: 10px;
  }

.mostratecInfo {
  display: flex;
  justify-content: space-evenly;
}
</style>
