<template>
  <div>
    <main class="top">
      <h1>{{ $t("projectRegistration") }}</h1>
      <p class="small">{{ $t("welcomeProjectHeader1") }}</p>
      <p>{{ $t("welcomeProjectHeader2") }}</p>
    </main>

    <section class="bottom">
      <router-link class="router_link"
      :to="{name: 'RegisterProjectSelection'}" v-if="!selectionClosed">
        <div class="card__left card">
          <div class="image">
            <img src="@/assets/project-image-1.png" alt="" />
          </div>
          <div class="text">
            <h3>{{ $t("selectionProcess") }}</h3>
            <p>{{ $t("welcomeProjectRegistrationBySelectionProcess") }}</p>
            <div class="levels">
              <p>{{ $t("teachingLevels") }}</p>
              <div class="badges">
                <span class="badge">{{ $t("highSchoolTechnical") }}</span>
                <span class="badge">{{ $t("elementarySchool") }}</span>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link class="router_link" :to="{}" v-else>
        <div class="card__left card">
          <div class="image disabled">
            <img src="@/assets/project-image-1.png" alt="">
          </div>
          <div class="text">
            <h3>{{$t('selectionProcess')}}</h3>
            <p>{{$t('welcomeProjectRegistrationBySelectionProcess')}}</p>
            <div class="levels">
              <p>{{$t('teachingLevels')}}</p>
              <div class="badges disabled">
                <span class="badge">{{$t('highSchoolTechnical')}}</span>
                <span class="badge">{{ $t("elementarySchool") }}</span>
              </div>
              <div class="over">
              <img :src="require(`@/assets/information-circle-red.png`)" alt="informação">
              <span class="over">{{$t('registrationClosed')}}</span>
            </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link class="router_link" :to="{name: 'RegisterProjectFair'}" v-if="!fairClosed">
        <div class="card__right card" >
          <div class="image" disable>
            <img src="@/assets/project-image-2.png" alt="">
          </div>
          <div class="text">
            <h3>{{ $t("byAffiliatedFair") }}</h3>
            <p>{{ $t("welcomeProjectRegistrationByAffiliatedFair") }}</p>
            <div class="levels">
              <p>{{ $t("teachingLevels") }}</p>
              <div class="badges">
                <span class="badge">{{ $t("childEducation") }}</span>
                <span class="badge">{{ $t("elementarySchool") }}</span>
                <span class="badge">{{ $t("highSchoolTechnical") }}</span>
              </div>
            </div>
          </div>
        </div>
      </router-link>

      <router-link class="router_link" :to="{}" v-else>
        <div class="card__right card">
          <div class="image disabled" >
            <img src="@/assets/project-image-2.png" alt="">
          </div>

        <div class="text">
          <h3>{{$t('byAffiliatedFair')}}</h3>
          <p>{{$t('welcomeProjectRegistrationByAffiliatedFair')}}</p>

          <div class="levels">
            <p>{{$t('teachingLevels')}}</p>
            <div class="badges disabled">
              <span class="badge">{{$t('childEducation')}}</span>
              <span class="badge">{{$t('elementarySchool')}}</span>
              <span class="badge">{{$t('highSchoolTechnical')}}</span>
            </div>
            <div class="over">
              <img :src="require(`@/assets/information-circle-red.png`)" alt="informação">
              <span class="over">{{$t('registrationClosed')}}</span>
            </div>
          </div>
        </div>
        </div>
      </router-link>
    </section>

    <footer>
      <img src="@/assets/logo.png" alt="Logo mostratec" />
      <p class="information">
        {{ $t("moreInformation") }}
        <a href="https://www.mostratec.com.br/" target="_blank">www.mostratec.com.br</a>
      </p>
      <p class="information">
        {{ $t("illustrationsBy") }}
        <a href="https://www.icons8.com" target="_blank">www.icons8.com</a>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'WelcomeProject',
  data: () => ({
    fairClosed: false,
    selectionClosed: false
  }),
  mounted() {
    this.returnDate()
  },
  methods: {
    async returnDate() {
      const { data } = await this.$http.get('/current-mostratec')
      this.projectRegisterEndDate = new Date(data.project_date_end)
      this.projectSelectionEndDate = new Date(data.project_selection_date_end)
      this.projectRegisterStartDate = new Date(data.project_date_init)
      this.projectSelectionStartDate = new Date(data.project_selection_date_init)
      this.projectRegisterEndDate.setHours(23, 59, 59)
      this.projectSelectionEndDate.setHours(23, 59, 59)
      this.projectRegisterStartDate.setHours(23, 59, 59)
      this.projectSelectionStartDate.setHours(23, 59, 59)
      this.compareDeadline()
    },
    compareDeadline() {
      const actualDay = new Date().getTime()
      const projectDateEndFormatedTime = this.projectRegisterEndDate.getTime()
      const projectSelectionDateEndFormatedTime = this.projectSelectionEndDate.getTime()
      const projectDateStartFormatedTime = this.projectRegisterStartDate.getTime()
      const projectSelectionDateStartFormatedTime = this.projectSelectionStartDate.getTime()
      this.fairClosed = (actualDay > projectDateEndFormatedTime
        || actualDay < projectDateStartFormatedTime)
      this.selectionClosed = (actualDay > projectSelectionDateEndFormatedTime
        || actualDay < projectSelectionDateStartFormatedTime)
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  margin: 0 auto;
  max-width: 600px;
  text-align: center;

  h1 {
    font-family: Mulish;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: #1b778b;
    margin: 10px 0px 20px 0px;
  }

  p {
    font-family: Mulish;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #1b778b;
    margin: 5px 0px;
  }

  .small {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
}

.bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 30px;
  justify-content: center;
  padding: 30px 5%;
  max-width: 1200px;

  .router_link {
    transition: all 0.2s;
    &:hover {
      box-shadow: 0px 4px 28px rgba(255, 188, 3, 0.664);
      border-radius: 30px;
    }
  }
}

.card {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  display: grid;
  grid-template-rows: 220px 1fr;
  height: 100%;
  padding: 0px;

  .image {
    align-items: center;
    background: #ffffff;
    border-radius: 30px 30px 50% 50%;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
    display: flex;
    height: 220px !important;
    justify-content: center;
    padding: 0px 30px 0px 15px;
    width: 100%;
  }

  .text {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 100%;
    padding: 0px 20px;

    h3 {
      font-family: Mulish;
      font-weight: bold;
      font-size: 20px;
      color: $primaryDark;
    }

    p {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: $primaryDark;
      margin: 20px 0px;
      padding: 0px 25px;
    }

    .levels {
      border-top: 1px solid #cdcbcb;
      margin: 20px 0px;
      padding: 0px 20px;
      text-align: center;
      width: 100%;

      p {
        font-family: Mulish;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 110%;
        text-align: center;
        color: #afaeae;
        margin: 20px 0px 15px 0px;
      }

      .badges {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .badge {
          padding: 3px 16px;
          border: 1px solid #319cb4;
          box-sizing: border-box;
          border-radius: 10px;
          margin: 0px 10px 10px 0px;

          font-family: Mulish;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #319cb4;
        }
      }
    }

    .over{
      display: flex;
      align-items: center;
      justify-content: center;
      filter: grayscale(0) !important;

      span{
        margin-left: 4px;
        color: #F9537B;
      }
    }
  }
}

.disabled {
  filter: grayscale(1);
}

footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 30px;

  img {
    margin-bottom: 10px;
    width: 135px;
  }

  .information {
    text-align: center !important;
    margin-bottom: 10px;

    font-family: Mulish;
    font-size: 16px;
    color: $primaryDark;

    &:first-of-type {
      border-bottom: 1px solid #afaeae;
    }

    a {
      &:hover {
        color: $attention;
      }
    }
  }
}
</style>
