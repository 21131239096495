<template>
  <div class="info-wrapper">
    <heading-three class="info-title">
      {{title}}
    </heading-three>
    <span class="content">
      {{ content }}
    </span>
  </div>
</template>

<script>
import { HeadingThree } from '@/components/atoms/Texts'

export default {
  components: { HeadingThree },
  name: 'BlueInformation',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  display: flex;
  flex-direction: column;
  height: 84%;
  margin: 3px 20px;
  width: 35%;
  justify-content: center;
;

  & > .info-title {
    margin-bottom: 5px;
    color:$primary;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
  }

  & > .content {
    color: $mediumBlue;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

  }
}

</style>
