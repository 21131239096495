<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('whatIsYourEducationalLevel')}}</p>
        <div class="form__text">{{$t('confirmYourEducationalLevel')}}
        <text-warning class="mt-2" :text="$t('considerAllFieldObligatory')" />
        </div>
      </div>

      <input-default
        :class="[{warning: checkErrorIfContains('state')}, 'mb-2']"
        :label="$t('levelsOfEducational')"
        id="level"
        type="text"
        v-model="form.level"
        :disabled='true'
        @input="resetErrors"
      />
      <text-info
        class="mb-2"
        text="As inscrições do tipo Processo de Seleção são voltadas unicamente para o ensino médio e técnico. Confirme se pertence a esse nível de ensino para prosseguir com sua inscrição na Mostratec 2021."
      />
      <input-default
        :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
        :label="$t('activeEditionForSubscription')"
        id="edition"
        type="text"
        v-model="form.edition"
        :disabled='true'
        @input="resetErrors"
      />

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :large='true' :onClick="checkForm" class="button">{{$t('confirmAndContinue')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, ButtonDefault, FeedbackWarning, TextWarning, TextInfo
} from '@/components/atoms'

export default {
  name: 'FormSelectionLevel',
  components: {
    InputDefault, ButtonDefault, FeedbackWarning, TextWarning, TextInfo
  },
  data() {
    return {
      form: {
        level: 'Ensino médio / técnico',
        edition: ''
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation',
      projectSelection: 'projectSelection'
    })
  },
  created() {
    this.getCurrentMostratec()
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      this.resetErrors()
      this.saveForm()
    },
    saveForm() {
      try {
        const formData = this.$store.getters.projectSelection
        const newData = {
          ...formData,
          level: 'high',
          edition: this.form.edition
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem('projectSelectionData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        console.log('erro: ', error)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async getCurrentMostratec() {
      try {
        const { data } = await this.$http.get('/current-mostratec')
        this.form.edition = data.name_edition
      } catch (error) {
        this.form.edition = 'Erro ao carregar edição mostratec'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 640px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
    max-width: 450px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
