<template>
  <div @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <div class="modal__core">
        <div class="icon">
          <img src="@/assets/red-attencion.png" class="icon"/>
        </div>
        <div class="modal__body">
          <header-selection-process
            headerTemplate="splitted"
            @closeClicked="$emit('closeModal')"
            :title="title"
          />
          <slot></slot>
        </div>
      </div>
      <div class="modal__buttons__container">
        <button-default
          :onClick="() => {$emit('closeModal')}"
          class="button__cancel white"
        >{{$t('noCancel')}}</button-default>
        <button-default :onClick="onClick" class="button">{{$t('yesContinue')}}</button-default>
      </div>
    </div>
  </div>
</template>

<script>
import { HeaderSelectionProcess } from '@/components/organisms/Headers'
import { ButtonDefault } from '@/components/atoms'

export default {
  name: 'ModalSelectionProcess',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    modal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  components: { HeaderSelectionProcess, ButtonDefault }
}
</script>

<style lang="scss" scoped>
.icon{
  width: 143px;
  height: 139px;
  position: relative;
  top: 10px;
  left: 9px;
}
.modal__outer {
  @include d-flex-center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;

  .modal__inner {
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    height: 268px;
    padding: 25px;
    width: 649px;
  }

  .modal__core {
    display: flex;
    flex-direction: row;
  }

  .modal__header {
    max-width: 340px;
  }

  .modal__body {
    flex-direction: column;
  }

  .modal__buttons__container {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
  }

  .button {
    line-height: 20, 08px;
    &__cancel {
      color: $primary;
      background-color: white;
    }
  }
}
</style>
