<template>
  <div>
    <div class="mb-4">
      <p class="grey_separator">{{ $t("projectSummary") }}</p>
      <div class="abstract">
        <h1 class="abstract__title">Resumo</h1>
        <p class="abstract__content">{{ summary }}</p>
      </div>
    </div>
    <div class="mb-4">
      <p class="grey_separator">Plano de pesquisa</p>
      <div class="abstract">
        <h1 class="abstract__title">Tema</h1>
        <p class="abstract__content">{{ research_plan.theme }}</p>
      </div>
      <div v-if="level === 'middle' || level === 'high'">
        <div class="abstract">
          <h1 class="abstract__title">{{ $t("JustificationLabel") }}</h1>
          <p class="abstract__content">
            {{ research_plan.justification }}
          </p>
        </div>
        <div class="abstract">
          <h1 class="abstract__title">Problema</h1>
          <p class="abstract__content">{{ research_plan.problem }}</p>
        </div>
      </div>
      <div v-if="level === 'high'" class="abstract">
        <h1 class="abstract__title">Hipóteses</h1>
        <p class="abstract__content">
          {{ research_plan.hypothesis }}
        </p>
      </div>
      <div class="abstract">
        <h1 class="abstract__title">Objetivos</h1>
        <p class="abstract__content">{{ research_plan.objective }}</p>
      </div>
      <div v-if="level === 'high'" class="abstract">
        <h1 class="abstract__title">Objetos</h1>
        <p class="abstract__content">{{ research_plan.object }}</p>
      </div>
      <div v-if="level === 'high'" class="abstract">
        <h1 class="abstract__title">Revisão bibliográfica</h1>
        <p class="abstract__content">
          {{ research_plan.literature_review }}
        </p>
      </div>
      <div class="abstract">
        <h1 class="abstract__title">Metodologia</h1>
        <p class="abstract__content">{{ research_plan.methodology }}</p>
      </div>
      <div v-if="level === 'high'" class="abstract">
        <h1 class="abstract__title">Cronograma</h1>
        <p class="abstract__content">{{ research_plan.schedule }}</p>
      </div>
      <div v-if="level === 'high'" class="abstract">
        <h1 class="abstract__title">Análise de dados</h1>
        <p class="abstract__content">
          {{ research_plan.data_analysis }}
        </p>
      </div>
      <div v-if="level === 'high'" class="abstract">
        <h1 class="abstract__title">Referências</h1>
        <p class="abstract__content">
          {{ research_plan.references }}
        </p>
      </div>
      <div v-if="level !== 'high'" class="abstract">
        <h1 class="abstract__title">{{ $t("ResultsLabel") }}</h1>
        <p class="abstract__content">{{ results }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectSummaryData',
  data() {
    return {
      level: '',
      summary: '',
      research_plan: {},
      results: ''
    }
  },
  props: {
    projectInfo: Object,
    open: Boolean
  },
  mounted() {
    this.level = this.projectInfo.level
    this.summary = this.projectInfo.summary
    this.results = this.projectInfo.results
    this.research_plan = this.projectInfo.research_plan[0]
  }
}
</script>

<style lang="scss" scoped>
.grey_separator {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #303030;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
  width: 785px;
  margin-left: 65px;
  border-bottom: 0.5px solid #cdcdcd;
}

.abstract {
  &__title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #303030;
    margin-top: 35px;
    margin-bottom: 14px;
    margin-left: 81px;
  }
  &__content {
    font-family: Mulish;
    margin-left: 81px;
    width: 733px;
    font-style: normal;
    margin-bottom: 35px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #686868;
  }
}
</style>
