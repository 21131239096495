<template>
  <div>
    <table>
      <thead>
        <tr>
          <template v-for="(item, index) in header">
            <th :key="index">{{ item }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item,index) in data">
          <TableLineAdm
            :key="item.id"
            :allProjects="allProjects"
            :title="title"
            :switchTitle="switchTitle"
            :students="students[index].students"
            :data="item"
            :actions="actions"
            :infos="infos">
          </TableLineAdm>
        </template>
      </tbody>
    </table>
    <Paginator
      v-if=" !noPage"
      :page="cPage"
      :lastPage="cLastPage"
      :perPage="cPerPage"
      :total="cTotal"
      @goPage="newPage=$event"
    />
    <!-- <Paginator
      :page="page"
      :lastPage="lastPage"
      :perPage="perPage"
      :total="total"
    /> -->
  </div>
</template>

<script>

import { Paginator, HeadingThree } from '@/components/atoms'
import { Information } from '@/components/molecules'
import { TableLineAdm } from '@/components/organisms/Lines'
import { InfoDefault } from '@/components/organisms/Infos'

export default {
  name: 'TableDefault',
  props: {
    allProjects: {
      type: [Array, Object],
      required: false
    },
    students: {
      type: Array,
      required: false
    },
    noPage: {
      type: Boolean,
      required: false
    },
    switchTitle: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: true,
      default: []
    },
    title: {
      type: [String, Array],
      required: false
    },
    header: {
      type: Array,
      required: true,
      default: []
    },
    infos: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      required: false,
      default: []
    },
    page: {
      type: [String, Number],
      required: false
    },
    lastPage: {
      type: [String, Number],
      required: false
    },
    perPage: {
      type: [String, Number],
      required: false
    },
    total: {
      type: [String, Number],
      required: false
    },
    maxArray: {
      type: [String, Number],
      required: false
    }

  },
  components: {
    Paginator,
    HeadingThree,
    Information,
    InfoDefault,
    TableLineAdm
  },
  computed: {
    cPerPage() {
      return this.perPage ? this.perPage : 0
    },
    cPage() {
      return this.page ? this.page : 0
    },
    cLastPage() {
      return this.lastPage ? this.lastPage : 0
    },
    cTotal() {
      return this.total ? this.total : 0
    }
  },
  data() {
    return {
      newPage: 1,
      studentsInfo: []
    }
  },
  watch: {
    newPage() {
      this.$emit('goPage', this.newPage)
    },
    changeName(schoolName) {
      this.infos[1].value = schoolName
      return this.infos
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: rgba(27, 119, 139, 0.15);
    color:#1B778B;

    tr {
      th {
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }
    }
  }

  tbody {
    tr {

      background-color: #F6F6F6;
      &:nth-child(even) {
        background-color: #FFF;
      }
    }
  }

  thead, tbody {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
  }
}

tbody tr td, thead tr th {
  padding: 0 17px;
  height: 61px!important;
}

.center {
  text-align: center;
  cursor: pointer;

  img {
    max-width: 24px;
    width: 100%;
  }
}

.showMore {
  position: absolute;
  z-index: 5;
  width: 514px;
  height: 500px;
  right:4%;
  top: 260px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  }
</style>
