<template>
  <section>
    <div v-if="(status === 'revisao-aceita' || status === 'revisao-negada') && !sentEmail" class="footer_email" >
      <h3 class="email_title">{{$t('waitingEmailSent')}}</h3>
      <p class="email_description">
        <strong>
          {{$t('requestApproved')}}
        </strong>
        {{$t('sendEmailDescription')}}
      </p>
      <h1>** {{projectId}} **</h1>
      <!-- <a class="restart_revision">Reavaliar solicitação</a> -->
      <div class="send_email_box">
        <span v-if="role === 'admin'" class="solicitation" @click="reviewSolicitation()">Reavaliar solicitação</span>
        <button-default  :onClick="sendEmail" v-if="this.role!=='crc'">
          {{$t('sendEmail')}}
          <img
            class="img-icon"
            :src="require('@/assets/email-icon.png')"
            alt="send email"
          />
        </button-default>
      </div>
    </div>
    <div v-if="sentEmail" class="footer_email" >
      <h3 class="email_title">{{$t('replySentByEmail')}}</h3>
      <p class="email_description">{{$t('sentEmailDescription')}}</p>
    </div>
    <modal-default :title="$t('emailSent')" @closeModal="toggleModal" :modal="modal">
        <div class="modal__body">
          <div class="icon">
            <img src="@/assets/email-send.png" alt="E-mail enviado" />
          </div>
          <div class="text">
            <p
              slot="text"
            >{{$t('emailSentStudents')}}</p>
            <p slot="text">{{$t('statusUpdateAfterAnalysis')}}</p>
          </div>
        </div>
      </modal-default>
      <feedback-warning
      v-if="revisionFinished"
      :text="textWarning"
      :onClick="toggleFinished"
      color="#33B850"
    />
  </section>
</template>

<script>
import {
  ButtonDefault,
  FeedbackWarning
} from '@/components/atoms'
import { mapActions } from 'vuex'
import { ModalDefault } from '..'

export default {
  name: 'FooterSendEmail',
  components: { ButtonDefault, ModalDefault, FeedbackWarning },
  props: {
    projectId: {
      type: Array,
      defaultStatus: []
    },
    fairId: {
      type: Array,
      defaultStatus: []
    },
    status: {
      type: String,
      require: true
    },
    sentEmail: {
      type: Boolean,
      require: true
    },
    paymentType: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      revisionFinished: false,
      modal: false,
      role: '',
      errors: [],
      fullStatus: []
    }
  },
  async created() {
    this.fullStatus = await this.getStatus()
  },
  async mounted() {
    this.role = localStorage.getItem('role')
  },
  methods: {
    ...mapActions({
      projectsStatusChange: 'projectsStatusChange'
    }),
    async reviewSolicitation() {
      try {
        const { data } = await this.$http.get(`/status/projectus/${ this.projectId[0] }/3/3`)
        this.$router.go()
        /*
        this.status = { name: 'Em revisão', slug: 'em-revisao' }
        this.$store.commit('PROJECTS_STATUS_CHANGE', this.slugToID(this.status.slug))
        */
      } catch (error) {
        console.log('erro: ', error)
      }
    },
    async getStatus() {
      const { data } = await this.$http.get('/status/project?statusType=projeto')
      return data.data
    },
    slugToID(slug) {
      const status = this.fullStatus.find((item) => item.slug === slug)
      //console.log('status: ', status.id)
      //console.log('slug: ', slug)
      return status?.id || null
    },
    async sendEmail() {
      try {
        // alert(1)
        const { data: response } = await this.$http.post('/project/send-answer',
          {
            fairProjects: this.fairId,
            processSelectionProjects: this.projectId
          })
        // alert(2)
        // console.log('this')
        // console.log(this.status)
        // return
        // alert(3)

        if (response.status === 200) {
          // alert(4)
          this.toggleModal()
          this.textWarning = 'Revisão salva com sucesso.'
          this.revisionFinished = true
          if (this.paymentType === 'credenciamento' || this.paymentType === 'nota-empenho') {
            if (this.status === 'revisao-aceita') {
              await this.changeStatus(5)
            }
            if (this.status === 'revisao-negada') {
              await this.changeStatus(11)
            }
          } else if (this.status === 'revisao-negada') {
            await this.changeStatus(11)
          }
        }
      } catch (error) {
        // alert('erro')
        console.log('erro: ', error)
        this.revisionFinished = false
        this.textWarning = 'failedToSendEmail'
        this.errors.push('emails')
      } finally {
        // alert('emmit')
        this.$emit('att-data')
      }
      // alert('emmit2')
      this.$emit('att-data')
    },
    toggleModal() {
      this.modal = !this.modal
    },
    toggleFinished() {
      this.revisionFinished = !this.revisionFinished
    },
    async changeStatus(id) {
      await this.$http.patch(`/status/project/${ this.projectId[0] }`, {
        status_id: id
      })
      await this.$http.patch(`/status/project/${ this.projectId[0] }`, {
        internal_status_id: id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.solicitation {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  margin-top: 18px;
  cursor: pointer;
  color: $textCard;
}

.footer_email{
  background: #fdfdfd;
  border-radius: 10px;
  font-family: Mulish;
  height:100%;
  margin: 35px 0px;
  padding: 25px 35px;

  .email_title{
    color: $primary;
  }
  .email_description{
    font-size: 16px;
    line-height: 20px;
    color: $almostBlack;
  }
  .restart_revision{
    font-size: 17px;
    line-height: 23px;
    text-decoration-line: underline;
    color: #686868;
  }
  .send_email_box{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
.modal__body {
  @include d-flex-center;
  margin-top: 30px;
  justify-content: flex-start;
  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $primaryDark;
    font-size: 16px;
    font-weight: 600;
    line-height: 20, 08px;
    margin-left: 7%;
    text-align: left;
    max-width: 300px;
  }
}
.img-icon {
  width: 21px;
  height: 15px;
  margin-left: 7px;
}
</style>
