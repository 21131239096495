<template>
  <div class="body">
    <div class="centralize">
      <div class="centralize">
        <HeadingThree>{{$t('NumberParticipatingProjects')}}</HeadingThree>
      </div>
      <FinalistsIndicatorsCard :data="participants" />
    </div>
    <LineDefault color='gray'/>
    <h2 class="subtitle" >{{$t('subtitleRS')}}</h2>
    <div class="line">
      <FinalistsIndicatorsCard :data="rs_public" :title="$t('publicSchools')" />
      <div class="separator"/>
      <FinalistsIndicatorsCard :data="rs_private" :title="$t('privateSchools')" />
    </div>
    <LineDefault color='gray'/>
    <h2 class="subtitle" >{{$t('subtitleBR')}}</h2>
    <div class="line">
      <FinalistsIndicatorsCard :data="br_public" :title="$t('publicSchools')" />
      <div class="separator"/>
      <FinalistsIndicatorsCard :data="br_private" :title="$t('privateSchools')" />
    </div>
    <LineDefault color='gray'/>
    <h2 class="subtitle" >{{$t('subtitleEX')}}</h2>
    <div class="line">
      <FinalistsIndicatorsCard :data="foreign_public" :title="$t('publicSchools')" />
      <div class="separator"/>
      <FinalistsIndicatorsCard :data="foreign_private" :title="$t('privateSchools')" />
    </div>
  </div>
</template>

<script>
import { FinalistsIndicatorsCard } from '@/components/organisms'
import { LineDefault } from '@/components/atoms/Lines'
import { DefaultText, HeadingThree } from '@/components/atoms/Texts'

export default {
  name: 'FinalistsIndicators',
  props: {
    projectData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      participants: [],
      rs_public: [],
      rs_private: [],
      br_public: [],
      br_private: [],
      foreign_public: [],
      foreign_private: []
    }
  },
  components: {
    FinalistsIndicatorsCard,
    LineDefault,
    DefaultText,
    HeadingThree
  },
  watch: {
    projectData() {
      this.resetData()
      this.participants.push(
        {
          color: '#686868',
          text: 'Total',
          value: this.projectData.total_projects || '--'
        },
        {
          color: '#319CB4',
          text: 'Estudantes do sexo masculino',
          value: this.projectData.total_male || '--'
        },
        {
          color: '#1B778B',
          text: 'Estudantes do sexo feminino',
          value: this.projectData.total_female || '--'
        }
      )
      this.rs_public.push(
        {
          color: '#686868',
          text: 'Projetos inscritos',
          value: this.projectData.rs_public_schools_projects || '--'
        },
        {
          color: '#05AB5B',
          text: 'Projetos aprovados',
          value: this.projectData.rs_public_schools_accepted_projects || '--'
        },
        {
          color: '#319CB4',
          text: 'Estudantes do sexo masculino',
          value: this.projectData.rs_public_school_male_students || '--'
        },
        {
          color: '#1B778B',
          text: 'Estudantes do sexo feminino',
          value: this.projectData.rs_public_school_female_students || '--'
        }
      )
      this.rs_private.push(
        {
          color: '#686868',
          text: 'Projetos inscritos',
          value: this.projectData.rs_private_schools_projects || '--'
        },
        {
          color: '#05AB5B',
          text: 'Projetos aprovados',
          value: this.projectData.rs_private_schools_accepted_projects || '--'
        },
        {
          color: '#319CB4',
          text: 'Estudantes do sexo masculino',
          value: this.projectData.rs_private_school_male_students || '--'
        },
        {
          color: '#1B778B',
          text: 'Estudantes do sexo feminino',
          value: this.projectData.rs_private_school_female_students || '--'
        }
      )
      this.br_public.push(
        {
          color: '#686868',
          text: 'Projetos inscritos',
          value: this.projectData.brazil_public_schools_projects || '--'
        },
        {
          color: '#05AB5B',
          text: 'Projetos aprovados',
          value: this.projectData.brazil_public_schools_accepted_projects || '--'
        },
        {
          color: '#319CB4',
          text: 'Estudantes do sexo masculino',
          value: this.projectData.brazil_public_school_male_students || '--'
        },
        {
          color: '#1B778B',
          text: 'Estudantes do sexo feminino',
          value: this.projectData.brazil_public_school_female_students || '--'
        }
      )
      this.br_private.push(
        {
          color: '#686868',
          text: 'Projetos inscritos',
          value: this.projectData.brazil_private_schools_projects || '--'
        },
        {
          color: '#05AB5B',
          text: 'Projetos aprovados',
          value: this.projectData.brazil_private_schools_accepted_projects || '--'
        },
        {
          color: '#319CB4',
          text: 'Estudantes do sexo masculino',
          value: this.projectData.brazil_private_school_male_students || '--'
        },
        {
          color: '#1B778B',
          text: 'Estudantes do sexo feminino',
          value: this.projectData.brazil_private_school_female_students || '--'
        }
      )
      this.foreign_public.push(
        {
          color: '#686868',
          text: 'Projetos inscritos',
          value: this.projectData.foreign_public_schools_projects || '--'
        },
        {
          color: '#05AB5B',
          text: 'Projetos aprovados',
          value: this.projectData.foreign_public_schools_accepted_projects || '--'
        },
        {
          color: '#319CB4',
          text: 'Estudantes do sexo masculino',
          value: this.projectData.foreign_public_school_male_students || '--'
        },
        {
          color: '#1B778B',
          text: 'Estudantes do sexo feminino',
          value: this.projectData.foreign_public_school_female_students || '--'
        }
      )
      this.foreign_private.push(
        {
          color: '#686868',
          text: 'Projetos inscritos',
          value: this.projectData.foreign_private_schools_projects || '--'
        },
        {
          color: '#05AB5B',
          text: 'Projetos aprovados',
          value: this.projectData.foreign_private_schools_accepted_projects || '--'
        },
        {
          color: '#319CB4',
          text: 'Estudantes do sexo masculino',
          value: this.projectData.foreign_private_school_male_students || '--'
        },
        {
          color: '#1B778B',
          text: 'Estudantes do sexo feminino',
          value: this.projectData.foreign_private_school_female_students || '--'
        }
      )
    }
  },
  methods: {
    resetData() {
      this.participants = [],
      this.rs_public = [],
      this.rs_private = [],
      this.br_public = [],
      this.br_private = [],
      this.foreign_public = [],
      this.foreign_private = []
    }
  }
}
</script>

<style lang="scss" scoped>
  .body {
    padding: 40px;
  }
  .line {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
  }
  .separator {
    height: 115px;
    width: 1px;
    margin-right: 15px;
    margin-left: 15px;
    border: 0.5px solid $mediumGray;
    align-self: center;
  }
  .subtitle {
    font-family: $font-stack;
    font-style: normal;
    font-weight: bold;
    height: fit-content;
    font-size: 24px;
    line-height: 18px;
    color: #1B778B;
    margin-top: 64px;
  }
  .centralize {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
