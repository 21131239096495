<template>
  <div v-if="refresh" class="add-user">
    <div>
    <form @submit.prevent class="form">
      <div class="mr-bottom">
        <input-default
          :class="[{warning: cName}, 'mb-7']"
          label="Nome de usuário"
          id="name"
          placeholder="Insira o nome de usuário"
          type="text"
          v-model="form.name"
        />
        <input-masked
            :class="[{warning: cBirthDate}]"
            required
            label=birthDate
            id="data-nasc"
            placeholder="dd/mm/yyyy"
            type="text"
            v-model="form.birthDate"

            :mask="['##/##/####']"
          />
        </div>
        <radio-group
          :class="[{warning: cGender}, 'mb-7']"
          label=sex
          id="gender"
          v-model="form.gender"
          :options="gender_options"
          @change="resetErrors"
        />
        <input-default
          :class="[{warning: cOffice}, 'mb-7']"
          label="Cargo na Mostratec"
          id="office"
          placeholder="Insira o cargo"
          type="text"
          v-model="form.office"
        />
        <input-select
          :class="[{warning: cCountry }, 'mb-7']"
          :id="'country'"
          :value= form.country
          :items="countries"
          :label="$t('country')"
          @input="form.country=$event"
        />
        <input-masked
          v-if="form.country == 'Brazil'"
          :class="['mb-7']"
          required
          label="CEP"
          id="zipCode"
          :placeholder="$t('inputCepPlaceholder')"
          type="text"
          v-model="form.zipCode"
          :mask="['#####-###']"
        />
        <template v-if="!cepValidate">
          <input-select-location
            v-if="form.country == 'Brazil'"
            :class="[{warning: cState}, 'mb-7']"
            label= state
            id="state"
            type="text"
            :items="states"
            :onEnter="() => setLocalization('state', form.state)"
            v-model="form.state"
            @input="form.state = $event"
          />
        </template>
        <template v-else>
          <input-default
            v-if="form.country == 'Brazil'"
            :class="[{warning: cState}, 'mb-7']"
            label= state
            id="state"
            type="text"
            v-model="form.state"
            @input="form.state = $event"
          />
        </template>
        <template v-if="!cepValidate">
          <input-select-location
            v-if="form.country === 'Brazil'"
            :class="[{warning: cCity}, 'mb-7']"
            label= city
            id="city"
            type="text"
            :items="cities"
            :onEnter="() => setLocalization('city', form.city)"
            v-model="form.city"
            @input="resetErrors"
          />
        </template>
        <template v-else>
          <input-default
            v-if="form.country === 'Brazil'"
            :class="[{warning: cCity}, 'mb-7']"
            label= city
            id="city"
            type="text"
            v-model="form.city"
            @input="resetErrors"
          />
        </template>
        <input-default
          v-if="form.country"
          :class="[{warning: cNeighborhood}, 'mb-7']"
          label=neighborhood
          id=neighborhood
          placeholder=neighborhoodPlaceholder
          type="text"
          v-model="form.neighborhood"
        />
        <input-default
          v-if="form.country"
          :class="[{warning: cStreet}, 'mb-7']"
          label=address
          id="Street"
          placeholder="Informe seu endereço!"
          type="text"
          v-model="form.street"
        />
        <input-default
          v-if="form.country"
          :class="[{warning: cHouseNumber}, 'mb-7']"
          label="Número"
          id="Number"
          placeholder="Informe o número da casa!"
          type="number"
          v-model="form.house_number"
        />
        <input-default
          v-if="form.country"
          :class="['mb-7']"
          label="Complemento"
          id="Complemento"
          placeholder="Informações adicionais!"
          type="text"
          v-model="form.complement"
        />
        <div class="mr-bottom" v-if="form.country == 'Brazil'">
          <input-masked
            :class="[{warning: cCpf}]"
            required
            label=cpf
            id="cnpj-cpf"
            placeholder="Insira o CPF"
            type="text"
            v-model="form.cpf"
            :mask="['###.###.###-##']"
          />
        </div>
        <input-default
          v-if="form.country && form.country != 'Brazil'"
          :class="[{warning: cCpf }, 'mb-7']"
          label="Identification number"
          id="codigo"
          placeholder=initialRegisterIdentificationNumber
          type="number"
          v-model="form.cpf"
        />
        <input-default
          :class="{warning: cEmail }"
          label=email
          id="email"
          placeholder="Insira o email para confirmação de pré-cadastro"
          type="text"
          v-model="form.email"
        />
        <text-info
          class="mb-2"
          text="O email inserido acima também será utilizado para acessar a plataforma Mostratec após confirmação do pré-cadastro. Precisa ser diferente do email cadastrado na feira afiliada e não pode ser usado em mais de um projeto."
        />
        <text-info
          text="A senha será criada automaticamente e enviada pelo e-mail."
        />
        <input-masked
          :class="[{warning: cPhone}, 'mb-7']"
          label= contactPhone
          id="phone"
          placeholder="(55) 55555-5555 / (55) 5555-5555"
          :mask="['(##) #####-####', '(##) ####-####']"
          type="text"
          v-model="form.phone"
        />
        <input-select
          :class="[{warning: cRole }, 'mb-7']"
          id="role"
          :value= form.role
          :items="profile"
          :label="'Perfil'"
          @input="form.role=$event"
        />
        <div class="buttons">
          <button class="cancel" @click="cancel">{{$t('back')}}</button>
          <button-default :onClick="checkForm" class="button">{{$t('submit')}}</button-default>
        </div>
      </form>
      <modal-default title="Solicitação enviada com sucesso" @closeModal="() => toggleModal('modal')" :modal="modal">
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/email-send.png" alt="Solicitação enviada a MostraTec">
        </div>
        <div class="text">
          <p slot="text">Solicitação enviada com sucesso! Um email será enviado para o email informado no formulário</p>
        </div>
      </div>
    </modal-default>
      <feedback-warning
        v-if="errors.length"
        :text="textWarning"
        :onClick="resetErrors"
        color="#ff9800"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  InputDefault,
  InputMasked,
  InputSelectLocation,
  ButtonDefault,
  TextWarning,
  TextInfo,
  FeedbackWarning,
  InputSelect,
  RadioGroup
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'
import { ModalDefault } from '@/components/organisms/Modals'

export default {
  name: 'FormAddUser',
  data() {
    return {
      form: {
        name: '',
        country: '',
        state: '',
        city: '',
        cpf: '',
        email: '',
        password: this.generatePassword().trim(),
        birthDate: '',
        role: '',
        office: '',
        zipCode: '',
        gender: '',
        neighborhood: '',
        street: '',
        house_number: '',
        complement: ''
      },
      refresh: true,
      gender_options: [
        {
          value: 'masculino',
          label: this.$t('maleSingle')
        },
        {
          value: 'feminino',
          label: this.$t('femaleSingle')
        }
      ],
      profile: [
        { value: 'admin', text: 'Admin' },
        { value: 'secretaria', text: 'Secretaria' },
        { value: 'crc', text: 'CRC' },
        { value: 'restaurante', text: 'Restaurante' },
        { value: 'credenciamento', text: 'Credenciamento' },
        { value: 'apm_credenciamento', text: 'APM Credenciamento' }
      ],
      countries,
      states,
      errors: [],
      cities: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      modal: false,
      firstLoad: false,
      cepValidate: false
    }
  },
  components: {
    InputDefault,
    InputMasked,
    InputSelectLocation,
    ButtonDefault,
    TextWarning,
    TextInfo,
    FeedbackWarning,
    ModalDefault,
    InputSelect,
    RadioGroup
  },
  watch: {
    'form.zipCode': function (newVal, oldVal) {
      const cep = String(this.form.zipCode).replace(/\D/g, '')
      if (cep.length == 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              this.cepValidate = false
              this.errors.push('zipCode')
              this.states = states
              this.textWarning = 'checkTheCep'
            } else {
              this.form.state = data.uf
              this.form.city = data.localidade
              this.form.street = data.logradouro
              this.form.neighborhood = data.bairro
              this.cepValidate = true
            }
          })
          .catch((err) => {
            this.cepValidate = false
            this.errors.push('zipCode')
            this.textWarning = 'checkTheCep'
          })
      } else if (cep.length === 0) {
        this.form.state = ''
        this.form.city = ''
        this.form.street = ''
        this.form.neighborhood = ''
        this.cepValidate = false
      }
    },
    'form.country': function () {
      if (this.form.country !== 'Brazil') {
        this.form.state = ''
        this.form.city = ''
        this.form.street = ''
        this.form.neighborhood = ''
      }
    },

    'form.state': function (newVal, oldVal) {
      this.cities = []
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ this.form.state }/municipios`
      )
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    }),
    cEmail() {
      return this.firstLoad && !this.validateEmail(this.form.email)
    },
    cRole() {
      return this.firstLoad && !this.form.role
    },
    cName() {
      return this.firstLoad && !this.form.name
    },
    cCountry() {
      return this.firstLoad && !this.form.country
    },
    cState() {
      return this.firstLoad && ((this.form.country === 'Brazil') && (!this.form.state))
    },
    cCity() {
      return this.firstLoad && !this.form.city
    },
    cCpf() {
      return this.firstLoad && !this.validateCnpjCPF(this.form.cpf)
    },
    cBirthDate() {
      return this.firstLoad && (this.form.birthDate.length !== 10)
    },
    cGender() {
      return this.firstLoad && !this.form.gender
    },
    cOffice() {
      return this.firstLoad && !this.form.office
    },
    cNeighborhood() {
      return this.firstLoad && !this.form.neighborhood
    },
    cStreet() {
      return this.firstLoad && !this.form.street
    },
    cHouseNumber() {
      return this.firstLoad && !this.form.house_number
    },
    cPhone() {
      if (this.form.phone) {
        return this.firstLoad && (!this.form.phone.length > 13)
      }
      return this.firstLoad
    }
  },
  methods: {
    toggleModal(type) {
      this[type] = !this[type]
    },
    cancel() {
      this.$emit('newIndex', 0)
    },
    generatePassword() {
      const letters = [
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'x', 'w', 'y', 'z'
      ]
      const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      const special = ['!', '@', '#', '$', '%', '?']

      const numsRandom = []
      for (let i = 0; i < 5; i++) {
        numsRandom.push(numbers[Math.ceil(Math.random() * 9)])
      }
      const myPassword = `
        ${ special[Math.ceil(Math.random() * 5)] }${ letters[Math.ceil(Math.random() * 25)] }${ letters[Math.ceil(Math.random() * 25)].toUpperCase() }${ numsRandom }
      `
      return myPassword.split(',').join('')
    },
    back() {
      this.$router.push('/')
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async checkForm() {
      this.firstLoad = true
      this.resetErrors()
      if (this.cRole) {
        this.textWarning = 'Selecione uma permissão!'
        this.errors.push('role')
      }
      if (this.cPhone) {
        this.textWarning = 'Informe seu telefone!'
        this.errors.push('phone')
      }
      if (this.cEmail) {
        this.textWarning = 'Email inválido/campo vazio!'
        this.errors.push('email')
      }
      if (this.cCpf) {
        this.textWarning = 'Informe seu documento'
        this.errors.push('cpf')
      }
      if (this.cHouseNumber) {
        this.textWarning = 'Informe o número da casa!'
        this.errors.push('houseNumber')
      }
      if (this.cStreet) {
        this.textWarning = 'Informe o endereço!'
        this.errors.push('street')
      }
      if (this.cNeighborhood) {
        this.textWarning = 'Informe o Bairro!'
        this.errors.push('neighborhood')
      }
      if (this.cCity) {
        this.textWarning = 'Selecione uma cidade!'
        this.errors.push('city')
      }
      if (this.cState && this.form.country === 'Brazil') {
        this.textWarning = 'Selecione um estado!'
        this.errors.push('state')
      }
      if (this.cCountry) {
        this.textWarning = 'Selecione um país'
        this.errors.push('country')
      }
      if (this.cOffice) {
        this.textWarning = 'Informe o cargo!'
        this.errors.push('office')
      }
      if (this.cGender) {
        this.textWarning = 'Informe o sexo!'
        this.errors.push('sex')
      }
      if (this.cName) {
        this.textWarning = 'Informe o nome!'
        this.errors.push('name')
      }
      if (this.cBirthDate) {
        this.textWarning = 'Informe a data de aniversário!'
        this.errors.push('birthDate')
      }

      if (!this.cEmail
        && !this.cRole
        && !this.cCountry
        && !this.cCity
        && !this.cState
        && !this.cName
        && !this.cOffice
        && !this.cGender
        && !this.cBirthDate
        && !this.cCpf
        && !this.cNeighborhood
        && !this.cStreet
        && !this.cHouseNumber
        && !this.cPhone
      ) {
        this.resetErrors()
        await this.saveForm()
      } else {
        console.log(this.errors)
        if (this.errors.length === 13) {
          this.textWarning = this.$t('warningAllFieldsAreObligatory')
        }
      }
    },
    async saveForm() {
      try {
        const birth = new Date(
          this.form.birthDate.slice(6, 10),
          Number(this.form.birthDate.slice(3, 5)) - 1,
          this.form.birthDate.slice(0, 2)
        )
        const body = {
          email: this.form.email,
          password: this.form.password,
          language: this.lang,
          role: this.form.role,
          name: this.form.name,
          country: this.form.country,
          cep: Number(this.form.zipCode.replace(/\D/g, '')),
          state: this.form.state,
          city: this.form.city,
          neighborhood: this.form.neighborhood,
          street: this.form.street,
          //          house_number: Number(this.form.house_number),
          house_number: this.form.house_number,
          complement: this.form.complement,
          number_phone: this.form.phone,
          cpf: String(this.form.cpf).replace(/\D/g, ''),
          active: true,
          sex: this.form.gender,
          birth_date: `${ birth.getFullYear() },${ birth.getMonth() + 1 },${ birth.getDate() }`
        }
        const createdUser = await this.$http.post('/user/internal', body)
        if (createdUser.status === 204) {
          this.modal = true
        }
        // CLEAR FORM
        this.form.password = this.generatePassword()
        this.form.email = ''
        this.form.gender = ''
        this.form.role = ''
        this.form.name = ''
        this.form.office = ''
        this.form.country = ''
        this.form.state = ''
        this.form.city = ''
        this.form.cpf = ''
        this.form.zipCode = ''
        this.form.street = ''
        this.form.neighborhood = ''
        this.form.complement = ''
        this.form.phone = ''
        this.form.birthDate = ''
        this.firstLoad = false
        this.modal = true
        this.$forceUpdate()
      } catch (error) {
        this.modal = false
        if (
          error.response.data.errors[0].source.pointer
          && error.response.data.errors[0].source.pointer === 'email'
        ) {
          this.errors.push('email')
          this.textWarning = 'registrationFailedEmailAlreadyExists'
        }
        if (
          error.response.data.errors[0].source.pointer
          && error.response.data.errors[0].source.pointer === 'cpf_cnpj'
        ) {
          this.errors.push('cnpj')
          this.textWarning = this.form.country && this.form.country != 'Brazil'
            ? 'registrationFailedIcAlreadyExists'
            : 'registrationFailedCpfAlreadyExists'
        }
      } finally {
        if (this.loading) this.toggleModal('loading')
      }
      // this.modal = false;
      // this.$emit("openModal", "loading", false);
    },
    validateEmail(mail) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
        // this.errors.push("email");
        return false
      }
      return true
    },
    validateCnpjCPF(value) {
      if ((this.form.country != 'Brazil') && (this.form.cpf > 0)) {
        return true
      }
      if (
        !/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/.test(
          value
        )
      ) {
        // this.errors.push("cnpj");
        return false
      }
      return true
    },
    setLocalization(item, value) {
      this[item] = this[value]
    }
  }
}
</script>
<style lang="scss" scoped>
.add-user {
  background-color: $white;
  border-radius: 20px;
  padding: 30px 64px;
}
.mr-bottom {
  margin-bottom: 28px;
}
.cancel {
  width: 229px;
  height: 50px;
  color: $primary;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
  outline: none;
  &:hover {
    opacity: .8;
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
}
</style>
