<template>
  <div>
    <template v-if="educationLevel === 'high'">
      <FormProjectHighUpload :allDataForm="allDataForm" @changeElement="changeElementWrapper" :mostratec_type="mostratec_type" />
    </template>
    <template v-else>
      <FormContract :allDataForm="allDataForm" @changeElement="changeElementWrapper" :mostratec_type="mostratec_type" />
    </template>
  </div>
</template>
<script>
import { FormProjectHighUpload, FormContract } from '..'

export default {
  name: 'WrapperFormProjectContract',
  data() {
    return {}
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String],
    educationLevel: String
  },
  components: {
    FormContract,
    FormProjectHighUpload
  },
  methods: {
    changeElementWrapper(data) {
      this.$emit('changeElement', data)
    }
  },
  mounted() {
    if (this.allDataForm.FormProjectHighUpload) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectHighUpload
      }
    }
    if (this.allDataForm.FormContract) {
      this.form = { ...this.form, ...this.allDataForm.FormContract }
    }
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormProjectHighUpload) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectHighUpload
        }
      }
      if (this.allDataForm.FormContract) {
        this.form = { ...this.form, ...this.allDataForm.FormContract }
      }
    }
  }
}
</script>
