<template>
  <div class="contents">
    <HeadingThree>{{title}}</HeadingThree>
    <div class="rowLine">
      <div v-for="(item, index) in data" :key="index">
        <div class="row">
          <DescriptionNumberPair :color="item.color" :text="item.text" :number="item.value"/>
          <div v-if="index === 0" class="separator" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DescriptionNumberPair } from '@/components/molecules'
import { HeadingThree } from '@/components/atoms/Texts'

export default {
  name: 'FinalistsIndicatorsCard',
  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  components: {
    DescriptionNumberPair,
    HeadingThree
  }
}
</script>

<style lang="scss" scoped>
  .contents {
    margin-bottom: 32px;
  }
  .separator {
    height: 41px;
    width: 1px;
    margin-left: 13px;
    align-self: center;
    border: 0.5px;
    border-style: solid;
    border-color: #CDCBCB;
  }
  .row{
    display: flex;
    flex-wrap: nowrap;
    margin-right: 25px;
    min-height: 110px;
  }
  .rowLine{
    display: flex;
    margin-top: 25px;
    margin-left: 12px;
  }
  @media screen and (max-width: 1024px) {
    .rowLine {
      flex-wrap: wrap;
    }
  }
</style>
