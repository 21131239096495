<template>
  <div>
    <div class="inner__wrapper">
      <div class="header">
        <h3>Dados da escola</h3>
        <div v-if="onlyAdmin === 'admin'">
          <button class="button_edit" v-if="!open" @click="open = !open">
            <strong>EDITAR</strong>
          </button>
          <div class="cancel-and-save">
            <button class="button_cancel" v-if="open" @click="cancelSave">CANCELAR</button>
            <button-default class="button_save" v-if="open" :onClick="checkForm">Salvar</button-default>
          </div>
        </div>
      </div>
      <div class="infos">
        <div class="info__item">
            <div class="title">
              <span>Dados gerais</span>
            </div>
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>Nome da instituição/escola</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('school_name') },
                    'input__space',
                  ]"
                  type="text"
                  v-model="fairInfo.school_name"
                />
                <span v-else>{{ fairInfo.school_name }}</span>
              </div>
              <div class="data__right">
                <h4>Categoria de ensino</h4>
                <RadioGroup
                  v-if="open"
                  :class="['mb-1']"
                  :options="options"
                  v-model="fairInfo.school_type"
                />
                <span v-else>{{
                  fairInfo.school_type === "private" ? "Particular" : "Pública"
                }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>Nome do responsável da instituição/escola</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('coordinator_name') },
                    'input__space',
                  ]"
                  max="100"
                  type="text"
                  v-model="fairInfo.coordinator_name"
                />
                <span v-else>{{ fairInfo.coordinator_name }}</span>
              </div>
              <div class="data__right">
                <h4>E-mail da instituição/escola</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('school_email') },
                    'input__space',
                  ]"
                  type="text"
                  v-model="fairInfo.school_email"
                />
                <span v-else>{{ fairInfo.school_email }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="info__item">
          <div class="title">
            <span>{{ $t("address") }}</span>
          </div>
          <div class="data__wrapper">
            <div class="data__row">
              <div v-if="fairInfo.country === 'Brazil'" class="data__left">
                <h4>CEP</h4>
                <input-masked
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('zipcode') },
                    'input__space',
                  ]"
                  required
                  id="zipCode"
                  type="text"
                  v-model="fairInfo.zipcode"
                  :mask="['#####-###']"
                />
                <span v-else>{{ fairInfo.zipcode }}</span>
              </div>
              <div class="data__right">
                <h4>{{ $t("uf") }}</h4>
                <div v-if="open">
                  <input-select
                    v-if="fairInfo.country === 'Brazil'"
                    :class="['input__space']"
                    id="serie"
                    :items="states"
                    type="text"
                    v-model="fairInfo.state"
                  />
                  <input-default
                    v-if="fairInfo.country && fairInfo.country !== 'Brazil'"
                    :class="['input__space']"
                    id="state"
                    type="text"
                    v-model="fairInfo.state"
                  />
                </div>
                <span v-else>{{ fairInfo.state }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{ $t("city") }}</h4>
                <div v-if="open">
                  <input-select
                    v-if="fairInfo.country === 'Brazil'"
                    :class="['input__space']"
                    id="serie"
                    :items="cities"
                    v-model="fairInfo.city"
                    :selected="fairInfo.city"
                  />
                  <input-default
                    v-if="fairInfo.country !== 'Brazil'"
                    :class="['input__space']"
                    id="serie"
                    type="text"
                    v-model="fairInfo.city"
                  />
                </div>
                <span v-else>{{ fairInfo.city }}</span>
              </div>
              <div class="data__right">
                <h4>{{ $t("address") }}</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('street') },
                    'input__space',
                  ]"
                  id="address"
                  type="text"
                  :maxlength="100"
                  v-model="fairInfo.street"
                />
                <span v-else>{{ fairInfo.street }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>Número</h4>
                <input-default
                  v-if="open && fairInfo.state"
                  :class="[
                    { warning: checkErrorIfContains('house_number') },
                    'input__space',
                  ]"
                  id="house_number"
                  type="text"
                  v-model="fairInfo.house_number"
                />
                <span v-else>{{ fairInfo.house_number }}</span>
              </div>
              <div class="data__right">
                <h4>Complemento</h4>
                <input-default
                  v-if="open"
                  :class="['input__space']"
                  id="complement"
                  type="text"
                  v-model="fairInfo.complement"
                />
                <span v-else>{{
                  fairInfo.complement ? fairInfo.complement : $t("notInformed")
                }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{ $t("neighborhood") }}</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('neighborhood') },
                    'input__space',
                  ]"
                  id="neighborhood"
                  type="text"
                  v-model="fairInfo.neighborhood"
                />
                <span v-else
                  >{{
                    fairInfo.neighborhood
                      ? fairInfo.neighborhood
                      : $t("notInformed")
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="info__item">
          <div class="title">
            <span>{{ $t("contacts") }}</span>
          </div>
          <div class="data__wrapper">
            <div v-for="(item, index) in contacts" :key="index">
              <h3>Contato <span v-if="item.main"> (Principal)</span></h3>
              <div class="data__row">
                <div class="data__left">
                  <h4>{{ $t("name") }}</h4>
                  <input-default
                    v-if="open"
                    :class="[
                      { warning: checkErrorIfContains('contact_name') },
                      'input__space',
                    ]"
                    type="text"
                    v-model="item.contact_name"
                  />
                  <span v-else>{{ item.contact_name }}</span>
                </div>
                <div class="data__right">
                  <h4>E-mail</h4>
                  <input-default
                    v-if="open"
                    :class="[
                      { warning: checkErrorIfContains('email') },
                      'input__space',
                    ]"
                    type="text"
                    v-model="item.email"
                  />
                  <span v-else>{{ item.email }}</span>
                </div>
              </div>
              <div class="data__row">
                <div class="data__left">
                  <h4>Telefone</h4>
                  <input-default
                    v-if="open"
                    :class="[
                      { warning: checkErrorIfContains('phone') },
                      'input__space',
                    ]"
                    type="text"
                    v-model="item.phone"
                  />
                  <span v-else>{{ item.phone }}</span>
                </div>
                <div class="data__right">
                  <h4>Cargo</h4>
                  <input-default
                    v-if="open"
                    :class="[
                      { warning: checkErrorIfContains('office') },
                      'input__space',
                    ]"
                    type="text"
                    v-model="item.office"
                  />
                  <span v-else>{{ item.office }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputDefault,
  RadioGroup,
  ButtonDefault,
  InputMasked,
  InputSelect,
  textWarning,
  FeedbackWarning
} from '@/components/atoms'

import { countries, states } from '@/utils/locations'

export default {
  name: 'SchoolData',
  components: {
    InputDefault,
    RadioGroup,
    ButtonDefault,
    InputMasked,
    InputSelect,
    textWarning,
    FeedbackWarning
  },
  props: ['fairInfo'],
  data() {
    return {
      schoolType: '',
      contacts: [],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      open: false,
      options: [
        { label: this.$t('public'), value: 'public' },
        { label: this.$t('private'), value: 'private' }
      ],
      cities: [],
      states,
      onlyAdmin: ''
    }
  },
  mounted() {
    this.getInfos()
    this.executeGetCities(this.fairInfo.state)
    this.onlyAdmin = localStorage.getItem('role')
  },
  watch: {
    cStateSelected() {
      this.executeGetCities(this.fairInfo.state)
    }
  },
  computed: {
    cStateSelected() {
      if (this.fairInfo) {
        return this.fairInfo.state
      }
    }
  },
  methods: {
    getInfos() {
      this.contacts = this.fairInfo.fair_contacts
    },
    checkForm() {
      this.resetErrors()
      for (const key in this.fairInfo) {
        if (!this.fairInfo[key]) {
          if (
            key === 'complement'
            || key === 'country_code'
            || key === 'justification'
            || key === 'status_is_active'
            || key === 'observation'
            || key === 'school_id'
            || key === 'schools'
            || key === 'has_crc'
            || key === 'next_edition_confirmed'
          ) {
          } else if (this.fairInfo.country !== 'Brazil') {} else { this.errors.push(`${ key }`) }
        }
      }

      this.contacts.forEach((item, index) => {
        for (const key in item) {
          if (!this.contacts[index][key]) {
            if (key === 'main') {
            } else {
              this.errors.push(`${ key }`)
            }
          }
        }
      })
      if (this.errors.length) {
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }

      if (!this.errors.length) {
        if (!this.validateEmailSchool(this.fairInfo.school_email)) {
          this.textWarning = 'E-mail inválido!'
          return false
        }
        if (this.fairInfo.country === 'Brazil' && this.fairInfo.zipcode.length < 9) {
          this.errors.push('zipcode')
          this.textWarning = 'Informe um CEP válido'
          return false
        }
        this.contacts.forEach((item, index) => {
          if (!this.validateEmailContact(this.contacts[index].email, index)) {
            this.textWarning = 'E-mail inválido!'
            return false
          }
        })
        if (!this.errors.length) {
          this.editForm()
        }
      }
    },
    async editForm() {
      const fairId = this.fairInfo.id

      const body = {
        fair: {
          school_name: this.fairInfo.school_name,
          school_type: this.fairInfo.school_type,
          coordinator_name: this.fairInfo.coordinator_name,
          school_email: this.fairInfo.school_email,
          zipcode: String(this.fairInfo.zipcode).replace(/\D/g, ''),
          street: this.fairInfo.street,
          //          house_number: parseInt(this.fairInfo.house_number),
          house_number: this.fairInfo.house_number,
          state: this.fairInfo.state,
          city: this.fairInfo.city,
          neighborhood: this.fairInfo.neighborhood,
          complement: this.fairInfo.complement,
          fair_name: this.fairInfo.fair_name,
          fairs_coordinator_name: this.fairInfo.fairs_coordinator_name,
          fair_type: this.fairInfo.fair_type,
          website: this.fairInfo.website
        },
        fair_contacts: this.contacts
      }

      try {
        await this.$http.put(`/edit-fair/${ fairId }`, body)
        location.reload()
      } catch (error) {
        console.log(error.message)
      }
    },
    cancelSave() {
      location.reload()
    },
    executeGetCities(state) {
      if (this.fairInfo.country !== 'Brazil') return false
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`
      )
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmailSchool(mail) {
      const onlyEmail = mail.replace(/ /g, '')
      this.fairInfo.school_email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('school_email')
        return false
      }
      return true
    },
    validateEmailContact(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.contacts[index].email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.inner__wrapper {
  background: #fdfdfd;
  border-radius: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 0px 22px 25px;

    .info__item {
      margin-top: 25px;

      .title {
        align-items: center;
        border-bottom: 0.5px solid #afaeae;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        padding-bottom: 5px;

        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: $almostBlack;
        }
      }

      .data__wrapper {
        h3 {
          border-bottom: 1px solid #3e778d;
          color: $primary;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
          margin-left: 8%;
          margin-right: 8%;
          padding-bottom: 10px;
        }

        .data__row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 30px;
          padding-left: 8%;

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: #686868;
          }
        }
      }
    }
  }
}

.button_edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

/*   position: absolute; */
  width: 100px;
  height: 36px;
  left: 1222px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  margin: 0px 10px;

  width: 106px;
  height: 36px;
  left: 1215px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_cancel {
  box-sizing: border-box;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 32px;

  width: 113px;
  height: 36px;
  left: 1105px;
  top: 126px;

  border: 1px solid #26768d;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;

  color: #26768D;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.cancel-and-save {
  display: flex;
  justify-content: space-between;
}
</style>
