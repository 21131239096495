<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('vacanciesRequest')}}</span>
      </div>
      <div class="form__info">
        <span
          class="mb-3"
        >{{$t('categoryDesired')}}</span>
        <text-warning
          :text="`vacanciesNumberRequested`"
        />
        <text-warning
          :text="`ifYourFairDoesNotIntendCoverAllTypes`"
        />
      </div>

      <main class="form__main mt-3">
        <div class="form__item mb-5">
          <input-checkbox-title
            class="mb-7"
            label="childEducationCompleted"
            v-model="activeChild"
            id="infantil"
            @change="resetErrors"
          />
          <div v-if="activeChild">
            <input-masked
              :class="{warning: checkErrorIfContains('quantityChild')}"
              label="vacanciesNumber"
              placeholder="vacanciesNumberPlaceholder"
              v-model="quantityChild"
              type="number"
              :mask="['####']"
              @input="resetErrors"
            />
          </div>
        </div>

        <!-- div class="form__item mb-5">
          <input-checkbox-title
            class="mb-7"
            label="elementarySchoolCompleted"
            v-model="activeElementary"
            id="fundamental"
            @change="resetErrors"
          />
          <div v-if="activeElementary">
            <input-masked
              :class="{warning: checkErrorIfContains('quantityElementary')}"
              label="vacanciesNumber"
              placeholder="vacanciesNumberPlaceholder"
              v-model="quantityElementary"
              type="number"
              :mask="['####']"
              @input="resetErrors"
            />

          </div>
        </div -->

        <div class="form__item mb-5">
          <input-checkbox-title
            class="mb-7"
            label="elementarySchoolCategory1"
            v-model="activeElementaryCat1"
            id="fundamental"
            @change="resetErrors"
          />
          <div v-if="activeElementaryCat1">
            <input-masked
              :class="{warning: checkErrorIfContains('quantityElementaryCat1')}"
              label="vacanciesNumber"
              placeholder="vacanciesNumberPlaceholder"
              v-model="quantityElementaryCat1"
              type="number"
              :mask="['####']"
              @input="resetErrors"
            />

          </div>
        </div>
        <div class="form__item mb-5">
          <input-checkbox-title
            class="mb-7"
            label="elementarySchoolCategory2"
            v-model="activeElementaryCat2"
            id="fundamental"
            @change="resetErrors"
          />
          <div v-if="activeElementaryCat2">
            <input-masked
              :class="{warning: checkErrorIfContains('quantityElementaryCat2')}"
              label="vacanciesNumber"
              placeholder="vacanciesNumberPlaceholder"
              v-model="quantityElementaryCat2"
              type="number"
              :mask="['####']"
              @input="resetErrors"
            />

          </div>
        </div>
        <div class="form__item mb-5">
          <input-checkbox-title
            class="mb-7"
            label="elementarySchoolCategory3"
            v-model="activeElementaryCat3"
            id="fundamental"
            @change="resetErrors"
          />
          <div v-if="activeElementaryCat3">
            <input-masked
              :class="{warning: checkErrorIfContains('quantityElementaryCat3')}"
              label="vacanciesNumber"
              placeholder="vacanciesNumberPlaceholder"
              v-model="quantityElementaryCat3"
              type="number"
              :mask="['####']"
              @input="resetErrors"
            />

          </div>
        </div>
        <div class="form__item mb-5">
          <input-checkbox-title
            class="mb-7"
            label="elementarySchoolCategoryEja"
            v-model="activeElementaryCatEja"
            id="fundamental"
            @change="resetErrors"
          />
          <div v-if="activeElementaryCatEja">
            <input-masked
              :class="{warning: checkErrorIfContains('quantityElementaryCatEja')}"
              label="vacanciesNumber"
              placeholder="vacanciesNumberPlaceholder"
              v-model="quantityElementaryCatEja"
              type="number"
              :mask="['####']"
              @input="resetErrors"
            />

          </div>
        </div>

        <div class="form__item mb-5">
          <input-checkbox-title
            class="mb-7"
            label="highSchoolAndOrTechnical"
            v-model="activeHighSchool"
            id="medio"
            @change="resetErrors"
          />
          <div v-if="activeHighSchool">
            <input-masked
              :class="{warning: checkErrorIfContains('quantityHighSchool')}"
              label="vacanciesNumber"
              placeholder="vacanciesNumberPlaceholder"
              v-model="quantityHighSchool"
              type="number"
              :mask="['####']"
              @input="resetErrors"
            />
          </div>
        </div>
      </main>

      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault,
  InputCheckboxTitle,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  InputMasked
} from '@/components/atoms'

export default {
  name: 'FormSchoolDataAddress',
  components: {
    InputDefault,
    InputCheckboxTitle,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    InputMasked
  },
  data: () => ({
    errors: [],
    textWarning:
      'oneLevelIsNecessary',
    activeChild: false,
    quantityChild: '',
    activeElementary: false,
    activeElementaryCat1: false,
    activeElementaryCat2: false,
    activeElementaryCat3: false,
    activeElementaryCatEja: false,
    quantityElementary: '',
    quantityElementaryCat1: '',
    quantityElementaryCat2: '',
    quantityElementaryCat3: '',
    quantityElementaryCatEja: '',
    otherElementary: '',
    formElementary: {
      biology: false,
      biologyChemistry: false,
      science: false,
      scienceComputing: false,
      scienceMathematics: false,
      socialSciences: false,
      engineering: false,
      engineeringMaterials: false,
      environment: false,
      medicine: false
    },
    activeHighSchool: false,
    quantityHighSchool: '',
    otherHighSchool: '',
    formHighSchool: {
      biology: false,
      biologyChemistry: false,
      science: false,
      scienceComputing: false,
      scienceMathematics: false,
      socialSciences: false,
      engineering: false,
      engineeringMaterials: false,
      environment: false,
      medicine: false
    }
  }),
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.vacancies_requests) {
      this.fairAffiliation.vacancies_requests.forEach((item) => {
        if (item.level === 'grade' && item.quantity_registered) {
          this.activeChild = true
          this.quantityChild = item.quantity_registered
        }
        if (item.level === 'middle' && item.quantity_registered) {
          this.activeElementary = true
          this.otherElementary = item.other
          this.quantityElementary = item.quantity_registered
          // this.formElementary.biology = item.biologia
          // this.formElementary.biologyChemistry = item.bioquimica_e_quimica
          // this.formElementary.science = item.ciencias
          // this.formElementary.scienceComputing = item.ciencias_da_computacao
          // this.formElementary.scienceMathematics = item.ciencias_planetarias_e_terrestres
          // this.formElementary.socialSciences = item.ciencias_sociais
          // this.formElementary.engineering = item.engenharia
          // this.formElementary.engineeringMaterials = item.engenharia_e_materiais
          // this.formElementary.environment = item.gerenciamento_do_meio_ambiente
          // this.formElementary.medicine = item.medicina_e_saude
        }
        if (item.level === 'middle_cat_1' && item.quantity_registered) {
          this.activeElementaryCat1 = true
          this.otherElementary = item.other
          this.quantityElementaryCat1 = item.quantity_registered
        }
        if (item.level === 'middle_cat_2' && item.quantity_registered) {
          this.activeElementaryCat2 = true
          this.otherElementary = item.other
          this.quantityElementaryCat2 = item.quantity_registered
        }
        if (item.level === 'middle_cat_3' && item.quantity_registered) {
          this.activeElementaryCat3 = true
          this.otherElementary = item.other
          this.quantityElementaryCat3 = item.quantity_registered
        }
        if (item.level === 'middle_cat_eja' && item.quantity_registered) {
          this.activeElementaryCatEja = true
          this.otherElementary = item.other
          this.quantityElementaryCatEja = item.quantity_registered
        }
        if (item.level === 'high' && item.quantity_registered) {
          this.activeHighSchool = true
          this.otherHighSchool = item.other
          this.quantityHighSchool = item.quantity_registered
          // this.formHighSchool.biology = item.biologia
          // this.formHighSchool.biologyChemistry = item.bioquimica_e_quimica
          // this.formHighSchool.science = item.ciencias
          // this.formHighSchool.scienceComputing = item.ciencias_da_computacao
          // this.formHighSchool.scienceMathematics = item.ciencias_planetarias_e_terrestres
          // this.formHighSchool.socialSciences = item.ciencias_sociais
          // this.formHighSchool.engineering = item.engenharia
          // this.formHighSchool.engineeringMaterials = item.engenharia_e_materiais
          // this.formHighSchool.environment = item.gerenciamento_do_meio_ambiente
          // this.formHighSchool.medicine = item.medicina_e_saude
        }
      })
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      this.resetErrors()
      this.textWarning = 'oneLevelIsNecessary'
      if (
        !this.activeChild
        // && !this.activeElementary
        && !this.activeHighSchool
        && !this.activeElementaryCat1
        && !this.activeElementaryCat2
        && !this.activeElementaryCat3
        && !this.activeElementaryCatEja
      ) {
        this.errors.push(1)
        return false
      }

      if (this.activeChild) {
        if (!this.quantityChild) {
          this.errors.push('quantityChild')
          this.textWarning = 'makeSureToFillIn' + ' Child'
        }
      } else {
        this.quantityChild = ''
      }
      /*
      if (this.activeElementary) {
        if (!this.quantityElementary) {
          this.errors.push('quantityElementary')
          this.textWarning = 'makeSureToFillIn' + ' Elem'
          return false
        }
      } else {
        this.otherElementary = ''
        this.quantityElementary = ''
        this.formElementary.biology = false
        this.formElementary.biologyChemistry = false
        this.formElementary.science = false
        this.formElementary.scienceComputing = false
        this.formElementary.scienceMathematics = false
        this.formElementary.socialSciences = false
        this.formElementary.engineering = false
        this.formElementary.engineeringMaterials = false
        this.formElementary.environment = false
        this.formElementary.medicine = false
      }
      */
      if (this.activeElementaryCat1) {
        if (!this.quantityElementaryCat1) {
          this.errors.push('quantityElementaryCat1')
          this.textWarning = 'makeSureToFillIn' + ' Cat1'
          return false
        }
      } else {
        this.otherElementaryCat1 = ''
        this.quantityElementaryCat1 = ''
      }
      if (this.activeElementaryCat2) {
        if (!this.quantityElementaryCat2) {
          this.errors.push('quantityElementaryCat2')
          this.textWarning = 'makeSureToFillIn' + ' Cat2'
          return false
        }
      } else {
        this.otherElementaryCat2 = ''
        this.quantityElementaryCat2 = ''
      }
      if (this.activeElementaryCat3) {
        if (!this.quantityElementaryCat3) {
          this.errors.push('quantityElementaryCat3')
          this.textWarning = 'makeSureToFillIn' + ' Cat3'
          return false
        }
      } else {
        this.otherElementaryCat3 = ''
        this.quantityElementaryCat3 = ''
      }
      if (this.activeElementaryCatEja) {
        if (!this.quantityElementaryCatEja) {
          this.errors.push('quantityElementaryCatEja')
          this.textWarning = 'makeSureToFillIn' + ' Eja'
          return false
        }
      } else {
        this.otherElementaryCatEja = ''
        this.quantityElementaryCatEja = ''
      }

      if (this.activeHighSchool) {
        if (!this.quantityHighSchool) {
          this.errors.push('quantityHighSchool')
          this.textWarning = 'makeSureToFillIn' + ' HS'
          return false
        }
      } else {
        this.otherHighSchool = ''
        this.quantityHighSchool = ''
        this.formHighSchool.biology = false
        this.formHighSchool.biologyChemistry = false
        this.formHighSchool.science = false
        this.formHighSchool.scienceComputing = false
        this.formHighSchool.scienceMathematics = false
        this.formHighSchool.socialSciences = false
        this.formHighSchool.engineering = false
        this.formHighSchool.engineeringMaterials = false
        this.formHighSchool.environment = false
        this.formHighSchool.medicine = false
      }
      if (!this.errors.length) {
        this.saveForm()
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    saveForm() {
      try {
        let screen
        const formData = this.$store.getters.fairAffiliation
        const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
        if (currentScreen < 8) {
          screen = 8
        } else {
          screen = currentScreen
        }
        const newData = {
          ...formData,
          vacancies_requests: this.formatVacanciesRequest(),
          mostratec_type: this.getMostratecType(),
          currentScreen: screen
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
      }
    },
    formatVacanciesRequest() {
      const formated = []
      if (this.activeChild) {
        formated.push({
          level: 'grade',
          quantity_registered: +this.quantityChild,
          quantity_granted: 0,
          quantity_exempt: 0,
          justification: '',
          biologia: false,
          bioquimica_e_quimica: false,
          ciencias: false,
          ciencias_da_computacao: false,
          ciencias_planetarias_e_terrestres: false,
          ciencias_sociais: false,
          engenharia: false,
          engenharia_e_materiais: false,
          gerenciamento_do_meio_ambiente: false,
          medicina_e_saude: false,
          other: ''
        })
      }
      if (this.activeElementary) {
        formated.push({
          level: 'middle',
          quantity_registered: +this.quantityElementary,
          quantity_granted: 0,
          quantity_exempt: 0,
          justification: '',
          biologia: false,
          bioquimica_e_quimica: false,
          ciencias: false,
          ciencias_da_computacao: false,
          ciencias_planetarias_e_terrestres: false,
          ciencias_sociais: false,
          engenharia: false,
          engenharia_e_materiais: false,
          gerenciamento_do_meio_ambiente: false,
          medicina_e_saude: false,
          other: ''

        })
      }
      if (this.activeElementaryCat1) {
        formated.push({
          level: 'middle_cat_1',
          quantity_registered: +this.quantityElementaryCat1,
          quantity_granted: 0,
          quantity_exempt: 0,
          justification: '',
          biologia: false,
          bioquimica_e_quimica: false,
          ciencias: false,
          ciencias_da_computacao: false,
          ciencias_planetarias_e_terrestres: false,
          ciencias_sociais: false,
          engenharia: false,
          engenharia_e_materiais: false,
          gerenciamento_do_meio_ambiente: false,
          medicina_e_saude: false,
          other: ''

        })
      }
      if (this.activeElementaryCat2) {
        formated.push({
          level: 'middle_cat_2',
          quantity_registered: +this.quantityElementaryCat2,
          quantity_granted: 0,
          quantity_exempt: 0,
          justification: '',
          biologia: false,
          bioquimica_e_quimica: false,
          ciencias: false,
          ciencias_da_computacao: false,
          ciencias_planetarias_e_terrestres: false,
          ciencias_sociais: false,
          engenharia: false,
          engenharia_e_materiais: false,
          gerenciamento_do_meio_ambiente: false,
          medicina_e_saude: false,
          other: ''
        })
      }
      if (this.activeElementaryCat3) {
        formated.push({
          level: 'middle_cat_3',
          quantity_registered: +this.quantityElementaryCat3,
          quantity_granted: 0,
          quantity_exempt: 0,
          justification: '',
          biologia: false,
          bioquimica_e_quimica: false,
          ciencias: false,
          ciencias_da_computacao: false,
          ciencias_planetarias_e_terrestres: false,
          ciencias_sociais: false,
          engenharia: false,
          engenharia_e_materiais: false,
          gerenciamento_do_meio_ambiente: false,
          medicina_e_saude: false,
          other: ''
        })
      }
      if (this.activeElementaryCatEja) {
        formated.push({
          level: 'middle_cat_eja',
          quantity_registered: +this.quantityElementaryCatEja,
          quantity_granted: 0,
          quantity_exempt: 0,
          justification: '',
          biologia: false,
          bioquimica_e_quimica: false,
          ciencias: false,
          ciencias_da_computacao: false,
          ciencias_planetarias_e_terrestres: false,
          ciencias_sociais: false,
          engenharia: false,
          engenharia_e_materiais: false,
          gerenciamento_do_meio_ambiente: false,
          medicina_e_saude: false,
          other: ''
        })
      }
      if (this.activeHighSchool) {
        formated.push({
          level: 'high',
          quantity_registered: +this.quantityHighSchool,
          quantity_granted: 0,
          quantity_exempt: 0,
          justification: '',
          biologia: false,
          bioquimica_e_quimica: false,
          ciencias: false,
          ciencias_da_computacao: false,
          ciencias_planetarias_e_terrestres: false,
          ciencias_sociais: false,
          engenharia: false,
          engenharia_e_materiais: false,
          gerenciamento_do_meio_ambiente: false,
          medicina_e_saude: false,
          other: ''
        })
      }
      return formated
    },
    getMostratecType() {
      if (this.activeHighSchool && (this.activeChild || this.activeElementary)) return 'ambas'
      if (this.activeHighSchool && !this.activeChild && !this.activeElementary) return 'mostratec'
      return 'junior'
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    max-width: 642px;
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 120%;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-width: 710px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  .form__title__complement {
    font-weight: 400;
    color: $primary;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}

.areas {
  margin-top: 30px;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $almostBlack;
    margin-bottom: 15px;
  }

  &__options {
    display: flex;
    flex-direction: column;

    label {
      align-items: center;
      display: flex;
      color: $almostBlack;
      font-size: 16px;
      margin-bottom: 5px;

      input {
        zoom: 1.5;
        margin-right: 5px;
      }
    }

    .otherInput {
      flex-direction: row;
      align-items: center;
      display: flex;
      color: $almostBlack;
      font-size: 16px !important;
      font-weight: normal;
      margin-bottom: 5px;

      ::v-deep input {
        width: 400px !important;
      }
    }
  }

  .warning {
    color: $attention !important;
  }
}
</style>
