<template>
  <div class="container-content">
    <div id="qrcodeContent">
      <div v-for="(info, index) in infoTag" :key="index" class="qrcodeContent">
        <div class="left-side-print">
          <p><strong>{{ info.fullName }}</strong></p>
          <p>{{ info.city }}/{{ info.state }}</p>
          <p>{{ info.country }}</p>
          <p>Estande: {{info.booth}}</p>
        </div>
        <div :class="[index === 0 ? 'right-side-print-first' : 'right-side-print']">
          <qrcode-vue
            v-if="info.studentId"
            :value="`${info.studentId}-s`"
            :size="100"
            level="H"
          />
          <qrcode-vue
            v-if="info.involvedId"
            :value="`${info.involvedId}-i`"
            :size="100"
            level="H"
          />
        </div>
      </div>
    </div>
    <div class="btn-container">
      <button-default class="btn-tag" :onClick="() => printer()">
        Imprimir
      </button-default>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import printJS from 'print-js'
import ButtonDefault from '../../atoms/Buttons/ButtonDefault'

export default {
  props: {
    params: Object,
    infoTag: Array
  },
  components: {
    QrcodeVue,
    ButtonDefault
  },
  methods: {
    printer() {
      printJS({
        printable: 'qrcodeContent',
        type: 'html',
        targetStyles: ['*']
      })
    }
  },
  computed: {
    qrValue() {
      let value
      if (this.params.id) {
        value = this.params.role === 'estudante'
          ? `${ this.params.id }-s`
          : `${ this.params.id }-i`
      }
      return value
    }
  }
}
</script>
<style scoped lang="scss">
.container-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 50px 0px;
}
@media print {
  #qrcodeContent {
    page-break-after: always;
  }
}

.qrcodeContent {
  display: flex;
  width: 300px;
  max-width: 100%;
  height: 120px;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 20px;
}

p {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 14px;
}

.left-side-print-first {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  max-width: 200px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.left-side-print {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  max-width: 200px;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
}

.right-side-print-first {
  display: flex !important;
  align-items: center;
}

.right-side-print {
  display: flex !important;
  margin-top: 15px;
}

.btn-container {
  margin: 30px 0;
  max-width: 120px;
  width: 100%;
}

.btn-tag {
  padding: 10px 15px !important;
}
</style>
