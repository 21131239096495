<template>
  <div class="info-wrapper">
    <heading-three :color="color">
      {{$t(title)}}
    </heading-three>
    <span v-if="!isBadge" :class="[{disabled: content === $t('noAwards')},{content: content !== $t('noAwards')}]"> {{ content }}</span>
    <BlueBadge
       v-if="isBadge"
      :isArea='isArea'
      :list='list'
    />
  </div>
</template>

<script>
import { HeadingThree } from '@/components/atoms'
import { BlueBadge } from '@/components/molecules'

export default {
  components: { HeadingThree, BlueBadge },
  name: 'Information',
  props: {
    title: {
      type: String,
      required: true
    },
    content: [String, Number],
    isBadge: Boolean,
    isArea: Boolean,
    list: {
      type: [Object, String],
      required: false
    },
    color: String
  }
}
</script>

<style lang="scss" scoped>
info-wrapper{
  display: flex;
  flex-direction: column;

  .content {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
  }
}
 .disabled {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #CDCBCB;
  }

</style>
