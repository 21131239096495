<template>
  <div class="user_options">
    <div @click="logout">{{$t('exit')}}</div>
  </div>
</template>
<script>
export default {
  name: 'options',
  data() {
    return {}
  },
  methods: {
    logout() {
      localStorage.clear()
      this.$store.commit('AFILLIATION_REQUEST', {})
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.user_options {
  position: absolute;
  cursor: pointer;
  width: 164px;
  padding: 15px 26px;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  top: 52px;
  left: 10px;
  color: $primary;
  font-weight: normal;
  z-index: 1;
  @media screen and(max-width: 503px) {
    width: 215px;
    left: 0px;
  }
  @media screen and(max-width: 281px) {
    width: 215px;
    left: -60px;
  }
  .active {
    font-weight: bold;
  }
  :hover {
    opacity: 0.7;
  }
}
</style>
