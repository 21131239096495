<template>
  <header :style="{width: headerTemplate === 'default' ? '100%' : '475px'}" class="header">
    <div class="header__title">
      <title-default class="header__text">{{$t(title)}}</title-default>
    </div>
    <button-close class="header__icon-close" :onClick="closeClicked"></button-close>
  </header>
</template>

<script>
import { ButtonClose, TitleDefault } from '@/components/atoms'

export default {
  name: 'HeaderSelecitionProcess',
  props: {
    title: {
      type: String,
      require: true
    },
    headerTemplate: {
      type: String,
      require: false,
      default: 'default'
    }
  },
  components: { ButtonClose, TitleDefault },
  data() {
    return {}
  },
  methods: {
    closeClicked() {
      this.$emit('closeClicked')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  @include d-flex-between;
  position: relative;

  &__title {
    @include d-flex-center;
  }

  &__text {
    @include H1;
    color: $primaryDark;
    line-height: 30px;
    letter-spacing: 1px;
    transform: translateY(10px);
    max-width: 100%;
    position: relative;
    bottom: 16px;
    right: 128px;
  }

  &__icon-close {
    position: absolute;
    right: 10px;
    top: 0px;
  }
}
</style>
