<template>
  <div class="home">
    <ResetPassword />
  </div>
</template>

<script>
import { ResetPassword } from '@/components/templates'

export default {
  name: 'ResetPasswordView',
  components: { ResetPassword }
}
</script>
