const projectSubAreas = {
  transportEngineer: 'Eng. dos transportes',
  aerospaceAeronauticalEngineeringAerodynamics: 'Eng. aeroespacial e aeronáutica, aerodinâmica',
  civilEngineeringConstruction: 'Eng. civil e construção',
  securityEngineering: 'Eng. de Segurança',
  productDevelopment: 'Desenvolvimento de produtos',
  materialsScience: 'Ciência dos materiais',
  others: 'Outras',
  generalEletrical: 'Elétrica Geral',
  generationTransmissionTransformationElectricity: 'Geração, transmissão e transformação de energia elétrica',
  eletricDrives: 'Acionamentos elétricos',
  renewableEnergi: 'Energias Renováveis',
  eletronicGeneral: 'Eletrônica Geral',
  eletronicSystemProcess: 'Sistemas e Processos Eletrônicos',
  communicationsSystem: 'Sistemas de Comunicação',
  industrialAutomation: 'Automação Industrial',
  robotics: 'Robótica',
  generalMechanics: 'Mecânica Geral',
  industrialEng: 'Eng. industrial',
  vehicleDevelopment: 'Desenvolvimento de veículos',
  processProductDevelopment: 'Desenvolvimento de produtos e processos',
  polutionAirQuality: 'Poluição e qualidade do ar',
  polutionWaterQuality: 'Poluição e qualidade da água',
  contaminationSoilQuality: 'Contaminação e qualidade do solo',
  environmentalToxicology: 'Toxicologia Ambiental',
  biologicalDevelopment: 'Desenvolvimento biológico (humano e animal)',
  ecology: 'Ecologia',
  animalHusbandry: 'Criação de animais',
  animalPhysiologyPathology: 'Fisiologia e patologia animal',
  agricultureAgronomy: 'Agricultura/agronomia',
  plantMorphologyDevelopment: 'Morfologia e desenvolvimento das plantas',
  Photosynthesis: 'Fotossíntese',
  plantPhysiology: 'Fisiologia das plantas',
  plantEvolutionTheory: 'Teoria de evolução das plantas',
  generalBiochemistry: 'Bioquímica geral',
  structuralBiochemistry: 'Bioquímica estrutural',
  metabolism: 'Metabolismo',
  foodChemistry: 'Química dos alimentos',
  organicChemistry: 'Química orgânica',
  inorganicChemistry: 'Química inorgânica',
  analyticalChemistry: 'Química analítica',
  generalChemistry: 'Química geral',
  chemicalEngineering: 'Engenharia química',
  physicochemical: 'Físico-química',
  molecularCellBiology: 'Biologia molecular e celular',
  genetics: 'Genética',
  immunology: 'Imunologia',
  antibiotics: 'Antibióticos',
  antimicrobials: 'Antimicrobianos',
  Bacteriology: 'Bacteriologia',
  virology: 'Virologia',
  mycology: 'Micologia',
  history: 'História',
  psychology: 'Psicologia',
  Sociology: 'Sociologia',
  anthropology: 'Antropologia',
  economy: 'Economia',
  politics: 'Política',
  management: 'Administração',
  climatologyClimate: 'Climatologia, clima',
  geochemistryGeophysics: 'Geoquímica, Geofísica',
  mineralogy: 'Mineralogia',
  paleontology: 'Paleontologia',
  planetarySciences: 'Ciências planetárias',
  geology: 'Geologia',
  sismology: 'Sismologia',
  oceanography: 'Oceanografia',
  algebra: 'Álgebra',
  analyticalMathematics: 'Matemática analítica',
  aplicatedMath: 'Matemática aplicada',
  geometry: 'Geometria',
  probabilityStatistics: 'Probabilidade e Estatística',
  atomsMoleculesSolids: 'Átomos, moléculas e sólidos',
  biologicalPhysics: 'Física Biológica',
  instrumentationElectronics: 'Instrumentação e eletrônica',
  magnetismElectromagnetism: 'Magnetismo e Eletromagnetismo',
  particleNuclearPhysics: 'Física de partículas e nuclear',
  opticsLaser: 'Óptica, Laser',
  acoustics: 'Acústica',
  theoreticalPhysicsAstronomy: 'Física teórica, astronomia',
  theoriesknowledgeMethodologyDidacticsPedagogy: 'Teorias do conhecimento, Metodologia, Didática, Pedagogia',
  Linguistics: 'Linguística',
  artisticExpression: 'Expressão Artística',
  philosophyTheology: 'Filosofia e Teologia',
  Bioremediation: 'Biorremediação',
  ecosystemManagement: 'Gerenciamento de ecossistemas',
  managementLandWaterForestResources: 'Gerenciamento de recursos terrestres, hídricos e florestais',
  wasteManagementRecycling: 'Gerenciamento de resíduos, reciclagem',
  environmentalPolicyEnvironmentalLegislation: 'Política ambiental, legislação ambiental',
  environmentalEducation: 'Educação ambiental',
  diagnosisTreatmentDiseases: 'Diagnóstico e tratamento de doenças',
  epidemiology: 'Epidemiologia',
  Genetics: 'Genética',
  physiologyPathophysiology: 'Fisiologia e Fisiopatologia',
  occupationalHealth: 'Saúde no Trabalho',
  preventionProphylaxis: 'Prevenção, profilaxia',
  nutrition: 'Nutrição',
  biomedicine: 'Biomedicina',
  nursing: 'Enfermagem',
  dentistry: 'Odontologia',
  physicalEducation: 'Educação Física',
  computerEngineering: 'Engenharia da Computação',
  algorithmsDatabase: 'Algoritmos, base de dados',
  artificialIntelligence: 'Inteligência Artificial',
  CommunicationNetworks: 'Comunicação e redes',
  computerScience: 'Ciência Computacional',
  graphics: 'Gráficos',
  softwareEngineering: 'Engenharia de Software',
  computerSystems: 'Sistemas de computadores',
  programmingLanguages: 'Linguagens de Programação'
}

export default projectSubAreas
