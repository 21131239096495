import { render, staticRenderFns } from "./ModalFilterSidebarProjects.vue?vue&type=template&id=7f66ffda&scoped=true&"
import script from "./ModalFilterSidebarProjects.vue?vue&type=script&lang=js&"
export * from "./ModalFilterSidebarProjects.vue?vue&type=script&lang=js&"
import style0 from "./ModalFilterSidebarProjects.vue?vue&type=style&index=0&id=7f66ffda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f66ffda",
  null
  
)

export default component.exports