<template>
  <div class="collum-card" @click="cardSelected">
    <card-default
      :newStyle="{ marginBottom: '26px' }"
    >
      <year-status-pair
        :content ="String(dataEdition)"
        :statusText="statusText"
        :active="active"
      />
      <heading-three class="heading">
        {{ projectTitle || $t('draft') }}
      </heading-three>
      <template v-if="statusText !=='rascunho' && active">
        <solicitation-dates
          :init="$t('requestDate')"
          :initDate ="formatDate(initDate)"
          :last ="$t('lastUpdate')"
          :lastDate ="formatDate(lastDate)"
        />
      </template>
    </card-default>
  </div>
</template>

<script>

import CardDefault from '@/components/organisms/Cards/CardDefault.vue'
import SolicitationDates from '@/components/organisms/Dates/SolicitationDates.vue'
import YearStatusPair from '@/components/molecules/Pairs/YearStatusPair.vue'
import HeadingThree from '@/components/atoms/Texts/HeadingThree.vue'

export default {
  name: 'GenericCard',
  props: {
    dataEdition: {
      type: [String, Number],
      required: true
    },
    projectTitle: {
      type: String,
      required: true
    },
    initDate: {
      type: String,
      required: true
    },
    lastDate: {
      type: String,
      required: true
    },
    statusText: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    index: {
      type: Number,
      required: false
    }
  },
  components: {
    SolicitationDates,
    YearStatusPair,
    CardDefault,
    HeadingThree
  },
  methods: {
    formatDate(value) {
      return value ? value.slice(0, 10).split('-').reverse().join('/') : ''
    },
    cardSelected() {
      this.$emit('cardSelected', this.index)
    }
  }
}
</script>
<style lang="scss" scoped>
.collum-card {
  margin-right: 26px;
}
.box{
  width: 35% !important;
  max-width: 308px;
  padding: 14px 10px;
  flex-direction: column;
  align-items: center;
}
.heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  margin: 7px 0px;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
  max-width: 277px;
  // width: fit-content;
}
</style>
