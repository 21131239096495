<template>
  <form @submit.prevent class="form">
    <v-row no-gutters class="flex-column align-start mb-5 form__info">
      <text-info-title text="Informações" />
      <span class="text-left">Defina o número de vagas que serão disponibilizadas para cada modalidade de ensino.</span>
    </v-row>

    <div class="mt-2">
      <h3 class="school-type mb-5">Pública/Privada</h3>
      <input-default
        :class="[{warning: checkErrorIfContains('publica-infantil')}, 'mb-8']"
        label="ED Infantil"
        id="ed-infantil"
        placeholder="Insira o número de vagas"
        type="number"
        v-model="publica.infantil"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('publica-fundamental')}, 'mb-8']"
        label="Ensino Fundamental"
        id="ed-fundamental"
        placeholder="Insira o número de vagas"
        type="number"
        v-model="publica.fundamental"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('publica-medio')}, 'mb-8']"
        label="Ensino Médio/Técnico"
        id="ed-medio"
        placeholder="Insira o número de vagas"
        type="number"
        v-model="publica.medio"
        @input="resetErrors"
      />
    </div>
    <button-default :onClick="checkVacancies" class="button mt-8">{{$t('save')}}</button-default>
    <feedback-warning v-if="errors.length" :text="textWarning" :onClick="resetErrors" color="#ff9800" />
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  ButtonDefault, TextInfoTitle, FeedbackWarning, InputDefault
} from '@/components/atoms'
// import api from '@/services/api';

export default {
  name: 'Vacancies',
  components: {
    ButtonDefault, TextInfoTitle, FeedbackWarning, InputDefault
  },
  data() {
    return {
      publica: {
        infantil: null,
        fundamental: null,
        medio: null
      },
      privada: {
        infantil: null,
        fundamental: null,
        medio: null
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    ...mapGetters({
      editionId: 'editionId'
    })
  },
  methods: {
    ...mapActions({
      changeEdition: 'changeEdition',
      editionVacanciesSet: 'editionVacanciesSet'
    }),
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) this.errors = []
    },
    checkVacancies() {
      this.errors = []
      for (const key in this.publica) {
        const value = this.publica[key]
        if (!value) this.errors.push(`publica-${ key }`)
      }
      if (!this.errors.length) {
        this.$emit('openLoading')
        this.saveForm()
      }
    },
    async saveForm() {
      const edition = this.$store.getters.editionId
      const editionData = await this.$http.get(`/mostratec/${ edition }`)
      const updatedData = {
        ...editionData.data,
        vacancies_public_grade: this.publica.infantil,
        vacancies_public_middle: this.publica.fundamental,
        vacancies_public_high: this.publica.medio,
        vacancies_private_grade: 0,
        vacancies_private_middle: 0,
        vacancies_private_high: 0
      }
      this.$http.put(`/mostratec/${ edition }`, updatedData)
      this.$store.commit('EDITION_VACANCIES_SET')
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  &__info {
    span {
      line-height: 22px;
      color: $primary;
    }
  }

  .button {
    align-self: flex-end;
  }
}

.school-type {
  color: $primary;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}
</style>
