import { render, staticRenderFns } from "./ButtonIconFileLink.vue?vue&type=template&id=315c921b&scoped=true&"
import script from "./ButtonIconFileLink.vue?vue&type=script&lang=js&"
export * from "./ButtonIconFileLink.vue?vue&type=script&lang=js&"
import style0 from "./ButtonIconFileLink.vue?vue&type=style&index=0&id=315c921b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "315c921b",
  null
  
)

export default component.exports