<template>
  <div class="container-app">
    <header-default />
    <ListUsersTemplate />
    <router-view></router-view>
  </div>
</template>

<script>

import { ListUsersTemplate } from '@/components/templates'
import { HeaderDefault } from '@/components/organisms'

export default {
  name: 'ListUsers',
  components: {
    HeaderDefault,
    ListUsersTemplate
  }
  // computed: {
  //   route() {
  //     return this.$route.name
  //   }
  // }
}
</script>

<style lang="scss">
.container-app {
  background-color: #F1F5F6;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px 0px;
}
</style>
