<template>
  <div class="body">
    <ContainerComponents
      :components="components"
      :index="index"
      :newStyle="{ paddingBottom: '20px!important' }"
      :data="data"
    >
      <Menu
        :menus="menus"
        :active="index"
        :newStyle="{ borderRadius: '20px 20px 0 0' }"
        @selectedMenu="index=$event"
      />
    <input-select
      :label="'Edição'"
      :value="mostratecSelected"
      :seleted="mostratecSelected"
      :items="mostratecs"
      @input="mostratecSelected=$event"
      :newStyle="{ margin: '30px 0 0 41px' }"
    />
    </ContainerComponents>
  </div>
</template>

<script>
import {
  ContainerComponents, Menu, ButtonDefault, InputSelect
} from '@/components/atoms'
import { GeneralVision, FinalistsIndicators } from '@/components/templates'

export default {
  name: 'IndicatorsTemplate',
  data() {
    return {
      mostratecs: [],
      mostratecSelected: '',
      index: 0,
      data: {},
      menus: [
        'mostratecOverview',
        'highFinalists',
        'middleFinalists',
        'childishFinalists'
      ],
      components: [
        GeneralVision,
        FinalistsIndicators,
        FinalistsIndicators,
        FinalistsIndicators
      ],
      dashboardType: {
        0: 'general',
        1: 'high',
        2: 'middle',
        3: 'grade'
      }
    }
  },
  components: {
    ContainerComponents,
    Menu,
    ButtonDefault,
    GeneralVision,
    FinalistsIndicators,
    InputSelect
  },
  async mounted() {
    await this.getMostratec()
    await this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const mostratec_id = this.mostratecSelected
        const { data: { data } } = await this.$http.get(`/project/dashboard/general?dashboardType=${ this.dashboardType[this.index] }&mostratecId=${ mostratec_id }`)
        if (data) {
          this.data = data
        } else {
          this.data = []
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getMostratec() {
      try {
        const { data } = await this.$http.get('/mostratec')
        if (data.length) {
          this.mostratecs = data.map((item) => ({
            text: item.name_edition,
            value: item.id,
            active: item.active
          }))

          this.mostratecSelected = this.mostratecs.find((item) => item.active).value
        }
      } catch (error) {
        console.error(error)
      }
    }
  },

  watch: {
    index() {
      this.getInfo()
    },
    mostratecSelected() {
      this.getInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  margin-top: 30px;
}
.box-indicators {
  display: flex;
  padding: 40px;
}

// .clear {
//   display: flex;
//   align-items: flex-end;
//   padding-bottom: 7px;
//   margin-left: 10px;
//   width: 100px;
// }
</style>
