const buildStatusObject = (theme, name, image = null) => ({
  theme,
  name,
  image
})

const statusDictionary = {
  rascunho: buildStatusObject(
    'grey_neutral',
    'sketch',
    'enrollmentInProgress.png'
  ),
  'aguardando-revisao': buildStatusObject(
    'warn',
    'pendingRevision',
    'pendingAnalysis.png'
  ),
  'em-revisao': buildStatusObject(
    'blue_analisis',
    'underReview',
    'inAnalysis.png'
  ),
  'aguardando-pagamento': buildStatusObject(
    'purple_waiting',
    'waitingPayment',
    'waitingPayment.png'
  ),
  'revisao-aceita': buildStatusObject(
    'green_accept',
    'reviewAccepted',
    'card.png'
  ),
  'revisao-negada': buildStatusObject(
    'red_reject',
    'reviewDenied',
    'notAccepted.png'
  ),
  reprovado: buildStatusObject(
    'red_reject',
    'notAccept',
    'notAccepted.png'
  ),
  'aguardando-aceite-feira': buildStatusObject(
    'warn',
    'pendingAcceptStatus',
    'time.png'
  ),
  'inscricao-efetivada': buildStatusObject(
    'green_accept',
    'registrationComplete',
    'done.png'
  ),
  'inscricao-cancelada': buildStatusObject(
    'red_reject',
    'registrationCanceled',
    'subscriptionCanceled.png'
  )
}

export default statusDictionary
