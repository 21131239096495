<template>
  <aside class="inner__wrapper">
    <div class="header">
      <h3>{{$t('institutionTitle')}}</h3>
    </div>

    <main class="form__main mt-4 main_align">
      <div class="infos">
        <div class="generalData">
          <div class="title">
            <span>{{$t('dataGeneral')}}</span>
          </div>
          <div class="data">
            <div class="data__left">
              <div class="data__row">
                <h4>{{$t('institutionName')}}</h4>
                <span>{{form.school_name}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('institutionResponsibleName')}}</h4>
                <span>{{form.responsible_name}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('cell')}}</h4>
                <span>{{form.cellphone}}</span>
              </div>
            </div>
            <div class="data__right">
              <div class="data__row">
                <h4>{{$t('educationalInstitutionCategory')}}</h4>
                <span>{{form.school_type == 'public' ? 'Pública' : 'Privada'}}</span>
              </div>
              <div
                v-if="form.landline"
                class="data__row"
              >
                <h4>{{$t('landline')}}</h4>
                <span>{{form.landline}}</span>
              </div>
              <div
                v-else
                class="data__row"
              >
                <h4>{{$t('landline')}}</h4>
                <span>Não informado</span>
              </div>
              <div class="data__row">
                <h4>{{$t('schoolEmail')}}</h4>
                <span>{{form.email}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="address">
          <div class="title">
            <span>{{$t('address')}}</span>
          </div>
          <div class="data">
            <div class="data__left">
              <div class="data__row">
                <h4>{{$t('zipCode')}}</h4>
                <span>{{form.cep}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('city')}}</h4>
                <span>{{form.city}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('number')}}</h4>
                <span>{{form.locate_number}}</span>
              </div>
            </div>
            <div class="data__right">
              <div class="data__row">
                <h4>{{$t('uf')}}</h4>
                <span>{{form.uf}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('address')}}</h4>
                <span>{{form.address}}</span>
              </div>
              <div class="data__row">
                <h4>{{$t('complement')}}</h4>
                <span>{{form.complement}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="contacts">
          <div class="title">
            <span>{{$t('contacts')}}</span>
          </div>
          <div
            v-for="(contact, index) in form.school_contacts"
            :key="index"
          >
            <div class="data__header">
              <h4>{{$t('contact')}} {{index + 1}}</h4>
              <div class="separator"></div>
            </div>
            <div class="data">
              <div class="data__left">
                <div class="data__row">
                  <h4>{{$t('name')}}</h4>
                  <span>{{contact.name}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('contactPhone')}}</h4>
                  <span>{{contact.phone}}</span>
                </div>
              </div>
              <div class="data__right">
                <div class="data__row">
                  <h4>{{$t('email')}}</h4>
                  <span>{{contact.email}}</span>
                </div>
                <div class="data__row">
                  <h4>{{$t('office')}}</h4>
                  <span>{{contact.office}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  </aside>
</template>

<script>

export default {
  name: 'ProjectsKindergartenInstitution',
  data: () => ({
    form: {
      school_name: '',
      responsible_name: '',
      cellphone: '',
      school_type: '',
      landline: '',
      email: '',
      cep: '',
      uf: '',
      city: '',
      address: '',
      locate_number: '',
      complement: '',
      school_contacts: [
        {
          name: '',
          phone: '',
          email: '',
          office: ''
        }
      ]
    }
  }),
  mounted() {
    this.getInfos()
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    async getInfos() {
      const { data: schoolData } = await this.$http.get(`/school/${ this.projectInfo.school_id }`)

      this.form.school_name = schoolData.school_name
      this.form.responsible_name = schoolData.responsible_name
      this.form.cellphone = schoolData.cellphone
      this.form.school_type = schoolData.school_type
      this.form.email = schoolData.email
      this.form.cep = schoolData.cep
      this.form.uf = schoolData.uf
      this.form.city = schoolData.city
      this.form.address = schoolData.address
      this.form.locate_number = schoolData.locate_number
      this.form.complement = schoolData.complement
      this.form.school_contacts = schoolData.school_contacts
    }
  }
}

</script>

<style lang="scss" scoped>
.inner__wrapper {
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #fdfdfd;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;
    margin-bottom: 25px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }
  .infos {
    padding: 0px 22px 25px;

    .address,
    .contacts,
    .generalData {
      .title {
        border-bottom: 0.5px solid $primary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 5px;

        span {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .data {
        margin-left: 10%;
        display: flex;
        font-size: 16px;
        color: #686868;

        &__row {
          width: 320px;
          margin-bottom: 35px;
          h4 {
            color: $almostBlack;
            margin-bottom: 10px;
          }
        }
        &__left {
          float: left;
        }
        &__right {
          float: right;
          margin-left: 18%;
        }
        &__header {
          font-size: 17px;
          line-height: 20px;
          font-weight: 700;
          color: $primary;
          margin-left: 10%;
        }
      }
    }
  }
}
.warning {
  color: $attention;
  border-bottom-color: $attention;
}
.separator {
  background-color: $primary;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 30px;
  width: 81.17%;
}

</style>
