<template>
<div>
  <transition appear mode="out-in">
    <keep-alive>
      <component
        v-if="['general', 'vacancies', 'allEditions'].includes(fields)"
        @openLoading="openLoading"
        @openModal="openModal"
        :is="fields"
      />
    </keep-alive>
  </transition>

  <modal-default title="notifications" @closeModal="toggleModal" :modal="modal">
    <div class="modal__body">
      <div class="icon">
        <img src="@/assets/green-attention.png" alt="">
      </div>
      <div class="text">
        <p slot="text">{{$t('fairStatusChangeWarning')}}</p>
      </div>
    </div>
  </modal-default>
  <modal-loading :modal="loading" />
</div>
</template>

<script>
import { ModalDefault, ModalLoading } from '@/components/organisms/Modals'
import { General, Vacancies, AllEditions } from './FormEditionTabs'

export default {
  name: 'FormEdition',
  components: {
    General, Vacancies, AllEditions, ModalDefault, ModalLoading
  },
  props: { fields: String },
  data() {
    return {
      modal: false,
      loading: false
    }
  },
  methods: {
    menuSelected(e) {
      this.fields = e
    },
    openModal() {
      this.toggleModal()
    },
    openLoading() {
      this.toggleLoading()
      setTimeout(() => {
        this.toggleLoading()
      }, 1000)
    },
    toggleModal() {
      this.modal = !this.modal
    },
    toggleLoading() {
      this.loading = !this.loading
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__body {
  @include d-flex-center;
  margin-top: 25px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $almostBlack;
    font-size: 16px;
    line-height: 20px;
    margin-left: 7%;
    max-width: 326px;
    text-align: left;

    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
}
</style>
