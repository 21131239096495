<template>
  <div class="container-app">
    <div class="wrapper">
      <form-edition :fields="fields" />
    </div>
  </div>
</template>

<script>
import { FormEdition } from '@/components/organisms'

export default {
  name: 'EditionTemplate',
  components: { FormEdition },
  props: ['fields']
}
</script>

<style lang="scss" scoped>
.container-app {
  background-color: #F1F5F6;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px 50px;

  .wrapper {
    background: #FDFDFD;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
    width: 100%;
  }
}
</style>
