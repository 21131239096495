<template>
  <div @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <header-modal @closeClicked="$emit('closeModal')" :title="title" />
      <div class="filter">
        <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
        <span>Filtrar</span>
      </div>
      <div class="modal__body">
        <div class="search__inputs">
          <div class="search__item mt-6">
            <input-select
              id="nameSelect"
              :items="nameOptions"
              v-model="fairName"
              label=name
              placeholder="Selecione o Nome"
              @input="() => addtoFilters('filterName', 'fairName')"
            />
            <div class="badges" v-if="filtersName">
              <span
                class="badgeFilter"
                v-for="(item, index) in filtersName"
                :key="`${item}-${index}`"
              >
                {{item}}
                <button @click="removeFilter('filtersName', index)">
                  <v-icon medium color="white">mdi-close-circle</v-icon>
                </button>
              </span>
            </div>
          </div>

          <div class="search__item mt-6">
            <input-select
              id="formatoSelect"
              :items="formatOptions"
              v-model="fairFormat"
              label="Formato"
              placeholder="Selecione o Formato"
              @input="() => addtoFilters('filterFormat', 'fairFormat')"
            />
            <div class="badges" v-if="filterFormat">
              <span
                class="badgeFilter"
                v-for="(item, index) in filterFormat"
                :key="`${item}-${index}`"
              >
                {{item}}
                <button @click="removeFilter('filterFormat', index)">
                  <v-icon medium color="white">mdi-close-circle</v-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="search__item mt-6">
            <h5>Status</h5>
            <input-radio-group id="status" v-model="fairStatus" :options="status_options" />
          </div>
        </div>
        <div class="button mt-10 text-center">
          <button-default :onClick="checkForm" class="button">Filtrar</button-default>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault,
  ButtonDefault,
  InputRadioGroup,
  InputSelect
} from '@/components/atoms'
import { HeaderModal } from '@/components/organisms'

export default {
  name: 'ModalFilterSidebar',
  props: ['modal', 'title'],
  components: {
    HeaderModal,
    InputDefault,
    ButtonDefault,
    InputRadioGroup,
    InputSelect
  },
  data: () => ({
    fairName: '',
    filterName: [],
    nameOptions: [],
    fairFormat: '',
    filterFormat: [],
    formatOptions: [
      { text: 'Presencial', value: 'presencial' },
      { text: 'Virtual', value: 'virtual' },
      { text: 'Híbrida', value: 'hibrida' }
    ],
    fairStatus: null,
    status_options: [
      {
        value: true,
        text: 'Ativo'
      },
      {
        value: false,
        text: 'Inativo'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      editionRequestFilter: 'editionRequestFilter'
    })
  },
  mounted() {
    this.getEditionNames()

    this.filtros = this.$store.getters.editionRequestFilter
    this.filterName = this.filtros.name_edition
      ? this.filtros.name_edition
      : []
    this.filterFormat = this.filtros.format_edition
      ? this.filtros.format_edition
      : []
    this.filterStatus = this.filtros.status ? this.filtros.status : null
  },
  methods: {
    ...mapActions({
      // editionRequestFilter: 'editionRequestFilter',
    }),
    addtoFilters(array, value) {
      if (!this[array].includes(this[value])) {
        this[array].push(this[value])
        this[value] = ''
      }
    },
    removeFilter(array, index) {
      this[array].splice(index, 1)
    },
    checkForm() {
      const filters = {
        ...this.filtros,
        name_edition: this.filterName,
        format_edition: this.filterFormat,
        active: [this.fairStatus]
      }

      if (!filters.name_edition.length) delete filters.name_edition
      if (!filters.format_edition.length) delete filters.format_edition
      if (filters.active[0] === null) delete filters.active
      this.$store.commit('EDITION_LIST_FILTER', filters)

      this.$emit('closeModal')
    },
    async getEditionNames() {
      const { data } = await this.$http.get('/mostratec')
      const formatedNames = data.map((i) => ({
        text: i.name_edition,
        value: i.name_edition
      }))
      this.nameOptions = formatedNames
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  left: 0px;
  position: fixed;
  height: 100%;
  top: 0px;
  width: 100%;
  z-index: 5;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding: 25px;
    flex: 1;
    width: 649px;

    .modal__body {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      width: 100%;
      max-height: 100%;

      .search__inputs {
        margin: 5px 0px 15px 0px;

        .search__item {
          .badges {
            margin-top: 15px;

            span {
              align-items: center;
              display: inline-flex;
              justify-content: center;
              background: $primary;
              border-radius: 49px;
              opacity: 0.8;
              color: $white;
              font-weight: bold;
              font-size: 14px;
              min-width: 79px;
              height: 28px;
              padding: 0px 8px;

              &:not(:last-of-type) {
                margin-right: 10px;
              }

              button {
                margin-left: 5px;
              }
            }
          }

          h5 {
            color: $almostBlack;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.filter {
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: $primary;
  border-bottom: 1px solid $primary;
  padding-bottom: 12px;
}
</style>
