<template>
  <aside class="inner__wrapper">
    <div class="header">
      <h3>{{ $t("projectData") }}</h3>
      <div v-if="onlyAdmin === 'admin'">
        <button class="button_edit" v-if="!open" @click="open=!open">EDITAR</button>
      </div>
    </div>

    <main class="form__main mt-4 main_align">
      <div class="infos">
        <div class="generalData">
          <ProjectData
            :projectInfo="projectInfo"
            :open="open"
          />
          <ProjectSummaryData
            :projectInfo="projectInfo"
            :open=open
          />
        </div>
      </div>
    </main>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { InputDefault, InputCheckboxAccordion, ButtonDefault } from '@/components/atoms'
import ProjectData from '@/components/templates/ProjectData'
import ProjectSummaryData from '@/components/templates/ProjectSummaryData'
import { areaOptions, areas } from '@/utils/areas'

export default {
  name: 'Projects',
  components: {
    InputDefault, InputCheckboxAccordion, ProjectData, ProjectSummaryData
  },
  data() {
    return {
      onlyAdmin: '',
      open: false,
      school_name: '',
      responsible_name: '',
      cellphone: '',
      school_type: '',
      landline: '',
      email: '',
      cep: '',
      uf: '',
      city: '',
      address: '',
      locate_number: '',
      complement: '',
      school_contacts: [
        {
          name: '',
          phone: '',
          email: '',
          office: ''
        }
      ],
      area: '',
      sub_areas: [],
      virology: '',
      project_title: '',
      quantityStudents: 1,
      livePresentation: true,
      human_beings: '',
      continuous_project: '',
      human_or_animals_tissues: '',
      biological_substances: '',
      danger_biological_substances: '',
      controlled_substances: '',
      equipments_danger: '',
      research_type: '',
      active: false,
      summary: '',
      results: '',
      research_plan: {},
      infos: [],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.getInfos()
    this.onlyAdmin = localStorage.getItem('role')
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    findRightLabel(value) {
      if (value === 0) {
        return 'Instituição de pesquisa ou indústria regulamentada'
      }
      if (value === 1 || value === 2) {
        return `Novo estudante ${ value }`
      }
    },
    findRightName(value) {
      if (value === 1 || value === 2) {
        return this.form.infos[value].fullname
      }
    },
    async getInfos() {
      const selectedArea = areaOptions.find(
        (item) => item.value === this.projectInfo.area
      )
      this.form.area = selectedArea.text || 'Não informado'
      this.form.sub_area = this.projectInfo.sub_areas
        ? this.projectInfo.sub_areas.sub_areas
        : 'Não informado'
      const subAreas = this.projectInfo.sub_areas
        ? this.projectInfo.sub_areas.sub_areas
        : 'Não informado'
      if (subAreas !== 'Não informado') {
        areas[selectedArea.value].forEach((item) => {
          if (subAreas.includes(item.value) && item.value !== 'others') {
            this.form.sub_areas.push(item)
          }
        })
        if (subAreas.includes('others')) {
          const otherIndex = subAreas.indexOf('others') + 1
          const otherSubArea = subAreas[otherIndex]
          this.form.sub_areas.push({ text: otherSubArea, value: otherSubArea })
        }
      }
      this.form.quantityStudents = this.projectInfo.students.length
      this.form.project_title = this.projectInfo.project_title
      this.form.human_beings = this.projectInfo.human_beings ? 'Sim' : 'Não'
      this.form.continuous_project = this.projectInfo.continuous_project
        ? 'Sim'
        : 'Não'
      this.form.human_or_animals_tissues = this.projectInfo
        .human_or_animals_tissues
        ? 'Sim'
        : 'Não'
      this.form.biological_substances = this.projectInfo.biological_substances
        ? 'Sim'
        : 'Não'
      this.form.danger_biological_substances = this.projectInfo
        .danger_biological_substances
        ? 'Sim'
        : 'Não'
      this.form.controlled_substances = this.projectInfo.controlled_substances
        ? 'Sim'
        : 'Não'
      this.form.equipments_danger = this.projectInfo.equipments_danger
        ? 'Sim'
        : 'Não'
      this.form.vertebrate_animals = this.projectInfo.vertebrate_animals
        ? 'Sim'
        : 'Não'
      this.form.research_type = this.projectInfo.research_type || 'Não informado'
      this.form.summary = this.projectInfo.summary
      this.form.results = this.projectInfo.results
      this.form.research_plan = this.projectInfo.research_plan[0]
      this.projectInfo.students.forEach((student) => {
        this.form.infos.push(student)
      })
    },
    openModal(test) {
      this.open = test
    },
    checkForm() {
      this.resetErrors()

      if (!this.errors.length) {
        this.resetErrors()
        this.saveForm()
      }
    },
    saveForm() {
      try {
        const formData = this.$store.getters.fairAffiliation
        const newData = {
          ...formData,
          studentsInfo: this.form,
          fair_name: this.form.fairName,
          website: this.form.site,
          fairs_coordinator_name: this.form.coordinatorName,
          fair_type: this.form.fairType
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem(
          'projectSelectionData',
          JSON.stringify(newData)
        )
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      return this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data__right {
  margin-left: 50px;
}
.area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 17px;
  position: static;
  width: fit-content;
  height: 28px;
  left: 0px;
  top: 0px;
  background: #1b778b;
  opacity: 0.8;
  border-radius: 49px;
  flex: none;
  order: 0;
  flex-grow: 0;
  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.thingsNeeded {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 19px;
  position: static;
  width: fit-content;
  height: 28px;
  left: 0px;
  top: 0px;
  background: #1b778b;
  opacity: 0.8;
  border-radius: 49px;
  flex: none;
  order: 0;
  flex-grow: 0;
  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.subarea {
  display: flex;
  flex-direction: row;
  padding: 5px 19px;
  width: fit-content;
  height: 28px;
  background: #319cb4;
  border-radius: 49px;
  opacity: 0.8;
  margin-top: 10px;

  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.right_side {
  right: 117px;
  position: absolute;
  &__middle {
    right: 242px;
    position: absolute;
  }
  &__init {
    right: 36px;
    position: absolute;
  }
}
.separator {
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  width: 300px-55px;
  font-size: 24px;
  position: absolute;
  top: -55px;
  line-height: 33px;
  color: #1b778b;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
  &__align {
    height: 40px;
    position: absolute;
  }
}

.align_table {
  display: flex;
}

.form_info {
  font-family: Mulish;
  font-style: normal;
  margin-bottom: 9px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  margin-left: 1.5px;
  margin-right: 60px;
  &__subtitle {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 18px;
    margin-left: 2px;
    color: #686868;
  }
}

.content {
  border-top: 1px solid #cdcbcb;
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: block;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.middle_content {
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
}
.end_content {
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
}

.accordions {
  margin-left: 62px;
}
.accordion_size {
  width: 785px;
  padding-left: 24px;
}

.grey_line {
  width: 714px;
  margin-left: 44px;
  margin-bottom: 35px;
  border-bottom: 0.5px solid #cdcdcd;
}

.inner__wrapper {
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #fdfdfd;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;
    margin-bottom: 25px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }
  .infos {
    padding: 0px 22px 25px;

    .address,
    .contacts,
    .generalData {
      .title {
        border-bottom: 0.5px solid $primary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 5px;

        span {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .data {
        margin-left: 10%;
        margin-top: 35px;
        display: flex;
        font-size: 16px;
        color: #686868;

        &__row {
          width: 320px;
          margin-bottom: 35px;
          h4 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__rowLarge {
          width: 520px;
          margin-bottom: 35px;
          margin-left: 6px;
          h4 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__align {
          width: 520px;
          margin-bottom: 35px;
          margin-left: 46px;
        }
        .warning {
          color: $attention;
          border-bottom-color: $attention;
        }
        .separator {
          background-color: $primary;
          height: 1px;
          margin-top: 5px;
          margin-bottom: 30px;
          width: 81.17%;
        }
      }
    }
  }
}

.button_edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  width: 100px;
  height: 36px;
  left: 1230px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-weight: 600;
  font-size: 15px;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
