<template>
  <div>
    <HeaderSub @newIndex="index=$event" :index="index" />
    <transition>
      <keep-alive appear>
        <component
          :is="components[index]"
          :index="index"
          @newIndex="index=$event"
        />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { HeaderSub } from '@/components/organisms/Headers'
import { TableListUsers, TableRolePermissions } from '@/components/organisms/Tables'
import { FormAddUser } from '@/components/organisms/Forms'

export default {
  name: 'ListUsersTemplate',
  components: {
    HeaderSub,
    TableListUsers,
    FormAddUser,
    TableRolePermissions
  },
  data() {
    return {
      index: 0,
      components: ['TableListUsers', 'FormAddUser', 'TableRolePermissions']
    }
  },
  watch: {
    index() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
