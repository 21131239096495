<template>
  <div class="info-wrapper">
    <heading-three class="info-title">
      {{ $t(title) }}
    </heading-three>
    <default-text class="content">
      {{ $t(content) }}
    </default-text>
  </div>
</template>

<script>
import { HeadingThree, DefaultText } from '@/components/atoms/Texts'

export default {
  components: { HeadingThree, DefaultText },
  name: 'Information',
  props: {
    title: {
      type: String,
      Required: true
    },
    content: {
      type: String,
      Required: false,
      default: 'notInformed'
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  display: flex;
  flex-direction: column;

  & > .info-title {
    margin-bottom: 5px;
  }

  & > .content {
    color: #686868;
  }
}

</style>
