<template>
  <a @click="onClick" class="button__close">
  </a>
</template>

<script>
export default {
  props: ['onClick'],
  name: 'ButtonClose'
}
</script>

<style lang="scss" scoped>

.button__close {
  width: 23px;
  height: 23px;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 0.4;
  }

  &::before, &::after {
    content: '';
    position: absolute;
    left: 15px;
    height: 28px;
    width: 2px;
    background-color: $primary;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

</style>
