<template>
  <label class="label" :for="id">
    {{ label }}
    <input class="input"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      v-on:input="$emit('input', $event.target.value)"
      :maxlength="max || false"
    />
  </label>
</template>

<script>

export default {
  name: 'InputDefault',
  props: {
    label: String,
    type: String,
    id: String,
    placeholder: String,
    value: String,
    max: Number | String
  }
}
</script>

<style lang="scss" scoped>
.label {
  letter-spacing: 0.05em;
  color: #303030;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  text-align: left;
}

.warning {
  color: $warningRed;

  .input {
    border-color: $warningRed;
  }
}

.input {
  background: $white;
  border: 1px solid #AFAEAE;
  border-radius: 100px;
  color: $almostBlack;
  font-weight: 500;
  height: 50px;
  margin-top: 5px;
  width: 345px;
  padding: 8px 15px 8px 24px;
  outline: 0;

  @media screen and (max-width: 512px) {
    width: 100%;
  }

  &::placeholder {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #AFAEAE;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}
</style>
