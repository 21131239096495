<template>
  <div @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <div class="modal__core">
        <div class="modal__body">
          <header-modal
            :style="{width: '600px'}"
            @closeClicked="$emit('closeModal')"
            :title="title"
          />
          <div class="info_box">
            <div class="icon">
              <img src="@/assets/red-attencion.png" alt="E-mail enviado a MostraTec" />
            </div>
            <div class="text">
              <slot>
                Você tem certeza que deseja <strong>reprovar</strong> o projeto <strong>{{project_title}}</strong> Essa ação não poderá ser desfeita e
                o(a) estudante responsável será notificado via email sobre sua decisão.
              </slot>
            </div>
          </div>
        </div>
      </div>
      <div class="modal__buttons__container">
        <button-default
          :onClick="() => {$emit('closeModal')}"
          class="button__cancel"
        >{{$t('noCancel')}}</button-default>
        <button-default
          :onClick="onClick"
          class="button">
        {{$t('yesContinue')}}</button-default>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderModal from '@/components/organisms/Headers/HeaderModal'
import ButtonDefault from '@/components/atoms/Buttons/ButtonDefault'

export default {
  name: 'ModalSendForm',
  props: {
    onClick: {
      type: Function,
      required: false
    },
    modal: {
      type: Boolean,
      required: true
    },
    project_title: {
      type: String,
      required: true

    }
  },
  components: { HeaderModal, ButtonDefault },
  data: () => ({
    title: 'Confirmação de projeto'
  })
}
</script>

<style lang="scss" scoped>
.modal__outer {
  @include d-flex-center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  top: 0px;
  padding: 0px 10px;
  position: fixed;
  width: 100%;
  z-index: 1;

  .modal__inner {
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-height: 221px;
    padding: 25px;
    width: 649px;
    height: 337px;
  }

  .modal__core {
    display: flex;
    flex-direction: row;
    margin-bottom: 70px;

    .info_box{
      display: flex;
      margin-top: 10px;

      .icon{
        img{
          width: 121.69px;
        }
      }
      .text{
        font-family: Mulish, sans-serif;
        font-size: 16px;
        line-height: 21.68px;
        color: #18516F;
        margin-top: 20px;

        width: 441px;
      }
    }

    .modal__body {
      flex-direction: column;
    }
  }

  .modal__buttons__container {
    display: flex;
    justify-content: space-between;
  }

  .button {
    line-height: 20, 08px;
    &__cancel {
      color: $primary;
      background-color: white;
    }
  }
}
</style>
