<template>
  <aside class="inner__wrapper">
    <div class="header">
      <h3>{{ $t("projectData") }}</h3>
      <div v-if="onlyAdmin === 'admin'">
        <button class="button_edit" v-if="!open" @click="open=!open">EDITAR</button>
      </div>
    </div>

    <main class="form__main mt-4 main_align">
      <div class="infos">
        <div class="generalData">
          <ProjectData
            :projectInfo="projectInfo"
            :open="open"
          />
        </div>
        <ProjectSummaryData
          :projectInfo="projectInfo"
          :open=open
        />
      </div>
    </main>
  </aside>
</template>

<script>
import { InputDefault, InputCheckboxAccordion } from '@/components/atoms'
import { FormEditProjectElementary } from '@/components/organisms/Forms'
import ProjectData from '@/components/templates/ProjectData'
import ProjectSummaryData from '@/components/templates/ProjectSummaryData'

export default {
  name: 'Projects',
  components: {
    InputDefault,
    InputCheckboxAccordion,
    FormEditProjectElementary,
    ProjectData,
    ProjectSummaryData
  },
  data() {
    return {
      onlyAdmin: '',
      open: false
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.onlyAdmin = localStorage.getItem('role')
  }
}
</script>

<style lang="scss" scoped>
.data__right {
  margin-left: 50px;
}
.area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 17px;
  position: static;
  width: fit-content;
  height: 28px;
  left: 0px;
  top: 0px;
  background: #1b778b;
  opacity: 0.8;
  border-radius: 49px;
  flex: none;
  order: 0;
  flex-grow: 0;
  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.thingsNeeded {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 19px;
  position: static;
  width: fit-content;
  height: 28px;
  left: 0px;
  top: 0px;
  background: #1b778b;
  opacity: 0.8;
  border-radius: 49px;
  flex: none;
  order: 0;
  flex-grow: 0;
  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.subarea {
  display: flex;
  flex-direction: row;
  padding: 5px 19px;
  width: fit-content;
  height: 28px;
  background: #319cb4;
  border-radius: 49px;
  opacity: 0.8;
  margin-top: 10px;

  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.right_side {
  right: 117px;
  position: absolute;
  &__middle {
    right: 242px;
    position: absolute;
  }
  &__init {
    right: 36px;
    position: absolute;
  }
}
.separator {
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  width: 300px-55px;
  font-size: 24px;
  position: absolute;
  top: -55px;
  line-height: 33px;
  color: #1b778b;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
  &__align {
    height: 40px;
    position: absolute;
  }
}

.align_table {
  display: flex;
}

.form_info {
  font-family: Mulish;
  font-style: normal;
  margin-bottom: 9px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  margin-left: 1.5px;
  margin-right: 60px;
  &__subtitle {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 18px;
    margin-left: 2px;
    color: #686868;
  }
}

.content {
  border-top: 1px solid #cdcbcb;
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: block;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.middle_content {
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
}
.end_content {
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
}

.accordions {
  margin-left: 62px;
}
.accordion_size {
  width: 785px;
  padding-left: 24px;
}

.abstract {
  &__title {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #303030;
    margin-top: 35px;
    margin-bottom: 14px;
    margin-left: 81px;
  }
  &__content {
    font-family: Mulish;
    margin-left: 81px;
    width: 733px;
    font-style: normal;
    margin-bottom: 35px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #686868;
  }
}
.grey_separator {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #303030;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
  width: 785px;
  margin-left: 65px;
  border-bottom: 0.5px solid #cdcdcd;
}

.grey_line {
  width: 714px;
  margin-left: 44px;
  margin-bottom: 35px;
  border-bottom: 0.5px solid #cdcdcd;
}

.inner__wrapper {
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #fdfdfd;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;
    margin-bottom: 25px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }
  .infos {
    padding: 0px 22px 25px;

    .address,
    .contacts,
    .generalData {
      .title {
        border-bottom: 0.5px solid $primary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 5px;

        span {
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .data {
        margin-left: 10%;
        margin-top: 35px;
        display: flex;
        font-size: 16px;
        color: #686868;

        &__row {
          width: 320px;
          margin-bottom: 35px;
          h4 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__rowLarge {
          width: 520px;
          margin-bottom: 35px;
          margin-left: 6px;
          h4 {
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__align {
          width: 520px;
          margin-bottom: 35px;
          margin-left: 46px;
        }
        .warning {
          color: $attention;
          border-bottom-color: $attention;
        }
        .separator {
          background-color: $primary;
          height: 1px;
          margin-top: 5px;
          margin-bottom: 30px;
          width: 81.17%;
        }
      }
    }
  }
}

.button_edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  /* position: absolute; */
  width: 100px;
  height: 36px;
  left: 1230px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-weight: 600;
  font-size: 15px;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
