<template>
  <aside class="wrapper__analisis-info">
    <div @click="backToList()" class="return">
      <v-icon medium color="#0E5671">mdi-arrow-left</v-icon>
      <h3>Retornar à listagem</h3>
    </div>
    <div class="separator"></div>
    <div class="wrapper__info">
      <button-default
        v-if="this.status === 'pendente de analise' && this.active != false"
        color="#FF9800"
        :onClick="startAnalisis"
        >Iniciar Análise</button-default
      >
      <button-default
        v-else-if="this.status === 'resposta enviada' && this.active != false"
        color="#A76F00"
        disabled
        class="disabled"
        >Análise encerrada</button-default
      >
      <button-default v-else-if="this.active === false" color="#A76F00" disabled class="disabled"
        >Solicitação desativada</button-default
      >
      <button-default v-else color="#A76F00" :onClick="revalidateSolicitation"
        >Reavaliar solicitação</button-default
      >

      <div class="infos">
        <div class="infos__item">
          <p>
            Participação da feira afiliada:
            <span v-if="this.active === false" class="inactive__solicitation">inativa</span>
            <span v-else class="active__solicitation">ativa</span>
          </p>
          <a @click="showModal('disableParticipationModal')">Desativar participação</a>
          <div v-if="fairInfo.status === 'resposta enviada'">
            <div v-if="userRole === 'admin'" class="admin_button">
              <a @click="showModal('revalidateSolicitationByAdminModal')">Reavaliar solicitação</a>
            </div>
          </div>
        </div>
        <modal-send-form
          title="areYouSureTitle"
          :onClick="disableParticipation"
          @closeModal="() => toggleModal('disableParticipationModal')"
          :modal="disableParticipationModal"
        >
          <div class="text">
            <p>{{ $t("areYouSureText") }}</p>
          </div>
        </modal-send-form>
        <modal-send-form
          title="importantTitle"
          :onClick="revalidateSolicitation"
          @closeModal="() => toggleModal('revalidateSolicitationByAdminModal')"
          :modal="revalidateSolicitationByAdminModal"
        >
          <div class="text">
            <p>{{ $t("revalidationByAdminText") }}</p>
          </div>
        </modal-send-form>
        <div class="infos__item">
          <p>Resumo da solicitação:</p>
          <div class="consumer">
            <span class="mr-1">Status:</span>
            <div class="badges">
              <div v-if="this.active === false" class="badge orange_inactive">
                <v-icon color="#BD5805F1" small>mdi-information-outline</v-icon>Desativada
              </div>
              <div
                v-if="this.active === true && this.status === 'pendente de analise'"
                class="badge warn"
              >
                <v-icon color="#A76F00" small>mdi-information-outline</v-icon>Pendente de análise
              </div>
              <div
                v-if="this.active === true && this.status === 'em analise'"
                class="badge blue_analisis"
              >
                <v-icon color="#006076" small>mdi-information-outline</v-icon>Solicitação em análise
              </div>

              <div
                v-if="this.active === true && this.status === 'aguardando resposta'"
                class="badge purple_waiting"
              >
                <v-icon color="#8E24AA" small>mdi-information-outline</v-icon>Aguardando resposta
              </div>
              <div
                v-if="this.active === true && this.status === 'resposta enviada'"
                class="badge blue_send"
              >
                <v-icon color="#0E5671" small>mdi-information-outline</v-icon>Resposta enviada
              </div>
              <div
                v-if="this.active === true && this.accepted === true"
                class="badge green_accept ml-2"
              >
                <v-icon color="#046619" small>mdi-information-outline</v-icon>Aceita
              </div>
              <div
                v-if="this.active === true && this.accepted === false"
                class="badge red_reject ml-2"
              >
                <v-icon color="#950000" small>mdi-information-outline</v-icon>Não aceita
              </div>
            </div>
          </div>

          <div class="consumer">
            <span>Código de identificação:</span>
            <span>{{ fairInfo.fair_id }}</span>
          </div>
          <div class="consumer">
            <span>Tipo de escola:</span>
            <span>{{ schoolType }}</span>
          </div>
          <div class="consumer">
            <span>Escola:</span>
            <span>{{ fairInfo.school_name }}</span>
          </div>
          <div class="consumer">
            <span>Feira afiliada:</span>
            <span>{{ fairInfo.fair_name }}</span>
          </div>
          <div class="consumer">
            <span>País/Estado:</span>
            <span>{{ countryState }}</span>
          </div>
          <div class="textarea">
            <textarea
              placeholder="Deixe aqui qualquer observação"
              v-model="observation"
              @blur="saveObservation()"
              maxlength="400"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <nav class="navbar">
      <h3>Navegue pela solicitação:</h3>
      <ul>
        <li :class="{ 'font-weight-bold': tabNavigation === 'request' }">
          <a @click="setTab('Request')">REQUISIÇÕES DE VAGAS</a>
        </li>
        <li :class="{ 'font-weight-bold': tabNavigation === 'schoolData' }">
          <a @click="setTab('SchoolData')">DADOS DA ESCOLA</a>
        </li>
        <li :class="{ 'font-weight-bold': tabNavigation === 'currentFair' }">
          <a @click="setTab('CurrentFair')">FEIRA VIGENTE</a>
        </li>
        <li :class="{ 'font-weight-bold': tabNavigation === 'lastEdition' }">
          <a @click="setTab('LastEdition')">ÚLTIMA EDIÇÃO DA FEIRA</a>
        </li>
        <li :class="{ 'font-weight-bold': tabNavigation === 'aboutFair' }">
          <a @click="setTab('AboutFair')">SOBRE A FEIRA</a>
        </li>
        <li :class="{ 'font-weight-bold': tabNavigation === 'term' }">
          <a @click="setTab('Term')">TERMO DE RESPONSABILIDADE</a>
        </li>
        <li v-if="onlyAdmin === 'admin'" :class="{ 'font-weight-bold': tabNavigation === 'requestVacancies' }">
          <a @click="setTab('RequestVacancies')">ACEITE DE PROJETOS</a>
        </li>
      </ul>
    </nav>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ButtonDefault } from '@/components/atoms'
import { ModalSendForm } from '@/components/organisms/Modals'

export default {
  name: 'AnalisisInfo',
  props: ['fairInfo', 'userRole'],
  components: { ButtonDefault, ModalSendForm },
  data: () => ({
    tabNavigation: '',
    schoolType: '',
    countryState: '',
    observation: '',
    status: '',
    accepted: '',
    disableParticipationModal: false,
    revalidateSolicitationByAdminModal: false,
    active: '',
    onlyAdmin: ''
  }),
  mounted() {
    this.getInfos()
    this.onlyAdmin = localStorage.getItem('role')
  },
  watch: {
    fairInfo() {
      this.getInfos()
    }
  },
  computed: {
    ...mapGetters({
      count: 'list/countDone',
      lang: 'lang',
      affiliationRequestFilter: 'affiliationRequestFilter'
    })
  },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage',
      affiliationListFilter: 'affiliationListFilter'
    }),
    backToList() {
      return this.$router.go(-1)
    },
    async startAnalisis() {
      const id = localStorage.getItem('affiliationRequestId')
      this.accepted = null
      await this.$http.patch(`/fair/status-affiliate/${ id }`, {
        status: 'em analise',
        status_is_active: this.accept,
        is_active: this.active
      })
      this.status = 'em analise'
      this.$emit('reloadInfos')
    },
    showModal(Modal) {
      if (Modal === 'disableParticipationModal') {
        this.toggleModal('disableParticipationModal')
      }
      if (Modal === 'revalidateSolicitationByAdminModal') {
        this.toggleModal('revalidateSolicitationByAdminModal')
      }
    },

    async disableParticipation() {
      this.toggleModal('disableParticipationModal')
      const newData = {
        is_active: false,
        status_is_active: null,
        status: 'pendente de analise'
      }
      const id = localStorage.getItem('affiliationRequestId')
      await this.$http.put(`/fair/send-mail/${ id }`, newData)
      this.$emit('reloadInfos')
    },
    setTab(value) {
      this.tabNavigation = value
      this.$emit('navSelected', this.tabNavigation)
    },
    getInfos() {
      if (this.fairInfo.school_type === 'private') this.schoolType = 'Particular'
      else this.schoolType = 'Pública'

      if (!this.fairInfo.state) this.countryState = this.fairInfo.country
      else { this.countryState = `${ this.fairInfo.country } / ${ this.fairInfo.state }` }

      this.observation = this.fairInfo.observation

      this.status = this.fairInfo.status
      this.accepted = this.fairInfo.status_is_active
      this.active = this.fairInfo.is_active
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    saveObservation() {
      const id = localStorage.getItem('affiliationRequestId')
      const newData = {
        observation: this.observation
      }
      this.$http.put(`/fair/send-mail/${ id }`, newData)
    },
    async revalidateSolicitation() {
      if (this.revalidateSolicitationByAdminModal) {
        this.toggleModal('revalidateSolicitationByAdminModal')
      }
      const newData = {
        vacancies_request: [
          {
            level: 'grade',
            quantity_granted: null,
            quantity_exempt: 0
          },
          {
            level: 'middle',
            quantity_granted: null,
            quantity_exempt: 0
          },
          {
            level: 'high',
            quantity_granted: null,
            quantity_exempt: 0
          }
        ]
      }
      const id = localStorage.getItem('affiliationRequestId')
      await this.$http.put(`/fair/send-mail/${ id }`, newData)
      await this.$http.patch(`/fair/status-affiliate/${ id }`, {
        status: 'pendente de analise',
        status_is_active: null
      })

      this.$emit('reloadInfos')
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: #18516f;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  max-width: 90%;
  text-align: left;
  margin-top: 25px;
}
.wrapper__analisis-info {
  padding: 18px 0px;

  .return {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-left: 23px;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }

    h3 {
      color: $primaryDark;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      margin-left: 10px;
    }
  }

  .separator {
    background-color: $primary;
    height: 1px;
    margin-top: 15px;
    width: 100%;
  }

  .wrapper__info {
    padding: 28px 23px;

    .infos {
      margin-top: 30px;

      &__item {
        margin-top: 30px;
        p {
          font-size: 18px;
          margin-bottom: 6px;

          .active__solicitation {
            color: #0aa640;
          }
          .inactive__solicitation {
            color: #f01111;
          }
        }
        a {
          font-weight: 600;
          font-size: 16px;
          text-decoration-line: underline;
          color: #686868;
        }
        .admin_button {
          margin-top: 15px;
        }
        .consumer {
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;

          span:first-of-type {
            font-weight: bold;
            font-size: 16px;
            color: $primary;
          }

          span:not(:first-of-type) {
            font-size: 16px;
            color: $almostBlack;
          }

          .badges {
            align-items: center;
            display: flex;
          }
        }
        .textarea {
          margin-top: 30px;
          width: 100%;
          textarea {
            border: 1px solid #686868;
            box-sizing: border-box;
            border-radius: 10px;
            height: 200px;
            padding: 10px;
            resize: none;
            width: 100%;

            &::placeholder {
              color: #afaeae;
            }
          }
        }
      }
    }
  }

  .navbar {
    padding: 0px 23px;

    h3 {
      font-size: 16px;
      color: $almostBlack;
    }

    ul {
      list-style: none;
      padding: 10px 0px;

      li {
        color: $primary;
        font-size: 14px;
        padding: 10px 0px;

        &:not(:last-of-type) {
          border-bottom: 1px solid $primary;
        }

        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
}
.disabled {
  background-color: #d4d4d4 !important;
  pointer-events: none;
}
</style>
