<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('projectData')}}</span>
        <span class="form__title__complement"></span>
      </div>
      <div class="form__info">
       <text-warning :text="$t('considerAllFieldObligatory')"/>
      </div>
      <input-default
        :class="[{ warning: checkErrorIfContains('projectTitle') }, 'mb-7 input_large']"
        label=projectTitle id="projectTitle" placeholder=enterTheProjectTitle v-model="form.projectTitle"
        max="250" @input="resetErrors"
      />
      <RadioGroup :class="[{ warning: checkErrorIfContains('typeSearch') }, 'mb-2']"
        label=typeOfResearchDevelop :options="typeOptions"
        v-model="form.typeSearch" @change="resetErrors"
      />
      <div v-if="form.typeSearch === 'tecnológica'" class="info mb-2">
        <img class="info__icon" :src="require(`@/assets/info-black.png`)" alt="Informação sobre a pesquisa" />
        <p class="text">Pesquisa de Engenharia - Materialização de um produto ou processo através de um modelo, protótipo ou instalação piloto. Execução de uma obra, fabricação de um produto, fornecimento de um serviço, execução de um processo.</p>
      </div>

      <div v-if="form.typeSearch === 'científica'" class="info">
        <img class="info__icon" :src="require(`@/assets/info-black.png`)" alt="Informação sobre a pesquisa" />
        <p class="text">{{$t('scientificInfo')}}</p>
      </div>

      <input-select id="areaSelect" :class="[{ warning: checkErrorIfContains('researchArea') }, 'mb-4' ]" :items="areaOptions" v-model="researchArea" label=researchAreaSingle placeholder="Selecione a Área de pesquisa" @input="resetErrors" />

      <InputCheckbox v-if="researchArea" :class="[{ warning: checkErrorIfContains('subareas') }, 'mb-7' ]" :dataArea="areas[researchArea]" :v-model="areas[researchArea]" />

      <input-default :class="[{ warning: checkErrorIfContains('otherSubArea') }, 'mb-7' ]"
        label="Existe outra sub-área?" id="otherSubArea" placeholder="Se sim, insira qual é a outra sub-área" type="text" v-model="form.otherSubArea" @input="resetErrors" />
      <div class="radioGroups">
        <div class="continuity">
          <RadioGroup  :class="[{ warning: checkErrorIfContains('isContinuous')}, 'mb-2']"  label=isYourProjectContinuity :options="generalOptions" v-model="form.isContinuous" @change="resetErrors" />

          <div class="info">
            <img class="info__icon" :src="require(`@/assets/info-black.png`)" alt="Informação sobre a pesquisa" />
            <p class="text">{{$t('continuityProjectInfo')}}</p>
          </div>
        </div>

        <div class="involvingAnimals">
          <RadioGroup
            :class="[{ warning: checkErrorIfContains('hasAnimals') }, 'mb-2']"
            label=doesItInvolveVertebrateAnimals
            :options="generalOptions" v-model="form.hasAnimals" @change="resetErrors" />

          <RadioGroup
            :class="[{warning: checkErrorIfContains('hasHumans')}, 'mb-2']"
            label=doesItInvolveHumans :options="generalOptions" v-model="form.hasHumans" @change="resetErrors" />

          <div class="info">
            <img class="info__icon" :src="require(`@/assets/info-black.png`)" alt="Informação sobre a pesquisa"
            />
            <p class="text">{{$t('doesItInvolveHumansInfo')}}</p>
          </div>
        </div>
        <RadioGroup
          :class="[{ warning: checkErrorIfContains('hasBioSubst') }, 'mb-2' ]"
          label=doesItInvolveBiologicalSubstances :options="generalOptions" v-model="form.hasBioSubst" @change="resetErrors" />

        <div :class="[{ biologicalSubst: form.hasBioSubst === false }, 'mb-2']">
          <RadioGroup :class="[{ warning: checkErrorIfContains('dangerousBiologicals')}, { biologicalSubst: form.hasBioSubst === false }]"
            label=doesItInvolveDangerousBiologicalSubstances
            :options="generalOptions" v-model="form.dangerousBiologicals" :disabled="!this.form.hasBioSubst" @change="resetErrors"
          />
          <RadioGroup :class="[{ warning: checkErrorIfContains('humanOrAnimalTissue')}, { biologicalSubst: form.hasBioSubst === false }]"
            label=doesItInvolveTissueFromHumans
            :options="generalOptions" v-model="form.humanOrAnimalTissue" :disabled="!this.form.hasBioSubst" @change="resetErrors"
          />
        </div>

        <RadioGroup :class="[{ warning: checkErrorIfContains('controlledSubst') }, 'mb-2']" label="Envolve substâncias controladas?" :options="generalOptions" v-model="form.controlledSubst" @change="resetErrors"
        />

        <RadioGroup :class="[{ warning: checkErrorIfContains('dangerousEquip') }, 'mb-2']" label=doesItInvolveDangerousSubstances :options="generalOptions" v-model="form.dangerousEquip" @change="resetErrors"
        />

        <div class="researchOnInstituition">
          <RadioGroup :class="[{ warning: checkErrorIfContains('researchOnInstituition') }, 'mb-2']" label=isTheResearchCarriedInInstituition :options="generalOptions" v-model="form.researchOnInstituition" @change="resetErrors"
          />

          <div class="info">
            <img class="info__icon" :src="require(`@/assets/info-black.png`)" alt="Informação sobre a pesquisa" />
            <p class="text">{{$t('isTheResearchCarriedInInstituitionInfo')}}</p>
          </div>
        </div>
      </div>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndContinue')}}</button-default >
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, InputSelect, InputCheckbox, ButtonDefault, FeedbackWarning, TextWarning, ButtonIconDefault, InputMasked, RadioGroup
} from '@/components/atoms'
import { ModalSendForm } from '@/components/organisms/Modals'
import { areas, areaOptions } from '@/utils/areas'

export default {
  name: 'FormSelectionDataProject',
  components: {
    InputDefault, InputSelect, InputCheckbox, ButtonDefault, FeedbackWarning, TextWarning, ButtonIconDefault, InputMasked, ModalSendForm, RadioGroup
  },
  props: ['element'],
  data() {
    return {
      modal: false,
      disabled: true,
      researchArea: '',
      form: {
        otherSubArea: '',
        typeSearch: '',
        projectTitle: '',
        isContinuous: '',
        hasAnimals: '',
        hasHumans: '',
        hasBioSubst: '',
        dangerousBiologicals: '',
        humanOrAnimalTissue: '',
        controlledSubst: '',
        dangerousEquip: '',
        researchOnInstituition: ''
      },
      areas,
      typeOptions: [
        { label: 'Científica', value: 'cientifica' },
        { label: 'Tecnológica', value: 'tecnologia' }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ],
      areaOptions,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    ...mapGetters({
      projectSelection: 'projectSelection'
    })
  },
  created() {
    if (this.projectSelection.project_title) {
      this.researchArea = this.projectSelection.area
      this.form.controlledSubst = !!this.projectSelection.controlled_substances
      this.form.dangerousBiologicals = !!this.projectSelection.danger_biological_substances || false
      this.form.dangerousEquip = !!this.projectSelection.equipments_danger
      this.form.hasAnimals = !!this.projectSelection.vertebrate_animals
      this.form.hasBioSubst = !!this.projectSelection.biological_substances
      this.form.humanOrAnimalTissue = !!this.projectSelection.human_or_animals_tissues || false
      this.form.isContinuous = !!this.projectSelection.continuous_project
      this.form.hasHumans = !!this.projectSelection.human_beings
      this.form.projectTitle = this.projectSelection.project_title
      this.form.researchOnInstituition = !!this.projectSelection.research_instituition
      this.form.typeSearch = this.projectSelection.research_type
      this.areas = {
        ...this.areas,
        [this.projectSelection.area]: {
          ...this.areas[this.projectSelection.area],
          ...this.projectSelection.sub_areas
        }
      }
      if (this.projectSelection.otherSubArea) this.form.otherSubArea = this.projectSelection.otherSubArea
    }
  },
  methods: {
    checkForm() {
      this.resetErrors()
      if (!this.researchArea) {
        this.errors.push('researchArea')
      }
      for (const key in this.form) {
        if (typeof this.form[key] === 'boolean') {
          continue
        }
        if (!this.form[key]) {
          if (key != 'otherSubArea' || this.isMarkedOther(this.areas[this.researchArea])) {
            this.errors.push(key)
          }
        }
      }

      if (this.form.hasBioSubst == false && typeof this.form.hasBioSubst === 'boolean') {
        const indexDangerous = this.errors.indexOf('dangerousBiologicals')
        if (indexDangerous > -1) {
          this.errors.splice(indexDangerous, 1)
        }
        const indexHuman = this.errors.indexOf('humanOrAnimalTissue')
        if (indexHuman > -1) {
          this.errors.splice(indexHuman, 1)
        }
      }
      const allFalse = []
      for (const property in this.areas) {
        const result = Object.entries(this.areas[property]).every((item) => !item[1].value)
        allFalse.push(result)
      }
      if (allFalse.every((item) => item == true)) {
        this.errors.push('subareas')
      }
      if (!this.errors.length) {
        this.saveForm()
      }
    },
    isMarkedOther(subareas) {
      for (const key in subareas) {
        if (key.includes('Other') && subareas[key].value == true) {
          return true
        }
      }
      return false
    },
    saveForm() {
      try {
        const localForm = { ...this.form }
        const formData = this.$store.getters.projectSelection
        const sub_areas = []
        for (const property in this.areas) {
          if (property == this.researchArea) {
            Object.entries(this.areas[property]).forEach((item) => {
              if (item[1].value) {
                sub_areas.push({ ...item[1], variableName: item[0] })
              }
            })
          }
        }
        if (localForm.otherSubArea.length) {
          sub_areas.otherSubArea = { text: localForm.otherSubArea, value: true }
        }
        const newData = {
          ...formData,
          area: this.researchArea,
          research_type: localForm.typeSearch,
          project_title: localForm.projectTitle,
          continuous_project: localForm.isContinuous,
          vertebrate_animals: localForm.hasAnimals,
          human_beings: localForm.hasHumans,
          biological_substances: localForm.hasBioSubst,
          danger_biological_substances: localForm.dangerousBiologicals,
          human_or_animals_tissues: localForm.humanOrAnimalTissue,
          controlled_substances: localForm.controlledSubst,
          equipments_danger: localForm.dangerousEquip,
          research_instituition: localForm.researchOnInstituition,
          otherSubArea: localForm.otherSubArea,
          sub_areas
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem('projectSelectionData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.biologicalSubst {
  color: #afaeae;
  ::v-deep .v-radio > .v-label {
    font-weight: normal !important;
    font-size: 16px;
    line-height: 18px;
    color: #afaeae;
  }

  :after,
  :before {
    color: #afaeae;
  }
}

.info {
  display: flex;
}

.info__icon {
  align-self: bottom;
  margin-top: 0px;
  margin-right: 5px;
  height: 17px;
  width: 17px;
  color: #303030;
}
.text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
}
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }

    .form__title__complement {
      font-weight: 400;
      margin-left: 11px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__contact__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $primary;
    color: $primary;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__add__contact__button__container {
    display: flex;
    justify-content: center;
  }

  &__add__contact__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }

  .button {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
