<template>
  <div>
    <div class="inner__wrapper">
      <div class="header">
        <h3>Feira vigente</h3>
        <div v-if="onlyAdmin === 'admin'">
          <button class="button_edit" v-if="!open" @click="open = !open">
            <strong>EDITAR</strong>
          </button>
          <div class="cancel-and-save">
            <button class="button_cancel" v-if="open" @click="cancelSave">
              CANCELAR
            </button>
            <button-default class="button_save" v-if="open" :onClick="checkForm"
              >Salvar</button-default
            >
          </div>
        </div>
      </div>
      <div class="infos">
        <div class="info__item">
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>Nome da feira</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('fair_name') },
                    'input__space',
                  ]"
                  type="text"
                  max="100"
                  v-model="fairInfo.fair_name"
                />
                <span v-else>{{ fairInfo.fair_name }}</span>
              </div>
              <div class="data__right">
                <h4>Site</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('website') },
                    'input__space',
                  ]"
                  type="text"
                  max="100"
                  v-model="fairInfo.website"
                />
                <span v-else>{{ fairInfo.website }}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>Nome do coordenador da feira</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('fairs_coordinator_name') },
                    'input__space',
                  ]"
                  type="text"
                  max="100"
                  v-model="fairInfo.fairs_coordinator_name"
                />
                <span v-else>{{ fairInfo.fairs_coordinator_name }}</span>
              </div>
              <div class="data__right">
                <h4>Tipo de feira</h4>
                <input-select
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('fairType') },
                    'mb-7',
                  ]"
                  id="fairType"
                  :items="items"
                  v-model="fairInfo.fair_type"
                  @input="resetErrors"
                />
                <span v-else>{{ fairType }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputDefault,
  ButtonDefault,
  InputSelect,
  textWarning,
  FeedbackWarning
} from '@/components/atoms'

export default {
  name: 'CurrentFair',
  components: {
    InputDefault,
    ButtonDefault,
    InputSelect,
    textWarning,
    FeedbackWarning
  },
  props: ['fairInfo'],
  data() {
    return {
      fairType: '',
      open: false,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      onlyAdmin: '',
      items: [
        { text: this.$t('schoolFairType'), value: 'escolar' },
        { text: this.$t('multiSchoolFairType'), value: 'multiescolar' },
        { text: this.$t('townFairType'), value: 'municipal' },
        { text: this.$t('townFairType2'), value: 'outras redes' },
        { text: this.$t('regionalFairType'), value: 'regional' },
        { text: this.$t('stateFairType'), value: 'estadual' },
        { text: this.$t('nationalFairType'), value: 'nacional' },
        { text: this.$t('internationalFairType'), value: 'internacional' }
      ]
    }
  },
  mounted() {
    this.getInfos()
    this.onlyAdmin = localStorage.getItem('role')
  },
  methods: {
    getInfos() {
      if (this.fairInfo.fair_type === 'escolar') this.fairType = 'Escolar (participam projetos somente de uma escola)'
      else if (this.fairInfo.fair_type === 'multiescolar') this.fairType = 'Multiescolar (participam projetos de diferentes escolas, podendo ser de uma mesma rede ou de redes diferentes, inclusive de cidades diferentes)'
      else if (this.fairInfo.fair_type === 'municipal') this.fairType = 'Municipal (participam exclusivamente projetos da rede municipal de educação)'
      else if (this.fairInfo.fair_type === 'outras redes') this.fairType = 'Municipal - outras redes (participam mais de uma rede de ensino: municipal e/ou estadual e/ou privada)'
      else if (this.fairInfo.fair_type === 'regional') this.fairType = 'Regional'
      else if (this.fairInfo.fair_type === 'estadual') this.fairType = 'Estadual'
      else if (this.fairInfo.fair_type === 'nacional') this.fairType = 'Nacional'
      else this.fairType = 'Internacional'
    },
    checkForm() {
      this.resetErrors()
      for (const key in this.fairInfo) {
        if (!this.fairInfo[key]) {
          if (
            key !== 'fair_name'
            && key !== 'website'
            && key !== 'fairs_coordinator_name'
          ) {
          } else {
            this.errors.push(`${ key }`)
          }
        }
      }
      if (!this.fairType) {
        this.errors.push('fairType')
      }
      if (this.errors.length) {
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }

      if (!this.errors.length) {
        this.editForm()
      }
    },
    async editForm() {
      const fairId = this.fairInfo.id

      const body = {
        fair: {
          fair_name: this.fairInfo.fair_name,
          fairs_coordinator_name: this.fairInfo.fairs_coordinator_name,
          fair_type: this.fairInfo.fair_type,
          website: this.fairInfo.website
        }
      }

      try {
        await this.$http.put(`/edit-fair/${ fairId }`, body)
        location.reload()
      } catch (error) {
        console.log(error.message)
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    cancelSave() {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.inner__wrapper {
  background: #fdfdfd;
  border-radius: 10px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 10px 22px 10px;

    .info__item {
      margin-top: 25px;

      .data__wrapper {
        .data__row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 30px;
          padding-left: 8%;

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: #686868;
          }
        }
      }
    }
  }
}

.button_edit {
  padding: 0px 16px;

  height: 36px;

  background: #26768d;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;

  color: #ffffff;
}

.button_save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  margin: 0px 10px;

  width: 106px;
  height: 36px;
  left: 1215px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_cancel {
  box-sizing: border-box;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 32px;

  width: 113px;
  height: 36px;
  left: 1105px;
  top: 126px;

  border: 1px solid #26768d;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;

  color: #26768d;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.edit-and-paragrafh {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2%;
}

.cancel-and-save {
  display: flex;
  justify-content: space-between;
}
</style>
