const projectAreas = {
  arts: 'art',
  biology: 'bio',
  education: 'eduPedago',
  eletronic: 'eletronicInfoRobo',
  engineering: 'enge',
  filosofy: 'filoso',
  phisics: 'fis',
  geography: 'geo',
  history: 'his',
  inclusion: 'incAcessTec',
  chemistry: 'qui',
  portuguese: 'lingPortLingStrangLit',
  math: 'mat',
  envirornment: 'meioAmb',
  health: 'saud',
  healthSciences: 'saud',
  sociology: 'socio',
  bioquimics: 'bioquimics',
  animalSciences: 'animalPlantsSciences',
  ambientalSciences: 'ambientalSciences',
  computacionalSciences: 'computerScience',
  healthSciences: 'healthSciences',
  planetarySciences: 'planetarySciences',
  humanities: 'educationHumanities',
  sanitaryEngineering: 'sanitaryEngineering',
  materialsEngineering: 'materialsAndEngineering',
  eletricEngineering: 'electricEngineering',
  eletronicEngineering: 'electronicEngineering',
  mechanicalEngineering: 'mechanicalEngineering',
  socialSciences: 'historyAndSocialSciences',
  other: 'other'
}

export default projectAreas
