<template>
  <label class="label" :for="id" :style="newStyle">
    {{ $t(label) }}
    <v-select
      class="input"
      v-model="selectedOption"
      @input="$emit('input', selectedOption)"
      :items="items"
      item-text="text"
      item-value="value"
      outlined
      :disabled="disabled"
    />
  </label>
</template>

<script>

export default {
  name: 'InputSelect',
  props: {
    id: String,
    items: Array,
    label: String,
    value: [String, Boolean, Object, Number],
    selected: [String, Number],
    newStyle: {
      type: Object,
      required: false
    },
    newStyleInternalElement: {
      type: Object,
      required: false
    },
    disabled: Boolean
  },
  data: () => ({
    selectedOption: ''
  }),
  mounted() {
    if (this.value) {
      this.selectedOption = this.value
      this.$emit('input', this.selectedOption)
    }
  },
  watch: {
    value() {
      this.selectedOption = this.value
    },
    selected() {
      this.selectedOption = this.selected
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: #303030;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.warning {
  color: $attention;

  .input {
    border-color: $attention;
  }

  ::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset, ::v-deep .v-input__slot fieldset {
    border-color: $attention !important;
    color: $attention !important;
  }

}

.input {
  background: #FFFFFF;
  border-radius: 10px;
  color: $almostBlack;
  font-weight: 500;
  height: 55px;
  margin-top: 5px;
  max-width: 400px;
  padding: 8px 15px;
  outline: none;

  &::placeholder {
    color: #AFAEAE;
    font-size: 14px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}

::v-deep .v-text-field--outlined fieldset {
  top: 5px !important;
  bottom: 7px !important;
}

::v-deep .v-input__slot fieldset {
  color: #CDCBCB !important
}
</style>
