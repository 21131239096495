<template>
  <div>
    <div class="stepper__container">
      <v-stepper v-model="element" alt-labels>
        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :complete="element > index + 1"
              :step="index + 1"
              :key="`${index}-${step.text}`"
              ><p class="step_text">{{ step.text }}</p></v-stepper-step
            >
            <v-divider
              :key="`${index}:${step}`"
              v-if="index < steps.length - 1"
            ></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            v-for="(component, index) in components"
            :step="index + 1"
            :key="`${index}`"
          >
            <component
              :is="component"
              :ref="component"
              @changeElement="changeElement"
              @changeElementWrapper="changeElement"
              :mostratec_type="cAllDataForm.FormLevelOfEducation.mostratec_type"
              :allDataForm="cAllDataForm"
              :educationLevel="cAllDataForm.FormLevelOfEducation.level"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import {
  FormLevelOfEducation,
  FormProjectHighOthersInvolveds,
  FormProjectHighResearchPlan,
  FormProjectHighProjectAbstract,
  FormProjectHighResearcherSummary,
  FormPayment
} from './FormProjectSubscribe'
import { FormSelectionSchool } from './FormSelectionTabs'
import {
  WrapperFormProjectAboutAdvisors,
  WrapperFormProjectData,
  WrapperFormProjectStudents,
  WrapperFormProjectContract
} from './FormProjectSubscribe/Wrappers'

export default {
  name: 'FormSelection',
  components: {
    FormSelectionSchool,
    FormLevelOfEducation,
    WrapperFormProjectData,
    WrapperFormProjectStudents,
    WrapperFormProjectAboutAdvisors,
    WrapperFormProjectContract,
    FormProjectHighOthersInvolveds,
    FormProjectHighResearchPlan,
    FormProjectHighProjectAbstract,
    FormProjectHighResearcherSummary,
    FormPayment
  },
  data() {
    return {
      userId: '',
      element: 1,
      randomKey: 0,
      steps: [
        { text: this.$t('levelsOfEducational') },
        { text: this.$t('school') },
        { text: this.$t('projectData') },
        { text: this.$t('aboutStudents') },
        { text: this.$t('aboutAdvisors') },
        { text: this.$t('othersInvolved') },
        { text: this.$t('researchPlan') },
        { text: this.$t('projectSummary') },
        { text: this.$t('summaryForTheResearcher') },
        { text: this.$t('registrationFee') },
        { text: this.$t('documents') }
      ],
      components: [
        FormLevelOfEducation.name,
        FormSelectionSchool.name,
        WrapperFormProjectData.name,
        WrapperFormProjectStudents.name,
        WrapperFormProjectAboutAdvisors.name,
        FormProjectHighOthersInvolveds.name,
        FormProjectHighResearchPlan.name,
        FormProjectHighProjectAbstract.name,
        FormProjectHighResearcherSummary.name,
        FormPayment.name,
        WrapperFormProjectContract.name
      ],
      allDataForm: {
        FormLevelOfEducation: '',
        FormSelectionSchool: '',
        FormProjectData: '',
        FormProjectStudents: '',
        FormSelectionAboutAdvisors: '',
        FormProjectChildishData: '',
        FormProjectChildishStudents: '',
        FormProjectChildishAdvisors: '',
        FormProjectHighData: '',
        FormProjectHighStudents: '',
        FormProjectHighAdvisors: '',
        FormProjectHighOthersInvolveds: '',
        FormProjectHighResearchPlan: '',
        FormProjectHighProjectAbstract: '',
        FormProjectHighResearcherSummary: '',
        FormProjectHighUpload: '',
        FormPayment: '',
        FormContract: ''
      }
    }
  },
  mounted() {
    this.getUser()
    this.getAllDataForm()
  },
  watch: {
    'allDataForm.FormLevelOfEducation.level': {
      handler(after, before) {
        if (this.allDataForm.FormLevelOfEducation.level !== 'high') {
          this.components = [
            FormLevelOfEducation.name,
            FormSelectionSchool.name,
            WrapperFormProjectData.name,
            WrapperFormProjectStudents.name,
            WrapperFormProjectAboutAdvisors.name,
            FormPayment.name,
            WrapperFormProjectContract.name
          ]

          this.steps = [
            { text: this.$t('levelsOfEducational') },
            { text: this.$t('school') },
            { text: this.$t('projectData') },
            { text: this.$t('aboutStudents') },
            { text: this.$t('aboutAdvisors') },
            { text: this.$t('registrationFee') },
            { text: this.$t('coexistenceContract') }
          ]
        }
      },
      deep: true
    }
  },
  methods: {
    changeElement(data) {
      const actual = this.components[this.element - 1]

      if (data.direction === 'up') {
        this.element += 1
        const next = this.components[this.element - 1]
        this.$refs[next]?.[0].activate()
      }

      if (data.direction === 'down') this.element -= 1

      if (data[actual]) {
        this.allDataForm[actual] = data[actual]
      }

      return false
    },
    getUser() {
      this.userId = jwt_decode(localStorage.getItem('token')).uid
    },
    async getAllDataForm() {
      try {
        const { data: response } = await this.$http.get(`/temp/${ this.userId }`)
        if (response) {
          if (response.temporary_data.FormLevelOfEducation.level) {
            let index = 1
            if (response.temporary_data.FormLevelOfEducation.level) {
              this.allDataForm = response.temporary_data
            }
            for (const i in this.allDataForm) {
              if (this.allDataForm[i]) {
                if (this.allDataForm.FormLevelOfEducation.level !== 'high') {
                  this.components = [
                    FormLevelOfEducation.name,
                    FormSelectionSchool.name,
                    WrapperFormProjectData.name,
                    WrapperFormProjectStudents.name,
                    WrapperFormProjectAboutAdvisors.name,
                    FormPayment.name,
                    WrapperFormProjectContract.name
                  ]

                  if (index < this.components.length - 1) {
                    index++
                  }
                }
                if (this.allDataForm.FormLevelOfEducation.level === 'high') {
                  this.components = [
                    FormLevelOfEducation.name,
                    FormSelectionSchool.name,
                    WrapperFormProjectData.name,
                    WrapperFormProjectStudents.name,
                    WrapperFormProjectAboutAdvisors.name,
                    FormProjectHighOthersInvolveds.name,
                    FormProjectHighResearchPlan.name,
                    FormProjectHighProjectAbstract.name,
                    FormProjectHighResearcherSummary.name,
                    FormPayment.name,
                    WrapperFormProjectContract.name
                  ]

                  if (index < this.components.length) {
                    index++
                  }
                }
              }
            }
            this.element = index
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  computed: {
    cAllDataForm() {
      if (this.allDataForm) {
        return this.allDataForm
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-steeper {
  box-shadow: none !important;
}

.stepper__container {
  ::v-deep .v-stepper__label {
    text-align: center !important;
    font-size: clamp(0.855rem, calc( 12px + 0.900vw ), 1.125rem);
    line-height: 120%;
    color: $primaryDark !important;
    opacity: 0.8;
  }

  ::v-deep .v-stepper__step--complete + hr {
    border-color: $primary !important;
  }

  ::v-deep .v-stepper__header {
    box-shadow: none !important;
  }

  ::v-deep .v-stepper {
    box-shadow: none !important;
  }

  ::v-deep .v-stepper__wrapper {
    padding-bottom: 20px !important;
  }
}

@media only screen and (max-width: 1250px){
   .step_text{
    display: none;
  }
}
</style>
