<template>
  <div @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <div class="filter">
        <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
        <span>Filtrar</span>
      </div>
      <div class="modal__body">
        <div class="search__inputs">

          <input-multi-select
            v-model="fairName"
            class="mt-6"
            id="fairEditionModal"
            label="fairName"
            placeholder="Selecione o nome da feira"
            :selectedItems="filterName"
            :items="fairNameItems"
            @input="() => addToFilters('filterName', 'fairName')"
            @onRemoveSelected="(index) => removeFilter('filterName', index)"
          />

          <input-multi-select
            v-model="fairCountry"
            class="mt-6"
            id="paisSelect"
            label="country"
            placeholder="Selecione o País"
            :selectedItems="filterCountry"
            :items="countries"
            @input="() => addToFilters('filterCountry', 'fairCountry')"
            @onRemoveSelected="(index) => removeFilter('filterCountry', index)"
          />

          <input-multi-select
            v-if="showState && filterCountry.length === 1"
            v-model="fairState"
            class="mt-6"
            id="stateSelect"
            label="state"
            placeholder="Selecione o Estado"
            :selectedItems="filterState"
            :items="states"
            @input="() => addToFilters('filterState', 'fairState')"
            @onRemoveSelected="(index) => removeFilter('filterState', index)"
          />

          <input-multi-select
            v-if="filterState.length === 1 && filterCountry.length === 1"
            v-model="fairCity"
            class="mt-6"
            id="citySelect"
            label="city"
            placeholder="Selecione a Cidade"
            :selectedItems="filterCity"
            :items="cities"
            @input="() => addToFilters('filterCity', 'fairCity')"
            @onRemoveSelected="(index) => removeFilter('filterCity', index)"
          />

          <input-multi-select
            v-model="fairStatus"
            class="mt-6"
            id="fairStatusModal"
            label="Status"
            placeholder="Selecione o Status"
            :selectedItems="filterStatus"
            :items="fairStatusItems"
            @input="() => addToFilters('filterStatus', 'fairStatus')"
            @onRemoveSelected="(index) => removeFilter('filterStatus', index)"
          />
        </div>

        <div class="button mt-10 text-center">
          <button-default :onClick="checkForm" class="button">Filtrar</button-default>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { InputDefault, ButtonDefault, InputSelect } from '@/components/atoms'
import { HeaderModal } from '@/components/organisms'
import { countries, states } from '@/utils/locations'
import api from '@/services/api'
import InputMultiSelect from '@/components/atoms/Inputs/InputMultiSelect.vue'

export default {
  name: 'ModalFilterSidebar',
  props: ['modal', 'title'],
  components: {
    HeaderModal,
    InputDefault,
    ButtonDefault,
    InputSelect,
    InputMultiSelect
  },
  data: () => ({

    // PROVISÓRIOS
    cities: [],
    //-----------

    countries,
    states,
    fairStatusItems: [
      { text: 'Pendente de análise', value: 'pendente de analise' },
      { text: 'Em analise', value: 'em analise' },
      { text: 'Aguardando resposta', value: 'aguardando resposta' },
      { text: 'Resposta enviada', value: 'resposta enviada' },
      { text: 'Desativada', value: 'desativada' },
      { text: 'Inscrição cancelada', value: 'inscricao cancelada' }
    ],
    fairNameItems: [],
    fairName: '',
    filterName: [],
    fairCountry: '',
    filterCountry: [],
    fairState: '',
    filterState: [],
    showState: false,
    fairCity: '',
    filterCity: [],
    fairStatus: '',
    filterStatus: [],
    filters: null
  }),
  computed: {
    ...mapGetters({
      affiliationRequestFilter: 'affiliationRequestFilter'
    }),
    affiliationRequestFilter: {
      get() {
        return this.affiliationRequestFilter
      },
      set(newName) {
        return newName
      }
    }
  },
  mounted() {
    this.getFairNames()

    const filtros = this.$store.getters.affiliationRequestFilter
    this.filterName = filtros.fair_name ? filtros.fair_name : []
    this.filterCountry = filtros.country ? filtros.country : []
    this.filterState = filtros.state ? filtros.state : []
    this.filterCity = filtros.city ? filtros.city : []
    this.filterStatus = filtros.status ? filtros.status : []
  },
  watch: {
    filterCountry() {
      if (this.filterCountry.includes('Brazil')) {
        this.showState = true
      } else {
        this.showState = false
        this.filterState = []
      }
    },

    filterState() {
      this.cities = []
      this.fairState = this.filterState[0]
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ this.fairState }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    }
  },
  created() {
    document.addEventListener('keyup', (event) => {
      if (event.code === 'Enter' || event.key === 'Enter') {
        return this.checkForm()
      }
    })
  },
  methods: {
    ...mapActions({
      affiliationRequestFilter: 'affiliationRequestFilter'
    }),
    addToFilters(array, value) {
      if (!this[array].includes(this[value])) {
        this[array].push(this[value])
        this[value] = ''
      }
    },
    removeFilter(array, index) {
      this[array].splice(index, 1)
    },
    checkForm() {
      const filters = {
        ...this.$store.getters.affiliationRequestFilter,
        fair_name: this.filterName,
        country: this.filterCountry,
        state: this.filterState,
        city: this.filterCity,
        status: this.filterStatus
      }

      if (!filters.country?.length || !filters.country?.includes('Brazil')) {
        filters.state = []
      }

      if (!filters.state?.length) {
        filters.city = []
      }

      Object.keys(filters).forEach((key) => {
        if (!filters[key].length) delete filters[key]
      })

      this.$store.commit('AFILLIATION_LIST_FILTER', filters)
      this.$emit('closeModal')
      this.$emit('filterAffiliationRequestsList')
    },
    async getFairNames() {
      const { data } = await this.$http.get('/fair/search/name')
      const formatedNames = data.map((i) => ({
        text: i.name,
        value: i.name
      }))
      this.fairNameItems = formatedNames
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  left: 0px;
  position: fixed;
  height: 100%;
  top: 0px;
  width: 100%;
  z-index: 5;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding: 25px;
    flex: 1;
    width: 649px;

    .modal__body {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      width: 100%;
      max-height: 100%;

      .search__inputs {
        margin: 5px 0px 15px 0px;

        .search__item {
          .badges {
            margin-top: 15px;

            span {
              align-items: center;
              display: inline-flex;
              justify-content: center;
              background: $primary;
              border-radius: 49px;
              opacity: 0.8;
              color: $white;
              font-weight: bold;
              font-size: 14px;
              min-width: 79px;
              height: 28px;
              padding: 0px 8px;

              &:not(:last-of-type) {
                margin-right: 10px;
              }

              button {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.filter {
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: $primary;
  border-bottom: 1px solid $primary;
  padding-bottom: 12px;
}
</style>
