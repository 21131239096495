module.exports = {
areasEs: {
  materialsEngineering: [
    { text: 'Ingeniería de Transporte', value: 'transportEngineer' },
    { text: 'Ingeniería Aeroespacial y Aeronáutica, Aerodinámica', value: 'aerospaceAeronauticalEngineeringAerodynamics' },
    { text: 'Ingeniería Civil y Construcción', value: 'civilEngineeringConstruction' },
    { text: 'Ingeniería de Seguridad', value: 'securityEngineering' },
    { text: 'Desarrollo de Productos', value: 'productDevelopment' },
    { text: 'Ciencia de los Materiales', value: 'materialsScience' },
    { text: 'Otros', value: 'others' }
  ],

  eletricEngineering: [
    { text: 'Eléctrica General', value: 'generalElectrical' },
    { text: 'Generación, Transmisión y Transformación de Energía Eléctrica', value: 'generationTransmissionTransformationElectricity' },
    { text: 'Accionamientos Eléctricos', value: 'electricDrives' },
    { text: 'Energías Renovables', value: 'renewableEnergy' },
    { text: 'Otros', value: 'others' }
  ],

  eletronicEngineering: [
    { text: 'Electrónica General', value: 'electronicGeneral' },
    { text: 'Sistemas y Procesos Electrónicos', value: 'electronicSystemProcess' },
    { text: 'Sistemas de Comunicación', value: 'communicationsSystem' },
    { text: 'Automatización Industrial', value: 'industrialAutomation' },
    { text: 'Robótica', value: 'robotics' },
    { text: 'Otros', value: 'others' }
  ],

  mechanicalEngineering: [
    { text: 'Mecánica General', value: 'generalMechanics' },
    { text: 'Ingeniería Industrial', value: 'industrialEng' },
    { text: 'Desarrollo de Vehículos', value: 'vehicleDevelopment' },
    { text: 'Desarrollo de Productos y Procesos', value: 'processProductDevelopment' },
    { text: 'Otros', value: 'others' }
  ],

  ambientalSciences: [
    { text: 'Contaminación y Calidad del Aire', value: 'pollutionAirQuality' },
    { text: 'Contaminación y Calidad del Agua', value: 'pollutionWaterQuality' },
    { text: 'Contaminación y Calidad del Suelo', value: 'contaminationSoilQuality' },
    { text: 'Toxicología Ambiental', value: 'environmentalToxicology' },
    { text: 'Otros', value: 'others' }
  ],

  animalSciences: [
    { text: 'Desarrollo Biológico (Humano y Animal)', value: 'biologicalDevelopment' },
    { text: 'Ecología', value: 'ecology' },
    { text: 'Crianza de Animales', value: 'animalHusbandry' },
    { text: 'Fisiología y Patología Animal', value: 'animalPhysiologyPathology' },
    { text: 'Agricultura/Agronomía', value: 'agricultureAgronomy' },
    { text: 'Morfología y Desarrollo de las Plantas', value: 'plantMorphologyDevelopment' },
    { text: 'Fotosíntesis', value: 'photosynthesis' },
    { text: 'Fisiología de las Plantas', value: 'plantPhysiology' },
    { text: 'Teoría de la Evolución de las Plantas', value: 'plantEvolutionTheory' },
    { text: 'Otros', value: 'others' }
  ],

  bioquimics: [
    { text: 'Bioquímica General', value: 'generalBiochemistry' },
    { text: 'Bioquímica Estructural', value: 'structuralBiochemistry' },
    { text: 'Metabolismo', value: 'metabolism' },
    { text: 'Química de los Alimentos', value: 'foodChemistry' },
    { text: 'Química Orgánica', value: 'organicChemistry' },
    { text: 'Química Inorgánica', value: 'inorganicChemistry' },
    { text: 'Química Analítica', value: 'analyticalChemistry' },
    { text: 'Química General', value: 'generalChemistry' },
    { text: 'Ingeniería Química', value: 'chemicalEngineering' },
    { text: 'Fisicoquímica', value: 'physicochemical' },
    { text: 'Otros', value: 'others' }
  ],

  biology: [
    { text: 'Biología Molecular y Celular', value: 'molecularCellBiology' },
    { text: 'Genética', value: 'genetics' },
    { text: 'Inmunología', value: 'immunology' },
    { text: 'Antibióticos', value: 'antibiotics' },
    { text: 'Antimicrobianos', value: 'antimicrobials' },
    { text: 'Bacteriología', value: 'bacteriology' },
    { text: 'Virología', value: 'virology' },
    { text: 'Micología', value: 'mycology' },
    { text: 'Otros', value: 'others' }
  ],

  socialSciences: [
    { text: 'Historia', value: 'history' },
    { text: 'Psicología', value: 'psychology' },
    { text: 'Sociología', value: 'sociology' },
    { text: 'Antropología', value: 'anthropology' },
    { text: 'Economía', value: 'economics' },
    { text: 'Política', value: 'politics' },
    { text: 'Administración', value: 'management' },
    { text: 'Otros', value: 'others' }
  ],

  planetarySciences: [
    { text: 'Climatología, Clima', value: 'climatologyClimate' },
    { text: 'Geoquímica, Geofísica', value: 'geochemistryGeophysics' },
    { text: 'Mineralogía', value: 'mineralogy' },
    { text: 'Paleontología', value: 'paleontology' },
    { text: 'Ciencias Planetarias', value: 'planetarySciences' },
    { text: 'Geología', value: 'geology' },
    { text: 'Sismología', value: 'seismology' },
    { text: 'Oceanografía', value: 'oceanography' },
    { text: 'Álgebra', value: 'algebra' },
    { text: 'Matemáticas Analíticas', value: 'analyticalMathematics' },
    { text: 'Matemáticas Aplicadas', value: 'appliedMath' },
    { text: 'Geometría', value: 'geometry' },
    { text: 'Probabilidad y Estadísticas', value: 'probabilityStatistics' },
    { text: 'Átomos, Moléculas, Sólidos', value: 'atomsMoleculesSolids' },
    { text: 'Física Biológica', value: 'biologicalPhysics' },
    { text: 'Instrumentación y Electrónica', value: 'instrumentationElectronics' },
    { text: 'Magnetismo y Electromagnetismo', value: 'magnetismElectromagnetism' },
    { text: 'Física de Partículas y Nuclear', value: 'particleNuclearPhysics' },
    { text: 'Óptica, Láser', value: 'opticsLaser' },
    { text: 'Física Teórica, Astronomía', value: 'theoreticalPhysicsAstronomy' },
    { text: 'Otros', value: 'others' }
  ],

  humanities: [
    { text: 'Teorías del Conocimiento, Metodología, Didáctica, Pedagogía', value: 'theoriesKnowledgeMethodologyDidacticsPedagogy' },
    { text: 'Lingüística', value: 'linguistics' },
    { text: 'Expresión Artística', value: 'artisticExpression' },
    { text: 'Filosofía y Teología', value: 'philosophyTheology' },
    { text: 'Otros', value: 'others' }
  ],

  sanitaryEngineering: [
    { text: 'Biorremediación', value: 'Bioremediation' },
    { text: 'Gestión de Ecosistemas', value: 'ecosystemManagement' },
    { text: 'Gestión de Recursos Terrestres, Hídricos y Forestales', value: 'managementLandWaterForestResources' },
    { text: 'Gestión de Residuos, Reciclaje', value: 'wasteManagementRecycling' },
    { text: 'Política Ambiental, Legislación Ambiental', value: 'environmentalPolicyEnvironmentalLegislation' },
    { text: 'Educación Ambiental', value: 'environmentalEducation' },
    { text: 'Otras', value: 'others' }
  ],
  healthSciences: [
    { text: 'Diagnóstico y Tratamiento de Enfermedades', value: 'diagnosisTreatmentDiseases' },
    { text: 'Epidemiología', value: 'epidemiology' },
    { text: 'Genética', value: 'Genetics' },
    { text: 'Fisiología y Fisiopatología', value: 'physiologyPathophysiology' },
    { text: 'Salud Laboral', value: 'occupationalHealth' },
    { text: 'Prevención, Profilaxis', value: 'preventionProphylaxis' },
    { text: 'Nutrición', value: 'nutrition' },
    { text: 'Biomedicina', value: 'biomedicine' },
    { text: 'Enfermería', value: 'nursing' },
    { text: 'Odontología', value: 'dentistry' },
    { text: 'Educación Física', value: 'physicalEducation' },
    { text: 'Otras', value: 'others' }
  ],
  computacionalSciences: [
    { text: 'Ingeniería Informática', value: 'computerEngineering' },
    { text: 'Algoritmos, Bases de Datos', value: 'algorithmsDatabase' },
    { text: 'Inteligencia Artificial', value: 'artificialIntelligence' },
    { text: 'Comunicación y Redes', value: 'CommunicationNetworks' },
    { text: 'Ciencias de la Computación', value: 'computerScience' },
    { text: 'Gráficos', value: 'graphics' },
    { text: 'Ingeniería de Software', value: 'softwareEngineering' },
    { text: 'Sistemas Informáticos', value: 'computerSystems' },
    { text: 'Lenguajes de Programación', value: 'programmingLanguages' },
    { text: 'Otras', value: 'others' }
  ]
},

areaOptionsEs: [
  { text: 'Biología Celular y Molecular, Microbiología', value: 'biology' },
  { text: 'Bioquímica y Química', value: 'bioquimics' },
  { text: 'Ciencias Animales y de Plantas', value: 'animalSciences' },
  { text: 'Ciencias Ambientales', value: 'ambientalSciences' },
  { text: 'Ciencias de la Computación', value: 'computacionalSciences' },
  { text: 'Ciencias de la Salud', value: 'healthSciences' },
  { text: 'Ciencias Planetarias, Terrestres, Matemáticas y Físicas', value: 'planetarySciences' },
  { text: 'Educación y Humanidades', value: 'humanities' },
  { text: 'Ingeniería Ambiental y Sanitaria', value: 'sanitaryEngineering' },
  { text: 'Ingeniería y Materiales', value: 'materialsEngineering' },
  { text: 'Ingeniería Eléctrica', value: 'eletricEngineering' },
  { text: 'Ingeniería Electrónica', value: 'eletronicEngineering' },
  { text: 'Ingeniería Mecánica', value: 'mechanicalEngineering' },
  { text: 'Historia y Ciencias Sociales', value: 'socialSciences' }
],

areasElementarySchoolEs: [
  { text: 'Artes', value: 'arts' },
  { text: 'Biología', value: 'biology' },
  { text: 'Educación y Pedagogía', value: 'education' },
  { text: 'Electrónica, Informática y Robótica', value: 'eletronic' },
  { text: 'Ingeniería', value: 'engineering' },
  { text: 'Filosofía', value: 'filosofy' },
  { text: 'Física', value: 'phisics' },
  { text: 'Geografía', value: 'geography' },
  { text: 'Historia', value: 'history' },
  { text: 'Inclusión, Accesibilidad y Tecnología Asistida', value: 'inclusion' },
  { text: 'Química', value: 'chemistry' },
  { text: 'Lengua Portuguesa, Lengua Extranjera y Literatura', value: 'portuguese' },
  { text: 'Matemáticas', value: 'math' },
  { text: 'Medio Ambiente', value: 'envirornment' },
  { text: 'Salud', value: 'health' },
  { text: 'Sociología', value: 'sociology' },
  { text: 'Otra', value: 'other' }
],
areaOptionsElementaryEs: [
  { text: 'Artes', value: 'arts' },
  { text: 'Biología', value: 'biology' },
  { text: 'Educación y Pedagogía', value: 'education' },
  { text: 'Electrónica, Informática y Robótica', value: 'eletronic' },
  { text: 'Ingeniería', value: 'engineering' },
  { text: 'Filosofía', value: 'filosofy' },
  { text: 'Física', value: 'phisics' },
  { text: 'Geografía', value: 'geography' },
  { text: 'Historia', value: 'history' },
  { text: 'Inclusión, Accesibilidad y Tecnología', value: 'inclusion' },
  { text: 'Química', value: 'chemistry' },
  { text: 'Lengua Portuguesa, Lengua Extranjera y Literatura', value: 'portuguese' },
  { text: 'Matemáticas', value: 'math' },
  { text: 'Medio Ambiente', value: 'envirornment' },
  { text: 'Salud', value: 'health' },
  { text: 'Sociología', value: 'sociology' },
  { text: 'Insertar Otra Subárea', value: 'other' }
]
}
