<template>
  <label :class="[{badge: !color}, {lightBlue: color === 'lightBlue' }]">
    <span class="name">{{$t(name)}}</span>
  </label>
</template>

<script>
export default {
  name: 'BadgeDefault',
  props: {
    color: String,
    name: String
  }
}
</script>

<style lang="scss" scoped>
.badge {
   display: flex;
    flex-direction: row;
    padding: 5px 19px;
    width: fit-content;
    height: 28px;
    background: $primary;
    border-radius: 49px;
    opacity: 0.8;
    margin-top: 10px;
}

.lightBlue {
   display: flex;
    flex-direction: row;
    padding: 5px 19px;
    width: fit-content;
    height: 28px;
    background: #319CB4;
    border-radius: 49px;
    opacity: 0.8;
    margin-top: 10px;
}

.name{
      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
    }
</style>
