<template>
  <div class="wrapper">
    <div class="wrapper__item" v-for="(option, index) in options" :key="option.text">
      <input
        type="radio"
        :id="id + index"
        :name="(id + index)"
        :value="option.value"
        :checked="option.value === value"
        @change="updateValue(option.value)"
      />
      <label class="label" :for="id + index">{{ $t(option.text) }}</label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputRadioGroup',
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: null
    },
    options: {
      type: [Array],
      required: true
    }
  },
  methods: {
    updateValue(value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;

  &__item {
    margin-right: 10px;

    input {
      cursor: pointer;
    }

    .label {
      color: $almostBlack;
      cursor: pointer;
      font-size: 16px;
      line-height: 18px;
      margin-left: 5px;
    }
  }
}
</style>
