<template>
  <div>
    <div class="inner__wrapper">
      <div class="infos">

        <div class="container">
            <div class="row" style="width: 1100px;">
                <div class="col text-left title"><strong>{{$t('schoolInstitution')}}</strong></div>
            </div>
            <div class="row" style="width: 1100px;">
                <div class="col text-left" style="padding-left: 30px;">
                    <strong>{{this.fairInfo.school_name}}</strong><br/>
                    <strong>{{$t('responsiblePerson')}}: </strong>{{this.fairInfo.coordinator_name}}<br/>
                    <strong>Email: </strong>{{this.fairInfo.school_email}}
                </div>
            </div>
            <div class="row" style="width: 1100px;">
                <div class="col text-left title"><strong>{{$t('address')}}</strong></div>
            </div>
            <div class="row" style="width: 1100px;">
                <div class="col text-left" style="padding-left: 30px;">
                    {{this.fairInfo.country}} / {{this.fairInfo.state}}<br/>
                    {{this.fairInfo.city}}<br/>
                    {{this.fairInfo.street}}, {{this.fairInfo.house_number}} {{this.fairInfo.complement}}
                </div>
            </div>

            <div class="row" style="width: 1100px;">
                <div class="col text-left title"><strong>{{$t('contacts')}}</strong></div>
            </div>
            <div v-for="(contact, index) in fairInfo.fair_contacts" :key="contact.id">
                <div class="row" style="width: 1100px;">
                    <div class="col text-left" style="padding-left: 30px;">
                        <strong>{{contact.contact_name}}</strong>
                        <strong v-if="contact.main"> (Principal)</strong><br/>
                        {{contact.email}}<br/>
                        {{contact.office}}
                    </div>
                </div>
            </div>

        </div>


      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AboutSchool',
  props: {
    fairInfo: Object
  },
  data() {
    return {
      fair_participants: [0, 0, 0]
    }
  },
  created() {
    if (this.fairInfo.fair_participants) {
        //console.log('this.fairInfo')
        //console.log(this.fairInfo.school_name)
      this.fairInfo.fair_participants.forEach((item) => {
        if (item.level == 'participants_grade') {
          this.fair_participants[0] = item
        }
        if (item.level == 'participants_middle') {
          this.fair_participants[1] = item
        }
        if (item.level == 'participants_high') {
          this.fair_participants[2] = item
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.about {
  margin-top: 16px;
}
.inner__wrapper {
  background: #FDFDFD;
  border-radius: 10px;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 0px 22px 25px;

    .info__item {
      margin-top: 40px;

      .title {
        align-items: center;
        border-bottom: 0.5px solid #AFAEAE;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        padding-bottom: 5px;

        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: $almostBlack;
        }
      }

      .data__wrapper {

        h3 {
          border-bottom: 1px solid #3E778D;
          color: $primary;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
          margin-left: 8%;
          margin-right: 8%;
          padding-bottom: 10px;
        }

        .data__row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 10px;
          padding-left: 8%;

          div {
            align-items: center;
            display: flex;
          }

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: #686868;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
