<template>
  <section>
    <filter-edition @filterEditionsList="getEditions" />
    <div>
      <table>
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome da edição</th>
            <th>Formato</th>
            <th>Inscrição de afiliação</th>
            <th>Análise das feiras afiliadas</th>
            <th>Incrição de projetos via feira afiliada</th>
            <th>Incrição de projetos via processo de seleção</th>
            <th>Período do evento</th>
            <th>Mais informações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in editions" :key="index">
            <td class="code">
              <strong>#{{item.id}}</strong>
            </td>
            <td class="name">
              <span>{{item.name}}</span>
            </td>
            <td class="name">
              <span>{{item.format}}</span>
            </td>
            <td class="institution">
              <span>{{item.afiliation}}</span>
            </td>
            <td class="type-school">
              <span>{{item.analisis}}</span>
            </td>
            <td class="infantil">
              <span>{{item.inscription}}</span>
            </td>
             <td class="infantil">
              <span>{{item.selection}}</span>
            </td>
            <td class="infantil">
              <span>{{item.events}}</span>
            </td>
            <td class="action">
              <v-icon @click="detailItem(item.id)" color="#1B778B" large>mdi-chevron-right</v-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <v-container fluid>
          <v-row justify="end">
            <v-pagination v-model="page" class="my-4" :length="lastPage"></v-pagination>
          </v-row>
          <v-row class="mr-0" justify="end">
            <span
              class="pagination__text"
            >{{page == 1 ? '1' : ((page - 1) * perPage) + 1}} - {{page * perPage}} de {{total}}</span>
          </v-row>
        </v-container>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { FilterEdition } from '@/components/organisms/Filters'

export default {
  name: 'FormEditionAllEditions',
  components: { FilterEdition },
  computed: {
    ...mapGetters({
      count: 'list/countDone',
      lang: 'lang',
      editionVacanciesSet: 'editionVacanciesSet',
      editionRequestFilter: 'editionRequestFilter'
    })
  },
  data: () => ({
    page: 1,
    total: 0,
    perPage: 50,
    lastPage: 1,
    limit: 15,
    editions: []
  }),
  mounted() {
    this.getEditions()
  },
  watch: {
    editionVacanciesSet() {
      this.getEditions()
    },
    editionRequestFilter() {
      this.getEditions()
    },
    page() {
      this.getEditions()
    }
  },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage'
    }),
    detailItem(id) {
      localStorage.setItem('editionDetailsId', id)
      this.$router.push({ name: 'DetailsEdition', params: { id } })
    },
    async getEditions() {
      const filtros = await this.$store.getters.editionRequestFilter
      const { data } = await this.$http.post(`/mostratec/search/all?page=${ this.page }&limit=${ this.perPage }`, filtros)
      const tableContent = []
      this.total = data.total
      this.lastPage = data.lastPage
      data.data.forEach((item) => {
        tableContent.push({
          id: item.id,
          name: item.name_edition,
          format: this.getFormat(item.format_edition),

          afiliation: `${ this.formatDate(
            item.fair_register_init
          ) } - ${ this.formatDate(item.fair_register_end) }`,
          analisis: `${ this.formatDate(item.analisys_init) } - ${ this.formatDate(
            item.analisys_end
          ) }`,
          inscription: `${ this.formatDate(
            item.project_date_init
          ) } - ${ this.formatDate(item.project_date_end) }`,
          selection: `${ this.formatDate(
            item.project_selection_date_init
          ) } - ${ this.formatDate(item.project_selection_date_end) }`,
          events: `${ this.formatDate(item.fair_date_init) } - ${ this.formatDate(
            item.fair_date_end
          ) }`
        })
      })
      this.editions = tableContent
    },
    getFormat(format) {
      if (format === 'presencial') return 'Presencial'
      if (format === 'virtual') return 'Virtual'
      if (format === 'hibrida') return 'Híbrida'
    },
    formatDate(date) {
      if (!date) return null

      const dateFormatted = new Date(date).toISOString().substr(0, 10)

      const [year, month, day] = dateFormatted.split('-')
      return `${ day }/${ month }/${ year }`
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  display: grid;
  overflow-x: auto;
  border-collapse: collapse;
  grid-template-columns:
    minmax(60px, 80px)
    minmax(160px, 1fr)
    minmax(80px, 90px)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(140px, 1fr)
    minmax(132px, 132px);
}

thead,
tbody,
tr {
  display: contents;
}

th,
td {
  padding: 5px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  align-items: center;
  background-color: #dbe9ec;
  color: $primary;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 65px;
  position: relative;
  text-align: left;
}

th:first-child {
  border-radius: 10px 0px 0px 0px;
}

th:last-child {
  border-radius: 0px 10px 0px 0px;
}

tbody tr td {
  align-items: center;
  display: flex;
  color: $almostBlack;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

tbody tr:nth-child(odd) td {
  background-color: #f6f6f6;
}

tbody tr:nth-child(even) td {
  background-color: $white;
}

.pagination__text {
  font-size: 14px;
  line-height: 18px;
  color: #6b6b6b;
}

::v-deep .v-pagination__item {
  box-shadow: none !important;
  color: #b5b5b5 !important;
  font-size: 14px !important;
  height: 30px !important;
  line-height: 16px !important;
  min-width: 15px !important;
}

::v-deep .v-pagination__item--active {
  color: $white !important;
}

::v-deep .v-pagination__navigation {
  box-shadow: none !important;
  height: 30px !important;
  line-height: 16px !important;
  width: 15px !important;
  outline: 0;
}
</style>
