<template>
  <div>
    <input-masked
      required
      :id="data"
      placeholder="dd/mm/yyyy"
      type="text"
      v-model="date"
      :mask="['##/##/####']"
      style="width:fit-content;max-width: 125px;"
    />
  </div>
</template>

<script>
import { InputMasked } from '.'

export default {
  name: 'InputCalendar2',
  components: { InputMasked },
  props: {
    label: String,
    data: String,
    value: String | Boolean
  },
  data: () => ({
    date: '',
    dateFormatted: '',
    menu: false
  }),
  created() {
    if (this.value) {
      this.date = this.value
      this.$emit('dateChange', this.date, this.data)
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
      this.$emit('dateChange', this.dateFormatted, this.data)
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${ year }-${ month }-${ day }`
    }
  }
}
</script>

<style lang="scss" scoped>
.input-calendar {
  background: $white;
  border: 1px solid #CDCBCB;
  border-radius: 10px;
  box-sizing: border-box;
  height: 35px;
  max-width: 240px;
  //width: 60px;
  padding: 10px 15px;

  font-size: 14px;
  line-height: 18px;
  color: #AFAEAE;

  ::v-deep label {
    color: $almostBlack;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    top: -12px;
    left: -10px !important;
  }

  ::v-deep input {
    padding: 0px !important;
  }

  ::v-deep .v-text-field {
    margin-top: 0px;
  }

  ::v-deep .v-input__slot {
    margin-bottom: 0px;

    &::before {
      border-color: red !important;
      border: none !important;
    }
  }
}

</style>
