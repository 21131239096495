<template>
  <section>
    <nav class="navigation">
      <div
        class="nav__item"
        :class="{active: list == 'all'}"
        @click="setList('all')"
      >Todos as solicitações</div>
      <div
        class="nav__item"
        :class="{active: list == 'aguardando resposta'}"
        @click="setList('aguardando resposta')"
      >Aguardando resposta</div>
    </nav>
    <div class="top__container">
      <div class="search">
        <div class="search__top col-12">
          <h3 class="search__top--label">Busca</h3>
        </div>
        <div class="search__inputs">
          <div class="search__item col-4">
            <input-select
              id="EditionSelect"
              :items="filterEditionOptions"
              v-model="fairEdition"
              label="Edição da feira"
              placeholder="Selecione a Edição da feira"
              @input="() => addFairEdition()"
            />
            <div class="badges" v-if="filtersEdition">
              <span
                class="badgeFilter"
                v-for="(item, index) in filtersEdition"
                :key="`${item}-${index}`"
              >
                {{item}}
                <button @click="removeFilter('filtersEdition', index)">
                  <v-icon medium color="white">mdi-close-circle</v-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="search__item col-4">
            <input-select
              id="typeSelect"
              :items="filterTypeOptions"
              v-model="fairType"
              label="Tipo de feira"
              placeholder="Selecione o Tipo de feira"
              @input="() => addFairType()"
            />
            <div class="badges" v-if="filtersType">
              <span
                class="badgeFilter"
                v-for="(item, index) in filtersType"
                :key="`${item}-${index}`"
              >
                {{item}}
                <button @click="removeFilter('filtersType', index)">
                  <v-icon medium color="white">mdi-close-circle</v-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="search__item col-4">
            <button-default
              v-if="list == 'aguardando resposta'"
              :onClick="enviarEmails"
              class="button send float-right"
              :class="{ disabledButton: !anyCheckboxEnabled}"
              :disabled="!anyCheckboxEnabled"
            >
              ENVIAR E-MAIL
              <img
                class="img-icon"
                :src="require('@/assets/email-icon.png')"
                alt="send email"
              />
            </button-default>
          </div>
        </div>
      </div>
    </div>

    <div class="indicators">
      <indicators />
    </div>

    <div class="info-container">
      <div class="filter">
        <button @click="modalFilterSidebar = !modalFilterSidebar">
          <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
          <span>{{ $t('filter') }}</span>
        </button>
      </div>
      <div class="labels">
        <div class="text">
          <span>Descrição das legendas:</span>
        </div>
        <div class="badge warn" @click="setList('pendente de analise')">
          <v-icon color="#A76F00" small>mdi-information-outline</v-icon>
          <span>Pendente de análise</span>
        </div>
        <div class="badge blue_analisis" @click="setList('em analise')">
          <v-icon color="#006076" small>mdi-information-outline</v-icon>
          <span>Em análise</span>
        </div>
        <div class="badge red_reject" @click="setList('nao aceita')">
          <v-icon color="#950000" small>mdi-information-outline</v-icon>
          <span>Não aceita</span>
        </div>
        <div class="badge green_accept" @click="setList('aceita')">
          <v-icon color="#046619" small>mdi-information-outline</v-icon>
          <span>Aceita</span>
        </div>
        <div class="badge purple_waiting" @click="setList('aguardando resposta')">
          <v-icon color="#8E24AA" small>mdi-information-outline</v-icon>
          <span>Aguardando resposta</span>
        </div>
        <div class="badge blue_send" @click="setList('resposta enviada')">
          <v-icon color="#0E5671" small>mdi-information-outline</v-icon>
          <span>Resposta enviada</span>
        </div>
        <div class="badge orange_inactive" @click="setList('desativada')">
          <v-icon color="#BD5805F1" small>mdi-information-outline</v-icon>
          <span>Desativada</span>
        </div>
        <div class="badge red_reject" @click="setList('inscricao cancelada')">
          <v-icon color="#950000" small>mdi-information-outline</v-icon>
          <span>Inscrição cancelada</span>
        </div>
      </div>

      <modal-filter-sidebar
        @closeModal="modalFilterSidebar = !modalFilterSidebar"
        :modal="modalFilterSidebar"
        @filterAffiliationRequestsList="$emit('filterAffiliationRequestsList')"
      >
      </modal-filter-sidebar>
    </div>
    <modal-default title="E-mail enviado!" @closeModal="toggleModal" :modal="modal">
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/email-send.png" alt="E-mail enviado a MostraTec" />
        </div>
        <div class="text">
          <p
            slot="text"
          >O(s) e-mail(s) de retorno a(s) feira(s) afiliada(s) foi enviado com sucesso!</p>
          <p slot="text">O status das solicitações será atualizado de acordo análise realizada.</p>
        </div>
      </div>
    </modal-default>
    <feedback-warning
      :onClick="() => errors = []"
      v-if="errors.length"
      :text="textWarning"
      color="#ff9800"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  ButtonDefault,
  InputSelect,
  FeedbackWarning
} from '@/components/atoms'
import {
  ModalFilterSidebar,
  ModalDefault
} from '@/components/organisms/Modals'
import { Indicators } from '@/components/organisms/Cards'

export default {
  name: 'FilterDefault',
  props: {
    anyCheckboxEnabled: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ButtonDefault,
    ModalFilterSidebar,
    InputSelect,
    ModalDefault,
    FeedbackWarning,
    Indicators
  },
  computed: {
    ...mapGetters({
      count: 'list/countDone',
      lang: 'lang',
      affiliationRequestFilter: 'affiliationRequestFilter',
      affiliationSendMails: 'affiliationSendMails'
    }),
    affiliationRequestFilter: {
      get() {
        return this.affiliationRequestFilter
      },
      set(newName) {
        return newName
      }
    },
    mailsToSend: {
      get() {
        return this.affiliationSendMails
      },
      set(newName) {
        return newName
      }
    }
  },
  mounted() {
    this.getMostratecsData()
  },
  data() {
    return {
      fairEdition: '',
      fairType: '',
      list: 'all',
      filtersEdition: [],
      filterEditionOptions: [],
      filtersType: [],
      filterTypeOptions: [
        { text: 'Mostratec', value: 'mostratec' },
        { text: 'Mostratec Júnior', value: 'junior' },
        { text: 'Ambas', value: 'ambas' }
      ],
      modalFilterSidebar: false,
      modal: false,
      textWarning: '',
      errors: []
    }
  },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage',
      affiliationRequestFilter: 'affiliationRequestFilter'
    }),
    setList(type) {
      const filters = this.$store.getters.affiliationRequestFilter
      let envio = {}
      if (type === 'all') {
        envio = {
          ...filters
        }
        delete envio.status
      }
      if (type !== 'all') {
        envio = {
          ...filters,
          status: [type]
        }
      }
      this.$store.commit('AFILLIATION_LIST_FILTER', envio)
      this.list = type
      this.$emit('setList', type)
    },
    addFairEdition() {
      if (!this.filtersEdition.includes(this.fairEdition)) {
        this.filtersEdition.push(this.fairEdition)
        this.fairEdition = ''
        this.filterRequests()
      }
    },
    addFairType() {
      if (!this.filtersType.includes(this.fairType)) {
        this.filtersType.push(this.fairType)
        this.fairType = ''
        this.filterRequests()
      }
    },
    removeFilter(array, index) {
      this[array].splice(index, 1)
      this.filterRequests()
    },
    filterRequests() {
      const oldFilters = this.$store.getters.affiliationRequestFilter
      const newFilters = {
        ...oldFilters,
        mostratec_type: this.filtersType,
        name_edition: this.filtersEdition
      }
      if (!newFilters.mostratec_type.length) delete newFilters.mostratec_type
      if (!newFilters.name_edition.length) delete newFilters.name_edition
      this.$store.commit('AFILLIATION_LIST_FILTER', newFilters)
      this.$emit('filterAffiliationRequestsList')
    },
    async enviarEmails() {
      const emails = this.mailsToSend
      try {
        const { data: response } = await this.$http.post('/fair/send-mail/all', { emails })
        if (response.status === 200) {
          this.toggleModal()
          this.filterRequests()
        }
      } catch (error) {
        console.log('erro: ', error)
        this.textWarning = 'failedToSendEmail'
        this.errors.push('emails')
      }
    },
    async getMostratecsData() {
      const { data } = await this.$http.get('/mostratec')
      const activeYear = data.find((item) => item.active)
      this.fairEdition = activeYear.name_edition
      this.addFairEdition()
      this.filterEditionOptions = data.map((item) => item.name_edition)
    },
    toggleModal() {
      this.modal = !this.modal
    }
  }
}
</script>

<style lang="scss" scoped>
.disabledButton {
  cursor: default;
  background-color: darkgray !important;
}

.navigation {
  align-items: center;
  background-color: $white;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px;
  margin: 0px -15px;

  .nav__item {
    color: $primaryDark;
    cursor: pointer;
    line-height: 120%;
    opacity: 0.8;
    padding: 0px 20px 15px 20px;
  }

  .active {
    font-weight: 600;
    color: $primaryDark;
    border-bottom: 3px solid $primary;
  }
}

.top__container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.indicators {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -50px;
}

.search {
  flex: 1;
  &__top {
    display: flex;
    justify-content: space-between;
    line-height: 120%;
    margin-top: 20px;

    &--label {
      font-weight: bold;
      font-size: 1rem;
      line-height: 120%;
      color: $almostBlack;
      opacity: 0.8;
    }

    span {
      font-weight: 600;
      font-size: 1rem;
      color: $primaryDark;
    }
  }

  &__inputs {
    display: flex;
    margin-bottom: 50px;

    .search__item {
      .badges {
        margin-top: 15px;

        span {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          background: $primary;
          border-radius: 49px;
          opacity: 0.8;

          color: $white;
          font-weight: bold;
          font-size: 14px;
          min-width: 79px;
          height: 28px;
          padding: 0px 8px;

          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.send {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 229px;
  height: 44.43px;
  left: 1138px;
  bottom: 1015.57px;
  background: $primaryDark;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #fff;
}
.img-icon {
  width: 21px;
  height: 15px;
  margin-left: 7px;
}

.info-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 50px;

  .text span {
    font-weight: 600;
    font-size: 14px;
    color: $almostBlack;
    margin-right: 10px;
  }

  .badge {

    span {
      margin-left: 5px;
    }
  }
}

.filter button {
  @include d-flex-center;
  background-color: $white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  height: 40px;
  width: 92px;

  font-weight: 600;
  line-height: 20px;
  color: $primary;

  &:hover {
    background-color: #d6d6d6;
  }
}

.modal__body {
  @include d-flex-center;
  margin-top: 30px;
  justify-content: flex-start;
  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $primaryDark;
    font-size: 16px;
    font-weight: 600;
    line-height: 20, 08px;
    margin-left: 7%;
    text-align: left;
    max-width: 300px;
  }
}
</style>
