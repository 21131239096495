<template>
  <div class="info">
    <img :src="require(`@/assets/information-circle.png`)" alt="Informação sobre Liberato">
    <p>{{$t(text)}}</p>
  </div>
</template>

<script>
export default {
  name: 'TextInfo',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;

  p {
    font-size: 14px;
    line-height: 18px;
    color: $almostBlack;
    margin-left: 6px;
    max-width: 522px;
  }
}

</style>
