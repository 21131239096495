<template>
  <div class="home">
    <InitialRegisterProjectFair />
  </div>
</template>

<script>
import { InitialRegisterProjectFair } from '@/components/templates'

export default {
  name: 'RegisterProjectFair',
  components: { InitialRegisterProjectFair }
}
</script>
