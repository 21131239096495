/* eslint-disable prefer-destructuring */
<template>
  <div class="details__wrapper">
    <div @click="backToList()" class="return">
      <v-icon medium color="#0E5671">mdi-arrow-left</v-icon>
      <h3>Retornar à listagem</h3>
    </div>
    <div class="separator"></div>
    <div class="title mb-7">
      <h1>Edição Mostratec Digital</h1>
      <div class="info">
        <span class="badge">{{editionInfo.id}}</span>
        <span class="info__text">Dados da edição</span>
      </div>
    </div>
    <div v-if="field === 'dataGeneral'">
      <div class="status">
        <v-row no-gutters>
          <div class="col-12 col-sm-8">
            <input-switch
              label="fairStatus"
              id="status"
              :onChange="onChangeSwitch"
              :defaultState="editionInfo.active"
              :disable="true"
            />
            <text-info text="editionFairStatusInfo" />
          </div>
        </v-row>
      </div>
      <div class="data__info mt-5">
        <h4>Nome da edição</h4>
        <span>{{editionInfo.name_edition}}</span>
      </div>
      <div class="data__info">
        <h4>Ano</h4>
        <span>{{editionInfo.year_edition}}</span>
      </div>
      <div class="data__info">
        <h4>Formato</h4>
        <span>{{format}}</span>
      </div>
      <div class="periods">
        <h3>Períodos para as feiras afiliadas</h3>
      </div>
      <div class="data__row mt-7">
        <div class="data__info">
          <h4>Inscrição de afiliação à Mostratec</h4>
          <span
            >{{formatDate(this.editionInfo.fair_register_init)}} •
            {{formatDate(this.editionInfo.fair_register_end)}}</span
          >
        </div>
        <div class="data__info">
          <h4>Análise das feiras afiliadas</h4>
          <span
            >{{formatDate(this.editionInfo.analisys_init)}} •
            {{formatDate(this.editionInfo.analisys_end)}}</span
          >
        </div>
      </div>
      <div class="data__row">
        <div class="data__info">
          <h4>Resposta às feiras afiliadas</h4>
          <span
            >{{formatDate(this.editionInfo.fair_response_init)}} •
            {{formatDate(this.editionInfo.fair_response_end)}}</span
          >
        </div>
        <div class="data__info">
          <h4>Inscrição de projetos via feira afiliada</h4>
          <span
            >{{formatDate(this.editionInfo.project_date_init)}} •
            {{formatDate(this.editionInfo.project_date_end)}}</span
          >
        </div>
      </div>
      <div class="data__row">
        <div class="data__info">
          <h4>Evento Mostratec</h4>
          <span
            >{{formatDate(this.editionInfo.fair_date_init)}} •
            {{formatDate(this.editionInfo.fair_date_end)}}</span
          >
        </div>
        <div class="data__info">
          <h4>Inscrição de projetos via processo de seleção</h4>
          <span
            >{{formatDate(this.editionInfo.project_selection_date_init)}} •
            {{formatDate(this.editionInfo.project_selection_date_end)}}</span
          >
        </div>
      </div>
    </div>
    <div v-if="field === 'vacanciesDetails'" class="status">
      <!-- ---------  DETALHES DAS VAGAS  -------------- -->

      <div class="container" style="width: 600px;">
        <div class="row" style="width: 600px;">
            <div class="col text-center"><strong>Níveis</strong></div>
            <div class="col text-left"><strong>Nº Vagas</strong></div>
            <!-- div class="col text-left"><strong>Públicas</strong></div>
            <div class="col text-left"><strong>Privadas</strong></div>
            <div class="col text-left"><strong>Total</strong></div -->
          </div>
          <!--Educação Infantil -->
          <div class="row" style="width: 600px;">
            <div class="col text-nowrap"><strong>Educação Infantil</strong></div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_grade}}</span>
            </div>
            <!-- div class="col text-left">
              <span>{{editionInfo.vacancies_private_grade}}</span>
            </div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_grade+editionInfo.vacancies_private_grade}}</span>
            </div -->
          </div>
          <!-- EF Cat 1 -->
          <div class="row" style="width: 600px;">
            <div class="col text-nowrap"><strong>EF Categoria 1</strong></div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_1}}</span>
            </div>
            <!-- div class="col text-left">
              <span>{{editionInfo.vacancies_private_middle_cat_1}}</span>
            </div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_1+editionInfo.vacancies_private_middle_cat_1}}</span>
            </div -->
             
          </div>
          <!-- EF Cat 2 -->
          <div class="row" style="width: 600px;">
            <div class="col text-nowrap"><strong>EF Categoria 2</strong></div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_2}}</span>
            </div>
            <!-- div class="col text-left">
              <span>{{editionInfo.vacancies_private_middle_cat_2}}</span>
            </div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_2+editionInfo.vacancies_private_middle_cat_2}}</span>
            </div -->
          </div>
          <!-- EF Cat 3 -->
          <div class="row" style="width: 600px;">
            <div class="col text-nowrap"><strong>EF Categoria 3</strong></div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_3}}</span>
            </div>
            <!-- div class="col text-left">
              <span>{{editionInfo.vacancies_private_middle_cat_3}}</span>
            </div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_3+editionInfo.vacancies_private_middle_cat_3}}</span>
            </div -->
          </div>
          <!-- EF Cat EJA -->
          <div class="row" style="width: 600px;">
            <div class="col text-nowrap"><strong>EF EJA</strong></div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_eja}}</span>
            </div>
            <!-- div class="col text-left">
              <span>{{editionInfo.vacancies_private_middle_cat_eja}}</span>
            </div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_middle_cat_eja+editionInfo.vacancies_private_middle_cat_eja}}</span>
            </div -->
          </div>
          <!-- Ensino Medio -->
          <div class="row" style="width: 600px;">
            <div class="col text-nowrap"><strong>Ens. Medio/Téc</strong></div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_high}}</span>
            </div>
            <!-- div class="col text-left">
              <span>{{editionInfo.vacancies_private_high}}</span>
            </div>
            <div class="col text-left">
              <span>{{editionInfo.vacancies_public_high+editionInfo.vacancies_private_high}}</span>
            </div -->
          </div>

        </div>

        <!-- ---------  FIM DETALHES DAS VAGAS  ----------------- -->


    </div>
    <div v-if="field === 'feesDetails'" class="status">
      <div v-if="this.editable">
        <div class="periods">
          <h3>Informações</h3>
          <span class="subTitle"
            >Defina o valor para a taxa de inscrição de projetos da edição
            ativa.</span
          >
        </div>
        <div class="data__info">
          <h4>Edição ativa do Mostratec</h4>
          <span>{{this.editionInfo.name_edition}}</span>
        </div>
        <div class="feesTitle" style="height:auto;">
          <h3>
            Taxa por participante (Pública, Privada e Estrangeira)
            <p class="alertRequired">
              *Obrigatório Preenchimento de todos os campos
            </p>
          </h3>
        </div>
        <div class="container">
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"></div>
            <div class="col text-left">Públicas: R$</div>
            <div class="col text-left">Privadas: R$</div>
            <div class="col text-left">Estrangeiro: US$</div>
            <div class="col text-left">Nº Vagas</div>
          </div>
          <!--Educação Infantil -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>Educação Infantil</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_public_child"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_private_child"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.foreign_child"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left">
              <input-default 
                disabled
                v-model="editionInfo.vacancies_public_grade" data="vacancies_public_grade" 
                @dateChange="updateVacancies('vacancies_public_grade', $event)"  
                style="width: 80px;" />
              </div>
          </div>
          <!-- EF Cat 1 -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF Categoria 1</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_1"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_1"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_1"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left">
            <input-default 
                disabled
              v-model="editionInfo.vacancies_public_middle_cat_1" data="vacancies_public_middle_cat_1" 
              @dateChange="updateVacancies('vacancies_public_middle_cat_1', $event)"  
              style="width: 80px;" />
            </div>

          </div>
          <!-- EF Cat 2 -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF Categoria 2</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_2"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_2"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_2"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left">
            <input-default 
                disabled
              v-model="editionInfo.vacancies_public_middle_cat_2" data="vacancies_public_middle_cat_2" 
              @dateChange="updateVacancies('vacancies_public_middle_cat_2', $event)"  
              style="width: 80px;" />
            </div>
          </div>
          <!-- EF Cat 3 -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF Categoria 3</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_3"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_3"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_3"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left">
            <input-default 
                disabled
              v-model="editionInfo.vacancies_public_middle_cat_3" data="vacancies_public_middle_cat_3" 
              @dateChange="updateVacancies('vacancies_public_middle_cat_3', $event)"  
              style="width: 80px;" />
            </div>
          </div>
          <!-- EF Cat EJA -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF EJA</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_eja"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_eja"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_eja"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left">
            <input-default 
                disabled
              v-model="editionInfo.vacancies_public_middle_cat_eja" data="vacancies_public_middle_cat_eja" 
              @dateChange="updateVacancies('vacancies_public_middle_cat_eja', $event)"  
              style="width: 80px;" />
            </div>
          </div>
          <!-- Ensino Medio -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>Ens. Medio/Téc</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_public_high"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_private_high"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.foreign_high"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left">
            <input-default 
                disabled
              v-model="editionInfo.vacancies_public_high" data="vacancies_public_high" 
              @dateChange="updateVacancies('vacancies_public_high', $event)"  
              style="width: 80px;" />
            </div>
          </div>

        </div>


        <div class="feesTitle" style="height:auto;">
          <h3>
            Valor das Refeições (por refeição)
          </h3>
        </div>
        <div class="container">
          <!--Educação Infantil -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>Nacional</strong></div>
            <div class="col text-right">R$</div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.national_meal"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

            <div class="col text-nowrap"><strong>Estrangeiros</strong></div>
            <div class="col text-right">US$</div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                disabled
                type="text"
                v-model="editionInfo.body.foreign_meal"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
          </div>

        </div>

        <div>
          <div class="feesSubTitle">
            <h3 class="nameInput">
            </h3>
          </div>
        </div>

        <!-- ================= DISABLED ============================ -->
        <div class="feesTitle">
          <h3>
            <u>Editar datas associadas a esta Mostratec</u>
          </h3>
        </div>

        <div class="container">
          <!--Educação Infantil -->
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">===================================</div>
            <div class="col text-left"><strong>Início</strong></div>
            <div class="col text-left"><strong>Fim</strong></div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Inscrição de afiliação à Mostratec:</div>
            <div class="col text-left">{{ editionInfo.body.fair_register_init }}</div>
            <div class="col text-left">{{ editionInfo.body.fair_register_end }}</div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Análise das feiras afiliadas:</div>
            <div class="col text-left">{{ editionInfo.body.analisys_init }}</div>
            <div class="col text-left">{{ editionInfo.body.analisys_end }}</div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Resposta às feiras afiliadas:</div>
            <div class="col text-left">{{ editionInfo.body.fair_response_init }}</div>
            <div class="col text-left">{{ editionInfo.body.fair_response_end }}</div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Inscrição de projetos via feira afiliada:</div>
            <div class="col text-left">{{ editionInfo.body.project_date_init }}</div>
            <div class="col text-left">{{ editionInfo.body.project_date_end }}</div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Inscrição de projetos via processo de seleção:</div>
            <div class="col text-left">{{ editionInfo.body.project_selection_date_init }}</div>
            <div class="col text-left">{{ editionInfo.body.project_selection_date_end }}</div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Evento Mostratec:</div>
            <div class="col text-left">{{ editionInfo.body.fair_date_init }}</div>
            <div class="col text-left">{{ editionInfo.body.fair_date_end }}</div>
          </div>
        </div>


        <!-- ================================================================== -->
        <div class="flex">
          <button-default
            :onClick="changeToEditScreen"
            class="button-edit"
            >{{$t('edit')}}</button-default
          >
        </div>
      </div>
      <div v-else>
        <!-- EDITAVEL -->
        <div class="periods">
          <h3>Informações</h3>
          <span class="subTitle"
            >Defina o valor para a taxa de inscrição de projetos da edição
            ativa.</span
          >
        </div>
        <div class="data__info">
          <h4>Edição ativa do Mostratec</h4>
          <span>{{this.editionInfo.name_edition}}</span>
        </div>


        <div class="container">
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"></div>
            <div class="col text-left">Públicas: R$</div>
            <div class="col text-left">Privadas: R$</div>
            <div class="col text-left">Estrangeiro: US$</div>
            <div class="col text-left">Nº Vagas</div>
          </div>
          <!--Educação Infantil -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>Educação Infantil</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_public_child"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_private_child"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.foreign_child"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

              <div class="col text-left">
              <input-default :class="inputNew" 
                v-model="editionInfo.vacancies_public_grade" data="vacancies_public_grade" 
                @dateChange="updateVacancies('vacancies_public_grade', $event)"  
                style="width: 80px;" />
              </div>

          </div>
          <!-- EF Cat 1 -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF Categoria 1</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_1"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_1"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_1"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

              <div class="col text-left">
              <input-default 
                v-model="editionInfo.vacancies_public_middle_cat_1" data="vacancies_public_middle_cat_1" 
                @dateChange="updateVacancies('vacancies_public_middle_cat_1', $event)"  
                style="width: 80px;" />
              </div>

          </div>
          <!-- EF Cat 2 -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF Categoria 2</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_2"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_2"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
              <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_2"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

              <div class="col text-left">
              <input-default 
                v-model="editionInfo.vacancies_public_middle_cat_2" data="vacancies_public_middle_cat_2" 
                @dateChange="updateVacancies('vacancies_public_middle_cat_2', $event)"  
                style="width: 80px;" />
              </div>

          </div>
          <!-- EF Cat 3 -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF Categoria 3</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_3"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_3"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_3"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

              <div class="col text-left">
              <input-default
                v-model="editionInfo.vacancies_public_middle_cat_3" data="vacancies_public_middle_cat_3" 
                @dateChange="updateVacancies('vacancies_public_middle_cat_3', $event)"  
                style="width: 80px;" />
              </div>

          </div>
          <!-- EF Cat EJA -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>EF EJA</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_public_middle_cat_eja"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_private_middle_cat_eja"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.foreign_middle_cat_eja"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

              <div class="col text-left">
              <input-default 
                v-model="editionInfo.vacancies_public_middle_cat_eja" data="vacancies_public_middle_cat_eja" 
                @dateChange="updateVacancies('vacancies_public_middle_cat_eja', $event)"  
                style="width: 80px;" />
              </div>

          </div>
          <!-- Ensino Medio -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>Ens. Medio/Téc</strong></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_public_high"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_private_high"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.foreign_high"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

              <div class="col text-left">
              <input-default 
                v-model="editionInfo.vacancies_public_high" data="vacancies_public_high" 
                @dateChange="updateVacancies('vacancies_public_high', $event)"  
                style="width: 80px;" />
              </div>

          </div>

        </div>


        <div class="feesTitle" style="height:auto;">
          <h3>
            Valor das Refeições (por refeição)
          </h3>
        </div>
        <div class="container">
          <!--Educação Infantil -->
          <div class="row" style="width: 1200px;">
            <div class="col text-nowrap"><strong>Nacional</strong></div>
            <div class="col text-right">R$</div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.national_meal"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>

            <div class="col text-nowrap"><strong>Estrangeiros</strong></div>
            <div class="col text-right">US$</div>
            <div class="col text-left"><currency-input  style="width: 80px;"
                class="inputNew"
                type="text"
                v-model="editionInfo.body.foreign_meal"
                :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
              /></div>
          </div>

        </div>

        <!-- ================= edicao ============================ -->


        <div class="feesTitle">
          <h3>
            <u>Editar as datas associadas à esta Mostratec</u>
          </h3>
        </div>

        <div class="container">
          <!--Educação Infantil -->
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">===================================</div>
            <div class="col text-left"><strong>Início</strong></div>
            <div class="col text-left"><strong>Fim</strong></div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Inscrição de afiliação à Mostratec:</div>
            <div class="col text-left"><input-default v-model="editionInfo.body.fair_register_init" data="fairRegisterInit" @dateChange="updateDate('fair_register_init', $event)" /></div>
            <div class="col text-left"><input-default v-model="editionInfo.body.fair_register_end" data="fairRegisterEnd" @dateChange="updateDate('fair_register_end', $event)" /></div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Análise das feiras afiliadas:</div>
            <div class="col text-left"><input-default v-model="editionInfo.body.analisys_init" data="analisysInit" @dateChange="formatDate(dateChange)" /></div>
            <div class="col text-left"><input-default v-model="editionInfo.body.analisys_end" data="analisysEnd" @dateChange="formatDate(dateChange)" /></div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Resposta às feiras afiliadas:</div>
            <div class="col text-left"><input-default v-model="editionInfo.body.fair_response_init" data="fairResponseInit" @dateChange="formatDate(dateChange)" /></div>
            <div class="col text-left"><input-default v-model="editionInfo.body.fair_response_end" data="fairResponseEnd" @dateChange="formatDate(dateChange)" /></div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Inscrição de projetos via feira afiliada:</div>
            <div class="col text-left"><input-default v-model="editionInfo.body.project_date_init" data="projectDateInit" @dateChange="formatDate(dateChange)" /></div>
            <div class="col text-left"><input-default v-model="editionInfo.body.project_date_end" data="projectDateEnd" @dateChange="formatDate(dateChange)" /></div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Inscrição de projetos via processo de seleção:</div>
            <div class="col text-left"><input-default v-model="editionInfo.body.project_selection_date_init" data="projectSelectionDateInit" @dateChange="formatDate(dateChange)" /></div>
            <div class="col text-left"><input-default v-model="editionInfo.body.project_selection_date_end" data="projectSelectionDateEnd" @dateChange="formatDate(dateChange)" /></div>
          </div>
          <div class="row" style="width: 1100px;">
            <div class="col text-nowrap">Evento Mostratec:</div>
            <div class="col text-left"><input-default v-model="editionInfo.body.fair_date_init" data="fairDateInit" @dateChange="formatDate(dateChange)" /></div>
            <div class="col text-left"><input-default v-model="editionInfo.body.fair_date_end" data="fairDateEnd" @dateChange="formatDate(dateChange)" /></div>
          </div>
        </div>

        <!-- ================================================================== -->
        <div class="flex">
          <button-default
            :onClick="checkForm"
            class="button"
            >{{$t('save')}}</button-default
          >
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="textWarning.length"
      :text="textWarning"
      color="#ff9800"
    />
    <feedback-warning v-if="message.length" :text="message" color="#33B850" />
  </div>
</template>

<script>
import {
  InputSwitch, InputDefault, InputDefault2, TextInfo, ButtonDefault, InputMoney, FeedbackWarning
} from '@/components/atoms'

import { AnalisisInfo } from '@/components/organisms'
import {
  ManagerSolicitation, Request, SchoolData, CurrentFair, LastEdition, AboutFair, Term
} from '@/components/organisms/Consumers'
import CurrencyInput from './CurrencyInput'
import { InputCalendar } from '../atoms/Inputs'
import { InputCalendar2 } from '../atoms/Inputs'

export default {
  name: 'DetailsEdition',
  components: {
    InputSwitch,
    ButtonDefault,
    InputMoney,
    FeedbackWarning,
    InputDefault,
    TextInfo,
    AnalisisInfo,
    ManagerSolicitation,
    Request,
    SchoolData,
    CurrentFair,
    LastEdition,
    AboutFair,
    Term,
    CurrencyInput,
    InputCalendar2,
    InputCalendar,
    ButtonDefault,
  },
  props: ['fields'],
  data: () => ({
    editionInfo: {},
    active: true,
    editable: false,
    field: 'dataGeneral',
    format: '',
    message: '',
    textWarning: ''
  }),
  async created() {
    //console.log('entrou created()')
    let requestData = localStorage.getItem('editionDetailsId')
    requestData = requestData || this.$route.params.id
    try {
      requestData = await this.$http.get(`/mostratec/${ requestData }`)
      // eslint-disable-next-line prefer-destructuring
      //
      this.editionInfo = requestData.data[0]
      //console.log('this.editionInfo = requestData.data[0]')
      //console.log(requestData.data[0])
      /*
      const dtfri = new Date(this.editionInfo.fair_register_init)
      const dtfre = new Date(this.editionInfo.fair_register_end)
      const dtanai = new Date(this.editionInfo.analisys_init)
      const dtanae = new Date(this.editionInfo.analisys_end)
      const dtpdi = new Date(this.editionInfo.project_date_init)
      const dtpde = new Date(this.editionInfo.project_date_end)
      const dtfrespi = new Date(this.editionInfo.fair_response_init)
      const dtfrespe = new Date(this.editionInfo.fair_response_end)
      const dtfi = new Date(this.editionInfo.fair_date_init)
      const dtfe = new Date(this.editionInfo.fair_date_end)
      const dtpsdi = new Date(this.editionInfo.project_selection_date_init)
      const dtpsde = new Date(this.editionInfo.project_selection_date_end)
      */
      const dtfri = (this.editionInfo.fair_register_init)
      const dtfre = (this.editionInfo.fair_register_end)
      const dtanai = (this.editionInfo.analisys_init)
      const dtanae = (this.editionInfo.analisys_end)
      const dtpdi = (this.editionInfo.project_date_init)
      const dtpde = (this.editionInfo.project_date_end)
      const dtfrespi = (this.editionInfo.fair_response_init)
      const dtfrespe = (this.editionInfo.fair_response_end)
      const dtfi = (this.editionInfo.fair_date_init)
      const dtfe = (this.editionInfo.fair_date_end)
      const dtpsdi = (this.editionInfo.project_selection_date_init)
      const dtpsde = (this.editionInfo.project_selection_date_end)

      this.editionInfo = {
        ...this.editionInfo,
        national_public_school_rate: `${ this.editionInfo.national_public_school_rate }`,
        national_private_school_rate: `R$ ${ this.editionInfo.national_private_school_rate }`,
        foreign_school_rate: `R$ ${ this.editionInfo.foreign_school_rate }`,
        body: {
          vacancies_public_grade: this.editionInfo.vacancies_public_grade ? this.editionInfo.vacancies_public_grade : 0,
          national_child_education: this.editionInfo.fee ? this.editionInfo.fee.national_child_education : 0,
          //
          national_public_grade: this.editionInfo.fee ? this.editionInfo.fee.national_public_grade : 0,
          national_private_grade: this.editionInfo.fee ? this.editionInfo.fee.national_private_grade : 0,
          //
          
          foreign_child : this.editionInfo.fee ? this.editionInfo.fee.foreign_child : 0,
          foreign_middle_cat_1 : this.editionInfo.fee ? this.editionInfo.fee.foreign_middle_cat_1 : 0,
          foreign_middle_cat_2 : this.editionInfo.fee ? this.editionInfo.fee.foreign_middle_cat_2 : 0,
          foreign_middle_cat_3 : this.editionInfo.fee ? this.editionInfo.fee.foreign_middle_cat_3 : 0,
          foreign_middle_cat_eja : this.editionInfo.fee ? this.editionInfo.fee.foreign_middle_cat_eja : 0,
          foreign_middle: this.editionInfo.fee ? this.editionInfo.fee.foreign_middle : 0,
          foreign_high : this.editionInfo.fee ? this.editionInfo.fee.foreign_high : 0,
          national_private_child : this.editionInfo.fee ? this.editionInfo.fee.national_private_child : 0,
          national_private_middle_cat_1 : this.editionInfo.fee ? this.editionInfo.fee.national_private_middle_cat_1 : 0,
          national_private_middle_cat_2 : this.editionInfo.fee ? this.editionInfo.fee.national_private_middle_cat_2 : 0,
          national_private_middle_cat_3 : this.editionInfo.fee ? this.editionInfo.fee.national_private_middle_cat_3 : 0,
          national_private_middle_cat_eja : this.editionInfo.fee ? this.editionInfo.fee.national_private_middle_cat_eja : 0,
          national_private_middle: this.editionInfo.fee ? this.editionInfo.fee.national_private_middle : 0,
          national_private_high : this.editionInfo.fee ? this.editionInfo.fee.national_private_high : 0,
          national_public_child : this.editionInfo.fee ? this.editionInfo.fee.national_public_child : 0,
          national_public_middle_cat_1 : this.editionInfo.fee ? this.editionInfo.fee.national_public_middle_cat_1 : 0,
          national_public_middle_cat_2 : this.editionInfo.fee ? this.editionInfo.fee.national_public_middle_cat_2 : 0,
          national_public_middle_cat_3 : this.editionInfo.fee ? this.editionInfo.fee.national_public_middle_cat_3 : 0,
          national_public_middle_cat_eja : this.editionInfo.fee ? this.editionInfo.fee.national_public_middle_cat_eja : 0,
          national_public_high : this.editionInfo.fee ? this.editionInfo.fee.national_public_high : 0,

          // MEAL
          national_meal: this.editionInfo.fee ? Number(this.editionInfo.fee.national_meal) : 0,
          foreign_meal: this.editionInfo.fee ? this.editionInfo.fee.foreign_meal : 0,
          // Datas da feira
          fair_register_init: dtfri, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          fair_register_end: dtfre, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          analisys_init: dtanai, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          analisys_end: dtanae, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          project_date_init: dtpdi, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          project_date_end: dtpde, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          fair_response_init: dtfrespi, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          fair_response_end: dtfrespe, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          fair_date_init: dtfi, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          fair_date_end: dtfe, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          project_selection_date_init: dtpsdi, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
          project_selection_date_end: dtpsde, //.toLocaleDateString('pt-BR', { timeZone: 'UTC' })
        }
      }
      if (this.editionInfo.national_private_school_rate !== null) {
        return this.editable = true
      }
      //console.log('this.editionInfo',this.editionInfo)
    } catch (error) {
      this.textWarning = 'Algo deu errado!'
      setTimeout(() => {
        this.textWarning = ''
      }, 3000)
      console.log('Erro api get: ', error)
    }
  },
  watch: {
    fields() {
      this.field = this.fields
      this.editionInfo.vacancies_public_grade
    },
    editionInfo() {
      this.formatInfos()
    }
  },
  methods: {
    async changeToEditScreen() {
      this.editable = !this.editable
    },
    async checkForm() {
      //console.log('checkForm')
      try {
        let body = {}
        //console.log(this.editionInfo)
        if (this.editionInfo.national_public_school_rate !== 'R$ 0,00' && this.editionInfo.national_private_school_rate !== 'R$ 0,00' && this.editionInfo.foreign_school_rate !== 'R$ 0,00') {
          body = {
            ...this.editionInfo,
            national_public_school_rate: parseFloat(
              this.editionInfo.national_public_school_rate.replace('R$ ', '').replace(',', '.')
            ),
            national_private_school_rate: parseFloat(
              this.editionInfo.national_private_school_rate.replace('R$ ', '').replace(',', '.')
            ),
            foreign_school_rate: parseFloat(
              this.editionInfo.foreign_school_rate.replace('R$ ', '').replace(',', '.')
            ),
            vacancies_public_grade: this.editionInfo.body.vacancies_public_grade,
          }
        } else {
          this.textWarning = 'Todos os campos são obrigatórios!'
          setTimeout(() => {
            this.textWarning = ''
          }, 3000)
          return true
        }
        this.$emit('openModal', 'loading', true)
        const createdUser = await this.$http.put(`/mostratec/${ this.editionInfo.id }`, body)
        await this.changeToEditScreen()

        if (createdUser.status === 200) {
          this.message = this.$t('succesfullySaved')
          setTimeout(() => {
            this.message = ''
          }, 3000)
        }
        this.modal = true
      } catch (error) {
        this.textWarning = this.$t('errorDuringSaving')
        setTimeout(() => {
          this.textWarning = ''
        }, 3000)
        console.log('Error: ', error)
      }
      this.$emit('openModal', 'loading', false)
    },
    onChangeSwitch(e) {
      this.$emit('openModal')
    },
    updateDate(key, value) {
      value = formatDate(value)
      this.editionInfo.body[key] = value;
    },
    updateVacancies(key, value){
      console.log('key, value = ', key, value);
      if (key=='vacancies_public_grade') this.editionInfo.body.vacancies_public_grade = value;  // Atualiza o valor diretamente no objeto principal
    },
    formatInfos() {
      if (this.editionInfo.format_edition === 'presencial') this.format = 'Presencial'
      if (this.editionInfo.format_edition === 'virtual') this.format = 'Virtual'
      if (this.editionInfo.format_edition === 'hibrida') this.format = 'Híbrida'
    },
    formatDate(date) {
      return date
      /*
      if (!date) return null

      const dateFormatted = new Date(date).toISOString().substr(0, 10)

      const [year, month, day] = dateFormatted.split('-')
      return `${ day }/${ month }/${ year }`
      */
    },
    backToList() {
      return this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>

.labelCalendario{
  vertical-align: text-bottom;
  line-height: 20px;
  font-size: 18px;
  top: -12px;
  left: -10px !important;
  width: 450px;
  padding: 10px 15px;
}
.labelCalendario2{
  vertical-align: text-bottom;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
  top: -12px;
  left: -10px !important;
  width: 70px;
  padding: 10px 15px;
}
.labelCalendario3{
  vertical-align: text-bottom;
  text-align: center;
  background: #FFFFFF;
  border: 1px solid #CDCBCB;
  border-radius: 10px;
  box-sizing: border-box;
  height: 35px;
  max-width: 240px;
  width: 150px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 18px;
  color: #AFAEAE;
}

.details__wrapper {
  background-color: #fdfdfd;
  border-radius: 20px;
  margin-top: 25px;
  margin-bottom: 40px;
  padding: 40px 60px;
  width: 100%;
  .feesTitle {
    width: auto;
    height: 23px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    color: #0e5671;
    margin-top: 2%;
  }
  .feesSubTitle {
    width: 300px;
    height: 18px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #1b778b;
    margin: 5px 0px;
    padding-right: 25px;
    padding-bottom: 25px;
    h3 {
      margin-top: 2%;
    }
  }
  .feeContainer {
    width: 300px;
    margin-bottom: 30px;
    margin-top: 30px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    color: #1b778b;
    margin: 5px 0px;
    padding-right: 25px;
    padding-bottom: 25px;
  }
  .flex {
    display: flex;
    justify-content: flex-end;
  }
  .button-edit {
    margin-top: 30px;
    color: $primary;
    background-color: white;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.16);
  }
  .title {
    h1 {
      font-family: Mulish;
      font-weight: 900;
      font-size: 40px;
      color: $primaryDark;
      margin: 5px 0px;
    }

    .info {
      align-items: center;
      background-color: transparent !important;
      display: flex;
      margin-top: 20px;

      .badge {
        background: $primary;
        border-radius: 49px;
        color: $white;
        font-size: 15px;
        opacity: 0.8;
        margin-right: 10px;
        width: 101px;
      }

      .info__text {
        font-size: 16px;
        color: $primary;
      }
    }
  }
  .subTitle {
    height: 22px;
    width: 742px;
    left: 102px;
    top: 253px;
    border-radius: nullpx;
    color: #1b778b !important;
  }
  .data__info {
    margin-bottom: 30px;
    margin-top: 30px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $almostBlack;
      margin-bottom: 14px;
    }

    span {
      font-size: 14px;
      line-height: 18px;
      color: #686868;
    }
  }

  .data__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .data__row--calendar {
    display: flex;
    padding-bottom: 10px;
  }
  .data__row--double {
    display: flex;
    padding-bottom: 70px;
  }

  .data__row--triple {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 70px;
  }

  .periods {
    border-top: 1px solid #1b778b;

    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 120%;
      color: #0e5671;
      padding-top: 30px;
    }
  }

  .return {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding-left: 23px;
    transition: all 0.3s;

    &:hover {
      opacity: 0.8;
    }

    h3 {
      color: $primaryDark;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      margin-left: 10px;
    }
  }

  .separator {
    background-color: $primary;
    height: 1px;
    margin-top: 8px;
    margin-bottom: 15px;
    width: 100%;
  }
  .inputNew {
    width: 30%;
    background-color: white;
    color: black;
    text-align: center;
    border: 1px solid rgb(12, 7, 7);
    padding: 1%;
    border-radius: 5%;
    color: #636b6f;
  }
  .inputsController {
    display: flex;
    align-items: center;
  }
  .nameInput {
    color: black;
    font-weight: normal;
    margin-top: 5px;
  }
  .alertRequired {
    font-size: 12px;
    color: #950000;
  }
}
</style>
