<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('summaryForTheResearcher')}}</p>
        <text-warning :text="$t('considerAllFieldObligatory')" />
      </div>

      <div class="summary mb-7">
        <text-info-title text="projectData" />
        <p class="form__text mt-0 mb-7">
          {{$t('reviewProjectData')}}
        </p>

        <input-default
          label="projectTitle"
          id="projectTitle"
          :disabled="true"
          v-model="cTitle"
        />

        <label class="label mt-7">{{$t('students')}}</label>
        <label
          class="students"
          v-for="(student, indexStudents) in cStudents"
          :key="`${students}-${indexStudents}`"
          >{{ student.full_name }}</label
        >

        <label class="label mt-7">{{$t('researchAreaSingle')}}</label>
        <label class="label__item">{{ returnTheArea(cArea) }}</label>

        <label class="label mt-7">{{$t('researchSubAreas')}}</label>
        <label
          class="label__item"
          v-for="(subarea, indexSubAreas) in cSubAreas"
          :key="`${subarea}-${indexSubAreas}`"
          >{{ returnArea(subarea) }}</label
        >
      </div>

      <input-masked
        :class="[{ warning: checkErrorIfContains('startProject') }, 'input__space']"
        required
        label="researchProjectStartDate"
        id="startProject"
        placeholder="dd/mm/aaaa"
        type="text"
        v-model="form.startProject"
        @input="resetErrors"
        :mask="['##/##/####']"
      />

      <input-masked
        :class="[{ warning: checkErrorIfContains('endsProject') }, 'input__space']"
        required
        label="plannedEndDate"
        id="endsProject"
        placeholder="dd/mm/aaaa"
        type="text"
        v-model="form.endsProject"
        @input="resetErrors"
        :mask="['##/##/####']"
      />

      <radio-group
        :class="[{ warning: checkErrorIfContains('willContinued') }]"
        label="isYourProjectContinuity"
        :options="generalOptions"
        v-model="form.willContinued"
        @change="resetErrors"
      />

      <div v-if="form.willContinued">
        <text-info class="mb-2" text="continuityProjectInfo" />

        <input-masked
          :class="[{ warning: checkErrorIfContains('startCollect') }, 'input__space']"
          label="plannedDateForStartingExperimentation"
          id="startCollect"
          placeholder="dd/mm/aaaa"
          type="text"
          v-model="form.startCollect"
          @input="resetErrors"
          :mask="['##/##/####']"
        />

        <radio-group
          :class="[{ warning: checkErrorIfContains('researchInLab') }]"
          label="willYouConductInLaboratory"
          :options="generalOptions"
          v-model="form.researchInLab"
          @change="resetErrors"
        />

        <div v-if="form.researchInLab">
          <div class="separator">
            <h3>{{$t('researchInstituitionIndustry')}}</h3>
          </div>

          <input-default
            label="institutionNameSingle"
            :class="[
            { warning: checkErrorIfContains('researchInstitution-institutionName') },
            'input__space mt-5'
          ]"
            placeholder="fullNamePlaceholder"
            type="text"
            v-model="researchInstitution.institutionName"
            @input="resetErrors"
          />

          <input-default
            :class="[
            { warning: checkErrorIfContains('researchInstitution-institutionPhone') },
            'input__space'
          ]"
            label="enterInstitutionSchoolPhone"
            id="phone"
            placeholder="(00) 00000-0000"
            type="text"
            v-model="researchInstitution.institutionPhone"
            @input="resetErrors"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />

          <input-default
            :class="[
            { warning: checkErrorIfContains('researchInstitution-institutionEmail') },
            'input__space'
          ]"
            label="email"
            id="email"
            placeholder="enterTheEmail"
            type="text"
            v-model="researchInstitution.institutionEmail"
            @input="resetErrors"
          />

          <div class="levels">
            <p
              :class="[
              { warning: checkErrorIfContains('researchInstitution-researchLocal') },
              'title'
            ]"
            >
              {{$t('locationsWhereTheResearchWillBeConducted')}}
            </p>
            <InputCheckboxProject
              :dataAreas="researchLocalOptions"
              @selecteds="researchInstitution.researchLocal = $event"
              :actives="researchInstitution.researchLocal"
              :one="true"
            />
            <input-default
              v-if="
              researchInstitution.researchLocal[0] &&
                researchInstitution.researchLocal[0].value === 'other'
            "
              :class="[
              { warning: checkErrorIfContains('researchInstitution-researchLocalOther') },
              'input__space'
            ]"
              id="email"
              placeholder="Insira o local"
              type="text"
              v-model="researchInstitution.researchLocalOther"
              @input="resetErrors"
            />
          </div>

          <div class="levels">
            <p
              :class="[
              { warning: checkErrorIfContains('researchInstitution-researchItens') },
              'title mt-3'
            ]"
            >
              {{$t('itemsThatApplyToYourResearchTrials')}}
            </p>
            <InputCheckboxProject
              :dataAreas="researchItensOption"
              @selecteds="researchInstitution.researchItens = $event"
              :actives="researchInstitution.researchItens"
              :one="true"
            />
          </div>

          <div class="separator">
            <h3>{{$t('address')}}</h3>
          </div>

          <input-select-location
            :class="[
            { warning: checkErrorIfContains('researchInstitution-country') },
            'input__space mt-5'
          ]"
            id="country"
            :items="countries"
            v-model="researchInstitution.country"
            label="country"
            @input="resetErrors"
          />

          <input-masked
            v-if="researchInstitution.country == 'Brazil'"
            :class="[
            { warning: checkErrorIfContains('researchInstitution-zipCode') },
            'input__space'
          ]"
            required
            label="CEP"
            id="zipCode"
            :placeholder="$t('inputCepPlaceholder')"
            type="text"
            v-model="researchInstitution.zipCode"
            @input="resetErrors"
            :mask="['#####-###']"
          />

          <template v-if="researchInstitution.cepValidate">
            <input-default
              v-if="researchInstitution.country == 'Brazil'"
              :class="[
              { warning: checkErrorIfContains('researchInstitution-state') },
              'input__space'
            ]"
              label="state"
              id="state"
              type="text"
              v-model="researchInstitution.state"
              @input="resetErrors"
            />
            <input-default
              v-if="researchInstitution.country == 'Brazil'"
              :class="[{ warning: checkErrorIfContains('researchInstitution-city') }, 'input__space']"
              label="city"
              id="city"
              type="text"
              v-model="researchInstitution.city"
              @input="resetErrors"
            />
          </template>
          <template v-else>
            <input-select
              v-if="researchInstitution.country == 'Brazil'"
              :class="[
              { warning: checkErrorIfContains('researchInstitution-state') },
              'input__space'
            ]"
              id="class"
              :items="states"
              v-model="researchInstitution.state"
              label="state"
              @input="resetErrors"
              :selected="researchInstitution.state"
            />
            <input-select
              v-if="researchInstitution.country == 'Brazil'"
              :class="[{ warning: checkErrorIfContains('researchInstitution-city') }, 'input__space']"
              id="class"
              :items="cities"
              v-model="researchInstitution.city"
              label="city"
              @input="resetErrors"
              :selected="researchInstitution.city"
            />
          </template>
          <input-default
            v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
            :class="[
            { warning: checkErrorIfContains('researchInstitution-address') },
            'input__space'
          ]"
            label="address"
            id="address"
            placeholder="addressExample"
            type="text"
            v-model="researchInstitution.address"
            @input="resetErrors"
          />

          <input-default
            v-if="researchInstitution.country === 'Brazil'"
            :class="[
            { warning: checkErrorIfContains('researchInstitution-neighborhood') },
            'input__space'
          ]"
            label="neighborhood"
            id="neighborhood"
            placeholder="neighborhoodPlaceholder"
            type="text"
            v-model="researchInstitution.neighborhood"
            @input="resetErrors"
          />

          <input-default
            v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
            :class="[{ warning: checkErrorIfContains('researchInstitution-number') }, 'input__space']"
            label="Número"
            id="number"
            placeholder="numberPlaceholder"
            type="text"
            v-model="researchInstitution.number"
            @input="resetErrors"
          />

          <input-default
            v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
            :class="['input__space']"
            label="complementOptional"
            id="complement"
            placeholder="complementPlaceholder"
            type="text"
            v-model="researchInstitution.complement"
            @input="resetErrors"
          />

          <input-default
            v-if="researchInstitution.country && researchInstitution.country != 'Brazil'"
            :class="[{ warning: checkErrorIfContains('researchInstitution-state') }, 'input__space']"
            label="State/Province"
            id="state"
            placeholder="statePlaceholder"
            type="text"
            v-model="researchInstitution.state"
            @input="resetErrors"
          />

          <input-default
            v-if="researchInstitution.country && researchInstitution.country != 'Brazil'"
            :class="[{ warning: checkErrorIfContains('researchInstitution-city') }, 'input__space']"
            label="City"
            id="city"
            placeholder="Input your city"
            type="text"
            v-model="researchInstitution.city"
            @input="resetErrors"
          />
        </div>

        <radio-group
          :class="[{ warning: checkErrorIfContains('newArea') }]"
          label="willTheContinuityIncludeNewResearch"
          :options="generalOptions"
          v-model="form.newArea"
          @change="resetErrors"
        />

        <div v-if="form.newArea">
          <input-select
            id="areaSelect"
            :class="[{ warning: checkErrorIfContains('continuity_area') }, 'mb-4']"
            :items="areaOptions"
            v-model="form.continuity_area"
            label="researchAreaSingle"
            placeholder="Selecione a Área de pesquisa"
            @input="resetErrors"
          />

          <p
            v-if="form.continuity_area"
            :class="['black-title', { warning: checkErrorIfContains('continuity_sub_areas') }]"
          >
            {{$t('checkYourProjectNewResearchSubarea')}}
          </p>
          <InputCheckboxProject
            v-if="form.continuity_area"
            :class="['mb-7']"
            :dataAreas="areas[form.continuity_area]"
            @click="resetErrors"
            @selecteds="form.continuity_sub_areas = $event"
            :actives="form.continuity_sub_areas"
            :one="true"
          />
          <input-default
            v-if="form.continuity_sub_areas[0] && form.continuity_sub_areas[0].value === 'others'"
            :class="[{ warning: checkErrorIfContains('continuity_sub_areasOther') }, 'input__space']"
            label="Qual?"
            id="continuity_sub_areasOther"
            placeholder="Insira qual é a outra subárea"
            type="text"
            v-model="form.continuity_sub_areasOther"
            @input="resetErrors"
          />
        </div>
      </div>

      <div class="form__footer">
        <button-default
          :onClick="back"
          class="button white"
          >{{$t('back')}}</button-default
        >
        <button-default
          :large="true"
          :onClick="checkForm"
          class="button"
          >{{$t('confirmAndContinue')}}</button-default
        >
      </div>

      <modal-selection-process
        title="disableStudentInvolved"
        :onClick="() => closeModalAndYes()"
        @closeModal="() => closeModal()"
        :modal="warningModal"
      >
        <div>
          <p class="form__textModal">{{$t('disableStudentInvolvedInfo')}}</p>
        </div>
      </modal-selection-process>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
    {{ cZipCode1 }}
    {{ cZipCode2 }}
    {{ cZipCode3 }}
    {{ cZipCode4 }}
    {{ cStateSelected }}
  </div>
</template>

<script>
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo,
  TextInfoTitle,
  RadioGroup,
  InputSelectLocation,
  InputMasked,
  InputCheckboxProject,
  InputCheckboxTitle,
  InputSelect,
  InputCheckbox
} from '@/components/atoms'
import { ModalSelectionProcess } from '@/components/organisms/Modals'
import { countries, states } from '@/utils/locations'
import { areas, areaOptions } from '@/utils/areas'

export default {
  name: 'FormProjectHighResearcherSummary',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo,
    TextInfoTitle,
    RadioGroup,
    InputSelectLocation,
    InputMasked,
    InputCheckboxProject,
    InputCheckboxTitle,
    InputSelect,
    InputCheckbox,
    ModalSelectionProcess
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  data() {
    return {
      warningModal: false,
      indexToDelete: false,
      projectTitle: this.allDataForm.FormProjectHighData.projectTitle || '',
      students: this.allDataForm.FormProjectHighStudents.infos || [],
      areaResearch: this.allDataForm.FormProjectHighData.area || '',
      subareasResearch: this.allDataForm.FormProjectHighData.sub_areas || [],
      researchArea: '',
      form: {
        startProject: '',
        endsProject: '',
        willContinued: null,
        startCollect: '',
        researchInLab: '',
        newArea: '',
        continuity_area: '',
        continuity_sub_areas: [],
        continuity_sub_areasOther: ''
      },
      researchInstitution: {
        institutionName: '',
        institutionPhone: '',
        institutionEmail: '',
        researchLocal: [],
        researchLocalOther: '',
        researchItens: [],
        cepValidate: false,
        country: '',
        zipCode: '',
        state: '',
        city: '',
        neighborhood: '',
        address: '',
        number: '',
        complement: ''
      },
      serieOptions: [
        { text: '1ª', value: '1' },
        { text: '2ª', value: '2' },
        { text: '3ª', value: '3' },
        { text: this.$t('internship'), value: 'estagio' }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ],
      quantityOptions: [
        { label: this.$t('individual'), value: 1 },
        { label: this.$t('pair'), value: 2 },
        { label: this.$t('trio'), value: 3 }
      ],
      genderOptions: [
        { label: this.$t('maleSingle'), value: 'male' },
        { label: this.$t('femaleSingle'), value: 'female' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: this.$t('advanced') },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' },
        { text: 'P', value: 'p' },
        { text: 'M', value: 'm' },
        { text: 'G', value: 'g' },
        { text: 'GG', value: 'gg' }
      ],
      researchLocalOptions: [
        { text: this.$t('field'), value: 'field' },
        { text: this.$t('atHome'), value: 'inHome' },
        { text: this.$t('school'), value: 'school' },
        { text: this.$t('other'), value: 'other' }
      ],
      researchItensOption: [
        { text: 'Seres Humanos', value: 'humanBeings' },
        { text: 'Animais Vertebrados', value: 'vertebrateAnimals' },
        {
          text: 'Agentes Biológicos Potencialmente Perigosos',
          value: 'potentiallyHazardousBiologicalAgents'
        },
        {
          text: 'Substâncias Químicas, Atividades ou Equipamentos Perigosos',
          value: 'hazardousChemicalSubstancesActivitiesEquipment'
        },
        { text: 'Substâncias Controladas por Lei', value: 'substancesControlledLaw' },
        { text: 'Nenhuma', value: 'none' }
      ],
      areas,
      areaOptions,
      errors: [],
      cities: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      countries,
      states,
      isBetter: false
    }
  },
  mounted() {
    if (this.allDataForm.FormProjectHighResearcherSummary) {
      this.form = { ...this.form, ...this.allDataForm.FormProjectHighResearcherSummary.form }
      this.researchInstitution = {
        ...this.researchInstitution,
        ...this.allDataForm.FormProjectHighResearcherSummary.researchInstitution
      }
    }
  },
  computed: {
    cTitle() {
      return this.allDataForm.FormProjectHighData.projectTitle
    },
    cStudents() {
      return this.allDataForm.FormProjectHighStudents.infos
    },
    cArea() {
      return this.allDataForm.FormProjectHighData.area
    },
    cSubAreas() {
      return this.allDataForm.FormProjectHighData ? this.allDataForm.FormProjectHighData.sub_areas.filter((item) => item !== 'others') : []
    }
  },
  watch: {
    'researchInstitution.zipCode': function (newVal, oldVal) {
      const cep = String(this.researchInstitution.zipCode).replace(/\D/g, '')
      if (cep.length == 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              this.researchInstitution.cepValidate = false
              this.errors.push(`zipCode${ index }`)
              this.states = states
              this.textWarning = 'checkTheCep'
            } else {
              this.researchInstitution.state = data.uf
              this.researchInstitution.city = data.localidade
              this.researchInstitution.address = data.logradouro
              this.researchInstitution.neighborhood = data.bairro
              this.researchInstitution.cepValidate = true
            }
          })
          .catch((err) => {
            this.researchInstitution.cepValidate = false
            this.errors.push('zipCode')
            this.textWarning = 'checkTheCep'
          })
      } else if (cep.length < 8) {
        this.researchInstitution.cepValidate = false
      }
    },
    'researchInstitution.state': function () {
      this.executeGetCities(this.researchInstitution.state)
    },
    'researchInstitution.researchLocal': function () {
      if (
        this.researchInstitution.researchLocal.length
        && this.researchInstitution.researchLocal[0].value !== 'other'
      ) {
        this.researchInstitution.researchLocalOther = ''
      }
    },
    allDataForm() {
      if (this.allDataForm.FormProjectHighResearcherSummary) {
        this.form = { ...this.form, ...this.allDataForm.FormProjectHighResearcherSummary.form }
        this.researchInstitution = {
          ...this.researchInstitution,
          ...this.allDataForm.FormProjectHighResearcherSummary.researchInstitution
        }
      }
    },
    zipCodeAdvisor() {
      this.getAddressInfo(0)
    },
    zipCodeCoAdvisor() {
      this.getAddressInfo(1)
    }
  },
  methods: {
    returnArea(area) {
      if (area) {
        const subarea = this.areas[`${ this.cArea }`].find((item) => item.value === area)
        if (subarea) return subarea.text
        return (`Outros: ${ area }`)
      }
      return ''
    },
    returnTheArea(area) {
      if (area) {
        return this.areaOptions.find((item) => item.value === area).text
      }
      return ''
    },
    executeGetCities(state) {
      this.cities = []
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    async checkForm() {
      await this.resetErrors()
      const emails = []
      if (this.form.willContinued) {
        for (const key in this.form) {
          if (!this.form[key] && typeof this.form[key] !== 'boolean') {
            this.errors.push(key)
          }
        }
      }
      if (!this.form.continuity_sub_areas.length) {
        this.errors.push('continuity_sub_areas')
      } else if (this.form.continuity_sub_areas[0].value !== 'others') {
        const indexContinuityArea = this.errors.indexOf('continuity_sub_areasOther')
        if (indexContinuityArea > -1) {
          this.errors.splice(indexContinuityArea, 1)
        }
      }
      if (!this.form.newArea) {
        const indexContinuity_area = this.errors.indexOf('continuity_area')
        if (indexContinuity_area > -1) {
          this.errors.splice(indexContinuity_area, 1)
        }
        const indexContinuitySubAreasOther = this.errors.indexOf('continuity_sub_areasOther')
        if (indexContinuitySubAreasOther > -1) {
          this.errors.splice(indexContinuitySubAreasOther, 1)
        }
        const indexContinuitySubAreas = this.errors.indexOf('continuity_sub_areas')
        if (indexContinuitySubAreas > -1) {
          this.errors.splice(indexContinuitySubAreas, 1)
        }
      }
      if (!this.form.newArea) {
        const indexContinuity_area = this.errors.indexOf('continuity_area')
        if (indexContinuity_area > -1) {
          this.errors.splice(indexContinuity_area, 1)
        }
        const indexContinuitySubAreasOther = this.errors.indexOf('continuity_sub_areasOther')
        if (indexContinuitySubAreasOther > -1) {
          this.errors.splice(indexContinuitySubAreasOther, 1)
        }
        const indexContinuitySubAreas = this.errors.indexOf('continuity_sub_areas')
        if (indexContinuitySubAreas > -1) {
          this.errors.splice(indexContinuitySubAreas, 1)
        }
      }
      if (this.form.researchInLab) {
        for (const key in this.researchInstitution) {
          if (!this.researchInstitution[key] && key !== 'complement') {
            this.errors.push(`researchInstitution-${ key }`)
          }
        }

        if (this.researchInstitution.country !== 'Brazil') {
          const indexCepValidate = this.errors.indexOf('researchInstitution-cepValidate')
          if (indexCepValidate > -1) {
            this.errors.splice(indexCepValidate, 1)
          }
          const indexZipcode = this.errors.indexOf('researchInstitution-zipCode')
          if (indexZipcode > -1) {
            this.errors.splice(indexZipcode, 1)
          }
          const indexAddress = this.errors.indexOf('researchInstitution-address')
          if (indexAddress > -1) {
            this.errors.splice(indexAddress, 1)
          }
          const indexNeighborhood = this.errors.indexOf('researchInstitution-neighborhood')
          if (indexNeighborhood > -1) {
            this.errors.splice(indexNeighborhood, 1)
          }
          const indexNumber = this.errors.indexOf('researchInstitution-number')
          if (indexNumber > -1) {
            this.errors.splice(indexNumber, 1)
          }
          const indexComplement = this.errors.indexOf('researchInstitution-complement')
          if (indexComplement > -1) {
            this.errors.splice(indexComplement, 1)
          }
        }
        const indexOther = this.errors.indexOf('researchInstitution-researchLocalOther')
        if (indexOther > -1) {
          this.errors.splice(indexOther, 1)
        }
      }
      if (this.form.willContinued === null) {
        this.errors.push('willContinued')
        this.textWarning = 'Preencher campo de continuidade'
      }
      if (!this.form.startProject) {
        this.errors.push('startProject')
        this.textWarning = 'Data de início inválida'
      }
      if (!this.form.endsProject) {
        this.errors.push('endsProject')
        this.textWarning = 'Data de término inválida'
      }
      if (!this.errors.length) {
        if (this.researchInstitution.institutionEmail) {
          const onlyEmail = (this.researchInstitution.institutionEmail).replace(/ /g, '')
          this.researchInstitution.institutionEmail = onlyEmail
          if (!this.validateEmail(this.researchInstitution.institutionEmail)) {
            this.textWarning = 'E-mail inválido!'
          }
        }
        if (this.errors.length) {
          return false
        }

        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'E-mails inseridos devem ser distintos!'
          return false
        }

        this.resetErrors()
        await this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      this.allDataForm.FormProjectHighResearcherSummary = {
        form: this.form,
        researchInstitution: this.researchInstitution
      }
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
        return response
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.$emit('changeElement', {
          direction: 'up',
          FormProjectHighResearcherSummary: {
            form: this.form,
            researchInstitution: this.researchInstitution
          }
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
        console.log('error: ', error)
      }
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmail(mail) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 610px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
    max-width: 742px;
  }

  .button__add {
    align-self: flex-start;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .buttonText__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    .icon-plus {
      margin-left: 5px;
    }
  }

  .person__checkbox_delete {
    display: flex;
    justify-content: space-between;

    .checkbox__person {
      flex: 1;
    }
  }

  .icon-delete {
    max-width: 25px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__textModal {
    color: #18516f;
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    max-width: 425px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .summary {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin: 10px 10px;
    padding: 30px;
    max-width: 90%;
  }

  .label {
    color: $almostBlack;
    display: flex;
    font-weight: 600;
    text-align: left;

    &__item {
      background: #319cb4;
      border-radius: 49px;
      color: #ffffff;
      font-size: 14px;
      margin: 0px 10px 10px 0px;
      padding: 5px 10px;
    }
  }

  .students {
    display: block;
    border-left: 2px solid #1b778b;
    color: #303030;
    padding-left: 5px;
  }

  .input__space {
    margin-bottom: 30px;
  }

  .separator {
    border-bottom: 1px solid $primary;
    padding-bottom: 15px;
    margin-top: 30px;

    color: $primaryDark;
    font-family: Mulish;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
  }

  .levels {
    display: flex;
    flex-direction: column;

    .title {
      color: #303030;
      display: flex;
      flex-direction: column;
      font-size: 16px !important;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }

    label {
      display: flex;
      color: $almostBlack;
      font-size: 16px;
      margin-bottom: 5px;

      input {
        zoom: 1.5;
        margin-right: 5px;
      }
    }

    .warning {
      color: #ff9800 !important;
    }
  }
}
.warning {
  color: #ff9800 !important;
}
.black-title {
  font-size: 16px;
  font-weight: 600;
  color: #303030;
}
</style>
