<template>
  <div>
    <div class="inner__wrapper">
      <div class="infos">
        <template v-if="fair_participants[0]">
        <div class="info__item">
          <div class="title">
            <span>{{$t('childEducationCompleted')}}</span>
          </div>
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('projectsNumber')}}</h4>
                <span>{{fair_participants[0].projects_quantity}}</span>
              </div>
              <div class="data__right">
                <h4>{{$t('schoolsNumber')}}</h4>
                <span>{{fair_participants[0].schools_quantity}}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('teachersNumber')}}</h4>
                <span>{{fair_participants[0].teachers_quantity}}</span>
              </div>
            </div>
            <h3>{{$t('totalStudents')}}</h3>
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('male')}}</h4>
                <span>{{fair_participants[0].male_students}}</span>
              </div>
              <div class="data__right">
                <h4>{{$t('female')}}</h4>
                <span>{{fair_participants[0].female_students}}</span>
              </div>
            </div>
          </div>
        </div>
        </template>

        <template v-if="fair_participants[1]">
          <div class="info__item">
            <div class="title">
              <span>{{$t('elementarySchoolCompleted')}}</span>
            </div>
            <div class="data__wrapper">
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('howManyProjectsAbout')}}</h4>
                  <span>{{fair_participants[1].projects_quantity}}</span>
                </div>
                <div class="data__right">
                  <h4>{{$t('schoolsNumber')}}</h4>
                  <span>{{fair_participants[1].schools_quantity}}</span>
                </div>
              </div>
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('teachersNumber')}}</h4>
                  <span>{{fair_participants[1].teachers_quantity}}</span>
                </div>
                <div class="data__right">
                  <h4>{{$t('projectsWithOneStudent')}}</h4>
                  <span>{{fair_participants[1].one_participants}}</span>
                </div>
              </div>
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('projectsWithTwoOrThreeStudent')}}</h4>
                  <span>{{fair_participants[1].more_participants}}</span>
                </div>
              </div>
              <h3>{{$t('totalStudents')}}</h3>
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('male')}}</h4>
                  <span>{{fair_participants[1].male_students}}</span>
                </div>
                <div class="data__right">
                  <h4>{{$t('female')}}</h4>
                  <span>{{fair_participants[1].female_students}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="fair_participants[2]">
          <div class="info__item">
            <div class="title">
              <span>{{$t('highSchoolAndOrTechnical')}}</span>
            </div>
            <div class="data__wrapper">
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('howManyProjectsAbout')}}</h4>
                  <span>{{fair_participants[2].projects_quantity}}</span>
                </div>
                <div class="data__right">
                  <h4>{{$t('schoolsNumber')}}</h4>
                  <span>{{fair_participants[2].schools_quantity}}</span>
                </div>
              </div>
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('teachersNumber')}}</h4>
                  <span>{{fair_participants[2].teachers_quantity}}</span>
                </div>
                <div class="data__right">
                   <h4>{{$t('projectsWithOneStudent')}}</h4>
                  <span>{{fair_participants[2].one_participants}}</span>
                </div>
              </div>
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('projectsWithTwoOrThreeStudent')}}</h4>
                  <span>{{fair_participants[2].more_participants}}</span>
                </div>
              </div>
              <h3>{{$t('totalStudents')}}</h3>
              <div class="data__row">
                <div class="data__left">
                  <h4>{{$t('male')}}</h4>
                  <span>{{fair_participants[2].male_students}}</span>
                </div>
                <div class="data__right">
                  <h4>{{$t('female')}}</h4>
                  <span>{{fair_participants[2].female_students}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="info__item">
          <div class="title">
            <span>{{$t('generalInformation')}}</span>
          </div>
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('schoolNumberThatParticipatedOnFair')}}</h4>
                <span>{{fairInfo.about_fairs.total_participants_fair}}</span>
              </div>
              <div class="data__right">
                <h4>{{$t('didOthersAffiliatedFairsParticipateAbout')}}</h4>
                <span v-if="fairInfo.about_fairs.other_participants_fairs">{{$t('yes')}}</span>
                <span v-else>{{$t('no')}}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__right">
                <h4>{{$t('publicSchoolNumber')}}</h4>
                <span>{{fairInfo.about_fairs.total_public_school}}</span>
              </div>
              <div class="data__left">
                <h4>{{$t('privateSchoolNumber')}}</h4>
                <span>{{fairInfo.about_fairs.total_private_school}}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('fairAffiliatesNumber')}}</h4>
                <span>{{fairInfo.about_fairs.number_fairs_affiliate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AboutFair',
  props: {
    fairInfo: Object
  },
  data() {
    return {
      fair_participants: [0, 0, 0]
    }
  },
  created() {
    if (this.fairInfo.fair_participants) {
      this.fairInfo.fair_participants.forEach((item) => {
        if (item.level == 'participants_grade') {
          this.fair_participants[0] = item
        }
        if (item.level == 'participants_middle') {
          this.fair_participants[1] = item
        }
        if (item.level == 'participants_high') {
          this.fair_participants[2] = item
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.about {
  margin-top: 16px;
}
.inner__wrapper {
  background: #FDFDFD;
  border-radius: 10px;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 0px 22px 25px;

    .info__item {
      margin-top: 40px;

      .title {
        align-items: center;
        border-bottom: 0.5px solid #AFAEAE;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        padding-bottom: 5px;

        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: $almostBlack;
        }
      }

      .data__wrapper {

        h3 {
          border-bottom: 1px solid #3E778D;
          color: $primary;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
          margin-left: 8%;
          margin-right: 8%;
          padding-bottom: 10px;
        }

        .data__row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 30px;
          padding-left: 8%;

          div {
            align-items: center;
            display: flex;
          }

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: #686868;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
