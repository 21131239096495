<template>
  <div>
    <div class="inner__wrapper">
      <div v-if="status==='em analise'">
        <div class="header">
          <h3>Gerenciar solicitação de afiliação</h3>
        </div>
        <div class="infos">
          <p>Ao aceitar essa solicitação, será possível gerenciar o número de vagas disponibilizadas para a feira afiliada. Caso a solicitação seja negada, será necessário justificar.</p>
          <h5>Aceitar solicitação de afiliação?</h5>
          <input-radio-group id="accept" v-model="accept" :options="radio_values" />
          <div v-if="accept === false">
            <div class="header">
              <h3>Justifique a reprovação da solicitação</h3>
            </div>
            <div class="infos">
              <p>Justifique a reprovação da solicitação. O novo status e justificativa serão disponibilizada para a feira afiliada na plataforma Mostratec apenas após clicar em “enviar e-mail”.</p>
              <div class="textarea">
                <textarea v-model="message" placeholder="Justifique a reprovação da solicitação:"></textarea>
              </div>
            </div>
          </div>
          <button-default
            v-if="(this.accept !== null) && (vacanciesRequestFilled === true) && (this.status === 'em analise')|| (this.accept === false) && (this.status === 'em analise')"
            :onClick="save"
            class="button"
          >{{$t('save')}}</button-default>
        </div>
      </div>
      <feedback-warning
        v-if="displayWarning"
        :text="textWarning"
        :onClick="resetWarning"
        color="#ff9800"
      />
      <div v-if="status==='aguardando resposta' && accept===true">
        <div class="header">
          <h3>Aguardando o envio de e-mail</h3>
        </div>
        <div class="infos">
          <p>Solicitação aprovada. Quando desejar notificar a feira afiliada sobre seus status, basta clicar em “enviar e-mail” e um e-mail orientará a feira a acessar a plataforma Mostratec e verificar seu novo status.</p>
          <a @click="revalidateSolicitation">Reavaliar solicitação</a>
          <button-default :onClick="() => toggleModal('sendEmailModal')" class="button">Enviar email</button-default>
        </div>
      </div>
      <div v-if="status==='aguardando resposta' && accept===false">
        <div class="header">
          <h3>Aguardando o envio de e-mail</h3>
        </div>
        <div class="infos">
          <p>Solicitação reprovada. Quando desejar notificar a feira afiliada sobre seus status, basta clicar em “enviar e-mail” e um e-mail orientará a feira a acessar a plataforma Mostratec e verificar seu novo status.</p>
          <a @click="revalidateSolicitation">Reavaliar solicitação</a>
          <button-default :onClick="() => toggleModal('sendEmailModal')" class="button">Enviar email</button-default>
        </div>
      </div>
      <div v-if="status==='resposta enviada' && accept===true">
        <div class="header">
          <h3>Resposta enviada por email</h3>
        </div>
        <div class="infos">
          <p>Solicitação aprovada. O email notificando a feira afiliada sobre seus status já foi enviado. Agora a feira poderá acessar a plataforma Mostratec, verificar seu novo status e gerenciar inscrições.</p>
        </div>
      </div>
      <div v-if="status==='resposta enviada' && accept===false">
        <div class="header">
          <h3>Resposta enviada por email</h3>
        </div>
        <div class="infos">
          <p>Solicitação reprovada. O email notificando a feira afiliada sobre seus status já foi enviado. Agora a feira poderá acessar a plataforma Mostratec, verificar seu novo status e gerenciar inscrições.</p>
        </div>
      </div>
    </div>
    <div
      v-if="(this.status === 'resposta enviada' || this.status === 'aguardando resposta') && (this.accept !== true)"
      class="inner__wrapper"
    >
      <div class="header">
        <h3>{{$t('JustificationLabel')}}</h3>
      </div>
      <div class="infos">
        <p>{{this.message}}</p>
      </div>
    </div>
    <modal-default title="E-mail enviado!" @closeModal="() => sendEmail()" :modal="sendEmailModal">
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/email-send.png" alt="E-mail enviado a MostraTec" />
        </div>
        <div class="text">
          <p
            slot="text"
          >O(s) e-mail(s) de retorno a(s) feira(s) afiliada(s) foi enviado com sucesso!</p>
          <p slot="text">O status das solicitações será atualizado de acordo análise realizada.</p>
        </div>
      </div>
    </modal-default>
  </div>
</template>

<script>
import {
  InputRadioGroup,
  ButtonDefault,
  TextWarning,
  FeedbackWarning
} from '@/components/atoms'
import { ModalSendForm, ModalDefault } from '@/components/organisms/Modals'

export default {
  name: 'ManagerSolicitation',
  props: [
    'vacanciesRequestFilled',
    'fairInfo',
    'vacanciesRequestNumber',
    'errors'
  ],
  components: {
    InputRadioGroup,
    ButtonDefault,
    ModalSendForm,
    TextWarning,
    FeedbackWarning,
    ModalDefault
  },
  data: () => ({
    displayWarning: false,
    sendEmailModal: false,
    accept: null,
    status: null,
    message: '',
    radio_values: [
      { value: true, text: 'Sim' },
      { value: false, text: 'Não' }
    ]
  }),
  watch: {
    fairInfo() {
      this.message = this.fairInfo.justification
        ? this.fairInfo.justification
        : ''

      this.accept = this.fairInfo.status_is_active
      this.status = this.fairInfo.status
    },
    accept(val) {
      this.$store.commit('FAIR_AFFILIATION_ANALYSIS_ACCEPT', val)
    }
  },
  methods: {
    toggleModal(type) {
      this[type] = !this[type]
    },
    resetWarning() {
      if (this.displayWarning) this.displayWarning = false
    },
    async save() {
      this.$emit('errors', 'resetErrors')
      if (this.accept !== null) {
        let newData = {}
        const existingErrors = [false, false]
        const errorContext = {
          nullCamp: 0,
          invalidCamp: 1
        }

        if (this.message.length) {
          newData = {
            justification: this.message,
            vacancies_request: this.vacanciesRequestNumber
          }
        } else {
          newData = {
            vacancies_request: this.vacanciesRequestNumber
          }
        }

        for (const currentCamp of newData.vacancies_request) {
          if (typeof (currentCamp.quantity_granted) !== 'number' || typeof (currentCamp.quantity_exempt) !== 'number') {
            if (currentCamp.quantity_exempt === '') {
              currentCamp.quantity_exempt = 0
            }
            if (currentCamp.quantity_registered === 0) {
              currentCamp.quantity_granted = 0
            } else {
              existingErrors[errorContext.nullCamp] = true
              this.$emit('errors', `${ currentCamp.level } - granted`)
            }
          }
          if (currentCamp.quantity_granted < 0 || currentCamp.quantity_exempt < 0) {
            if (currentCamp.quantity_exempt < 0) {
              currentCamp.quantity_exempt = 0
            } else {
              existingErrors[errorContext.invalidCamp] = true
              this.$emit('errors', `${ currentCamp.level } - granted`)
            }
          }
        }

        if (existingErrors[errorContext.nullCamp]) {
          this.displayWarning = true
          this.textWarning = 'VacanciesGrantedMustBeProvided'
          return false
        }
        if (existingErrors[errorContext.invalidCamp]) {
          this.displayWarning = true
          this.textWarning = 'VacanciesGrantedValuesNotValid'
          return false
        }
        const id = localStorage.getItem('affiliationRequestId')
        await this.$http.put(`/fair/send-mail/${ id }`, newData)
        this.changeStatus('aguardando resposta')
      }
    },
    async sendEmail() {
      const id = localStorage.getItem('affiliationRequestId')
      let newData = {}

      if (this.message.length) {
        newData = {
          send_mail: true,
          justification: this.message
        }
      } else {
        newData = {
          send_mail: true
        }
      }
      await this.$http.put(`/fair/send-mail/${ id }`, newData)
      this.changeStatus('resposta enviada')
      this.toggleModal('sendEmailModal')
    },
    async revalidateSolicitation() {
      const newData = {
        vacancies_request: [
          {
            level: 'grade',
            quantity_granted: null,
            quantity_exempt: 0
          },
          {
            level: 'middle',
            quantity_granted: null,
            quantity_exempt: 0
          },
          {
            level: 'high',
            quantity_granted: null,
            quantity_exempt: 0
          }
        ]
      }
      const id = localStorage.getItem('affiliationRequestId')
      await this.$http.put(`/fair/send-mail/${ id }`, newData)
      this.accept = null
      this.$store.commit('FAIR_AFFILIATION_ANALYSIS_ACCEPT', null)

      this.changeStatus('pendente de analise')
    },
    async changeStatus(newStatus) {
      this.status = newStatus
      const id = localStorage.getItem('affiliationRequestId')
      await this.$http.patch(`/fair/status-affiliate/${ id }`, {
        status: this.status,
        status_is_active: this.accept
      })
      this.$emit('reloadInfos')
    }
  }
}
</script>

<style lang="scss" scoped>
.text {
  color: #18516f;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  max-width: 90%;
  text-align: left;
  margin-top: 25px;
}
.inner__wrapper {
  background: #fdfdfd;
  border-radius: 10px;
  margin-bottom: 25px;

  .header {
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 20px;

    p {
      color: $almostBlack;
      font-size: 16px;
      line-height: 20px;
    }

    h5 {
      color: $almostBlack;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .textarea {
      margin-top: 5px;
      margin-bottom: 10px;
      width: 100%;
      textarea {
        border: 1px solid #686868;
        box-sizing: border-box;
        border-radius: 10px;
        height: 200px;
        padding: 10px;
        resize: none;
        width: 100%;

        &::placeholder {
          color: #afaeae;
        }
      }
    }

    a {
      font-size: 16px;
      text-decoration-line: underline;
      color: #686868;
    }
  }

  .button {
    align-self: flex-end;
    margin-top: 15px;
  }
}

.modal__body {
  @include d-flex-center;
  margin-top: 30px;
  justify-content: flex-start;
  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $primaryDark;
    font-size: 16px;
    font-weight: 600;
    line-height: 20, 08px;
    margin-left: 7%;
    text-align: left;
    max-width: 300px;
  }
}
</style>
