<template>
  <div class="info-wrapper">
    <heading-three class="heading">
      {{title}}
    </heading-three>
    <span class="content"> {{ content }}</span>
  </div>
</template>

<script>
import { HeadingThree } from '@/components/atoms/Texts/HeadingThree'

export default {
  components: { HeadingThree },
  name: 'DateInformation',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  width: fit-content;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: #686868;
}

.content {
    font-family: Mulish, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: 0.03em;
    color: #686868;
}

.info-wrapper{
  display: flex;
  width: 60%;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 4px;
}

</style>
