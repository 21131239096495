<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t("fair")}}</p>
        <text-info
          text=confirmWhichOfOurAffiliatedFairsIsYou
        />
        <text-warning :text="'considerAllFieldObligatory'" />
      </div>
      <input-auto-complete
        :class="[{warning: checkErrorIfContains('fair_name')}, 'mb-7']"
        label=fairName
        id="name"
        :placeholder="$t('fairName')"
        type="text"
        :items="fairs_name"
        v-model="form.fair_name"
        @input="resetErrors"
        :disabled="!fairs.length"
        :selected="form.fair_name"
      />
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndAdvance')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputAutoComplete,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo
} from '@/components/atoms'

export default {
  name: 'FormFair',
  components: {
    InputAutoComplete,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo
  },
  data() {
    return {
      form: {
        fair_name: this.allDataForm.FormFair.fair_name || '',
        id: ''
      },
      fairs: [],
      fairs_name: [],
      errors: [],
      textWarning: 'Selecione uma feira',
      type: this.mostratec_type
    }
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  mounted() {
    this.getFairs()
  },
  watch: {
    mostratec_type() {
      this.getFairs()
    },
    allDataForm() {
      if (this.allDataForm.FormFair) {
        this.form = this.allDataForm.FormFair
      }
    }
  },
  methods: {
    async getFairs() {
      try {
        //console.log('this.allDataForm',this.allDataForm)
        const levelq = this.allDataForm.FormLevelOfEducation.level
        const { data: response } = await this.$http.get(
          //`/all-fair-active/?type=${ this.mostratec_type }`
          `/all-fair-active-by-cat/?level=${ levelq }`
        )
        this.fairs = response.data
        //console.log('getFairs()',this.fairs)
        if (!this.fairs.length) {
          this.errors.push(1)
          this.textWarning = 'Não há feiras para o seu nível de ensino'
        }
        this.fairs_name = this.fairs.map((item) => item.fair_name)
        //console.log('this.fairs_name', this.fairs_name)
        //this.fairs_name = this.fairs.map((item) => item.fair_name + ' - ' + item.city + '/' + item.state)
      } catch (error) {
        console.error(error)
      }
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async checkForm() {
      //console.log('this.form', this.form)
      //console.log('this.form.fair_name', this.form.fair_name)
      //console.log('this.fairs', this.fairs)
      //console.log('this.form.id ', this.form.id)
      /*
      this.form.id = this.fairs.find(
        (item) => item.id === this.form.id
      ).id
      */
      this.form.id = this.fairs.find(
        (item) => item.fair_name === this.form.fair_name
        //(item) => (item.fair_name + ' - ' + item.city + '/' + item.state) === this.form.fair_name
      ).id
      //this.form.fair_name = this.fairs.find(
      //  //(item) => item.fair_name === this.form.fair_name
      //  (item) => (item.fair_name + ' - ' + item.city + '/' + item.state) === this.form.fair_name
      //).fair_name


      this.resetErrors()
      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
        }
      }
      if (!this.errors.length) {
        if (!this.form.fair_name) {
          return false
        }
        await this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      this.allDataForm.FormFair = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.$emit('changeElement', { direction: 'up', FormFair: this.form })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
        return false
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    back() {
      this.$emit('changeElement', { direction: 'down', FormFair: this.form })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
</style>
