<template>
  <aside class="inner__wrapper">
    <div class="header">
      <h3>{{$t('projectData')}}</h3>
    </div>

    <main class="form__main mt-4 main_align">
      <div class="infos">
        <p class=" grey_separator">Contrato de convivência</p>
        <div class="generalData">
          <div class="mb-4">
            <div class="abstract">
              <h1 class="abstract__title">Termo</h1>
              <p class="abstract__content">
                {{ $t("coexistenceContractInfo") }}
              </p>
              <!-- p class="abstract__content">
                Estou ciente dos dados informados no formulário de inscrição, sendo que os mesmos estão de acordo com a feira realizada.
              </p>
              <p class="abstract__content">
                Assumo o compromisso de enviar o arquivo contrato de convivência para Mostratec Júnior, seja virtual ou presencial.
              </p -->
            </div>
          </div>

          <div class="data">
            <div class="data__left">
              <div class="data__row">
                <h4>Consentimento</h4>
                <span>Aceito</span>
              </div>
            </div>
            <div class="data__right">
              <div class="data__row">
                <h4>Formato do contrato</h4>
                <span>{{form.contract_format}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="revision_box">
          <span class="revision_title">{{$t('revisionFiles')}}</span>
          <p class="revision_text">{{$t('useCheckbox')}}</p> 
        </div>
        <div class="grid_container">
          <div v-for="(file, index) in files" :key="index" class="revision__content_left">
            <div
              v-if="requiredFiles.includes(index)"
              @mouseenter="enterModel(index)"
              @mouseleave="leaveModel(index)"
              class="files"
            >
              <div class="icon_wrapper" @click="downloadFile(file)">
                <icon-default
                  :url="getFileIcon(index)" alt="Ícone de pdf"
                />
              </div>
              <input
                type="checkbox"
                name="step"
                class="box_info__input"
                id="step"
                @click="toggleModel(index)"
                :disabled="!file.url"
              >
              <div class="files__name">
                <label for="step" class="box_info__label">{{getFileName(index)}}</label>
                <label  v-if="index === 'banner' && file.url" class="files__extension">{{file.url}}</label>
                <label  v-else class="files__extension">{{file.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </aside>
</template>

<script>
import { IconDefault } from '@/components/atoms'

export default {
  name: 'ProjectsContract',
  components: { IconDefault },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        contract_format: ''
      },
      levelOptions: {
        high: 'Ensino Médio/Técnico',
        middle: 'Ensino Fundamental',
        grade: 'Ensino Infantil'
      },
      level: '',
      //requiredFiles: ['pdf', 'banner', 'video', 'presentation_link'],
      requiredFiles: ['pdf', 'banner', 'presentation_link'],
      files: {},
      icons: { pdf: 'pdf-icon.png', banner: 'pdf-icon.png', presentation_link: 'play.svg' },
      revision: {
        pdf: false, banner: false, presentation_link: false, video: false
      },
      hover: {
        pdf: false, banner: false, presentation_link: false, video: false
      }

    }
  },
  computed: {
    cPdfIcon() {
      if (this.hover.pdf && this.revision.pdf != true) {
        return this.hover.pdf ? 'download.svg' : 'grey_pdf.png'
      } return this.revision.pdf ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cBannerIcon() {
      if (this.hover.banner && this.revision.banner != true) {
        return this.hover.banner ? 'download.svg' : 'grey_pdf.png'
      } return this.revision.banner ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cVideoIcon() {
      if (this.hover.video && this.revision.video != true) {
        return this.hover.video ? 'download.svg' : 'grey_mp4.png'
      } return this.revision.video ? 'mp4-icon.png' : 'grey_mp4.png'
    },
    cPresentationLinkIcon() {
      if (this.hover.presentation_link && this.revision.presentation_link != true) {
        return this.hover.presentation_link ? 'play_blue.svg' : 'play_gray.svg'
      } return this.revision.presentation_link ? 'play.svg' : 'play_gray.svg'
    }
  },
  mounted() {
    if (Object.keys(this.projectInfo).length) this.loadInfos()
  },
  methods: {
    downloadFile(file) {
      if (this.projectInfo.files && file.url) {
        window.open(file.url)
      }
    },
    loadInfos() {
      this.form.contract_format = this.projectInfo.coexistence_contract === 'presencial' ? 'Presencial' : 'Virtual'
      this.files = this.projectInfo.files
      for (const file in this.files) {
        console.log('videos ',this.files[file].name)
        if (!(this.files[file].name || this.files[file].url)) this.files[file].name = 'Não anexado.'
      }
      if (this.projectInfo.presentation_link?.length) {
        this.files.presentation_link = {
          name: this.projectInfo.presentation_link,
          url: this.projectInfo.presentation_link
        }
      }
      this.level = this.levelOptions[this.projectInfo.level]
      return false
    },
    toggleModel(model) {
      this.revision[model] = !this.revision[model]
    },
    enterModel(model) {
      this.hover[model] = true
    },
    leaveModel(model) {
      this.hover[model] = false
    },
    getFileName(index) {
      const retornos = {
        pdf: 'Contrato de convivência',
        banner: 'Banner',
        video: 'Video',
        presentation_link: this.$t('presentationLink')
      }
      return retornos[index]
    },
    getFileIcon(index) {
      const retornos = {
        pdf: this.files[index].url ? this.cPdfIcon : 'grey_mp4.png',
        banner: this.files[index].url ? this.cBannerIcon : 'grey_pdf.png',
        video: this.files[index].url ? this.cVideoIcon : 'grey_pdf.png',
        presentation_link: this.files[index].url ? this.cPresentationLinkIcon : 'play_gray.svg'
      }

      return retornos[index]
    }
  }
}
</script>

<style lang="scss" scoped>

* {
  font-family: Mulish;
}

.files__extension {
  padding-left: 10px;
}

.box_info__input {
  margin: 10px
}

input[type=checkbox] {
  transform: scale(1.8);
}

.icon_wrapper{
  cursor: pointer;
}
.data__right{
  margin-left: 50px;
}
.right_side{
  right: 117px;
  position: absolute;
  &__middle{
    right: 242px;
    position: absolute;
  }
  &__init{
    right: 36px;
    position: absolute;
  }
}

.align_table{
  display: flex;
}

.box_info__label{
    /* width: 200px;
    height: 20px;
    left: 24px; */
    margin-left: 5px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 20px; */
    /* display: flex;
    align-items: center; */
    color: #303030;
  }

.content{
  border-top: 1px solid #CDCBCB;
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: block;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #FDFDFD;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.abstract{
  &__title{
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #303030;
    margin-top: 35px;
    margin-bottom: 14px;
    margin-left: 81px;
  }
  &__content{
    font-family: Mulish;
    margin-left: 81px;
    width: 733px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 18px;
    color: #686868;

  }
}
.grey_separator{
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #1B778B;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
  width: 785px;
  margin-left: 65px;
  border-bottom:  0.5px solid #3E778D;
}

.archives{
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 33px;
  color: #303030;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px 35px 20px;
  width: 785px;
  margin-left: 65px;
  border-bottom:  0.5px solid #cdcdcd;
}

.files {
  display: flex;
  margin-left: 4pc;
  margin-bottom: 35px;
  &__extension {
    margin-left: -5px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    color: #319CB4;
    margin-top: 8px;
  }

  &_subst{
    position: absolute;
    bottom: 103px;
  }
  &__after{
    position: absolute;
    top: 457px;
  }
  &__bios{
    position: absolute;
    bottom: 188px;

  }
}

.blue_line{
  width: 714px;
  position: absolute;
  bottom: 40px;
  left: 103px;
  border-bottom:  0.5px solid #3E778D;
}

.inner__wrapper {
  height:100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #FDFDFD;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;
    margin-bottom: 25px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }
  .infos {
    padding: 0px 22px 25px;

    .address, .contacts, .generalData {
      .title {
        border-bottom: 0.5px solid $primary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 5px;

        span{
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .data{
        margin-left: 10%;
        margin-top: 35px;
        display: flex;
        font-size: 16px;
        color: #686868;

        &__row{
          width: 320px;
          margin-left: -4px;
          margin-bottom: 35px;
          h4{
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__rowLarge{
          width: 520px;
          margin-bottom: 35px;
          margin-left: 6px;
          h4{
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__align{
          width: 520px;
          margin-bottom: 35px;
           margin-left: 46px;
        }
        .warning {
          color: $attention;
          border-bottom-color: $attention;
        }
        .separator {
          background-color: $primary;
          height: 1px;
          margin-top: 5px;
          margin-bottom: 30px;
          width: 81.17%;
        }
      }
    }
  }
  .grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 21px;
  }
  .revision_box{
    height: 57px;
    padding-bottom: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 65px;
    margin-bottom: 16px;

    .revision_title{
      color: $primary;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: $almostBlack;
      margin-bottom: 10px;
    }

    .revision_text{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #686868;
    }
  }
}
</style>
