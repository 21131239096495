module.exports = {
  areas: {
    materialsEngineering: [
      { text: 'Eng. dos transportes', value: 'transportEngineer' },
      { text: 'Eng. aeroespacial e aeronáutica, aerodinâmica', value: 'aerospaceAeronauticalEngineeringAerodynamics' },
      { text: 'Eng. civil e construção', value: 'civilEngineeringConstruction' },
      { text: 'Eng. de Segurança', value: 'securityEngineering' },
      { text: 'Desenvolvimento de produtos', value: 'productDevelopment' },
      { text: 'Ciência dos materiais', value: 'materialsScience' },
      { text: 'Outras', value: 'others' }
    ],

    eletricEngineering: [
      { text: 'Elétrica Geral', value: 'generalEletrical' },
      { text: 'Geração, transmissão e transformação de energia elétrica', value: 'generationTransmissionTransformationElectricity' },
      { text: 'Acionamentos elétricos', value: 'eletricDrives' },
      { text: 'Energias Renováveis', value: 'renewableEnergi' },
      { text: 'Outras', value: 'others' }
    ],

    eletronicEngineering: [
      { text: 'Eletrônica Geral', value: 'eletronicGeneral' },
      { text: 'Sistemas e Processos Eletrônicos', value: 'eletronicSystemProcess' },
      { text: 'Sistemas de Comunicação', value: 'communicationsSystem' },
      { text: 'Automação Industrial', value: 'industrialAutomation' },
      { text: 'Robótica', value: 'robotics' },
      { text: 'Outras', value: 'others' }
    ],

    mechanicalEngineering: [
      { text: 'Mecânica Geral', value: 'generalMechanics' },
      { text: 'Eng. industrial', value: 'industrialEng' },
      { text: 'Desenvolvimento de veículos', value: 'vehicleDevelopment' },
      { text: 'Desenvolvimento de produtos e processos', value: 'processProductDevelopment' },
      { text: 'Outras', value: 'others' }
    ],

    ambientalSciences: [
      { text: 'Poluição e qualidade do ar', value: 'polutionAirQuality' },
      { text: 'Poluição e qualidade da água', value: 'polutionWaterQuality' },
      { text: 'Contaminação e qualidade do solo', value: 'contaminationSoilQuality' },
      { text: 'Toxicologia Ambiental', value: 'environmentalToxicology' },
      { text: 'Outras', value: 'others' }
    ],
    animalSciences: [
      { text: 'Desenvolvimento biológico (humano e animal)', value: 'biologicalDevelopment' },
      { text: 'Ecologia', value: 'ecology' },
      { text: 'Criação de animais', value: 'animalHusbandry' },
      { text: 'Fisiologia e patologia animal', value: 'animalPhysiologyPathology' },
      { text: 'Agricultura/agronomia', value: 'agricultureAgronomy' },
      { text: 'Morfologia e desenvolvimento das plantas', value: 'plantMorphologyDevelopment' },
      { text: 'Fotossíntese', value: 'Photosynthesis' },
      { text: 'Fisiologia das plantas', value: 'plantPhysiology' },
      { text: 'Teoria de evolução das plantas', value: 'plantEvolutionTheory' },
      { text: 'Outras', value: 'others' }
    ],

    bioquimics: [
      { text: 'Bioquímica geral', value: 'generalBiochemistry' },
      { text: 'Bioquímica estrutural', value: 'structuralBiochemistry' },
      { text: 'Metabolismo', value: 'metabolism' },
      { text: 'Química dos alimentos', value: 'foodChemistry' },
      { text: 'Química orgânica', value: 'organicChemistry' },
      { text: 'Química inorgânica', value: 'inorganicChemistry' },
      { text: 'Química analítica', value: 'analyticalChemistry' },
      { text: 'Química geral', value: 'generalChemistry' },
      { text: 'Engenharia química', value: 'chemicalEngineering' },
      { text: 'Físico-química', value: 'physicochemical' },
      { text: 'Outras', value: 'others' }
    ],

    biology: [
      { text: 'Biologia molecular e celular', value: 'molecularCellBiology' },
      { text: 'Genética', value: 'genetics' },
      { text: 'Imunologia', value: 'immunology' },
      { text: 'Antibióticos', value: 'antibiotics' },
      { text: 'Antimicrobianos', value: 'antimicrobials' },
      { text: 'Bacteriologia', value: 'Bacteriology' },
      { text: 'Virologia', value: 'virology' },
      { text: 'Micologia', value: 'mycology' },
      { text: 'Outras', value: 'others' }
    ],

    socialSciences: [
      { text: 'História', value: 'history' },
      { text: 'Psicologia', value: 'psychology' },
      { text: 'Sociologia', value: 'Sociology' },
      { text: 'Antropologia', value: 'anthropology' },
      { text: 'Economia', value: 'economy' },
      { text: 'Política', value: 'politics' },
      { text: 'Administração', value: 'management' },
      { text: 'Outras', value: 'others' }
    ],

    planetarySciences: [
      { text: 'Climatologia, clima', value: 'climatologyClimate' },
      { text: 'Geoquímica, Geofísica', value: 'geochemistryGeophysics' },
      { text: 'Mineralogia', value: 'mineralogy' },
      { text: 'Paleontologia', value: 'paleontology' },
      { text: 'Ciências planetárias', value: 'planetarySciences' },
      { text: 'Geologia', value: 'geology' },
      { text: 'Sismologia', value: 'sismology' },
      { text: 'Oceanografia', value: 'oceanography' },
      { text: 'Álgebra', value: 'algebra' },
      { text: 'Matemática analítica', value: 'analyticalMathematics' },
      { text: 'Matemática aplicada', value: 'aplicatedMath' },
      { text: 'Geometria', value: 'geometry' },
      { text: 'Probabilidade e Estatística', value: 'probabilityStatistics' },
      { text: 'Átomos, moléculas e sólidos', value: 'atomsMoleculesSolids' },
      { text: 'Física Biológica', value: 'biologicalPhysics' },
      { text: 'Instrumentação e eletrônica', value: 'instrumentationElectronics' },
      { text: 'Magnetismo e Eletromagnetismo', value: 'magnetismElectromagnetism' },
      { text: 'Física de partículas e nuclear', value: 'particleNuclearPhysics' },
      { text: 'Óptica, Laser', value: 'opticsLaser' },
      { text: 'Acústica', value: 'acoustics' },
      { text: 'Física teórica, astronomia', value: 'theoreticalPhysicsAstronomy' },
      { text: 'Outras', value: 'others' }
    ],

    humanities: [
      { text: 'Teorias do conhecimento, Metodologia, Didática, Pedagogia', value: 'theoriesknowledgeMethodologyDidacticsPedagogy' },
      { text: 'Linguística', value: 'Linguistics' },
      { text: 'Expressão Artística', value: 'artisticExpression' },
      { text: 'Filosofia e Teologia', value: 'philosophyTheology' },
      { text: 'Outras', value: 'others' }
    ],

    sanitaryEngineering: [
      { text: 'Biorremediação', value: 'Bioremediation' },
      { text: 'Gerenciamento de ecossistemas', value: 'ecosystemManagement' },
      { text: 'Gerenciamento de recursos terrestres, hídricos e florestais', value: 'managementLandWaterForestResources' },
      { text: 'Gerenciamento de resíduos, reciclagem', value: 'wasteManagementRecycling' },
      { text: 'Política ambiental, legislação ambiental', value: 'environmentalPolicyEnvironmentalLegislation' },
      { text: 'Educação ambiental', value: 'environmentalEducation' },
      { text: 'Outras', value: 'others' }
    ],

    healthSciences: [
      { text: 'Diagnóstico e tratamento de doenças', value: 'diagnosisTreatmentDiseases' },
      { text: 'Epidemiologia', value: 'epidemiology' },
      { text: 'Genética', value: 'Genetics' },
      { text: 'Fisiologia e Fisiopatologia', value: 'physiologyPathophysiology' },
      { text: 'Saúde no Trabalho', value: 'occupationalHealth' },
      { text: 'Prevenção, profilaxia', value: 'preventionProphylaxis' },
      { text: 'Nutrição', value: 'nutrition' },
      { text: 'Biomedicina', value: 'biomedicine' },
      { text: 'Enfermagem', value: 'nursing' },
      { text: 'Odontologia', value: 'dentistry' },
      { text: 'Educação Física', value: 'physicalEducation' },
      { text: 'Outras', value: 'others' }
    ],

    computacionalSciences:
    [
      { text: 'Engenharia da Computação', value: 'computerEngineering' },
      { text: 'Algoritmos, base de dados', value: 'algorithmsDatabase' },
      { text: 'Inteligência Artificial', value: 'artificialIntelligence' },
      { text: 'Comunicação e redes', value: 'CommunicationNetworks' },
      { text: 'Ciência Computacional', value: 'computerScience' },
      { text: 'Gráficos', value: 'graphics' },
      { text: 'Engenharia de Software', value: 'softwareEngineering' },
      { text: 'Sistemas de computadores', value: 'computerSystems' },
      { text: 'Linguagens de Programação', value: 'programmingLanguages' },
      { text: 'Outras', value: 'others' }
    ]

  },
  areaOptions: [
    { text: 'Biologia Celular e Molecular, Microbiologia', value: 'biology' },
    { text: 'Bioquímica e Química', value: 'bioquimics' },
    { text: 'Ciências Animais e de Plantas', value: 'animalSciences' },
    { text: 'Ciências Ambientais', value: 'ambientalSciences' },
    { text: 'Ciências da Computação', value: 'computacionalSciences' },
    { text: 'Ciências da Saúde', value: 'healthSciences' },
    { text: 'Ciências Planetárias, Terrestres e Matemática e Física', value: 'planetarySciences' },
    { text: 'Educação e Humanidades', value: 'humanities' },
    { text: 'Engenharia Ambiental e Sanitária', value: 'sanitaryEngineering' },
    { text: 'Engenharia e Materiais', value: 'materialsEngineering' },
    { text: 'Engenharia Elétrica', value: 'eletricEngineering' },
    { text: 'Engenharia Eletrônica', value: 'eletronicEngineering' },
    { text: 'Engenharia Mecânica', value: 'mechanicalEngineering' },
    { text: 'História e Ciências Sociais', value: 'socialSciences' }
  ],
  areasElementarySchool: [
    { text: 'Artes', value: 'arts' },
    { text: 'Biologia', value: 'biology' },
    { text: 'Educação e Pedagogia', value: 'education' },
    { text: 'Eletrônica, Informática e Robótica', value: 'eletronic' },
    { text: 'Engenharia', value: 'engineering' },
    { text: 'Filosofia', value: 'filosofy' },
    { text: 'Física', value: 'phisics' },
    { text: 'Geográfia', value: 'geography' },
    { text: 'História', value: 'history' },
    { text: 'Inclusão, Acessibilidade e Tecnologia Assistida', value: 'inclusion' },
    { text: 'Química', value: 'chemistry' },
    { text: 'Língua portuguesa, Língua estrangeira e literatura', value: 'portuguese' },
    { text: 'Matemática', value: 'math' },
    { text: 'Meio Ambiente', value: 'envirornment' },
    { text: 'Saúde', value: 'health' },
    { text: 'Sociologia', value: 'sociology' },
    { text: 'Outra', value: 'other' }
  ],
  areaOptionsElementary: [
    { text: 'Artes', value: 'arts' },
    { text: 'Biologia', value: 'biology' },
    { text: 'Educação e pedagogia', value: 'education' },
    { text: 'Elêtronica, informática e robótica', value: 'eletronic' },
    { text: 'Engenharia', value: 'engineering' },
    { text: 'Filosofia', value: 'filosofy' },
    { text: 'Física', value: 'phisics' },
    { text: 'Geografia', value: 'geography' },
    { text: 'História', value: 'history' },
    { text: 'Inclusão, acessibilidade e tecnologia', value: 'inclusion' },
    { text: 'Química', value: 'chemistry' },
    { text: 'Língua portuguesa, língua estrangeira e literatura', value: 'portuguese' },
    { text: 'Matemática', value: 'math' },
    { text: 'Meio ambiente', value: 'envirornment' },
    { text: 'Saúde', value: 'health' },
    { text: 'Sociologia', value: 'sociology' },
    { text: 'Insira a outra subárea', value: 'other' }
  ]
}
