<template>
  <aside class="inner__wrapper">
    <div class="header">
          <h3>{{$t('projectData')}}</h3>
    </div>

    <main class="form__main mt-4 main_align">
        <div class="infos">
            <p class=" grey_separator">Contrato de convivência</p>
            <div class="generalData">
              <div class="mb-4">
                <div class="abstract">
                  <h1 class="abstract__title">Termo</h1>
                  <p class="abstract__content">
                    {{ $t("coexistenceContractInfo") }}
                  </p>                  
                  <!-- p class="abstract__content">
                    Estou ciente dos dados informados no formulário de inscrição, sendo que os mesmos estão de acordo com a feira realizada.
                  </p>
                  <p class="abstract__content">
                    Assumo o compromisso de enviar o arquivo contrato de convivência para Mostratec Júnior, seja virtual ou presencial.
                  </p -->
                </div>
              </div>

              <div class="data">
                <div class="data__left">
                  <div class="data__row">
                    <h4>Consentimento</h4>
                    <span>Aceito</span>
                  </div>
                </div>

                <div class="data__right">
                  <div class="data__row">
                    <h4>Formato do contrato</h4>
                    <span>{{form.contract_format}}</span>
                  </div>
              </div>
              </div>
            </div>
            <p class="archives">Contrato de convivência</p>
            <div class="revision__content_left">
              <div  @mouseenter="enterModel('revisionHover')" @mouseleave="leaveModel('revisionHover')" class="files">
                <icon-default
                  :url="cRevisionIcon"
                  alt="Ícone de pdf"
                />
                <input
                  type="checkbox"
                  name="step"
                  id="step"
                  class="box_info__input"
                  @click="toggleModel('revision')"
                >
                <div class="files__name">
                  <label for="step" class="box_info__label">Ensino Infantil</label>
                  <label class="files__extension">{{file_name}}</label>
                </div>
              </div>
            </div>
        </div>
      </main>

  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { IconDefault } from '@/components/atoms'

export default {
  name: 'ProjectsKindergartenContract',
  components: { IconDefault },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      file_name: 'nome_do_arquivo.pdf',
      revision: false,
      revisionHover: false,
      form: {
        contract_format: ''
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    cRevisionIcon() {
      if (this.revisionHover && this.revision != true) {
        return this.revisionHover ? 'download.svg' : 'grey_pdf.png'
      }
      return this.revision ? 'pdf-icon.png' : 'grey_pdf.png'
    }
  },
  mounted() {
    this.form.contract_format = this.projectInfo.live_presentation ? 'Virtual' : 'Presencial'
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    toggleModel(model) {
      this[model] = !this[model]
    },
    enterModel(model) {
      this[model] = true
      //console.log('entrou')
    },
    leaveModel(model) {
      this[model] = false
      //console.log('saiu')
    },
    checkForm() {
      this.resetErrors()

      if (!this.errors.length) {
        this.resetErrors()
        this.saveForm()
      }
    },
    saveForm() {
      try {
        const formData = this.$store.getters.fairAffiliation
        const newData = {
          ...formData,
          studentsInfo: this.form
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem('projectSelectionData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }

  }
}

</script>

<style lang="scss" scoped>
.data__right{
  margin-left: 50px;
}
.right_side{
  right: 117px;
  position: absolute;
  &__middle{
    right: 242px;
    position: absolute;
  }
  &__init{
    right: 36px;
    position: absolute;
  }
}

.align_table{
  display: flex;
}

.box_info__label{
    width: 200px;
    height: 20px;
    left: 24px;
    margin-left: 5px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #303030;
  }

.box_info__input {
  margin-left: 2px;
}

.content{
  border-top: 1px solid #CDCBCB;
  margin-top: -33px;
  width: 96%;
  height: fit-content;
  padding-top: 13px;
  display: block;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #FDFDFD;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.abstract{
  &__title{
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #303030;
    margin-top: 35px;
    margin-bottom: 14px;
    margin-left: 81px;
  }
  &__content{
    font-family: Mulish;
    margin-left: 81px;
    width: 733px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 18px;
    color: #686868;

  }
}
.grey_separator{
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #1B778B;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
  width: 785px;
  margin-left: 65px;
  border-bottom:  0.5px solid #3E778D;
}

.archives{
  font-family: Mulish;
  position: absolute;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 33px;
  color: #303030;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 20px;
  width: 785px;
  margin-left: 65px;
  border-bottom:  0.5px solid #cdcdcd;
}

.files{
  display: flex;
  margin-top: 60px;
  margin-left: 4pc;
  margin-bottom: 30px;
  &__extension{
    margin-left: -5px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    color: #319CB4;
    margin-top: 8px;
  }

  &_subst{
    position: absolute;
    bottom: 103px;
  }
  &__after{
    position: absolute;
    top: 457px;
  }
  &__bios{
    position: absolute;
    bottom: 188px;

  }
}

.blue_line{
  width: 714px;
  position: absolute;
  bottom: 40px;
  left: 103px;
  border-bottom:  0.5px solid #3E778D;
}

.inner__wrapper {
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #FDFDFD;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;
    margin-bottom: 25px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }
  .infos {
    padding: 0px 22px 25px;

    .address, .contacts, .generalData {
      .title {
        border-bottom: 0.5px solid $primary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 5px;

        span{
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .data{
        margin-left: 10%;
        margin-top: 35px;
        display: flex;
        font-size: 16px;
        color: #686868;

        &__row{
          width: 320px;
          margin-left: -4px;
          margin-bottom: 35px;
          h4{
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__rowLarge{
          width: 520px;
          margin-bottom: 35px;
          margin-left: 6px;
          h4{
            font-family: Mulish;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #303030;
            margin-bottom: 10px;
          }
        }
        &__align{
          width: 520px;
          margin-bottom: 35px;
           margin-left: 46px;
        }
.warning {
  color: $attention;
  border-bottom-color: $attention;
}
.separator {
  background-color: $primary;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 30px;
  width: 81.17%;
}

}
    }
  }
}
</style>
