<template>
  <div class="info-wrapper">
    <GrayLineTitle
      :title ="title"
    />
    <div class="section_wrapper">
      <!-- <text-info
        v-if="textInfo"
        :text="textInfo"
      /> -->
      <div class="form_wrapper">
        <div
          :class="[
            {form_component: full_width},
            {info_component: !full_width},
            'mb-5',
            'video-link-container'
          ]"
          v-for="(item, index) in infos"
          :key="index"

        >
           <div class="title_content">
            <div @click="downloadFile(item)">
              <IconDownload
                :url='url'
              />
            </div>
            <div class="heading">
              <heading-three class="heading_three">
                {{title}}
              </heading-three>
              <default-text
                class="content"
              >
                {{ isLoading ? $t('loadingMightTakeWhile') : item.value.name }}
              </default-text>
            </div>
          </div>
          <input-default
            v-if="checkBanner(item)"
            :class="{warning: !isUrlValid}"
            :label="$t('bannerLink')"
            placeholder="Insira o link"
            id="office"
            type="text"
            v-model="item.value.url"
            @blur="handleURLValidation(item.value.url)"
            @input="emitChanges({
              text: item.text,
              value: {
                banner_link: item.value.url
              }
            })"
          />
          <input-default
            v-if="checkLink(item, $t('videoLink'))"
            :class="{warning: !isUrlValid}"
            :label="$t('videoLink')"
            id="office"
            :placeholder="$t('placeholderVideoLink')"
            type="text"
            v-model="item.value.video"
            @blur="handleURLValidation(item.value.video)"
            @input="emitChanges({
              text: item.text,
              value: {
                presentation_link: item.value.video
              }
            })"
          />
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="error"
      :text="textWarning"
      :onClick="() => error = false"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { GrayLineTitle } from '@/components/molecules'
import {
  InputDefault,
  IconDownload,
  HeadingThree,
  DefaultText,
  ButtonIconFileLink,
  FeedbackWarning,
  TextInfo
} from '@/components/atoms'
import validateUrl from '@/utils/validateUrl.js'

export default {
  name: 'InfoDefault',
  components: {
    GrayLineTitle,
    InputDefault,
    IconDownload,
    HeadingThree,
    DefaultText,
    ButtonIconFileLink,
    FeedbackWarning,
    TextInfo
  },
  props: {
    infos: {
      type: Array,
      required: true
    },
    full_width: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: false
    },
    url: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    },
    textInfo: {
      type: String,
      required: false
    },
    limitSize: {
      type: Number,
      required: false
    },
    reference: String,
    type: String
  },
  data() {
    return {
      error: false,
      textWarning: '',
      isUrlValid: true,
      isLoading: false
    }
  },
  methods: {
    checkBadge(infos) {
      return (infos === this.$t('researchAreaSingle') || infos === this.$t('researchSubAreas'))
    },
    checkArea(infos) {
      return (infos === this.$t('researchAreaSingle'))
    },
    checkLink(item, locale) {
      return (!!(item.text === locale && typeof item.value.video !== 'undefined'))
    },
    checkBanner(item) {
      return (!!(item.text === 'banner' && typeof item.value.url !== 'undefined'))
    },
    emitChanges(linkValue) {
      this.$emit('linkChanges', linkValue)
    },
    emitUpdatedFile(url, name) {
      this.$emit('updateFileData', this.reference, { url, name })
    },
    downloadFile(obj) {
      if (obj?.value?.url) {
        window.open(obj.value.url)
      }
    },
    handleURLValidation(url) {
      this.isUrlValid = validateUrl(url)
      this.$emit('urlValidation', this.isUrlValid)
    },
    displayErrors(textWarning) {
      this.error = true
      this.textWarning = textWarning
    },
    handleLoading(e) {
      this.isLoading = e
      this.$emit('emitLoading', e)
    }
  }
}
</script>

<style lang="scss" scoped>

info-wrapper {
  display: flex;
}

.video-link-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 500px;
}

.form_component{
  width: 100%;
}

.section_wrapper {
  margin-left: 17px;
}

.form_wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
}

.title_content {
  display: flex;
  margin-left: 9px;
  margin-top: 0px;

}

.heading {
  margin: 0 0 8px 15px;

  &_three {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
}

.content {
  color: $primary;
  margin-left: 5px;
}
</style>
