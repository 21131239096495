<template>
  <div class="info-wrapper">
    <heading-three class="title-info">
      {{title}}
    </heading-three>
    <div class="subarea-wrapper"
      v-if="this.info"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
      >
        <label :class="[{area: isArea}, {subarea: !isArea}]">
          <span class="subarea_name">{{item.text}}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { HeadingThree } from '@/components/atoms/Texts'

export default {
  components: { HeadingThree },
  name: 'OvalInformation',
  props: {
    title: {
      type: String,
      required: true
    },
    info: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    isArea: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.title-info {
  text-transform:initial;
}
.area {
   display: flex;
    flex-direction: row;
    padding: 5px 19px;
    width: fit-content;
    height: 28px;
    background: $primary;
    border-radius: 49px;
    opacity: 0.8;
    margin-top: 10px;
}

.subarea {
    display: flex;
    flex-direction: row;
    padding: 5px 19px;
    width: fit-content;
    height: 28px;
    background: #319CB4;
    border-radius: 49px;
    opacity: 0.8;
    margin-top: 10px;

    &_name{
      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #FFFFFF;
    }
  }

.subarea-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

info-wrapper {
  display: flex;
  flex-direction: column;
}
</style>
