var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info-wrapper"},[_c('GrayLineTitle',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"section_wrapper"},[_c('div',{staticClass:"form_wrapper"},_vm._l((_vm.infos),function(item,index){return _c('div',{key:index,class:[
          {form_component: _vm.full_width},
          {info_component: !_vm.full_width},
          'mb-5',
          'video-link-container'
        ]},[_c('div',{staticClass:"title_content"},[_c('div',{on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('IconDownload',{attrs:{"url":_vm.url}})],1),_c('div',{staticClass:"heading"},[_c('heading-three',{staticClass:"heading_three"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('default-text',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.isLoading ? _vm.$t('loadingMightTakeWhile') : item.value.name)+" ")])],1)]),(_vm.checkBanner(item))?_c('input-default',{class:{warning: !_vm.isUrlValid},attrs:{"label":_vm.$t('bannerLink'),"placeholder":"Insira o link","id":"office","type":"text"},on:{"blur":function($event){return _vm.handleURLValidation(item.value.url)},"input":function($event){return _vm.emitChanges({
            text: item.text,
            value: {
              banner_link: item.value.url
            }
          })}},model:{value:(item.value.url),callback:function ($$v) {_vm.$set(item.value, "url", $$v)},expression:"item.value.url"}}):_vm._e(),(_vm.checkLink(item, _vm.$t('videoLink')))?_c('input-default',{class:{warning: !_vm.isUrlValid},attrs:{"label":_vm.$t('videoLink'),"id":"office","placeholder":_vm.$t('placeholderVideoLink'),"type":"text"},on:{"blur":function($event){return _vm.handleURLValidation(item.value.video)},"input":function($event){return _vm.emitChanges({
            text: item.text,
            value: {
              presentation_link: item.value.video
            }
          })}},model:{value:(item.value.video),callback:function ($$v) {_vm.$set(item.value, "video", $$v)},expression:"item.value.video"}}):_vm._e()],1)}),0)]),(_vm.error)?_c('feedback-warning',{attrs:{"text":_vm.textWarning,"onClick":function () { return _vm.error = false; },"color":"#ff9800"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }