<template>
  <main class="form__main mt-4 main_align">
    <div
      class="form__item mb-5"
      v-for="(info, index) in form.infos"
      :key="index"
    >
      <div v-if="index === 0" class="grey_separator">{{ $t("advisors") }}</div>
      <input-checkbox-accordion
        :class="[
          { warning: checkErrorIfContains('active' + index) },
          'mb-7',
          'accordion_size',
        ]"
        :label="isAdvisor(index)"
        :person_name="form.infos[index].full_name"
        v-model="form.infos[index].active"
        @change="resetErrors"
      />
      <div v-if="form.infos[index].active" class="rectangle">
        <div class="content">
          <!-- <div > -->
            <div class="edit-and-paragrafh" v-if="onlyAdmin === 'admin'">
              <button
                class="button_edit"
                v-if="!open"
                @click="open = !open"
              >
                EDITAR
              </button>
            </div>
          <!-- </div> -->
          <div class="align_table">
            <div class="left_side mb-9">
              <div class="mb-9">
                <p class="form_info">{{ $t("fullName") }}</p>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('full_name' + index) },
                    ' input__space',
                  ]"
                  type="text"
                  v-model="form.infos[index].full_name"
                  @change="resetErrors"
                />
                <span v-else class="form_info__subtitle">{{
                  form.infos[index].full_name
                }}</span>

              </div>
              <div class="mb-9">
                <p class="form_info">{{ $t("sex") }}</p>
                <RadioGroup
                  v-if="open"
                  :class="['input__space']"
                  :options="genderOptions"
                  v-model="form.infos[index].sex"
                />
                <span v-else class="form_info__subtitle">
                  {{
                    form.infos[index].sex === "male"
                      ? $t("maleSingle")
                      : $t("femaleSingle")
                  }}
                </span>
              </div>
              <div class="mb-9">
                <p class="form_info">{{ $t("email") }}</p>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('email' + index) },
                    'input__space',
                  ]"
                  id="email"
                  placeholder="enterTheEmail"
                  type="text"
                  v-model="form.infos[index].email"
                />
                <span v-else class="form_info__subtitle">{{
                  form.infos[index].email
                }}</span>
              </div>
            </div>
            <div
              :class="[
                { right_side__init_after: index > 2 },
                'right_side',
                'mb-9',
              ]"
            >
              <div class="mb-9">
                <p class="form_info">{{ $t("cpf") }}</p>
                <div v-if="open">
                  <input-masked
                    v-if="
                      !form.infos[index].country ||
                      form.infos[index].country == 'Brazil'
                    "
                    :class="[
                      { warning: checkErrorIfContains('cpf' + index) },
                      'input__space',
                    ]"
                    id="codigo"
                    placeholder="xxx.xxx.xxx-xx"
                    type="text"
                    v-model="form.infos[index].cpf"
                    :mask="['###.###.###-##']"
                  />

                  <input-default
                    v-else
                    :class="['input__space']"
                    id="codigo"
                    placeholder="xxx.xxx.xxx-xx"
                    type="text"
                    v-model="form.infos[index].cpf"
                  />
                </div>
                <span v-else class="form_info__subtitle">{{
                  form.infos[index].cpf
                }}</span>
              </div>
              <div class="mb-9">
                <p class="form_info">{{ $t("landlineOrCellphone") }}</p>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('phone' + index) },
                    'input__space',
                  ]"
                  id="phone"
                  placeholder="(00) 00000-0000"
                  type="text"
                  v-model="form.infos[index].phone"
                  min="1" max="11"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
                <span v-else class="form_info__subtitle">{{
                  form.infos[index].phone
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="middle_content">
          <div class="left_side mb-9">
            <div class="mb-9">
              <p class="form_info">{{ $t("country") }}</p>
              <input-select-location
                v-if="open"
                :class="['input__space']"
                id="country"
                :items="countries"
                v-model="form.infos[index].country"
                :selected="form.infos[index].country"
              />
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].country) }}
              </span>
            </div>
            <div class="mb-9">
              <p class="form_info">{{ $t("uf") }}</p>
              <div v-if="open">
                <input-select
                  v-if="form.infos[index].country === 'Brazil'"
                  :class="['input__space']"
                  id="serie"
                  :items="states"
                  type="text"
                  v-model="form.infos[index].state"
                />
                <input-default
                  v-if="
                    form.infos[index].country &&
                    form.infos[index].country !== 'Brazil'
                  "
                  :class="[
                    { warning: checkErrorIfContains('state' + index) },
                    'input__space',
                  ]"
                  id="state"
                  placeholder="statePlaceholder"
                  type="text"
                  v-model="form.infos[index].state"
                />
              </div>
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].state) }}
              </span>
            </div>
            <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
              <p class="form_info">{{ $t("address") }}</p>
              <input-default
                v-if="open && form.infos[index].state"
                :class="[
                  { warning: checkErrorIfContains('street' + index) },
                  'input__space',
                ]"
                id="address"
                type="text"
                v-model="form.infos[index].street"
              />
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].street) }}
              </span>
            </div>
            <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
              <p class="form_info">{{ $t("neighborhood") }}</p>
              <input-default
                v-if="open"
                :class="[
                  { warning: checkErrorIfContains('district' + index) },
                  'input__space',
                ]"
                id="neighborhood"
                type="text"
                v-model="form.infos[index].district"
              />
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].district) }}
              </span>
            </div>
          </div>
          <div
            :class="[
              { right_side__middle_after: index > 1 },
              'right_side__middle',
              'mb-9',
            ]"
          >
            <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
              <p class="form_info">{{ $t("zipCode") }}</p>
              <input-masked
                v-if="open"
                :class="[
                  { warning: checkErrorIfContains('zipcode' + index) },
                  'input__space',
                ]"
                required
                id="zipCode"
                type="text"
                v-model="form.infos[index].zipcode"
                :mask="['#####-###']"
              />
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].zipcode) }}
              </span>
            </div>
            <div class="mb-9">
              <p class="form_info">{{ $t("city") }}</p>
              <div v-if="open">
                <input-select
                  v-if="form.infos[index].country === 'Brazil'"
                  :class="['input__space']"
                  id="serie"
                  :items="cities"
                  v-model="form.infos[index].city"
                  :selected="form.infos[index].city"
                />
                <input-default
                  v-if="form.infos[index].country !== 'Brazil'"
                  :class="[
                    { warning: checkErrorIfContains('city' + index) },
                    'input__space',
                  ]"
                  id="serie"
                  type="text"
                  v-model="form.infos[index].city"
                />
              </div>
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].city) }}
              </span>
            </div>
            <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
              <p class="form_info">{{ $t("number") }}</p>
              <input-default
                v-if="open && form.infos[index].state"
                :class="[
                  { warning: checkErrorIfContains('house_number' + index) },
                  'input__space',
                ]"
                id="house_number"
                type="text"
                v-model="form.infos[index].house_number"
              />
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].house_number) }}
              </span>
            </div>
            <div class="mb-9" v-if="form.infos[index].country === 'Brazil'">
              <p class="form_info">{{ $t("complement") }}</p>
              <input-default
                v-if="open"
                :class="['input__space']"
                id="complement"
                type="text"
                v-model="form.infos[index].complement"
              />
              <span v-else class="form_info__subtitle">
                {{ coalesceInformation(form.infos[index].complement) }}
              </span>
            </div>
          </div>
          <div class="separator__align">
            <p class="separator">{{ $t("address") }}</p>
          </div>
        </div>
        <div v-if="index <= 2" class="end_content">
          <div class="left_side mb-9">
            <div v-if="index <= 2" class="mb-9">
              <p class="form_info">{{ $t("graduation") }}</p>
              <input-default
                v-if="open"
                :class="[
                  { warning: checkErrorIfContains('formation' + index) },
                  'input__space',
                ]"
                type="text"
                v-model="form.infos[index].formation"
              />
              <span v-else class="form_info__subtitle">{{
                form.infos[index].formation
              }}</span>
            </div>
            <div v-if="index <= 2" class="mb-9">
              <p class="form_info">{{ $t("actingDiscipline") }}</p>
              <input-default
                v-if="open"
                :class="[
                  { warning: checkErrorIfContains('position' + index) },
                  'input__space',
                ]"
                type="text"
                :max=255
                v-model="form.infos[index].position"
              />
              <span v-else class="form_info__subtitle">{{
                form.infos[index].position
              }}</span>
            </div>
            <div
              v-if="
                index <= 2 && form.infos[index].scholarship_holder === 'yes'
              "
              class="mb-8"
            >
              <p :class="['form_info']">{{ $t("whichProgram") }}</p>
              <div v-if="open">
                <RadioGroup
                  v-if="form.infos[index].scholarship_holder == 'yes'"
                  :options="programOptions"
                  v-model="form.infos[index].which_program"
                  @change="resetErrors, deleteInputWhenNoShow(index)"
                />
                <input-default
                  v-if="
                    form.infos[index].which_program === 'other' &&
                    form.infos[index].scholarship_holder === 'yes'
                  "
                  :class="[
                    { warning: checkErrorIfContains('other_program' + index) },
                    'input__space mt-0',
                  ]"
                  id="city"
                  type="text"
                  v-model="form.infos[index].other_program"
                />
              </div>
              <span v-else class="form_info__subtitle">
                {{
                  $t(
                    form.infos[index].other_program ||
                      scholarshipHolderPrograms[form.infos[index].which_program]
                  )
                }}
              </span>
            </div>
            <div class="mb-9 levels">
              <p
                :class="[
                  'form_info',
                  { warning: checkErrorIfContains('deficiency' + index) },
                ]"
              >
                {{ $t("doYouHaveAnyKindOfDisability") }}
              </p>
              <div v-if="open">
                <label>
                  <input
                    @change="
                      resetErrors, validCheckoboxGroup(form.infos[index], index)
                    "
                    value="nao"
                    v-model="form.infos[index].deficiency"
                    type="checkbox"
                  />{{ $t("none") }}
                </label>
                <label>
                  <input
                    value="fisica"
                    v-model="form.infos[index].deficiency"
                    type="checkbox"
                    :disabled="form.infos[index].deficiency.includes('nao')"
                  />{{ $t("fis") }}
                </label>
                <label>
                  <input
                    value="intelectual"
                    v-model="form.infos[index].deficiency"
                    type="checkbox"
                    :disabled="form.infos[index].deficiency.includes('nao')"
                  />{{ $t("intellectual") }}
                </label>
                <label>
                  <input
                    value="auditiva"
                    v-model="form.infos[index].deficiency"
                    type="checkbox"
                    :disabled="form.infos[index].deficiency.includes('nao')"
                  />{{ $t("auditory") }}
                </label>
                <label>
                  <input
                    value="visual"
                    v-model="form.infos[index].deficiency"
                    type="checkbox"
                    :disabled="form.infos[index].deficiency.includes('nao')"
                  />{{ $t("visual") }}
                </label>
              </div>
              <span v-else class="form_info__subtitle">
                {{ deficiencyListPicker(form.infos[index].deficiency) }}
              </span>
            </div>
            <div v-if="!form.infos[index].t_shirt.includes('no')" class="mb-9">
              <p class="form_info">{{ $t("whatSizeShirt") }}</p>
              <input-select
                v-if="open"
                :class="[
                  'input__space',
                  { warning: checkErrorIfContains('t_shirt_size' + index) },
                ]"
                :items="sizeShirtOptions"
                v-model="form.infos[index].t_shirt_size"
              />
              <span v-else class="form_info__subtitle">{{
                tShirtSizes[form.infos[index].t_shirt_size]
              }}</span>
            </div>
          </div>
          <div class="right_side__end mb-8">
            <div v-if="index <= 2" class="mb-9">
              <p class="form_info">{{ $t("doYouHaveScholarship") }}</p>
              <RadioGroup
                @change="deleteInputWhenNoShow(index)"
                v-if="open"
                :class="[
                  {
                    warning: checkErrorIfContains('scholarship_holder' + index),
                  },
                  'mb-2 title_checkbox',
                ]"
                :options="generalOptions"
                v-model="form.infos[index].scholarship_holder"
              />
              <span v-else class="form_info__subtitle">
                {{
                  form.infos[index].scholarship_holder === "yes" ? "Sim" : "Não"
                }}
              </span>
            </div>
            <div v-if="level === 'middle'" class="mb-9">
                <p class="form_info">{{$t('haveYouParticipatedAnyOtherMostratec')}}</p>
                <RadioGroup
                  v-if="open"
                  :class="[{ warning: checkErrorIfContains('participated_mostratec' + index) }, 'mb-1']"
                  :options="generalOptions"
                  v-model="form.infos[index].participated_mostratec"
                  @change="resetErrors"
                  :selected="form.infos[index].participated_mostratec"
                />
                <span v-else class="form_info__subtitle">{{form.infos[index].participated_mostratec === 'yes' ? 'Sim' : 'Não'}}</span>
            </div>
            <div class="mb-9 levels">
              <p
                :class="[
                  'form_info',
                  { warning: checkErrorIfContains('special_service' + index) },
                ]"
              >
                {{ $t("willYouNeedAnySpecializedAssistance") }}
              </p>
              <div v-if="open">
                <label>
                  <input
                    @change="validCheckoboxGroup(form.infos[index], index)"
                    value="nao"
                    v-model="form.infos[index].special_service"
                    type="checkbox"
                  />{{ $t("none") }}
                </label>
                <label>
                  <input
                    value="acessibilidade"
                    v-model="form.infos[index].special_service"
                    type="checkbox"
                    :disabled="
                      form.infos[index].special_service.includes('nao')
                    "
                  />{{ $t("architecturalAccessibility") }}
                </label>
                <label>
                  <input
                    value="libras"
                    v-model="form.infos[index].special_service"
                    type="checkbox"
                    :disabled="
                      form.infos[index].special_service.includes('nao')
                    "
                  />{{ $t("libraInterpreter") }}
                </label>
                <label>
                  <input
                    value="ledor"
                    v-model="form.infos[index].special_service"
                    type="checkbox"
                    :disabled="
                      form.infos[index].special_service.includes('nao')
                    "
                  />{{ $t("reader") }}
                </label>
                <label>
                  <input
                    value="outro"
                    v-model="form.infos[index].special_service"
                    type="checkbox"
                    :disabled="
                      form.infos[index].special_service.includes('nao')
                    "
                  />{{ $t("othersMale") }}
                </label>
              </div>
              <span v-else class="form_info__subtitle">
                {{ serviceListPicker(form.infos[index].special_service) }}
              </span>
            </div>
            <div class="mb-9">
              <p class="form_info">{{ $t("typeOfShirt") }}</p>
              <input-select
                v-if="open"
                :class="['input__space']"
                id="t_shirt"
                :items="typeShirtOptions"
                v-model="form.infos[index].t_shirt"
              />
              <span v-else class="form_info__subtitle">
                {{ $t(tShirtTypes[form.infos[index].t_shirt]) }}
              </span>
            </div>
          </div>
          <div class="separator__align">
            <p class="separator">{{ $t("registrationData") }}</p>
          </div>
        </div>
        <div class="card-vacancies-cancel-and-save">
          <button  v-if="open" class="button_cancel" @click="cancelSave">
            CANCELAR
          </button>
          <button-default
            v-if="open"
            :onClick="() => checkForm(index)"
            class="button_save"
            >Salvar</button-default
          >
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </main>
</template>

<script>
import {
  InputCheckboxAccordion,
  InputDefault,
  RadioGroup,
  InputMasked,
  InputSelectLocation,
  InputSelect,
  ButtonDefault,
  textWarning,
  FeedbackWarning
} from '@/components/atoms'

import { countries, states } from '@/utils/locations'

import {
  tShirtTypes,
  tShirtSizes,
  englishLevels,
  scholarshipHolderPrograms
} from '@/utils/dictionaries'

export default {
  name: 'ProjectsAdvisorsData',
  components: {
    InputCheckboxAccordion,
    InputDefault,
    RadioGroup,
    InputMasked,
    InputSelectLocation,
    InputSelect,
    ButtonDefault,
    textWarning,
    FeedbackWarning
  },
  data() {
    return {
      onlyAdmin: '',
      level: '',
      open: false,
      form: {
        infos: []
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      tShirtTypes,
      tShirtSizes,
      englishLevels,
      scholarshipHolderPrograms,
      genderOptions: [
        { label: this.$t('maleSingle'), value: 'male' },
        { label: this.$t('femaleSingle'), value: 'female' }
      ],
      countries,
      states,
      cities: [],
      sizeShirtOptions: [
        { text: 'P', value: 'p' },
        { text: 'M', value: 'm' },
        { text: 'G', value: 'g' },
        { text: 'GG', value: 'gg' }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: 'yes' },
        { label: this.$t('no'), value: 'no' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' },
        { text: 'P', value: 'p' },
        { text: 'M', value: 'm' },
        { text: 'G', value: 'g' },
        { text: 'GG', value: 'gg' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ]
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.getInfos()
    this.onlyAdmin = localStorage.getItem('role')
  },
  watch: {
    cStateSelected() {
      this.executeGetCities(this.form.infos[0].state)
    },
    cStateSelected2() {
      this.executeGetCities(this.form.infos[1].state)
    },
    cStateSelected3() {
      this.executeGetCities(this.form.infos[2].state)
    }
  },
  computed: {
    cStateSelected() {
      if (this.form.infos[0]) {
        return this.form.infos[0].state
      }
    },
    cStateSelected2() {
      if (this.form.infos[1]) {
        return this.form.infos[1].state
      }
    },
    cStateSelected3() {
      if (this.form.infos[2]) {
        return this.form.infos[2].state
      }
    }
  },
  methods: {
    isAdvisor(value) {
      return value === 0 ? 'advisor' : 'coAdvisor'
    },
    getInfos() {
      this.level = this.projectInfo.level
      this.projectInfo.involveds.forEach((involved) => {
        if (involved.type === 'orientador' || involved.type === 'coorientador') { this.form.infos.push(involved) }
      })

      this.form.infos.sort((a, b) => {
        if (a.type === 'orientador') return -1
        if (a.type === 'coorientador') return 1
        return 0
      })

      this.form.infos.forEach((el) => {
        el.special_service = [
          this.serviceListPicker(el.special_service)
        ][0].split(', ')
        el.deficiency = [this.deficiencyListPicker(el.deficiency)][0].split(
          ', '
        )
        el.scholarship_holder = el.scholarship_holder === true ? 'yes' : 'no'
        el.participated_mostratec = el.participated_mostratec === true ? 'yes' : 'no'
      })
    },
    deficiencyListPicker(deficiency) {
      let list = deficiency?.deficiency || deficiency?.deficiencies
      if (!deficiency?.deficiency && !deficiency?.deficiencies) {
        list = deficiency
      }
      return this.defOrServiceFormatted(list)
    },
    serviceListPicker(service) {
      let list = service?.special_service || service?.special_services
      if (!service?.special_service && !service?.special_services) {
        list = service
      }
      return this.defOrServiceFormatted(list)
    },
    defOrServiceFormatted(defOrService) {
      if (defOrService.constructor !== Array) {
        return 'nao'
      }

      if (defOrService?.includes('nao') || !defOrService?.length) {
        return 'nao'
      }
      const formattedDefOrService = []
      defOrService.forEach((item) => {
        if (typeof item === 'string') {
          return formattedDefOrService.push(
            item.charAt(0).toLowerCase() + item.slice(1)
          )
        }

        formattedDefOrService.push('nao')
      })
      return formattedDefOrService.join(', ')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    coalesceInformation(info) {
      if (!info && info !== 0) return 'Não informado'
      return info
    },
    executeGetCities(state) {
      if (this.form.infos[0].country !== 'Brazil') return false
      this.cities = []
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`
      )
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    validCheckoboxGroup(actualData, index) {
      if (actualData.deficiency.includes('nao')) {
        this.form.infos[index].deficiency = ['nao']
        this.form.infos[index].special_service = ['nao']
      }
      if (actualData.special_service.includes('nao')) {
        this.form.infos[index].special_service = ['nao']
      }
    },
    deleteInputWhenNoShow(index) {
      if (this.form.infos[index].scholarship_holder === 'no') {
        this.form.infos[index].which_program = ''
        this.form.infos[index].other_program = ''
      }

      if (this.form.infos[index].which_program === 'cnpq') {
        this.form.infos[index].other_program = ''
      }
    },
    checkForm(index) {
      this.resetErrors()
      this.form.infos.forEach((item, index) => {
        if (item.active) {
          for (const key in item) {
            if (!this.form.infos[index][key]) {
              if (
                key === 'complement'
                || key === 'active'
                || key === 'meal'
                || key === 'will_pay'
                || key === 'appeared'
                || ((key === 'street' || key === 'house_number' || key === 'district' || key === 'zipcode')
                  && item.country !== 'Brazil')
                || ((key === 'which_program' || key === 'other_program')
                  && item.scholarship_holder === 'no')
                || (key === 'participated_mostratec' && this.level !== 'middle')
                || (key === 'other_program' && item.which_program !== 'other')
                || (key === 't_shirt_size'
                  && (item.t_shirt === 'no' || !item.t_shirt))
              ) {
              } else {
                this.errors.push(`${ key }${ index }`)
                //console.log('liiinhaa 829', key)
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
              }
            }
            if (key === 'deficiency') {
              if (!item.deficiency.length) {
                // this.resetErrors()
                this.errors.push(`${ key }${ index }`)
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
                return false
              }
            }
            if (
              !item.deficiency.includes('nao')
              && !item.special_service.length
            ) {
              this.errors.push(`special_service${ index }`)
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
              return false
            }
          }

          if (!this.errors.length) {
            if (!this.validateCpf(item.cpf, index)) {
              this.textWarning = 'invalidZipCode'
              return false
            }
            if (!this.validateEmail(item.email, index)) {
              this.textWarning = 'E-mail inválido!'
              return false
            }
            if (item.country === 'Brazil') {
              if (item.zipcode.length < 9) {
                this.errors.push(`zipcode${ index }`)
                this.textWarning = 'Informe um CEP válido'
                return false
              }
            }
          }
        }
      })

      if (!this.errors.length) {
        this.editForm(index)
      }
    },
    async editForm(index) {
      const { id } = this.projectInfo
      const involvedId = this.form.infos[index].id

      const body = {
        involveds: [
          {
            id: involvedId,
            full_name: this.form.infos[index].full_name,
            cpf: this.form.infos[index].cpf,
            sex: this.form.infos[index].sex,
            phone: this.form.infos[index].phone,
            email: this.form.infos[index].email,
            country: this.form.infos[index].country,
            state: this.form.infos[index].state,
            city: this.form.infos[index].city,
            street: this.form.infos[index].street,
            house_number: this.form.infos[index].house_number,
            complement: this.form.infos[index].complement,
            zipcode: this.form.infos[index].zipcode,
            district: this.form.infos[index].district,
            formation: this.form.infos[index].formation,
            position: this.form.infos[index].position,
            scholarship_holder:
              this.form.infos[index].scholarship_holder === 'yes',
            participated_mostratec: this.form.infos[index].participated_mostratec,
            which_program: this.form.infos[index].which_program,
            other_program: this.form.infos[index].other_program,
            deficiency: { deficiencies: this.form.infos[index].deficiency },
            special_service: {
              special_services: this.form.infos[index].special_service
            },
            t_shirt: this.form.infos[index].t_shirt,
            t_shirt_size: this.form.infos[index].t_shirt_size
          }
        ]
      }
      try {
        await this.$http.put(`/project/${ id }`, body)
        location.reload()
      } catch (error) {
        console.log(error.message)
      }
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    cancelSave() {
      location.reload()
    },
    validateCpf(value, index) {
      if (this.form.infos[index].country != 'Brazil') {
        return true
      }
      if (
        !/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/.test(
          value
        )
      ) {
        this.errors.push(`cpf${ index }`)
        return false
      }
      return true
    },
    validateEmail(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.infos[index].email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push(`email${ index }`)
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.rectangle {
  width: inherit;
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 464px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__main {
    .form__item {
      margin-left: 10px;

      .input__space {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      &--info {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        color: $primary;
      }
    }
  }
}

.main_align {
  padding-top: 70px;
  padding-left: 50px;
  // input[type="checkbox"] {
  //   position: absolute;
  //   left: 351px;
  //   width: 65%;
  // }
  img {
    position: absolute;
    left: 69px;
    top: 56px;
  }
}

.grey_separator {
  margin-left: 4px;
  border-bottom: 0.5px solid #cdcbcb;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  width: 98%;
  color: #303030;
}

.align_table {
  display: flex;
  justify-content: space-between;
}

.content {
  border-top: 1px solid #cdcbcb;
  margin-top: -33px;
  width: 95%;
  height: fit-content;
  padding-top: 13px;
  display: block;
  padding-left: 12px;
  padding-bottom: 37px;
  background: #fdfdfd;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.left_side {
  width: 48%;
  // padding-right: 10px;
}

.form_info {
  font-family: Mulish;
  font-style: normal;
  margin-bottom: 9px;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  margin-left: 1.5px;
  margin-right: 60px;
  &__subtitle {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 18px;
    margin-left: 2px;
    color: #686868;
  }
}

.right_side {
  // margin-right: 69px;
  width: 48%;
  &__middle {
    // margin-right: 142px;
    width: 48%;
  }
  &__end {
    max-width: 308px;
  }
  &__middle_after {
    // right: 171px;
    // max-width: 446px;
    // position: absolute;
    width: 48%;
  }
  &__init_after {
    right: 94px;
    max-width: 446px;
    position: absolute;
  }
  &__third_index {
    right: -15px;
    max-width: 446px;
    position: absolute;
  }
}

.middle_content {
  margin-top: -33px;
  width: 95%;
  justify-content: space-between;
  height: fit-content;
  padding-top: 13px;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
}

.separator {
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  width: 300px-55px;
  font-size: 24px;
  position: absolute;
  top: -55px;
  line-height: 33px;
  color: #1b778b;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
  &__align {
    height: 40px;
    position: absolute;
  }
}

.end_content {
  margin-top: -33px;
  width: 95%;
  height: fit-content;
  padding-top: 13px;
  justify-content: space-between;
  display: flex;
  padding-left: 12px;
  padding-bottom: 20px;
  background: #fdfdfd;
  border-top: 1px solid #3e778d;
}

.levels {
  display: flex;
  flex-direction: column;

  .title {
    color: #303030;
    display: flex;
    flex-direction: column;
    font-size: 16px !important;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  label {
    display: flex;
    color: $almostBlack;
    font-size: 16px;
    margin-bottom: 5px;

    input {
      zoom: 1.5;
      margin-right: 5px;
    }
  }

  .warning {
    color: #ff9800;
  }
}

.button_edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  /* position: absolute; */
  width: 100px;
  height: 36px;
  left: 1230px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_save {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  margin: 0px 10px;

  width: 106px;
  height: 44px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_cancel {
  box-sizing: border-box;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 32px;

  width: 113px;
  height: 45px;

  border: 1px solid #26768d;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;

  color: #26768d;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.edit-and-paragrafh {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 2%;
}

.card-vacancies-cancel-and-save {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5%;
}
</style>
