<template>
  <div class="wrapper">
    <label class="label" :for="id">
      {{ $t(label) }}
    </label>
    <div class="info">
      <img :src="require(`@/assets/information-circle.png`)" alt="Informação sobre Liberato">
      <p>{{$t(text)}}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TitleAndInfo',
  props: {
    label: String,
    id: String,
    url: String,
    alt: String,
    text: String
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;

  .label {
    color: #303030;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }
}

.info {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  margin-top: 8px;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $almostBlack;
    margin-left: 6px;
    max-width: 469px;
    text-align: left;
  }
}

.warning {
  .label {
    color: $attention;
  }

  .info p {
    color: $attention;
  }
}

</style>
