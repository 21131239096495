<template>
  <span :class="[{default_text: !color}, {lightBlue: color === 'lightBlue'}]">
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'DefaultText',
  props: {
    color: String
  }
}
</script>

<style lang="scss" scoped>
.default_text {
  font-family: $font-stack;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.lightBlue {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  height: fit-content;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #319CB4;
}
</style>
