<template>
  <div>
    <!-- div class="inner__wrapper mb-6">
      <div class="header">
        <h3>Requisição de vagas - Painel Administrativo</h3>
      </div>
      <div class="infos">
        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior: Educação Infantil</span>
            <span v-if="vancanciesRequests.grade.quantity_registered !== 0"
              >Número de vagas solicitadas: {{ vancanciesRequests.grade.quantity_registered }}</span
            >
            <span v-else>Não foram requisitadas vagas</span>
          </div>
          <div class="areas">
            <span>Não há áreas de pesquisa especificadas para esse ensino</span>
            <span
              v-for="(item, index) in vancanciesRequests.grade.research_areas"
              :key="index"
              class="badge__info"
              >{{ item }}</span
            >
          </div>
        </div>

        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior: Ensino fundamental</span>
            <span v-if="vancanciesRequests.elementary.quantity_registered !== 0"
              >Número de vagas solicitadas:
              {{ vancanciesRequests.elementary.quantity_registered }}</span
            >
            <span v-else>Não foram requisitadas vagas</span>
          </div>
          <div class="areas">
            <span v-if="vancanciesRequests.elementary.research_areas.length"
              >Área de pesquisa:</span
            >
            <span v-else>Não foram especificadas as áreas de pesquisa</span>
            <span
              v-for="(item, index) in vancanciesRequests.elementary.research_areas"
              :key="index"
              class="badge__info"
              >{{ item }}</span
            >
          </div>
        </div>
        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior:<br/>{{$t('elementarySchoolCategory1')}}</span>
            <span v-if="vancanciesRequests.middleCat1.quantity_registered !== 0"
              >Número de vagas solicitadas:
              {{ vancanciesRequests.middleCat1.quantity_registered }}</span
            >
            <span v-else>Não foram requisitadas vagas</span>
          </div>
          <div class="areas">
            <span v-if="vancanciesRequests.middleCat1.research_areas.length"
              >Área de pesquisa:</span
            >
            <span v-else>Não foram especificadas as áreas de pesquisa</span>
            <span
              v-for="(item, index) in vancanciesRequests.middleCat1.research_areas"
              :key="index"
              class="badge__info"
              >{{ item }}</span
            >
          </div>
        </div>

        <div class="info__item">
          <div class="title">
            <span>Mostratec: Ensino médio/técnico</span>
            <span v-if="vancanciesRequests.high.quantity_registered !== 0"
              >Número de vagas solicitadas: {{ vancanciesRequests.high.quantity_registered }}</span
            >
            <span v-else>Não foram requisitadas vagas</span>
          </div>
          <div class="areas">
            <span v-if="vancanciesRequests.high.research_areas.length">Área de pesquisa:</span>
            <span v-else>Não foram especificadas as áreas de pesquisa</span>
            <span
              v-for="(item, index) in vancanciesRequests.high.research_areas"
              :key="index"
              class="badge__info"
              >{{ item }}</span
            >
          </div>
        </div>
      </div>
    </div -->

    <div
      :class="[
        {
          disableFields:
            fairInfo.status === 'pendente de analise' ||
            fairInfo.status === 'aguardando resposta' ||
            fairInfo.status === 'resposta enviada'
        },
        'inner__wrapper'
      ]"
    >
      <div class="header">
        <h3>Concessão de vagas - Painel Administrativo</h3>
      </div>
      <div class="infos">
        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior: Educação Infantil</span>
            <span
              >Vagas solicitadas: {{ vancanciesRequests.grade.quantity_registered }}</span
            >
          </div>
          <div class="areas">
            <div class="inputs">
              <inputDefault
                :class="[{
                  disableFields: vancanciesRequests.high.quantity_registered === 0
                    || !solicitationAccepted,
                  warning: checkErrorIfContains('grade - granted')
                }]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas concedidas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.grade.quantity_granted"
              />

              <!-- inputDefault
                :class="[
                  {
                    disableFields: checkIfDisableField()
                  }
                ]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas isentas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.grade.quantity_exempt"
              / -->
            </div>
          </div>
        </div>

        <!-- div class="info__item">
          <div class="title">
            <span>Mostratec Júnior: Ensino fundamental</span>
            <span
              >Número de vagas solicitadas:
              {{ vancanciesRequests.elementary.quantity_registered }}</span
            >
          </div>
          <div class="areas">
            <div class="inputs">
              <inputDefault
                :class="[{
                  disableFields: vancanciesRequests.elementary.quantity_registered === 0
                    || !solicitationAccepted,
                  warning: checkErrorIfContains('middle - granted')
                }]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas concedidas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.elementary.quantity_granted"
              />
              <inputDefault
                :class="[
                  {
                    disableFields: checkIfDisableField()
                  }
                ]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas isentas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.elementary.quantity_exempt"
              />
            </div>
          </div>
        </div -->

        <!-- New levels middle_cat_X-->
        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior:<br/>{{$t('elementarySchoolCategory1')}}</span>
            <span
              >Vagas solicitadas:
              {{ vancanciesRequests.middleCat1.quantity_registered }}</span
            >
          </div>
          <div class="areas">
            <div class="inputs">
              <inputDefault
                :class="[{
                  disableFields: vancanciesRequests.middleCat1.quantity_registered === 0
                    || !solicitationAccepted,
                  warning: checkErrorIfContains('middle - granted')
                }]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas concedidas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCat1.quantity_granted"
              />
              <!-- inputDefault
                :class="[
                  {
                    disableFields: checkIfDisableField()
                  }
                ]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas isentas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCat1.quantity_exempt"
              / -->
            </div>
          </div>
        </div>

        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior:<br/>{{$t('elementarySchoolCategory2')}}</span>
            <span
              >Vagas solicitadas:
              {{ vancanciesRequests.middleCat2.quantity_registered }}</span
            >
          </div>
          <div class="areas">
            <div class="inputs">
              <inputDefault
                :class="[{
                  disableFields: vancanciesRequests.middleCat2.quantity_registered === 0
                    || !solicitationAccepted,
                  warning: checkErrorIfContains('middle - granted')
                }]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas concedidas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCat2.quantity_granted"
              />
              <!-- inputDefault
                :class="[
                  {
                    disableFields: checkIfDisableField()
                  }
                ]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas isentas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCat2.quantity_exempt"
              / -->
            </div>
          </div>
        </div>

        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior:<br/>{{$t('elementarySchoolCategory3')}}</span>
            <span
              >Vagas solicitadas:
              {{ vancanciesRequests.middleCat3.quantity_registered }}</span
            >
          </div>
          <div class="areas">
            <div class="inputs">
              <inputDefault
                :class="[{
                  disableFields: vancanciesRequests.middleCat3.quantity_registered === 0
                    || !solicitationAccepted,
                  warning: checkErrorIfContains('middle - granted')
                }]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas concedidas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCat3.quantity_granted"
              />
              <!-- inputDefault
                :class="[
                  {
                    disableFields: checkIfDisableField()
                  }
                ]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas isentas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCat3.quantity_exempt"
              / -->
            </div>
          </div>
        </div>

        <div class="info__item">
          <div class="title">
            <span>Mostratec Júnior:<br/>{{$t('elementarySchoolCategoryEja')}}</span>
            <span
              >Vagas solicitadas:
              {{ vancanciesRequests.middleCatEJA.quantity_registered }}</span
            >
          </div>
          <div class="areas">
            <div class="inputs">
              <inputDefault
                :class="[{
                  disableFields: vancanciesRequests.middleCatEJA.quantity_registered === 0
                    || !solicitationAccepted,
                  warning: checkErrorIfContains('middle - granted')
                }]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas concedidas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCatEJA.quantity_granted"
              />
              <!-- inputDefault
                :class="[
                  {
                    disableFields: checkIfDisableField()
                  }
                ]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas isentas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.middleCatEJA.quantity_exempt"
              / -->
            </div>
          </div>
        </div>
        <!-- End new levels-->

        <div class="info__item">
          <div class="title">
            <span>Mostratec: Ensino médio/técnico</span>
            <span
              >Vagas solicitadas: {{ vancanciesRequests.high.quantity_registered }}</span
            >
          </div>
          <div class="areas">
            <div class="inputs">
              <inputDefault
                :class="[{
                  disableFields: vancanciesRequests.high.quantity_registered === 0
                    || !solicitationAccepted,
                  warning: checkErrorIfContains('high - granted')
                }]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas concedidas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.high.quantity_granted"
              />
              <!-- inputDefault
                :class="[
                  {
                    disableFields: checkIfDisableField()
                  }
                ]"
                :disabled="checkIfDisableField()"
                type="number"
                label="Vagas isentas"
                placeholder="Insira o número de vagas"
                v-model="vancanciesRequests.high.quantity_exempt"
              / -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="fairInfo.status === 'nao aceita'">
      <div class="inner__wrapper mb-6 top-space">
        <div class="header">
          <h3>{{$t('JustificationLabel')}}</h3>
        </div>
        <div class="infos">
          <div class="info__item">
            <span>{{ fairInfo.justification }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { InputDefault } from '@/components/atoms'

export default {
  name: 'Request',
  props: ['fairInfo', 'navSwitch', 'errors'],
  components: { InputDefault },
  data: () => ({
    vancanciesRequests: {
      grade: {
        quantity_registered: 0,
        quantity_granted: null,
        quantity_exempt: 0
      },
      elementary: {
        quantity_registered: 0,
        quantity_granted: null,
        quantity_exempt: 0,
        research_areas: []
      },
      high: {
        quantity_registered: 0,
        quantity_granted: null,
        quantity_exempt: 0,
        research_areas: []
      },
      // new levels
      middleCat1: {
        quantity_registered: 0,
        quantity_granted: null,
        quantity_exempt: 0,
        research_areas: []
      },
      middleCat2: {
        quantity_registered: 0,
        quantity_granted: null,
        quantity_exempt: 0,
        research_areas: []
      },
      middleCat3: {
        quantity_registered: 0,
        quantity_granted: null,
        quantity_exempt: 0,
        research_areas: []
      },
      middleCatEJA: {
        quantity_registered: 0,
        quantity_granted: null,
        quantity_exempt: 0,
        research_areas: []
      }
      // end new levels
    },
    nav: '',
    solicitationAccepted: null
  }),
  mounted() {
    this.nav = this.navSwitch
  },
  computed: {
    ...mapGetters({
      fairAffiliationAnalysisAccept: 'fairAffiliationAnalysisAccept'
    })
  },
  watch: {
    vancanciesRequests: {
      handler() {
        this.checkVacanciesRequests()
        this.sendVacanciesRequestNumber()
      },
      deep: true
    },
    fairInfo() {
      this.getInfos()
    },
    nav() {
      this.getInfos()
    },
    fairAffiliationAnalysisAccept(val) {
      this.solicitationAccepted = val
      if (this.fairInfo.status_is_active === null) {
        if (!val) {
          this.fairInfo.vacancies_requests.forEach((item) => {
            if (item.level === 'grade') {
              this.vancanciesRequests.grade.quantity_granted = 0
              item.quantity_granted = null
              this.vancanciesRequests.grade.quantity_exempt = 0
              item.quantity_exempt = 0
            }
            if (item.level === 'middle') {
              this.vancanciesRequests.elementary.quantity_granted = 0
              item.quantity_granted = null
              this.vancanciesRequests.elementary.quantity_exempt = 0
              item.quantity_exempt = 0
            }
            if (item.level === 'high') {
              this.vancanciesRequests.high.quantity_granted = 0
              item.quantity_granted = null
              this.vancanciesRequests.high.quantity_exempt = 0
              item.quantity_exempt = 0
            }
            // new level middle_cat_X
            if (item.level === 'middle_cat_1') {
              this.vancanciesRequests.middleCat1.quantity_granted = 0
              item.quantity_granted = null
              this.vancanciesRequests.middleCat1.quantity_exempt = 0
              item.quantity_exempt = 0
            }
            if (item.level === 'middle_cat_2') {
              this.vancanciesRequests.middleCat2.quantity_granted = 0
              item.quantity_granted = null
              this.vancanciesRequests.middleCat2.quantity_exempt = 0
              item.quantity_exempt = 0
            }
            if (item.level === 'middle_cat_3') {
              this.vancanciesRequests.middleCat3.quantity_granted = 0
              item.quantity_granted = null
              this.vancanciesRequests.middleCat3.quantity_exempt = 0
              item.quantity_exempt = 0
            }
            if (item.level === 'middle_cat_eja') {
              this.vancanciesRequests.middleCatEJA.quantity_granted = 0
              item.quantity_granted = null
              this.vancanciesRequests.middleCatEJA.quantity_exempt = 0
              item.quantity_exempt = 0
            }
            // end new levels
          })
        }
        if (val) {
          this.vancanciesRequests.grade.quantity_granted = null
          this.vancanciesRequests.elementary.quantity_granted = null
          this.vancanciesRequests.high.quantity_granted = null
          // new levels
          this.vancanciesRequests.middleCat1.quantity_granted = null
          this.vancanciesRequests.middleCat2.quantity_granted = null
          this.vancanciesRequests.middleCat3.quantity_granted = null
          this.vancanciesRequests.middleCatEJA.quantity_granted = null
        }
      }
    }
  },
  methods: {
    getInfos() {
      if (this.fairInfo.status_is_active !== null) {
        this.solicitationAccepted = this.fairInfo.status_is_active
      } else this.solicitationAccepted = this.$store.getters.fairAffiliationAnalysisAccept || null

      this.vancanciesRequests.elementary.research_areas = []
      this.vancanciesRequests.high.research_areas = []

      if (this.fairInfo.vacancies_requests) {
        this.fairInfo.vacancies_requests.forEach((item) => {
          if (this.solicitationAccepted) {
            if (item.level === 'grade') {
              switch (true) {
                case this.vancanciesRequests.grade.quantity_exempt < 0:
                case this.vancanciesRequests.grade.quantity_exempt === '':
                  this.vancanciesRequests.grade.quantity_exempt = 0
                  break
              }
            }
            if (item.level === 'middle') {
              switch (true) {
                case this.vancanciesRequests.elementary.quantity_exempt < 0:
                case this.vancanciesRequests.elementary.quantity_exempt === '':
                  this.vancanciesRequests.elementary.quantity_exempt = 0
                  break
              }
            }
            // new level middle_cat_X
            if (item.level === 'middle_cat_1') {
              switch (true) {
                case this.vancanciesRequests.middleCat1.quantity_exempt < 0:
                case this.vancanciesRequests.middleCat1.quantity_exempt === '':
                  this.vancanciesRequests.middleCat1.quantity_exempt = 0
                  break
              }
            }
            if (item.level === 'middle_cat_2') {
              switch (true) {
                case this.vancanciesRequests.middleCat2.quantity_exempt < 0:
                case this.vancanciesRequests.middleCat2.quantity_exempt === '':
                  this.vancanciesRequests.middleCat2.quantity_exempt = 0
                  break
              }
            }
            if (item.level === 'middle_cat_3') {
              switch (true) {
                case this.vancanciesRequests.middleCat3.quantity_exempt < 0:
                case this.vancanciesRequests.middleCat3.quantity_exempt === '':
                  this.vancanciesRequests.middleCat3.quantity_exempt = 0
                  break
              }
            }
            if (item.level === 'middle_cat_eja') {
              switch (true) {
                case this.vancanciesRequests.middleCatEJA.quantity_exempt < 0:
                case this.vancanciesRequests.middleCatEJA.quantity_exempt === '':
                  this.vancanciesRequests.middleCatEJA.quantity_exempt = 0
                  break
              }
            }
            // end new level middle_cat_X
            if (item.level === 'high') {
              switch (true) {
                case this.vancanciesRequests.high.quantity_exempt < 0:
                case this.vancanciesRequests.high.quantity_exempt === '':
                  this.vancanciesRequests.high.quantity_exempt = 0
                  break
              }
            }
          }

          if (item.level === 'grade') {
            if (this.solicitationAccepted === null) item.quantity_granted = null
            this.vancanciesRequests.grade.quantity_registered = item.quantity_registered
            this.vancanciesRequests.grade.quantity_granted = item.quantity_granted
            this.vancanciesRequests.grade.quantity_exempt = item.quantity_exempt
          }
          if (item.level === 'middle') {
            if (this.solicitationAccepted === null) item.quantity_granted = null
            this.vancanciesRequests.elementary.quantity_registered = item.quantity_registered
            this.vancanciesRequests.elementary.quantity_granted = item.quantity_granted
            this.vancanciesRequests.elementary.quantity_exempt = item.quantity_exempt
            if (item.biologia) { this.vancanciesRequests.elementary.research_areas.push('Biologia') }
            if (item.bioquimica_e_quimica) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Bioquímica e Química'
              )
            }
            if (item.ciencias) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Ciências (ambientais, animais e das plantas)'
              )
            }
            if (item.ciencias_da_computacao) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Ciências da Computação'
              )
            }
            if (item.ciencias_planetarias_e_terrestres) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Ciências Planetárias e terrestres, Matemática e Física'
              )
            }
            if (item.ciencias_sociais) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Ciências Sociais, Comportamento e Arte'
              )
            }
            if (item.engenharia) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Engenharia (Elétrica, Eletrônica, Mecânica)'
              )
            }
            if (item.engenharia_e_materiais) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Engenharia e Materiais'
              )
            }
            if (item.gerenciamento_do_meio_ambiente) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Gerenciamento do Meio Ambiente'
              )
            }
            if (item.medicina_e_saude) {
              this.vancanciesRequests.elementary.research_areas.push(
                'Medicina e Saúde'
              )
            }
            if (item.other && item.other.length) {
              this.vancanciesRequests.elementary.research_areas.push(
                `Outro: ${ item.other }`
              )
            }
          }

          // new level middle_cat_X
          if (item.level === 'middle_cat_1') {
            if (this.solicitationAccepted === null) item.quantity_granted = null
            this.vancanciesRequests.middleCat1.quantity_registered = item.quantity_registered
            this.vancanciesRequests.middleCat1.quantity_granted = item.quantity_granted
            this.vancanciesRequests.middleCat1.quantity_exempt = item.quantity_exempt
          }
          if (item.level === 'middle_cat_2') {
            if (this.solicitationAccepted === null) item.quantity_granted = null
            this.vancanciesRequests.middleCat2.quantity_registered = item.quantity_registered
            this.vancanciesRequests.middleCat2.quantity_granted = item.quantity_granted
            this.vancanciesRequests.middleCat2.quantity_exempt = item.quantity_exempt
          }
          if (item.level === 'middle_cat_3') {
            if (this.solicitationAccepted === null) item.quantity_granted = null
            this.vancanciesRequests.middleCat3.quantity_registered = item.quantity_registered
            this.vancanciesRequests.middleCat3.quantity_granted = item.quantity_granted
            this.vancanciesRequests.middleCat3.quantity_exempt = item.quantity_exempt
          }
          if (item.level === 'middle_cat_eja') {
            if (this.solicitationAccepted === null) item.quantity_granted = null
            this.vancanciesRequests.middleCatEJA.quantity_registered = item.quantity_registered
            this.vancanciesRequests.middleCatEJA.quantity_granted = item.quantity_granted
            this.vancanciesRequests.middleCatEJA.quantity_exempt = item.quantity_exempt
          }
          // end new levels
          if (item.level === 'high') {
            if (this.solicitationAccepted === null) item.quantity_granted = null
            this.vancanciesRequests.high.quantity_registered = item.quantity_registered
            this.vancanciesRequests.high.quantity_granted = item.quantity_granted
            this.vancanciesRequests.high.quantity_exempt = item.quantity_exempt
            if (item.biologia) { this.vancanciesRequests.high.research_areas.push('Biologia') }
            if (item.bioquimica_e_quimica) {
              this.vancanciesRequests.high.research_areas.push(
                'Bioquímica e Química'
              )
            }
            if (item.ciencias) {
              this.vancanciesRequests.high.research_areas.push(
                'Ciências (ambientais, animais e das plantas)'
              )
            }
            if (item.ciencias_da_computacao) {
              this.vancanciesRequests.high.research_areas.push(
                'Ciências da Computação'
              )
            }
            if (item.ciencias_planetarias_e_terrestres) {
              this.vancanciesRequests.high.research_areas.push(
                'Ciências Planetárias e terrestres, Matemática e Física'
              )
            }
            if (item.ciencias_sociais) {
              this.vancanciesRequests.high.research_areas.push(
                'Ciências Sociais, Comportamento e Arte'
              )
            }
            if (item.engenharia) {
              this.vancanciesRequests.high.research_areas.push(
                'Engenharia (Elétrica, Eletrônica, Mecânica)'
              )
            }
            if (item.engenharia_e_materiais) {
              this.vancanciesRequests.high.research_areas.push(
                'Engenharia e Materiais'
              )
            }
            if (item.gerenciamento_do_meio_ambiente) {
              this.vancanciesRequests.high.research_areas.push(
                'Gerenciamento do Meio Ambiente'
              )
            }
            if (item.medicina_e_saude) {
              this.vancanciesRequests.high.research_areas.push(
                'Medicina e Saúde'
              )
            }
            if (item.other && item.other.length) {
              this.vancanciesRequests.high.research_areas.push(
                `Outro: ${ item.other }`
              )
            }
          }
        })
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    checkVacanciesRequests() {
      if (
        this.vancanciesRequests.grade.quantity_registered !== 0
      ) {
        if (this.vancanciesRequests.grade.quantity_granted) {
          return this.$emit('vacanciesRequest', true)
        }
      }

      if (
        this.vancanciesRequests.elementary.quantity_registered !== 0
      ) {
        if (this.vancanciesRequests.elementary.quantity_granted) {
          return this.$emit('vacanciesRequest', true)
        }
      }

      // new level middle_cat_X
      if (
        this.vancanciesRequests.middleCat1.quantity_registered !== 0
      ) {
        if (this.vancanciesRequests.middleCat1.quantity_granted) {
          return this.$emit('vacanciesRequest', true)
        }
      }
      if (
        this.vancanciesRequests.middleCat2.quantity_registered !== 0
      ) {
        if (this.vancanciesRequests.middleCat2.quantity_granted) {
          return this.$emit('vacanciesRequest', true)
        }
      }
      if (
        this.vancanciesRequests.middleCat3.quantity_registered !== 0
      ) {
        if (this.vancanciesRequests.middleCat3.quantity_granted) {
          return this.$emit('vacanciesRequest', true)
        }
      }
      if (
        this.vancanciesRequests.middleCatEJA.quantity_registered !== 0
      ) {
        if (this.vancanciesRequests.middleCatEJA.quantity_granted) {
          return this.$emit('vacanciesRequest', true)
        }
      }
      // end new levels

      if (
        this.vancanciesRequests.high.quantity_registered !== 0
      ) {
        if (this.vancanciesRequests.high.quantity_granted) {
          return this.$emit('vacanciesRequest', true)
        }
        return this.$emit('vacanciesRequest', false)
      }
    },
    sendVacanciesRequestNumber() {
      const vacanciesRequestNumber = [
        {
          level: 'grade',
          quantity_registered:
          this.vancanciesRequests.grade.quantity_registered !== null
            ? this.vancanciesRequests.grade.quantity_registered
            : 0,
          quantity_granted:
            this.vancanciesRequests.grade.quantity_granted !== null
              ? Number(this.vancanciesRequests.grade.quantity_granted)
              : null,
          quantity_exempt:
            this.vancanciesRequests.grade.quantity_exempt !== null
              ? Number(this.vancanciesRequests.grade.quantity_exempt)
              : 0
        }, /*
        {
          level: 'middle',
          quantity_registered:
            this.vancanciesRequests.elementary.quantity_registered !== null
              ? this.vancanciesRequests.elementary.quantity_registered
              : 0,
          quantity_granted:
            this.vancanciesRequests.elementary.quantity_granted !== null
              ? Number(this.vancanciesRequests.elementary.quantity_granted)
              : null,
          quantity_exempt:
            this.vancanciesRequests.elementary.quantity_exempt !== null
              ? Number(this.vancanciesRequests.elementary.quantity_exempt)
              : 0
        }, */
        // new levels middle_cat_X
        {
          level: 'middle_cat_1',
          quantity_registered:
            this.vancanciesRequests.middleCat1.quantity_registered !== null
              ? this.vancanciesRequests.middleCat1.quantity_registered
              : 0,
          quantity_granted:
            this.vancanciesRequests.middleCat1.quantity_granted !== null
              ? Number(this.vancanciesRequests.middleCat1.quantity_granted)
              : null,
          quantity_exempt:
            this.vancanciesRequests.middleCat1.quantity_exempt !== null
              ? Number(this.vancanciesRequests.middleCat1.quantity_exempt)
              : 0
        },
        {
          level: 'middle_cat_2',
          quantity_registered:
            this.vancanciesRequests.middleCat2.quantity_registered !== null
              ? this.vancanciesRequests.middleCat2.quantity_registered
              : 0,
          quantity_granted:
            this.vancanciesRequests.middleCat2.quantity_granted !== null
              ? Number(this.vancanciesRequests.middleCat2.quantity_granted)
              : null,
          quantity_exempt:
            this.vancanciesRequests.middleCat2.quantity_exempt !== null
              ? Number(this.vancanciesRequests.middleCat2.quantity_exempt)
              : 0
        },
        {
          level: 'middle_cat_3',
          quantity_registered:
            this.vancanciesRequests.middleCat3.quantity_registered !== null
              ? this.vancanciesRequests.middleCat3.quantity_registered
              : 0,
          quantity_granted:
            this.vancanciesRequests.middleCat3.quantity_granted !== null
              ? Number(this.vancanciesRequests.middleCat3.quantity_granted)
              : null,
          quantity_exempt:
            this.vancanciesRequests.middleCat3.quantity_exempt !== null
              ? Number(this.vancanciesRequests.middleCat3.quantity_exempt)
              : 0
        },
        {
          level: 'middle_cat_eja',
          quantity_registered:
            this.vancanciesRequests.middleCatEJA.quantity_registered !== null
              ? this.vancanciesRequests.middleCatEJA.quantity_registered
              : 0,
          quantity_granted:
            this.vancanciesRequests.middleCatEJA.quantity_granted !== null
              ? Number(this.vancanciesRequests.middleCatEJA.quantity_granted)
              : null,
          quantity_exempt:
            this.vancanciesRequests.middleCatEJA.quantity_exempt !== null
              ? Number(this.vancanciesRequests.middleCatEJA.quantity_exempt)
              : 0
        },
        // end new levels
        {
          level: 'high',
          quantity_registered:
            this.vancanciesRequests.high.quantity_registered !== null
              ? this.vancanciesRequests.high.quantity_registered
              : 0,
          quantity_granted:
            this.vancanciesRequests.high.quantity_granted !== null
              ? Number(this.vancanciesRequests.high.quantity_granted)
              : null,
          quantity_exempt:
            this.vancanciesRequests.high.quantity_exempt !== null
              ? Number(this.vancanciesRequests.high.quantity_exempt)
              : 0
        }
      ]
      this.$emit('vacanciesRequestNumber', vacanciesRequestNumber)
    },
    checkIfDisableField() {
      return this.fairInfo.status === 'pendente de analise'
        || this.fairInfo.status === 'aguardando resposta'
        || this.fairInfo.status === 'resposta enviada'
        || !this.solicitationAccepted
    }
  }
}
</script>

<style lang="scss" scoped>
.top-space {
  margin-top: 16px;
}
.inner__wrapper {
  background: #fdfdfd;
  border-radius: 10px;
  padding-bottom: 10px;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 0px 22px 25px;

    .info__item {
      margin-top: 25px;

      .title {
        align-items: center;
        border-bottom: 0.5px solid #afaeae;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        padding-bottom: 5px;

        span:first-of-type {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: $almostBlack;
        }

        span:last-of-type {
          font-size: 16px;
          line-height: 20px;
          color: #686868;
        }
      }

      .areas {
        span:first-of-type {
          font-size: 16px;
          line-height: 20px;
          color: $almostBlack;
        }
        span:not(:last-of-type) {
          margin-right: 5px;
          margin-bottom: 10px;
        }

        .inputs {
          display: grid;
          grid-template-columns: minmax(180px, 250px) minmax(180px, 250px);

          label {
            font-size: 14px;
            font-weight: 600;
            margin-right: 30px;
          }
        }
      }
    }
  }
}
.warning {
  color: $attention;
  border-bottom-color: $attention;
}

.disableFields {
  filter: grayscale(100%) saturate(0%) opacity(70%);
}

.badge__info {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background: $primary;
  border-radius: 49px;
  opacity: 0.8;

  color: $white;
  font-size: 14px;
  height: 28px;
  padding: 0px 10px;
}
</style>
