<template>
  <div class="input__checkbox">
    <img v-if="index === 0" src="@/assets/blue_info_circle.png" alt class="info__icon">
    <span><label class="label" :for="id">{{$t(label)}} - {{$t(person_name)}}</label></span>
    <input type="checkbox" name="" :id="id"
      v-bind:checked="checked"
      @change="$emit('change', $event.target.checked)"
      class="accordion"
    />
    <icon-default
      class="header__icon"
      :url=cArrowIcon
      alt="Ícone da setinha"
      />
  </div>
</template>

<script>
import { IconDefault } from '@/components/atoms'

export default {
  name: 'InputCheckboxAccordion',
  components: { IconDefault },
  model: {
    prop: 'checked',
    event: 'change'
  },
  data() {
    return {
      component: 'icon-default'
    }
  },
  props: {
    label: String,
    person_name: String,
    type: String,
    id: String,
    placeholder: String,
    checked: Boolean,
    max: Number || String,
    disabled: Boolean,
    index: Number
  },
  computed: {
    cArrowIcon() {
      return this.checked ? 'blue_arrow_openned.png' : 'blue_arrow_closed.png'
    }
  }
}
</script>

<style lang="scss" scoped>

label{
  width: 500px;
  line-height: 27px;
  display: flex;
}

.header__icon{
  margin-right: 23px;
}

.info__icon{
  width: 20px;
  height: 20px;
  position: absolute;
}

.input__checkbox {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 97%;
  height: 73px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex: none;
  order: 4;
  flex-grow: 0;
  margin: 30px 0px;

  input[type='checkbox'] {
    zoom: 1.8;
    margin-top: 1.5px;
    position: absolute;
    width: 100%;
    height: 32px;
    opacity: 0;
  }

  span {
    color: $primary;
    flex: 1;
    padding-left: 22px;
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    margin-left: 30px;
    margin-right: 10px;
    max-width: 728px;
  }

}

.warning {
  span {
    color: $attention;
    border-bottom-color: $attention;
  }

}
</style>
