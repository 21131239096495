<template>
  <div :class="[{ wrapper: !border }, { wrapper_border: border }]">
    <heading-three v-if="title" class="info-title">
      {{title}}
    </heading-three>
      <div :class="[{tableWrapper: infos.length > 5}, {contentWrapper: infos.length <= 5}]">
        <div v-for="(item, index) in cInfos" :key="index">
          <DescriptionNumberPair
            :color="item.color"
            :text="item.text"
            :number="item.value"
          />
        </div>
      </div>
    </div>
</template>

<script>
import { DescriptionNumberPair } from '@/components/molecules'
import { HeadingThree } from '@/components/atoms/Texts'

export default {
  name: 'DescriptionNumberInfo',
  props: {
    infos: {
      type: [Object, Array],
      required: true
    },
    title: {
      type: String,
      required: false
    },
    border: {
      type: Boolean,
      required: false
    }
  },
  components: {
    DescriptionNumberPair,
    HeadingThree
  },
  computed: {
    cInfos() {
      return this.infos
    }
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  display: block;
  width: fit-content;
  margin: 0px 30px;
  &_border {
    margin-right: 60px;
    width: fit-content;
    //border-left: 1px solid #CDCBCB;
  }
}

.info-title {
  margin-left: 24px;
  margin-bottom: 8px;
}

.contentWrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: 20px;
  gap: 18px;
}
.tableAlign {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.teste {
  display: flex;
  flex-direction: column;
}

.tableWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: 20px;
  gap: 18px;
}
</style>
