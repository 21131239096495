<template>
  <button type="submit" @click.prevent="onClick" class="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonDefault',
  props: {
    onClick: {
      type: Function,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  background: $primary;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  cursor: pointer;
  height: 50px;
  max-width: 345px;
  outline: none;
  width: 100%;

  color: $white;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.92;
  }
}
</style>
