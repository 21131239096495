<template>
  <div class="lang">
    <template v-for="(lang, chave, index) in langs">
      <div
        :class="{ 'active': langSelected(chave)}"
        @click="selectedLang(chave)"
        class="selectable"
        :key="index"
      >
        {{ lang }}
        <img :src="require(`@/assets/shape.png`)" v-if="langSelected(chave)"/>
      </div>
    </template>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  name: 'lang',
  data() {
    return {
      langs: {
        pt: 'Português (PT-BR)',
        en: 'Inglês (EN-US)',
        es: 'Espanhol (ES-ES)'
      }
    }
  },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage'
    }),
    langSelected(lang) {
      return this.$store.getters.lang === lang
    },
    selectedLang(lang) {
      this.changeLang({ lang, i18n: this.$i18n })
    }
  }
}
</script>
<style lang="scss" scoped>
.lang {
  position: absolute;
  cursor: pointer;
  width: 369px;
  padding: 15px 26px;
  background: #FFFFFF;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  top: 52px;
  left: 10px;
  color: $primary;
  font-weight: normal;
  z-index: 1;
  @media screen and(max-width: 503px) {
    width: 215px;
    left: 0px;
  }
  @media screen and(max-width: 281px) {
    width: 215px;
    left: -60px;
  }
  .active {
    font-weight: bold;
  }
  :hover {
    opacity: .7;
  }
}
</style>
