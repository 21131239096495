<template>
  <div class="wrapper">
    <ImageInfo
      url='grey_pdf.png'
      :infos='docs'
      :title="$t('banner')"
      :full_width='false'
      :editable='true'
      :reference="'banner'"
      :type="'pdf'"
      :limitSize="100000000"
      @updateFileData="updateFileData"
      @urlValidation="handleURLValidation"
      @emitLoading="handleLoading"
    />
    <ImageInfo
      url='grey_mp4.png'
      textInfo="presentationVideoInformation"
      :infos='presentation'
      :title="$t('presentationVideo')"
      :full_width='false'
      :editable='true'
      :reference="'video'"
      :type="'mp4'"
      :limitSize="300000000"
      @updateFileData="updateFileData"
      @urlValidation="handleURLValidation"
      @emitLoading="handleLoading"
    />
    <div class="footer">
      <button-default
        :class="[
          {disabled: isLoading},
          'button'
        ]"
        :onClick="saveChanges"
      >
        {{$t('save')}}
      </button-default>
    </div>
    <feedback-warning
      v-if="isRequestSuccesful"
      text="succesfullySaved"
      :onClick="resetSuccesfulStatus"
      color="#33b850"
    />
    <feedback-warning
      v-if="errors.length"
      color="#ff9800"
      :text="textWarning"
      :onClick="resetErrors"
    />
  </div>
</template>

<script>

import { ImageInfo } from '@/components/organisms'
import { ButtonDefault, FeedbackWarning, TextWarning } from '@/components/atoms'
import checkDeadline from '@/utils/checkDeadline.js'

export default {
  name: 'Documents',
  components: {
    ImageInfo, ButtonDefault, FeedbackWarning, TextWarning
  },
  props: [
    'projectData'
  ],
  data() {
    return {
      // Docs é um array de obj que pega os arquivos obrigatórios do banco
      docs: [],
      // Presentation é um array de obj que pega os arquivos de vídeo
      presentation: [],
      isRequestSuccesful: false,
      errors: [],
      textWarning: '',
      isLoading: false,
      mostratec: {}
    }
  },
  mounted() {
    this.loadFiles()
    this.getMostratec()
  },
  watch: {
    projectData() {
      this.loadFiles()
    }
  },
  methods: {
    loadFiles() {
      this.docs = []
      this.presentation = []
      if (this.projectData) {
        const { files, presentation_link } = this.projectData
        const banner = Object.entries(files).find((file) => file[0] === 'banner')
        this.docs.push({
          text: banner[0],
          value: {
            ...banner[1]
          }
        })
        const video = Object.entries(files).find((file) => file[0] === 'video')
        this.presentation.push({
          text: this.$t('videoLink'),
          value: {
            video: presentation_link
          }
        })
      }
    },
    async getMostratec() {
      try {
        const { data: response } = await this.$http.get('/current-mostratec')
        this.mostratec = response
      } catch (error) {
        console.error(error)
      }
    },
    updateFileData(reference, data) {
      if (reference === 'banner') this.docs[0].value = data
      else this.presentation[0].value = data
    },
    async saveChanges() {
      if (this.projectData.fair) {
        if (!checkDeadline(this.mostratec.project_date_end)) {
          this.errors.push('past deadline')
          this.textWarning = 'Prazo de inscrição encerrado.'
          return
        }
      } else if (!checkDeadline(this.mostratec.project_selection_date_end)) {
        this.errors.push('past deadline')
        this.textWarning = 'Prazo de inscrição encerrado.'
        return
      }

      if (this.textWarning === 'inputValidUrl') {
        this.errors.push('url')
        return null
      }

      if (this.isLoading) {
        return null
      }

      const newFileData = {
        ...this.projectData.files,
        video: this.presentation[0].value,
        banner: this.docs[0].value
      }

      const { value: { video: presentation_link } } = this.presentation[0]

      const updatedProject = {
        files: newFileData,
        presentation_link
      }

      const updateFiles = await this.$http.patch(
        `/project/${ this.projectData.id }/update`,
        updatedProject
      )

      if (updateFiles?.status === 200) {
        this.isRequestSuccesful = true
        this.$store.commit('SELECTED_PROJECT', { ...this.projectData, ...updatedProject })
        this.$store.commit('UPDATE_PROJECT_LIST', { ...this.projectData, ...updatedProject })
      } else {
        this.errors.push('not ok')
        this.textWarning = 'warningTryLater'
      }
      return updateFiles
    },
    resetSuccesfulStatus() {
      this.isRequestSuccesful = false
    },
    resetErrors() {
      this.errors = []
    },
    handleURLValidation(e) {
      if (!e && !this.errors.includes('url')) {
        this.errors.push('url')
        this.textWarning = 'inputValidUrl'
      } else {
        this.errors = this.errors.filter((err) => err !== 'url')
        this.textWarning = ''
      }
    },
    handleLoading(e) {
      this.isLoading = e
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #FDFDFD;
  .footer {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    margin-top: 50px;
    margin-bottom:20px;
  }
}
</style>
