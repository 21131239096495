<template>
  <div class="info-wrapper">
    <GrayLineTitle
      :title ="title || ''"
    />
    <div class="form_wrapper">
      <div
        :class="[{form_container: full_width && noWrap},{form_component: full_width && !noWrap}, {info_component: !full_width}, 'mb-5']"
        v-for="(item, index) in infos"
        :key="index"
      >
      <Information
        :title="item.text"
        :list="item.value"
        :color="color"
        :content='noWrap ? item.value : item.text'
        :isBadge="item.value.sub_areas || item.value.area ? true : false"
        :isArea ="item.value.area ? true : false"
      />
      </div>
    </div>
  </div>
</template>

<script>
import { Information, GrayLineTitle } from '@/components/molecules'

export default {
  name: 'InfoDefault',
  components: { Information, GrayLineTitle },
  props: {
    infos: {
      type: Array,
      required: true
    },
    full_width: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    noWrap: {
      type: Boolean,
      required: false
    },
    schoolFairName: {
      type: String,
      required: false
    }
  },
  methods: {
    checkBadge(infos) {
      return (infos === this.$t('researchAreaSingle') || infos === this.$t('researchSubAreas'))
    },
    checkArea(infos) {
      return (infos === this.$t('researchAreaSingle'))
    }
  }
}
</script>

<style lang="scss" scoped>

info-wrapper {
  display: flex;
  flex-direction: column;
}

.info_component{
  width: 40%;
}

.form_component{
  width: 100%;
}

.form_wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 5px;
}

.form_container {
  display: flex;
  justify-content: space-evenly;
}
</style>
