var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      {
        disableFields:
          _vm.fairInfo.status === 'pendente de analise' ||
          _vm.fairInfo.status === 'aguardando resposta' ||
          _vm.fairInfo.status === 'resposta enviada'
      },
      'inner__wrapper'
    ]},[_vm._m(0),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Mostratec Júnior: Educação Infantil")]),_c('span',[_vm._v("Vagas solicitadas: "+_vm._s(_vm.vancanciesRequests.grade.quantity_registered))])]),_c('div',{staticClass:"areas"},[_c('div',{staticClass:"inputs"},[_c('inputDefault',{class:[{
                disableFields: _vm.vancanciesRequests.high.quantity_registered === 0
                  || !_vm.solicitationAccepted,
                warning: _vm.checkErrorIfContains('grade - granted')
              }],attrs:{"disabled":_vm.checkIfDisableField(),"type":"number","label":"Vagas concedidas","placeholder":"Insira o número de vagas"},model:{value:(_vm.vancanciesRequests.grade.quantity_granted),callback:function ($$v) {_vm.$set(_vm.vancanciesRequests.grade, "quantity_granted", $$v)},expression:"vancanciesRequests.grade.quantity_granted"}})],1)])]),_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Mostratec Júnior:"),_c('br'),_vm._v(_vm._s(_vm.$t('elementarySchoolCategory1')))]),_c('span',[_vm._v("Vagas solicitadas: "+_vm._s(_vm.vancanciesRequests.middleCat1.quantity_registered))])]),_c('div',{staticClass:"areas"},[_c('div',{staticClass:"inputs"},[_c('inputDefault',{class:[{
                disableFields: _vm.vancanciesRequests.middleCat1.quantity_registered === 0
                  || !_vm.solicitationAccepted,
                warning: _vm.checkErrorIfContains('middle - granted')
              }],attrs:{"disabled":_vm.checkIfDisableField(),"type":"number","label":"Vagas concedidas","placeholder":"Insira o número de vagas"},model:{value:(_vm.vancanciesRequests.middleCat1.quantity_granted),callback:function ($$v) {_vm.$set(_vm.vancanciesRequests.middleCat1, "quantity_granted", $$v)},expression:"vancanciesRequests.middleCat1.quantity_granted"}})],1)])]),_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Mostratec Júnior:"),_c('br'),_vm._v(_vm._s(_vm.$t('elementarySchoolCategory2')))]),_c('span',[_vm._v("Vagas solicitadas: "+_vm._s(_vm.vancanciesRequests.middleCat2.quantity_registered))])]),_c('div',{staticClass:"areas"},[_c('div',{staticClass:"inputs"},[_c('inputDefault',{class:[{
                disableFields: _vm.vancanciesRequests.middleCat2.quantity_registered === 0
                  || !_vm.solicitationAccepted,
                warning: _vm.checkErrorIfContains('middle - granted')
              }],attrs:{"disabled":_vm.checkIfDisableField(),"type":"number","label":"Vagas concedidas","placeholder":"Insira o número de vagas"},model:{value:(_vm.vancanciesRequests.middleCat2.quantity_granted),callback:function ($$v) {_vm.$set(_vm.vancanciesRequests.middleCat2, "quantity_granted", $$v)},expression:"vancanciesRequests.middleCat2.quantity_granted"}})],1)])]),_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Mostratec Júnior:"),_c('br'),_vm._v(_vm._s(_vm.$t('elementarySchoolCategory3')))]),_c('span',[_vm._v("Vagas solicitadas: "+_vm._s(_vm.vancanciesRequests.middleCat3.quantity_registered))])]),_c('div',{staticClass:"areas"},[_c('div',{staticClass:"inputs"},[_c('inputDefault',{class:[{
                disableFields: _vm.vancanciesRequests.middleCat3.quantity_registered === 0
                  || !_vm.solicitationAccepted,
                warning: _vm.checkErrorIfContains('middle - granted')
              }],attrs:{"disabled":_vm.checkIfDisableField(),"type":"number","label":"Vagas concedidas","placeholder":"Insira o número de vagas"},model:{value:(_vm.vancanciesRequests.middleCat3.quantity_granted),callback:function ($$v) {_vm.$set(_vm.vancanciesRequests.middleCat3, "quantity_granted", $$v)},expression:"vancanciesRequests.middleCat3.quantity_granted"}})],1)])]),_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Mostratec Júnior:"),_c('br'),_vm._v(_vm._s(_vm.$t('elementarySchoolCategoryEja')))]),_c('span',[_vm._v("Vagas solicitadas: "+_vm._s(_vm.vancanciesRequests.middleCatEJA.quantity_registered))])]),_c('div',{staticClass:"areas"},[_c('div',{staticClass:"inputs"},[_c('inputDefault',{class:[{
                disableFields: _vm.vancanciesRequests.middleCatEJA.quantity_registered === 0
                  || !_vm.solicitationAccepted,
                warning: _vm.checkErrorIfContains('middle - granted')
              }],attrs:{"disabled":_vm.checkIfDisableField(),"type":"number","label":"Vagas concedidas","placeholder":"Insira o número de vagas"},model:{value:(_vm.vancanciesRequests.middleCatEJA.quantity_granted),callback:function ($$v) {_vm.$set(_vm.vancanciesRequests.middleCatEJA, "quantity_granted", $$v)},expression:"vancanciesRequests.middleCatEJA.quantity_granted"}})],1)])]),_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Mostratec: Ensino médio/técnico")]),_c('span',[_vm._v("Vagas solicitadas: "+_vm._s(_vm.vancanciesRequests.high.quantity_registered))])]),_c('div',{staticClass:"areas"},[_c('div',{staticClass:"inputs"},[_c('inputDefault',{class:[{
                disableFields: _vm.vancanciesRequests.high.quantity_registered === 0
                  || !_vm.solicitationAccepted,
                warning: _vm.checkErrorIfContains('high - granted')
              }],attrs:{"disabled":_vm.checkIfDisableField(),"type":"number","label":"Vagas concedidas","placeholder":"Insira o número de vagas"},model:{value:(_vm.vancanciesRequests.high.quantity_granted),callback:function ($$v) {_vm.$set(_vm.vancanciesRequests.high, "quantity_granted", $$v)},expression:"vancanciesRequests.high.quantity_granted"}})],1)])])])]),(_vm.fairInfo.status === 'nao aceita')?[_c('div',{staticClass:"inner__wrapper mb-6 top-space"},[_c('div',{staticClass:"header"},[_c('h3',[_vm._v(_vm._s(_vm.$t('JustificationLabel')))])]),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"info__item"},[_c('span',[_vm._v(_vm._s(_vm.fairInfo.justification))])])])])]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h3',[_vm._v("Concessão de vagas - Painel Administrativo")])])}]

export { render, staticRenderFns }