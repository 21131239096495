<template>
  <div class="app-wrapper">
    <header-default @menuSelected="menuSelected"  />
    <div class="container-app">
      <elementary-projects-info @navSelected="(value) => navSelected(value)" :projectInfo="projectInfo" class="left"/>

      <transition>
        <component :is="component" :projectInfo="projectInfo" />
      </transition>
    </div>
  </div>
</template>

<script>
import { HeaderDefault } from '@/components/organisms'
import {
  ProjectsKindergartenStudents, ProjectsKindergartenInstitution, ProjectsKindergartenContract, ProjectsKindergartenInvolveds, ProjectsKindergartenRevision, ProjectsKindergarten
} from '@/components/templates'

export default {
  name: 'KindergartenProjectDetails',
  components: {
    HeaderDefault, ProjectsKindergartenStudents, ProjectsKindergartenInstitution, ProjectsKindergartenContract, ProjectsKindergartenInvolveds, ProjectsKindergartenRevision, ProjectsKindergarten
  },
  data: () => ({
    tabs: {
      revision: 'projects-kindergarten-revision',
      institution: 'projects-kindergarten-institution',
      students: 'projects-kindergarten-students',
      contract: 'projects-kindergarten-contract',
      involveds: 'projects-kindergarten-involveds',
      projects: 'projects-kindergarten'
    },
    component: 'projects-kindergarten-revision',
    projectInfo: {}
  }),
  created() {
    this.getInfos()
  },
  methods: {
    menuSelected(e) {
      this.fields = e
    },
    navSelected(value) {
      this.component = this.tabs[value]
      //console.log(this.component)
    },
    async getInfos() {
      const { data: response } = await this.$http.get(`/project/${ this.$route.params.id }`)
      //console.log('response: ', response)
      this.projectInfo = response
      //console.log('teste: ', this.projectInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  .container-app{
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 16px;

    width: 101.5%;
    margin-left: -20px;
    min-height: 100vh;

    .left {
      align-self: stretch;
    }
  }
}
</style>
