<template>
  <div @click="open = !open" v-if="open" class="modal__outer">
    <div class="modal__inner">
      <div class="lds-dual-ring"></div>
      <p>{{$t('loading')}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalLoading',
  props: ['modal'],
  data: () => ({
    open: false
  }),
  watch: {
    modal() {
      this.open = this.modal
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  @include d-flex-center;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;

  .modal__inner {
    @include d-flex-center;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-height: 221px;
    padding: 25px;
    width: 300px;
  }
}

.lds-dual-ring {
  display: inline-block;
  height: 80px;
  margin-bottom: 30px;
  width: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid $primary;
  border-color: $primary transparent $primary transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
