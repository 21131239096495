import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'
import ptMessages from './locales/pt.js'
import enMessages from './locales/en.js'
import esMessages from './locales/es.js'

Vue.use(VueI18n)

const { lang } = store.getters

const locals = {
  pt: ['pt', ptMessages],
  en: ['en', enMessages],
  es: ['es', esMessages]
}

const i18n = new VueI18n({
  locale: locals[lang][0],
  messages: locals[lang][1],
  silentFallbackWarn: true,
  silentTranslationWarn: true
})

export default i18n
