<template>
  <div class="home">
    <form-selection-template />
  </div>
</template>

<script>
import { FormSelectionTemplate } from '@/components/templates'

export default {
  name: 'FormSelectionView',
  components: {
    FormSelectionTemplate
  }
}
</script>
