const tShirtSizes = {
  6: '6',
  8: '8',
  10: '10',
  12: '12',
  14: '14',
  p: 'P',
  m: 'M',
  g: 'G',
  gg: 'GG',
  no: 'notApplicable'
}

export default tShirtSizes
