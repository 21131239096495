<template>
  <div class="container-app">
    <div class="wrapper">
      <header-form title="preRegistration" subtitle="affiliatedFairSingle" />
      <paragraph class="paragraph">
        {{$t('initialRegisterHeader1')}}
        {{$t('initialRegisterHeader2')}}
        {{$t('initialRegisterHeader3')}}
        {{$t('initialRegisterHeader4')}}
        {{$t('initialRegisterHeader5')}}
        {{$t('initialRegisterHeader6')}}
      </paragraph>
      <paragraph class="paragraphRed">
        {{$t('warningForMultiple')}}
      </paragraph>
      <form-register @openModal="openModal" />
    </div>
    <modal-loading :modal="loading" />
  </div>
</template>

<script>
import { Paragraph } from '@/components/atoms'
import { HeaderForm, FormRegister, ModalLoading } from '@/components/organisms'

export default {
  name: 'InitialRegister',
  components: {
    HeaderForm,
    Paragraph,
    FormRegister,
    ModalLoading
  },
  data() {
    return {
      modal: false,
      loading: false
    }
  },
  async mounted() {
    const statusRegistration = await this.getCheckRegistration()
    if (!statusRegistration.fair) {
      this.$router.push('/')
    }
  },
  methods: {
    openModal(type, value) {
      this[type] = value
    },
    toggleModal() {
      this.modal = !this.modal
    },
    async getCheckRegistration() {
      const { data } = await this.$http.get('/check-register')
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
$imgUrl: "../../assets/background-login.png";

.container-app {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
    url($imgUrl);
  background-size: contain;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;

  .wrapper {
    background-color: #fff;
    opacity: 0.9;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 649px;
    padding: 25px 28px;
  }
}

.paragraph {
  margin: 25px 0px 15px 0px;
  text-align: left;
}

.paragraphRed {
  color: red;
  margin: 25px 0px 15px 0px;
  text-align: left;
}

</style>
