<template>
  <main class="mt-4 main_align">
    <div class="mb-5" v-for="(info, index) in form.infos" :key="index">
      <input-checkbox-accordion
        :class="['mb-7', 'accordion_size']"
        :label="'Estudante ' + String(Number(index + 1))"
        :person_name="form.infos[index].full_name"
        :index="index"
        v-model="form.infos[index].active"
      />
      <div v-if="form.infos[index].active">
        <div class="content">
          <div :class="index === 0 ? 'edit-and-paragrafh' : 'only_edit'">
            <p v-if="index == 0" class="mainStudent_description">
              Estudante responsável pelo pré-cadastro e pela inscrição do
              projeto.
            </p>
            <div v-if="onlyAdmin === 'admin'">
              <button class="button_edit" v-if="!open" @click="open = !open">
                EDITAR
              </button>
            </div>
          </div>
          <div class="contact_info">
            <div class="data__left">
              <div class="data__row">
                <h4>Nome Completo</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('full_name' + index) },
                    'input__space',
                  ]"
                  type="text"
                  v-model="form.infos[index].full_name"
                />
                <span v-else>{{ form.infos[index].full_name }}</span>
              </div>
              <div class="data__row">
                <h4>Sexo</h4>
                <RadioGroup
                  v-if="open"
                  :class="['input__space']"
                  :options="genderOptions"
                  v-model="form.infos[index].sex"
                />

                <span v-else>{{
                  form.infos[index].sex == "male"
                    ? $t("maleSingle")
                    : $t("femaleSingle")
                }}</span>
              </div>
              <div
                class="data__row"
                v-if="
                  validateIfBigger(form.infos[index].birth_date, index) ||
                  form.infos[index].isBetter
                "
              >
                <h4>Nome do(a) Responsável</h4>
                <input-default
                  v-if="open"
                  :class="[
                    {
                      warning: checkErrorIfContains('responsible_name' + index),
                    },
                    'input__space',
                  ]"
                  type="text"
                  max="150"
                  v-model="form.infos[index].responsible_name"
                />
                <span v-else>{{ form.infos[index].responsible_name }}</span>
              </div>
              <div class="data__row">
                <h4>Telefone (fixo ou celular)</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('phone' + index) },
                    'input__space',
                  ]"
                  id="phone"
                  placeholder="(00) 00000-0000"
                  type="text"
                  v-model="form.infos[index].phone"
                />
                <span v-else>{{ form.infos[index].phone }}</span>
              </div>
            </div>
            <div class="data__right">
              <div class="data__row">
                <h4>CPF</h4>
                <div v-if="open">
                  <input-masked
                    v-if="
                      !form.infos[index].country ||
                      form.infos[index].country == 'Brazil'
                    "
                    :class="[
                      { warning: checkErrorIfContains('cpf' + index) },
                      'input__space',
                    ]"
                    id="codigo"
                    placeholder="xxx.xxx.xxx-xx"
                    type="text"
                    v-model="form.infos[index].cpf"
                    :mask="['###.###.###-##']"
                  />

                  <input-default
                    v-else
                    :class="[
                      { warning: checkErrorIfContains('cpf' + index) },
                      'input__space',
                    ]"
                    id="codigo"
                    placeholder="xxx.xxx.xxx-xx"
                    type="text"
                    v-model="form.infos[index].cpf"
                  />
                </div>
                <span v-else>{{ form.infos[index].cpf }}</span>
              </div>
              <div class="data__row">
                <h4>Data de nascimento</h4>
                <input-masked
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('birth_date' + index) },
                    'input__space',
                  ]"
                  id="birthday"
                  placeholder="dd/mm/aaaa"
                  type="text"
                  v-model="form.infos[index].birth_date"
                  :mask="['##/##/####']"
                />
                <span v-else>{{ form.infos[index].birth_date }}</span>
              </div>
              <div
                class="data__row"
                v-if="
                  validateIfBigger(form.infos[index].birth_date, index) ||
                  form.infos[index].isBetter
                "
              >
                <h4>Parentesco do(a) Responsável</h4>
                <input-default
                  v-if="open"
                  :class="[
                    {
                      warning: checkErrorIfContains(
                        'responsible_kinship' + index
                      ),
                    },
                    'input__space',
                  ]"
                  type="text"
                  max="150"
                  v-model="form.infos[index].responsible_kinship"
                />
                <span v-else>{{ form.infos[index].responsible_kinship }}</span>
              </div>
              <div class="data__row">
                <h4>Email</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('email' + index) },
                    'input__space',
                  ]"
                  id="email"
                  placeholder="enterTheEmail"
                  type="text"
                  v-model="form.infos[index].email"
                />
                <span v-else>{{ form.infos[index].email }}</span>
              </div>
            </div>
          </div>
          <div class="content_title">Endereço</div>
          <div class="separator"></div>
          <div class="address_info">
            <div class="data__left">
              <div class="data__row">
                <h4>País</h4>
                <input-select-location
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('country' + index) },
                    'input__space',
                  ]"
                  id="country"
                  :items="countries"
                  v-model="form.infos[index].country"
                  :selected="form.infos[index].country"
                />
                <span v-else>{{ form.infos[index].country }}</span>
              </div>
              <div class="data__row">
                <div>
                  <h4>{{ $t("uf") }}</h4>
                </div>
                <div v-if="open">
                  <input-select
                    v-if="form.infos[index].country === 'Brazil'"
                    :class="[
                      { warning: checkErrorIfContains('state' + index) },
                      'input__space',
                    ]"
                    id="serie"
                    :items="states"
                    type="text"
                    v-model="form.infos[index].state"
                  />
                  <input-default
                    v-if="
                      form.infos[index].country &&
                      form.infos[index].country !== 'Brazil'
                    "
                    :class="[
                      { warning: checkErrorIfContains('state' + index) },
                      'input__space',
                    ]"
                    id="state"
                    placeholder="statePlaceholder"
                    type="text"
                    v-model="form.infos[index].state"
                  />
                </div>
                <span v-else>{{ form.infos[index].state }}</span>
              </div>
              <div
                v-if="form.infos[index].country === 'Brazil'"
                class="data__row"
              >
                <h4>Endereço</h4>
                <input-default
                  v-if="open && form.infos[index].state"
                  :class="[
                    { warning: checkErrorIfContains('street' + index) },
                    'input__space',
                  ]"
                  id="address"
                  type="text"
                  max="150"
                  v-model="form.infos[index].street"
                />
                <span v-else>{{ form.infos[index].street }}</span>
              </div>
              <div
                v-if="form.infos[index].country === 'Brazil'"
                class="data__row"
              >
                <h4>Bairro</h4>
                <input-default
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('district' + index) },
                    'input__space',
                  ]"
                  id="neighborhood"
                  type="text"
                  max="100"
                  v-model="form.infos[index].district"
                />
                <span v-else>{{ form.infos[index].district }}</span>
              </div>
            </div>
            <div class="data__right">
              <div
                v-if="form.infos[index].country === 'Brazil'"
                class="data__row"
              >
                <h4>CEP</h4>
                <input-masked
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('zipcode' + index) },
                    'input__space',
                  ]"
                  required
                  id="zipCode"
                  type="text"
                  v-model="form.infos[index].zipcode"
                  :mask="['#####-###']"
                />
                <span v-else>{{ form.infos[index].zipcode }}</span>
              </div>
              <div class="data__row">
                <h4>{{ $t("city") }}</h4>
                <div v-if="open">
                  <input-select
                    v-if="form.infos[index].country === 'Brazil'"
                    :class="[
                      { warning: checkErrorIfContains('city' + index) },
                      'input__space',
                    ]"
                    id="serie"
                    :items="cities"
                    v-model="form.infos[index].city"
                    :selected="form.infos[index].city"
                  />
                  <input-default
                    v-if="form.infos[index].country !== 'Brazil'"
                    :class="['input__space']"
                    id="serie"
                    type="text"
                    v-model="form.infos[index].city"
                  />
                </div>
                <span v-else>{{
                  coalesceInformation(form.infos[index].city)
                }}</span>
              </div>
              <div
                v-if="form.infos[index].country === 'Brazil'"
                class="data__row"
              >
                <h4>Número</h4>
                <input-default
                  v-if="open && form.infos[index].state"
                  :class="[
                    { warning: checkErrorIfContains('house_number' + index) },
                    'input__space',
                  ]"
                  id="house_number"
                  type="text"
                  max="20"
                  v-model="form.infos[index].house_number"
                />
                <span v-else>{{
                  coalesceInformation(form.infos[index].house_number)
                }}</span>
              </div>
              <div
                v-if="form.infos[index].country === 'Brazil'"
                class="data__row"
              >
                <h4>Complemento</h4>
                <input-default
                  v-if="open"
                  :class="['input__space']"
                  id="complement"
                  type="text"
                  max="100"
                  v-model="form.infos[index].complement"
                />
                <span v-else>{{
                  coalesceInformation(form.infos[index].complement)
                }}</span>
              </div>
            </div>
          </div>

          <div class="content_title">Dados de Inscrição</div>
          <div class="separator"></div>
          <div class="inscription_info">
            <div class="data__left">
              <div v-if="level === 'high'" class="data__row">
                <h4>Série</h4>
                <input-select
                  v-if="open"
                  :class="['input__space']"
                  id="educational_level"
                  :items="serieOptions"
                  v-model="form.infos[index].educational_level"
                  :selected="form.infos[index].educational_level"
                />
                <span v-else>
                  {{
                    $t(educationalLevels[form.infos[index].educational_level])
                  }}
                </span>
              </div>
              <div v-if="level === 'high'" class="data__row">
                <h4>
                  É bolsista de algum programa de iniciação científica júnior?
                </h4>
                <RadioGroup
                  @change="deleteInputWhenNoShow(index)"
                  v-if="open"
                  :class="[
                    {
                      warning: checkErrorIfContains(
                        'scholarship_holder' + index
                      ),
                    },
                    'mb-2 title_checkbox',
                  ]"
                  :options="generalOptions"
                  v-model="form.infos[index].scholarship_holder"
                />
                <span v-else>
                  {{
                    form.infos[index].scholarship_holder === "yes"
                      ? "Sim"
                      : "Não"
                  }}
                </span>
              </div>
              <div class="data__row">
                <h4>Já participou de alguma feira de ciências?</h4>
                <div v-if="open">
                  <RadioGroup
                    :class="[
                      { warning: checkErrorIfContains('fair_science' + index) },
                      'mb-2 title_checkbox',
                    ]"
                    :options="attendOptions"
                    v-model="form.infos[index].fair_science"
                  />
                  <input-default
                    v-if="form.infos[index].fair_science === 'other' && open"
                    :class="[
                      {
                        warning: checkErrorIfContains(
                          'attendOtherFairs' + index
                        ),
                      },
                      'input__space mt-0',
                    ]"
                    id="city"
                    type="text"
                    max="150"
                    v-model="form.infos[index].attendOtherFairs"
                  />
                </div>
                <span v-else>
                  {{
                    $t(scienceFairs[form.infos[index].fair_science]) ||
                    form.infos[index].attendOtherFairs
                  }}
                </span>
              </div>
              <div class="data__row levels">
                <h4
                  :class="[
                    {
                      warning: checkErrorIfContains('special_service' + index),
                    },
                  ]"
                >
                  Necessitará de algum atendimento especializado?
                </h4>
                <div v-if="open">
                  <label>
                    <input
                      @change="validCheckoboxGroup(form.infos[index], index)"
                      value="nao"
                      v-model="form.infos[index].special_service"
                      type="checkbox"
                    />{{ $t("none") }}
                  </label>
                  <label>
                    <input
                      value="acessibilidade"
                      v-model="form.infos[index].special_service"
                      type="checkbox"
                      :disabled="
                        form.infos[index].special_service.includes('nao')
                      "
                    />{{ $t("architecturalAccessibility") }}
                  </label>
                  <label>
                    <input
                      value="libras"
                      v-model="form.infos[index].special_service"
                      type="checkbox"
                      :disabled="
                        form.infos[index].special_service.includes('nao')
                      "
                    />{{ $t("libraInterpreter") }}
                  </label>
                  <label>
                    <input
                      value="ledor"
                      v-model="form.infos[index].special_service"
                      type="checkbox"
                      :disabled="
                        form.infos[index].special_service.includes('nao')
                      "
                    />{{ $t("reader") }}
                  </label>
                  <label>
                    <input
                      value="outro"
                      v-model="form.infos[index].special_service"
                      type="checkbox"
                      :disabled="
                        form.infos[index].special_service.includes('nao')
                      "
                    />{{ $t("othersMale") }}
                  </label>
                  <input-default
                    v-if="form.infos[index].special_service.includes('outro')"
                    :class="[
                      {
                        warning: checkErrorIfContains(
                          'otherSpecialService' + index
                        ),
                      },
                      'mt-0',
                    ]"
                    id="city"
                    placeholder="enterTheSpecializedService"
                    type="text"
                    max="150"
                    v-model="form.infos[index].otherSpecialService"
                    @input="resetErrors"
                  />
                </div>
                <span v-else>
                  {{ serviceListPicker(form.infos[index].special_service_values).join(', ') }}
                </span>
              </div>
            </div>
            <div class="data__right">
              <div v-if="level === 'high'" class="data__row">
                <h4>Nível de conhecimento em língua inglesa</h4>
                <input-select
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('english_level' + index) },
                    'input__space',
                  ]"
                  id="englishLevel"
                  :items="englishOptions"
                  v-model="form.infos[index].english_level"
                  :selected="form.infos[index].english_level"
                />
                <span v-else>
                  {{ $t(englishLevels[form.infos[index].english_level]) }}
                </span>
              </div>
              <div v-if="level === 'high'">
                <div
                  class="data__row"
                  v-if="form.infos[index].scholarship_holder === 'yes'"
                >
                  <h4
                    :class="[
                      {
                        warning: checkErrorIfContains('which_program' + index),
                      },
                    ]"
                  >
                    Qual programa?
                  </h4>
                  <div v-if="open">
                    <RadioGroup
                      v-if="form.infos[index].scholarship_holder == 'yes'"
                      :options="programOptions"
                      v-model="form.infos[index].which_program"
                      @change="resetErrors"
                    />
                    <input-default
                      v-if="
                        form.infos[index].which_program === 'other' &&
                        form.infos[index].scholarship_holder === 'yes'
                      "
                      :class="[
                        {
                          warning: checkErrorIfContains(
                            'other_program' + index
                          ),
                        },
                        'input__space mt-0',
                      ]"
                      id="city"
                      type="text"
                      max="150"
                      v-model="form.infos[index].other_program"
                    />
                  </div>
                  <span v-else>
                    {{
                      $t(
                        form.infos[index].other_program ||
                          scholarshipHolderPrograms[
                            form.infos[index].which_program
                          ]
                      )
                    }}
                  </span>
                </div>
              </div>
              <div class="data__row levels">
                <h4
                  :class="[
                    { warning: checkErrorIfContains('deficiency' + index) },
                  ]"
                >
                  Possui algum tipo de deficiência?
                </h4>
                <div v-if="open">
                  <label>
                    <input
                      @change="validCheckoboxGroup(form.infos[index], index)"
                      value="nao"
                      v-model="form.infos[index].deficiency"
                      type="checkbox"
                    />{{ $t("none") }}
                  </label>
                  <label>
                    <input
                      value="fisica"
                      v-model="form.infos[index].deficiency"
                      type="checkbox"
                      :disabled="form.infos[index].deficiency.includes('nao')"
                    />{{ $t("fis") }}
                  </label>
                  <label>
                    <input
                      value="intelectual"
                      v-model="form.infos[index].deficiency"
                      type="checkbox"
                      :disabled="form.infos[index].deficiency.includes('nao')"
                    />{{ $t("intellectual") }}
                  </label>
                  <label>
                    <input
                      value="auditiva"
                      v-model="form.infos[index].deficiency"
                      type="checkbox"
                      :disabled="form.infos[index].deficiency.includes('nao')"
                    />{{ $t("auditory") }}
                  </label>
                  <label>
                    <input
                      value="visual"
                      v-model="form.infos[index].deficiency"
                      type="checkbox"
                      :disabled="form.infos[index].deficiency.includes('nao')"
                    />{{ $t("visual") }}
                  </label>
                </div>
                <span v-else>
                  {{ deficiencyListPicker(form.infos[index].deficiency).join(', ') }}
                </span>
              </div>
              <div class="data__row">
                <h4>Opta por qual tipo de camiseta?</h4>
                <input-select
                  v-if="open"
                  :class="[
                    { warning: checkErrorIfContains('t_shirt' + index) },
                    'input__space',
                  ]"
                  id="t_shirt"
                  :items="typeShirtOptions"
                  v-model="form.infos[index].t_shirt"
                />
                <span v-else>
                  {{ $t(tShirtTypes[form.infos[index].t_shirt]) }}
                </span>
              </div>
              <div class="data__row" v-if="form.infos[index].t_shirt !== 'no'">
                <h4>Qual o tamanho da camiseta?</h4>
                <div v-if="open">
                  <input-select
                    v-if="level === 'high'"
                    :class="[
                      { warning: checkErrorIfContains('t_shirt_size' + index) },
                      'input__space',
                    ]"
                    :items="sizeShirtOptionsHigh"
                    v-model="form.infos[index].t_shirt_size"
                  />
                  <input-select
                    v-else
                    :class="[
                      { warning: checkErrorIfContains('t_shirt_size' + index) },
                      'input__space',
                    ]"
                    :items="sizeShirtOptions"
                    v-model="form.infos[index].t_shirt_size"
                  />
                </div>
                <span v-else>
                  {{ $t(tShirtSizes[form.infos[index].t_shirt_size]) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-vacancies-cancel-and-save">
          <button class="button_cancel" v-if="open" @click="cancelSave">
            CANCELAR
          </button>
          <button-default
            v-if="open"
            :onClick="() => checkForm(index)"
            class="button_save"
            >Salvar</button-default
          >
        </div>
      </div>
    </div>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </main>
</template>

<script>
import {
  InputCheckboxAccordion,
  InputDefault,
  RadioGroup,
  InputMasked,
  InputSelectLocation,
  InputSelect,
  ButtonDefault,
  textWarning,
  FeedbackWarning
} from '@/components/atoms'

import {
  scienceFairs,
  tShirtSizes,
  tShirtTypes,
  scholarshipHolderPrograms,
  educationalLevels,
  englishLevels
} from '@/utils/dictionaries'
import { countries, states } from '@/utils/locations'
import LoginVue from './Login.vue'

export default {
  name: 'ProjenctDataStudents',
  components: {
    InputCheckboxAccordion,
    InputDefault,
    RadioGroup,
    InputMasked,
    InputSelectLocation,
    InputSelect,
    ButtonDefault,
    textWarning,
    FeedbackWarning
  },
  data() {
    return {
      onlyAdmin: '',
      open: false,
      level: '',
      form: {
        infos: []
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      scienceFairs,
      tShirtSizes,
      tShirtTypes,
      scholarshipHolderPrograms,
      educationalLevels,
      englishLevels,
      genderOptions: [
        { label: this.$t('maleSingle'), value: 'male' },
        { label: this.$t('femaleSingle'), value: 'female' }
      ],
      countries,
      states,
      cities: [],
      compareOtherSpecialService: '',
      generalOptions: [
        { label: this.$t('yes'), value: 'yes' },
        { label: this.$t('no'), value: 'no' }
      ],
      serieOptions: [
        { text: '1ª', value: '1' },
        { text: '2ª', value: '2' },
        { text: '3ª', value: '3' },
        { text: this.$t('internship'), value: 'estagio' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      sizeShirtOptionsHigh: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' },
        { text: 'P', value: 'p' },
        { text: 'M', value: 'm' },
        { text: 'G', value: 'g' },
        { text: 'GG', value: 'gg' }
      ],
      // attendOtherFairs: '',
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: this.$t('advanced') },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ]
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.onlyAdmin = localStorage.getItem('role')
    this.level = this.projectInfo.level
    const studentMain = this.projectInfo.students.find(
      (student) => student.main
    )
    this.form.infos.push(studentMain)
    const studentsAux = this.projectInfo.students.sort((a, b) => {
      if (a.id > b.id) return 1
      if (a.id < b.id) return -1
      return 0
    })
    studentsAux.forEach((student) => {
      if (!student.is_continuity && !student.main) {
        this.form.infos.push(student)
      }
    })

    this.form.infos.forEach((el, index) => {
      el.birth_date = this.formatDate(el.birth_date)

      el.special_service = this.serviceListPicker(el.special_service)
      if (el.special_service.includes('outro')) {
        const otherIndex = el.special_service.indexOf('outro') + 1
        const otherSpecialService = el.special_service[otherIndex]
        this.compareOtherSpecialService = otherSpecialService
        this.form.infos[index].otherSpecialService = otherSpecialService
      } else {
        this.form.infos[index].otherSpecialService = ''
      }

      const specialServiceRemoveOther = []
      el.special_service.forEach((item) => {
        if (item !== 'outro') {
          specialServiceRemoveOther.push(item)
        }
      })
      this.form.infos[index].special_service_values = specialServiceRemoveOther

      el.deficiency = this.deficiencyListPicker(el.deficiency)
      // this.attendOtherFairs = el.fair_science;
      this.form.infos[index].attendOtherFairs = this.scienceFairs[
        el.fair_science
      ]
        ? ''
        : el.fair_science
      el.fair_science = this.scienceFairs[el.fair_science]
        ? el.fair_science
        : 'other'
      el.scholarship_holder = el.scholarship_holder === true ? 'yes' : 'no'
    })
  },
  watch: {
    cStateSelected() {
      this.executeGetCities(this.form.infos[0].state)
    },
    cStateSelected2() {
      this.executeGetCities(this.form.infos[1].state)
    },
    cStateSelected3() {
      this.executeGetCities(this.form.infos[2].state)
    },
    cStateSelected4() {
      this.executeGetCities(this.form.infos[3].state)
    }
  },
  computed: {
    cStateSelected() {
      if (this.form.infos[0]) {
        return this.form.infos[0].state
      }
    },
    cStateSelected2() {
      if (this.form.infos[1]) {
        return this.form.infos[1].state
      }
    },
    cStateSelected3() {
      if (this.form.infos[2]) {
        return this.form.infos[2].state
      }
    },
    cStateSelected4() {
      if (this.form.infos[3]) {
        return this.form.infos[3].state
      }
    }
  },
  methods: {
    validCheckoboxGroup(actualData, index) {
      if (actualData.deficiency.includes('nao')) {
        this.form.infos[index].deficiency = ['nao']
        this.form.infos[index].special_service = ['nao']
      }

      if (actualData.special_service.includes('nao')) {
        this.form.infos[index].special_service = ['nao']
      }
    },
    deficiencyListPicker(deficiency) {
      let list = deficiency?.deficiency || deficiency?.deficiencies
      if (!deficiency?.deficiency && !deficiency?.deficiencies) {
        list = deficiency
      }
      return this.defOrServiceFormatted(list)
    },
    serviceListPicker(service) {
      let list = service?.special_service || service?.special_services
      if (!service?.special_service && !service?.special_services) {
        list = service
      }

      return this.defOrServiceFormatted(list)
    },
    defOrServiceFormatted(defOrService) {
      if (defOrService?.includes('nao') || !defOrService?.length) {
        return ['nao']
      }
      const formattedDefOrService = []
      defOrService.forEach((item) => {
        if (typeof item === 'string') {
          return formattedDefOrService.push(
            item.charAt(0).toLowerCase() + item.slice(1)
          )
        }

        formattedDefOrService.push('nao')
      })
      return formattedDefOrService
    },
    formatDate(birthDate) {
      const myDate = new Date(birthDate)
      let day = myDate.getDate()
      if (day < 10) {
        day = `0${ day }`
      }
      let month = myDate.getMonth() + 1
      if (month < 10) {
        month = `0${ month }`
      }
      return `${ day }/${ month }/${ myDate.getFullYear() }`
    },
    validateIfBigger(data, index) {
      if (data.length === 10) {
        const dataForm = data
          .split('/')
          .reverse()
          .join('-')
          .concat(' 00:00:00')
        const dataAtual = new Date()
        const birth = new Date(dataForm)
        // Levando em consideração os anos bissextos, um ano não é uma quantidade de tempo constante.
        // Tratamento usando comparação de data
        if (dataAtual.getFullYear() - birth.getFullYear() > 18) {
          this.form.infos[index].isBetter = false
        } else if (dataAtual.getFullYear() - birth.getFullYear() == 18) {
          if (dataAtual.getMonth() - birth.getMonth() > 0) {
            this.form.infos[index].isBetter = false
          } else if (dataAtual.getDate() - birth.getDate() >= 0) {
            this.form.infos[index].isBetter = false
          } else this.form.infos[index].isBetter = true
        } else this.form.infos[index].isBetter = true
      } else {
        this.form.infos[index].isBetter = null
      }
    },
    coalesceInformation(info) {
      if (!info && info !== 0) return 'Não informado'
      return info
    },
    executeGetCities(state) {
      if (this.form.infos[0].country !== 'Brazil') return false
      this.cities = []
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`
      )
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    deleteInputWhenNoShow(index) {
      if (this.form.infos[index].scholarship_holder === 'no') {
        this.form.infos[index].other_program = ''
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    checkForm(index) {
      this.resetErrors()
      this.form.infos.forEach((item, index) => {
        if (item.active) {
          for (const key in item) {
            if (!this.form.infos[index][key]) {
              if (
                key === 'complement'
                || key === 'active'
                || key === 'is_continuity'
                || key === 'responsable_phone'
                || key === 'isBetter'
                || key === 'main'
                || key === 'appeared'
                || (key === 'attendOtherFairs' && item.fair_science !== 'other')
                || ((key === 'street'
                  || key === 'house_number'
                  || key === 'district'
                  || key === 'zipcode')
                  && item.country !== 'Brazil')
                || key === 'meal'
                || key === 'will_pay'
                || ((key === 'which_program' || key === 'other_program')
                  && item.scholarship_holder === 'no')
                || (key === 'other_program' && item.which_program !== 'other')
                || (key === 't_shirt_size'
                  && (item.t_shirt === 'no' || !item.t_shirt))
                || ((key === 'responsible_name'
                  || key === 'responsible_kinship')
                  && !item.isBetter)
                || (key === 'english_level' && item.level !== 'high')
                || (key === 'otherSpecialService' && (!item.special_service.includes('outro') || item.special_service.includes('nao')))
              ) {
              } else {
                this.errors.push(`${ key }${ index }`)
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
              }
            }
            if (key === 'deficiency') {
              if (!item.deficiency.length) {
                this.errors.push(`${ key }${ index }`)
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
                return false
              }
            }
            if (
              !item.deficiency.includes('nao')
              && !item.special_service.length
            ) {
              this.errors.push(`special_service${ index }`)
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
              return false
            }
          }

          if (!this.errors.length) {
            if (!this.validateCpf(item.cpf, index)) {
              this.textWarning = 'invalidZipCode'
              return false
            }
            if (!this.validateEmail(item.email, index)) {
              this.textWarning = 'E-mail inválido!'
              return false
            }

            if (!this.validateDate(item.birth_date)) {
              this.errors.push(`birth_date${ index }`)
              return false
            }

            if (item.country === 'Brazil') {
              if (item.zipcode.length < 9) {
                this.errors.push(`zipcode${ index }`)
                this.textWarning = 'Informe um CEP válido'
                return false
              }
            }
          }
        }
      })

      if (!this.errors.length) {
        this.editForm(index)
      }
    },
    async editForm(index) {
      const { id } = this.projectInfo
      const studentId = this.form.infos[index].id

      if (this.form.infos[index].otherSpecialService) {
        const { otherSpecialService, special_service } = this.form.infos[index]
        if (this.compareOtherSpecialService.length) {
          if ((otherSpecialService !== this.compareOtherSpecialService) || !special_service.includes('outro')) {
            special_service.splice(special_service.indexOf(this.compareOtherSpecialService), 1)
          }
        }

        if (special_service.includes('outro')) {
          this.form.infos[index].special_service.push(otherSpecialService)
        }
      }

      const body = {
        students: [
          {
            id: studentId,
            full_name: this.form.infos[index].full_name,
            cpf: this.form.infos[index].cpf,
            sex: this.form.infos[index].sex,
            phone: this.form.infos[index].phone,
            birth_date: new Date(
              this.form.infos[index].birth_date
                .split('/')
                .reverse()
                .join('-')
                .concat(' 00:00:00')
            ),
            email: this.form.infos[index].email,
            country: this.form.infos[index].country,
            state: this.form.infos[index].state,
            city: this.form.infos[index].city,
            street: this.form.infos[index].street,
            house_number: this.form.infos[index].house_number,
            complement: this.form.infos[index].complement,
            zipcode: this.form.infos[index].zipcode,
            district: this.form.infos[index].district,
            responsible_name: this.form.infos[index].responsible_name,
            responsible_kinship: this.form.infos[index].responsible_kinship,
            educational_level: this.form.infos[index].educational_level,
            scholarship_holder:
              this.form.infos[index].scholarship_holder === 'yes',
            which_program: this.form.infos[index].which_program,
            other_program: this.form.infos[index].other_program,
            english_level: this.form.infos[index].english_level,
            fair_science:
              this.form.infos[index].fair_science === 'other'
                ? this.form.infos[index].attendOtherFairs
                : this.form.infos[index].fair_science,
            deficiency: { deficiencies: this.form.infos[index].deficiency },
            special_service: {
              special_services: this.form.infos[index].special_service
            },
            t_shirt: this.form.infos[index].t_shirt,
            t_shirt_size: this.form.infos[index].t_shirt_size
          }
        ]
      }
      try {
        await this.$http.put(`/project/${ id }`, body)
        location.reload()
      } catch (error) {
        console.log(error.message)
      }
    },
    cancelSave() {
      location.reload()
    },
    validateDateFormat(date) {
      const [year, month, day] = date.split('-')
      return Number(month) <= 12 && Number(day) <= 31 && year >= 1900
    },
    validateDate(birth) {
      const formattedDate = birth.split('/').reverse().join('-')

      const actualDate = new Date().setHours(0, 0, 0, 0, 0, 0)
      const birthDate = new Date(formattedDate.concat(' 00:00:00'))
      if (!this.validateDateFormat(formattedDate)) {
        this.textWarning = 'invalidDateFormat'
        return false
      }
      if (actualDate < birthDate) {
        this.textWarning = 'A data de nascimento informada deve ser menor do que a data atual.'
        return false
      }
      return true
    },
    validateCpf(value, index) {
      if (this.form.infos[index].country != 'Brazil') {
        return true
      }
      if (
        !/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/.test(
          value
        )
      ) {
        this.errors.push(`cpf${ index }`)
        return false
      }
      return true
    },
    validateEmail(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.infos[index].email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push(`email${ index }`)
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.main_align {
  .accordion_size {
    width: 93%;
    margin-left: 48px;
    padding-left: 24px;
  }
  .content {
    border-top: 1px solid #cdcbcb;
    margin-top: -33px;
    width: 93%;
    margin-left: 48px;
    height: fit-content;
    padding-top: 13px;
    justify-content: space-between;
    display: block;
    padding-left: 12px;
    padding-bottom: 20px;
    background: #fdfdfd;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    .mainStudent_description {
      color: $primary;
      margin-left: 5px;
      margin-bottom: 15px;
    }
    .content_title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      color: $primary;
      margin-left: 35px;
    }
    .contact_info,
    .address_info,
    .inscription_info {
      display: flex;
      .data {
        display: flex;
        &__row {
          width: 355px;
          margin-bottom: 35px;
          font-size: 16px;
          color: #686868;
          h4 {
            font-weight: 600;
            color: $almostBlack;
            margin-bottom: 10px;
          }
        }
        &__left {
          width: 45%;
          margin-left: 35px;
          float: left;
        }
        &__right {
          margin-left: 35px;
          float: right;
        }
      }
    }
  }
}

.separator {
  background-color: $primary;
  height: 0.5px;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 98%;
}

.levels {
  display: flex;
  flex-direction: column;

  .title {
    color: #303030;
    display: flex;
    flex-direction: column;
    font-size: 16px !important;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
  }

  label {
    display: flex;
    color: $almostBlack;
    font-size: 16px;
    margin-bottom: 5px;

    input {
      zoom: 1.5;
      margin-right: 5px;
    }
  }

  .warning {
    color: #ff9800;
  }
}

.button_edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  width: 100px;
  height: 36px;
  left: 1230px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_save {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  margin: 0px 10px;

  width: 106px;
  height: 44px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-transform: uppercase;

  color: #ffffff;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button_cancel {
  box-sizing: border-box;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 32px;

  width: 113px;
  height: 45px;

  border: 1px solid #26768d;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;

  color: #26768d;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.edit-and-paragrafh {
  display: flex;
  justify-content: space-between;
  margin-right: 2%;
  align-items: baseline;
}

.only_edit {
  display: flex;
  justify-content: flex-end;
  margin-right: 2%;
  align-items: center;
}

.card-vacancies-cancel-and-save {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5%;
}
</style>
