const formTypes = {
  pdf: 'coexistenceContract',
  banner: 'banner',
  biological: 'bios',
  cientistQualified: 'scientist',
  continuousProject: 'continuity',
  humans: 'humans',
  presentWork: 'presentation',
  researchInstitution: 'researchInstituitionIndustry',
  revision: 'revision',
  risksAnalysis: 'riscs',
  tissueSubstances: 'tissues',
  vertebrateAnimals: 'vertebrates',
  video: 'video',
  presentationLink: 'presentationLink'
}

export default formTypes
