<template>
  <div class="wrapper">
    <div class="header">
      <heading-three
        :class="[
          { heading: color === 'black' },
          { gray_heading: color === 'gray' },
        ]"
      >
        {{ title }}
      </heading-three>
      <money-default v-if="hasMoney" :isMoney="isMoney">
        {{ price }}
      </money-default>
    </div>
    <LineDefault color="gray" />
  </div>
</template>

<script>
import { MoneyDefault } from '@/components/atoms'
import { HeadingThree } from '@/components/atoms/Texts'
import { LineDefault } from '@/components/atoms/Lines'

export default {
  name: 'GrayLineTitle',
  components: { HeadingThree, LineDefault, MoneyDefault },
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    price: {
      type: [String, Number],
      required: false
    },
    hasMoney: Boolean,
    isMoney: Boolean
  },
  computed: {
    cTitle() {
      return this.title || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
}

.heading {
  font-weight: 650;
  font-size: 17.5px;
  margin: 9px 20px;
  width: fit-content;
}

.gray_heading {
  font-weight: 650;
  font-size: 17.5px;
  margin: 15px 20px;
  width: 58%;
  color: #686868;
}
</style>
