<template>
  <div class="card">
    <div class="main" :class="{'active': cOpen}">
      <div class="header">
        <div class="year" :class="{'active': cOpen}">{{cData.year_edition}}</div>
        <Status
          :status="cData.status"
          :active="cOpen"
          :is_active="cData.status_is_active"
          :is_legend="false"
          :active_status="isActive"
        />
      </div>
      <div class="fair-name">{{cData.fair_name}}</div>
    </div>
    <template v-if="cOpen">
      <div class="dates">
        <div class="info">
          <div class="text">{{$t('requestDate')}}</div>
          <div class="date">{{cData.created_at | formatDate}}</div>
        </div>
        <div class="info">
          <div class="text">{{$t('lastUpdate')}}</div>
          <div class="date">{{cData.updated_at | formatDate}}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Status } from '@/components/atoms/Status'

export default {
  name: 'cardPanelInTemplate',
  components: {
    Status
  },
  props: {
    data: {
      type: Object
    },
    open: {
      type: Number
    }
  },
  data() {
    return {
      isActive: true
    }
  },
  mounted() {
    this.$http.get(`/fair/${ this.data.id }`).then((response) => {
      this.isActive = response.data.is_active
    })
  },
  computed: {
    cData() {
      if (this.data) {
        return this.data
      }
      return ''
    },
    cOpen() {
      if (this.open) {
        return this.open === this.data.id
      }
      return false
    }
  }
}
</script>
<style lang="scss">
.card {
  cursor: pointer;
  user-select: none;
  border-radius: 20px;
  padding: 10px 15px 19px 15px;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  margin-bottom: 26px;

  .main {
    .header {
      display: flex;
      justify-content: space-between;
      .year {
        font-size: 20px;
        color: #afaeae;
        font-weight: 700;
        &.active {
          color: $primary;
        }
      }
    }
    &.active {
      margin-bottom: 43px;
    }
  }
  .dates {
    display: flex;
    justify-content: space-between;
    color: $textCard;
    .info {
      .text {
        font-size: 10px;
      }
      .date {
        font-family: "Mulish", sans-serif;
        font-weight: 600;
      }
    }
  }
  .fair-name,
  .text {
    font-family: "Mulish", sans-serif;
    color: $textCard;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
