<template>
  <label class="label" :for="id">
    {{ $t(label) }}
    <v-autocomplete
      dense
      solo
      clearable
      class="input"
      v-model="selectedOption"
      @input="$emit('input', selectedOption)"
      :items="items"
      :label="placeholder"
      :disabled="disabled ? disabled : false"
    />
  </label>
</template>

<script>

export default {
  name: 'InputAutoComplete',
  props: {
    id: String,
    items: Array,
    label: String,
    placeholder: String,
    disabled: Boolean,
    value: String,
    selected: String
  },
  data: () => ({
    selectedOption: ''
  }),
  created() {
    if (this.value) {
      this.selectedOption = this.value
      this.$emit('input', this.value)
    }
  },
  watch: {
    selected() {
      this.selectedOption = this.selected
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: #303030;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  width: 100%;
}

.warning {
  color: $attention;

  .input {
    border-color: $attention;
  }

  ::v-deep fieldset {
    border-color: $attention !important;
  }
}

.input {
  background: #FFFFFF;
  border-radius: 10px;
  color: $almostBlack;
  font-weight: 500;
  margin-top: 15px;
  max-width: 400px;
  outline: none;

  &::placeholder {
    color: #AFAEAE;
    font-size: 14px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  border: 1px solid #cdcbcb;
  height: 35px;
  min-height: 35px;
  margin-top: 5px;
  box-shadow: none;
}

::v-deep .v-text-field--outlined fieldset {
  top: 5px !important;
  bottom: 7px !important;
}
</style>
