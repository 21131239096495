<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('projectSummary')}}</p>
        <p class="form__text">{{$t('projectAbstractIntro')}}</p>
        <p class="form__text">{{$t('projectAbstractIntroA')}}</p>
        <p class="form__text">{{$t('projectAbstractIntroB')}}</p>
        <p class="form__text">{{$t('projectAbstractIntroC')}}</p>
        <p class="form__text">{{$t('projectAbstractIntroD')}}</p>
        <p class="form__text">{{$t('projectAbstractIntroE')}}</p>
        <text-warning :text="`considerAllFieldObligatory`" />
      </div>

      <div :class="[{ warning: checkErrorIfContains('abstract') }, 'textarea mb-2']">
        <label class="label">{{$t('abstract')}}</label>
        <textarea
          :placeholder="$t('JustificationPlaceholder')"
          v-model="form.abstract"
          maxlength="4000"
          @input="resetErrors"
        ></textarea>
      </div>
      <div class="info-area">
        <text-info
          class="text-info mb-2"
          text=youCanEditTheAbstract
        />
        <span class="char-counter">{{ form.abstract.length }}/4000</span>
      </div>
      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndContinue')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputDefault,
  InputCheckboxTitle,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo
} from '@/components/atoms'
// import { mapGetters } from "vuex";

export default {
  name: 'FormProjectHighProjectAbstract',
  components: {
    InputDefault,
    InputCheckboxTitle,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  data() {
    return {
      errors: [],
      form: {
        abstract: ''
      }
    }
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormProjectHighProjectAbstract) {
        this.form = this.allDataForm.FormProjectHighProjectAbstract
      }
    }
  },
  mounted() {
    if (this.allDataForm.FormProjectHighProjectAbstract) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectHighProjectAbstract
      }
    }
  },
  // computed: {
  //   ...mapGetters({
  //     projectSelection: "projectSelection"
  //   })
  // },
  // created() {
  //   if (this.projectSelection.summary) {
  //     this.form.abstract = this.projectSelection.summary;
  //   }
  // },
  methods: {
    async checkForm() {
      this.resetErrors()
      if (!this.form.abstract.length) {
        this.errors.push('abstract')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }
      if (!this.errors.length) {
        await this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      this.allDataForm.FormProjectHighProjectAbstract = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        // const formData = this.$store.getters.projectSelection;
        // const newData = {
        //   ...formData,
        //   summary: this.form.abstract
        // };
        // this.$store.commit("PROJECT_SELECTION_REQUEST", newData);
        // window.localStorage.setItem(
        //   "projectSelectionData",
        //   JSON.stringify(newData)
        // );
        this.$emit('changeElement', {
          direction: 'up',
          FormProjectHighProjectAbstract: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 600px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .label {
    color: $almostBlack;
    display: flex;
    font-weight: 600;
    text-align: left;
  }

  .textarea {
    margin-top: 20px;
    width: 100%;

    textarea {
      border: 1px solid #cdcbcb;
      box-sizing: border-box;
      border-radius: 10px;
      min-height: 126px;
      padding: 10px;
      resize: vertical;

      float: left;
      width: 100%;
      max-width: 570px;

      &::placeholder {
        color: #afaeae;
      }
    }
  }

  .info-area {
    display: flex;
    max-width: 570px;

    .text-info {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
      margin-top: 8px;
      width: 85%;

      p {
        font-size: 14px;
        line-height: 18px;
        color: $almostBlack;
        margin-left: 6px;
        max-width: 522px;
      }
    }
    .char-counter {
      margin-top: 7px;
      margin-left: 10%;
      width: 71.2px;
      font-size: 14px;
      line-height: 18px;
      color: #afaeae;
    }
  }

  .warning {
    label {
      color: $attention;
    }
    textarea {
      border-color: $attention;
    }
  }
}
</style>
