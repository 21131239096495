<template>
  <div>
    <form @submit.prevent class="form" method="post">
      <div class="form__info">
        <p class="form__title">{{ $t("Contrato de convivência") }}</p>
        <text-warning :text="'considerAllFieldObligatory'" />
        <text-warning :text="'Limite de 60mb por arquivo'" />
        <text-info
          :text="{{  $t("coexistenceContractInfo") }}"
        />
      </div>
      <div class="accepted mb-7">
        <input class="input" type="checkbox" v-model="form.accepted" />
        <label :class="{ label: true, warning: cAccepted }">{{ $t("Li e concordo") }}</label>
      </div>

      <RadioGroup
        :class="[{ warning: cLive }, 'mb-2']"
        label="Como será a entrega do contrato 2"
        :options="generalOptions"
        v-model="form.livePresentation"
        @change="resetErrors"
        :selected="form.livePresentation"
      />

      <DragFile
        @file="form.pdf = $event"
        :attention="cAttention"
        :type="'pdf'"
        :actualData="form.pdf"
        :reference="'contract'"
      />

      <label class="label">Banner</label>
      <DragFile
        @file="form.banner = $event"
        :type="'banner'"
        :actualData="form.banner"
        :reference="'contract'"
      />
      <text-info
        class="text-info mt-3"
        text="O formato do arquivo deve ser em PDF. Você poderá adicionar o arquivo após o envio de sua inscrição, se ainda não o possuir."
      />

      <div class="form__footer">
        <button-default :onClick="back" class="button">{{ $t("back") }}</button-default>
        <div class="two-button">
          <button-default :onClick="checkForm" :class="{ button: true, disabledButton: projectSaved }">{{ $t("Salvar") }}</button-default>
          <button-default :onClick="saveFix" :class="{ button: true, disabledButton: projectSaved }">{{ $t("Enviar") }}</button-default>
        </div>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  RadioGroup,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo,
  DragFile
} from '@/components/atoms'

export default {
  name: 'FormContract',
  components: {
    RadioGroup,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo,
    DragFile
  },
  data() {
    return {
      form: {
        accepted: false,
        livePresentation: '',
        pdf: {
          name: '',
          url: ''
        }
      },
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      generalOptions: [
        { label: 'Presencial', value: 'presential' },
        { label: 'Virtual', value: 'virtual' }
      ],
      firstLoader: false,
      temporaryData: {},
      projectSaved: false
    }
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  computed: {
    cAccepted() {
      return this.firstLoader && !this.form.accepted
    },
    cLive() {
      return this.firstLoader && !this.form.livePresentation
    },
    cAttention() {
      return this.firstLoader && !this.form.pdf
    }
  },
  async mounted() {
    this.projectSaved = await this.getProjectSaved()
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormContract) {
        this.form = this.allDataForm.FormContract
      }
    }
  },
  methods: {
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    checkForm() {
      this.firstLoader = true
      this.resetErrors()
      if (this.projectSaved) {
        this.textWarning = this.$t('projectAlreadySaved')
        this.errors.push('enviado')
      }
      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
        }
      }

      if (!this.errors.length && this.form.accepted) {
        this.temporarySave()
        this.saveForm()
      }
    },
    async getProjectSaved() {
      const { data: response } = await this.$http.get('/temp')
      if (response) return response.project_saved
      return false
    },
    async temporarySave() {
      this.allDataForm.FormContract = this.form

      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
        this.temporaryData = response
      } catch (error) {
        console.error(error)
      }
    },
    async saveForm() {
      try {
        this.$emit('changeElement', { FormFair: this.form })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
        return false
      }
    },
    saveFix() {
      if (this.projectSaved) {
        this.textWarning = this.$t('projectAlreadySaved')
        this.errors.push('enviado')
        return
      }
      const infoStundentsAux = this.allDataForm.FormProjectChildishStudents.infos.filter(
        (item) => item.active
      )

      const infoStudents = []

      infoStundentsAux.forEach((item) => {
        infoStudents.push({
          zipcode: Number(item.zipCode),
          deficiency: { deficiency: item.levelDeficiency },
          special_service: { special_service: item.specialattendance },
          cpf: Number(item.cpf),
          phone: Number(item.phone),
          // house_number: Number(item.number),
          house_number: item.number,
          birth_date: new Date(
            item.birthday
              .split('/')
              .reverse()
              .join('-')
          ),
          full_name: item.fullname,
          sex: item.gender,
          t_shirt: item.typeShirt,
          educational_level: item.serie,
          street: item.address,
          fair_science: item.attendOtherFair,
          english_level: item.englishLevel,
          district: item.neighborhood,
          responsible_name: item.responsibleName,
          responsible_kinship: item.responsibleRelative,
          t_shirt_size: item.sizeShirt
        })
      })
      infoStudents[0].main = true

      const infoInvolvedsAux = this.allDataForm.FormProjectChildishAdvisors.infos.filter(
        (item) => item.active
      )

      const infoInvolveds = []

      infoInvolvedsAux.forEach((item) => {
        infoInvolveds.push({
          full_name: item.fullname,
          cpf: Number(item.cpf),
          phone: Number(item.phone),
          sex: item.gender,
          zipcode: Number(item.zipCode),
          street: item.address,
          //          house_number: Number(item.number),
          house_number: item.number,
          scholarship_holder: item.scholarship,
          which_program: item.program,
          other_program: item.programOther,
          position: item.discipline,
          deficiency: { deficiency: item.levelDeficiency },
          special_service: { special_service: item.specialattendance },
          t_shirt: item.typeShirt,
          t_shirt_size: item.sizeShirt,
          english_level: item.englishLevel,
          participated_mostratec: item.attendOtherFair
        })
      })

      const { projectName } = this.allDataForm.FormProjectChildishData
      delete this.allDataForm.FormProjectChildishData.projectName
      const results = this.allDataForm.FormProjectChildishData.resultsConclusion
      delete this.allDataForm.FormProjectChildishData.resultsConclusion
      const { summary } = this.allDataForm.FormProjectChildishData
      delete this.allDataForm.FormProjectChildishData.summary

      const body = {
        level: this.allDataForm.FormLevelOfEducation.level,
        project_title: projectName,
        project_category: this.allDataForm.FormProjectChildishStudents.categoryProject,
        results,
        live_presentation: this.allDataForm.FormProjectChildishStudents.livePresentation,
        mostratec_edition_active: this.allDataForm.FormLevelOfEducation.edition,
        summary,
        students: infoStudents,
        involveds: infoInvolveds,
        research_plan: this.allDataForm.FormProjectChildishData,
        research_type: null,
        area: this.allDataForm.FormProjectChildishData.areas[0],
        sub_areas: {
          a: 'a'
        }
      }

      try {
        this.$http.post('/project', body)
      } catch (error) {
        console.log('erro:', error)
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
// .input__checkbox {
//   display: flex;
//   flex-direction: column;

input[type="checkbox"] {
  zoom: 1.8;
}
.center {
  display: flex;
  align-items: center;
  margin-top: 11px;
}

span {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
  max-width: 728px;
}

// }

.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  &.warning {
    color: $attention !important;
  }
}

.warning {
  span {
    color: $attention;
    border-bottom-color: $attention;
  }
}
.accepted {
  display: flex;
  align-items: center;
  margin-top: -20px;
  label {
    margin-left: 10px;
    color: $primary;
  }
}
.two-button {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  :first-child {
    margin-right: 10px;
  }
}

.disabledButton {
  color: grey;
}
</style>
