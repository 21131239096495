<template>
  <div :class="['line', {gray_line: color === 'gray' }]"></div>
</template>

<script>
export default {
  name: 'LineDefault',
  props: {
    color: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  margin-bottom: 30px;
  border: 0.5px solid $almostBlack;

}
.gray_line {
  border: 0.5px solid $mediumGray;
}
</style>
