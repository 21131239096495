<template>
  <div class="denied">
    <div class="box">
      <div>
        <br/>
        Português: <strong>Acesso Negado</strong><br/>
        Esta situação pode ocorrer:<br/>
        - por ter expirado a sessão.<br/>
        - por falta de permissão de acesso ao recurso.<br/>
        - pela ocorrência de múltiplas abas/janelas abertas, neste caso <span class="deniedclosemessage">feche todas as janelas acessando o site e ingresse novamente</span>. <br/>
        &nbsp;&nbsp;Não é permitida a abertura de mais de uma janela/aba por sessão.<br/>
        <br/>

        English: <strong>Access Denied</strong><br/>
        This situation may occur:<br/>
        - due to session expiration.<br/>
        - due to lack of permission to access the resource.<br/>
        - due to multiple tabs/windows being open. In this case, <span class="deniedclosemessage">close all windows accessing the site and log in again</span>. <br/>
        &nbsp;&nbsp;Opening more than one window/tab per session is not allowed.<br/>
        <br/>
        
        Espanhõl: <strong>Acceso Denegado</strong><br/>
        Esta situación puede ocurrir:<br/>
        - debido a la expiración de la sesión.<br/>
        - por falta de permiso para acceder al recurso.<br/>
        - por la apertura de varias pestañas/ventanas. En este caso, <span class="deniedclosemessage">cierre todas las ventanas que accedan al sitio e inicie sesión nuevamente</span>. <br/>
        &nbsp;&nbsp;No se permite abrir más de una ventana/pestaña por sesión.<br/>
        <br/>
        <br/>

      </div>
      
    </div>
  </div>
</template>
<script>
  export default {
    name: 'access-denied'
  }
</script>
<style lang="scss" scoped>
  .denied {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: white;
    background-color: $primary;
    .box {
      display: box;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      width: 80%;
      height: 95%;
      background-color: rgba(0,0,0,0.5);
      font-size:70%;
      padding: 10px;
    }
    .box strong{
      font-size:130%;
      font-weight: 700;
      color:rgb(255, 96, 96);
    }
    .deniedclosemessage{
      color: rgb(255, 96, 96);
    }
  }
</style>
