<template>
  <div>
    <div class="inner__wrapper">
      <div class="header">
        <h3>Feira vigente</h3>
      </div>
      <div class="infos">
        <div class="info__item">
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('fairName')}}</h4>
                <span>{{cFairInfo.fair_name}}</span>
              </div>
              <div class="data__right">
                <h4>{{$t('site')}}</h4>
                <span>{{cFairInfo.website}}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('fairCoordenatorName')}}</h4>
                <span>{{cFairInfo.fairs_coordinator_name}}</span>
              </div>
              <div class="data__right">
                <h4>{{$t('fairType')}}</h4>
                <span class='first-letter-up'>{{cFairInfo.fair_type}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'CurrentFair',
  props: {
    fairInfo: Object
  },
  computed: {
    cFairInfo() {
      if (this.fairInfo) {
        return this.fairInfo
      }
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
.inner__wrapper {
  background: #FDFDFD;
  border-radius: 10px;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 10px 22px 10px;

    .info__item {
      margin-top: 25px;

      .data__wrapper {

        .data__row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 30px;
          padding-left: 8%;

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: #686868;
          }
        }
      }
    }
  }
}
.first-letter-up {
  text-transform: capitalize;
}
</style>
