<template>
  <div class="wrapper">
    <StudentStatusLegend
      :goTo="'/startproject/'"
      :formType="formType"
      :appear="appear"
    />
    <template v-if="projectList.length">
      <div class="orientation">
        <template v-if="projectList.length">
          <ContainerComponents
            :components="components"
            :index="index"
            :data="selectedProject"
            margin="35px 60px"
          >
          <Menu
            :menus="cMenus"
            :active="index"
            :newStyle="{ borderRadius: '20px 20px 0 0' }"
            @selectedMenu="index = $event"
          />
          </ContainerComponents>
        </template>
      </div>
    </template>
    <template v-else>
      <ImageInformation image="subrequest.png" :content="$t('noProject')" />
    </template>
  </div>
</template>
<script>
import { ImageInformation } from '@/components/molecules'
import {
  Documents,
  SubscriptionRequest,
  ProjectsPanel
} from '@/components/templates'
import { Menu } from '@/components/atoms/Menu'
import { ContainerComponents } from '@/components/atoms/Containers'
import {
  StudentStatusLegend
} from '@/components/organisms'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'panel-student-vacancies-template',
  data() {
    return {
      isData: false,
      url: 'grey_pdf.png',
      content: 'defadsds.pdf',
      infos: [
        { text: this.$t('institutionFairName'), value: '' },
        { text: this.$t('site'), value: '' },
        { text: this.$t('fairCoordenatorName'), value: '' },
        { text: this.$t('fairType'), value: '' },
        { text: this.$t('researchAreaSingle'), value: { area: '' } },
        { text: this.$t('researchSubAreas'), value: { sub_areas: [] } }
      ],
      selected: 0,
      mostratec: {},
      index: 0,
      appear: true,
      projectsInfo: {},
      menus: [
        'subscriptionRequest',
        'projectData',
        'documents'
      ],
      components: [
        SubscriptionRequest,
        ProjectsPanel,
        Documents
      ],
      projects: [],
      textWarning: null,
      formType: '',
      noStatus: false
    }
  },
  computed: {
    ...mapGetters({ selectedProject: 'getSelectedProject' }),
    projectList() {
      return this.$store.getters.getProjectList
    },
    cMenus() {
      if (this.selectedProject) {
        if (this.validStatusSlug(this.selectedProject) === 'rascunho') {
          // index = 0 pra garantir que volte ao primeiro componente.
          this.index = 0
          return [this.menus[0]]
        }
      }
      return this.menus
    }
  },
  components: {
    StudentStatusLegend,
    ImageInformation,
    Menu,
    ContainerComponents
  },
  mounted() {
    this.getTemporaryAndProjects()
    this.getPath()
  },
  methods: {
    ...mapActions({ setSelectedProject: 'project/setSelectedProject' }),
    projectSelected(value) {
      this.selected = value
      this.$store.commit('SELECTED_PROJECT', this.projectList[this.selected])
    },
    validStatusSlug(value) {
      return value.status?.slug || value.statusSlug
    },
    validStatusName(value) {
      return value.status?.name || value.statusName
    },
    validYearEdition(value) {
      return value.created_at
        ? value.created_at.slice(0, 4)
        : new Date().getFullYear()
    },
    validInitDate(value) {
      return value.created_at || this.$t('draft')
    },
    validLastDate(value) {
      return value.updated_at || this.$t('draft')
    },
    validProjectTitle(value) {
      return `#${ value.id } - ${ value.project_title }` || this.$t('draft')
    },
    async getTemporaryAndProjects() {
      try {
        const {
          data: { temporary, projects }
        } = await this.$http.get('/project-for-user')
        this.$store.commit('PROJECT_LIST', [...temporary, ...projects])
        this.projectSelected(this.selected)
        this.seeButton()
      } catch (error) {
        console.log('ERROR', error)
      }
    },
    seeButton() {
      const projectsThisYear = this.projectList.find(
        (item) => new Date(item.created_at).getFullYear() === new Date().getFullYear()
      )
      if (projectsThisYear) {
        this.appear = false
      }
    },
    getPath() {
      const role = window.localStorage.getItem('role')
      if (role === 'aluno_processo_selecao') {
        this.formType = 'selection'
        return
      }
      this.formType = 'feira_afiliada'
    }
  }
}
</script>
<style lang="scss" scoped>
.cho-conteiner {
  background-color: red;
}
.wrapper {
  width: 100%;
}
.menu {
  width: 80%;
  max-width: 80%;
  height: fit-content;
  padding: 24px 10px;
  flex-direction: column;
  align-items: center;
}
.menu_align {
  display: flex;
  gap: 50px;
}
.heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  margin: 7px 0px;
  font-size: 14px;
  line-height: 18px;
  color: #686868;
  max-width: 277px;
  width: fit-content;
}
.card {
  width: 35%;
  max-width: 308px;
  padding: 14px 10px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.year {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: $primary;
}
.orientation {
  display: flex;
}
.collum-card {
  margin-right: 26px;
}
.cho-conteiner {
  color: blueviolet;
}
</style>
