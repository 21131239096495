<template>
  <div>
    <div class="container-app-restaurant">
      <div class="container-logo header">
        <img class="" src="@/assets/logo.png" alt="Logo Mostratec" />
        <div class="menu border">
          <div class="menu__item" @click="openUserOptions = !openUserOptions">
            <icon-default
              class="header__icon"
              url="profile.png"
              alt="Ícone de caneta da tela pré-cadastro"
            />
            <span>
              <button :disabled="true">{{ $t("welcome") }}</button>
            </span>
            <template v-if="openUserOptions">
              <UserOptions />
            </template>
          </div>
        </div>
      </div>

      <div class="container-content">
        <div class="container-area-restaurant border-bottom">
          <h1 class="title-restaurant">Área do restaurante</h1>
          <h2 class="subtitle-restaurant">Escanear código</h2>
          <div class="container-scanner">
            <div v-if="!showCamera">
              <div class="camera" @click="showCamera = !showCamera">
                <img src="@/assets/camera.png" alt="imagem de uma camera" />
              </div>
              <p>Abrir a camera</p>
            </div>

            <div v-else class="qrcode">
              <qrcode-stream @decode="onDecode"></qrcode-stream>
            </div>
          </div>
        </div>

        <div class="container-data border-bottom">
          <h2 class="subtitle-restaurant">Dados do cliente</h2>
          <div class="data-content">
            <div class="data-client">
              <h4>Nome</h4>
              <p>{{ clientData.name }}</p>
            </div>
            <div class="data-client">
              <h4>ID</h4>
              <p>{{ clientData.id }}</p>
            </div>
            <div class="data-client">
              <h4>Qtdade de créditos disponíveis</h4>
              <div>
                <input  v-if="clientData.credits > 0"
                  class="input-credits-valorado"
                  :value="clientData.credits"
                  type="text"
                  disabled
                />
                <input  v-if="clientData.credits <= 0"
                  class="input-credits-zerado"
                  :value="clientData.credits"
                  type="text"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div class="container-data border-bottom">
          <h2 class="subtitle-restaurant">Dados da venda</h2>
          <div class="data-content">
            <div class="data-sale">
              <h4>Qtdade de refeições</h4>
              <div>
                <input
                  class="input-credits"
                  :value="numberOfMeal"
                  type="text"
                  disabled
                />
              </div>
            </div>

            <div class="container-btn">
              <button-default
                class="btn-restaurant"
                :disabled="clientData.credits <= 0"
                :onClick="updateMeal"
              >
                Confirmar
              </button-default>
            </div>
          </div>
        </div>

        <div class="container-data">
          <h2 class="subtitle-restaurant">Relatório</h2>
          <div class="data-content-date">
            <div class="report-data">
              <h4>Período</h4>
              <div class="container-date">
                <div class="input-date">
                  <input-masked
                    placeholder="Data início"
                    :class="[
                      { warning: checkErrorIfContains('startReportDates') },
                      'mb-4',
                    ]"
                    type="text"
                    :mask="['##/##/####']"
                    v-model="reportDates.startDate"
                  />
                </div>

                <div class="input-date">
                  <input-masked
                    placeholder="Data Fim"
                    :class="[
                      { warning: checkErrorIfContains('endReportDates') },
                      'mb-4',
                    ]"
                    type="text"
                    :mask="['##/##/####']"
                    v-model="reportDates.endDate"
                  />
                </div>
              </div>
            </div>
            <div class="container-btn">
              <button-default class="btn-restaurant" :onClick="reportGenerate">
                Gerar relatório
              </button-default>
              <button-default class="btn-restaurant" :onClick="exportToExcel">
                Exportar relatório completo
              </button-default>
            </div>
          </div>
          <table v-if="reportInfos && reportInfos.length > 0">
            <tr>
              <th>ID Consumidor</th>
              <th>Nome consumidor</th>
              <th>Data da venda</th>
            </tr>
            <tr v-for="(info, index) in reportInfos" :key="index">
              <td>{{ info.student_id || info.involved_id }}</td>
              <td>{{ info.consumer_name }}</td>
              <td>{{ info.created_at }}</td>
            </tr>
          </table>
        </div>
      </div>
      <feedback-warning
        v-if="errors.length"
        :text="textWarning"
        :onClick="resetErrors"
        color="#ff9800"
      />
      <feedback-warning
        v-if="errorSales"
        :text="textWarning"
        :onClick="resetErrors"
        color="#D12500"
      />
      <feedback-warning
        v-if="confirmedSale"
        :text="textWarning"
        :onClick="toggleConfirmedSale"
        color="#33B850"
      />
    </div>
  </div>
</template>

<script>
import fileDownload from 'js-file-download'
import { QrcodeStream } from 'vue-qrcode-reader'
import printJS from 'print-js'
import jwt_decode from 'jwt-decode'
import UserOptions from '@/components/organisms/Headers/UserOptions'
import QRCodeTag from '@/components/organisms/Cards/QRCodeTag'

import {
  ButtonDefault,
  InputMasked,
  FeedbackWarning,
  IconDefault
} from '@/components/atoms'

export default {
  name: 'RestaurantTemplate',
  components: {
    ButtonDefault,
    InputMasked,
    FeedbackWarning,
    QrcodeStream,
    IconDefault,
    UserOptions,
    QRCodeTag
  },
  data() {
    return {
      clientData: {
        name: '',
        id: 0,
        credits: 0
      },
      numberOfMeal: 1,
      reportDates: {
        startDate: null,
        endDate: null
      },
      reportInfos: null,
      clientType: '',
      decodedString: null,
      showCamera: false,
      textWarning: '',
      confirmedSale: false,
      errorSales: false,
      showButton: true,
      userId: '',
      role: '',
      createdAt: '',
      roleStorage: '',
      errors: [],
      openUserOptions: false,
      QRValue: null,
      showPrint: false,
      getAllInfos: {}
    }
  },
  mounted() {
    this.getUser()
  },
  methods: {
    pagePrint(id) {
      const role = this.role ? this.role : 'estudante'
      this.$router.push(`print-etiquetas/${ id }/${ role }`)
    },
    pagePrintAll() {
      this.$router.push('print-todas-etiquetas/')
    },
    onDecode(decodedString) {
      this.decodedString = decodedString

      if (decodedString) {
        this.showCamera = !this.showCamera

        this.getInfo()
      }
    },

    getUser() {
      this.userId = jwt_decode(localStorage.getItem('token')).uid
      this.roleStorage = localStorage.getItem('role')
    },

    async getInfo() {
      const stringQrCode = this.decodedString.split('-')
      const [id, clientType] = stringQrCode
      this.clientType = clientType
      const {
        data: { message: response }
      } = await this.$http.get(`/show-infos/${ id }?clientType=${ clientType }`)
      this.clientData.name = response.full_name
      this.clientData.id = id
      this.clientData.credits = response.meal
      this.role = response.type
      this.getAllInfos = response
      if (this.clientData.credits > 0) {
        this.showButton = true
      } else {
        this.showButton = false
      }

      this.getSale()
    },

    async getSale() {
      const {
        data: { message: response }
      } = await this.$http.get(
        `/restaurant-history-last-sale/${ this.clientData.id }?clientType=${ this.clientType }`
      )
      if (response) {
        this.createdAt = response.created_at
        this.compareDate()
      } else {
        this.showButton = !this.showButton
      }
    },

    async updateMeal() {
      const body = {
        meal: this.clientData.credits - this.numberOfMeal,
        clientType: this.clientType
      }

      // await this.$http.put(`/change-quant-meal/${ this.clientData.id }`, body)
      const updMeal = await this.$http.put(`/change-quant-meal/${ this.clientData.id }`, body)

      //console.log(updMeal.data.status)
      // alert(updMeal.status)
      //console.log(updMeal)
      // return
      if (updMeal.data.status === 400) {
        this.textWarning = 'NÃO CONFIRMADO - Não há creditos suficientes'
        this.errorSales = true
      } else {
        await this.createSale()
        this.textWarning = 'Compra confirmada com sucesso!'
        this.confirmedSale = !this.confirmedSale
        setTimeout(() => {
          window.location.reload()
        }, 1)
      }
    },

    async createSale() {
      const body = {
        user_id: this.userId,
        consumer_name: this.clientData.name,
        student_id: this.clientType === 's' ? this.clientData.id : null,
        involved_id: this.clientType === 'i' ? this.clientData.id : null,
        type: this.role ? this.role : 'estudante',
        credits: 1
      }

      await this.$http.post('/restaurant-history/', body)
    },

    async reportGenerate() {
      this.resetErrors()
      if (!this.reportDates.startDate) {
        this.errors.push('startReportDates')
        this.textWarning = 'Todos os campos devem ser inseridos'
        return false
      }
      if (!this.reportDates.endDate) {
        this.errors.push('endReportDates')
        this.textWarning = 'Todos os campos devem ser inseridos'
        return false
      }
      if (!this.validateDate(this.reportDates.startDate)) {
        this.errors.push('startReportDates')
        return false
      }
      if (!this.validateDate(this.reportDates.endDate)) {
        this.errors.push('endReportDates')
        return false
      }
      if (this.reportDates.startDate > this.reportDates.endDate) {
        this.errors.push('startReportDates')
        this.textWarning = 'A data de inicio deve ser menor que a final'
        return false
      }

      const { startDate, endDate } = this.reportDates
      const starDateFormat = startDate.split('/').join('-')
      const endDateFormat = endDate.split('/').join('-')
      const {
        data: { message: response }
      } = await this.$http.get(
        `/restaurant-history-show/${ this.userId }?startDate=${ starDateFormat }&endDate=${ endDateFormat }`
      )
      this.reportInfos = response.map((item) => {
        const createdAt = item.created_at.toString().split(' ')
        createdAt[0] = createdAt[0].split('-').reverse().join('-')
        item.created_at = createdAt.join(' ')
        return item
      })
      this.reportDates.startDate = null
      this.reportDates.endDate = null
    },

    compareDate() {
      const currentDateTime = this.actualDateHour()
      const actualDate = currentDateTime.split(' ')
      const dateHourOfLastSale = this.createdAt.split(' ')
      const inicio = new Date(this.createdAt)
      const fim = new Date(currentDateTime)
      const diferenca = new Date(fim - inicio)

      if (actualDate[0] === dateHourOfLastSale[0]) {
        if (diferenca.toString().substring(19, 24) >= '01:00') {
          this.showButton = false
        } else {
          this.showButton = true
        }

        return false
      }
      this.showButton = false
    },

    actualDateHour() {
      const today = new Date()
      let date

      if (today.getMonth() >= 9 && today.getDate() >= 10) {
        date = `${ today.getFullYear() }-${
          today.getMonth() + 1
        }-${ today.getDate() }`
      }

      if (today.getMonth() < 9 && today.getDate() < 10) {
        date = `${ today.getFullYear() }-0${
          today.getMonth() + 1
        }-0${ today.getDate() }`
      }

      if (today.getMonth() < 9) {
        date = `${ today.getFullYear() }-0${
          today.getMonth() + 1
        }-${ today.getDate() }`
      }

      if (today.getDate() < 10) {
        date = `${ today.getFullYear() }-${
          today.getMonth() + 1
        }-0${ today.getDate() }`
      }

      const time = `${ today.getHours() }:${ today.getMinutes() }:${ today.getSeconds() }`
      const dateTime = `${ date } ${ time }`
      return dateTime
    },

    toggleConfirmedSale() {
      this.confirmedSale = !this.confirmedSale
    },

    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },

    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
      if (this.errorSales) {
        this.errorSales = false
      }
    },

    validateDateFormat(date) {
      const [year, month, day] = date.split('-')
      return Number(month) <= 12 && Number(day) <= 31 && year >= 1900
    },
    validateDate(date) {
      const formattedDate = date.split('/').reverse().join('-')

      if (!this.validateDateFormat(formattedDate)) {
        this.textWarning = 'Data Inválida!'
        return false
      }
      return true
    },
    async exportToExcel() {
      try {
        await this.$http
          .get(
            `/restaurant-history-by-id/${ this.userId }?role=${ this.roleStorage }`,
            {
              responseType: 'blob'
            }
          )
          .then((response) => {
            const nameFile = `Histórico Restaurante - ${ new Date().toLocaleDateString() }.xlsx`
            fileDownload(response.data, nameFile)
          })
      } catch (error) {
        console.error(error)
      }
    },
    printVue() {
      // this.showPrint = true
      printJS({
        printable: 'qrcodeContent',
        type: 'html',
        targetStyles: ['*']
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border-bottom: 1px solid #c1c1c1;
}

.container-app-restaurant {
  background-color: #f1f5f6;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 60px 60px;
  font-family: "Mulish", sans-serif;
}

@media only screen and (max-width: 420px) {
  .container-app-restaurant {
    padding: 30px 10px;
  }
}

.container-logo {
  width: 100%;
  margin-bottom: 40px;
}

.container-content {
  min-width: 100%;
  border-radius: 20px;
  margin-bottom: 24px;
  padding-bottom: 30px;
  background-color: #ffffff;
}

.container-area-restaurant {
  padding: 60px 84px 40px;
}

@media only screen and (max-width: 420px) {
  .container-area-restaurant {
    text-align: center;
  }
}

.qrcode {
  max-width: 100%;
  width: 100%;
  height: 248px;
}

.title-restaurant {
  color: #26768d;
  font-size: 28px;
  margin-bottom: 40px;
}

.subtitle-restaurant {
  color: #26768d;
  font-size: 20px;
  margin-bottom: 26px;
}

.container-scanner {
  border: 2px dashed #636b6f;
  border-radius: 16px;
  max-width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 22px;

  .camera {
    max-width: 120px;
    width: 100%;
    max-height: 120px;
    height: 100%;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 420px) {
  .container-scanner {
    height: 200px;
  }
}

.container-data {
  display: flex;
  flex-direction: column;
  padding: 25px 84px 40px;
}

@media only screen and (max-width: 420px) {
  .container-data {
    padding: 25px 25px 40px;
  }
}

.data-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

data-content-date {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.data-client {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.data-sale {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.report-data {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container-date {
  display: flex;
  gap: 20px;
}

.input-date {
  max-width: 130px;
  width: 100%;
}

.input-credits {
  border: 1px solid #a1a1a1;
  max-width: 60px;
  width: 100%;
  padding: 5px 0px 5px 14px;
  border-radius: 8px;
  color: #a1a1a1;
}

.input-credits-zerado {
  border: 1px solid #a1a1a1;
  max-width: 60px;
  width: 100%;
  padding: 5px 0px 5px 14px;
  border-radius: 8px;
  color: #a1a1a1;
  font-weight: 900;
  background-color: #FFC3B6;
}

.input-credits-valorado {
  border: 1px solid #a1a1a1;
  max-width: 60px;
  width: 100%;
  padding: 5px 0px 5px 14px;
  border-radius: 8px;
  color: #a1a1a1;
  font-weight: 900;
  background-color: #EEFFBC;
}

.container-btn {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-restaurant {
  margin-top: 30px;
  padding: 3px 12px !important;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: #1b778b;
  border-radius: 8px;
  transition: 0.25s;
  cursor: pointer;
}

.btn-restaurant:hover {
  opacity: 0.92;
}

.disabled {
  opacity: 0.5;
  cursor: default;

  &:hover {
    opacity: 0.5;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 50px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f1f5f6;
}

.header {
  @include d-flex-between;
  flex-wrap: wrap;
  background: #fdfdfd;
  border-radius: 29px;
  padding: 20px 30px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1017px) {
    justify-content: center;
  }

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }
  .menu {
    @include d-flex-center;
    flex-wrap: wrap;
    z-index: 1;
    button {
      cursor: pointer;
    }
    .border {
      &:not(:last-of-type) {
        @media screen and (min-width: 1015px) {
          border-right: 0.7px solid $primary;
        }
      }
    }

    @media screen and (max-width: 1017px) {
      margin-top: 20px;
    }
    &__item {
      @include d-flex-center;
      cursor: pointer;
      padding-right: 15px;
      padding-left: 15px;
      position: relative;

      @media screen and (max-width: 1017px) {
        padding-bottom: 5px;
      }

      &:hover {
        opacity: 0.7;
      }

      ::v-deep .router-link-active {
        font-weight: 600;
      }

      span {
        color: $primary;
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
}
</style>
