const educationalLevels = {
  1: 'ordinalFirst',
  2: 'ordinalSecond',
  3: 'ordinalThird',
  4: 'ordinalFourth',
  5: 'ordinalFifth',
  6: 'ordinalSixth',
  7: 'ordinalSeventh',
  8: 'ordinalEighth',
  9: 'ordinalNinth',
  estagio: 'internship',
  eja: 'yae',
  maternal: 'maternal',
  bercario: 'nursery',
  pre: 'kindergarten'
}

export default educationalLevels
