var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"inner__wrapper"},[_c('div',{staticClass:"header"},[_c('h3',[_vm._v("Dados da escola")]),(_vm.onlyAdmin === 'admin')?_c('div',[(!_vm.open)?_c('button',{staticClass:"button_edit",on:{"click":function($event){_vm.open = !_vm.open}}},[_c('strong',[_vm._v("EDITAR")])]):_vm._e(),_c('div',{staticClass:"cancel-and-save"},[(_vm.open)?_c('button',{staticClass:"button_cancel",on:{"click":_vm.cancelSave}},[_vm._v("CANCELAR")]):_vm._e(),(_vm.open)?_c('button-default',{staticClass:"button_save",attrs:{"onClick":_vm.checkForm}},[_vm._v("Salvar")]):_vm._e()],1)]):_vm._e()]),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"info__item"},[_vm._m(0),_c('div',{staticClass:"data__wrapper"},[_c('div',{staticClass:"data__row"},[_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v("Nome da instituição/escola")]),(_vm.open)?_c('input-default',{class:[
                  { warning: _vm.checkErrorIfContains('school_name') },
                  'input__space' ],attrs:{"type":"text"},model:{value:(_vm.fairInfo.school_name),callback:function ($$v) {_vm.$set(_vm.fairInfo, "school_name", $$v)},expression:"fairInfo.school_name"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.school_name))])],1),_c('div',{staticClass:"data__right"},[_c('h4',[_vm._v("Categoria de ensino")]),(_vm.open)?_c('RadioGroup',{class:['mb-1'],attrs:{"options":_vm.options},model:{value:(_vm.fairInfo.school_type),callback:function ($$v) {_vm.$set(_vm.fairInfo, "school_type", $$v)},expression:"fairInfo.school_type"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.school_type === "private" ? "Particular" : "Pública"))])],1)]),_c('div',{staticClass:"data__row"},[_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v("Nome do responsável da instituição/escola")]),(_vm.open)?_c('input-default',{class:[
                  { warning: _vm.checkErrorIfContains('coordinator_name') },
                  'input__space' ],attrs:{"max":"100","type":"text"},model:{value:(_vm.fairInfo.coordinator_name),callback:function ($$v) {_vm.$set(_vm.fairInfo, "coordinator_name", $$v)},expression:"fairInfo.coordinator_name"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.coordinator_name))])],1),_c('div',{staticClass:"data__right"},[_c('h4',[_vm._v("E-mail da instituição/escola")]),(_vm.open)?_c('input-default',{class:[
                  { warning: _vm.checkErrorIfContains('school_email') },
                  'input__space' ],attrs:{"type":"text"},model:{value:(_vm.fairInfo.school_email),callback:function ($$v) {_vm.$set(_vm.fairInfo, "school_email", $$v)},expression:"fairInfo.school_email"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.school_email))])],1)])])]),_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t("address")))])]),_c('div',{staticClass:"data__wrapper"},[_c('div',{staticClass:"data__row"},[(_vm.fairInfo.country === 'Brazil')?_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v("CEP")]),(_vm.open)?_c('input-masked',{class:[
                  { warning: _vm.checkErrorIfContains('zipcode') },
                  'input__space' ],attrs:{"required":"","id":"zipCode","type":"text","mask":['#####-###']},model:{value:(_vm.fairInfo.zipcode),callback:function ($$v) {_vm.$set(_vm.fairInfo, "zipcode", $$v)},expression:"fairInfo.zipcode"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.zipcode))])],1):_vm._e(),_c('div',{staticClass:"data__right"},[_c('h4',[_vm._v(_vm._s(_vm.$t("uf")))]),(_vm.open)?_c('div',[(_vm.fairInfo.country === 'Brazil')?_c('input-select',{class:['input__space'],attrs:{"id":"serie","items":_vm.states,"type":"text"},model:{value:(_vm.fairInfo.state),callback:function ($$v) {_vm.$set(_vm.fairInfo, "state", $$v)},expression:"fairInfo.state"}}):_vm._e(),(_vm.fairInfo.country && _vm.fairInfo.country !== 'Brazil')?_c('input-default',{class:['input__space'],attrs:{"id":"state","type":"text"},model:{value:(_vm.fairInfo.state),callback:function ($$v) {_vm.$set(_vm.fairInfo, "state", $$v)},expression:"fairInfo.state"}}):_vm._e()],1):_c('span',[_vm._v(_vm._s(_vm.fairInfo.state))])])]),_c('div',{staticClass:"data__row"},[_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v(_vm._s(_vm.$t("city")))]),(_vm.open)?_c('div',[(_vm.fairInfo.country === 'Brazil')?_c('input-select',{class:['input__space'],attrs:{"id":"serie","items":_vm.cities,"selected":_vm.fairInfo.city},model:{value:(_vm.fairInfo.city),callback:function ($$v) {_vm.$set(_vm.fairInfo, "city", $$v)},expression:"fairInfo.city"}}):_vm._e(),(_vm.fairInfo.country !== 'Brazil')?_c('input-default',{class:['input__space'],attrs:{"id":"serie","type":"text"},model:{value:(_vm.fairInfo.city),callback:function ($$v) {_vm.$set(_vm.fairInfo, "city", $$v)},expression:"fairInfo.city"}}):_vm._e()],1):_c('span',[_vm._v(_vm._s(_vm.fairInfo.city))])]),_c('div',{staticClass:"data__right"},[_c('h4',[_vm._v(_vm._s(_vm.$t("address")))]),(_vm.open)?_c('input-default',{class:[
                  { warning: _vm.checkErrorIfContains('street') },
                  'input__space' ],attrs:{"id":"address","type":"text","maxlength":100},model:{value:(_vm.fairInfo.street),callback:function ($$v) {_vm.$set(_vm.fairInfo, "street", $$v)},expression:"fairInfo.street"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.street))])],1)]),_c('div',{staticClass:"data__row"},[_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v("Número")]),(_vm.open && _vm.fairInfo.state)?_c('input-default',{class:[
                  { warning: _vm.checkErrorIfContains('house_number') },
                  'input__space' ],attrs:{"id":"house_number","type":"text"},model:{value:(_vm.fairInfo.house_number),callback:function ($$v) {_vm.$set(_vm.fairInfo, "house_number", $$v)},expression:"fairInfo.house_number"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.house_number))])],1),_c('div',{staticClass:"data__right"},[_c('h4',[_vm._v("Complemento")]),(_vm.open)?_c('input-default',{class:['input__space'],attrs:{"id":"complement","type":"text"},model:{value:(_vm.fairInfo.complement),callback:function ($$v) {_vm.$set(_vm.fairInfo, "complement", $$v)},expression:"fairInfo.complement"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.complement ? _vm.fairInfo.complement : _vm.$t("notInformed")))])],1)]),_c('div',{staticClass:"data__row"},[_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v(_vm._s(_vm.$t("neighborhood")))]),(_vm.open)?_c('input-default',{class:[
                  { warning: _vm.checkErrorIfContains('neighborhood') },
                  'input__space' ],attrs:{"id":"neighborhood","type":"text"},model:{value:(_vm.fairInfo.neighborhood),callback:function ($$v) {_vm.$set(_vm.fairInfo, "neighborhood", $$v)},expression:"fairInfo.neighborhood"}}):_c('span',[_vm._v(_vm._s(_vm.fairInfo.neighborhood ? _vm.fairInfo.neighborhood : _vm.$t("notInformed")))])],1)])])]),_c('div',{staticClass:"info__item"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.$t("contacts")))])]),_c('div',{staticClass:"data__wrapper"},_vm._l((_vm.contacts),function(item,index){return _c('div',{key:index},[_c('h3',[_vm._v("Contato "),(item.main)?_c('span',[_vm._v(" (Principal)")]):_vm._e()]),_c('div',{staticClass:"data__row"},[_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v(_vm._s(_vm.$t("name")))]),(_vm.open)?_c('input-default',{class:[
                    { warning: _vm.checkErrorIfContains('contact_name') },
                    'input__space' ],attrs:{"type":"text"},model:{value:(item.contact_name),callback:function ($$v) {_vm.$set(item, "contact_name", $$v)},expression:"item.contact_name"}}):_c('span',[_vm._v(_vm._s(item.contact_name))])],1),_c('div',{staticClass:"data__right"},[_c('h4',[_vm._v("E-mail")]),(_vm.open)?_c('input-default',{class:[
                    { warning: _vm.checkErrorIfContains('email') },
                    'input__space' ],attrs:{"type":"text"},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}):_c('span',[_vm._v(_vm._s(item.email))])],1)]),_c('div',{staticClass:"data__row"},[_c('div',{staticClass:"data__left"},[_c('h4',[_vm._v("Telefone")]),(_vm.open)?_c('input-default',{class:[
                    { warning: _vm.checkErrorIfContains('phone') },
                    'input__space' ],attrs:{"type":"text"},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}}):_c('span',[_vm._v(_vm._s(item.phone))])],1),_c('div',{staticClass:"data__right"},[_c('h4',[_vm._v("Cargo")]),(_vm.open)?_c('input-default',{class:[
                    { warning: _vm.checkErrorIfContains('office') },
                    'input__space' ],attrs:{"type":"text"},model:{value:(item.office),callback:function ($$v) {_vm.$set(item, "office", $$v)},expression:"item.office"}}):_c('span',[_vm._v(_vm._s(item.office))])],1)])])}),0)])])]),(_vm.errors.length)?_c('feedback-warning',{attrs:{"text":_vm.textWarning,"onClick":_vm.resetErrors,"color":"#ff9800"}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("Dados gerais")])])}]

export { render, staticRenderFns }