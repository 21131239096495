<template>
  <div class="breadcrumb">
    <div class="submenu">
      <span>
        Solicitações de inscrição :
      </span>
    </div>
    <div class="items">
      <span
        :class="{active: active == 'tableListProjectSolicitations'}"
        @click="menuSelected('tableListProjectSolicitations')"
      >
        Projetos
      </span>
      <span
       v-if="(role != 'crc')"
        :class="{active: active == 'tableListSolicitations'}"
        @click="menuSelected('tableListSolicitations')"
      >
        Feiras afiliadas
      </span>
      </div>
      <div class="export"><span >Exportar:</span></div>
      <div class="items">
      <span v-if="(role != 'crc')" @click="exportToExcelProjects">Projetos</span>
      <span v-if="(role != 'crc')" @click="exportToExcelRestaurants">Histórico dos restaurantes</span>
      <span v-if="(role != 'crc')" @click="exportToExcelAttendance">Lista de presença</span>
      <span v-if="(role != 'crc')" @click="exportToExcelFairs">Feiras Afiliadas</span>
    </div>
    </div>
</template>

<script>
import fileDownload from 'js-file-download'

export default {
  name: 'List',
  data: () => ({
    active: 'tableListProjectSolicitations',
    role: ''
  }),
  mounted() {
    this.role = localStorage.getItem('role')
  },
  methods: {
    menuSelected(tab) {
      this.active = tab
      this.$emit('menuSelected', tab)
    },
    async exportToExcelProjects() {
      try {
        const { id: currentMostratecId } = await this.getMostratec()
        await this.$http.get(`/export-projects/${ currentMostratecId }`, {
          responseType: 'blob'
        }).then((response) => {
          const nameFile = `Projetos - ${ new Date().toLocaleDateString() }.xlsx`
          fileDownload(response.data, nameFile)
        })
      } catch (error) {
        console.error(error)
      }
    },
    async exportToExcelRestaurants() {
      try {
        const { id: currentMostratecId } = await this.getMostratec()
        // await this.$http.get(`/restaurant-history-by-id/123?role=${ this.role }}`, {
        // await this.$http.get(`/restaurant-history-by-id/123?role=${ this.role }/${currentMostratecId}`, {
        await this.$http.get(`/restaurant-history-by-id/123?role=${ this.role }&mid=${ currentMostratecId }`, {
          responseType: 'blob'
        }).then((response) => {
          const nameFile = `Histórico Restaurante - ${ new Date().toLocaleDateString() }.xlsx`
          fileDownload(response.data, nameFile)
        })
      } catch (error) {
        console.error(error)
      }
    },
    async exportToExcelAttendance() {
      try {
        const { id: currentMostratecId } = await this.getMostratec()
        await this.$http.get(`/attendance/${ currentMostratecId }`, {
          responseType: 'blob'
        }).then((response) => {
          const nameFile = `Lista de Presença - ${ new Date().toLocaleDateString() }.xlsx`
          fileDownload(response.data, nameFile)
        })
      } catch (error) {
        console.error(error)
      }
    },
    async exportToExcelFairs() {
      try {
        const { id: currentMostratecId } = await this.getMostratec()
        await this.$http.get(`/export-fairs/${ currentMostratecId }`, {
          responseType: 'blob'
        }).then((response) => {
          const nameFile = `Lista de Feiras - ${ new Date().toLocaleDateString() }.xlsx`
          fileDownload(response.data, nameFile)
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getMostratec() {
      try {
        const { data: response } = await this.$http.get('/current-mostratec')
        this.mostratec = response
        return this.mostratec
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  align-items: center;
  background-color: $white;
  border-radius: 0px 29px 29px 0px;
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 20px;
  padding: 10px 20px;

  @media screen and (max-width: 320px) {
    flex-direction: column;
  }

  .submenu {
    @include d-flex-center;

    @media screen and (min-width: 321px) {
      padding-right: 15px;
    }

    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $primary;
    }
  }

  .items {
    @include d-flex-center;

    @media screen and (min-width: 321px) {
      padding-left: 20px;
    }

    span {
      color: $primary;
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      padding-right: 20px;

      &:hover {
        opacity: 0.7;
      }
    }

    .active {
      font-weight: 600
    }
  }

    .export {
      border-left: 0.7px solid #1B778B;
      font-weight: 700;
      padding-left: 20px;
      padding-right: 10px;
      color: $primary;
      font-size: 16px;
  }
}
</style>
