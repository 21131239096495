<template>
  <div class="info-wrapper">
    <div class="info-box">
      <heading-three class="head_title">
        {{content}}
      </heading-three>
    </div>
    <LineDefault
      color='gray'
    />
  </div>
</template>

<script>
import { HeadingThree, StatusWithTooltip, LineDefault } from '@/components/atoms'

export default {
  components: { HeadingThree, StatusWithTooltip, LineDefault },
  name: 'TitleStatusPair',
  props: {
    content: {
      type: String,
      required: true
    },
    statusText: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.badges{
  display: block;
}

.status{
  margin:5px 0px;
}

.info-wrapper{
  display: flex;
  flex-direction: column;
  width: 98%;
}
.info-box {
  width: 96%;
  justify-content: space-between;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
}

.head_title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  width: 42%;
  margin: 10px 0px;
  line-height: 20px;
  text-transform: uppercase;
  color: #686868;
}

</style>
