<template>
  <div class="container-app">

    <div class="wrapper">
      <form-request />
    </div>
  </div>
</template>

<script>
import { FormRequest } from '@/components/organisms'

export default {
  name: 'FormRequestTemplate',
  components: { FormRequest },
  data: () => ({
    fields: ''
  }),
  methods: {
    menuSelected(e) {
      this.fields = e
    }
  }
}
</script>

<style lang="scss" scoped>

.container-app {
  background-color: #F1F5F6;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px 50px;

  .wrapper {
    background: #FDFDFD;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin-top: 25px;
    opacity: 0.9;
    padding: 25px 28px;
    width: 100%;
  }
}
</style>
