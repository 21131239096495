<template>
  <div class="edit-user">
    <div class="container">
      <div class="header">
        <h1>Deseja excluir {{text}}?</h1>
        <div class="close" @click="close">
          <img :src="require('@/assets/close-dark.svg')" />
        </div>
      </div>

      <div class="name-user">
        <h2>{{data.name}}</h2>
      </div>

      <div class="buttons">
        <button class="cancel" @click="close">CANCELAR</button>
        <button-default :onClick="handleDelete" class="">{{$t('delete')}}</button-default>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonDefault from '@/components/atoms/Buttons/ButtonDefault.vue'

export default {
  name: 'ModalDelete',
  data() {
    return {
      modal: false
    }
  },
  components: {
    ButtonDefault
  },
  props: {
    data: Object,
    text: String
  },

  methods: {
    handleDelete() {
      this.$emit('openModal', 'loading', true)
      this.$emit('delete')

      this.modal = true
      this.close()
    },

    close() {
      this.$emit('close', false)
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  }
}
</script>
<style lang="scss" scoped>
.edit-user {
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  .container {
    max-width: 830px;
    padding: 25px 39px;
    border-radius: 20px;
    background-color: $white;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }
}
.buttons {
  display: flex;
  justify-content: space-between;
}
.cancel {
  width: 229px;
  height: 50px;
  color: $primary;
  font-weight: bold;
  border-radius: 10px;
  font-size: 18px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
  outline: none;
  &:hover {
    opacity: .8;
  }
}

.name-user {
  margin: 20px 0px 50px;
}
</style>
