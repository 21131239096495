<template>
  <div class="container-app">
    <HeaderDefault />
    <FairVacanciesTemplate />
  </div>
</template>

<script>
import { HeaderDefault } from '@/components/organisms'
import { FairVacanciesTemplate } from '@/components/templates'

export default {
  name: 'panel-fair-vacancies',
  components: { HeaderDefault, FairVacanciesTemplate }
}
</script>
