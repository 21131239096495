<template>
  <section>
    <filter-default
      :anyCheckboxEnabled="anyCheckboxEnabled"
      @filterAffiliationRequestsList="getAffiliationRequests"
      @setList="setList"
    />
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Código</th>
            <th>Status da solicitação</th>
            <th>País/Estado</th>
            <th>Nome da Feira</th>
            <th>Tipo de escola</th>
            <th>Infantil</th>
            <!-- th>Fundamental</th -->
            <th>F Cat1</th>
            <th>F Cat2</th>
            <th>F Cat3</th>
            <th>F Eja</th>
            <th>Méd/Tec</th>
            <th>Formulário</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in affiliationRequests" :key="index">
            <td v-if="waitingResponse === 'aguardando resposta'" class="checkbox">
              <input
                v-if="item.status==='Aguardando Resposta'"
                type="checkbox"
                name="row1"
                id="row1"
                value="row1"
                v-model="selected[`id${item.id}`]"
                @click="checkboxClicked(item.email, $event.target.checked)"
              />
            </td>
            <td v-else></td>
            <td class="code">
              <strong>#{{item.fair_id}}</strong>
            </td>
            <td class="status">
              <span :class="item.status_class">{{item.status ? item.status : "N/A"}}</span>
              <span
                class="badge green_accept ml-2"
                v-if="item.status_is_active === true && item.is_active === true"
              >Aceita</span>
              <span
                v-else-if="item.status_is_active == false && item.is_active == true"
                class="badge red_reject ml-2"
              >Não Aceita</span>
            </td>
            <td class="country">
              <span>{{item.country}}</span>
            </td>
            <td class="fair_name">
              <span>{{item.fair_name}}</span>
            </td>
            <td class="type-school">
              <span>{{item.school_type}}</span>
            </td>
            <td class="infantil">
              <span>{{item.grade_vacancies}}</span>
            </td>
            <!-- td class="fundamental">
              <span>{{item.elementary_vacancies}}</span>
            </td -->
            <td class="fundamentalC1">
              <span>{{item.cat1_vacancies}}</span>
            </td>
            <td class="fundamentalC2">
              <span>{{item.cat2_vacancies}}</span>
            </td>
            <td class="fundamentalC3">
              <span>{{item.cat3_vacancies}}</span>
            </td>
            <td class="fundamentalC4">
              <span>{{item.catEja_vacancies}}</span>
            </td>
            <td class="medio">
              <span>{{item.high_vacancies}}</span>
            </td>
            <td class="action">
              <v-icon color="#1B778B" large @click="detailItem(item.id)">mdi-chevron-right</v-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <v-container fluid>
          <v-row justify="end">
            <v-pagination v-model="page" class="my-4" :length="lastPage"></v-pagination>
          </v-row>
          <v-row class="mr-0" justify="end">
            <span
              class="pagination__text"
            >{{page == 1 ? '1' : ((page - 1) * perPage) + 1}} - {{page * perPage}} de {{total}}</span>
          </v-row>
        </v-container>
      </div>
    </div>

    <modal-loading @toggleLoading="modalLoading = !modalLoading" :modal="modalLoading" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { ModalLoading, FilterDefault } from '@/components/organisms'
import { ButtonDefault } from '@/components/atoms'

export default {
  name: 'TableListSolicitations',
  components: { ModalLoading, FilterDefault, ButtonDefault },
  computed: {
    ...mapGetters({
      count: 'list/countDone',
      lang: 'lang',
      affiliationRequestFilter: 'affiliationRequestFilter',
      affiliationSendMails: 'affiliationSendMails'
    }),
    affiliationSendMails: {
      get() {
        return this.affiliationSendMails
      },
      set(newName) {
        return newName
      }
    }
  },
  data: () => ({
    modalLoading: false,
    waitingResponse: 'all',
    active: '',
    page: 1,
    total: 0,
    perPage: 30,
    lastPage: 1,
    selected: {},
    selectedPerpage: [],
    affiliationRequests: [],
    anyCheckboxEnabled: false
  }),
  mounted() {
    this.getAffiliationRequests()
  },
  watch: {
    page() {
      this.getAffiliationRequests()
    },
    waitingResponse() {
      this.getAffiliationRequests()
    }
  },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage',
      affiliationListFilter: 'affiliationListFilter',
      affiliationSendMails: 'affiliationSendMails'
    }),
    detailItem(id) {
      localStorage.setItem('affiliationRequestId', id)
      this.$router.push({ name: 'AnalisisSolicitations', params: { id } })
    },
    async getAffiliationRequests() {
      const filtros = await this.$store.getters.affiliationRequestFilter
      const requests = await this.$http.post(`/fair/search/all?page=${ this.page }&limit=${ this.perPage }`, filtros)

      this.total = requests.data.total
      this.lastPage = requests.data.lastPage
      const tableContent = []
      let countryState = ''

      requests.data.data.forEach((item, index) => {
        if (item.state) {
          countryState = `${ item.country } / ${ item.state }`
        } else countryState = item.country
        tableContent.push({
          id: item.id,
          fair_id: item.fair_id,
          status: this.getStatusName(item.status, item.is_active),
          status_class: this.getStatusClass(item.status, item.is_active),
          status_is_active: item.status_is_active,
          is_active: item.is_active,
          country: countryState,
          fair_name: item.fair_name,
          school_type: this.getSchoolType(item.school_type),
          email: this.getMainEmail(item.fair_contacts),
          grade_vacancies: 0,
          elementary_vacancies: 0,
          cat1_vacancies: 0,
          cat2_vacancies: 0,
          cat3_vacancies: 0,
          catEja_vacancies: 0,
          high_vacancies: 0
        })
        item.vacancies_requests.forEach((i) => {
          if (i.level === 'grade') {
            tableContent[index].grade_vacancies = i.quantity_registered
          }
          if (i.level === 'middle') {
            tableContent[index].elementary_vacancies = i.quantity_registered
          }
          if (i.level === 'middle_cat_1') {
            tableContent[index].cat1_vacancies = i.quantity_registered
          }
          if (i.level === 'middle_cat_2') {
            tableContent[index].cat2_vacancies = i.quantity_registered
          }
          if (i.level === 'middle_cat_3') {
            tableContent[index].cat3_vacancies = i.quantity_registered
          }
          if (i.level === 'middle_cat_eja') {
            tableContent[index].catEja_vacancies = i.quantity_registered
          }
          if (i.level === 'high') {
            tableContent[index].high_vacancies = i.quantity_registered
          }
        })
      })
      this.affiliationRequests = tableContent
    },
    getStatusName(stat, isActive) {
      if (isActive !== false) {
        const statusList = {
          'inscricao em andamento': 'Inscrição',
          'pendente de analise': 'Pendente de Análise',
          'em analise': 'Em Análise',
          'aguardando resposta': 'Aguardando Resposta',
          'resposta enviada': 'Resposta Enviada',
          'nao aceita': 'Não Aceita',
          aceita: 'Aceita'
        }
        return statusList[stat]
      }
      return 'Desativada'
    },
    getStatusClass(stat, isActive) {
      if (isActive === false) return 'badge orange_inactive'
      if (stat === 'inscricao em andamento') return 'badge blue_analisis'
      if (stat === 'pendente de analise') return 'badge warn'
      if (stat === 'em analise') return 'badge blue_analisis'
      if (stat === 'aguardando resposta') return 'badge purple_waiting'
      if (stat === 'resposta enviada') return 'badge blue_send'
      if (stat === 'aceita') return 'badge green_accept'
      if (stat === 'nao aceita') return 'badge red_reject'
    },
    getSchoolType(type) {
      if (type === 'private') return 'Particular'
      return 'Pública'
    },
    checkboxClicked(email, value) {
      let emails = this.$store.getters.affiliationSendMails
      if (!emails.includes(email) && value === true) {
        emails.push(email)
        this.$store.commit('AFILLIATION_SEND_MAILS', emails)
      }
      if (value === false) {
        const filteredEmails = emails.filter((i) => i !== email)
        emails = filteredEmails
        this.$store.commit('AFILLIATION_SEND_MAILS', filteredEmails)
      }
      if (emails.length !== 0) {
        return (this.anyCheckboxEnabled = true)
      }
      this.anyCheckboxEnabled = false
    },
    setList(e) {
      this.waitingResponse = e
    },
    getMainEmail(contacts) {
      const main = contacts.find((item) => item.main)
      if (main) {
        return main.email
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  display: grid;
  overflow-x: auto;
  border-collapse: collapse;
  grid-template-columns:
    minmax(30px, 30px)
    minmax(60px, 80px)
    minmax(160px, 1fr)
    minmax(90px, 90px)
    minmax(180px, 1fr)
    minmax(108px, 110px)
    minmax(55px, 55px)
    minmax(55px, 55px)
    minmax(55px, 55px)
    minmax(55px, 55px)
    minmax(55px, 55px)
    minmax(55px, 55px)
    minmax(80px, 80px);
}

thead,
tbody,
tr {
  display: contents;
}

th,
td {
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

th {
  align-items: center;
  background-color: #dbe9ec;
  font-weight: 600;
  color: $primary;
  display: flex;
  font-size: 12px;
  position: relative;
  height: 60px;
  text-align: left;
}

th:first-child {
  border-radius: 10px 0px 0px 0px;
}

th:last-child {
  border-radius: 0px 10px 0px 0px;
}

tbody tr td {
  align-items: center;
  display: flex;
  color: $almostBlack;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

tbody tr:nth-child(odd) td {
  background-color: #f6f6f6;
}

tbody tr:nth-child(even) td {
  background-color: $white;
}

tbody tr td:first-child {
  @include d-flex-center;
}

.pagination__text {
  font-size: 14px;
  line-height: 18px;
  color: #6b6b6b;
}

::v-deep .v-pagination__item {
  box-shadow: none !important;
  color: #b5b5b5 !important;
  font-size: 14px !important;
  height: 30px !important;
  line-height: 16px !important;
  min-width: 15px !important;
}

::v-deep .v-pagination__item--active {
  color: $white !important;
}

::v-deep .v-pagination__navigation {
  box-shadow: none !important;
  height: 30px !important;
  line-height: 16px !important;
  width: 15px !important;
  outline: 0;
}
</style>
