<template>
  <div class="stepper__container">
    <v-stepper v-model="element" alt-labels>
      <v-stepper-header>
        <template v-for="(step, index) in steps">
          <v-stepper-step
          :complete="element > index + 1" :step="index + 1" :key="`${index}-${step}`">
            {{$t(step)}}
          </v-stepper-step>
          <v-divider :key="`${index}:${step}`" v-if="index < steps.length - 1"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="(component, index) in components" :step="index + 1" :key="`${index}-${component}`">
          <component :is="component" @changeElement="changeElement" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import {
  FormSchoolData,
  FormSchoolDataAboutFair,
  FormSchoolDataAboutFairGeneral,
  FormSchoolDataAddress,
  FormSchoolDataContacts,
  FormSchoolDataCurrentFair,
  FormSchoolDataLastFairEdition,
  FormSchoolDataRequestVacancies,
  FormSchoolDataTerm
} from './FormRequestTabs'

export default {
  name: 'FormRequest',
  prop: [
    'currentStatus'
  ],
  components: {
    FormSchoolData,
    FormSchoolDataAddress,
    FormSchoolDataContacts,
    FormSchoolDataCurrentFair,
    FormSchoolDataRequestVacancies,
    FormSchoolDataTerm,
    FormSchoolDataAboutFair,
    FormSchoolDataAboutFairGeneral,
    FormSchoolDataLastFairEdition
  },
  data: () => ({
    mostratec: null,
    fairs: [],
    subscriptionInProgress: [],
    element: 1,
    steps: ['schoolData', 'address', 'contacts', 'currentFair', 'fairEdition', 'aboutYourFair', 'generalInfos', 'vacanciesRequestsInPlural', 'responsibilityTerm'],
    components: ['form-school-data', 'form-school-data-address', 'form-school-data-contacts', 'form-school-data-current-fair', 'form-school-data-last-fair-edition', 'form-school-data-about-fair', 'form-school-data-about-fair-general', 'form-school-data-request-vacancies', 'form-school-data-term']
  }),
  mounted() {
    this.getMostratec()
    this.getFairs()
    this.checkExitingFairs()

    if (this.$route.params.currentStatus == 'inscricao em andamento') {
      this.element = this.subscriptionInProgressCurrentScreen()
    }
  },
  methods: {
    async checkExitingFairs() {
      const { id: currentMostratecId } = await this.getMostratec()
      for (const currentFair of this.fairs) {
        if (currentFair.mostratec_id === currentMostratecId) {
          this.$router.push('/panel-fair-vacancies')
        }
      }
    },
    async getFairs() {
      try {
        const { data: response } = await this.$http.get('/fair')
        this.fairs = response
        if (window.localStorage.getItem('fairAffiliatonData')) {
          this.element = this.subscriptionInProgressCurrentScreen()
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getMostratec() {
      try {
        const { data: response } = await this.$http.get('/current-mostratec')
        this.mostratec = response
        return this.mostratec
      } catch (error) {
        console.error(error)
      }
    },
    subscriptionInProgressCurrentScreen() {
      const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
      if (!currentScreen) currentScreen = 1
      return currentScreen
    },
    changeElement(value) {
      if (value === 'up') return this.element += 1
      if (value === 'down') return this.element -= 1
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.v-steeper {
  box-shadow: none !important;
}

.stepper__container {
  ::v-deep .v-stepper__label {
    text-align: center !important;
    font-size: 14px;
    line-height: 120%;
    color: $primaryDark !important;
    opacity: 0.8;
  }

  ::v-deep .v-stepper__step--complete + hr {
    border-color: $primary !important;
  }

  ::v-deep .v-stepper__header {
    box-shadow: none !important;
  }

  ::v-deep .v-stepper {
    box-shadow: none !important;
  }

}
</style>
