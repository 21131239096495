<template>
  <div v-if="modal" class="modal__outer" @click.self="close">
    <div class="modal__inner">
      <div class="header">
        <div class="close">
          <img
            @click="close"
            :src="require('@/assets/close-dark.svg')"
          />
        </div>
      </div>
      <div class="filter">
        <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
        <span>Filtrar</span>
      </div>
      <div class="modal__body">
        <input-auto-complete
          :class="['mb-7']"
          id="country"
          :value= form.countries
          :items="countries"
          :label="'País'"
          @input="form.countries=$event"
        />
        <div class="margin-negative">
          <template v-for="(coun,index) in country">
            <div class="badges" :key="index">
              <span>
                {{coun}}
                <v-icon
                  @click="removeItem(index, country)"
                  medium color="white">
                    mdi-close-circle
                </v-icon>
              </span>
            </div>
          </template>
        </div>
        <template v-if="validIsBrazil()">
          <input-auto-complete
            :class="['mb-7']"
            id="state"
            :value= form.states
            :items="states"
            :label="'Estado'"
            @input="form.states=$event"
          />
          <div class="margin-negative">
            <template v-for="(stat,index) in state">
              <div class="badges" :key="index">
                <span>
                  {{stat}}
                  <v-icon
                    @click="removeItem(index, state)"
                    medium color="white">
                      mdi-close-circle
                  </v-icon>
                </span>
              </div>
            </template>
          </div>
        </template>
        <div class="button mt-10 text-center">
          <button-default class="button" :onClick="filter">{{$t('filter')}}</button-default>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InputDefault, ButtonDefault, InputAutoComplete } from '@/components/atoms'
import { HeaderModal } from '@/components/organisms'
import { countries, states } from '@/utils/locations'

export default {
  name: 'ModalFilterProjects',
  data() {
    return {
      form: {
        countries: '',
        states: ''
      },
      country: [],
      state: [],
      countries,
      states
    }
  },
  watch: {
    modal() {
      this.country = []
      this.state = []
      this.form.countries = ''
      this.form.states = ''
    },
    'form.countries': function () {
      if (!this.form.countries) {
        return
      }
      const inside = this.country.indexOf(this.form.countries)
      if (inside === -1) {
        this.country.push(this.form.countries)
      }
    },
    'form.states': function () {
      const inside = this.state.indexOf(this.form.states)
      if (inside === -1) {
        this.state.push(this.form.states)
      }
    }
  },
  components: {
    HeaderModal,
    InputDefault,
    ButtonDefault,
    InputAutoComplete
  },
  props: {
    modal: Boolean
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    filter() {
      this.$emit('filter', { country: this.country, state: this.state })
      this.close()
    },
    removeItem(index, arrayItens) {
      arrayItens.splice(index, 1)
    },
    validIsBrazil() {
      if (this.country.length === 1 && this.country[0] === 'Brazil') {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  left: 0px;
  position: fixed;
  height: 100%;
  top: 0px;
  width: 100%;
  z-index: 5;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding: 25px;
    flex: 1;
    width: 649px;

    .modal__body {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      width: 100%;
      max-height: 100%;

      .search__inputs {
        margin: 5px 0px 15px 0px;

        .search__item {
          .badges {
            margin-top: 15px;

            span {
              align-items: center;
              display: inline-flex;
              justify-content: center;
              background: $primary;
              border-radius: 49px;
              opacity: 0.8;
              color: $white;
              font-weight: bold;
              font-size: 14px;
              min-width: 79px;
              height: 28px;
              padding: 0px 8px;

              &:not(:last-of-type) {
                margin-right: 10px;
              }

              button {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.filter {
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: $primary;
  border-bottom: 1px solid $primary;
  padding-bottom: 12px;
}
.close {
  display: flex;
  justify-content: flex-end;
  img {
    cursor: pointer;
  }
}

.badges {

  margin: 15px 5px 0 0;

  .v-icon {
    margin-left: 5px;
  }

  span {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    background: $primary;
    border-radius: 49px;
    opacity: 0.8;
    color: $white;
    font-weight: bold;
    font-size: 14px;
    padding: 0px 8px;
    height: 28px;
    white-space: nowrap;

    &:not(:last-of-type) {
      margin-right: 10px;
    }

    button {
      margin-left: 5px;
    }
  }
}

.margin-negative {
  margin: -30px 0 20px 0;
  display: flex;
  flex-wrap: wrap;
}
</style>
