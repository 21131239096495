<template>
  <span
    class="summary-text"
    :style="{color, fontWeight: weight}"
  >
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'SummaryText',
  props: {
    color: String,
    weight: {
      type: String,
      default: 'normal'
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-text {
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  letter-spacing: 1px;
}
</style>
