import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './modules/userModule'
import listModule from './modules/listModule'
import projectModule from './modules/projectModule'
import {
  CHANGE_LANGUAGE,
  CHANGE_EDITION,
  EDITION_VACANCIES_SET,
  AFILLIATION_REQUEST,
  AFILLIATION_LIST_FILTER,
  PROJECT_LIST_FILTER,
  EDITION_LIST_FILTER,
  AFILLIATION_SEND_MAILS,
  PROJECT_SELECTION_REQUEST,
  FAIR_AFFILIATION_ANALYSIS_ACCEPT,
  MOSTRATEC_INFO,
  PROJECTS_STATUS_CHANGE,
  PROJECT_SEND_MAILS,
  SELECTED_PROJECT,
  PROJECT_LIST,
  UPDATE_PROJECT_LIST
} from './mutation-types'

Vue.use(Vuex)

const initLang = (() => {
  const lang = window.localStorage.getItem('lang') || window.navigator.language
  const responseAccept = ['pt', 'en', 'es']
  return responseAccept.includes(lang) ? lang : 'pt'
})()

const initFairAffiliation = () => {
  const data = JSON.parse(localStorage.getItem('fairAffiliatonData'))
  return data || {}
}

const initProjectSelection = () => {
  const data = JSON.parse(localStorage.getItem('projectSelectionData'))
  return data || {}
}

const store = new Vuex.Store({
  modules: {
    user: userModule,
    list: listModule,
    project: projectModule
  },
  state: {
    lang: initLang,
    editionId: null,
    editionVacanciesSet: false,
    fairAffiliation: initFairAffiliation(),
    projectSelection: initProjectSelection(),
    affiliationRequestFilter: {},
    projectRequestFilter: {},
    editionRequestFilter: {},
    affiliationSendMails: [],
    fairAffiliationAnalysisAccept: null,
    mostratecInfo: null,
    projectsStatusChange: null,
    projectSendMails: [],
    selectedProject: {},
    projectList: []
  },
  mutations: {
    [CHANGE_LANGUAGE](state, payload) {
      window.localStorage.setItem('lang', payload.lang)
      state.lang = payload.lang
      location.reload()
    },
    [CHANGE_EDITION](state, payload) {
      state.editionId = payload
    },
    [EDITION_VACANCIES_SET](state) {
      state.editionVacanciesSet = !state.editionVacanciesSet
    },
    [AFILLIATION_REQUEST](state, payload) {
      state.fairAffiliation = payload
    },
    [PROJECT_SELECTION_REQUEST](state, payload) {
      state.projectSelection = payload
    },
    [AFILLIATION_LIST_FILTER](state, payload) {
      state.affiliationRequestFilter = payload
    },
    [PROJECT_LIST_FILTER](state, payload) {
      state.projectRequestFilter = payload
    },
    [EDITION_LIST_FILTER](state, payload) {
      state.editionRequestFilter = payload
    },
    [AFILLIATION_SEND_MAILS](state, payload) {
      state.affiliationSendMails = payload
    },
    [FAIR_AFFILIATION_ANALYSIS_ACCEPT](state, payload) {
      state.fairAffiliationAnalysisAccept = payload
    },
    [MOSTRATEC_INFO](state, payload) {
      state.mostratecInfo = payload
    },
    [PROJECTS_STATUS_CHANGE](state, payload) {
      state.projectsStatusChange = payload
    },
    [PROJECT_SEND_MAILS](state, payload) {
      state.projectSendMails = payload
    },
    [SELECTED_PROJECT](state, project) {
      state.selectedProject = project
    },
    [PROJECT_LIST](state, projects) {
      state.projectList = projects
    },
    [UPDATE_PROJECT_LIST](state, project) {
      const payload = state.projectList.find((item) => item.id === project.id)
      Object.assign(payload, project)
    }
  },
  actions: {
    changeLanguage(context, payload) {
      context.commit(CHANGE_LANGUAGE, payload)
      payload.i18n.locale = payload.lang
    },
    changeEdition(context, payload) {
      context.commit(CHANGE_EDITION, payload)
    },
    editionVacanciesSet(context) {
      context.commit(EDITION_VACANCIES_SET)
    },
    affiliationRequest(context, payload) {
      context.commit(AFILLIATION_REQUEST, payload)
    },
    projectSelectionRequest(context, payload) {
      context.commit(PROJECT_SELECTION_REQUEST, payload)
    },
    affiliationListFilter(context, payload) {
      context.commit(AFILLIATION_LIST_FILTER, payload)
    },
    projectListFilter(context, payload) {
      context.commit(PROJECT_LIST_FILTER, payload)
    },
    editionListFilter(context, payload) {
      context.commit(EDITION_LIST_FILTER, payload)
    },
    affiliationSendMails(context, payload) {
      context.commit(AFILLIATION_SEND_MAILS, payload)
    },
    fairAffiliationAnalysisAccept(context, payload) {
      context.commit(FAIR_AFFILIATION_ANALYSIS_ACCEPT, payload)
    },
    mostratecInfo(context, payload) {
      context.commit(MOSTRATEC_INFO, payload)
    },
    projectsStatusChange(context, payload) {
      context.commit(PROJECTS_STATUS_CHANGE, payload)
    },
    projectSendMails(context, payload) {
      context.commit(PROJECT_SEND_MAILS, payload)
    },
    setSelectedProject: (context, project) => {
      context.commit(SELECTED_PROJECT, project)
    },
    setProjectList: (context, projects) => {
      context.commit(PROJECT_LIST, projects)
    }
  },
  getters: {
    lang: (state) => state.lang,
    editionId: (state) => state.editionId,
    editionVacanciesSet: (state) => state.editionVacanciesSet,
    fairAffiliation: (state) => state.fairAffiliation,
    projectSelection: (state) => state.projectSelection,
    affiliationRequestFilter: (state) => state.affiliationRequestFilter,
    projectRequestFilter: (state) => state.projectRequestFilter,
    editionRequestFilter: (state) => state.editionRequestFilter,
    affiliationSendMails: (state) => state.affiliationSendMails,
    fairAffiliationAnalysisAccept: (state) => state.fairAffiliationAnalysisAccept,
    mostratecInfo: (state) => state.mostratecInfo,
    projectsStatusChange: (state) => state.projectsStatusChange,
    projectSendMails: (state) => state.projectSendMails,
    getSelectedProject: (state) => state.selectedProject,
    getProjectList: (state) => state.projectList
  }
})

export default store
