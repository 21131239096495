<template>
  <div
    class="box"
    :style="newStyle"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CardDefault',
  props: {
    newStyle: {
      type: Object,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.box{
  cursor: pointer;
  min-width: 308px;
  height: fit-content;
  background: #FDFDFD;
  border-radius: 10px;
  display: flex;
}
</style>
