<template>
  <div class="login">
    <Login />
  </div>
</template>

<script>

import { Login } from '@/components/templates'

export default {
  name: 'Home-login',
  components: {
    Login
  }
}
</script>
