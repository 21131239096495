<template>
  <h3 :class="[{left_heading: left},{light_blue_heading:color === 'blue' && !left},{heading: !color && !left}]">
    <slot></slot>
  </h3>
</template>

<script>
export default {
  name: 'HeadingThree',
  props: {
    color: {
      type: String,
      required: false
    },
    left: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  text-transform: initial !important;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
  flex-wrap: wrap;
  line-height: 20px;
  color: $almostBlack;
}

.left_heading {
  font-family: Mulish;
  font-style: normal;
  text-align: initial;
  margin-left: 21px;
  font-weight: bold;
  text-transform: uppercase !important;
  font-size: 16px;
  width: 100%;
  flex-wrap: wrap;
  line-height: 20px;
  color: $almostBlack;
}

.light_blue_heading {
  font-family: Mulish;
  text-align: initial;
  font-style: normal;
  font-weight: bold;
  text-transform: initial !important;
  margin-top: 10px;
  font-size: 16px;
  width: 100%;
  flex-wrap: wrap;
  line-height: 20px;
  color: #1B778B;
}
</style>
