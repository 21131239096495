<template v-model="disabled">
  <div class="label">
    {{ $t(label) }}
      <v-radio-group v-model="radios" :disabled="disabled" row>
        <v-radio
          v-for="option in options"
          :label="$t(option.label)"
          :value="option.value"
          :key="option.label"
        ></v-radio>
      </v-radio-group>
  </div>
</template>

<script>

export default {
  name: 'RadioGroup',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    disabled: Boolean,
    label: String,
    options: Array,
    value: String | Boolean,
    selected: [String, Number]
  },
  data() {
    return {
      radios: null
    }
  },
  created() {
    if (this.value || typeof this.value === 'boolean') {
      this.radios = this.value
    }
  },
  watch: {
    radios() {
      this.$emit('change', this.radios)
    },
    selected() {
      this.radios = this.selected
    },
    value() {
      this.radios = this.value
    }
  }

}
</script>

<style lang="scss" scoped>
.label {
  color: $almostBlack;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  ::v-deep .v-radio>.v-label {
    font-weight: normal!important;
    font-size: 16px;
    line-height: 18px;
    color: $almostBlack;
  }

  ::v-deep .v-application--is-ltr .v-messages {
    display: none!important;
  }
}

.warning {
  color: $attention!important;

  .input {
    border-color: $attention;
  }
}

</style>
