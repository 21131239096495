<!-- eslint-disable no-restricted-syntax -->
<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{ $t("projectData") }}</p>
        <text-warning :text="'considerAllFieldObligatory'" />
      </div>
      <input-default
        :class="[{ warning: cProjectName }, 'mb-7', 'teste']"
        id="projectName"
        placeholder="enterTheProjectName"
        type="text"
        max="250"
        v-model="form.projectName"
        @click="resetErrors"
        :width="800"
      />
      <label :class="{ label: true, warning: cAreas }">
        {{
        $t("checkYourProjectResearchArea")
        }}
      </label>
      <input-checkbox-project
        class="mb-7"
        :dataAreas="projectAreas"
        @selecteds="form.areas = $event"
        :actives="this.allDataForm.FormProjectData.areas || form.areas"
      />
      <input-default
        v-if="form.areas[0] === 'other'"
        :class="[{ warning: cNewArea }, 'mb-7']"
        label="Qual?"
        id="prjectName"
        placeholder="enterTheOtherSubarea"
        type="text"
        v-model="newArea"
        @click="resetErrors"
      />
      <!-- Envolve seres humanos -->
      <div v-if="!isGradeOrMiddleCat1">

        <RadioGroup
              :class="[{ warning: checkErrorIfContains('hasHumans') }, 'mb-2 title_checkbox']"
              label=doesItInvolveHumans
              :options="generalOptions"
              v-model="form.hasHumans"
              @change="resetErrors"
        />

        <div class="info">
          <img
            class="info__icon"
            :src="require(`@/assets/info-black.png`)"
            alt="Informação sobre a pesquisa"
          />
          <p class="text">
            {{$t('doesItInvolveHumansInfo')}} 
          </p>
        </div>
      </div>

      <!-- Envolve seres humanos -->

      <input-text-area
        :class="[{ warning: cSummary }, 'mb-7']"
        label="summary"
        id="summary"
        placeholder="enterTheSummary"
        :allText="form.summary"
        @input="form.summary = $event"
        :textMax="4000"
        info=youCanEditTheAbstract
      />
      <input-text-area
        :class="[{ warning: cTheme }, 'mb-7']"
        label="theme"
        id="theme"
        placeholder="enterTheThema"
        :allText="form.theme"
        @input="form.theme = $event"
        :textMax="2000"
        :info="$t('ThemeTextInfo')"
      />
      <input-text-area
        :class="[{ warning: cResearchProblem }, 'mb-7']"
        label=researchProblem
        id="researchProblem"
        placeholder=ProblemPlaceholder
        :allText="form.researchProblem"
        @input="form.researchProblem = $event"
        :textMax="2000"
        info=ProblemTextInfo
      />
      <input-text-area
        :class="[{ warning: cJustification }, 'mb-7']"
        label=JustificationLabel
        id="justification"
        placeholder=JustificationPlaceholder
        :allText="form.justification"
        @input="form.justification = $event"
        :textMax="2000"
        info=JustificationTextInfo_parTwo
      />
      <input-text-area
        :class="[{ warning: cObjective }, 'mb-7']"
        label=ObjectivesLabel
        id="objective"
        placeholder=ObjectivesPlaceholder
        :allText="form.objective"
        @input="form.objective = $event"
        :textMax="2000"
        info=ObjectivesTextInfo
      />
      <input-text-area
        :class="[{ warning: cMethodology }, 'mb-7']"
        label=MethodologyLabel
        id="methodology"
        placeholder=MethodologyPlaceholder
        :allText="form.methodology"
        @input="form.methodology = $event"
        :textMax="4000"
        :info="$t('MethodologyTextInfo_parOne') + ' ' + $t('MethodologyTextInfo_parTwo')"
      />
      <input-text-area
        :class="[{ warning: cResultsConclusion }, 'mb-7']"
        label=ResultsLabel
        id="resultsConclusion"
        placeholder=resultsAndConclusionsPlaceholder
        :allText="form.resultsConclusion"
        @input="form.resultsConclusion = $event"
        :textMax="4000"
        info=resultsAndConclusionsTextInfo
      />
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{ $t("back") }}</button-default>
        <button-default :onClick="checkForm" class="button">
          {{
          $t("saveAndAdvance")
          }}
        </button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputAutoComplete,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  InputDefault,
  InputCheckboxProject,
  InputTextArea,
  RadioGroup
} from '@/components/atoms'

export default {
  name: 'FormProjectData',
  components: {
    InputAutoComplete,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    InputDefault,
    InputCheckboxProject,
    InputTextArea,
    RadioGroup
  },
  data() {
    return {
      form: {
        projectName: '',
        areas: [],
        summary: '',
        theme: '',
        researchProblem: '',
        justification: '',
        hasHumans: null,
        objective: '',
        methodology: '',
        resultsConclusion: ''
      },
      generalOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ],
      otherArea: false,
      newArea: '',
      firstLoader: false,
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      projectAreas: [
        { text: this.$t('art'), value: 'arts' },
        { text: this.$t('bio'), value: 'biology' },
        { text: this.$t('eduPedago'), value: 'education' },
        { text: this.$t('eletronicInfoRobo'), value: 'eletronic' },
        { text: this.$t('enge'), value: 'engineering' },
        { text: this.$t('filoso'), value: 'filosofy' },
        { text: this.$t('fis'), value: 'phisics' },
        { text: this.$t('geo'), value: 'geography' },
        { text: this.$t('his'), value: 'history' },
        { text: this.$t('incAcessTec'), value: 'inclusion' },
        { text: this.$t('qui'), value: 'chemistry' },
        { text: this.$t('lingPortLingStrangLit'), value: 'portuguese' },
        { text: this.$t('mat'), value: 'math' },
        { text: this.$t('meioAmb'), value: 'envirornment' },
        { text: this.$t('saud'), value: 'health' },
        { text: this.$t('socio'), value: 'sociology' },
        { text: this.$t('other'), value: 'other' }
      ]
    }
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  computed: {
    isGradeOrMiddleCat1() {
      const level = this.allDataForm.FormLevelOfEducation.level
      //console.log('FormLevelOfEducation.level:', this.allDataForm.FormLevelOfEducation.level)  // Debug log
      return (level === 'grade' || level === 'middle_cat_1')
      //return true
    },
    cProjectName() {
      return this.firstLoader && !this.form.projectName
    },
    cAreas() {
      return this.firstLoader && !this.form.areas.length
    },
    cNewArea() {
      return (
        this.firstLoader
        && this.form.areas[0] === 'other'
        && !this.newArea
      )
    },
    cSummary() {
      return this.firstLoader && !this.form.summary
    },
    cTheme() {
      return this.firstLoader && !this.form.theme
    },
    cResearchProblem() {
      return this.firstLoader && !this.form.researchProblem
    },
    cJustification() {
      return this.firstLoader && !this.form.justification
    },
    cObjective() {
      return this.firstLoader && !this.form.objective
    },
    cMethodology() {
      return this.firstLoader && !this.form.methodology
    },
    cResultsConclusion() {
      return this.firstLoader && !this.form.resultsConclusion
    }
  },
  mounted() {
    if (this.allDataForm.FormProjectData) {
      this.form = { ...this.form, ...this.allDataForm.FormProjectData }
      this.thereWasNewArea()
    }
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormProjectData) {
        this.form = { ...this.form, ...this.allDataForm.FormProjectData }
        this.thereWasNewArea()
      }
    }
  },
  methods: {
    thereWasNewArea() {
      if (this.allDataForm.FormProjectData.areas[0]) {
        const oldArea = this.allDataForm.FormProjectData.areas[0]
        const heHad = this.projectAreas.find((item) => item.value === this.allDataForm.FormProjectData.areas[0])
        if (!heHad) {
          this.form.areas.push('other')
          this.newArea = oldArea
        }
      }
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async checkForm() {
      this.firstLoader = true
      this.resetErrors()
      if (!this.errors.length) {
        console.log('this.form.hasHumans ',this.form.hasHumans)
        if (this.form.hasHumans === null ) {
          if(!(this.allDataForm.FormLevelOfEducation.level=='middle_cat_1' || this.allDataForm.FormLevelOfEducation.level=='grade') ) {
            //console.log('hasHumans')
            this.errors.push('hasHumans')
            return false
          } 
        }
        if (this.cProjectName) {
          this.errors.push('projectName')
          return false
        }
        if (this.cAreas) {
          this.errors.push('areas')
          return false
        }
        if (this.cNewArea) {
          this.errors.push('newAreas')
          return false
        }
        if (this.cSummary) {
          this.errors.push('summary')
          return false
        }
        if (this.cTheme) {
          this.errors.push('theme')
          return false
        }
        if (this.cResearchProblem) {
          this.errors.push('researchProblem')
          return false
        }
        if (this.cJustification) {
          this.errors.push('justification')
          return false
        }
        if (this.cObjective) {
          this.errors.push('objective')
          return false
        }
        if (this.cMethodology) {
          this.errors.push('methodology')
          return false
        }
        if (this.cResultsConclusion) {
          this.errors.push('resultsConclusion')
          return false
        }
        await this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      if (this.form.areas[0] === 'other') {
        this.form.areas[0] = this.newArea
      }
      this.allDataForm.FormProjectData = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        if (this.form.areas[0] === 'other') {
          this.form.areas[0] = this.newArea
        }
        this.$emit('changeElement', {
          direction: 'up',
          FormProjectData: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
        return false
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    back() {
      this.$emit('changeElement', {
        direction: 'down'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
.disabled {
  background-color: #d4d4d4;
}
.input__checkbox {
  display: flex;
  flex-direction: column;

  input[type="checkbox"] {
    zoom: 1.8;
  }
  .center {
    display: flex;
    align-items: center;
    margin: 11px 0;
  }

  span {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 10px;
    max-width: 728px;
  }
}

.warning {
  span {
    color: $attention;
    border-bottom-color: $attention;
  }
}
.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: $almostBlack;
  &.warning {
    color: $attention !important;
  }
}
</style>
