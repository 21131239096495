<template>
  <div class="container-app">
    <header-project />
    <initial-start-project />
  </div>
</template>

<script>
import { HeaderProject } from '@/components/organisms'
import { InitialStartProject } from '@/components/templates'

export default {
  name: 'StartProject',
  components: { HeaderProject, InitialStartProject }
}
</script>
