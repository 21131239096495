<template>
  <button @click.prevent="onClick" class="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonIconDefault',
  props: {
    onClick: {
      type: Function,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.92;
  }
}
</style>
