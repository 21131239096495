<template>
  <div class="details__wrapper">
    <div class="title">
      <img :src="require(`@/assets/information-circle.png`)" alt="Informação sobre Liberato">
      <h1>Informações</h1>
      <div class="info">
        <span class="text">
          Relações dos perfis de acesso e suas respectivas funções no sistema da Mostratec Digital. Os usuários do Perfil Administrador podem visualizar as informações de todos os painéis além do painel de administração.
        </span>
      </div>
    </div>
    <div class="data">
      <h4>Administração</h4>
      <div class="separator"></div>
      <span class="badge">Gerência e permissões de perfis de acesso</span>
      <span class="badge">Cadastro de novos usuários</span>

      <h4>Aluno de processo de seleção</h4>
      <div class="separator"></div>
      <span class="badge">Pré-cadastro do aluno em processo seleção</span>
      <span class="badge">Editar perfil</span>
      <span class="badge">Inscrever projeto na Mostratec</span>
      <span class="badge">Acompanhar inscrição</span>

      <h4>Aluno de feira afiliada</h4>
      <div class="separator"></div>
      <span class="badge">Gerência e permissões de perfis de acesso</span>
      <span class="badge">Cadastro de novos usuários</span>

      <h4>Faira afiliada</h4>
      <div class="separator"></div>
      <span class="badge">Gerência e permissões de perfis de acesso</span>
      <span class="badge">Cadastro de novos usuários</span>

      <h4>Secretaria</h4>
      <div class="separator"></div>
      <span class="badge">Gerência e permissões de perfis de acesso</span>
      <span class="badge">Cadastro de novos usuários</span>
    </div>
    <button-default class="button" :onClick="backToUsers">{{$t('back')}}</button-default>
  </div>
</template>

<script>
import { ButtonDefault } from '@/components/atoms'

export default {
  name: 'TableRolePermissions',
  components: { ButtonDefault },
  methods: {
    backToUsers() {
      this.$emit('newIndex', 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.details__wrapper {
  background-color: #FDFDFD;
  border-radius: 20px;
  margin-top: 25px;
  margin-bottom: 40px;
  padding: 40px 60px;
  width: 100%;

  .title {
    h1 {
      font-family: Mulish;
      font-weight: 700;
      font-size: 18px;
      color: $primary;
      margin: 5px 0px;
      margin-left: 5px;
      display: inline-block;
    }

    .info {
      align-items: center;
      background-color: transparent!important;
      display: flex;

      .text {
        color: $primary;
        font-size: 16px;
        font-weight: 400;
        width: 800px;
      }
    }
  }
  .data {
    margin-top: 50px;
    margin-bottom: 30px;

    h4 {
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: $almostBlack;
      margin-bottom: 5px;
      margin-top: 40px
    }
    span {
      font-size: 14px;
      line-height: 18px;
      color: #686868;
    }
    .badge {
      align-items: center;
      display: inline-flex;
      justify-content: center;
      background: $primary;
      border-radius: 49px;
      opacity: 0.8;

      color: $white;
      font-size: 14px;
      height: 28px;
      padding: 0px 10px;
      margin-right: 5px
    }
  }
  .separator {
    background-color: lightgray ;
    height: 1px;
    margin-bottom: 10px;
    width: 100%;
  }
  .button {
    margin-top: 30px;
    color: $primary;
    background-color: white;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.16);
  }
}
</style>
