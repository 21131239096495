<template>
<div class="d-flex flex-column">
  <label class="label">
    {{ $t(label) }}
  </label>
  <span class="span">{{value}}</span>
</div>
</template>

<script>

export default {
  name: 'BadgeInfo',
  props: {
    label: String,
    value: Number | String
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: $almostBlack;
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.span {
  @include d-flex-center;
  background: #1B778B;
  border-radius: 49px;
  height: 28px;
  margin-top: 15px;
  opacity: 0.8;
  width: 101px;

  color: $white;
  font-weight: bold;
  font-size: 1rem;
}
</style>
