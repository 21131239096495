<template>
  <div class="home">
    <form-request-template />
  </div>
</template>

<script>
import { FormRequestTemplate } from '@/components/templates'
import { HeaderDefault } from '@/components/organisms'

export default {
  name: 'Edition',
  components: {
    FormRequestTemplate,
    HeaderDefault
  }
}
</script>
