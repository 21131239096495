<template>
  <div class="info">
    <img class="info__icon" :src="require(`@/assets/info-blue.png`)" alt="Informação sobre Liberato">
    <span>{{$t(text)}}</span>
  </div>
</template>

<script>
export default {
  name: 'TextInfo',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.info {
  align-items: center;
  display: flex;
  height: 50px;

  span {
    font-weight: bold;
    font-size: 18px;
    color: $primary;
    opacity: 0.8;
    margin-left: 6px;
    max-width: 398px;
    text-align: left;
  }
}

</style>
