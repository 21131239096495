<template>
  <label :class="[{label_active: !this.disabled}, {label_inactive: this.disabled}]" :for="id">
    {{ $t(label) }}
    <input class="input"
      :id="id"
      :type="type"
      :placeholder="$t(placeholder)"
      :value="value"
      v-mask="mask"
      v-on:input="$emit('input', $event.target.value)"
      :maxlength="max || false"
      :disabled="disabled"
    />
  </label>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'InputScore',
  props: {
    label: String,
    type: String,
    id: String,
    placeholder: String,
    value: String | Number,
    max: Number,
    disabled: Boolean,
    mask: {
      type: Array | String,
      default: ''
    }
  },
  directives: { mask }
}
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  &_active{
    color: #303030;
  }
  &_inactive{
    color: #686868;
  }
}

.warning {
  color: $attention;

  .input {
    border-color: $attention;
  }
}

.input {
  background: #FFFFFF;
  border: 1px solid #CDCBCB;
  border-radius: 10px;
  height: 35px;
  margin-top: 5px;
  width: 403px;
  padding: 8px 15px;
  outline: none;
  font-weight: 500;
  color: $almostBlack;

  &:placeholder {
    color: #AFAEAE;
    font-size: 14px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}
</style>
