<template>
  <div>
    <form @submit.prevent class="form">
      <p class="form__title">{{ $t("categoryFormSelection") }} </p>
      <div class="form__info">
        <text-info
          :text="$t('confirmYourEducationalLevel')"
        />
        <text-warning :text="'considerAllFieldObligatory'" />
        <!-- p>{{ role }}</p -->
      </div> 
        <input-select
          :class="[{ warning: checkErrorIfContains('level') }, 'mb-7']"
          :label="$t('levelsOfEducational')"
          id="level"
          :items="cOptions"
          :value="form.level"
          @input="form.level = $event"
          :selected="form.level"
        />
      <div class="warning-msg" v-if="allDataForm.FormLevelOfEducation.level">
        <img :src="require('@/assets/info-status-nao-aceito.svg')" alt="warning" />
        {{$t('ifYouChangeTheEducationalLevel')}}
      </div>
      <input-default
        :class="[{ warning: checkErrorIfContains('edition') }, 'mb-7']"
        :label="$t('activeEditionForSubscription')"
        id="mostratecType"
        placeholder="Edição Mostratec"
        type="text"
        v-model="form.edition"
        @input="resetErrors"
        disabled
      />
      <div v-if="form.level==='high'">
        <p class="form__title" style="font-size:16pt;">{{ $t("sebraeTitle") }} </p>
        <div class="form__info">
          <label>{{ $t('sebraeQuestion1') }}</label>
        </div>         
        <input-select
          :class="[{ warning: checkErrorIfContains('sebraeOptions') }, 'mb-7']"
          :label="$t('sebraeQuestion2')"
          id="level"
          :items="sebraeOptions"
          :value="form.sebraeQuestion"
          @input="form.sebraeQuestion = $event"
          :selected="form.sebraeQuestion"
        />
      </div>
      <!-- div>
        sebraeQuestion: <strong>{{ form.sebraeQuestion }}</strong><br/>
        Level: <strong>{{ form.level }}</strong><br/>
      </div -->
      <button-default :onClick="checkForm" class="button">{{$t('saveAndAdvance')}}</button-default>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    /> 
  <!-- /div> <div -->
  </div>
</template>

<script>
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  InputSelect,
  TextInfo
} from '@/components/atoms'

export default {
  name: 'FormLevelOfEducation',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    InputSelect,
    TextInfo
  },
  data() {
    return {
      form: {
        level: '',
        edition: '',
        sebraeQuestion: ''
      },
      role: null, // Inicializa com null ou outro valor padrão
      options: [
        { text: this.$t('selectEtc'), value: '' },
        { text: this.$t('childEducationCompleted'), value: 'grade' },
        { text: this.$t('elementarySchoolCategory1'), value: 'middle_cat_1' },
        { text: this.$t('elementarySchoolCategory2'), value: 'middle_cat_2' },
        { text: this.$t('elementarySchoolCategory3'), value: 'middle_cat_3' },
        { text: this.$t('elementarySchoolCategoryEja'), value: 'middle_cat_eja' },
        { text: this.$t('highSchoolOrTechnicalCompleted'), value: 'high' }
      ],
      errors: [],
      textWarning: this.$t('selectLevel')
    }
  },
  props: {
    allDataForm: [Object, String]
  },
  mounted() {
    this.getCurrentMostratec()
    if (this.allDataForm.FormLevelOfEducation) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormLevelOfEducation
      }
    }
    this.getRoleFromLocalStorage()
  },
  computed: {
    cOptions() {
      //console.log('role: ',this.role)
      if(this.role==='aluno_processo_selecao'){
        // Processo de seleção não tem "grade" nem "cat_1"
        return [
          { text: this.$t('selectEtc'), value: '' },
          { text: this.$t('elementarySchoolCategory2'), value: 'middle_cat_2' },
          { text: this.$t('elementarySchoolCategory3'), value: 'middle_cat_3' },
          { text: this.$t('elementarySchoolCategoryEja'), value: 'middle_cat_eja' },
          { text: this.$t('highSchoolOrTechnicalCompleted'), value: 'high' }
        ]
      }
      if (this.validPath()) {
        return [
          { text: this.$t('selectEtc'), value: '' },
          { text: this.$t('elementarySchoolCategory1'), value: 'middle_cat_1' },
          { text: this.$t('elementarySchoolCategory2'), value: 'middle_cat_2' },
          { text: this.$t('elementarySchoolCategory3'), value: 'middle_cat_3' },
          { text: this.$t('elementarySchoolCategoryEja'), value: 'middle_cat_eja' },
          { text: this.$t('highSchoolOrTechnicalCompleted'), value: 'high' }
        ]
      }
      return this.options
    },
    sebraeOptions() {
      //console.log('Entrou em sebraeOptions()')
      this.sebraeOpt = []  // Inicializa como array vazio

      // Cria os objetos dentro do array
      if (this.$i18n.locale=='pt'){
        this.sebraeOpt.push({ text: this.$t('sebraeResponse0'), value: '' })
      }
      else{
        this.sebraeOpt.push({ text: this.$t('sebraeResponse0'), value: 'Estrangeiro' })
      }

      if (this.$t('sebraeResponse1').length > 1) {
        this.sebraeOpt.push({ text: this.$t('sebraeResponse1'), value: 'Sim' })
      }

      if (this.$t('sebraeResponse2').length > 1) {
        this.sebraeOpt.push({ text: this.$t('sebraeResponse2'), value: 'Não' })
      }

      //console.log('sebraeOpt', this.sebraeOpt)

      return this.sebraeOpt
    },
  },
  watch: {
    'form.level': {
      async handler(newValue) {
        this.resetErrors()
        if (newValue === 'grade' || newValue === 'middle'
        || newValue === 'middle_cat_1'
        || newValue === 'middle_cat_2'
        || newValue === 'middle_cat_3'
        || newValue === 'middle_cat_eja') {
          this._data.form.mostratec_type = 'junior'
        }
        if (newValue === 'high') {
          this._data.form.mostratec_type = 'mostratec'
        }
        // if (window.location.pathname !== '/form-selection') {
        // if (newValue !== this.allDataForm.FormLevelOfEducation.level) {
        //   this.allDataForm.FormFair = ''
        //   this.FormContract = ''
        //   await this.temporarySave()
        // }
        // }
      }
    },
    allDataForm() {
      if (this.allDataForm.FormLevelOfEducation) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormLevelOfEducation
        }
      }
    }
  },
  methods: {
    validPath() {
      return window.location.pathname === '/form-selection'
    },
    async getCurrentMostratec() {
      try {
        const { data } = await this.$http.get('/current-mostratec')
        this.form.edition = data.name_edition
        this.form.formatEdition = data.format_edition
        this.$store.commit('MOSTRATEC_INFO', data)
      } catch (error) {
        this.form.edition = this.$t('errorLoadingInformation')
      }
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async checkForm() {
      this.resetErrors()
      //console.log(this)
      //console.log('Língua atual:', this.$i18n.locale);
      for (const key in this.form) {
        if (!this.form[key]) {
          if (key==='sebraeQuestion'){
            //console.log('key',key)
            //console.log('level',this.form.level)
            if(this.form.level==='high' && this.$i18n.locale=='pt') this.errors.push(key)
          }else{
            this.errors.push(key)
          }
        }
      }
      /*
      if(!form.sebraeQuestion){
        console.log('if(!form.sebraeQuestion)')
        this.textWarning = 'É necessário responder '
        return false
      }
      */
      if (!this.errors.length) {
        if (!this.form.level) {
          this.textWarning = 'Nível invalido'
          return false
        }
        await this.temporarySave()
        this.saveForm()
      }
      return true
    },
    async temporarySave() {
      if (this.allDataForm.FormLevelOfEducation.level !== this.form.level) {
        this.allDataForm.FormFair = ''
        this.allDataForm.FormSelectionSchool = ''
        this.allDataForm.FormProjectData = ''
        this.allDataForm.FormProjectStudents = ''
        this.allDataForm.FormSelectionAboutAdvisors = ''
        this.allDataForm.FormProjectChildishData = ''
        this.allDataForm.FormProjectChildishStudents = ''
        this.allDataForm.FormProjectChildishAdvisors = ''
        this.allDataForm.FormContract = ''
        this.allDataForm.FormProjectHighData = ''
        this.allDataForm.FormProjectHighStudents = ''
        this.allDataForm.FormProjectHighAdvisors = ''
        this.allDataForm.FormProjectHighOthersInvolveds = ''
        this.allDataForm.FormProjectHighResearchPlan = ''
        this.allDataForm.FormProjectHighProjectAbstract = ''
        this.allDataForm.FormProjectHighResearcherSummary = ''
        this.allDataForm.FormProjectHighUpload = ''
      }
      this.allDataForm.FormLevelOfEducation = this.form

      const body = {
        temporary_data: this.allDataForm,
        form_type: this.allDataForm.FormLevelOfEducation.mostratec_type
      }
      try {
        const result = await this.$http.post('/temp', body)
        return result
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.$emit('getProperForm')
        this.$emit('changeElement', {
          direction: 'up',
          FormLevelOfEducation: this.form
        })
        return false
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    back() {
      this.$emit('changeElement', {
        direction: 'down'
      })
    },
    getRoleFromLocalStorage() {
      // Usa a API do localStorage para obter o valor
      this.role = localStorage.getItem('role');
    },
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
.warning-msg {
  margin: -15px 0 12px 0;
  display: flex;
  align-items: center;
  color: $dangerStrong;
  img {
    margin-right: 5px;
  }
}
</style>
