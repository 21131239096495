<template>
    <div class="card-vacancies-project">
      <div class="card-vacancies-project-left">
        <heading-three>
          #{{projectId}} - {{projectTitle.toUpperCase()}}
        </heading-three>
        <div class="project-data-grid">
          <information
            class="authors"
            title="authors"
            :content="arrayStringify(authors)"
          />
          <information
            class="advisor"
            title="advisor"
            :content="advisor"
          />
          <information
            class="coadvisor"
            title="coAdvisor"
            :content="coadvisor"
          />
        </div>
      </div>
      <div v-if="open && pathnameUrl === '/list/analisis'" class="card-vacancies-project-right">
        <input-select-coloured
          :class="{ warning: textWarning === 'selectionSituation' ? textWarning : null }"
          id="situation-select"
          placeholder="Selecione a situação"
          :value="situationEdit"
          :items="situationItems"
          :type="situationClasses[situationEdit]"
          @input="situationEdit=$event"
        />
        <div
          class="textarea-wrapper"
          v-if="situationEdit === 'reproved'"
        >
          <input-text-area
            :class="{ warning: cRejectReason }"
            id="reject-reason"
            placeholder="insertRejectReason"
            :textMax="200"
            :allText="rejectReasonEdit"
            @input="rejectReasonEdit=$event"
            @change="resetErrors"
          />
        </div>
        <div class="card-vacancies-cancel-and-save">
          <button
            class="cancel-button"
            @click="cleanDataButtonCancel">
            <strong>CANCELAR</strong>
          </button>
          <button-default
          class="save-edit-button"
            :disabled="isUnableToSend"
            :onClick="editForm"
          >
          {{$t('saveAndSend')}}
          </button-default>
        </div>
      </div>
      <div v-else class="card-vacancies-project-right">
        <input-select-coloured
          id="situation-select"
          placeholder="Selecione a situação"
          :value="situation"
          :items="situationItems"
          :readOnly="readOnly"
          :type="situationClasses[situation]"
          @input="situation=$event"
        />
        <div class="textarea-wrapper" v-if="situation === 'reproved'">
          <information
            v-if="readOnly"
            :title="$t('JustificationLabel')"
            :content="rejectReason"
          />
          <input-text-area
            v-else
            :class="{ warning: cRejectReason }"
            label="JustificationLabel"
            id="reject-reason"
            placeholder="insertRejectReason"
            :textMax="200"
            :allText="rejectReason"
            @input="rejectReason=$event"
            @change="resetErrors"
          />
          <button-default
            v-if="!readOnly"
            :disabled="isUnableToSend"
            :onClick="checkForm"
          >
          {{$t('saveAndSend')}}
          </button-default>
        </div>
        <button-default
          v-else-if="!readOnly"
          :disabled="isUnableToSend"
          :onClick="checkForm"
        >
        {{$t('saveAndSend')}}
        </button-default>
      </div>
      <feedback-warning
        v-if="errors.length"
        :text="textWarning"
        :onClick="resetErrors"
        color="#ff9800"
      />
      <modal-attention
        :modal="modalAttention"
        :project_title="projectTitle"
        :onClick="saveForm"
        @closeModal="() => toggleModal('modalAttention')"
      />
      <div class="card-vacancies-button-edit" v-if="projectInfo.internal_status_id !== 12">
        <button
          v-if="!open && pathnameUrl === '/list/analisis'"
          @click="open = !open"
        >
          <strong>EDITAR</strong>
        </button>
      </div>
    </div>
</template>

<script>
import ButtonDefault from '@/components/atoms/Buttons/ButtonDefault'
import HeadingThree from '@/components/atoms/Texts/HeadingThree'
import FeedbackWarning from '@/components/atoms/Feedbacks/FeedbackWarning'
import DefaultText from '@/components/atoms/Texts/DefaultText'
import InputTextArea from '@/components/atoms/Inputs/InputTextArea'
import InputSelectColoured from '@/components/atoms/Inputs/InputSelectColoured'

import Information from '@/components/molecules/Contents/Information'
import ModalAttention from '@/components/organisms/Modals/ModalAttention'

export default {
  name: 'CardVacanciesProject',
  components: {
    ButtonDefault,
    HeadingThree,
    Information,
    FeedbackWarning,
    DefaultText,
    InputTextArea,
    InputSelectColoured,
    ModalAttention
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    },
    statusList: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      situationItems: [
        {
          text: this.$t('selectSituation'),
          value: ''
        },
        {
          text: this.$t('approved'),
          value: 'approved'
        },
        {
          text: this.$t('reproved'),
          value: 'reproved'
        }
      ],
      situationClasses: {
        approved: 'green-success',
        reproved: 'red-warning',
        '': 'grey-neutral'
      },
      registrationState: {
        approved: 'approvedRegistration',
        reproved: 'reprovedRegistration'
      },
      situation: '',
      status: [],
      rejectReason: '',
      internal_status_id: '',
      statusSlug: null,
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      firstLoader: false,
      readOnly: false,
      projectTitle: '',
      projectId: null,
      authors: [],
      advisor: '',
      coadvisor: '',
      modalAttention: false,
      fairAcceptanceResponsible: '',
      fairAcceptanceDate: '',
      fairAcceptanceTime: '',
      isNew: false,
      open: false,
      situationEdit: '',
      pathnameUrl: '',
      rejectReasonEdit: ''
    }
  },
  async mounted() {
    this.pathnameUrl = window.location.pathname
    this.status = await this.getStatus()
    this.getCardInfos()
  },
  computed: {
    isUnableToSend() {
      return this.situation === ''
    },
    cRejectReason() {
      return this.firstLoader && this.situation === 'reproved' && !this.rejectReason
    }
  },
  methods: {
    async checkForm() {
      this.firstLoader = true
      this.resetErrors()
      if (this.cRejectReason && this.pathnameUrl !== '/list/analisis') {
        this.errors.push('reject-reason')
        return false
      }
      this.situation === 'reproved' ? this.toggleModal('modalAttention') : this.saveForm()
    },
    toggleModal(modal) {
      this[modal] = !this[modal]
    },
    getCardInfos() {
      if (this.projectInfo.internal_status.slug !== 'aguardando-aceite-feira') {
        const fairAcceptanceDateTime = this.projectInfo.fair_acceptance_date
        const { date, time } = this.formatDateTime(fairAcceptanceDateTime)
        this.readOnly = true
        this.fairAcceptanceResponsible = this.projectInfo.fair_acceptance_responsible?.name || 'N/A'
        this.fairAcceptanceDate = date
        this.fairAcceptanceTime = time
        if (this.projectInfo.internal_status.slug === 'reprovado') {
          this.rejectReason = this.projectInfo.fair_justification
          this.situation = 'reproved'
        } else {
          this.situation = 'approved'
        }
      }
      this.projectTitle = this.projectInfo.project_title
      this.projectId = this.projectInfo.project_id
      this.projectInfo.students.forEach((student) => {
        this.authors.push(student?.full_name)
      })
      const advisorInfo = this.projectInfo.involveds.find((involved) => involved.type === 'orientador')
      this.advisor = advisorInfo?.full_name
      const coadvisorInfo = this.projectInfo.involveds.find((involved) => involved.type === 'coorientador')
      this.coadvisor = coadvisorInfo?.full_name
    },
    arrayStringify(myArray) {
      if (!myArray.length) return null
      const stringifiedArray = []
      myArray.forEach((item) => {
        stringifiedArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      })
      return stringifiedArray.join(', ')
    },
    slugToID(slug) {
      const status = this.statusList.find((item) => item.slug === slug)
      return status?.id || null
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    async saveForm() {
      this.resetErrors()
      this.modalAttention ? this.toggleModal('modalAttention') : null
      try {
        await this.$http.patch(`/project/${ this.projectInfo.id }/update`, {
          fair_justification: this.rejectReason ? this.rejectReason : ''
        })
        await this.statusChange(this.situation === 'approved' ? this.slugToID('aguardando-revisao') : this.slugToID('reprovado'))
        this.readOnly = true
        this.isNew = true
        location.reload()
      } catch (error) {
        this.errors.push('request')
        this.textWarning = 'failedToAcceptProject'
        return false
      }
    },
    async getStatus() {
      const { data } = await this.$http.get('/status/project?statusType=projeto')
      return data.data
    },
    slugToID(slug) {
      const status = this.status.find((item) => item.slug === slug)
      return status?.id || null
    },
    async statusChange(e) {
      await this.$http.patch(`/status/project/${ this.projectInfo.id }`, {
        internal_status_id: e
      })
    },
    formatDateTime(dateTime) {
      if (!dateTime) {
        return {
          date: 'N/A',
          time: 'N/A'
        }
      }
      const [date, time] = dateTime.split('T')

      const dateFormatted = new Date(date).toISOString().substr(0, 10)
      const [year, month, day] = dateFormatted.split('-')

      const timeFormatted = time.substring(0, 5)

      return {
        date: `${ day }/${ month }/${ year }`,
        time: timeFormatted
      }
    },
    async editForm() {
      try {
        if (!this.situationEdit) {
          this.errors.push('selectionSituation')
          this.textWarning = 'selectionSituation'
          return false
        }
        await this.$http.patch(`/project/${ this.projectInfo.id }/update`, {
          fair_justification: this.rejectReasonEdit ? this.rejectReasonEdit : ''
        })
        await this.statusChange(this.situationEdit === 'approved' ? this.slugToID('aguardando-revisao') : this.slugToID('reprovado'))
        location.reload()
      } catch (error) {
        console.log(error.message)
      }
    },
    cleanDataButtonCancel() {
      this.open = !this.open
      if (!this.open) {
        this.situationEdit = ''
        this.rejectReasonEdit = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-vacancies-project {
  font-family: $font-stack;
}
.card-vacancies-button-edit {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.card-vacancies-cancel-and-save {
  display: flex;
}
.cancel-button {
  margin: 0 5%
}
.save-edit-button {
  margin: 0 5%;
  width: 40%;
  height: 43px;
}
.card-vacancies-project {
  display: flex;
  flex-direction: row;
  padding: 24px;
  border: 1px solid #AFAEAE;
  border-radius: 20px;

  .card-vacancies-project-left {
    flex: 3;
    & > h3 {
      font-size: 16px;
      margin-bottom: 14px;
    }
    & > .project-data-grid {
      display: grid;
      gap: 14px;
      grid-template:
        "authors authors"
        "advisor coadvisor"
        / 1fr 1fr ;

      & > .authors {
        grid-area: authors;
      }

      & > .advisor {
        grid-area: advisor;
      }

      & > .coadvisor {
        grid-area: coadvisor;
      }
    }
  }
  .card-vacancies-project-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 2;
    & > * {
      width: 100%;
    }

    & > .textarea-wrapper {
      margin: 30px 0;
    }

    & > .historic {
      text-align: right;
      color: #AFAEAE;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 1000px) {
  .card-vacancies-project {
    flex-direction: column;

    & > .card-vacancies-project-left,
    & > .card-vacancies-project-right {
      width: 100%;
      align-items: center;
    }

    & > .card-vacancies-project-left {
      margin-bottom: 20px;

      & > .project-data-grid {
        display: grid;
        gap: 14px;
        grid-template:
          "authors"
          "advisor"
          "coadvisor"
          / 1fr ;
      }
    }

    & > .card-vacancies-project-right {
      .button, .historic {
        margin-top: 20px;
      }
    }
  }
}
</style>
