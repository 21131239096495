<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{ $t("schoolInstitutionData") }}</p>
        <p class="form__text">{{$t('informWhichSchoolYouAreEnrolled')}}</p>
        <text-warning :text="$t('considerAllFieldObligatory')"/>
      </div>

      <template>
        <input-default
          :class="[{ warning: checkErrorIfContains('school_name') }, 'mb-7']"
          :label="$t('institutionName')"
          id="name"
          placeholder=institutionNamePlaceholder
          type="text"
          max="100"
          v-model="form.school_name"
          @input="resetErrors"
        />
        <RadioGroup
          :class="[{ warning: checkErrorIfContains('category') }, 'mb-7']"
          label=educationalInstitutionCategoryCompleted
          :options="options"
          v-model="form.category"
          @change="resetErrors"
        />

        <div class="separator">
          <h3>{{$t('address')}}</h3>
        </div>
        <input-select-location
          :class="[{ warning: checkErrorIfContains('country') }, 'mb-7']"
          id="country"
          :items="countries"
          v-model="form.country"
          label=country
          @input="resetErrors"
        />
        <div v-if="form.country === 'Brazil'">
          <input-masked
            class="mb-7"
            :class="[{ warning: checkErrorIfContains('cnpj') }]"
            required
            label=CNPJ
            id="cnpj"
            placeholder="Insira o CNPJ da instituição"
            type="text"
            v-model="form.cnpj"
            @input="resetErrors"
            :mask="['##.###.###/####-##']"
          />
        </div>
        <input-masked
          v-if="form.country === 'Brazil'"
          :class="[{ warning: checkErrorIfContains('zipCode') }, 'mb-7']"
          required
          label=zipCode
          id="zipCode"
          placeholder=inputInstitutionCepPlaceholder
          type="text"
          v-model="form.zipCode"
          @input="resetErrors"
          :mask="['#####-###']"
        />
        <template v-if="form.cepValidate">
          <input-default
            v-if="form.country === 'Brazil'"
            :class="[{ warning: checkErrorIfContains('state') }, 'input__space']"
            label=state
            id="state"
            type="text"
            max="2"
            v-model="form.state"
            @input="resetErrors"
          />
          <input-default
            v-if="form.country === 'Brazil'"
            :class="[{ warning: checkErrorIfContains('city') }, 'input__space']"
            label=city
            id="city"
            type="text"
            max="100"
            v-model="form.city"
            @input="resetErrors"
          />
        </template>
        <template v-else>
          <input-select
            v-if="form.country === 'Brazil'"
            :class="[{ warning: checkErrorIfContains('state') }, 'input__space']"
            id="serie"
            :items="states"
            v-model="form.state"
            label=state
            @input="resetErrors"
            :selected="form.state"
          />
          <input-select
            v-if="form.country  ===  'Brazil'"
            :class="[{ warning: checkErrorIfContains('city') }, 'input__space']"
            id="serie"
            :items="cities"
            v-model="form.city"
            label=city
            @input="resetErrors"
            :selected="form.city"
          />
        </template>
        <input-default
          v-if="form.country  === 'Brazil' && form.state"
          :class="[{ warning: checkErrorIfContains('address') }, 'input__space']"
          label=address
          id="address"
          placeholder=addressExample
          type="text"
          max="100"
          v-model="form.address"
          @input="resetErrors"
        />

        <input-default
          v-if="form.country  ===  'Brazil'"
          :class="[{ warning: checkErrorIfContains('district') }, 'input__space']"
          label=neighborhood
          id="district"
          placeholder=neighborhoodPlaceholder
          type="text"
          max="100"
          v-model="form.district"
          @input="resetErrors"
        />

        <input-default
          v-if="form.country === 'Brazil' && form.state"
          :class="[{ warning: checkErrorIfContains('number') }, 'input__space']"
          label=number
          id="number"
          placeholder=numberPlaceholder
          type="text"
          max="15"
          v-model="form.number"
          @input="resetErrors"
        />

        <input-default
          v-if="form.country === 'Brazil'"
          :class="[{ warning: checkErrorIfContains('complement') }, 'input__space']"
          label=complementOptional
          id="complement"
          placeholder=complementPlaceholder
          type="text"
          max="45"
          v-model="form.complement"
          @input="resetErrors"
        />

        <input-default
          v-if="form.country && form.country !=='Brazil'"
          :class="[{ warning: checkErrorIfContains('state') }, 'input__space']"
          label="State/Province"
          id="state"
          placeholder=statePlaceholder
          type="text"
          max="100"
          v-model="form.state"
          @input="resetErrors"
        />

        <input-default
          v-if="form.country && form.country !=='Brazil'"
          :class="[{ warning: checkErrorIfContains('city') }, 'input__space']"
          label="City"
          id="city"
          placeholder="Input your city"
          type="text"
          max="100"
          v-model="form.city"
          @input="resetErrors"
        />
        <div>
          <button-default :onClick="() => saveSchool(true)" class="buttonNext">{{$t('save') + ' ' + $t('school')}}</button-default>
        </div>
      </template>
      <template>
        <div class="form__footer">
          <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
          <button-default v-if="is_saved" :onClick="checkForm" class="button">{{$t('saveAndAdvance')}}</button-default>
        </div>
      </template>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputDefault,
  InputAutoComplete,
  ButtonDefault,
  FeedbackWarning,
  RadioGroup,
  TextWarning,
  InputSelect,
  InputSelectLocation,
  InputMasked,
  ButtonIconDefault,
  TextInfo
} from '@/components/atoms'
import { ModalSendForm } from '@/components/organisms/Modals'
import { countries, states } from '@/utils/locations'

export default {
  name: 'FormSelectionSchool',
  components: {
    InputDefault,
    InputAutoComplete,
    ButtonDefault,
    FeedbackWarning,
    RadioGroup,
    TextWarning,
    InputSelect,
    InputSelectLocation,
    InputMasked,
    ButtonIconDefault,
    ModalSendForm,
    TextInfo
  },
  data() {
    return {
      form: {
        id: '',
        cepValidate: false,
        school_saved: '',
        school_name: this.allDataForm.FormSelectionSchool.school_name || '',
        category: this.allDataForm.FormSelectionSchool.category || '',
        country: this.allDataForm.FormSelectionSchool.country || '',
        cnpj: this.allDataForm.FormSelectionSchool.cnpj || '',
        state: this.allDataForm.FormSelectionSchool.state || '',
        city: this.allDataForm.FormSelectionSchool.city || '',
        zipCode: this.allDataForm.FormSelectionSchool.zipCode || '',
        address: this.allDataForm.FormSelectionSchool.address || '',
        district: this.allDataForm.FormSelectionSchool.district || '',
        number: this.allDataForm.FormSelectionSchool.number || '',
        complement: this.allDataForm.FormSelectionSchool.complement || ''
      },
      modal: false,
      newSchool: false,
      options: [
        { label: this.$t('public'), value: 'public' },
        { label: this.$t('private'), value: 'private' }
      ],
      schools: [],
      school_name: [],
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      is_saved: false
    }
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  mounted() {
    this.getSchools()
    if (this.allDataForm.FormSelectionSchool) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormSelectionSchool
      }
    }
  },

  watch: {
    'form.zipCode': function (newVal, oldVal) {
      this.getAddressInfo()
    },
    'form.state': function () {
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ this.form.state }/municipios`
      )
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    allDataForm() {
      if (this.allDataForm.FormSelectionSchool) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormSelectionSchool
        }
      }
    }
  },
  methods: {
    back() {
      this.$emit('changeElement', {
        direction: 'down'
      })
    },
    async saveSchool() {
      this.resetErrors()

      if (this.is_saved) {
        this.textWarning = this.$t('schoolSaved')
        this.errors.push('enviado')
        return
      }

      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
        }
      }
      if (this.textWarning === 'Informe um CEP válido') {
        this.errors.push('zipCode')
      }
      if (this.form.country !== 'Brazil') {
        const indexZip = this.errors.indexOf('zipCode')
        if (indexZip > -1) {
          this.errors.splice(indexZip, 1)
        }
        const indexAddress = this.errors.indexOf('address')
        if (indexAddress > -1) {
          this.errors.splice(indexAddress, 1)
        }
        const indexDistrict = this.errors.indexOf('district')
        if (indexDistrict > -1) {
          this.errors.splice(indexDistrict, 1)
        }
        const indexNumber = this.errors.indexOf('number')
        if (indexNumber > -1) {
          this.errors.splice(indexNumber, 1)
        }
        const indexComplement = this.errors.indexOf('complement')
        if (indexComplement > -1) {
          this.errors.splice(indexComplement, 1)
        }
        const indexCnpj = this.errors.indexOf('cnpj')
        if (indexCnpj > -1) {
          this.errors.splice(indexCnpj, 1)
        }
      } else {
        const indexComplement = this.errors.indexOf('complement')
        if (indexComplement > -1) {
          this.errors.splice(indexComplement, 1)
        }
      }

      const indexId = this.errors.indexOf('id')
      if (indexId > -1) {
        this.errors.splice(indexId, 1)
      }
      const indexCepValidate = this.errors.indexOf('cepValidate')
      if (indexCepValidate > -1) {
        this.errors.splice(indexCepValidate, 1)
      }

      if (this.form.country === 'Brazil') {
        if (this.form.cnpj.length < 18) {
          this.errors.push('cnpj')
          this.textWarning = 'CNPJ inválido!'
        }
      }

      const indexSchool_saved = this.errors.indexOf('school_saved')
      if (indexSchool_saved > -1) {
        this.errors.splice(indexSchool_saved, 1)
      }
      if (this.errors.length) {
        return
      }
      const body = {
        school_name: this.form.school_name,
        school_type: this.form.category,
        cep: this.form.zipCode,
        uf: this.form.state,
        country: this.form.country,
        city: this.form.city,
        address: this.form.address,
        locate_number: this.form.number,
        complement: this.form.complement,
        school_cnpj: this.form.cnpj,
        district: this.form.district
      }

      try {
        const { data: response } = await this.$http.post('/school', body)
        if (response) {
          await this.getSchools()
          this.newSchool = false
          this.form.school_saved = response.school_name
          this.is_saved = true
        }
      } catch (error) {
        console.error('ERROR', error)
      }
      return true
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    getAddressInfo() {
      const cep = String(this.form.zipCode).replace(/\D/g, '')
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              this.form.cepValidate = false
              this.form.state = ' '
              this.errors.push('zipCode')
              this.textWarning = 'Informe um CEP válido'
            } else {
              this.form.cepValidate = true
              this.form.state = data.uf
              this.form.city = data.localidade
              this.form.district = data.bairro
              this.form.address = data.logradouro
              this.resetErrors()
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
            }
          })
          .catch((err) => {
            this.form.cepValidate = false
            this.form.state = ' '
            this.errors.push('zipCode')
            this.textWarning = 'Informe um CEP válido'
          })
      }
    },
    async getSchools() {
      try {
        const { data } = await this.$http.get('/school')
        if (!data.length) {
          this.errors.push('schools')
          this.textWarning = 'Não há escolas cadastradas!'
        } else {
          this.school_name = data.map((item) => item.school_name)
          this.schools = data
        }
      } catch (error) {
        console.error(error)
      }
    },
    setNewSchool() {
      this.newSchool = !this.newSchool
    },
    checkForm() {
      this.resetErrors()
      if (!this.form.school_saved) {
        this.errors.push('school')
        this.textWarning = 'Selecione/ Cadastre sua escola!'
        this.temporarySave()
        this.saveForm()
        return false
      }
      if (!this.errors.length) {
        this.form.id = this.schools.find(
          (item) => item.school_name === this.form.school_saved
        ).id
        this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      this.allDataForm.FormSelectionSchool = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.$emit('changeElement', {
          direction: 'up',
          FormSelectionSchool: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    showModal() {
      this.toggleModal('modal')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 624px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  .question {
    font-family: Mulish;
    font-size: 16px;
    line-height: 18px;
    color: $almostBlack;
  }

  .buttonNext {
    align-self: flex-end;
    margin-top: 30px;
  }

  .button__add {
    align-self: flex-start;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .buttonText__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;

    .icon-plus {
      margin-left: 10px;
    }
  }

  .icon-delete {
    max-width: 25px;
    position: relative;
    left: 405px;
    top: -35px;
    cursor: pointer;
  }

  .separator {
    border-top: 1px solid $primary;
    padding-top: 30px;
    margin-top: 10px;
    margin-bottom: 30px;

    color: $primaryDark;
    font-family: Mulish;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
  }
  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}

.input__space {
  margin-bottom: 30px;
}
</style>
