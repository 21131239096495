<template>
  <div class="body">
    <ModalFilterProjects
      :modal="filter"
      @close="filter=$event"
      @filter="filters=$event"
    />
    <ContainerComponents
      :newStyle="{ paddingBottom: '20px!important' }"
    >
      <Menu
        :menus="menus"
        :active="index"
        :newStyle="{ borderRadius: '20px 20px 0 0' }"
        @selectedMenu="index=$event"
      />
      <div class="title">Busca por projetos finalistas</div>
      <div class="filter">
        <InputSelect
          :items="editionOptionNames"
          v-model="editionSelected"
          :label="'Edição'"
          :newStyle="{ width: '300px'}"
        />
        <template v-if="validSee('grade')">
          <InputSelect
            v-if="validSee('high')"
            :items="categoryOptions"
            v-model="categorySelected"
            :label="'Categoria'"
            :newStyle="{ width: '400px', marginLeft: '10px'}"
          />
          <InputSelect
            v-if="validSee('middle')"
            :items="options"
            v-model="areaSelected"
            :label="'Área de pesquisa'"
            :newStyle="{ width: '400px', marginLeft: '10px' }"
          />
          <InputSelect
            :items="orderOptions"
            v-model="orderSelected"
            :label="'Ordenar por'"
            :newStyle="{ width: '300px', marginLeft: '10px'}"
          />
          <div class="clear">
            <ButtonDefault
              :onClick="clearFilter"
              :height="'38px'"
              :letterSize="'14px'"
            >
              Limpar
            </ButtonDefault>
          </div>
        </template>
      </div>
      <div class="filter-buttons">
        <div>
          <ButtonDefault
            :color="'#fff'"
            :letterColor="'#358194'"
            :height="'40px'"
            :width="'120px'"
            :onClick="openFilter"
          >
            <img :src="require('@/assets/filter.svg')" />
            Filtrar
          </ButtonDefault>
        </div>
        <div class="buttons-ranking">
            <template v-for="(item, indexButton) in level">
              <template v-if="item === level[index]">
                <!-- <div class="button-info" :key="`${indexButton}q`">
                  <ButtonDefault
                    :onClick="openModalConfirmationEstandes"
                    :width="'500px'"
                    :disabled="!isActiveEdition"
                  >
                    Gerar Estandes
                  </ButtonDefault>
                  <div class="info">Estandes gerados por último em 13/10/2021 às 10:00</div>
                </div> -->
                <template v-if="validSee('grade')">
                  <div class="button-info" :key="`${indexButton}e`">
                    <ButtonDefault
                      :onClick="openModalConfirmationRanking"
                      :width="'500px'"
                      :disabled="!isActiveEdition"
                    >
                      Gerar Ranking
                    </ButtonDefault>
                    <div class="info">
                      {{formatRankingGeneratednfo(cInfoRanking, 'Ranking gerado em ')}}
                    </div>
                  </div>
                </template>
              </template>
          </template>
        </div>
      </div>
      <ModalDefault
        :title="'Confirmar geração de rankings'"
        :modal="modal"
        @closeModal="modal=$event"
      >
      <div class="attention">
        <div>
          <img :src="require('@/assets/green-attention.png')" />
        </div>
        <div>
          {{ msgModal }}
        </div>
      </div>
      <div class="separate">
        <ButtonDefault
            :onClick="() => this.modal = false"
            :height="'38px'"
            :letterSize="'14px'"
            :color="'white'"
            :letterColor="'#1B778B'"
          >
            NÃO, CANCELAR *
          </ButtonDefault>
          <ButtonDefault
            :onClick="rankingGenerate"
            :height="'38px'"
            :letterSize="'14px'"
          >
            SIM, CONTINUAR *
          </ButtonDefault>
      </div>
      </ModalDefault>
      <template v-if="projects.length">
        <div class ="table">
          <TableDefault
            :data="projects"
            :allProjects="pagination.data"
            :students="pagination.data"
            :switchTitle="switchTitle"
            :header="header"
            :actions="actions"
            :infos="infos"
            :page="pagination.page"
            :lastPage="pagination.lastPage"
            :perPage="pagination.perPage"
            :total="pagination.total"
            @goPage="page=$event"
          >
          </TableDefault>
        </div>
      </template>
      <template v-else>
        <div class ="table">Não há dados</div>
      </template>
    </ContainerComponents>
    <ModalProjectEdition :modal="openEditModal" :projectData="getDataProject" @closeModal="closeModal" />
  </div>
</template>

<script>

import {
  TableDefault, FilterProjects, ModalDefault, ModalProjectEdition
} from '@/components/organisms'
import {
  ContainerComponents, InputSelect, Menu, ButtonDefault
} from '@/components/atoms'
import { areaOptions, areaOptionsElementary } from '@/utils/areas'
import { ModalFilterProjects } from '@/components/organisms/Modals'

export default {
  name: 'AdministrativeProjects',
  data() {
    return {
      switchTitle: 'Projeto compareceu à Mostratec?',
      infos: [
        { text: 'Tipo de processo', value: 'valor' },
        { text: 'Feira Afiliada', value: 'valor' }
      ],
      areaOptions,
      areaOptionsElementary,
      options: this.areaOptions,
      index: 0,
      menus: [
        'highFinalists',
        'middleFinalists',
        'childishFinalists'
      ],
      header: [
        'Código',
        'Estande',
        'Área de pesquisa',
        'País/Estado',
        'Qtd. alunos',
        'Nota da banca',
        'Ranking Geral',
        'Ranking área',
        'Classificação',
        'Livrão',
        'Edição',
        'Ver mais'
      ],
      actions: [
        {
          img: 'book.svg',
          func: this.book
        },
        {
          img: 'pencil.svg',
          func: this.edit
        },
        {
          img: 'downer.png',
          func: this.seeMore
        }
      ],
      orderOptions: [
        'Nota da banca (crescente)',
        'Nota da banca (decrescente)',
        'Ranking geral (crescente)',
        'Ranking geral (decrescente)'
      ],
      orderSelected: 'Nota da banca (decrescente)',
      orderOptionsLiterals: {
        'Nota da banca (crescente)': '+bench_score',
        'Nota da banca (decrescente)': '-bench_score',
        'Ranking geral (crescente)': '+level_ranking',
        'Ranking geral (decrescente)': '-level_ranking'
      },
      order: '-bench_score',
      areaSelected: '',
      editionOptions: [],
      editionOptionNames: [],
      editionSelected: '',
      editionActive: '',
      isActiveEdition: true,
      categoryOptions: ['1º ao 3º Ano', '4º ao 6º Ano', '7º ao 9º Ano', 'EJA'],
      categorySelected: '',
      page: 1,
      projects: [],
      students: [],
      schools: [],
      pagination: {},
      level: ['high', 'middle', 'grade'],
      modal: false,
      msgModal: '',
      infoMostratec: '',
      openEditModal: false,
      idEvent: null,
      dataProjects: [],
      filter: false,
      filters: { country: [], state: [] }
    }
  },
  components: {
    TableDefault,
    ContainerComponents,
    FilterProjects,
    InputSelect,
    Menu,
    ButtonDefault,
    ModalDefault,
    ModalProjectEdition,
    ModalFilterProjects
  },
  async mounted() {
    await this.getMostratecsData()
    await this.getProjects()
    this.options = this.areaOptions
  },
  methods: {
    book(id) {
      const routeData = this.$router.resolve({
        name: 'ProjectSummaryDocument',
        params: { id }
      })
      window.open(routeData.href, '_blank')
    },
    edit(id) {
      this.openEditModal = !this.openEditModal
      this.idEvent = id
    },
    seeMore(id) {
      this.modal = !this.modal
    },
    async getProjects() {
      let url = `/project?fairYearEdition=${ this.editionYearByName() }&internalStatusSlug=inscricao-efetivada&all=true&level=${ this.level[this.index] }&page=${ this.page }&order=${ this.order }`

      if (this.areaSelected) {
        url += `&researchArea=${ this.areaSelected }`
      }

      if (this.categorySelected) {
        url += `&project_category=${ this.categorySelected }`
      }

      if (this.filters.country.length) {
        this.filters.country.forEach((item) => {
          url += `&country=${ item.split(' ').join('%20') }`
        })
      }
      if (this.filters.state.length) {
        this.filters.state.forEach((item) => {
          url += `&state=${ item.split(' ').join('%20') }`
        })
      }

      const { data: { data } } = await this.$http.get(url)
      this.pagination = { ...data }
      this.dataProjects = []
      if (data.data.length) {
        this.infoMostratec = this.pagination.data[0].mostratec
        this.projects = data.data.map((item) => {
          const aux = {
            id: item.id || '-',
            estande: item.estande || '',
            area: this.$t(`${ item.area || '-' }`),
            countryState: this.countryState(item.students),
            qtdStudents: item.students.length || '-',
            bench_score: item.bench_score || null,
            general_ranking: item.level_ranking ? `${ item.level_ranking }º` : '-',
            area_ranking: item.area_ranking ? `${ item.area_ranking }º` : '-',
            classification: item.classification || '-'
          }
          this.dataProjects.push(
            {
              ...aux,
              attended: item.is_active || false,
              awarding_description: item.awarding_description || ''
            }
          )
          return aux
        })
        this.infos[0].value = this.projects.fair_id ? 'feira afiliada' : 'processo de seleção'
        this.infos[1].text = this.projects.fair_id ? 'Feira afiliada' : 'Instituição/escola'
        this.schools = data.data.map((item) => ({
          school_name: item.schools?.school_name || '-'
        }))

        //  this.dataProjects.push(
        //     {
        //       ... dataProjects,
        //       attended: item.is_active || false,
        //       awarding_description: item.awarding_description || ''
        //     }
        //   )
        // return aux
      } else {
        this.projects = []
      }
    },
    countryState(values) {
      const main = values.find((item) => item.main)
      return `${ main.country }-${ main.state }`
    },
    studentNames(values) {
      const main = values.find((item) => item.main)
      return main.full_name
    },
    educationalLevels(values) {
      const main = values.find((item) => item.main)
      return main.educational_level
    },
    clearFilter() {
      this.orderSelected = 'Nota da banca (decrescente)'
      this.areaSelected = ''
      this.categorySelected = ''
      this.filters = { country: [], state: [] }
    },
    openModalConfirmationRanking() {
      this.msgModal = 'Você tem certeza que deseja gerar os "Rankings Geral e Por Área" dos finalistas atuais? Esta ação não poderá ser desfeita.'
      this.modal = true
    },
    openModalConfirmationEstandes() {
      this.msgModal = 'Você tem certeza que deseja gerar os "Estandes"? Esta ação não poderá ser desfeita.'
      this.modal = true
    },
    async rankingGenerate() {
      try {
        const bodyLevel = {
          level: this.level[this.index],
          rankingType: 'level'
        }
        await this.$http.post('/project/generate-ranking', bodyLevel)
        if (this.level[this.index] === 'high') {
          const bodyArea = {
            rankingType: 'area'
          }
          await this.$http.post('/project/generate-ranking', bodyArea)
          this.modal = false
          return true
        } if (this.level[this.index] === 'middle') {
          const bodyCategory = {
            rankingType: 'category'
          }
          await this.$http.post('/project/generate-ranking', bodyCategory)
          this.modal = false
          return true
        }
        this.modal = false
        return
      } catch (error) {
        console.error(error)
      } finally {
        await this.getProjects()
      }
    },
    changeOptions() {
      let headerIndex
      this.header.forEach((item, index) => {
        if (item === 'Ranking área' || item === 'Ranking categoria') headerIndex = index
      })
      if (this.level[this.index] === 'middle') {
        this.options = this.areaOptionsElementary
        this.header[headerIndex] = 'Ranking categoria'
        return
      }
      if (this.level[this.index] === 'high') {
        this.options = this.areaOptions
        this.header[headerIndex] = 'Ranking área'
        return
      }
      this.options = []
    },
    validSee(level) {
      return this.level[this.index] !== level
    },
    formatRankingGeneratednfo(value, msg) {
      if (value) {
        const data = value.slice(0, 10).split('-').reverse().join('/')
        const hrMin = value.slice(11, 16)
        return `${ msg } ${ data } às ${ hrMin }.`
      }
      return ''
    },
    async closeModal() {
      await this.getProjects()
      this.openEditModal = false
    },
    openFilter() {
      this.filter = true
    },
    async getMostratecsData() {
      const { data } = await this.$http.get('/mostratec')
      const activeYear = data.find((item) => item.active)
      this.editionActive = activeYear.name_edition
      this.editionSelected = activeYear.name_edition
      this.editionOptions = data
      this.editionOptionNames = data.map((item) => item.name_edition)
    },
    editionYearByName() {
      const edition = this.editionOptions.find((item) => item.name_edition === this.editionSelected)
      return edition.year_edition
    }
  },
  watch: {
    async page() {
      await this.getProjects()
    },
    async orderSelected() {
      this.page = 1
      this.order = this.orderOptionsLiterals[this.orderSelected]
      await this.getProjects()
    },
    async areaSelected() {
      this.page = 1
      await this.getProjects()
    },
    async categorySelected() {
      this.page = 1
      await this.getProjects()
    },
    async editionSelected() {
      this.page = 1
      this.isActiveEdition = this.editionActive === this.editionSelected
      await this.getProjects()
    },
    async index() {
      this.clearFilter()
      this.changeOptions()
      this.page = 1
      await this.getProjects()
    },
    async filters() {
      this.page = 1
      await this.getProjects()
    }
  },
  computed: {
    cInfoRanking() {
      if (this.infoMostratec) {
        if (this.level[this.index] === 'high') {
          return this.infoMostratec.high_ranking_generated_at
        }
        if (this.level[this.index] === 'middle') {
          return this.infoMostratec.middle_ranking_generated_at
        }
      }
      return ''
    },
    getDataProject() {
      return this.dataProjects.find((project) => project.id === this.idEvent)
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  margin-top: 30px;
}

.filter {
  display: flex;
}

.title {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #303030;
  margin-bottom: 16px;

}

.title, .filter, .table {
  padding: 20px;
}

.clear {
  display: flex;
  align-items: flex-end;
  padding-bottom: 7px;
  margin-left: 10px;
  width: 100px;
}
.buttons-ranking {
  display: flex;
  justify-content: flex-end;
  // padding: 20px;

  .button-info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .info {
      margin-top: 8px;
    }
  }
}

.attention {
  display: flex;
  align-items: center;
}

.separate {
  display: flex;
  justify-content: space-between;
}

.filter-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    margin-right: 5px;
  }
}
</style>
