<template>
  <div @click.self.exact="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <div class="filter">
        <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
        <span>{{ $t('filter') }}</span>
      </div>
      <div class="modal__body">
        <div class="search__inputs">
          <div class="mt-6">
            <input-default
              id="projectCode"
              type="number"
              label="projectCode"
              v-model="projectCode"
            />
          </div>

          <input-multi-select
            v-model="projectFairEdition"
            class="mt-6"
            id="fairEditionSelect"
            label="fairEdition"
            placeholder="selectFairEdition"
            :selectedItems="filterFairEdition"
            :items="fairEditions"
            @input="() => addToFilters('filterFairEdition', 'projectFairEdition')"
            @onRemoveSelected="(index) => removeFilter('filterFairEdition', index)"
          />

          <input-multi-select
            v-model="projectTeachingLevel"
            class="mt-6"
            id="teachingLevelSelect"
            label="teachingLevels"
            placeholder="selectTeachingLevel"
            :selectedItems="filterTeachingLevel"
            :items="teachingLevels"
            @input="() => addToFilters('filterTeachingLevel', 'projectTeachingLevel')"
            @onRemoveSelected="(index) => removeFilter('filterTeachingLevel', index)"
          />

          <input-multi-select
            v-model="projectRegisterType"
            class="mt-6"
            id="registerTypeSelect"
            label="registrationType"
            placeholder="selectRegisterType"
            :selectedItems="filterRegisterType"
            :items="registerTypes"
            @input="addToFilters('filterRegisterType', 'projectRegisterType')"
            @onRemoveSelected="(index) => removeFilter('filterRegisterType', index)"
          />

          <input-multi-select
            v-model="projectResearchArea"
            class="mt-6"
            id="researchAreaSelect"
            label="researchAreaSingular"
            placeholder="selectResearchArea"
            :selectedItems="filterResearchArea"
            :items="researchAreas"
            @input="() => addToFilters('filterResearchArea', 'projectResearchArea')"
            @onRemoveSelected="(index) => removeFilter('filterResearchArea', index)"
          />

          <input-multi-select
            v-model="projectStatus"
            class="mt-6"
            id="statusSelect"
            label="status"
            placeholder="selectStatus"
            :selectedItems="filterStatus"
            :items="statuses"
            @input="() => addToFilters('filterStatus', 'projectStatus')"
            @onRemoveSelected="(index) => removeFilter('filterStatus', index)"
          />

          <input-multi-select
            v-model="projectCountry"
            class="mt-6"
            id="countrySelect"
            label="country"
            placeholder="selectCountry"
            :selectedItems="filterCountry"
            :items="countries"
            @input="() => addToFilters('filterCountry', 'projectCountry')"
            @onRemoveSelected="(index) => removeFilter('filterCountry', index)"
          />

          <input-multi-select
            v-if="showState && filterCountry.length === 1"
            v-model="projectState"
            class="mt-6"
            id="stateSelect"
            label="state"
            placeholder="selectState"
            :selectedItems="filterState"
            :items="states"
            @input="() => addToFilters('filterState', 'projectState')"
            @onRemoveSelected="(index) => removeFilter('filterState', index)"
          />

          <input-multi-select
            v-if="filterState.length === 1 && filterCountry.length === 1"
            v-model="projectCity"
            class="mt-6"
            id="citySelect"
            label="city"
            placeholder="selectCity"
            :selectedItems="filterCity"
            :items="cities"
            @input="() => addToFilters('filterCity', 'projectCity')"
            @onRemoveSelected="(index) => removeFilter('filterCity', index)"
          />

          <input-multi-select
            v-model="projectFairName"
            class="mt-6"
            id="fairNameSelect"
            label="fairName"
            placeholder="selectFairName"
            :selectedItems="filterFairName"
            :items="fairs"
            @input="() => addToFilters('filterFairName', 'projectFairName')"
            @onRemoveSelected="(index) => removeFilter('filterFairName', index)"
          />
        </div>
      </div>
      <div class="button mt-10 text-center">
        <button-default :onClick="checkForm" class="button">
          {{ $t('filter') }}
        </button-default>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ButtonDefault, InputSelect, InputDefault } from '@/components/atoms'
import { countries, states } from '@/utils/locations'
import InputMultiSelect from '@/components/atoms/Inputs/InputMultiSelect.vue'

export default {
  name: 'ModalFilterSidebarProjects',
  props: ['modal', 'title'],
  components: {
    ButtonDefault,
    InputSelect,
    InputDefault,
    InputMultiSelect
  },
  data: () => ({
    statuses: [],
    countries,
    states,
    cities: [],
    fairs: [],
    researchAreas: [],
    teachingLevels: [],
    registerTypes: [],
    fairEditions: [],
    projectResearchArea: '',
    filterResearchArea: [],
    projectTeachingLevel: '',
    filterTeachingLevel: [],
    projectRegisterType: '',
    filterRegisterType: [],
    projectCode: '',
    projectStatus: '',
    filterStatus: [],
    projectCountry: '',
    filterCountry: [],
    projectState: '',
    filterState: [],
    projectCity: '',
    filterCity: [],
    projectFairName: '',
    filterFairName: [],
    projectFairEdition: '',
    filterFairEdition: [],
    showState: false
  }),
  computed: {
    ...mapGetters({
      projectRequestFilter: 'projectRequestFilter'
    }),
    projectRequestFilter: {
      get() {
        return this.projectRequestFilter
      },
      set(newName) {
        return newName
      }
    }
  },
  mounted() {
    this.teachingLevels = [
      { text: this.$t('childEducationCompleted'), value: 'grade' },
      { text: this.$t('elementarySchoolCompleted'), value: 'middle' }, 
      { text: this.$t('elementarySchoolCategory1'), value: 'middle_cat_1' }, 
      { text: this.$t('elementarySchoolCategory2'), value: 'middle_cat_2' }, 
      { text: this.$t('elementarySchoolCategory3'), value: 'middle_cat_3' }, 
      { text: this.$t('elementarySchoolCategoryEja'), value: 'middle_cat_eja' }, 
      { text: this.$t('highSchoolOrTechnicalCompleted'), value: 'high' }
    ]
    this.registerTypes = [
      { text: this.$t('selectionProcess'), value: 'processSelection' },
      { text: this.$t('affiliatedFairSingle'), value: 'affiliatedFairSingle' }
      // { text: this.$t('affiliatedFairSingle'), value: 'affiliatedFair' }
    ]
    this.researchAreas = [
      { text: this.$t('microbiology'), value: 'biology' },
      { text: this.$t('bioquimics'), value: 'bioquimics' },
      { text: this.$t('animalPlantsSciences'), value: 'animalSciences' },
      { text: this.$t('ambientalSciences'), value: 'ambientalSciences' },
      { text: this.$t('computerScience'), value: 'computacionalSciences' },
      { text: this.$t('healthSciences'), value: 'healthSciences' },
      { text: this.$t('planetarySciences'), value: 'planetarySciences' },
      { text: this.$t('educationHumanities'), value: 'humanities' },
      { text: this.$t('sanitaryEngineering'), value: 'sanitaryEngineering' },
      { text: this.$t('materialsAndEngineering'), value: 'materialsEngineering' },
      { text: this.$t('electricEngineering'), value: 'eletricEngineering' },
      { text: this.$t('electronicEngineering'), value: 'eletronicEngineering' },
      { text: this.$t('mechanicalEngineering'), value: 'mechanicalEngineering' },
      { text: this.$t('historyAndSocialSciences'), value: 'socialSciences' }
    ]
    this.statuses = [
      { text: this.$t('pendingRevision'), value: 'aguardando-revisao' },
      { text: this.$t('underReview'), value: 'em-revisao' },
      { text: this.$t('waitingPayment'), value: 'aguardando-pagamento' },
      { text: this.$t('registrationComplete'), value: 'inscricao-efetivada' },
      { text: this.$t('pendingAcceptStatus'), value: 'aguardando-aceite-feira' },
      { text: this.$t('reviewAccepted'), value: 'revisao-aceita' },
      { text: this.$t('reviewDenied'), value: 'revisao-negada' },
      { text: this.$t('notAccept'), value: 'reprovado' }
    ]
    this.getFairNames()
    this.getMostratecsData()
    const filters = this.$store.getters.projectRequestFilter
    this.filterStatus = filters.status ? filters.status : []
    this.filterCountry = filters.country ? filters.country : []
    this.filterCity = filters.city ? filters.city : []
    this.filterState = filters.state ? filters.state : []
    this.filterTeachingLevel = filters?.teaching_level ? filters.teaching_level : []
    this.filterRegisterType = filters?.register_type ? filters.register_type : []
    this.filterResearchArea = filters?.research_area ? filters.research_area : []
    this.filterFairName = filters.fair_name ? filters.fair_name : []
    this.filterFairEdition = filters?.fair_edition || []
    this.projectCode = filters?.project_code || ''
  },
  created() {
    document.addEventListener('keyup', (event) => {
      if (event.code === 'Enter' || event.key === 'Enter') {
        return this.checkForm()
      }
    })
  },
  methods: {
    ...mapActions({
      projectRequestFilter: 'projectRequestFilter'
    }),
    addToFilters(array, value) {
      if (!this[array].includes(this[value])) {
        this[array].push(this[value])
        this[value] = ''
      }
    },
    removeFilter(array, index) {
      this[array].splice(index, 1)
    },
    checkForm() {
      const filters = {
        ...this.$store.getters.projectRequestFilter,
        status: this.filterStatus,
        country: this.filterCountry,
        state: this.filterState,
        city: this.filterCity,
        teaching_level: this.filterTeachingLevel,
        register_type: this.filterRegisterType,
        research_area: this.filterResearchArea,
        fair_name: this.filterFairName,
        fair_edition: this.filterFairEdition,
        project_code: this.projectCode
      }

      if (!filters.country?.length || !filters.country?.includes('Brazil')) {
        filters.state = []
      }

      if (!filters.state?.length) {
        filters.city = []
      }

      Object.keys(filters).forEach((key) => {
        if (!filters[key].length) delete filters[key]
      })

      this.$store.commit('PROJECT_LIST_FILTER', filters)
      this.$emit('closeModal')
      this.$emit('filterProjectRequestsList')
    },
    getBadgeLabel(list, item) {
      return list.filter((object) => object.value === item)[0]?.text || ''
    },
    async getFairNames() {
      // alert('1')
      // const { data } = await this.$http.get('/fair/search/name')
      const { data } = await this.$http.get('/fair/search/nameActive')
      const formatedNames = data.map((i) => ({
        text: i.name,
        value: i.name
      }))
      this.fairs = formatedNames
    },
    async getMostratecsData() {
      const { data } = await this.$http.get('/mostratec')
      const activeYear = data.find((item) => item.active)
      this.projectFairEdition = String(activeYear.year_edition)
      if (this.filterFairEdition.length === 0) {
        this.addToFilters('filterFairEdition', 'projectFairEdition')
      }
      this.fairEditions = data.map(({ year_edition }) => ({
        text: String(year_edition),
        value: String(year_edition)
      }))
    }
  },
  watch: {
    filterCountry() {
      if (this.filterCountry.includes('Brazil')) {
        this.showState = true
      } else {
        this.showState = false
        this.filterState = []
      }
    },
    filterState() {
      this.cities = []
      const [state] = this.filterState
      this.state = state
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ this.state }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.forEach((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  left: 0px;
  position: fixed;
  height: 100%;
  top: 0px;
  width: 100%;
  z-index: 5;

  .modal__inner {
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding: 25px;
    height: 100%;
    max-width: 649px;

    .modal__body {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      width: 100%;
      max-height: 100%;
      flex: 1;
      overflow-y: scroll;

      .search__inputs {
        margin: 5px 0px 15px 0px;
      }
    }
  }
}

.filter {
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: $primary;
  border-bottom: 1px solid $primary;
  padding-bottom: 12px;
}
</style>
