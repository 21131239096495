<template>
  <label class="label" :for="id">
    {{ $t(label) }}
    <input
      class="input"
      :id="id"
      :type="type"
      :placeholder="$t(placeholder)"
      :value="value"
      v-mask="mask"
      v-on:input="$emit('input', $event.target.value)"
      :maxlength="max || false"
      :disabled="disabled"
    />
  </label>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'InputMasked',
  props: {
    label: String,
    type: String,
    id: String,
    placeholder: String,
    value: String | Number,
    max: Number,
    mask: {
      type: Array | String,
      default: ''
    },
    disabled: Boolean
  },
  directives: { mask }
}
</script>

<style lang="scss" scoped>
.label {
  color: #303030;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.warning {
  color: $attention;

  .input {
    border-color: $attention;
  }
}

.input {
  background: #ffffff;
  border: 1px solid #cdcbcb;
  border-radius: 10px;
  height: 35px;
  margin-top: 5px;
  max-width: 400px;
  padding: 8px 15px;
  outline: none;

  &::placeholder {
    color: #afaeae;
    font-size: 14px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
  }
}
</style>
