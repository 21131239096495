<template>
  <div>
  <aside>
  <div :class="[{wrapper__analisisInfo_after: this.aproved === false},'wrapper__analisis-info']">
      <div class="revision_box">
        <h3 class="revision_title">
          {{$t('projectsRevision')}}
        </h3>
        <button-default  v-if="this.status === 'aguardando-revisao'" class="start_button" :onClick="startReview" >{{$t('startReview')}}</button-default>
      </div>
      <div class="box_info">
        <div class="box_info__content">
          <h1 class="box_info__title">{{$t('isAproved')}}</h1>
          <p class="box_info__text">{{$t('ifAproved')}}</p>
        </div>
        <div class="box_info__confirmation">
          <button-default
            :class="[
              {box_info__button_accept: this.aproved === true},
              {box_info__button: this.aproved === null},
              {box_info__button_disabled: this.aproved === false},
              {disabled: disabled}
            ]" :onClick="() => aproval(true)"
              :disabled="disabled">
            <icon-default
              v-if="this.aproved !== null"
              class="box_info__icon"
              :url="cAcceptIcon"
              alt="Ícone de pdf"
            />
            <img
              v-else-if="this.aproved === null"
              src="@/assets/button_accept.png"
              alt class="box_info__icon"
            />
            {{$t('subscriptionAproved')}}
          </button-default>
          <button-default
            :class="[
              {box_info__button_reject: this.aproved === false},
              {box_info__button: this.aproved === null},
              {box_info__button_disabled: this.aproved === true},
              {disabled: disabled}
            ]"
            :onClick="() => aproval(false)"
            :disabled="disabled"
          >
            <icon-default
              v-if="this.aproved !== null"
              class="box_info__icon"
              :url="cRejectIcon"
              alt="Ícone de pdf"
            />
            <img
              v-else-if="this.aproved === null"
              src="@/assets/button_reject.png"
              alt class="box_info__icon"
            />
            {{$t('subscriptionRejected')}}
          </button-default>
        </div>
        <div class="box_info__step">
          <input type="checkbox" name="step" id="step" class="box_info__input" :disabled="disabled">
          <label for="step" class="box_info__label">{{$t('substitute')}}</label>
        </div>
      </div>

      <div class="main_info">
        <div :class="[{input_after: this.aproved === false}, 'input']">
        <input-score
          required
          :class="[{warning: checkErrorIfContains('score')}, {disabled: disabled}]"
          :disabled="disabled"
          label="Nota do projeto"
          id="score"
          placeholder="Dê uma nota de 0 a 10"
          type="text"
          v-model="score"
          @input="resetErrors"
          :mask="['##']"
        />
        <input-revisioner
          :class="[ 'mb-7', 'ml-7', {warning: checkErrorIfContains('name')}, {disabled: disabled}]"
          :disabled="disabled"
          label="Nome do responsável pela revisão"
          id="name"
          placeholder="Nome Sobrenome"
          type="text"
          v-model="name"
          @input="resetErrors"
        />
      </div>

        <div v-if="this.aproved === false" class="input_aprove">
        <input-text-area
          :class="[{warning: checkErrorIfContains('project_feedback')}, {disabled: disable}, 'textarea']"
          :disabled="disabled"
          label="projectOpinion"
          :allText="project_feedback"
          v-model="project_feedback"
          @input="project_feedback = $event"
          @change="resetErrors"
          :placeholder="$t('considerations')"
          :textMax="2000"
          :info="$t('opinionToStudents')"
        />
      </div>

        <div :class="[{revision__files_after: this.aproved === false},'revision__files']">
          <span class="researchPlan_title">{{$t('projectSummary')}}</span>
          <p class="separator"></p>

          <div class="mb-4">
            <div class="abstract">
              <h1 class="abstract__title">{{$t( 'abstract')}}</h1>
              <p class="abstract__content">{{abstract}}</p>
            </div>
          </div>

          <div class="mb-4">
            <span class="researchPlan_title">{{$t('ResearchPlan')}}</span>
            <p class="separator"></p>

            <div class="abstract">
              <h1 class="abstract__title">{{$t('ThemeLabel')}}</h1>
              <p class="abstract__content">{{form.theme}}</p>
            </div>

            <div class="abstract">
              <h1 class="abstract__title">{{$t('JustificationLabel')}}</h1>
              <p class="abstract__content">{{form.justification}}</p>
            </div>

            <div class="abstract">
              <h1 class="abstract__title">{{$t('ProblemLabel')}}</h1>
              <p class="abstract__content">{{form.problem}}</p>
            </div>

            <div class="abstract">
              <h1 class="abstract__title">{{$t('ObjectivesLabel')}}</h1>
              <p class="abstract__content">{{form.objective}}</p>
            </div>
            <div class="abstract">
              <h1 class="abstract__title">{{$t('MethodologyLabel')}}</h1>
              <p class="abstract__content">{{form.methodology}}</p>
            </div>

            <div class="abstract">
              <h1 class="abstract__title">{{$t('ResultsLabel')}}</h1>
              <p class="abstract__content">{{results}}</p>
            </div>

          </div>

        </div>

      <div class="mb-7 revision_title_wrapper">
        <h1 class="revision__title">Revisão dos arquivos inscritos</h1>
        <p class="revision__text">
          ==> {{$t('useCheckbox')}}
        </p> 
      </div>        
      <div  class="files_container_wrapper">
        <div v-for="(item, index) in files" :key="index" class="files_container">
          <div v-if="item.file !== 'pdf' && item.file!== 'presentation_link'" class="files">
            <div
              class="icon_wrapper"
              disabled
              @click="downloadFile(item.file)"
              @mouseenter="enterModel(`${displayManager(item.file, false)}Hover`)"
              @mouseleave="leaveModel(`${displayManager(item.file, false)}Hover`)"
            > 
              <icon-default
                :url="displayManager(item.file, true)"
                alt="Ícone de pdf"
              />
            </div>
            <div :class="[{warning: checkErrorIfContains(item.file)}, 'files__name']">
              <input
                type="checkbox"
                :disabled="disabled"
                name="step"
                id="step"
                :class="['box_info__input']"
                v-model="uploads[displayManager(item.file, false)]"
                @change="resetErrors"
              />
              <label
                for="step"
                :class="[{disabled: disabled}, 'box_info__label']"
              >
                {{$t(displayManager(item.file, false))}}
              </label>
               <label v-if="item.file === 'banner'" :class="[{disabled: disabled}, 'files__extension']">
                {{item.url}}
              </label>
              <label v-else :class="[{disabled: disabled}, 'files__extension']">
                {{item.name}}
              </label>
            </div>
          </div>
        </div>
      </div>

        <div class="footer">
          <button-default  v-if="this.status === 'em-revisao'" class="finish_button" :onClick="checkForm" :disabled="disabled">{{$t('finishReview')}}</button-default>
        </div>
    </div>
    </div>
    <footer-send-email  v-if="role !='crc'"
      :projectId="[projectInfo.id]"
      :status="status"
      :sentEmail="projectInfo.sent_reply"
      @att-data="attStatus"
    />
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
    <feedback-warning
      v-if="revisionFinished"
      :text="textWarning"
      :onClick="toggleFinished"
      color="#33B850"
    />
  </aside>
  </div>
</template>

<script>
import {
  ButtonDefault,
  InputScore,
  InputRevisioner,
  IconDefault,
  TextInfo,
  InputTextArea,
  FeedbackWarning
} from '@/components/atoms'
import { FooterSendEmail } from '@/components/organisms'

export default {
  name: 'ProjectsRevision',
  components: {
    ButtonDefault,
    InputScore,
    InputRevisioner,
    IconDefault,
    TextInfo,
    InputTextArea,
    FeedbackWarning,
    FooterSendEmail
  },
  data() {
    return {
      disabled: false,
      //fullStatus: {},
      fullStatus: [],
      errors: [],
      role: '',
      aproved: null,
      score: null,
      name: null,
      abstract: '',
      results: '',
      form: {
        theme: '',
        problem: '',
        justification: '',
        objective: '',
        methodology: ''
      },
      //new
      files: [],
      uploads: {
        humans: false,
        humansHover: false,
      },
      substitute: false,
      revisionFinished: false,
      project_feedback: '',
      errors: []
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    },
    internal_feedback: {
      type: String,
      required: false
    },
    project_penalty: {
      type: String,
      required: false
    },
    status: {
      type: String,
      required: false
    }
  },
  computed: {
    cRejectIcon() {
      return this.aproved ? 'grey_reject.png' : 'white_reject.png'
    },
    cAcceptIcon() {
      return this.aproved ? 'white_accept.png' : 'grey_accept.png'
    },
    cRevisionIcon() {
      return this.revision ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cContinuityIcon() {
      return this.continuity ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cHumansIcon() {
      return this.humans ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cSubstIcon() {
      return this.subst ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cRiscsIcon() {
      return this.riscs ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cBannerIcon() {
      return this.banner ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cPresentationIcon() {
      return this.presentation ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cVertebratesIcon() {
      return this.vertebrates ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cDangerousIcon() {
      return this.dangerous ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cInstituitionIcon() {
      return this.instituition ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cScientistIcon() {
      return this.scientist ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    cVideoIcon() {
      return this.video ? 'mp4-icon.png' : 'grey_mp4.png'
    }
  },
  async mounted() {
    if (Object.keys(this.projectInfo).length) {
      this.getResearchData()
      this.disabled = this.checkIfDisabled()
    }
    if (Object.keys(this.projectInfo).length) {
      this.loadFiles()
      this.loadRevisionInfos()
      this.disabled = this.checkIfDisabled()
    }    
    this.fullStatus = await this.getStatus()
    this.role = localStorage.getItem('role')
  },
  watch: {
    'projectInfo.project_feedback': function () {
      this.project_feedback = this.projectInfo.project_feedback
    },
    'projectInfo.files': function () {
      this.loadFiles()
    },
    status() {
      this.disabled = this.checkIfDisabled()
    },
    async projectInfo() {
      if (!this.fullStatus) { this.fullStatus = await this.getStatus() }
      this.loadRevisionInfos()
    }

  },
  methods: {
    displayManager(value, isIcon) {
      const display = {
        banner: isIcon ? this.bannerIcon() : 'banner',
        biological: isIcon ? this.biologicalIcon() : 'bios',
        cientistQualified: isIcon ? this.scientistIcon() : 'scientist',
        continuousProject: isIcon ? this.continuityIcon() : 'continuity',
        humans: isIcon ? this.humansIcon() : 'humans',
        presentWork: isIcon ? this.presentationIcon() : 'rulesOfConduct',
        researchInstitution: isIcon ? this.institutionIcon() : 'researchInstituitionIndustry',
        revision: isIcon ? this.revisionIcon() : 'revision',
        risksAnalysis: isIcon ? this.risksAnalysisIcon() : 'riscs',
        tissueSubstances: isIcon ? this.substIcon() : 'tissues',
        vertebrateAnimals: isIcon ? this.vertebratesIcon() : 'vertebrates',
        video: isIcon ? this.videoIcon() : 'video',
        presentationLink: isIcon ? this.presentationLinkIcon() : 'presentationLink'
      }
      return display[value]
    },
    loadFiles() {
      if (this.files && !this.files.length) {
        Object.entries(this.projectInfo.files).forEach(([key, value]) => {
          if (value.name && key!='video') {
            console.log('file.key',key)
            this.files.push({
              file: key,
              name: value.name,
              url: value.url
            })
          }
        })
        //if (this.projectInfo.presentation_link?.length) {
        const presentationLinkExists = this.files.some(file => file.file === 'presentationLink');
        console.log('presentationLinkExists',presentationLinkExists)

        if (!presentationLinkExists && this.projectInfo.presentation_link?.length) {          
          this.files.push({
            file: 'presentationLink',
            name: this.projectInfo.presentation_link,
            url: this.projectInfo.presentation_link
          })
        }
      }
    },
    loadRevisionInfos() {
      if (this.status === 'revisao-aceita' || this.status === 'aguardando-pagamento') {
        this.aproved = true
      } else if (this.status === 'revisao-negada' || this.status === 'aguardando-pagamento') {
        this.aproved = false
      } else {
        this.aproved = null
      }
      this.substitute = this.projectInfo.substitute
      this.score = this.projectInfo.project_grade
      this.name = this.projectInfo.revision_responsible
      this.project_feedback = this.projectInfo.project_feedback

      if (this.projectInfo.internal_status.id > this.slugToID('em-revisao')
      && this.slugToID('em-revisao') !== null) {
        Object.entries(this.uploads).forEach(([key]) => {
          if (!String(key).includes('Hover')) {
            this.uploads[key] = true
          }
        })
      }
    },
    getResearchData() {
      this.abstract = this.projectInfo.summary
      this.results = this.projectInfo.results
      this.form = this.projectInfo.research_plan[0]
      this.substitute = this.projectInfo.substitute
      this.score = this.projectInfo.project_grade
      this.name = this.projectInfo.revision_responsible
      this.project_feedback = this.projectInfo.project_feedback

      if (this.status === 'revisao-aceita' || this.status === 'aguardando-pagamento') {
        this.aproved = true
      } else if (this.status === 'revisao-negada' || this.status === 'aguardando-pagamento') {
        this.aproved = false
      }
    },
    toggleModel(model) {
      this[model] = !this[model]
    },
    resetErrors() {
      this.errors = []
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    checkIfDisabled() {
      return this.status ? (this.status !== 'em-revisao') : false
    },
    checkForm() {
      if (this.projectInfo.internal_status_id !== 3) {
        this.textWarning = 'Necessário aceitar ou negar a revisão!'
        this.errors.push(1)
        return false
      }
      if (this.score == null || this.name === null) {
        this.textWarning = 'Necessário informar a nota e o responsável pela revisão do projeto.'
        this.errors.push('score')
        this.errors.push('name')
        return false
      }
      if (!this.project_feedback && !this.aproved) {
        this.textWarning = 'Em caso de reprovação, informe o parecer do projeto.'
        this.errors.push('project_feedback')
        return false
      }
      //alert('files')
      for (const item of this.files) {
        //alert('entrou no loop files')
        const nameInUploads = this.displayManager(item.file)
        if (!this.uploads[nameInUploads] && nameInUploads !== undefined) {
          this.textWarning = 'Necessário verificar todos os arquivos de download.'
          this.errors.push(item.file)
        }
      }
      if (this.errors.length) { return false }
      this.finishReview()
      return true
    },
    async startReview() {
      try {
        this.$emit('statusChange', {
          internal_status: this.slugToID('em-revisao'),
          external_status: this.slugToID('em-revisao')
        })
      } catch (error) {
        console.error(error.message)
      }
    },
    async finishReview() {
      try {
        const newData = {
          ...this.projectInfo,
          substitute: this.substitute,
          project_grade: Number(this.score),
          revision_responsible: this.name,
          project_feedback: this.project_feedback,
          internal_feedback: this.internal_feedback,
          project_penalty: this.project_penalty
        }
        await this.$http.patch(`/project/${ this.projectInfo.id }`, newData)
        this.textWarning = 'Revisão salva com sucesso.'
        this.toggleFinished()
        this.$emit('statusChange', {
          internal_status: this.aproved
            ? this.slugToID('revisao-aceita')
            : this.slugToID('revisao-negada')
        })
      } catch (error) {
        console.error(error.message)
      }
    },
    toggleFinished() {
      this.revisionFinished = !this.revisionFinished
    },
    async getStatus() {
      const { data } = await this.$http.get('/status/project?statusType=projeto')
      return data.data
    },
    async attStatus() {
      this.fullStatus = await this.getStatus()
      window.location.reload()
    },
    slugToID(slug) {
      const status = this.fullStatus.find((item) => item.slug === slug)
      return status?.id || null
    },
    aproval(value) {
      this.aproved = value
      if (this.status === 'aguardando-revisao' || this.status == 'aguardando-aceite-feira' || !this.projectInfo.internal_status_id) {
        this.$emit('statusChange', {
          internal_status: this.slugToID('em-revisao')
        })
      }
    },
    downloadFile(file) {
      const myFile = this.files.find((item) => item.file === file)
      window.open(myFile.url)
    },
    rejectIcon() {
      return this.aproved ? 'grey_reject.png' : 'white_reject.png'
    },
    acceptIcon() {
      return this.aproved ? 'white_accept.png' : 'grey_accept.png'
    },
    revisionIcon() {
      if (this.uploads.revisionHover) {
        return this.uploads.revisionHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.revision ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    biologicalIcon() {
      if (this.uploads.biosHover) {
        return this.uploads.biosHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.bios ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    continuityIcon() {
      if (this.uploads.continuityHover) {
        return this.uploads.continuityHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.continuity ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    humansIcon() {
      if (this.uploads.humansHover) {
        return this.uploads.humansHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.humans ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    risksAnalysisIcon() {
      if (this.uploads.riscsHover) {
        return this.uploads.riscsHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.riscs ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    substIcon() {
      if (this.uploads.tissuesHover) {
        return this.uploads.tissuesHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.subst ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    risksIcon() {
      if (this.uploads.riscsHover) {
        return this.uploads.riscsHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.riscs ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    bannerIcon() {
      if (this.uploads.bannerHover) {
        //return this.uploads.bannerHover ? 'download.svg' : 'grey_pdf.png'
        return this.uploads.bannerHover ? 'play_blue.svg' : 'play_gray.svg'
      } return this.uploads.banner ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    presentationIcon() {
      if (this.uploads.presentationHover) {
        return this.uploads.presentationHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.presentation ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    vertebratesIcon() {
      if (this.uploads.vertebratesHover) {
        return this.uploads.vertebratesHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.vertebrates ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    dangerousIcon() {
      if (this.uploads.dangerousHover) {
        return this.uploads.dangerousHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.dangerous ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    institutionIcon() {
      if (this.uploads.researchInstituitionIndustryHover) {
        return this.uploads.researchInstituitionIndustryHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.institution ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    scientistIcon() {
      if (this.uploads.scientistHover) {
        return this.uploads.scientistHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.scientist ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    videoIcon() {
      if (this.uploads.videoHover) {
        return this.uploads.videoHover ? 'download.svg' : 'grey_mp4.png'
      } return this.uploads.video ? 'mp4-icon.png' : 'grey_mp4.png'
    },
    presentationLinkIcon() {
      if (this.uploads.presentationLinkHover) {
        return this.uploads.presentationLinkHover ? 'play_blue.svg' : 'play_gray.svg'
      } return this.uploads.presentationLink ? 'play.svg' : 'play_gray.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper__analisis-info{
  background: #fdfdfd;
  border-radius: 10px;
  font-family: Mulish;
  height:100%;
   .disabled {
    filter: grayscale(100%) saturate(0%) opacity(70%);
    cursor: alias;
    color: #686868;
  }

  .revision_box{
    height: 57px;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding-bottom: 10px;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 70px;

    .revision_title{
      margin-left: 20px;
      margin-top: 12px;
      align-self: center;
      color: $primary;
    }
    .start_button {
      flex-direction: row;
      align-self: center;
      margin-top: 9px;
      margin-right: 20px;
      width: 255px;
      height: 50px;
      background: #1B778B;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }

  }

  .box_info{
    background-color: #F6F6F6;
    border-radius: 10px;
    width: 90.6%;
    margin-top: 30px;
    margin-left: 4.9%;
    padding: 30px;
    text-align: center;

    &__content{
      .box_info__text{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.57px;
        color: #686868;
      }
      .box_info__title{
        font-size: 18px;
        font-weight: 600;
        line-height: 22.59px;
      }
    }

    &__confirmation{
      display: flex;
      justify-content: center;

      .box_info__button, .box_info__button_accept, .box_info__button_reject, .box_info__button_disabled{
        color: #686868;
        background-color: white;
        font-size: 14px;
        font-weight: 600;
        max-width: 235px;
        line-height: 18px;
        margin: 10px;
        padding: 5px 7px;
        text-transform: initial;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-around;
      }
      .box_info__button_accept{
        background-color: #008000;
        color: white;
      }
      .box_info__button_reject{
        background-color: #F9537B;
        max-width: 235px;
        color: white;
      }
    }

    &__step{
      margin-top: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;

      input{
        margin-right: 5px;
      }
    }
    &__icon {
      margin-right: 2px;
    }
  }

  .main_info{
    width: 90.6%;
    margin-left:4.9%;

    .input{
      display: flex;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .revision__files{
      margin-top: 30px;
      font-size: 14px;
      line-height: 18px;
      color: #686868;

      .abstract__content{
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 35px;
      }

      .separator {
        background-color: #CDCDCD;
        height: 1px;
        margin-top: 5px;
        margin-bottom: 15px;
        width: 100%;
      }

      h1{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $almostBlack;
        margin-left: 20px;
        margin-bottom: 10px;
      }
      .researchPlan_title, .revision__title{
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        color: $almostBlack;
        margin-top: 30px;
        margin-left: 0px;
      }

      .revision__title{
        line-height: 10px;
      }
    }
    .footer {
      margin-top: 100px;
      display: flex;
      width:100%;
      justify-content: flex-end;
      padding-bottom: 50px;
    }
  }
  .warning {
    color: #ff9800;
    border-bottom-color: $attention;
  }
}
</style>
