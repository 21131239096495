<template>
  <div class="container-app">
    <header-project />
    <paragraph class="paragraphRed">
        {{$t('warningForMultiple')}}
      </paragraph>
    <div class="wrapper">
      <form-selection />
    </div>
  </div>
</template>

<script>
import { FormSelection } from '@/components/organisms'
import { HeaderProject } from '@/components/organisms/Headers'

export default {
  name: 'FormSelectionTemplate',
  components: { FormSelection, HeaderProject }
}
</script>

<style lang="scss" scoped>

.container-app {
  background-color: #F1F5F6;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px 50px;

  .wrapper {
    background: #FDFDFD;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    margin-top: 5px;
    opacity: 0.9;
    padding: 25px 28px;
    width: 100%;
  }

  .paragraphRed {
  color: red;
  margin: 5px 0px 0px 0px;
  padding: 5px 25px 5px 25px;
  text-align: left;
  font-weight:700;
  background: #FDFDFD;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 100%;
}

}
</style>
