<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('projectData')}}</span>
        <span class="form__title__complement"></span>
      </div>
      <div class="form__info">
        <text-warning
          :text="$t('considerAllFieldObligatory')"
        />
      </div>
      <input-default
        :class="[{ warning: checkErrorIfContains('projectTitle') }, 'mb-7 title_checkbox']"
        label=projectTitle
        id="projectTitle"
        placeholder=enterTheProjectTitle
        v-model="form.projectTitle"
        @input="resetErrors"
        max="250"
        :width="800"
      />
      <RadioGroup
        :class="[{ warning: checkErrorIfContains('typeSearch') }, 'mb-2 btitle_checkbox']"
        label=typeOfResearchDevelop
        :options="typeOptions"
        v-model="form.typeSearch"
        @change="resetErrors"
      />
      <div v-if="form.typeSearch === 'tecnológica'" class="info mb-2">
        <img
          class="info__icon"
          :src="require(`@/assets/info-black.png`)"
          alt="Informação sobre a pesquisa"
        />
        <p class="text">
          Pesquisa de Engenharia - Materialização de um produto ou processo através de um modelo,
          protótipo ou instalação piloto. Execução de uma obra, fabricação de um produto,
          fornecimento de um serviço, execução de um processo.
        </p>
      </div>

      <div v-if="form.typeSearch === 'científica'" class="info">
        <img
          class="info__icon"
          :src="require(`@/assets/info-black.png`)"
          alt="Informação sobre a pesquisa"
        />
        <p class="text">Básica ou Aplicada e Pesquisa de engenharia.</p>
      </div>

      <!-- //:items="areaOptions" -->
      <input-select
        id="areaSelect"
        :class="[{ warning: checkErrorIfContains('area') }, 'mb-4']"
        :items="computedAreas"
        v-model="form.area"
        label=researchAreaSingle
        placeholder="Selecione a Área de pesquisa"
        @input="resetErrors"
        :selected="form.area"
      />

      <!-- //:dataAreas="areas[form.area]" -->
      <InputCheckboxProject
        v-if="form.area"
        :class="[{ warning: checkErrorIfContains('subareas') }, 'mb-7 title_checkbox']"
        :dataAreas="computedDataAreas"
        @selecteds="form.sub_areas = $event"
        :actives="form.sub_areas"
        :one="false"
      />
      <input-default
        v-if="form.sub_areas.includes('others')"
        :class="[{ warning: checkErrorIfContains('otherSubArea') }, 'mb-7']"
        label="Existe outra sub-área?"
        id="otherSubArea"
        placeholder="Se sim, insira qual é a outra sub-área"
        type="text"
        v-model="form.otherSubArea"
        @input="resetErrors"
      />
      <div class="radioGroups">
        <div class="involvingAnimals">
          <RadioGroup
            :class="[{ warning: checkErrorIfContains('hasAnimals') }, 'mb-2 title_checkbox']"
            label=doesItInvolveVertebrateAnimals
            :options="generalOptions"
            v-model="form.hasAnimals"
            @change="resetErrors"
          />

          <RadioGroup
            :class="[{ warning: checkErrorIfContains('hasHumans') }, 'mb-2 title_checkbox']"
            label=doesItInvolveHumans
            :options="generalOptions"
            v-model="form.hasHumans"
            @change="resetErrors"
          />

          <div class="info">
            <img
              class="info__icon"
              :src="require(`@/assets/info-black.png`)"
              alt="Informação sobre a pesquisa"
            />
            <p class="text">
              {{$t('doesItInvolveHumansInfo')}}
            </p>
          </div>
        </div>
        <RadioGroup
          :class="[{ warning: checkErrorIfContains('hasBioSubst') }, 'mb-2 title_checkbox']"
          label=doesItInvolveBiologicalSubstances
          :options="generalOptions"
          v-model="form.hasBioSubst"
          @change="resetErrors"
        />

        <div :class="['mb-2']">
          <RadioGroup
            :class="[
              { warning: checkErrorIfContains('dangerousBiologicals') },
              'title_checkbox'
            ]"
            label=doesItInvolveDangerousBiologicalSubstances
            :options="generalOptions"
            v-model="form.dangerousBiologicals"
            @change="resetErrors"
          />
          <RadioGroup
            :class="[
              { warning: checkErrorIfContains('humanOrAnimalTissue') },
              'title_checkbox'
            ]"
            label=doesItInvolveTissueFromHumans
            :options="generalOptions"
            v-model="form.humanOrAnimalTissue"
            @change="resetErrors"
          />
        </div>

        <RadioGroup
          :class="[{ warning: checkErrorIfContains('controlledSubst') }, 'mb-2 title_checkbox']"
          label=doesItInvolveControlledSubstances
          :options="generalOptions"
          v-model="form.controlledSubst"
          @change="resetErrors"
        />

        <RadioGroup
          :class="[{ warning: checkErrorIfContains('dangerousEquip') }, 'mb-2 title_checkbox']"
          label=doesItInvolveDangerousSubstances
          :options="generalOptions"
          v-model="form.dangerousEquip"
          @change="resetErrors"
        />

        <div class="researchOnInstituition">
          <RadioGroup
            :class="[
              { warning: checkErrorIfContains('researchOnInstituition') },
              'mb-2 title_checkbox'
            ]"
            label=isTheResearchCarriedInInstituition
            :options="generalOptions"
            v-model="form.researchOnInstituition"
            @change="resetErrors"
          />

          <div class="info">
            <img
              class="info__icon"
              :src="require(`@/assets/info-black.png`)"
              alt="Informação sobre a pesquisa"
            />
            <p class="text">{{$t('isTheResearchCarriedInInstituitionInfo')}}</p>
          </div>
        </div>
      </div>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndContinue')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputDefault,
  InputSelect,
  InputCheckboxProject,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  ButtonIconDefault,
  InputMasked,
  RadioGroup
} from '@/components/atoms'
import { ModalSendForm } from '@/components/organisms/Modals'
import { areas, areaOptions } from '@/utils/areas'
import { areasEn, areaOptionsEn } from '@/utils/areasEn'
import { areasEs, areaOptionsEs } from '@/utils/areasEs'
// import { mapGetters, mapActions } from "vuex";

export default {
  name: 'FormProjectHighData',
  components: {
    InputDefault,
    InputSelect,
    InputCheckboxProject,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    ButtonIconDefault,
    InputMasked,
    ModalSendForm,
    RadioGroup
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  data() {
    return {
      modal: false,
      disabled: true,
      area: '',
      selected_areas: [],
      form: {
        area: '',
        otherSubArea: '',
        typeSearch: '',
        projectTitle: '',
        hasAnimals: '',
        hasHumans: '',
        hasBioSubst: '',
        dangerousBiologicals: '',
        humanOrAnimalTissue: '',
        controlledSubst: '',
        dangerousEquip: '',
        researchOnInstituition: '',
        sub_areas: []
      },
      areas,
      areasEn,
      areasEs,
      typeOptions: [
        { label: this.$t('scientific'), value: 'cientifica' },
        { label: this.$t('technological'), value: 'tecnologia' }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ],
      areaOptions,
      areaOptionsEn,
      areaOptionsEs,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      controlHumanOrAnimalTissue: false,
      controlDangerousBiologicals: false
    }
  },
  mounted() {
    if (this.allDataForm.FormProjectHighData) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectHighData
      }
    }
  },
  computed: {
    computedAreas() {
      switch (this.$i18n.locale) {
        case 'pt':
          return this.areaOptions;
        case 'en':
          return this.areaOptionsEn;
        case 'es':
          return this.areaOptionsEs;
        default:
          return this.areaOptions; // Padrão para português
      }
    },
    computedDataAreas() {
      switch(this.$i18n.locale) {
        case 'pt':
          return this.areas[this.form.area] || [];
        case 'en':
          return this.areasEn[this.form.area] || [];
        case 'es':
          return this.areasEs[this.form.area] || [];
        default:
          return this.areas[this.form.area] || [];
      }
    }
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormProjectHighData) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectHighData
        }
      }
    },
    '$i18n.locale'() {
      this.updateAreaOptions();
    }
    /*
    'form.dangerousBiologicals': function () {
      this.controlHumanOrAnimalTissue = !this.form.hasBioSubst
        || (this.form.hasBioSubst && this.form.dangerousBiologicals)
    },
    'form.humanOrAnimalTissue': function () {
      this.controlDangerousBiologicals = !this.form.hasBioSubst
        || (this.form.hasBioSubst && this.form.humanOrAnimalTissue)
    }
    */
  },

  methods: {
    updateAreaOptions() {
      // Este método será chamado automaticamente pelo watch
      this.form.area = ''; // Reseta o campo selecionado quando a língua muda, se necessário
    },
    async checkForm() {
      this.resetErrors()
      if (!this.form.area) {
        this.errors.push('area')
      }
      for (const key in this.form) {
        if (typeof this.form[key] === 'boolean') {
          continue
        }
        if (!this.form[key]) {
          if (key === 'otherSubArea') {
            if (this.form.sub_areas.includes('others')) {
              this.errors.push(key)
            }
          }
          if (key !== 'otherSubArea') {
            this.errors.push(key)
          }
        }
      }

      if (
        this.form.hasBioSubst == false
        && typeof this.form.hasBioSubst === 'boolean'
      ) {
        const indexDangerous = this.errors.indexOf('dangerousBiologicals')
        if (indexDangerous > -1) {
          this.errors.splice(indexDangerous, 1)
        }
        const indexHuman = this.errors.indexOf('humanOrAnimalTissue')
        if (indexHuman > -1) {
          this.errors.splice(indexHuman, 1)
        }
      }
      if (this.controlHumanOrAnimalTissue) {
        const indexHuman1 = this.errors.indexOf('humanOrAnimalTissue')
        this.errors.splice(indexHuman1, 1)
      }
      if (this.form.sub_areas.includes('others')) {
        this.areas[this.form.area].forEach((item) => {
          this.selected_areas.push(item.value)
        })

        this.form.sub_areas = this.form.sub_areas.filter((item) => {
          if (this.selected_areas.includes(item) && item !== 'others') {
            return item
          }
        })

        this.form.sub_areas.push('others')
        this.form.sub_areas.push(this.form.otherSubArea)
      }
      if (!this.errors.length) {
        await this.temporarySave()
        this.saveForm()
      }
    },
    async temporarySave() {
      this.allDataForm.FormProjectHighData = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.$emit('changeElement', {
          direction: 'up',
          FormProjectHighData: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', {
        direction: 'down'
      })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.biologicalSubst {
  color: #afaeae;
  ::v-deep .v-radio > .v-label {
    font-weight: normal !important;
    font-size: 16px;
    line-height: 18px;
    color: #afaeae;
  }

  :after,
  :before {
    color: #afaeae;
  }
}

.info {
  display: flex;
}

.info__icon {
  align-self: bottom;
  margin-top: 0px;
  margin-right: 5px;
  height: 17px;
  width: 17px;
  color: #303030;
}
.text {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
}
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }

    .form__title__complement {
      font-weight: 400;
      margin-left: 11px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__contact__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $primary;
    color: $primary;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__add__contact__button__container {
    display: flex;
    justify-content: center;
  }

  &__add__contact__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }

  .button {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
