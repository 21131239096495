<template>
  <div class="container-app">
    <div class="wrapper">
      <img src="@/assets/padlock.png" alt="Redefinição de senha" class="padlockImage"/>
      <paragraph class="paragraph mb-7">
        {{ $t('enterUserEmailToPasswordRecover') }}
      </paragraph>

      <input-default
        :class="[{warning: checkErrorIfContains('email')}, 'mb-7']"
        :label="$t('email')"
        id="email"
        :placeholder="$t('enterRecoverEmail')"
        type="text"
        v-model="form.email"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('confirmEmail')}, 'mb-7']"
        :label="$t('confirmYourEmail')"
        id="confirmEmail"
        :placeholder="$t('confirmYourEmail')"
        type="text"
        v-model="form.confirmEmail"
        @input="resetErrors"
      />
      <div class="flex">
        <button-default :onClick="checkForm" class="button">{{$t('send')}}</button-default>
      </div>

      <feedback-warning
        v-if="errors.length"
        :text="textWarning"
        :onClick="resetErrors"
        color="#ff9800"
      />
      <modal-default
        :title="$t('emailSuccessfullySent')"
        :modal="modal"
        @closeModal="() => redirectToPage('/login')"
      >
        <div class="modal__body">
          <div class="icon">
            <img src="@/assets/email-send.png" :alt="$t('passwordRecoveryEmailSent')" />
          </div>
          <div class="text">
            <p slot="text">
              {{ $t('passwordRecoveryEmailSuccessfullySentCheckEmailChangePassword') }}
            </p>
          </div>
        </div>
    </modal-default>
    </div>
  </div>
</template>

<script>
import {
  Paragraph, InputDefault, ButtonDefault, TextWarning, FeedbackWarning
} from '@/components/atoms'
import { ModalDefault } from '@/components/organisms/Modals'

export default {
  name: 'ResetPassword',
  components: {
    Paragraph,
    InputDefault,
    ButtonDefault,
    TextWarning,
    FeedbackWarning,
    ModalDefault
  },
  data() {
    return {
      form: {
        email: '',
        confirmEmail: ''
      },
      errors: [],
      textWarning: '',
      modal: false
    }
  },
  methods: {
    resetErrors() {
      this.errors = []
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    redirectToPage(url) {
      this.$router.push(url)
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    validateEmail(email) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    validateConfirmation(email, confirmEmail) {
      if (!(email === confirmEmail)) {
        this.errors.push('email')
        this.errors.push('confirmEmail')
        return false
      }
      return true
    },
    async checkForm() {
      for (const key in this.form) {
        if (!this.form[key]) this.errors.push(key)
      }
      if (this.errors.length) {
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
        return false
      }

      if (!this.validateEmail(this.form.email)) {
        this.textWarning = 'O email inserido não é válido.'
        return false
      }
      if (!this.validateConfirmation(this.form.email, this.form.confirmEmail)) {
        this.textWarning = 'Os emails inseridos não batem.'
        return false
      }
      await this.saveForm()
    },
    async saveForm() {
      try {
        await this.$http.patch('/user/forgot-password', {
          email: this.form.email
        })
        this.toggleModal('modal')
      } catch (error) {
        console.error(error.message)
        if (error.response.data.message === 'This user not found') {
          this.textWarning = 'Email não cadastrado'
          this.errors.push('email')
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
$imgUrl: "../../assets/background-login.png";

.container-app {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
  url($imgUrl);
  background-size: contain;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;

  .wrapper {
    background-color: #fff;
    opacity: 0.9;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 649px;
    padding: 34.5px 89.55px;
    text-align: center;

    .paragraph {
      width: 404px;
      margin: 25px 0px 15px 0px;
      text-align: left;
      color: $primary
    }
	.paragraphRed {
	  color: red;
	  margin: 25px 0px 15px 0px;
	  text-align: left;
	}
    .padlockImage {
      width: 368px;
      height: 274px;
    }
    .modal{
      &__body{
        text-align: left;
      }
    }
    .flex {
      display: flex;
      justify-content: center;
    }
    .warning {
      color: $attention;
      border-bottom-color: $attention;
    }
  }
}
</style>
