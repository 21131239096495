<template>
  <div>
    <h1 class="title-payment">
      {{ this.$t("registrationFeeAndFood") }}
    </h1>
    <br/>
    <div v-if="schoolType.value !== 'foreign'">
      <h4 v-if="this.$t('nationalFormPaymentInfoL1')">{{ this.$t("nationalFormPaymentInfoL1") }}</h4>
      <h4 v-if="this.$t('nationalFormPaymentInfoL2')">{{ this.$t("nationalFormPaymentInfoL2") }}</h4>
      <h4 v-if="this.$t('nationalFormPaymentInfoL3')">{{ this.$t("nationalFormPaymentInfoL3") }}</h4>
      <h4 v-if="this.$t('nationalFormPaymentInfoL4')">{{ this.$t("nationalFormPaymentInfoL4") }}</h4>
    </div>
    <div v-if="schoolType.value === 'foreign'">
      <h4 v-if="this.$t('foreignFormPaymentInfoL1')">{{ this.$t("foreignFormPaymentInfoL1") }}</h4>
      <h4 v-if="this.$t('foreignFormPaymentInfoL2')">{{ this.$t("foreignFormPaymentInfoL2") }}</h4>
      <h4 v-if="this.$t('foreignFormPaymentInfoL3')">{{ this.$t("foreignFormPaymentInfoL3") }}</h4>
      <h4 v-if="this.$t('foreignFormPaymentInfoL4')">{{ this.$t("foreignFormPaymentInfoL4") }}</h4>
    </div>

    <div class="container-content">
      <h2 class="title-project">
        {{ this.$t("projectTitle") }}: {{ titleProject }}
      </h2>
      <div class="select-type">
        <h4>{{this.$t('schoolType')}}</h4>
        <input
          id="schoolTypeSelection"
          class="input-school"
          :value="schoolType.text"
          type="text"
          disabled
        />

      </div>
      <div class="container-header">
        <p class="size-name">{{ this.$t("enrollment") }}</p>
        <p class="size-rate">{{ this.$t("value") }}</p>
        <p class="size-meal">{{ this.$t("food") }}</p>
        <p class="size-final">{{ this.$t("finalValue") }}</p>
      </div>

      <p class="subtitle">{{ this.$t("students") }}</p>
      <div
        class="container-info"
        v-for="(item, index) in studentsList"
        :key="'student' + index"
      >
        <div class="size-name">
          <label :for="`checkboxStudent${index}`">
            <input
              :id="`checkboxStudent${index}`"
              v-model="item.willPay"
              type="checkbox"
            />
            {{ item.fullname }}
          </label>
        </div>
        <div class="size-rate">
          <p v-if="item.willPay" class="color-text">{{coinSimbol}}{{ valueRate }},00</p>
          <p v-else class="color-text">{{coinSimbol}}0,00</p>
        </div>
        <div class="size-meal">
          <input-select
            id="filterMealOptions"
            :class="[{ warning: checkErrorIfContains('studentMeal') }, 'mb-4']"
            :items="filterMealOptions"
            v-model="item.meal"
            :value="item.meal"
            placeholder="selecione quantidade de refeições"
            @input="
              () =>
                calculateFinalValue(
                  studentsList[index].meal,
                  index,
                  studentsList[index].role
                )
            "
            :disabled="!item.willPay"
          />
        </div>
        <div>
          <p v-if="item.willPay" class="color-text size-final">
            {{coinSimbol}}{{ studentsList[index].finalValue }},00
          </p>
          <p v-else class="color-text size-final">{{coinSimbol}}0,00</p>
        </div>
      </div>
      <p class="subtitle advisors">{{ this.$t("advisor") }}</p>
      <div v-for="(item, index) in nameAdvisor" :key="'advisor' + index">
        <div class="size-name advisors">
          <label :for="`checkboxAdvisor${index}`">
            <input
              :id="`checkboxAdvisor${index}`"
              v-model="item.willPay"
              type="checkbox"
              @change="check(item, index)"
            />
            {{ item.fullname }}
          </label>
        </div>
        <div class="container-info">
          <div class="size-name">
            <input-select
              id="filterMealOptions"
              :class="[
                { warning: checkErrorIfContains('isParticipantAdvisor') },
                'mb-4',
              ]"
              :items="advisorOptions"
              v-model="item.isParticipant"
              :value="item.isParticipant"
              placeholder="selecione quantidade de refeições"
              @input="() => checkWillPay(item.isParticipant, index, item.role)"
            />
          </div>
          <div class="size-rate">
            <p v-if="item.willPay" class="color-text">{{coinSimbol}}{{ valueRate }},00</p>
            <p v-else class="color-text">{{coinSimbol}}0,00</p>
          </div>
          <div class="size-meal">
            <input-select
              id="filterMealOptions"
              :class="[
                { warning: checkErrorIfContains('advisorMeal') },
                'mb-4',
              ]"
              :items="filterMealOptions"
              v-model="nameAdvisor[index].meal"
              :value="nameAdvisor[index].meal"
              placeholder="selecione quantidade de refeições"
              @input="
                () =>
                  calculateFinalValue(
                    nameAdvisor[index].meal,
                    index,
                    nameAdvisor[index].role
                  )
              "
              :disabled="!item.willPay || item.isParticipant !== 'yes'"
            />
          </div>
          <div>
            <p v-if="item.willPay" class="color-text size-final">
              {{coinSimbol}}{{ nameAdvisor[index].finalValue }},00
            </p>
            <p v-else class="color-text size-final">{{coinSimbol}}0,00</p>
          </div>
        </div>
      </div>
      <div v-if="coadvisorList.length">
        <p class="subtitle advisors">{{ this.$t("coAdvisor") }}</p>
        <div v-for="(item, index) in coadvisorList" :key="'coadvisor' + index">
          <div class="size-name">
            <label :for="`checkboxCoadvisor${index}`">
              <input
                :id="`checkboxCoadvisor${index}`"
                v-model="item.willPay"
                type="checkbox"
                @input="check"
                @change="check(item, index)"
              />
              {{ item.fullname }}
            </label>
          </div>
          <div class="container-info">
            <div class="size-name">
              <input-select
                id="EditionSelect"
                :class="[
                  { warning: checkErrorIfContains('isParticipantCoadvisor') },
                  'mb-4',
                ]"
                :items="advisorOptions"
                v-model="item.isParticipant"
                :value="item.isParticipant"
                placeholder="selecione quantidade de refeições"
                @input="
                  () => checkWillPay(item.isParticipant, index, item.role)
                "
              />
            </div>
            <div class="size-rate">
              <p v-if="item.willPay" class="color-text">{{coinSimbol}}{{ valueRate }},00</p>
              <p v-else class="color-text">{{coinSimbol}}0,00</p>
            </div>
            <div class="size-meal">
              <input-select
                id="EditionSelect"
                :class="[
                  { warning: checkErrorIfContains('coadvisorMeal') },
                  'mb-4',
                ]"
                :items="filterMealOptions"
                v-model="item.meal"
                :value="item.meal"
                placeholder="selecione quantidade de refeições"
                @input="() => calculateFinalValue(item.meal, index, item.role)"
                :disabled="!item.willPay || item.isParticipant !== 'yes'"
              />
            </div>
            <div>
              <p v-if="item.willPay" class="color-text size-final">
                {{coinSimbol}}{{ coadvisorList[index].finalValue }},00
              </p>
              <p v-else class="color-text size-final">{{coinSimbol}}0,00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="total-payment">
      <p><strong>TOTAL</strong></p>
      <p class="color-text size-final">{{coinSimbol}}{{ total }},00</p>
    </div>
    <p class="title-type-payment">
      {{ this.$t("infoAboutTypePayment") }}
    </p>
    <div class="obs">
      <text-info :text="this.$t('approvalPayment')" />
    </div>
    <div :class="schoolType.value !== 'foreign' ? 'type-payment' : 'type-payment2'">
      <div
        class="container-payment"
        v-for="(option, index) in paymentOptions"
        :key="'payment' + index"
      >
        <div
          v-if="schoolType.value === 'foreign' && index !== 1"
          class="custom-radio"
        >
          <input
            type="radio"
            :id="option.value"
            :value="option.value"
            v-model="typePayment"
          />
          <label v-if="index !== 0" :for="option.value">{{
            $t(option.label)
          }}</label>
          <label v-else :for="option.value" class="mp">
            <img
              src="@/assets/mercado-pago-logo1.png"
              alt="logo-mercado-pago"
            />
          </label>
        </div>
        <div v-else-if="schoolType.value !== 'foreign'" class="custom-radio">
          <input
            type="radio"
            :id="option.value"
            :value="option.value"
            v-model="typePayment"
          />
          <label v-if="index !== 0" :for="option.value">{{
            $t(option.label)
          }}</label>
          <label v-else :for="option.value" class="mp">
            <img
              src="@/assets/mercado-pago-logo1.png"
              alt="logo-mercado-pago"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="button-save">
      <button-default :onClick="back" class="button white">{{
        $t("back")
      }}</button-default>
      <button-default :onClick="checkForm">{{
        $t("saveAndAdvance")
      }}</button-default>
    </div>

    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>
<script>
import jwt_decode from 'jwt-decode'
import {
  InputSelect,
  RadioGroup,
  ButtonDefault,
  TitleDefault,
  TextInfo,
  FeedbackWarning
} from '@/components/atoms'
import InputCheckbox from '../../../atoms/Inputs/InputCheckboxTitle'
import InputCheckboxProject from '../../../atoms/Inputs/InputCheckboxProject'

export default {
  name: 'FormPayment',
  components: {
    InputSelect,
    RadioGroup,
    ButtonDefault,
    TitleDefault,
    InputCheckbox,
    InputCheckboxProject,
    TextInfo,
    FeedbackWarning
  },
  data() {
    return {
      userId: '',
      levelEducation: '',
      titleProject: '',
      checked: false,
      valueRate: 0,
      finalValue: 0,
      mealOption: 0,
      optionAdvisor: '',
      schoolType: null,
      studentsList: [],
      nameAdvisor: [],
      feesList: [],
      coadvisorList: [],
      dataForm: [],
      typePayment: '',
      isChange: false,
      value: undefined,
      paymentOptions: [
        { label: 'Mercado Pago', value: 'mercado pago' },
        {
          label: this.$t('commitNote'),
          value: 'nota empenho'
        },
        { label: this.$t('payOnAccreditation'), value: 'credenciamento' }
      ],
      schoolTypeOptions: [
        { text: this.$t('public'), value: 'public' },
        { text: this.$t('private'), value: 'private' },
        { text: this.$t('foreign'), value: 'foreign' }
      ],
      // Alterações chamado 463
      filterMealOptions: [
        { text: this.$t('noMeal'), value: 0 },
        { text: this.$t('oneMeals'), value: 1 },
        { text: this.$t('twoMeals'), value: 2 },
        { text: this.$t('threeMeals'), value: 3 },
        { text: this.$t('fourMeals'), value: 4 },
        { text: this.$t('fiveMeals'), value: 5 },
        { text: this.$t('sixMeals'), value: 6 },
        { text: this.$t('sevenMeals'), value: 7 }
      ],
      advisorOptions: [
        { text: this.$t('participateInThisProject'), value: 'yes' },
        { text: this.$t('alreadyParticipateInAnotherProject'), value: 'otherProject' },
        { text: this.$t('willNotParticipateInMostratec'), value: 'no' }
      ],

      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
      // coinSimbol: ''
    }
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  created() {
    this.getData()
  },
  watch: {
    allDataForm() {
      this.getData()
    }
  },
  methods: {
    getUser() {
      this.userId = jwt_decode(localStorage.getItem('token')).uid
    },
    activate() {
      this.getData()
    },
    getSchoolType() {
      if (this.allDataForm.FormSelectionSchool.country !== 'Brazil') {
        this.schoolType = this.schoolTypeOptions[2]
      } else if (this.allDataForm.FormSelectionSchool.category === 'public') {
        this.schoolType = this.schoolTypeOptions[0]
      } else if (this.allDataForm.FormSelectionSchool.category === 'private') {
        this.schoolType = this.schoolTypeOptions[1]
      }

      this.calculateFee(this.schoolType.value)
    },
    async getData() {
      this.getUser()
      const { data: response } = await this.$http.get(`/temp/${ this.userId }`)
      this.dataForm = response.temporary_data
      // console.log('this.dataForm ', this.dataForm)
      // console.log('this.dataForm.FormLevelOfEducation.level ', this.dataForm.FormLevelOfEducation.level)
      if (this.dataForm?.FormPayment) {
        await this.getFees()
        // console.log('Entrou aqui 000')
        this.levelEducation = this.dataForm.FormLevelOfEducation.level
        this.titleProject = this.levelEducation !== 'high'
          ? this.dataForm.FormProjectData.projectName
              ?? this.dataForm.FormProjectChildishData.projectName
          : this.dataForm.FormProjectHighData.projectTitle
        this.studentsList = this.dataForm.FormPayment.student
        this.nameAdvisor = this.dataForm.FormPayment.advisor
        this.coadvisorList = this.dataForm.FormPayment.coadvisors
        this.typePayment = this.dataForm.FormPayment.typePayment
        this.schoolType = this.dataForm.FormPayment.schoolType
        this.calculateFee(this.schoolType.value)

        const dataFormStudents = this.levelEducation !== 'high'
          ? this.allDataForm.FormProjectStudents.infos
              ?? this.allDataForm.FormProjectChildishStudents.infos
          : this.allDataForm.FormProjectHighStudents.infos

        const dataFormAdvisors = this.levelEducation !== 'high'
          ? this.allDataForm.FormSelectionAboutAdvisors.infos
              ?? this.allDataForm.FormProjectChildishAdvisors.infos
          : this.allDataForm.FormProjectHighAdvisors.infos

        const onlyStudentsActive = dataFormStudents.filter(
          (item) => item.active
        )
        const onlyAdvisorsActive = dataFormAdvisors.filter(
          (item) => item.active
        )

        this.changeAdvisorsAndStudents(onlyStudentsActive, onlyAdvisorsActive)

        dataFormStudents?.forEach((element, index) => {
          if (element.active) {
            this.studentsList[index].fullname = element.fullname || element.full_name
          }
        })

        onlyAdvisorsActive?.forEach((element, index) => {
          if (index === 0) {
            this.nameAdvisor[index].fullname = element.fullname || element.full_name
          } else if (element.active) {
            this.coadvisorList[index - 1].fullname = element.fullname || element.full_name
          }
        })
        return ''
      }
      await this.getFees()
      this.getSchoolType()
      // console.log('this.allDataForm.FormLevelOfEducation.level X ', this.allDataForm.FormLevelOfEducation.level)
      this.levelEducation = this.allDataForm.FormLevelOfEducation.level
      // console.log('this.levelEducation X ',this.levelEducation)

      this.titleProject = this.levelEducation !== 'high'
        ? this.allDataForm.FormProjectData.projectName
            ?? this.allDataForm.FormProjectChildishData.projectName
        : this.allDataForm.FormProjectHighData.projectTitle
      const dataFormStudents = this.levelEducation !== 'high'
        ? this.allDataForm.FormProjectStudents.infos
            ?? this.allDataForm.FormProjectChildishStudents.infos
        : this.allDataForm.FormProjectHighStudents.infos

      const dataFormAdvisors = this.levelEducation !== 'high'
        ? this.allDataForm.FormSelectionAboutAdvisors.infos
            ?? this.allDataForm.FormProjectChildishAdvisors.infos
        : this.allDataForm.FormProjectHighAdvisors.infos

      this.studentsList = dataFormStudents
        ?.filter((element) => element.active)
        .map((element) => ({
          fullname: element.fullname || element.full_name,
          rate: 0,
          meal: null,
          mealValue: 0,
          finalValue: 0,
          role: 'student',
          willPay: false
        })) ?? []

      const nameAdvisor = []
      const coadvisorList = []

      // console.log('element 1', this.studentsList);

      dataFormAdvisors?.forEach((element, index) => {
        if (index === 0) {
          nameAdvisor.push({
            fullname: element.fullname || element.full_name,
            rate: 0,
            meal: null,
            mealValue: 0,
            finalValue: 0,
            role: 'advisor',
            isParticipant: '',
            willPay: false
          })
        } else if (element.active) {
          coadvisorList.push({
            fullname: element.fullname || element.full_name,
            rate: 0,
            meal: null,
            mealValue: 0,
            finalValue: 0,
            role: 'coadvisor',
            isParticipant: '',
            willPay: false
          })
        }
      })

      this.nameAdvisor = nameAdvisor
      this.coadvisorList = coadvisorList
      return ''
    },

    changeAdvisorsAndStudents(onlyStudentsActive, onlyAdvisorsActive) {
      if (this.coadvisorList.length < onlyAdvisorsActive.length - 1) {
        const arrFullName = []
        const arrFullName2 = []

        onlyAdvisorsActive.forEach((item, index) => {
          if (index !== 0) {
            arrFullName.push(item.fullname || item.full_name)
          }
        })

        if (this.coadvisorList.length > 0) {
          onlyAdvisorsActive?.forEach((element, index) => {
            if (index === 1) {
              this.coadvisorList[index - 1].fullname = element.fullname || element.full_name
            }
          })
          arrFullName2.push(this.coadvisorList[0].fullname)

          const noIncludesName = arrFullName.filter(
            (item) => !arrFullName2.includes(item)
          )

          this.coadvisorList.push({
            fullname: noIncludesName[0],
            rate: 0,
            meal: null,
            mealValue: 0,
            finalValue: 0,
            role: 'coadvisor',
            isParticipant: '',
            willPay: false
          })
          return false
        }

        arrFullName.forEach((fullname) => {
          this.coadvisorList.push({
            fullname,
            rate: 0,
            meal: null,
            mealValue: 0,
            finalValue: 0,
            role: 'coadvisor',
            isParticipant: '',
            willPay: false
          })
        })
      }

      if (this.coadvisorList.length > onlyAdvisorsActive.length - 1) {
        const arrFullName = []
        const arrFullName2 = []

        onlyAdvisorsActive.forEach((item, index) => {
          if (index !== 0) {
            arrFullName.push(item.fullname || item.full_name)
          }
        })

        this.coadvisorList.forEach((item) => {
          arrFullName2.push(item.fullname || item.full_name)
        })

        const noIncludesName = []
        arrFullName2.forEach((item, index) => {
          if (!arrFullName.includes(item)) {
            noIncludesName.push(index)
          }
        })

        noIncludesName.forEach((index) => {
          this.coadvisorList.splice(index, 1)
        })
      }

      if (this.studentsList.length < onlyStudentsActive.length) {
        const arrFullName = []
        const arrFullName2 = []
        onlyStudentsActive.forEach((item) => {
          arrFullName.push(item.fullname || item.full_name)
        })
        this.studentsList.forEach((item) => {
          arrFullName2.push(item.fullname || item.full_name)
        })

        const noIncludesName = arrFullName.filter(
          (item) => !arrFullName2.includes(item)
        )

        noIncludesName.forEach((fullname) => {
          this.studentsList.push({
            fullname,
            rate: 0,
            meal: null,
            mealValue: 0,
            finalValue: 0,
            role: 'student',
            isParticipant: '',
            willPay: false
          })
        })
      }

      if (this.studentsList.length > onlyStudentsActive.length) {
        const arrFullName = []
        const arrFullName2 = []

        onlyStudentsActive.forEach((item) => {
          arrFullName.push(item.fullname || item.full_name)
        })
        this.studentsList.forEach((item) => {
          arrFullName2.push(item.fullname || item.full_name)
        })

        const noIncludesName = []
        arrFullName2.forEach((item, index) => {
          if (!arrFullName.includes(item)) {
            noIncludesName.push(index)
          }
        })
        noIncludesName.forEach((index) => {
          this.studentsList.splice(index, 1)
        })
      }
    },
    calculateFee(schoolType) {
      // console.log('** calculateFee(schoolType) **')
      // console.log('** calculateFee(schoolType) this.feesList[0] ', this.feesList[0])
      // console.log('** calculateFee(schoolType) schoolType ', schoolType)

      this.levelEducation = this.dataForm.FormLevelOfEducation.level

      // console.log('** calculateFee(schoolType) this.levelEducation ', this.levelEducation)
      // console.log('** calculateFee(schoolType) this ', this)
      // console.log('this ', this)

      /*
        //national_public_middle,
        //national_public_high,
        //national_private_middle,
        //national_private_high,
        //foreign_middle,
        //foreign_high
      */

      const {
        national_child_education,
        national_public_child,
        national_public_middle,
        national_public_middle_cat_1,
        national_public_middle_cat_2,
        national_public_middle_cat_3,
        national_public_middle_cat_eja,
        national_public_high,
        national_private_child,
        national_private_middle,
        national_private_middle_cat_1,
        national_private_middle_cat_2,
        national_private_middle_cat_3,
        national_private_middle_cat_eja,
        national_private_high,
        foreign_child,
        foreign_middle,
        foreign_middle_cat_1,
        foreign_middle_cat_2,
        foreign_middle_cat_3,
        foreign_middle_cat_eja,
        foreign_high
      } = this.feesList[0]

      // grade
      if (this.levelEducation === 'grade') {
        // console.log('entrou em if (this.levelEducation === grade)')
        // this.valueRate = national_child_education
        const valueFeeByTypeSchool = {
          public: national_public_child,
          private: national_private_child,
          foreign: foreign_child
        }

        this.valueRate = valueFeeByTypeSchool[schoolType]

        this.studentsList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.coadvisorList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.nameAdvisor.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
      }

      // middle
      if (this.levelEducation === 'middle') {
        // console.log('entrou em if (this.levelEducation === middle)')
        const valueFeeByTypeSchool = {
          public: national_public_middle,
          private: national_private_middle,
          foreign: foreign_middle
        }

        this.valueRate = valueFeeByTypeSchool[schoolType]

        this.studentsList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.coadvisorList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.nameAdvisor.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
      }

      // middle_cat_1
      if (this.levelEducation === 'middle_cat_1') {
        // console.log('entrou em if (this.levelEducation === middle_cat_1)')
        const valueFeeByTypeSchool = {
          public: national_public_middle_cat_1,
          private: national_private_middle_cat_1,
          foreign: foreign_middle_cat_1
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]

        this.studentsList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.coadvisorList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.nameAdvisor.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
      }

      // middle_cat_2
      if (this.levelEducation === 'middle_cat_2') {
        // console.log('entrou em if (this.levelEducation === middle_cat_2)')
        const valueFeeByTypeSchool = {
          public: national_public_middle_cat_2,
          private: national_private_middle_cat_2,
          foreign: foreign_middle_cat_2
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]

        this.studentsList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.coadvisorList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.nameAdvisor.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
      }

      // middle_cat_3
      if (this.levelEducation === 'middle_cat_3') {
        // console.log('entrou em if (this.levelEducation === middle_cat_3)')
        const valueFeeByTypeSchool = {
          public: national_public_middle_cat_3,
          private: national_private_middle_cat_3,
          foreign: foreign_middle_cat_3
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]

        this.studentsList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.coadvisorList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.nameAdvisor.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
      }

      // middle_cat_eja
      if (this.levelEducation === 'middle_cat_eja') {
        // console.log('entrou em if (this.levelEducation === middle_cat_eja)')
        const valueFeeByTypeSchool = {
          public: national_public_middle_cat_eja,
          private: national_private_middle_cat_eja,
          foreign: foreign_middle_cat_eja
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]

        this.studentsList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.coadvisorList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.nameAdvisor.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
      }

      // high
      if (this.levelEducation === 'high') {
        // console.log('entrou em if (this.levelEducation === high)')
        const valueFeeByTypeSchool = {
          public: national_public_high,
          private: national_private_high,
          foreign: foreign_high
        }

        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.coadvisorList.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
        this.nameAdvisor.forEach((elem) => {
          elem.rate = this.valueRate
          // if (elem.meal !== 0) {
          if (elem.meal >= 0) {
            elem.finalValue = this.calculateMeal(schoolType, elem.meal)
          }
        })
      }
    },
    calculateFinalValue(mealOption, index, role) {
      const { national_meal, foreign_meal } = this.feesList[0]
      // console.log('calculateFinalValue')
      // console.log('national_meal ', national_meal)
      // console.log('foreign_meal ', foreign_meal)
      // console.log('this.feesList[0] ',this.feesList[0])
      /*
      if (!this.valueRate) {
        alert(this.valueRate)
        this.mealOption = 0
        return false
      }
      */
      // alert(this.valueRate)

      // console.log('** calculateFinalValue this ', this)

      if (mealOption === 0) {
        this.mealOption = 0
        this.finalValue = this.valueRate
      }
      // Alteração chamado 463
      // if ((mealOption >= 1) && (mealOption <= 7)) {
      if ((mealOption >= 0) && (mealOption <= 7)) {
        if (this.schoolType.value !== 'foreign') {
          this.mealOption = national_meal * mealOption
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreign_meal * mealOption
          this.finalValue = this.valueRate + this.mealOption
        }
      }

      // comentar isto
      // alert('2 mealOption ' + mealOption + ', SchoolType ' + this.schoolType.value + ', national_meal ' + national_meal+ ', foreign_meal ' + foreign_meal)

      /*
      if (mealOption === 6) {
        if (this.schoolType.value !== 'foreign') {
          this.mealOption = national_meal * 6
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreign_meal * 6
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      if (mealOption === 7) {
        if (this.schoolType.value !== 'foreign') {
          this.mealOption = national_meal * 7
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreign_meal * 7
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      */

      if (role === 'student') {
        this.studentsList[index].finalValue = this.finalValue
        this.studentsList[index].mealValue = this.mealOption
      }
      if (role === 'advisor') {
        this.nameAdvisor[index].finalValue = this.finalValue
        this.nameAdvisor[index].mealValue = this.mealOption
      }
      if (role === 'coadvisor') {
        this.coadvisorList[index].finalValue = this.finalValue
        this.coadvisorList[index].mealValue = this.mealOption
      }

      return ''
    },

    calculateMeal(schoolType, meal) {
      // console.log('calculateMeal')
      // console.log(this.schoolType.value)
      const { national_meal, foreign_meal } = this.feesList[0]
      let mealValue = 0
      if (schoolType !== 'foreign') {
        mealValue = this.valueRate + meal * national_meal
      } else {
        mealValue = this.valueRate + meal * foreign_meal
      }
      return mealValue
    },
    checkWillPay(option, index, role) {
      if (role === 'advisor') {
        this.nameAdvisor[index].willPay = option === 'yes'
      }
      if (role === 'coadvisor') {
        this.coadvisorList[index].willPay = option === 'yes'
      }
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    },
    async getFees() {
      try {
        const response = await this.$http.get('/fees')
        this.feesList = response.data
      } catch (error) {
        console.log('erro:', error)
      }
    },
    async checkForm() {
      this.resetErrors()

      if (!this.schoolType) {
        this.errors.push('schoolType')
        this.textWarning = this.$t('chooseSchoolType')
        return false
      }

      const studentWillPay = this.studentsList.some(
        (student) => student.willPay
      )
      if (!studentWillPay) {
        this.errors.push('willPay')
        this.textWarning = this.$t('studentMustBeSelected')
        return false
      }

      const allParticipants = [
        ...this.studentsList,
        ...this.nameAdvisor,
        ...this.coadvisorList
      ]

      if (allParticipants) {
        allParticipants.forEach((participant) => {
          if (participant.willPay && participant.meal === null) {
            if (participant.role === 'student') {
              this.errors.push('studentMeal')
            }
            if (participant.role === 'advisor') {
              this.errors.push('advisorMeal')
            }
            if (participant.role === 'coadvisor') {
              this.errors.push('coadvisorMeal')
            }
          }

          if (participant.role === 'advisor') {
            if (participant.willPay && participant.isParticipant !== 'yes') {
              this.errors.push('isParticipantAdvisor')
            }
          }

          if (participant.role === 'coadvisor') {
            if (participant.willPay && participant.isParticipant !== 'yes') {
              this.errors.push('isParticipantCoadvisor')
            }
          }
        })

        if (
          this.errors.includes('studentMeal')
          || this.errors.includes('advisorMeal')
          || this.errors.includes('coadvisorMeal')
        ) {
          this.textWarning = this.$t('selectNumberOfMeals')
          return false
        }

        if (
          this.errors.includes('isParticipantAdvisor')
          || this.errors.includes('isParticipantCoadvisor')
        ) {
          this.textWarning = this.$t('participationNotSelected')
          return false
        }
      }

      if (!this.typePayment) {
        this.errors.push('typePayment')
        this.textWarning = this.$t('selectPaymentMethod')
        return false
      }

      await this.temporarySave()
      this.saveForm()
    },
    async temporarySave() {
      this.allDataForm.FormPayment = {
        student: this.studentsList,
        advisor: this.nameAdvisor,
        coadvisors: this.coadvisorList,
        typePayment: this.typePayment,
        schoolType: this.schoolType,
        valueRate: this.valueRate
      }

      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
        return response
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.$emit('changeElement', {
          direction: 'up',
          FormPayment: {
            student: this.studentsList,
            advisor: this.nameAdvisor,
            coadvisors: this.coadvisorList,
            typePayment: this.typePayment,
            schoolType: this.schoolType,
            valueRate: this.valueRate
          }
        })
      } catch (error) {
        console.log(error)
      }
    },

    check(item, index) {
      if (item.willPay) {
        if (item.role === 'advisor') {
          this.nameAdvisor[index].isParticipant = 'yes'
        }
        if (item.role === 'coadvisor') {
          this.coadvisorList[index].isParticipant = 'yes'
        }
      }

      if (!item.willPay) {
        if (item.role === 'advisor') {
          this.nameAdvisor[index].isParticipant = ''
        }
        if (item.role === 'coadvisor') {
          this.coadvisorList[index].isParticipant = ''
        }
      }
    },

    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },

    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  },
  computed: {
    total() {
      const participants = [
        ...this.studentsList,
        ...this.nameAdvisor,
        ...this.coadvisorList
      ]

      const total = participants.reduce(
        (accumulator, { finalValue, willPay }) => {
          if (willPay) {
            return accumulator + finalValue
          }

          return accumulator
        },
        0
      )
      return total
    },

    coinSimbol() {
      const coinSimbol = this.schoolType.value === 'foreign' ? 'US$' : 'R$'
      return coinSimbol
    }
  }
}
</script>
<style lang="scss" scoped>
.title-payment {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 160%;

  color: #26768d;
}

.title-project {
  margin: 20px 0 50px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;

  color: #26768d;
}

.container-content {
  padding: 30px 15px;
}

.select-type {
  max-width: 236px;
  margin-bottom: 30px;

  .input-school {
  border: 1px solid #a1a1a1;
  max-width: 120px;
  width: 100%;
  padding: 5px 0px 5px 14px;
  border-radius: 8px;
  color: #a1a1a1;
}
}

.container-header {
  display: flex;
  justify-content: space-between;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;

    color: #2d2d2d;
  }
}

.subtitle {
  margin-top: 30px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2d2d2d;
}

.container-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label {
  display: flex;
  align-items: flex-start;
  font-size: 16px !important;
  cursor: pointer;
}

input[type="checkbox"] {
  margin-top: 3px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 4px;
  background: white;
}

input[type="checkbox"]:checked:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #26768d;
  background-color: #26768d;
  border-radius: 3px;
}

input[type="checkbox"]:checked:after {
  content: "";
  width: 6px;
  height: 12px;
  border: 2px solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.color-text {
  color: #135273;
  font-weight: bold;
}

.size-name {
  width: 350px;
}

.size-rate {
  width: 100px;
}

.size-meal {
  width: 220px;
}

.size-final {
  width: 120px;
}

.advisors {
  margin-bottom: 10px;
}

.coadvisors {
  margin-bottom: 20px;
}

.type-payment {
  display: flex;
  padding-right: 180px;
  justify-content: space-between;
}

.type-payment2 {
  display: flex;
  padding-right: 180px;
  gap: 70px;
}

.title-type-payment {
  margin-top: 40px;
  font-weight: bold;
  font-size: 20px;
}

.container-payment {
  display: flex;
  align-items: center;

  label {
    margin-left: 10px;
  }
}

.total-payment {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  gap: 29px;
}

.button-save {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}

.custom-radio input {
  display: none;
}

.custom-radio input[type="radio"] {
  appearance: none;
  margin-top: 3px;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #636b6f;
}

.custom-radio input + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #636b6f;
  border-radius: 100px;
  background: white;
}

.custom-radio input:checked + label:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #26768d;
  background-color: #26768d;
  box-sizing: border-box;
}

.obs {
  margin-bottom: 20px;
}
</style>
