import { SELECTED_PROJECT } from '../mutation-types'

const state = () => ({
  selectedProject: {}
})

const getters = {
  getSelectedProject: (state) => state.selectedProject
}

const actions = {
  setSelectedProject: (context, project) => {
    context.commit(SELECTED_PROJECT, project)
  }
}

const mutation = {
  [SELECTED_PROJECT](state, project) {
    state.selectedProject = project
  }
}

export default {
  state,
  getters,
  actions,
  mutation,
  namespaced: true
}
