<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('aboutAdvisors')}}</p>
        <p class="form__text">{{$t('informAboutTheAdvisors')}}</p>
        <text-warning :text="$t('considerAllFieldObligatory')"/>
      </div>

      <main class="form__main mt-4">
        <div class="form__item mb-5" v-for="(info, index) in form.infos" :key="index">
          <input-checkbox-title
            :class="[
              { warning: checkErrorIfContains('active' + index) },
              { disable: !form.infos[index].active && index > 0 },
              'mb-7'
            ]"
            :label="index ? $t('coAdvisor') : $t('advisor')"
            v-model="form.infos[index].active"
            id="advisor"
            @change="resetErrors"
          />

          <div v-if="info.active">
            <input-default
              label=fullName
              :class="[{ warning: checkErrorIfContains('fullname' + index) }, 'input__space']"
              placeholder=fullNamePlaceholder
              type="text"
              v-model="form.infos[index].fullname"
              @input="resetErrors"
            />

            <input-masked
              v-if="!form.infos[index].country || form.infos[index].country == 'Brazil'"
              :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
              label=cpf
              id="codigo"
              placeholder="xxx.xxx.xxx-xx"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
              :mask="['###.###.###-##']"
            />

            <input-default
              v-else
              :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
              label="Identification number"
              id="codigo"
              placeholder="Insert your national identification number"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{ warning: checkErrorIfContains('gender' + index) }, 'input__space']"
              label=sex
              :options="genderOptions"
              v-model="form.infos[index].gender"
              @change="resetErrors"
            />

            <input-default
              :class="[{ warning: checkErrorIfContains('phone' + index) }, 'input__space']"
              label=enterInstitutionSchoolPhone
              id="phone"
              placeholder="(00) 00000-0000"
              type="text"
              max="20"
              v-model="form.infos[index].phone"
              @input="resetErrors"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />

            <input-default
              :class="[{ warning: checkErrorIfContains('email' + index) }, 'input__space']"
              label=email
              id="email"
              placeholder=enterTheEmail
              type="text"
              v-model="form.infos[index].email"
              @input="resetErrors"
            />

            <div class="separator">
              <h3>{{$t('address')}}</h3>
            </div>

            <input-select-location
              :class="[{ warning: checkErrorIfContains('country' + index) }, 'input__space']"
              id="country"
              :items="countries"
              v-model="form.infos[index].country"
              label=country
              @input="resetErrors"
              :selected="form.infos[index].country"
            />

            <input-masked
              v-if="form.infos[index].country == 'Brazil'"
              :class="['input__space']"
              required
              label="CEP"
              id="zipCode"
              :placeholder="$t('inputCepPlaceholder')"
              type="text"
              v-model="form.infos[index].zipCode"
              @input="resetErrors"
              :mask="['#####-###']"
            />

            <template v-if="form.infos[index].cepValidate">
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                label=state
                id="state"
                type="text"
                v-model="form.infos[index].state"
                @input="resetErrors"
              />
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                label=city
                id="city"
                type="text"
                v-model="form.infos[index].city"
                @input="resetErrors"
              />
            </template>
            <template v-else>
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                id="class"
                :items="states"
                v-model="form.infos[index].state"
                label=state
                @input="resetErrors"
                :selected="form.infos[index].state"
              />
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                id="class"
                :items="cities"
                v-model="form.infos[index].city"
                label=city
                @input="resetErrors"
                :selected="form.infos[index].city"
              />
            </template>
            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('address' + index) }, 'input__space']"
              label=address
              id="address"
              placeholder=addressExample
              type="text"
              v-model="form.infos[index].address"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil'"
              :class="[{ warning: checkErrorIfContains('neighborhood' + index) }, 'input__space']"
              label=neighborhood
              id="neighborhood"
              placeholder=neighborhoodPlaceholder
              type="text"
              v-model="form.infos[index].neighborhood"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('number' + index) }, 'input__space']"
              label="number"
              id="number"
              placeholder=numberPlaceholder
              type="text"
              v-model="form.infos[index].number"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('complement' + index) }, 'input__space']"
              label=complementOptional
              id="complement"
              placeholder=complementPlaceholder
              type="text"
              v-model="form.infos[index].complement"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
              label="State/Province"
              id="state"
              placeholder=statePlaceholder
              type="text"
              v-model="form.infos[index].state"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
              label="City"
              id="city"
              placeholder="Input your city"
              type="text"
              v-model="form.infos[index].city"
              @input="resetErrors"
            />

            <div class="separator">
              <h3>{{$t('registrationData')}}</h3>
            </div>

            <input-default
              label=graduation
              :class="[{ warning: checkErrorIfContains('formation' + index) }, 'input__space']"
              placeholder=enterTheGraduation
              type="text"
              v-model="form.infos[index].formation"
              @input="resetErrors"
            />

            <input-default
              label=actingDiscipline
              :class="[{ warning: checkErrorIfContains('discipline' + index) }, 'input__space']"
              placeholder=enterTheActingDiscipline
              type="text"
              :max=255
              v-model="form.infos[index].discipline"
              @input="resetErrors"
            />

            <input-select
              :class="[{ warning: checkErrorIfContains('englishLevel' + index) }, 'input__space']"
              id="englishLevel"
              :items="englishOptions"
              v-model="form.infos[index].englishLevel"
              label=levelOfKnowledgeInEnglish
              @input="resetErrors"
              :selected="form.infos[index].englishLevel"
            />

            <RadioGroup
              :class="[{ warning: checkErrorIfContains('scholarship' + index) }, 'mb-2']"
              label=doYouHaveScholarship
              :options="generalOptions"
              v-model="form.infos[index].scholarship"
              @change="resetErrors, checkValueInputOthersProgram(index)"
            />

            <RadioGroup
              v-if="form.infos[index].scholarship === 'yes'"
              :class="[{ warning: checkErrorIfContains('program' + index) }, 'mb-1']"
              label=whichProgram
              :options="programOptions"
              v-model="form.infos[index].program"
              @change="resetErrors"
            />

            <input-default
              v-if="
                form.infos[index].scholarship === 'yes' &&
                form.infos[index].program === 'other'
              "
              :class="[
                { warning: checkErrorIfContains('programOther' + index) },
                'input__space mt-0'
              ]"
              id="city"
              placeholder=enterTheProgramName
              type="text"
              v-model="form.infos[index].programOther"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{ warning: checkErrorIfContains('attendOtherFair' + index) }, 'mb-1']"
              label=haveYouParticipatedAnyScienceFair
              :options="alreadyParticipateOptions"
              v-model="form.infos[index].attendOtherFair"
              @change="resetErrors"
            />

            <input-default
              v-if="form.infos[index].attendOtherFair === 'other'"
              :class="[{ warning: checkErrorIfContains('otherFair' + index) }, 'input__space mt-0']"
              id="city"
              placeholder=enterWhichScienceFair
              type="text"
              v-model="form.infos[index].otherFair"
              @input="resetErrors"
            />

            <div class="levels">
              <p :class="[{ warning: checkErrorIfContains('levelDeficiency' + index) }, 'title']">
                {{$t('doYouHaveAnyKindOfDisability')}}
              </p>
              <label>
                <input
                  @change="resetErrors"
                  value="nao"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="fisica"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                />{{$t('fis')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="intelectual"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                />{{$t('intellectual')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="auditiva"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                />{{$t('auditory')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="visual"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                />{{$t('visual')}}
              </label>
            </div>

            <div class="levels">
              <p
                :class="[
                  { warning: checkErrorIfContains('specialattendance' + index) },
                  'title mt-3'
                ]"
              >
                {{$t('willYouNeedAnySpecializedAssistance')}}
              </p>
              <label>
                <input
                  @change="resetErrors"
                  value="nao"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="acessibilidade"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                />{{$t('architecturalAccessibility')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="libras"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                />{{$t('libraInterpreter')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="ledor"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                >{{$t('reader')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="outro"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                />{{$t('othersMale')}}
              </label>
              <input-default
                v-if="form.infos[index].specialattendance.includes('outro')"
                :class="[{ warning: checkErrorIfContains('attendOtherSpecial' + index) }, 'mt-0']"
                id="city"
                placeholder="enterTheSpecializedService"
                type="text"
                v-model="form.infos[index].attendOtherSpecial"
                @input="resetErrors"
              />
            </div>

            <input-select
              :class="[{ warning: checkErrorIfContains('typeShirt' + index) }, 'input__space']"
              id="typeShirt"
              :items="typeShirtOptions"
              v-model="form.infos[index].typeShirt"
              label=typeOfShirt
              @input="resetErrors"
              :selected="form.infos[index].typeShirt"
            />
            <input-select
              v-if="form.infos[index].typeShirt === 'no' && form.infos[index].typeShirt"
              :class="[{ warning: checkErrorIfContains('sizeShirt' + index) }, 'input__space']"
              id="englishLevel"
              :items="sizeShirtOptions"
              v-model="form.infos[index].sizeShirt"
              label=whatSizeShirt
              @input="resetErrors"
              :selected="form.infos[index].sizeShirt"
            />
          </div>
        </div>
      </main>

      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndAdvance')}}</button-default>
      </div>

      <modal-selection-process
        title=disableCoadvisor
        :onClick="() => closeModalAndYes()"
        @closeModal="() => closeModal()"
        :modal="coadvisorModal"
      >
        <div>
          <p class="form__textModal">{{$t('disableCoadvisorModalP1')}}
            <strong>{{$t('whitoutCoadvisor')}}</strong>{{$t('disableCoadvisorModalP2')}}
            <strong>checkbox.</strong>
          </p>
        </div>
      </modal-selection-process>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  RadioGroup,
  InputCheckboxTitle,
  InputMasked,
  InputSelectLocation,
  InputSelect
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'
import { ModalSelectionProcess } from '@/components/organisms/Modals'

export default {
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  name: 'FormProjectChildishAdvisors',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    RadioGroup,
    InputCheckboxTitle,
    InputMasked,
    InputSelectLocation,
    InputSelect,
    ModalSelectionProcess
  },
  data() {
    return {
      notRequired: ['complement'],
      coadvisorModal: false,
      form: {
        infos: [
          {
            cepValidate: false,
            active: true,
            fullname: '',
            cpf: '',
            gender: '',
            phone: '',
            email: '',
            country: '',
            state: '',
            city: '',
            zipCode: '',
            address: '',
            neighborhood: '',
            number: '',
            complement: '',
            formation: '',
            discipline: '',
            englishLevel: '',
            scholarship: '',
            program: '',
            programOther: '',
            attendOtherFair: '',
            otherFair: '',
            levelDeficiency: [],
            specialattendance: [],
            attendOtherSpecial: '',
            typeShirt: '',
            type: 'orientador',
            sizeShirt: 'no'
          },
          {
            cepValidate: false,
            active: false,
            fullname: '',
            cpf: '',
            gender: '',
            phone: '',
            email: '',
            country: '',
            state: '',
            city: '',
            zipCode: '',
            address: '',
            neighborhood: '',
            number: '',
            complement: '',
            formation: '',
            discipline: '',
            englishLevel: '',
            scholarship: '',
            program: '',
            programOther: '',
            attendOtherFair: '',
            otherFair: '',
            levelDeficiency: [],
            specialattendance: [],
            attendOtherSpecial: '',
            typeShirt: '',
            type: 'coorientador',
            sizeShirt: 'no'
          },
          {
            cepValidate: false,
            active: false,
            fullname: '',
            cpf: '',
            gender: '',
            phone: '',
            email: '',
            country: '',
            state: '',
            city: '',
            zipCode: '',
            address: '',
            neighborhood: '',
            number: '',
            complement: '',
            formation: '',
            discipline: '',
            englishLevel: '',
            scholarship: '',
            program: '',
            programOther: '',
            attendOtherFair: '',
            otherFair: '',
            levelDeficiency: [],
            specialattendance: [],
            attendOtherSpecial: '',
            typeShirt: '',
            type: 'coorientador',
            sizeShirt: 'no'
          }
        ]
      },
      quantityOptions: [
        { label: this.$t('individual'), value: 1 },
        { label: this.$t('pair'), value: 2 },
        { label: this.$t('trio'), value: 3 }
      ],
      alreadyParticipateOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'yes' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: 'yes' },
        { label: this.$t('no'), value: 'no' }
      ],
      genderOptions: [
        { label: this.$t('maleSingle'), value: 'masculino' },
        { label: this.$t('femaleSingle'), value: 'feminino' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: 'avancado' },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' }// ,
        // { text: 'P', value: 'p' },
        // { text: 'M', value: 'm' },
        // { text: 'G', value: 'g' },
        // { text: 'GG', value: 'gg' }
      ],
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      infoAux: []
    }
  },
  computed: {
    zipCodeAdvisor() {
      return this.form.infos[0].zipCode
    },
    zipCodeCoAdvisor1() {
      return this.form.infos[1].zipCode
    },
    zipCodeCoAdvisor2() {
      return this.form.infos[2].zipCode
    },
    coadvisorOption() {
      return this.form.infos[1].active
    },
    cStateSelected() {
      if (this.form.infos[0]) {
        return this.form.infos[0].state
      }
    },
    cStateSelected2() {
      if (this.form.infos[1]) {
        return this.form.infos[1].state
      }
    }
  },
  mounted() {
    if (this.allDataForm.FormProjectChildishAdvisors) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectChildishAdvisors
      }
      this.activeAdvisors()
    }
  },
  watch: {
    zipCodeAdvisor() {
      if (this.form.infos[0].zipCode) {
        this.getAddressInfo(0)
      }
    },
    zipCodeCoAdvisor1() {
      if (this.form.infos[1].zipCode) {
        this.getAddressInfo(1)
      }
    },
    zipCodeCoAdvisor2() {
      if (this.form.infos[2].zipCode) {
        this.getAddressInfo(2)
      }
    },
    coadvisorOption() {
      if (!this.form.infos[1].active) {
        console.log('vai querer desativar mesmo?')
        this.coadvisorModal = true
      }
    },
    allDataForm() {
      if (this.allDataForm.FormProjectChildishAdvisors) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectChildishAdvisors
        }
      }
    },
    cStateSelected() {
      this.executeGetCities(this.form.infos[0].state)
    },
    cStateSelected2() {
      this.executeGetCities(this.form.infos[1].state)
    }
  },
  methods: {
    validCheckoboxGroup(actualData, index) {
      if (actualData.levelDeficiency.includes('nao')) {
        this.form.infos[index].levelDeficiency = ['nao']
      }
      if (actualData.specialattendance.includes('nao')) {
        this.form.infos[index].specialattendance = ['nao']
      }
    },
    executeGetCities(state) {
      this.cities = []
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    activeAdvisors() {
      for (let i = 0; i <= 1; i++) {
        if (this.form.infos[i].email || this.form.infos[i].fullname) {
          this.form.infos[i].active = true
        } else this.form.infos[i].active = false
      }
    },
    checkForm() {
      this.resetErrors()
      const emails = []
      if (!this.form.infos[0].active) {
        this.textWarning = 'É obrigatório informar um orientador.'
        this.errors.push('active0')
        return false
      }
      this.infoAux = this.form.infos
      this.infoAux.map((item, index) => {
        if (item.active) {
          this.notRequired.push('isBetter')
          this.notRequired.push('cepValidate')
          if (item.country !== 'Brazil') {
            this.notRequired.push('neighborhood')
            this.notRequired.push('address')
            this.notRequired.push('street')
            this.notRequired.push('zipCode')
            this.notRequired.push('number')
            this.notRequired.push('complement')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'neighborhood'
                && item !== 'address'
                && item !== 'street'
                && item !== 'zipCode'
                && item !== 'number'
            )
          }
          if (item.country === 'Brazil' && item.cepValidate) {
            this.notRequired.push('zipCode')
          } else if (item.country === 'Brazil' && !item.cepValidate) {
            this.notRequired = this.notRequired.filter((item) => item !== 'zipCode')
          }
          if (!item.isBetter) {
            this.notRequired.push('responsibleName')
            this.notRequired.push('responsibleRelative')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'responsibleName' && item !== 'responsibleRelative'
            )
          }
          if (item.attendOtherFair != 'other') {
            this.notRequired.push('otherFair')
          } else this.notRequired = this.notRequired.filter((item) => item !== 'otherFair')
          if (item.scholarship === 'no') {
            this.notRequired.push('program')
          } else this.notRequired = this.notRequired.filter((item) => item !== 'program')
          /*
          if (item.typeShirt === 'no') {
            this.notRequired.push('typeShirt')
            this.notRequired.push('sizeShirt')
          } else this.notRequired = this.notRequired.filter((item) => item !== 'sizeShirt')
          */
          if (item.levelDeficiency.includes('nao')) {
            this.notRequired.push('attendOtherSpecial')
            this.notRequired.push('specialattendance')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'specialattendance' && item !== 'attendOtherSpecial'
            )
          }
          if (!item.specialattendance.includes('outro')) {
            this.notRequired.push('attendOtherSpecial')
          } else this.notRequired = this.notRequired.filter((item) => item !== 'attendOtherSpecial')
          if (item.program !== 'other') {
            this.notRequired.push('programOther')
          } else this.notRequired = this.notRequired.filter((item) => item !== 'programOther')
          index === 0 ? (item.type = 'orientador') : 'coorientador'
          for (const key in item) {
            if (item[key].length == 0 || !item[key]) {
              if (!this.notRequired.includes(key)) {
                this.errors.push(`${ key }${ index }`)
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
              }
            }
          }
        }
      })
      if (!this.errors.length) {
        this.form.infos.forEach((value, index) => {
          if (value.active) {
            if (!this.validateEmail(value.email, index)) {
              this.errors.push(`email${ index }`)
              this.textWarning = 'E-mail inválido!'
            }
            emails.push(value.email)
            if (value.specialattendance && value.specialattendance.includes('outro')) {
              this.form.infos[index].specialattendance.push(
                this.form.infos[index].attendOtherSpecial
              )
              delete this.form.infos[index].attendOtherSpecial
              this.form.infos[index].specialattendance = [
                ...new Set(this.form.infos[index].specialattendance.filter(Boolean))
              ]
            }
          }
        })

        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'E-mails inseridos devem ser distintos!'
          return false
        }

        if (!this.errors.length) {
          this.resetErrors()
          this.temporarySave()
          this.saveForm()
        }
      }
    },
    async temporarySave() {
      this.allDataForm.FormProjectChildishAdvisors = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        this.form.infos = this.infoAux

        this.$emit('changeElement', {
          direction: 'up',
          FormSelectionAboutAdvisors: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', {
        direction: 'down'
      })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmail(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.infos[index].email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    },
    getAddressInfo(index) {
      const cep = String(this.form.infos[index].zipCode).replace(/\D/g, '')
      if (cep.length === 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              if (this.allDataForm.FormProjectChildishAdvisors) {
                this.form.infos[index].cepValidate = true
                return
              }

              this.form.infos[index].cepValidate = false
              this.form.infos[index].state = ' '
              this.errors.push('zipCode')
              this.textWarning = 'Informe um CEP válido'
            } else {
              this.form.infos[index].cepValidate = true
              this.form.infos[index].state = data.uf
              this.form.infos[index].city = data.localidade
              this.form.infos[index].address = data.logradouro
              this.form.infos[index].neighborhood = data.bairro
              // this.resetErrors();
              // this.textWarning = "Todos os campos devem ser preenchidos."
            }
          })
          .catch((err) => {
            if (this.allDataForm.FormProjectChildishAdvisors) {
              const projectAdvisor = this.allDataForm.FormProjectChildishAdvisors.form.infos[index]

              this.form.infos[index].state = projectAdvisor.state
              this.form.infos[index].city = projectAdvisor.city
              this.form.infos[index].district = projectAdvisor.district
              this.form.infos[index].street = projectAdvisor.street
            }
            this.form.infos[index].cepValidate = false
            this.errors.push('zipCode')
            this.textWarning = 'Informe um CEP válido'
          })
      } else if (cep.length < 8) {
        // this.form.infos[index].state = "";
        // this.form.infos[index].city = "";
        // this.form.infos[index].address = "";
        // this.form.infos[index].neighborhood = "";
        this.form.infos[index].cepValidate = false
      }
    },
    closeModal() {
      this.coadvisorModal = false
      this.form.infos[1].active = true
    },
    closeModalAndYes() {
      this.coadvisorModal = false
    },
    checkValueInputOthersProgram(index) {
      if (this.form.infos[index].scholarship !== 'yes') {
        this.form.infos[index].programOther = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 600px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__main {
    .form__item {
      .input__space {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .separator {
        border-bottom: 1px solid $primary;
        padding-bottom: 15px;
        margin-top: 30px;

        color: $primaryDark;
        font-family: Mulish;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
      }

      &--info {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        color: $primary;
        max-width: 520px;
      }

      .levels {
        display: flex;
        flex-direction: column;

        .title {
          color: #303030;
          display: flex;
          flex-direction: column;
          font-size: 16px !important;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
        }

        label {
          display: flex;
          color: $almostBlack;
          font-size: 16px;
          margin-bottom: 5px;

          input {
            zoom: 1.5;
            margin-right: 5px;
          }
        }
        .warning {
          border-bottom-color: $attention;
          color: #ff9800;
        }
      }
    }
  }

  &__textModal {
    color: #18516f;
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    max-width: 425px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
