import { render, staticRenderFns } from "./FinalistsIndicators.vue?vue&type=template&id=43e47d76&scoped=true&"
import script from "./FinalistsIndicators.vue?vue&type=script&lang=js&"
export * from "./FinalistsIndicators.vue?vue&type=script&lang=js&"
import style0 from "./FinalistsIndicators.vue?vue&type=style&index=0&id=43e47d76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e47d76",
  null
  
)

export default component.exports