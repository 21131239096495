<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('summaryForTheResearcher')}}</p>
        <text-warning :text="$t('considerAllFieldObligatory')"/>
      </div>

      <div class="summary mb-7">
        <text-info-title text=projectData />
        <p class="form__text mt-0 mb-7">
          {{$t('reviewProjectData')}}
        </p>

        <input-default
          label=projectTitle
          id="projectTitle"
          :disabled="true"
          v-model="projectTitle"
        />

        <label class="label mt-7">{{$t('students')}}</label>
        <label
          class="students"
          v-for="(students, index) in students"
          :key="`${students}-${index}`"
          >{{ students }}</label
        >

        <label class="label mt-7">{{$t('researchAreaSingle')}}</label>
        <label
          class="label__item"
          v-for="(area, index) in areaResearch"
          :key="`${area}-${index}`"
          >{{ area }}</label
        >

        <label class="label mt-7">{{$t('researchSubAreas')}}</label>
        <label
          class="label__item"
          v-for="(subarea, index) in subareasResearch"
          :key="`${subarea}-${index}`"
          >{{ subarea }}</label
        >
      </div>

      <input-masked
        :class="[{ warning: checkErrorIfContains('startProject') }, 'input__space']"
        required
        label=researchProjectStartDate
        id="startProject"
        placeholder="dd/mm/aaaa"
        type="text"
        v-model="form.startProject"
        @input="resetErrors"
        :mask="['##/##/####']"
      />

      <input-masked
        :class="[{ warning: checkErrorIfContains('endsProject') }, 'input__space']"
        required
        label=plannedEndDate
        id="endsProject"
        placeholder="dd/mm/aaaa"
        type="text"
        v-model="form.endsProject"
        @input="resetErrors"
        :mask="['##/##/####']"
      />

      <radio-group
        :class="[{ warning: checkErrorIfContains('willContinued') }]"
        label=willYourProjectBeContinued
        :options="generalOptions"
        v-model="form.willContinued"
        @change="resetErrors"
      />

      <text-info
        class="mb-2"
        text=continuityProjectInfo
      />

      <input-masked
        :class="[{ warning: checkErrorIfContains('startCollect') }, 'input__space']"
        required
        label=plannedDateForStartingExperimentation
        id="startCollect"
        placeholder="dd/mm/aaaa"
        type="text"
        v-model="form.startCollect"
        @input="resetErrors"
        :mask="['##/##/####']"
      />

      <radio-group
        :class="[{ warning: checkErrorIfContains('researchInLab') }]"
        label=willYouConductInLaboratory
        :options="generalOptions"
        v-model="form.researchInLab"
        @change="resetErrors"
      />

      <div v-if="form.researchInLab">
        <div class="separator">
          <h3>{{$t('researchInstituitionIndustry')}}</h3>
        </div>

        <input-default
          label=institutionNameSingle
          :class="[
            { warning: checkErrorIfContains('researchInstitution-institutionName') },
            'input__space mt-5'
          ]"
          placeholder=fullNamePlaceholder
          type="text"
          v-model="researchInstitution.institutionName"
          @input="resetErrors"
        />

        <input-default
          :class="[
            { warning: checkErrorIfContains('researchInstitution-institutionPhone') },
            'input__space'
          ]"
          label=enterInstitutionSchoolPhone
          id="phone"
          placeholder="(00) 00000-0000"
          type="text"
          max="20"
          v-model="researchInstitution.institutionPhone"
          @input="resetErrors"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />

        <input-default
          :class="[
            { warning: checkErrorIfContains('researchInstitution-institutionEmail') },
            'input__space'
          ]"
          label=email
          id="email"
          placeholder=enterTheEmail
          type="text"
          v-model="researchInstitution.institutionEmail"
          @input="resetErrors"
        />

        <div class="levels">
          <p
            :class="[
              { warning: checkErrorIfContains('researchInstitution-researchLocal') },
              'title'
            ]"
          >
            {{$t('locationsWhereTheResearchWillBeConducted')}}
          </p>
          <label
            ><input
              @change="resetErrors"
              value="campo"
              v-model="researchInstitution.researchLocal"
              type="checkbox"
            />{{$t('field')}}</label
          >
          <label
            ><input
              @change="resetErrors"
              value="em casa"
              v-model="researchInstitution.researchLocal"
              type="checkbox"
            />{{$t('atHome')}}</label
          >
          <label
            ><input
              @change="resetErrors"
              value="escola"
              v-model="researchInstitution.researchLocal"
              type="checkbox"
            />{{$t('school')}}</label
          >
          <label
            ><input
              @change="resetErrors"
              value="outro"
              v-model="researchInstitution.researchLocal"
              type="checkbox"
            />Outro</label
          >
          <input-default
            v-if="researchInstitution.researchLocal.includes('outro')"
            :class="[{ warning: checkErrorIfContains('researchLocalOther') }, 'input__space']"
            id="email"
            placeholder="Insira o local"
            type="text"
            v-model="researchInstitution.researchLocalOther"
            @input="resetErrors"
          />
        </div>

        <div class="levels">
          <p
            :class="[
              { warning: checkErrorIfContains('researchInstitution-researchItens') },
              'title mt-3'
            ]"
          >
            {{$t('itemsThatApplyToYourResearchTrials')}}
          </p>
          <label
            ><input
              @change="resetErrors"
              value="seres humanos"
              v-model="researchInstitution.researchItens"
              type="checkbox"
            />{{$t('humans')}}</label
          >
          <label
            ><input
              @change="resetErrors"
              value=vertebrates
              v-model="researchInstitution.researchItens"
              type="checkbox"
            />{{$t('vertebrates')}}</label
          >
          <label
            ><input
              @change="resetErrors"
              value="agentes biologicos"
              v-model="researchInstitution.researchItens"
              type="checkbox"
            />Agentes Biológicos Potencialmente Perigosos</label
          >
          <label
            ><input
              @change="resetErrors"
              value="substancias quimicas/perigosos"
              v-model="researchInstitution.researchItens"
              type="checkbox"
            />Substâncias Químicas, Atividades ou Equipamentos Perigosos</label
          >
          <label
            ><input
              @change="resetErrors"
              value="controlada por lei"
              v-model="researchInstitution.researchItens"
              type="checkbox"
            />Substâncias Controladas por Lei</label
          >
          <label
            ><input
              @change="resetErrors"
              value="nenhuma"
              v-model="researchInstitution.researchItens"
              type="checkbox"
            />{{$t('none')}}</label
          >
        </div>

        <div class="separator">
          <h3>{{$t('address')}}</h3>
        </div>

        <input-select-location
          :class="[
            { warning: checkErrorIfContains('researchInstitution-country') },
            'input__space mt-5'
          ]"
          id="country"
          :items="countries"
          v-model="researchInstitution.country"
          label=country
          @input="resetErrors"
        />

        <input-masked
          v-if="researchInstitution.country == 'Brazil'"
          :class="[
            { warning: checkErrorIfContains('researchInstitution-zipCode') },
            'input__space'
          ]"
          required
          label="CEP"
          id="zipCode"
          :placeholder="$t('inputInstitutionCepPlaceholder')"
          type="text"
          v-model="researchInstitution.zipCode"
          @input="resetErrors"
          :mask="['#####-###']"
        />

        <input-default
          v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
          :class="[{ warning: checkErrorIfContains('researchInstitution-state') }, 'input__space']"
          label=state
          id="state"
          type="text"
          v-model="researchInstitution.state"
          @input="resetErrors"
        />

        <input-default
          v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
          :class="[{ warning: checkErrorIfContains('researchInstitution-city') }, 'input__space']"
          label=city
          id="city"
          type="text"
          v-model="researchInstitution.city"
          @input="resetErrors"
        />

        <input-default
          v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
          :class="[
            { warning: checkErrorIfContains('researchInstitution-address') },
            'input__space'
          ]"
          label=address
          id="address"
          placeholder=addressExample
          type="text"
          v-model="researchInstitution.address"
          @input="resetErrors"
        />

        <input-default
          v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
          :class="[{ warning: checkErrorIfContains('researchInstitution-number') }, 'input__space']"
          label="Número"
          id="number"
          placeholder=numberPlaceholder
          type="text"
          v-model="researchInstitution.number"
          @input="resetErrors"
        />

        <input-default
          v-if="researchInstitution.country == 'Brazil' && researchInstitution.state"
          :class="[
            { warning: checkErrorIfContains('researchInstitution-complement') },
            'input__space'
          ]"
          label=complementOptional
          id="complement"
          placeholder=complementPlaceholder
          type="text"
          v-model="researchInstitution.complement"
          @input="resetErrors"
        />

        <input-default
          v-if="researchInstitution.country && researchInstitution.country != 'Brazil'"
          :class="[{ warning: checkErrorIfContains('researchInstitution-state') }, 'input__space']"
          label="State/Province"
          id="state"
          placeholder="Input your city"
          type="text"
          v-model="researchInstitution.state"
          @input="resetErrors"
        />

        <input-default
          v-if="researchInstitution.country && researchInstitution.country != 'Brazil'"
          :class="[{ warning: checkErrorIfContains('researchInstitution-city') }, 'input__space']"
          label="City"
          id="city"
          placeholder="Input your city"
          type="text"
          v-model="researchInstitution.city"
          @input="resetErrors"
        />
      </div>

      <radio-group
        :class="[{ warning: checkErrorIfContains('newStudents') }]"
        label=willTheContinuityIncludeNewStudents
        :options="generalOptions"
        v-model="form.newStudents"
        @change="resetErrors"
      />

      <div v-if="form.newStudents">
        <section class="form__main mt-4" v-if="newStudentsInfo.length && form.newStudents">
          <div class="form__item mb-5" v-for="(info, index) in newStudentsInfo" :key="index">
            <div class="person__checkbox_delete">
              <input-checkbox-title
                :class="[
                  { warning: checkErrorIfContains('active' + index) },
                  'mb-7 checkbox__person'
                ]"
                :label="`Estudante ${index + 1}`"
                v-model="newStudentsInfo[index].active"
                id="advisor"
                @change="resetErrors"
              />

              <div class="icon-delete" @click="deleteInvolved(index)">
                <img src="@/assets/bin-1.png" alt="Delete" />
              </div>
            </div>

            <div v-if="newStudentsInfo[index].active">
              <input-default
                label=fullName
                :class="[{ warning: checkErrorIfContains('fullname' + index) }, 'input__space']"
                placeholder=fullNamePlaceholder
                type="text"
                v-model="newStudentsInfo[index].fullname"
                @input="resetErrors"
              />

              <input-masked
                v-if="!newStudentsInfo[index].country || newStudentsInfo[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
                label=cpf
                id="codigo"
                placeholder="xxx.xxx.xxx-xx"
                type="text"
                v-model="newStudentsInfo[index].cpf"
                @input="resetErrors"
                :mask="['###.###.###-##']"
              />

              <input-default
                v-else
                :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
                label="Identification number"
                id="codigo"
                placeholder="Insert your national identification number"
                type="text"
                v-model="newStudentsInfo[index].cpf"
                @input="resetErrors"
              />

              <radio-group
                :class="[{ warning: checkErrorIfContains('gender' + index) }, 'input__space mb-2']"
                label=sex
                :options="genderOptions"
                v-model="newStudentsInfo[index].gender"
                @change="resetErrors"
              />

              <input-masked
                :class="[{ warning: checkErrorIfContains('birthday' + index) }, 'input__space']"
                required
                label=birthDate
                id="birthday"
                placeholder="dd/mm/aaaa"
                type="text"
                v-model="newStudentsInfo[index].birthday"
                @input="resetErrors"
                :mask="['##/##/####']"
              />

              <input-default
                :class="[{ warning: checkErrorIfContains('phone' + index) }, 'input__space']"
                label=enterInstitutionSchoolPhone
                id="phone"
                placeholder="(00) 00000-0000"
                type="text"
                max="20"
                v-model="newStudentsInfo[index].phone"
                @input="resetErrors"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />

              <input-default
                :class="[{ warning: checkErrorIfContains('email' + index) }, 'input__space']"
                label=email
                id="email"
                placeholder=enterTheEmail
                type="text"
                v-model="newStudentsInfo[index].email"
                @input="resetErrors"
              />

              <div class="separator">
                <h3>{{$t('address')}}</h3>
              </div>

              <input-select-location
                :class="[
                  { warning: checkErrorIfContains('country' + index) },
                  'input__space mt-5 mb-5'
                ]"
                id="country"
                :items="countries"
                v-model="newStudentsInfo[index].country"
                label=country
                @input="resetErrors"
              />

              <input-masked
                v-if="newStudentsInfo[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('zipCode' + index) }, 'input__space']"
                required
                label="CEP"
                id="zipCode"
                :placeholder="$t('inputCepPlaceholder')"
                type="text"
                v-model="newStudentsInfo[index].zipCode"
                @input="resetErrors"
                :mask="['#####-###']"
              />

              <input-default
                v-if="newStudentsInfo[index].country == 'Brazil' && newStudentsInfo[index].state"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                label=state
                id="state"
                type="text"
                v-model="newStudentsInfo[index].state"
                @input="resetErrors"
              />

              <input-default
                v-if="newStudentsInfo[index].country == 'Brazil' && newStudentsInfo[index].state"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                label=city
                id="city"
                type="text"
                v-model="newStudentsInfo[index].city"
                @input="resetErrors"
              />

              <input-default
                v-if="newStudentsInfo[index].country == 'Brazil' && newStudentsInfo[index].state"
                :class="[{ warning: checkErrorIfContains('address' + index) }, 'input__space']"
                label=address
                id="address"
                placeholder=addressExample
                type="text"
                v-model="newStudentsInfo[index].address"
                @input="resetErrors"
              />

              <input-default
                v-if="newStudentsInfo[index].country == 'Brazil' && newStudentsInfo[index].state"
                :class="[{ warning: checkErrorIfContains('number' + index) }, 'input__space']"
                label="Número"
                id="number"
                placeholder=numberPlaceholder
                type="text"
                v-model="newStudentsInfo[index].number"
                @input="resetErrors"
              />

              <input-default
                v-if="newStudentsInfo[index].country == 'Brazil' && newStudentsInfo[index].state"
                :class="[{ warning: checkErrorIfContains('complement' + index) }, 'input__space']"
                label=complementOptional
                id="complement"
                placeholder=complementPlaceholder
                type="text"
                v-model="newStudentsInfo[index].complement"
                @input="resetErrors"
              />

              <input-default
                v-if="newStudentsInfo[index].country && newStudentsInfo[index].country != 'Brazil'"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                label="State/Province"
                id="state"
                placeholder="Input your city"
                type="text"
                v-model="newStudentsInfo[index].state"
                @input="resetErrors"
              />

              <input-default
                v-if="newStudentsInfo[index].country && newStudentsInfo[index].country != 'Brazil'"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                label="City"
                id="city"
                placeholder="Input your city"
                type="text"
                v-model="newStudentsInfo[index].city"
                @input="resetErrors"
              />

              <div class="separator">
                <h3>{{$t('registrationData')}}</h3>
              </div>

              <radio-group
                :class="[
                  { warning: checkErrorIfContains('attendOtherFair' + index) },
                  'input__space mt-5 mb-5'
                ]"
                label=haveYouParticipatedAnyOtherMostratec
                :options="generalOptions"
                v-model="newStudentsInfo[index].attendOtherFair"
                @change="resetErrors"
              />

              <div class="levels">
                <p :class="[{ warning: checkErrorIfContains('levelDeficiency' + index) }, 'title']">
                  {{$t('doYouHaveAnyKindOfDisability')}}
                </p>
                <label
                  ><input
                    @change="resetErrors"
                    value="nao"
                    v-model="newStudentsInfo[index].levelDeficiency"
                    type="checkbox"
                  />{{$t('none')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="fisica"
                    v-model="newStudentsInfo[index].levelDeficiency"
                    type="checkbox"
                  />{{$t('fis')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="intelectual"
                    v-model="newStudentsInfo[index].levelDeficiency"
                    type="checkbox"
                  />{{$t('intellectual')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="auditiva"
                    v-model="newStudentsInfo[index].levelDeficiency"
                    type="checkbox"
                  />{{$t('auditory')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="visual"
                    v-model="newStudentsInfo[index].levelDeficiency"
                    type="checkbox"
                  />{{$t('visual')}}</label
                >
              </div>

              <div class="levels">
                <p
                  :class="[
                    { warning: checkErrorIfContains('specialattendance' + index) },
                    'title mt-3'
                  ]"
                >
                  {{$t('willYouNeedAnySpecializedAssistance')}}
                </p>
                <label
                  ><input
                    @change="resetErrors"
                    value="nao"
                    v-model="newStudentsInfo[index].specialattendance"
                    type="checkbox"
                  />{{$t('none')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="acessibilidade"
                    v-model="newStudentsInfo[index].specialattendance"
                    type="checkbox"
                  />{{$t('architecturalAccessibility')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="libras"
                    v-model="newStudentsInfo[index].specialattendance"
                    type="checkbox"
                  />{{$t('libraInterpreter')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="ledor"
                    v-model="newStudentsInfo[index].specialattendance"
                    type="checkbox"
                  >{{$t('reader')}}</label
                >
                <label
                  ><input
                    @change="resetErrors"
                    value="outro"
                    v-model="newStudentsInfo[index].specialattendance"
                    type="checkbox"
                  />{{$t('othersMale')}}</label
                >
                <input-default
                  v-if="newStudentsInfo[index].specialattendance.includes('outro')"
                  :class="[{ warning: checkErrorIfContains('attendOther' + index) }, 'mt-0']"
                  id="city"
                  placeholder=enterWhichScienceFair
                  type="text"
                  v-model="newStudentsInfo[index].attendOther"
                  @input="resetErrors"
                />
              </div>

              <input-select
                :class="[
                  { warning: checkErrorIfContains('typeShirt' + index) },
                  'input__space mt-5'
                ]"
                id="typeShirt"
                :items="typeShirtOptions"
                v-model="newStudentsInfo[index].typeShirt"
                label=typeOfShirt
                @input="resetErrors"
              />

              <input-select
                :class="[{ warning: checkErrorIfContains('sizeShirt' + index) }, 'input__space']"
                id="englishLevel"
                :items="sizeShirtOptions"
                v-model="newStudentsInfo[index].sizeShirt"
                label=whatSizeShirt
                @input="resetErrors"
              />
            </div>
          </div>
        </section>

        <button-default
          :onClick="addNewInvolved"
          class="button__add mb-7"
          :style="{
            cursor: form.othersInvolved == 'no' ? 'no-drop' : 'pointer',
            'background-color': form.othersInvolved == 'no' ? '#4c4c4e' : '#1B778B'
          }"
        >
          <div class="buttonText__icon">
            {{$t('addNewStudent')}}
            <img class="icon-plus" src="@/assets/add-icon.svg" alt="Adicionar" />
          </div>
        </button-default>
      </div>

      <radio-group
        :class="[{ warning: checkErrorIfContains('newArea') }]"
        label=willTheContinuityIncludeNewResearch
        :options="generalOptions"
        v-model="form.newArea"
        @change="resetErrors"
      />

      <div v-if="form.newArea">
        <input-select
          id="areaSelect"
          :class="[{ warning: checkErrorIfContains('researchArea') }, 'mb-4']"
          :items="areaOptions"
          v-model="researchArea"
          label=researchAreaSingle
          placeholder="Selecione a Área de pesquisa"
          @input="resetErrors"
        />

        <input-checkbox
          v-if="researchArea"
          :class="[{ warning: checkErrorIfContains('subareas') }, 'mb-7 title_checkbox']"
          :dataArea="areas[researchArea]"
          :v-model="areas[researchArea]"
          @change="resetErrors"
        />
      </div>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :large="true" :onClick="checkForm" class="button"
          >{{$t('confirmAndContinue')}}</button-default
        >
      </div>

      <modal-selection-process
        title=disableStudentInvolved
        :onClick="() => closeModalAndYes()"
        @closeModal="() => closeModal()"
        :modal="warningModal"
      >
        <div>
          <p class="form__textModal">{{$t('disableStudentInvolvedInfo')}}</p>
        </div>
      </modal-selection-process>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo,
  TextInfoTitle,
  RadioGroup,
  InputSelectLocation,
  InputMasked,
  InputCheckboxTitle,
  InputSelect,
  InputCheckbox
} from '@/components/atoms'
import { ModalSelectionProcess } from '@/components/organisms/Modals'
import { countries } from '@/utils/locations'
import { areas, areaOptions } from '@/utils/areas'

export default {
  name: 'FormSelectionResearcherSummary',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo,
    TextInfoTitle,
    RadioGroup,
    InputSelectLocation,
    InputMasked,
    InputCheckboxTitle,
    InputSelect,
    InputCheckbox,
    ModalSelectionProcess
  },
  data() {
    return {
      warningModal: false,
      indexToDelete: false,
      projectTitle: '',
      students: [],
      areaResearch: [],
      subareasResearch: [],
      researchArea: '',
      form: {
        startProject: '',
        endsProject: '',
        willContinued: '',
        startCollect: '',
        researchInLab: '',
        newStudents: '',
        newArea: ''
      },
      researchInstitution: {
        institutionName: '',
        institutionPhone: '',
        institutionEmail: '',
        researchLocal: [],
        researchLocalOther: '',
        researchItens: [],
        country: '',
        zipCode: '',
        state: '',
        city: '',
        address: '',
        number: '',
        complement: ''
      },
      newStudentsInfo: [],
      generalOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ],
      quantityOptions: [
        { label: this.$t('individual'), value: 1 },
        { label: this.$t('pair'), value: 2 },
        { label: this.$t('trio'), value: 3 }
      ],
      genderOptions: [
        { label: this.$t('maleSingle'), value: 'Male' },
        { label: this.$t('femaleSingle'), value: 'Female' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: this.$t('advanced') },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' },
        { text: 'P', value: 'p' },
        { text: 'M', value: 'm' },
        { text: 'G', value: 'g' },
        { text: 'GG', value: 'gg' }
      ],
      areas,
      areaOptions,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      countries
    }
  },
  computed: {
    ...mapGetters({
      projectSelection: 'projectSelection'
    }),
    zipCodeAdvisor() {
      if (this.newStudentsInfo.length) {
        return this.newStudentsInfo[0].zipCode
      }
    },
    zipCodeCoAdvisor() {
      if (this.newStudentsInfo.lenght > 1) {
        return this.newStudentsInfo[1].zipCode
      }
    }
  },
  created() {
    const formData = this.$store.getters.projectSelection
    if (formData.sub_areas && formData.students) {
      this.projectTitle = formData.project_title
      this.areaResearch = this.areaOptions
        .filter((item) => item.value == this.projectSelection.area)
        .map((item) => item.text)
      this.students = formData.students.map((item) => item.full_name)
      this.subareasResearch = formData.sub_areas.map((item) => item.text.replace(';', ''))

      this.form.startProject = formData.research_init
      this.form.endsProject = formData.research_end
      this.form.willContinued = formData.will_continue
      this.form.startCollect = formData.experimentation_beginning
      this.form.newArea = formData.new_area

      if (formData.research_institution) {
        this.form.researchInLab = true
        this.researchInstitution = {
          ...formData.research_institution,
          researchLocal: formData.research_institution.research_locations,
          institutionName: formData.research_institution.institution_name,
          institutionPhone: formData.research_institution.phone,
          institutionEmail: formData.research_institution.email,
          researchLocalOther: '',
          researchItens: formData.research_institution.experiment_items,
          country: formData.research_institution.country,
          zipCode: formData.research_institution.zipcode,
          address: formData.research_institution.street,
          number: formData.research_institution.house_number,
          complement: formData.research_institution.complement
        }
      } else {
        this.form.researchInLab = false
      }
      if (formData.newStudentsInfo) {
        this.form.newStudents = true
        this.newStudentsInfo = formData.newStudentsInfo
      } else {
        this.form.newStudents = false
      }
      if (formData.continuity_area) {
        this.researchArea = formData.continuity_area
      }
      this.areas = {
        ...this.areas,
        [this.researchArea]: {
          ...this.areas[this.researchArea],
          ...this.projectSelection.continuity_sub_areas
        }
      }
    }
  },
  watch: {
    'researchInstitution.zipCode': function (newVal, oldVal) {
      const cep = String(this.researchInstitution.zipCode).replace(/\D/g, '')
      if (cep.length == 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              this.researchInstitution.state = ' '
              this.errors.push('zipCode')
              this.textWarning = 'Informe um CEP válido'
            } else {
              this.researchInstitution.state = data.uf
              this.researchInstitution.city = data.localidade
              this.researchInstitution.address = data.logradouro
              this.resetErrors()
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
            }
          })
          .catch((err) => {
            this.researchInstitution.state = ' '
            this.errors.push('zipCode')
            this.textWarning = 'Informe um CEP válido'
          })
      }
    },
    'form.newStudents': function () {
      if (!this.form.newStudents && this.newStudentsInfo.length) {
        this.warningModal = true
      }
      if (this.form.newStudents && !this.newStudentsInfo.length) {
        this.addNewInvolved()
      }
    },
    zipCodeAdvisor() {
      this.getAddressInfo(0)
    },
    zipCodeCoAdvisor() {
      this.getAddressInfo(1)
    }
  },
  methods: {
    checkForm() {
      this.resetErrors()
      const emails = []
      for (const key in this.form) {
        if (!this.form[key] && typeof this.form[key] !== 'boolean') {
          this.errors.push(key)
        }
      }
      if (
        this.form.researchInLab == false
        && this.form.newStudents == false
        && this.form.newArea == false
        && !this.errors.length
      ) {
        return this.saveForm()
      }
      if (this.form.researchInLab) {
        for (const key in this.researchInstitution) {
          if (!this.researchInstitution[key] && typeof this.researchInstitution[key] !== 'boolean') {
            this.errors.push(`researchInstitution-${ key }`)
          }
          if (
            typeof this.researchInstitution[key] === 'object'
            && !this.researchInstitution[key].length
          ) {
            this.errors.push(`researchInstitution-${ key }`)
          }
        }
        if (this.researchInstitution.researchLocalOther != 'other') {
          const indexAttend = this.errors.indexOf('researchInstitution-researchLocalOther')
          if (indexAttend > -1) {
            this.errors.splice(indexAttend, 1)
          }
        }
        if (this.researchInstitution.country != 'Brazil') {
          const indexZipcode = this.errors.indexOf('researchInstitution-zipCode')
          if (indexZipcode > -1) {
            this.errors.splice(indexZipcode, 1)
          }
          const indexAddress = this.errors.indexOf('researchInstitution-address')
          if (indexAddress > -1) {
            this.errors.splice(indexAddress, 1)
          }
          const indexNumber = this.errors.indexOf('researchInstitution-number')
          if (indexNumber > -1) {
            this.errors.splice(indexNumber, 1)
          }
          const indexComplement = this.errors.indexOf('researchInstitution-complement')
          if (indexComplement > -1) {
            this.errors.splice(indexComplement, 1)
          }
        }
      }
      if (this.form.newStudents) {
        this.newStudentsInfo.map((item, index) => {
          for (const key in item) {
            if (typeof item[key] === 'boolean' && !item[key]) {
              continue
            }
            if (item[key].length == 0 || !item[key]) {
              this.errors.push(`${ key }${ index }`)
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
            }
          }
          if (item.country != 'Brazil') {
            const indexZipcode = this.errors.indexOf(`zipCode${ index }`)
            if (indexZipcode > -1) {
              this.errors.splice(indexZipcode, 1)
            }
            const indexAddress = this.errors.indexOf(`address${ index }`)
            if (indexAddress > -1) {
              this.errors.splice(indexAddress, 1)
            }
            const indexNumber = this.errors.indexOf(`number${ index }`)
            if (indexNumber > -1) {
              this.errors.splice(indexNumber, 1)
            }
            const indexComplement = this.errors.indexOf(`complement${ index }`)
            if (indexComplement > -1) {
              this.errors.splice(indexComplement, 1)
            }
          }
          const indexComplement = this.errors.indexOf(`complement${ index }`)
          if (indexComplement > -1) {
            this.errors.splice(indexComplement, 1)
          }
          if (!item.specialattendance.includes('outro')) {
            const indexOther = this.errors.indexOf(`attendOther${ index }`)
            if (indexOther >= 0) {
              this.errors.splice(indexOther, 1)
            }
          }
          if (item.specialattendance.length) {
            const indexAttend = this.errors.indexOf(`attendOtherFair${ index }`)
            if (indexAttend >= 0) {
              this.errors.splice(indexAttend, 1)
            }
          }
        })
      }
      if (this.form.newArea) {
        if (!this.researchArea) {
          this.errors.push('researchArea')
        } else {
          const allFalse = []
          Object.entries(this.areas).forEach((item) => {
            Object.entries(item[1]).forEach((subarea) => {
              allFalse.push(subarea[1].value)
            })
          })
          if (allFalse.every((item) => item == false)) {
            this.errors.push('subareas')
          }
        }
      }
      if (!this.errors.length) {
        this.newStudentsInfo.forEach((value, index) => {
          if (!this.validateEmail(value.email)) {
            this.errors.push(`email${ index }`)
            this.textWarning = 'E-mail inválido!'
          }
          emails.push(value.email)
        })

        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'E-mails inseridos devem ser distintos!'
          return false
        }
        this.resetErrors()
        this.saveForm()
      }
    },
    saveForm() {
      try {
        const formData = this.$store.getters.projectSelection
        const sub_areas = []
        for (const property in this.areas) {
          if (property == this.researchArea) {
            Object.entries(this.areas[property]).forEach((item) => {
              if (item[1].value) {
                sub_areas.push({ ...item[1], variableName: item[0] })
              }
            })
          }
        }
        let research_institution = {}
        if (this.form.researchInLab) {
          research_institution = {
            institution_name: this.researchInstitution.institutionName,
            phone: this.researchInstitution.institutionPhone,
            research_locations: this.researchInstitution.researchLocal, // FALTA JUNTAR O OTHER AQUI
            email: this.researchInstitution.institutionEmail,
            experiment_items: this.researchInstitution.researchItens,
            country: this.researchInstitution.country,
            zipcode: this.researchInstitution.zipCode,
            state: this.researchInstitution.state,
            city: this.researchInstitution.city,
            street: this.researchInstitution.address,
            house_number: this.researchInstitution.number,
            complement: this.researchInstitution.complement
          }
        }
        const newData = {
          ...formData,
          research_init: this.form.startProject,
          research_end: this.form.endsProject,
          will_continue: this.form.willContinued,
          experimentation_beginning: this.form.startCollect,
          research_institution: research_institution || false,
          new_area: this.form.newArea,
          continuity_area: this.researchArea,
          continuity_sub_areas: sub_areas,
          newStudentsInfo: this.newStudentsInfo
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem('projectSelectionData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
        console.log('error: ', error)
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmail(mail) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    },
    getAddressInfo(index) {
      if (this.newStudentsInfo.length) {
        const cep = String(this.newStudentsInfo[index].zipCode).replace(/\D/g, '')
        if (cep.length == 8) {
          fetch(`https://viacep.com.br/ws/${ cep }/json/`)
            .then((res) => res.json())
            .then((data) => {
              if (data.erro) {
                this.newStudentsInfo[index].state = ' '
                this.errors.push('zipCode')
                this.textWarning = 'Informe um CEP válido'
              } else {
                this.newStudentsInfo[index].state = data.uf
                this.newStudentsInfo[index].city = data.localidade
                this.newStudentsInfo[index].address = data.logradouro
                this.resetErrors()
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
              }
            })
            .catch((err) => {
              this.newStudentsInfo[index].state = ' '
              this.errors.push('zipCode')
              this.textWarning = 'Informe um CEP válido'
            })
        }
      }
    },
    addNewInvolved() {
      this.newStudentsInfo.push({
        active: false,
        fullname: '',
        cpf: '',
        gender: '',
        birthday: '',
        phone: '',
        email: '',
        country: '',
        zipCode: '',
        state: '',
        city: '',
        address: '',
        number: '',
        complement: '',
        attendOtherFair: '',
        levelDeficiency: [],
        specialattendance: [],
        attendOther: '',
        typeShirt: '',
        sizeShirt: ''
      })
    },
    deleteInvolved(index) {
      this.warningModal = true
      this.indexToDelete = index
    },
    closeModal() {
      this.warningModal = false
      this.form.newStudents = true
    },
    closeModalAndYes() {
      this.warningModal = false
      if (typeof this.indexToDelete !== 'boolean') {
        this.newStudentsInfo.splice(this.indexToDelete, 1)
      } else {
        this.newStudentsInfo = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 610px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
    max-width: 742px;
  }

  .button__add {
    align-self: flex-start;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .buttonText__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    .icon-plus {
      margin-left: 5px;
    }
  }

  .person__checkbox_delete {
    display: flex;
    justify-content: space-between;

    .checkbox__person {
      flex: 1;
    }
  }

  .icon-delete {
    max-width: 25px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__textModal {
    color: #18516f;
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    max-width: 425px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .summary {
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin: 10px 10px;
    padding: 30px;
    max-width: 90%;
  }

  .label {
    color: $almostBlack;
    display: flex;
    font-weight: 600;
    text-align: left;

    &__item {
      background: #319cb4;
      border-radius: 49px;
      color: #ffffff;
      font-size: 14px;
      margin: 0px 10px 10px 0px;
      padding: 5px 10px;
    }
  }

  .students {
    display: block;
    border-left: 2px solid #1b778b;
    color: #303030;
    padding-left: 5px;
  }

  .input__space {
    margin-bottom: 30px;
  }

  .separator {
    border-bottom: 1px solid $primary;
    padding-bottom: 15px;
    margin-top: 30px;

    color: $primaryDark;
    font-family: Mulish;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
  }

  .levels {
    display: flex;
    flex-direction: column;

    .title {
      color: #303030;
      display: flex;
      flex-direction: column;
      font-size: 16px !important;
      font-weight: 600;
      line-height: 20px;
      text-align: left;
    }

    label {
      display: flex;
      color: $almostBlack;
      font-size: 16px;
      margin-bottom: 5px;

      input {
        zoom: 1.5;
        margin-right: 5px;
      }
    }

    .warning {
      color: #ff9800;
    }
  }
}
</style>
