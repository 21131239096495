<template>
  <div class="wrapper">
    <ProjectSummary
      :title='title'
      :label='label'
      :placeholder='placeholder'
      :save='checkForm'
      :content='summary'
      @change='summary=$event'
    />
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
    <feedback-warning
      v-if="isSaved"
      text="succesfullySaved"
      :onClick="resetSuccesfulStatus"
      color="#33b850"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { ProjectSummary } from '@/components/organisms'
import { FeedbackWarning } from '@/components/atoms'
import checkDeadline from '@/utils/checkDeadline.js'

export default {
  name: 'ProjectPanel',
  components: { ProjectSummary, FeedbackWarning },
  computed: {
    ...mapGetters({ selectedProject: 'getSelectedProject' })
  },
  data() {
    return {
      title: this.$t('projectSummary'),
      label: this.$t('summary'),
      placeholder: 'Insira o resumo',
      summary: '',
      errors: [],
      isSaved: false,
      textWarning: '',
      mostratec: {}
    }
  },
  mounted() {
    this.summary = this.selectedProject?.summary
    this.getMostratec()
  },
  watch: {
    selectedProject() {
      this.summary = this.selectedProject?.summary
    }
  },
  methods: {
    async getMostratec() {
      try {
        const { data: response } = await this.$http.get('/current-mostratec')
        this.mostratec = response
      } catch (error) {
        console.error(error)
      }
    },
    checkForm() {
      this.resetErrors()
      if (!this.summary) {
        this.errors.push('resumo')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
        return
      }
      if (this.selectedProject.fair) {
        if (!checkDeadline(this.mostratec.project_date_end)) {
          this.errors.push('past deadline')
          this.textWarning = 'Prazo de inscrição encerrado.'
          return
        }
      } else if (!checkDeadline(this.mostratec.project_selection_date_end)) {
        this.errors.push('past deadline')
        this.textWarning = 'Prazo de inscrição encerrado.'
        return
      }
      this.save()
    },
    async save() {
      try {
        const { id } = this.selectedProject
        await this.$http.patch(`/project/${ id }/update`, { summary: this.summary })
        this.$store.commit('SELECTED_PROJECT',
          {
            ...this.selectedProject,
            summary: this.summary
          })
        this.$store.commit('UPDATE_PROJECT_LIST',
          {
            ...this.selectedProject,
            summary: this.summary
          })
        this.isSaved = true
      } catch (err) {
        console.log(err)
        this.isSaved = false
      }
    },
    resetSuccesfulStatus() {
      this.isSaved = false
    },
    resetErrors() {
      this.errors = []
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: block;
  width: 100%;
  background-color: #FDFDFD;
}

</style>
