<template>
  <div>
    <template v-if="educationLevel === 'grade'">
      <FormProjectChildishData
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle'">
      <FormProjectData
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_1'">
      <FormProjectData
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_2'">
      <FormProjectData
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_3'">
      <FormProjectData
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_eja'">
      <FormProjectData
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'high'">
      <FormProjectHighData
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
  </div>
</template>
<script>
import {
  FormProjectChildishData,
  FormProjectData,
  FormProjectHighData
} from '..'

export default {
  name: 'WrapperFormProjectData',
  data() {
    return {}
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String],
    educationLevel: String
  },
  components: {
    FormProjectChildishData,
    FormProjectData,
    FormProjectHighData
  },
  methods: {
    changeElementWrapper(data) {
      this.$emit('changeElement', data)
    }
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormProjectChildishData) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectChildishData
        }
      }
      if (this.allDataForm.FormProjectData) {
        this.form = { ...this.form, ...this.allDataForm.FormProjectData }
      }
      if (this.allDataForm.FormProjectHighData) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectHighData
        }
      }
    }
  }
}
</script>
