<template>
  <div  @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <header-modal @closeClicked="$emit('closeModal')" :title="title" :left="left" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { HeaderModal } from '@/components/organisms/Headers'

export default {
  name: 'ModalDefault',
  props: {
    modal: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    left: Boolean
  },
  components: { HeaderModal }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  @include d-flex-center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 3;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-height: 221px;
    padding: 20px;
    width: 649px;
  }
}
</style>
