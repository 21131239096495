<template>
  <div>
    <template v-if="educationLevel === 'grade'">
      <FormProjectChildishStudents
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle'">
      <FormProjectStudents :allDataForm="allDataForm" :mostratec_type="mostratec_type" @changeElement="changeElementWrapper" />
    </template>
    <template v-if="educationLevel === 'middle_cat_1'">
      <FormProjectStudents :allDataForm="allDataForm" :mostratec_type="mostratec_type" @changeElement="changeElementWrapper" />
    </template>
    <template v-if="educationLevel === 'middle_cat_2'">
      <FormProjectStudents :allDataForm="allDataForm" :mostratec_type="mostratec_type" @changeElement="changeElementWrapper" />
    </template>
    <template v-if="educationLevel === 'middle_cat_3'">
      <FormProjectStudents :allDataForm="allDataForm" :mostratec_type="mostratec_type" @changeElement="changeElementWrapper" />
    </template>
    <template v-if="educationLevel === 'middle_cat_eja'">
      <FormProjectStudents :allDataForm="allDataForm" :mostratec_type="mostratec_type" @changeElement="changeElementWrapper" />
    </template>
    <template v-if="educationLevel === 'high'">
      <FormProjectHighStudents :allDataForm="allDataForm" :mostratec_type="mostratec_type" @changeElement="changeElementWrapper" />
    </template>
  </div>
</template>
<script>
import {
  FormProjectChildishStudents,
  FormProjectStudents,
  FormProjectHighStudents
} from '..'

export default {
  name: 'WrapperFormProjectStudents',
  data() {
    return {}
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String],
    educationLevel: String
  },
  components: {
    FormProjectChildishStudents,
    FormProjectStudents,
    FormProjectHighStudents
  },
  methods: {
    changeElementWrapper(data) {
      this.$emit('changeElement', data)
    }
  },
  allDataForm() {
    if (this.allDataForm.FormProjectChildishStudents) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectChildishStudents
      }
    }
    if (this.allDataForm.FormProjectStudents) {
      this.form = { ...this.form, ...this.allDataForm.FormProjectStudents }
    }
    if (this.allDataForm.FormProjectHighStudents) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectHighStudents
      }
    }
  }
}
</script>
