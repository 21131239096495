<template>
  <header class="header">
    <div class="header__title">
      <icon-large
        class="header__icon"
        url="icon-pen.png"
        alt="Ícone de caneta da tela pré-cadastro"
      />
      <div class="title__subtitle">
        <title-default class="header__text">{{$t(title)}}</title-default>
        <span>{{$t(subtitle)}}</span>
      </div>
    </div>
    <button-close :onClick="() => false" class="header__icon-close"></button-close>
  </header>
</template>

<script>
import { ButtonClose, IconLarge, TitleDefault } from '@/components/atoms'

export default {
  name: 'HeaderForm',
  components: { ButtonClose, IconLarge, TitleDefault },
  props: ['title', 'subtitle']
}
</script>

<style lang="scss" scoped>
.header {
  @include d-flex-between;
  position: relative;
  min-width: 300px;

  &__title {
    @include d-flex-center;
  }

  &__icon {
    margin-right: 14px;
  }

  &__text {
    @include H1;
    color: #0E5671;
    font-size: 1.4rem;
    line-height: 30px;
    letter-spacing: 1px;
    transform: translateY(10px);
  }

  &__icon-close {
    position: absolute;
    right: 10px;
    top: 0px;
  }

  .title__subtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 1px;
      color: #0E5671;
      padding-left: 1px;
      margin-top: 10px;
    }
  }
}
</style>
