<template>
<div>
  <form @submit.prevent class="form">
    <div class="form__info">
      <p class="form__title">{{$t("ResearchPlan")}}</p>
      <p class="form__text">{{$t("ResearchPlanInfo")}}</p>
      <text-warning :text="`considerAllFieldObligatory`" />
    </div>

    <div :class="[{warning: checkErrorIfContains('theme')}, 'textarea mb-2']">
      <label class="label">{{$t("ThemeLabel")}}</label>
      <textarea
        :placeholder="$t('ThemePlaceholder')"
        v-model="form.theme"
        maxlength="500"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="ThemeTextInfo"
      />
      <span class="char-counter">{{form.theme.length}}/500</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('justification')}, 'textarea mb-2']">
      <label class="label">{{$t("JustificationLabel")}}</label>
      <textarea
        :placeholder="$t('JustificationPlaceholder')"
        v-model="form.justification"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <div class="text-info mb-2">
        <img :src="require(`@/assets/information-circle.png`)" alt="Informação sobre Liberato">
        <p>{{$t("JustificationTextInfo_parOne")}}<br><br>{{$t("JustificationTextInfo_parTwo")}}</p>
      </div>
      <span class="char-counter">{{form.justification.length}}/2000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('problem')}, 'textarea mb-2']">
      <label class="label">{{$t("ProblemLabel")}}</label>
      <textarea
        :placeholder="$t('ProblemPlaceholder')"
        v-model="form.problem"
        maxlength="4000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="ProblemTextInfo"
      />
      <span class="char-counter">{{form.problem.length}}/4000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('hypothesis')}, 'textarea mb-2']">
      <label class="label">{{$t("HypothesisLabel")}}</label>
      <textarea
        :placeholder="$t('HypothesisPlaceholder')"
        v-model="form.hypothesis"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <div class="text-info mb-2">
        <img :src="require(`@/assets/information-circle.png`)" alt="Informação sobre Liberato">
        <p>{{$t("HypothesisTextInfo_parOne")}}<br><br>{{$t("HypothesisTextInfo_parTwo")}}</p>
      </div>
      <span class="char-counter">{{form.hypothesis.length}}/2000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('objectives')}, 'textarea mb-2']">
      <label class="label">{{$t("ObjectivesLabel")}}</label>
      <textarea
        :placeholder="$t('ObjectivesPlaceholder')"
        v-model="form.objectives"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="ObjectivesTextInfo"
      />
      <span class="char-counter">{{form.objectives.length}}/2000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('object')}, 'textarea mb-2']">
      <label class="label">{{$t("ObjectLabel")}}</label>
      <textarea
        :placeholder="$t('ObjectPlaceholder')"
        v-model="form.object"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="ObjectTextInfo"
      />
      <span class="char-counter">{{form.object.length}}/2000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('literature_review')}, 'textarea mb-2']">
      <label class="label">{{$t("BibliographicReviewLabel")}}</label>
      <textarea
        :placeholder="$t('BibliographicReviewPlaceholder')"
        v-model="form.literature_review"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="BibliographicReviewTextInfo"
      />
      <span class="char-counter">{{form.literature_review.length}}/2000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('methodology')}, 'textarea mb-2']">
      <label class="label">{{$t("MethodologyLabel")}}</label>
      <textarea
        :placeholder="$t('MethodologyPlaceholder')"
        v-model="form.methodology"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <div class="text-info mb-2">
        <img :src="require(`@/assets/information-circle.png`)" alt="Informação sobre Liberato">
        <p>{{$t("MethodologyTextInfo_parOne")}}<br><br>{{$t("MethodologyTextInfo_parTwo")}}</p>
      </div>
      <span class="char-counter">{{form.methodology.length}}/2000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('schedule')}, 'textarea mb-2']">
      <label class="label">{{$t("CronogramLabel")}}</label>
      <textarea
        :placeholder="$t('CronogramPlaceholder')"
        v-model="form.schedule"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="CronogramTextInfo"
      />
      <span class="char-counter">{{form.schedule.length}}/2000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('data_analysis')}, 'textarea mb-2']">
      <label class="label">{{$t("DataAnalisisLabel")}}</label>
      <textarea
        :placeholder="$t('DataAnalisisPlaceholder')"
        v-model="form.data_analysis"
        maxlength="4000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="DataAnalisisTextInfo"
      />
      <span class="char-counter">{{form.data_analysis.length}}/4000</span>
    </div>
    <div :class="[{warning: checkErrorIfContains('references')}, 'textarea mb-2']">
      <label class="label">{{$t("ReferencesLabel")}}</label>
      <textarea
        :placeholder="$t('ReferencesPlaceholder')"
        v-model="form.references"
        maxlength="2000"
        @input="resetErrors"
      ></textarea>
    </div>
    <div class="info-area">
      <text-info
        class="text-info mb-2"
        text="ReferencesTextInfo"
      />
      <span class="char-counter">{{form.references.length}}/2000</span>
    </div>

    <div class="form__footer">
      <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
      <button-default :onClick="checkForm" class="button">{{$t('saveAndContinue')}}</button-default>
    </div>
  </form>

  <feedback-warning v-if="errors.length" :text="textWarning" :onClick="resetErrors" color="#ff9800" />
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  InputDefault, FeedbackWarning, TextWarning, TextInfo, ButtonDefault
} from '@/components/atoms'

export default {
  name: 'FormSelectionResearchPlan',
  components: {
    InputDefault, FeedbackWarning, TextWarning, TextInfo, ButtonDefault
  },
  data() {
    return {
      form: {
        theme: '',
        justification: '',
        problem: '',
        hypothesis: '',
        objectives: '',
        object: '',
        literature_review: '',
        methodology: '',
        schedule: '',
        data_analysis: '',
        references: ''
      },
      errors: [],
      textWarning: 'warningAllRequiredFieldsAreObligatory'
    }
  },
  created() {
    const formData = this.$store.getters.projectSelection
    if (formData.research_plan) {
      this.form = {
        ...this.projectSelection.research_plan,
        objectives: this.projectSelection.research_plan.objective
      }
    }
  },
  computed: {
    ...mapGetters({
      projectSelection: 'projectSelection'
    })
  },
  methods: {
    resetErrors() {
      this.errors = []
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkForm() {
      for (const key in this.form) {
        if (!this.form[key].length && key !== 'hypothesis') {
          this.errors.push(key)
        }
      }
      if (!this.errors.length) {
        this.saveForm()
      }
    },
    saveForm() {
      try {
        const formData = this.$store.getters.projectSelection
        const research_plan = { ...this.form, objective: this.form.objectives }
        delete research_plan.objectives
        const newData = {
          ...formData,
          research_plan
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem('projectSelectionData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 600px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .label {
    color: $almostBlack;
    display: flex;
    font-weight: 600;
    text-align: left;
  }

  .textarea {
    margin-top: 20px;
    width: 100%;

    textarea {
      border: 1px solid #CDCBCB;
      box-sizing: border-box;
      border-radius: 10px;
      min-height: 126px;
      padding: 10px;
      resize: vertical;

      float: left;
      width: 100%;
      max-width: 570px;

      &::placeholder {
        color: #afaeae;
      }
    }
  }

  .info-area{
    display: flex;
    max-width: 570px;

    .text-info {
      align-items: flex-start;
      display: flex;
      justify-content: flex-start;
      margin-top: 8px;
      width: 85%;

      p {
        font-size: 14px;
        line-height: 18px;
        color: $almostBlack;
        margin-left: 6px;
        max-width: 522px;
      }
    }
    .char-counter{
      margin-top: 7px;
      margin-left: 10%;
      width: 71.2px;
      font-size: 14px;
      line-height: 18px;
      color: #AFAEAE;
    }
  }

  .warning{
    label{
      color: $attention;
    }
    textarea{
      border-color: $attention;
    }
  }
}
</style>
