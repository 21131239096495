<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t("schoolInstitutionData")}} -</span>
        <span class="form__title__complement">{{$t('contacts')}}</span>
      </div>
      <div class="form__info">
        <text-warning
          :text="'considerAllFieldObligatory'"
        />
        <text-warning
          :text="`contactOneInformation`"
        />
      </div>
      <div v-for="(contact, index) in form.contacts" :key="index">
        <div class="form__contact__header">
          {{` ${$t('contact')} ${index === 0 ? '1 ' + $t('main') + ' ' + $t('mainContactInfo')  : index + 1}`}}
          <ButtonIconDefault
            :onClick="showModal"
            url="bin.svg"
            alt="Ícone de caneta da tela pré-cadastro"
          >
            <img v-if="index !== 0" class="icon-back" src="@/assets/bin.svg" alt="Back" />
          </ButtonIconDefault>
        </div>
        <modal-send-form
            title="areYouSureTitle"
            :onClick="() => removeNewContact(index)"
            @closeModal="() => toggleModal('modal')"
            :modal="modal"
        >
            <div class="text">
              <p style="margin-top: 50px">{{$t('removeContact')}}</p>
            </div>
        </modal-send-form>
        <input-default
          :class="[{warning: checkErrorIfContains('contact_name' + index)}, 'mb-7']"
          label= name
          id="name"
          placeholder= contactNamePlaceholder
          type="text"
          max=100
          v-model="form.contacts[index].contact_name"
          @input="resetErrors"
        />
        <input-default
          :class="[{warning: checkErrorIfContains('email' + index)}, 'mb-7']"
          :label= "index === 0 ? $t('bestEmail') : $t('email')"
          id="email"
          placeholder= inputContactEmail
          type="text"
          max=100
          v-model="form.contacts[index].email"
          @input="resetErrors"
        />
        <input-default
          :class="[{warning: checkErrorIfContains('phone' + index)}, 'mb-7']"
          label= contactPhone
          id="phone"
          placeholder="(00) 00000-0000"
          type="text"
          v-model="form.contacts[index].phone"
          @input="resetErrors"
        />
        <input-default
          :class="[{warning: checkErrorIfContains('office' + index)}, 'mb-7']"
          label= office
          id="office"
          placeholder= officePlaceholder
          type="text"
          max=100
          v-model="form.contacts[index].office"
          @input="resetErrors"
        />
      </div>
      <div class="form__add__contact__button__container">
        <button-default
          :disabled="this.form.contacts.length === 3"
          :onClick="addNewContact"
          class="button"
          :style="{cursor: this.form.contacts.length !== 3 ? 'pointer' : 'no-drop',
            'background-color': this.form.contacts.length !== 3 ? '#1B778B' : '#4c4c4e'}"
        >
          <div class="form__add__contact__button">
            {{$t('addContact')}}
            <img class="icon-back" src="@/assets/add-icon.svg" alt="Back" />
          </div>
        </button-default>
      </div>
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  ButtonIconDefault,
  InputMasked
} from '@/components/atoms'
import { ModalSendForm } from '@/components/organisms/Modals'

export default {
  name: 'FormSchoolDataContacts',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    ButtonIconDefault,
    InputMasked,
    ModalSendForm
  },
  props: ['element'],
  data() {
    return {
      modal: false,
      form: {
        contacts: [
          {
            contact_name: '',
            email: '',
            phone: '',
            office: '',
            main: true
          }
        ]
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.fair_contacts) {
      this.form.contacts[0].contact_name = this.fairAffiliation.fair_contacts[0].contact_name
      this.form.contacts[0].email = this.fairAffiliation.fair_contacts[0].email
      this.form.contacts[0].phone = this.fairAffiliation.fair_contacts[0].phone
      this.form.contacts[0].office = this.fairAffiliation.fair_contacts[0].office

      if (this.fairAffiliation.fair_contacts.length > 1) {
        Array.from(Array(this.fairAffiliation.fair_contacts.length - 1).keys()).forEach((item) => {
          this.form.contacts.push({
            contact_name: '',
            email: '',
            phone: '',
            office: '',
            main: false
          })
        })
        this.form.contacts[1].contact_name = this.fairAffiliation.fair_contacts[1].contact_name
        this.form.contacts[1].email = this.fairAffiliation.fair_contacts[1].email
        this.form.contacts[1].phone = this.fairAffiliation.fair_contacts[1].phone
        this.form.contacts[1].office = this.fairAffiliation.fair_contacts[1].office

        if (this.fairAffiliation.fair_contacts.length === 3) {
          this.form.contacts[2].contact_name = this.fairAffiliation.fair_contacts[2].contact_name
          this.form.contacts[2].email = this.fairAffiliation.fair_contacts[2].email
          this.form.contacts[2].phone = this.fairAffiliation.fair_contacts[2].phone
          this.form.contacts[2].office = this.fairAffiliation.fair_contacts[2].office
        }
      }
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      const emails = []

      this.resetErrors()
      this.form.contacts.map((item, index) => {
        for (const key in item) {
          if (item[key].length == 0) {
            this.errors.push(`${ key }${ index }`)
            this.textWarning = 'warningAllFieldsAreObligatory'
          }
        }
      })

      if (!this.errors.length) {
        this.form.contacts.forEach((value, index) => {
          if (!this.validateEmail(value.email, index)) {
            this.errors.push(`email${ index }`)
            this.textWarning = 'invalidEmail'
          }
          emails.push(value.email)
        })

        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'emailsMustBeDifferent'
          return false
        }
      }
      !this.errors.length ? this.saveForm() : null
    },
    saveForm() {
      this.form.contacts = this.form.contacts.map((item) => ({
        ...item,
        phone: parseInt(String(item.phone).replace(/\D/g, ''))
      }))
      try {
        const localForm = { ...this.form }
        let screen
        const formData = this.$store.getters.fairAffiliation
        const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
        if (currentScreen < 3) {
          screen = 3
        } else {
          screen = currentScreen
        }
        const newData = {
          ...formData,
          fair_contacts: localForm.contacts,
          currentScreen: screen
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    showModal() {
      this.toggleModal('modal')
    },
    validateEmail(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.contacts[index].email = onlyEmail
      // eslint-disable-next-line
      const exp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (onlyEmail.search(exp) !== -1) {
        return true
      }
      return false
    },
    addNewContact() {
      this.form.contacts.push({
        contact_name: '',
        email: '',
        phone: '',
        office: '',
        main: false
      })
    },
    removeNewContact(index) {
      this.toggleModal('modal')
      this.form.contacts.splice(index, 1)
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }

    .form__title__complement {
      font-weight: 400;
      margin-left: 11px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__contact__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $primary;
    color: $primary;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__add__contact__button__container {
    display: flex;
    justify-content: center;
  }

  &__add__contact__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }

  .button {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
