import { render, staticRenderFns } from "./ButtonIconDefault.vue?vue&type=template&id=0381be3e&scoped=true&"
import script from "./ButtonIconDefault.vue?vue&type=script&lang=js&"
export * from "./ButtonIconDefault.vue?vue&type=script&lang=js&"
import style0 from "./ButtonIconDefault.vue?vue&type=style&index=0&id=0381be3e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0381be3e",
  null
  
)

export default component.exports