<template>
  <div class="home__wrapper">
    <main class="top">
      <div class="left">
        <h1>{{$t('welcome')}}</h1>
        <p class="normal">{{$t('welcomeHomeHeader1')}} <strong>Mostratec</strong>{{$t('welcomeHomeHeader2')}}</p>
        <p class="information">
          {{$t('welcomeHomePaymentInfo1')}}
          <br />{{$t('welcomeHomePaymentInfo2')}}
        </p>
      </div>
      <div class="right">
        <img src="@/assets/welcome-card.png" alt />
      </div>
    </main>

    <section class="bottom">
      <div class="card__left card">
        <div class="image">
          <img src="@/assets/afiliation-card.png" alt />
        </div>
        <div class="text">
          <h3>{{$t('fairAffiliation')}}</h3>
          <p v-if="currentDate">{{$t('welcomeHomeFairAffiliationText')}}</p>
          <!-- Chamado 652 - texto na página principal>
          <p v-else><span class="over">{{$t('affiliationPeriodClosed')}}</span>
          -->
          <br>{{$t('ifYouMissedTheDeadline')}}
          </p>
          <div class="link">
            <router-link
              class="button"
              to="/register"
              v-if="currentDate">{{$t('subscribeNow')}}</router-link>
          </div>
        </div>
      </div>

<!-- incrições não iniciadas -->
      <div
        class="card__right card disabled"
        v-if="projectNotInit && projectFairNotInit
          || projectEnd && projectFairNotInit
          || projectNotInit && projectFairEnd"
      >
        <div class="image">
          <img src="@/assets/mirage-science.png" alt />
        </div>
        <div class="text">
          <h3>{{$t('projectRegistration')}}</h3>
          <p>
            {{$t('welcomeHomeProjectRegistrationText1')}}
            <strong> {{$t('affiliatedFairSingle')}}</strong>
              {{$t('welcomeHomeProjectRegistrationText2')}}
            <strong>{{$t('selectionProcess')}}</strong>{{$t('welcomeHomeProjectRegistrationText3')}}
          </p>
        </div>
      </div>

<!-- incrições abertas -->
      <div class="card__right card" v-if="projectInit || projectFairInit">
        <div class="image">
          <img src="@/assets/mirage-science.png" alt />
        </div>
        <div class="text">
          <h3>{{$t('projectRegistration')}}</h3>
          <p>
            {{$t('welcomeHomeProjectRegistrationText1')}}
            <strong> {{$t('affiliatedFairSingle')}}</strong> {{$t('welcomeHomeProjectRegistrationText2')}}
            <strong>{{$t('selectionProcess')}}:</strong> {{$t('isTimeToRegister')}}
          </p>
          <div class="link">
            <router-link class="button" to="/projects">{{$t('subscribeNow')}}</router-link>
          </div>
        </div>
      </div>

<!-- incrições encerradas -->
        <div class="card__right card" v-if="projectEnd && projectFairEnd">
          <div class="image disabled">
            <img src="@/assets/mirage-science.png" alt />
          </div>
          <div class="text">
            <h3>{{$t('projectRegistration')}}</h3>
            <br>{{$t('caseYouMissedTheOpportunity')}}
            <div class="over_deadline">
              <!-- Chamado 652 - texto na página principal
              <img :src="require(`@/assets/information-circle-red.png`)" alt="informação">
              <span class="over">{{$t('registrationClosed')}}</span>
              -->
          </div>
          </div>
        </div>
    </section>

    <footer>
      <img src="@/assets/logo.png" alt="Logo mostratec" />
      <p class="information">
        {{$t('moreInformation')}}
        <a href="https://www.mostratec.com.br/" target="_blank">www.mostratec.com.br</a>
      </p>
      <p class="information">
        {{$t('illustrationsBy')}}
        <a href="https://www.icons8.com" target="_blank">www.icons8.com</a>
        <input type="hidden" name="version" value="2.0.28">
      </p>
    </footer>
  </div>

</template>

<script>
export default {
  name: 'WelcomeHome',
  props: ['projectData'],
  data: () => ({
    currentDate: undefined,
    fairRegisterEnd: undefined,
    fairRegisterInit: undefined,
    projectFairInitDate: undefined,
    projectFairEndDate: undefined,
    projectSelectionInitDate: undefined,
    projectSelectionEndDate: undefined,
    projectRegistrationClosed: false,
    projectRegistrationStarted: false,
    projectInit: false,
    projectEnd: false,
    projectFairInit: false,
    projectFairEnd: false,
    projectNotInit: false,
    projectFairNotInit: false,
    projectSelectionRegistrationClosed: false,
    projectSelectionRegistrationNotStarted: false
  }),
  mounted() {
    this.returnDate()
  },
  methods: {
    async returnDate() {
      const { data } = await this.$http.get('/current-mostratec')
      this.fairRegisterEnd = new Date(data.fair_register_end)
      this.fairRegisterInit = new Date(data.fair_register_init)
      this.projectFairInitDate = new Date(data.project_date_init)
      this.projectFairEndDate = new Date(data.project_date_end)
      this.projectSelectionInitDate = new Date(data.project_selection_date_init)
      this.projectSelectionEndDate = new Date(data.project_selection_date_end)
      this.projectSelectionInitDate.setHours(0, 1, 1)
      this.projectFairInitDate.setHours(0, 1, 1)
      this.projectFairEndDate.setHours(23, 59, 59)
      this.projectSelectionEndDate.setHours(23, 59, 59)
      this.compareDate()
    },
    compareDate() {
      const date = new Date().getTime()
      const registerLastDay = this.fairRegisterEnd.setHours(23, 59, 59)
      const lastDay = registerLastDay
      const firstDay = this.fairRegisterInit.setHours(0, 1, 1)

      if (Number(date) > Number(lastDay) || Number(date) < Number(firstDay)) {
        this.currentDate = false
      } else {
        this.currentDate = true
      }
      this.compareProjectsRegistrationDate()
    },
    compareProjectsRegistrationDate() {
      const actualDay = new Date().getTime()
      const projectFairDateInit = this.projectFairInitDate.getTime()
      const projectFairDateEnd = this.projectFairEndDate.getTime()
      const projectSelectionDateInit = this.projectSelectionInitDate.getTime()
      const projectSelectionDateEnd = this.projectSelectionEndDate.getTime()

      this.projectInit = actualDay >= projectSelectionDateInit && actualDay <= projectSelectionDateEnd
      this.projectFairInit = actualDay >= projectFairDateInit && actualDay <= projectFairDateEnd
      this.projectEnd = actualDay > projectSelectionDateEnd
      this.projectFairEnd = actualDay > projectFairDateEnd
      this.projectNotInit = actualDay < projectSelectionDateInit
      this.projectFairNotInit = actualDay < projectFairDateInit
    },

    sendDateStatus() {
      const projectData = [
        { type: 'fair', registration_closed: this.projectFairRegistrationClosed },
        { type: 'selection', registration_closed: this.projectSelectionRegistrationClosed }
      ]
      this.$emit('projectData', projectData)
    }
  }
}
</script>

<style lang="scss" scoped>
.over {
  color: #F9537B;
  font-weight: 700;
}
.home__wrapper {
  position: relative;
  margin-bottom: -24vh;
  top: -24vh;
}

.top {
  align-items: flex-end;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 40px 40px;
  display: flex;
  justify-content: center;
  height: 480px;
  width: 100vw;

  .left {
    margin-bottom: 60px;
    max-width: 400px;

    h1 {
      font-family: Mulish;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 45px;
      letter-spacing: 1px;
      color: #0e5671;
      margin: 10px 0px;
    }

    p.normal {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #0e5671;
      margin-top: 10px;
    }

    p.information {
      border-left: 2px solid #1b778b;
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #0e5671;
      padding-left: 10px;
      margin-top: 24px;
    }
  }

  .right {
    height: 342px;
  }
}

.bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  justify-content: center;
  margin: 0 auto;
  padding: 30px 5%;
  max-width: 1200px;
}

.card {
  align-items: center;
  display: flex;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 0px;
  cursor: inherit;

  .image {
    align-items: center;
    background: #ffffff;
    border-radius: 30px 50% 50% 30px;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    height: 100%;
    margin-right: 10px;
    padding: 0px 30px 0px 15px;
  }

  .text {
    display: grid;
    grid-template-rows: auto auto 1fr;
    height: 100%;
    padding: 20px 20px;

    h3 {
      font-family: Mulish;
      font-weight: bold;
      font-size: 20px;
      color: $primaryDark;
    }

    p {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $primaryDark;
      margin: 15px 0px;
    }

    .link {
      display: flex;
      justify-content: flex-end;
    }

    .over_deadline{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      align-self: flex-end;

      span{
        margin-left: 4px;
      }
    }

    .button {
      align-items: center;
      align-self: flex-end;
      border: 1px solid $primary;
      border-radius: 10px;
      display: flex;
      filter: drop-shadow(0px 4px 28px rgba(0, 0, 0, 0.2));
      justify-content: center;
      height: 28px;
      width: 145px;

      color: $primary;
      font-family: Mulish;
      font-weight: bold;
      transition: all 0.3s;

      &:hover {
        background-color: $attention;
        border-color: $attention;
        color: $white;
      }
    }
  }
}

.disabled {
  filter: grayscale(1);
}

footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 30px;

  img {
    margin-bottom: 10px;
    width: 135px;
  }

  .information {
    text-align: center !important;
    margin-bottom: 10px;

    font-family: Mulish;
    font-size: 16px;
    color: $primaryDark;

    &:first-of-type {
      border-bottom: 1px solid #afaeae;
    }

    a {
      &:hover {
        color: $attention;
      }
    }
  }
}
</style>
