<template>
  <p class="content" :style="{ color }">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'BigNumber',
  props: {
    color: {
      type: String,
      required: false,
      default: '#686868'
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  margin-bottom: -2px;
  font-size: 36px;
  max-width: 100px;
}
</style>
