<template>
    <div class="upload-container">
      <div>
        <h2>Upload de Pagamento Mercado Pago</h2>
        <h2>Requer Arquivo no formato XLSX</h2>
      </div>
      <div>
        <form @submit.prevent="handleUpload">    
            <div class="form-group">
                <label for="file">Arquivo CSV:</label>
                <input type="file" @change="handleFileChange" required />
            </div>
    
            <button type="submit">Enviar</button>
        </form>
        </div>
  
      <div v-if="response" class="response">
        <div>
    <h1>Resultado do Processamento</h1>
    <p>{{ response.message }}</p>
        
          <table border="0" style="width: 900px; font-size: 80%;">
          <tbody  v-for="(dado, index) in response.dados" :key="index">
            <tr class="tr_title">
            <th colspan="8" style="text-align: left;">
                <a :href="`/project-details-accreditation/${dado['Código de referência (external_reference)']}`" target="_blank">
                    <strong style="font-size:140%; color:darkgreen;"><u>Projeto ID {{ dado['Código de referência (external_reference)'] }}</u></strong>
                </a>
                 - {{ dado['Descrição da operação (reason)'] }}</th>
            </tr>
            <tr class="tr_data">
            <td>Status: <strong  style="font-size:120%; color:darkgreen;">{{ dado['Status da operação (status)'] }}</strong><br/>
                Detalhe: <strong>{{ dado['Detalhe do status da operação (status_detail)'] }}</strong><br/>
                TipOp: {{ dado['Tipo de operação (operation_type)'] }}</td>
            <td>Compra: {{ dado['Data da compra (date_created)'] }}<br/>
                Credito: <strong>{{ dado['Data de creditação (date_approved)'] || 'N/A' }}</strong></td>
            <td>Valor: <strong>{{ dado['Valor do produto (transaction_amount)'] }}</strong><br/>
                Tarifa: {{ dado['Tarifa do Mercado Pago (mercadopago_fee)'] }}<br/>
                Recebido: {{ dado['Valor total recebido (net_received_amount)'] }}</td>
            <td>Meio Pgto: <strong>{{ dado['Meio de pagamento (payment_type)'] }}</strong><br/>
                MP_ID: {{ dado['Número da transação do Mercado Pago (operation_id)'] }}</td>
            </tr>
            </tbody>
        </table>
    
  </div>


        <!-- h3>Resultado do Processamento:</h3 -->
        <!-- pre>{{ response }}</pre -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        mostratec_id: 0,
        file: null,
        response: null,
      };
    },
    methods: {
      handleFileChange(event) {
        this.file = event.target.files[0];
      },
      async handleUpload() {
        const formData = new FormData();
        formData.append("mostratec_id", this.mostratec_id);
        formData.append("file", this.file);
        
        // Log dos dados enviados para debug
        console.log('Dados enviados:', { mostratec_id: this.mostratec_id, file: this.file });

        try {
            // Fazendo o POST com `this.$http.post` para enviar o arquivo e `mostratec_id`
            const response = await this.$http.post(`/processaMP/execute/${this.mostratec_id}`, formData);
            this.response = response.data;
        } catch (error) {
            //console.error("Erro ao enviar o arquivo:", error);
            if (error.response) {
                console.error("Erro ao enviar o arquivo:", error.response.data);
            } else {
                console.error("Erro ao enviar o arquivo:", error.message);
            }
        }
      },
    },
  };
  </script>
  
  <style scoped>
.upload-container {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.form-group {
  margin-bottom: 15px;
}
.response {
  margin-top: 20px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 5px;
}
.tr_title{
    background-color: rgb(240, 230, 90);
}
.tr_data{
    background-color: rgb(255, 255, 255);

}
</style>
  