import { render, staticRenderFns } from "./AccreditationTemplate.vue?vue&type=template&id=37effb6e&scoped=true&"
import script from "./AccreditationTemplate.vue?vue&type=script&lang=js&"
export * from "./AccreditationTemplate.vue?vue&type=script&lang=js&"
import style0 from "./AccreditationTemplate.vue?vue&type=style&index=0&id=37effb6e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37effb6e",
  null
  
)

export default component.exports