<template>
  <div>
    <div class="separator">
      <h3>{{ $t("presentationVideo") }}</h3>
      <span>{{ $t("youCanADDTheVideo") }}.</span>
    </div>
    <input-default
      :class="{ warning: isURLInvalid }"
      v-model="inputLink"
      :label="$t('projectVideoLink')"
      placeholder="presentationLink"
      @blur="validateUrl(inputLink)"
      @input="
        () => {
          this.resetErrors();
          this.$emit('inputLink', this.inputLink);
        }
      "
      :id="linkId"
    />
  </div>
</template>

<script>
import {
  DragFile,
  TextInfo,
  InputDefault,
  FeedbackWarning
} from '@/components/atoms'

export default {
  name: 'VideoLinkUploadSection',
  components: {
    DragFile,
    TextInfo,
    InputDefault,
    FeedbackWarning
  },
  data() {
    return {
      inputLink: '',
      isURLInvalid: false
    }
  },
  props: {
    attention: {
      type: Boolean,
      default: false
    },
    limitSize: {
      type: Number,
      default: 300000000
    },
    limitDate: {
      type: Date,
      default: null
    },
    linkId: {
      type: String,
      default: 'video-link'
    },
    default: {
      type: String,
      default: ''
    },
    actualData: Object,
    formatEdition: String
  },
  watch: {
    default(value) {
      this.inputLink = value
    }
  },
  methods: {
    emitVideo(e) {
      this.$emit('upload', e)
    },
    validateUrl(url) {
      if (url.length && !/^(ftp|http|https):\/\/[^ "]+$/.test(url)) {
        this.isURLInvalid = true
        this.$emit('urlValidation', false)
        return false
      }
      this.$emit('urlValidation', true)
      return true
    },
    resetErrors() {
      this.isURLInvalid = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  font-family: Mulish;
}
.separator {
  border-bottom: 1px solid $primary;
  margin-top: 20px;
  margin-bottom: 20px;
  & > h3 {
    color: $primaryDark;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
    margin-bottom: 4px;
  }
}
.grey-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  margin: 30px 0px;
  color: #afaeae;
}

.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;

  &.warning {
    color: $attention !important;
  }
}
</style>
