<template>
  <div>
  <aside>
  <div :class="[{wrapper__analisisInfo_after: this.aproved === false},'wrapper__analisis-info']">
    <div class="revision_box">
      <h3 class="revision_title">
        {{$t('projectsRevision')}}
      </h3>
      <button-default  v-if="this.projectStatus && this.projectStatus.slug === 'aguardando-revisao'" class="start_button" :onClick="startReview" >{{$t('startReview')}}</button-default>
    </div>
    <div class="box_info" >
      <div class="box_info__content">
        <h1 class="box_info__title">Você considera o projeto aprovado para participação na mostratec?</h1>
        <p class="box_info__text">
          Caso aprovado, será permitido que os estudantes inscritos paguem a taxa de participação.
          Do contrário, insira uma justificativa para a reprovação nos campos de pareceres.
        </p>
      </div>
      <div class="box_info__confirmation">
        <button-default
          :class="[
            {box_info__button_accept: this.aproved === true},
            {box_info__button: this.aproved === null},
            {box_info__button_disabled: this.aproved === false},
            {disabled: disabled}
          ]"
          :disabled="disabled"
          :onClick="() => aproval(true)"
        >
          <icon-default
            v-if="this.aproved !== null"
            class="box_info__icon"
            :url="String(acceptIcon)"
            alt="Ícone de pdf"
          />
          <img v-else-if="this.aproved === null"  src="@/assets/button_accept.png" alt class="box_info__icon"/>
          Sim, inscrição aprovada!
        </button-default>

        <button-default
          :class="[
            {box_info__button_reject: this.aproved === false},
            {box_info__button: this.aproved === null},
            {box_info__button_disabled: this.aproved === true},
            {disabled: disabled}
          ]"
          :disabled="disabled"
          :onClick="() => aproval(false)"
        >
        <icon-default
          v-if="this.aproved !== null"
          class="box_info__icon"
          :url="String(rejectIcon)"
          alt="Ícone de pdf"
        />
          <img v-else-if="this.aproved === null" src="@/assets/button_reject.png" alt class="box_info__icon"/>
          Não, inscrição reprovada!
        </button-default>
      </div>
      <div class="box_info__step">
        <input type="checkbox" name="step" id="step" class="box_info__input" v-model="substitute" :disabled="disabled">
        <label for="step" class="box_info__label">Este projeto será suplente</label>
      </div>
    </div>

    <div class="main_info">
      <div :class="[{input_after: this.aproved === false}, 'input']">
        <input-score
          required
          :class="[{warning: checkErrorIfContains('score')}, {disabled: disabled}]"
          :disabled="disabled"
          label="Nota do projeto"
          id="score"
          placeholder="Dê uma nota de 0 a 10"
          type="text"
          v-model="score"
          @input="resetErrors"
          :mask="['##']"
        />
        <input-revisioner
          :class="['mb-7', 'ml-7',{warning: checkErrorIfContains('name')},{disabled: disabled}]"
          :disabled="disabled"
          label="Nome do responsável pela revisão"
          id="name"
          placeholder="Nome Sobrenome"
          type="text"
          v-model="name"
          @input="resetErrors"
        />
      </div>
      <div v-if="this.aproved === false" class="input_aprove">
        <input-text-area
          :class="[{warning: checkErrorIfContains('project_feedback')}, 'textarea']"
          :disabled="disabled"
          label="projectOpinion"
          :allText="project_feedback"
          v-model="project_feedback"
          @input="project_feedback = $event"
          @change="resetErrors"
          :placeholder="$t('considerations')"
          :textMax="2000"
          :info="$t('opinionToStudents')"
        />
      </div>
      <div class="mb-7 revision_title_wrapper">
        <h1 class="revision__title">Revisão dos arquivos inscritos</h1>
        <p class="revision__text">
          ==> {{$t('useCheckbox')}}
        </p> 
      </div>

      <div  class="files_container_wrapper">
        <div v-for="(item, index) in files" :key="index" class="files_container">
          <div v-if="item.file !== 'pdf'" class="files">
            <div
              class="icon_wrapper"
              disabled
              @click="downloadFile(item.file)"
              @mouseenter="enterModel(`${displayManager(item.file, false)}Hover`)"
              @mouseleave="leaveModel(`${displayManager(item.file, false)}Hover`)"
            >
              <icon-default
                :url="displayManager(item.file, true)"
                alt="Ícone de pdf"
              />
            </div>
            <div :class="[{warning: checkErrorIfContains(item.file)}, 'files__name']">
              <input
                type="checkbox"
                :disabled="disabled"
                name="step"
                id="step"
                :class="['box_info__input']"
                v-model="uploads[displayManager(item.file, false)]"
                @change="resetErrors"
              />
              <label
                for="step"
                :class="[{disabled: disabled}, 'box_info__label']"
              >
                {{$t(displayManager(item.file, false))}}
              </label>
               <label v-if="item.file === 'banner'" :class="[{disabled: disabled}, 'files__extension']">
                {{item.url}}
              </label>
              <label v-else :class="[{disabled: disabled}, 'files__extension']">
                {{item.name}}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button-default  v-if="this.projectStatus.slug === 'em-revisao'" class="finish_button" :onClick="checkForm" :disabled="disabled">{{$t('finishReview')}}</button-default>
      </div>

    </div>
    </div>
    <footer-send-email v-if="role !='crc'"
      :projectId="[projectInfo.id]"
      :status="status"
      :sentEmail="projectInfo.sent_reply"
      :paymentType="projectInfo.payment_type"
      @att-data="attStatus"
    />
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
    <feedback-warning
      v-if="revisionFinished"
      :text="textWarning"
      :onClick="toggleFinished"
      color="#33B850"
    />
  </aside>
  </div>
</template>

<script>
import {
  ButtonDefault, InputScore, InputRevisioner, IconDefault, InputTextArea, FeedbackWarning
} from '@/components/atoms'
import { FooterSendEmail } from '@/components/organisms'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectsRevision',
  components: {
    ButtonDefault,
    InputScore,
    InputRevisioner,
    IconDefault,
    InputTextArea,
    FeedbackWarning,
    FooterSendEmail
  },
  data() {
    return {
      disabled: false,
      fullStatus: [],
      notRequiredFiles: ['pdf'],
      role: '',
      uploads: {
        revision: false,
        revisionHover: false,
        continuity: false,
        continuityHover: false,
        subst: false,
        tissues: false,
        tissuesHover: false,
        bios: false,
        biosHover: false,
        humans: false,
        humansHover: false,
        banner: false,
        bannerHover: false,
        presentation: false,
        presentationHover: false,
        vertebrates: false,
        vertebratesHover: false,
        institution: false,
        researchInstituitionIndustry: false,
        researchInstituitionIndustryHover: false,
        scientist: false,
        scientistHover: false,
        riscs: false,
        riscsHover: false,
        video: false,
        videoHover: false,
        presentationLink: false,
        presentationLinkHover: false
      },
      revisionFinished: false,
      files: [],
      aproved: null,
      substitute: false,
      score: null,
      name: null,
      project_feedback: '',
      textWarning: '',
      errors: []
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    },
    internal_feedback: {
      type: String,
      required: false
    },
    project_penalty: {
      type: String,
      required: false
    },
    status: {
      type: String,
      required: false
    }
  },
  async mounted() {
    this.fullStatus = await this.getStatus()
    if (Object.keys(this.projectInfo).length) {
      this.loadFiles()
      this.loadRevisionInfos()
      this.disabled = this.checkIfDisabled()
    }
    this.role = localStorage.getItem('role')
  },
  computed: {
    ...mapGetters({
      projectStatus: 'projectsStatusChange'
    })
  },
  watch: {
    'projectInfo.project_feedback': function () {
      this.project_feedback = this.projectInfo.project_feedback
    },
    'projectInfo.files': function () {
      this.loadFiles()
    },
    async projectInfo() {
      if (!this.fullStatus) { this.fullStatus = await this.getStatus() }
      this.loadRevisionInfos()
    },
    projectStatus() {
      this.disabled = this.checkIfDisabled()
    }
  },
  methods: {
    displayManager(value, isIcon) {
      const display = {
        banner: isIcon ? this.bannerIcon() : 'banner',
        biological: isIcon ? this.biologicalIcon() : 'bios',
        cientistQualified: isIcon ? this.scientistIcon() : 'scientist',
        continuousProject: isIcon ? this.continuityIcon() : 'continuity',
        humans: isIcon ? this.humansIcon() : 'humans',
        presentWork: isIcon ? this.presentationIcon() : 'rulesOfConduct',
        researchInstitution: isIcon ? this.institutionIcon() : 'researchInstituitionIndustry',
        revision: isIcon ? this.revisionIcon() : 'revision',
        risksAnalysis: isIcon ? this.risksAnalysisIcon() : 'riscs',
        tissueSubstances: isIcon ? this.substIcon() : 'tissues',
        vertebrateAnimals: isIcon ? this.vertebratesIcon() : 'vertebrates',
        video: isIcon ? this.videoIcon() : 'video',
        presentationLink: isIcon ? this.presentationLinkIcon() : 'presentationLink'
      }
      return display[value]
    },
    loadFiles() {
      if (this.files && !this.files.length) {
        Object.entries(this.projectInfo.files).forEach(([key, value]) => {
          if (value.name) {
            this.files.push({
              file: key,
              name: value.name,
              url: value.url
            })
          }
        })
        if (this.projectInfo.presentation_link?.length) {
          this.files.push({
            file: 'presentationLink',
            name: this.projectInfo.presentation_link,
            url: this.projectInfo.presentation_link
          })
        }
      }
    },
    loadRevisionInfos() {
      if (this.status === 'revisao-aceita' || this.status === 'aguardando-pagamento') {
        this.aproved = true
      } else if (this.status === 'revisao-negada' || this.status === 'aguardando-pagamento') {
        this.aproved = false
      } else {
        this.aproved = null
      }
      this.substitute = this.projectInfo.substitute
      this.score = this.projectInfo.project_grade
      this.name = this.projectInfo.revision_responsible
      this.project_feedback = this.projectInfo.project_feedback

      if (this.projectInfo.internal_status.id > this.slugToID('em-revisao')
      && this.slugToID('em-revisao') !== null) {
        Object.entries(this.uploads).forEach(([key]) => {
          if (!String(key).includes('Hover')) {
            this.uploads[key] = true
          }
        })
      }
    },
    rejectIcon() {
      return this.aproved ? 'grey_reject.png' : 'white_reject.png'
    },
    acceptIcon() {
      return this.aproved ? 'white_accept.png' : 'grey_accept.png'
    },
    revisionIcon() {
      if (this.uploads.revisionHover) {
        return this.uploads.revisionHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.revision ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    biologicalIcon() {
      if (this.uploads.biosHover) {
        return this.uploads.biosHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.bios ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    continuityIcon() {
      if (this.uploads.continuityHover) {
        return this.uploads.continuityHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.continuity ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    humansIcon() {
      if (this.uploads.humansHover) {
        return this.uploads.humansHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.humans ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    risksAnalysisIcon() {
      if (this.uploads.riscsHover) {
        return this.uploads.riscsHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.riscs ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    substIcon() {
      if (this.uploads.tissuesHover) {
        return this.uploads.tissuesHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.subst ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    risksIcon() {
      if (this.uploads.riscsHover) {
        return this.uploads.riscsHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.riscs ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    bannerIcon() {
      if (this.uploads.bannerHover) {
        return this.uploads.bannerHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.banner ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    presentationIcon() {
      if (this.uploads.presentationHover) {
        return this.uploads.presentationHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.presentation ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    vertebratesIcon() {
      if (this.uploads.vertebratesHover) {
        return this.uploads.vertebratesHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.vertebrates ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    dangerousIcon() {
      if (this.uploads.dangerousHover) {
        return this.uploads.dangerousHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.dangerous ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    institutionIcon() {
      if (this.uploads.researchInstituitionIndustryHover) {
        return this.uploads.researchInstituitionIndustryHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.institution ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    scientistIcon() {
      if (this.uploads.scientistHover) {
        return this.uploads.scientistHover ? 'download.svg' : 'grey_pdf.png'
      } return this.uploads.scientist ? 'pdf-icon.png' : 'grey_pdf.png'
    },
    videoIcon() {
      if (this.uploads.videoHover) {
        return this.uploads.videoHover ? 'download.svg' : 'grey_mp4.png'
      } return this.uploads.video ? 'mp4-icon.png' : 'grey_mp4.png'
    },
    presentationLinkIcon() {
      if (this.uploads.presentationLinkHover) {
        return this.uploads.presentationLinkHover ? 'play_blue.svg' : 'play_gray.svg'
      } return this.uploads.presentationLink ? 'play.svg' : 'play_gray.svg'
    },
    downloadFile(file) {
      const myFile = this.files.find((item) => item.file === file)
      window.open(myFile.url)
    },
    async getStatus() {
      const { data } = await this.$http.get('/status/project?statusType=projeto')
      return data.data
    },
    async attStatus() {
      this.fullStatus = await this.getStatus()
      window.location.reload()
    },
    toggleModel(model) {
      this.uploads[model] = !this.uploads[model]
    },
    enterModel(model) {
      this.uploads[model] = true
    },
    leaveModel(model) {
      this.uploads[model] = false
    },
    resetErrors() {
      this.errors = []
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    checkIfDisabled() {
      return this.projectStatus ? (this.projectStatus.slug !== 'em-revisao') : false
    },
    checkForm() {
      if (this.projectStatus.slug !== 'em-revisao' || this.aproved === null) {
        this.textWarning = 'Necessário aceitar ou negar a revisão!'
        this.errors.push(1)
        return false
      }
      if (!this.score || !this.name) {
        this.textWarning = 'Necessário informar a nota e o responsável pela revisão do projeto.'
        this.errors.push('score')
        this.errors.push('name')
        return false
      }
      if (!this.project_feedback && !this.aproved) {
        this.textWarning = 'Em caso de reprovação, informe o parecer do projeto.'
        this.errors.push('project_feedback')
        return false
      }
      for (const item of this.files) {
        const nameInUploads = this.displayManager(item.file)
        if (!this.uploads[nameInUploads] && nameInUploads !== undefined) {
          this.textWarning = 'Necessário verificar todos os arquivos de download.'
          this.errors.push(item.file)
        }
      }
      if (this.errors.length) { return false }
      this.finishReview()
      return true
    },
    async startReview() {
      try {
        this.$emit('statusChange', {
          internal_status: this.slugToID('em-revisao'),
          external_status: this.slugToID('em-revisao')
        })
      } catch (error) {
        console.error(error.message)
      }
    },
    async finishReview() {
      try {
        const { involveds, ...projectData } = this.projectInfo
        const newData = {
          ...projectData,
          substitute: this.substitute,
          project_grade: Number(this.score),
          revision_responsible: this.name,
          project_feedback: this.project_feedback,
          internal_feedback: this.internal_feedback,
          project_penalty: this.project_penalty,
          internal_status_id: this.aproved ? this.slugToID('revisao-aceita') : this.slugToID('revisao-negada')
        }
        await this.$http.patch(`/project/${ this.projectInfo.id }`, newData)
        this.textWarning = 'Revisão salva com sucesso.'
        await this.toggleFinished()
        this.$emit('statusChange', {
          internal_status: newData.internal_status_id
        })
        window.location.reload()
      } catch (error) {
        console.error(error.message)
      }
    },
    toggleFinished() {
      this.revisionFinished = !this.revisionFinished
    },
    slugToID(slug) {
      const status = this.fullStatus.find((item) => item.slug === slug)
      return status?.id || null
    },
    aproval(value) {
      this.aproved = value
      if (this.status === 'aguardando-revisao' || this.status == 'aguardando-aceite-feira' || !this.projectInfo.internal_status.id) {
        this.$emit('statusChange', {
          internal_status: this.slugToID('em-revisao')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper__analisis-info{
  background: #fdfdfd;
  border-radius: 10px;
  font-family: Mulish;
  height:100%;

  .disabled {
    filter: grayscale(100%) saturate(0%) opacity(70%);
    cursor: alias;
    color: #686868;
  }
  .icon_wrapper{
    cursor: pointer;
  }

  .files_container_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 43px;

    .files_container {

      .files {
        display: flex;
        margin: 0px 35px 35px 0;

        .box_info__input{
          align-self: center;
          width: 20px;
          height: 20px;
        }
        &__extension {
          display: flex;
          font-size: 14px;
          line-height: 18px;
          color: #319CB4;
          margin: 8px;
        }
      }
      .box_info__label{
         font-size: 20px;
      }
    }
  }

  .revision_box{
    display: flex;
    justify-content: space-between;
    height: 70px;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding-bottom: 10px;
    border-radius: 10px 10px 0px 0px;

    .revision_title{
      margin-top: 12px;
      align-self: center;
      margin-left: 20px;
      color: $primary;
    }
    .start_button {
      flex-direction: row;
      align-self: center;
      margin-top: 9px;
      margin-right: 20px;
      width: 255px;
      height: 50px;
      background: #1B778B;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
  }

  .box_info{
    background-color: #F6F6F6;
    border-radius: 10px;
    width: 90.6%;
    margin-top: 30px;
    margin-left: 4.9%;
    padding: 30px;
    text-align: center;

    &__content{
      .box_info__text{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17.57px;
        color: #686868;
      }
      .box_info__title{
        font-size: 18px;
        font-weight: 600;
        line-height: 22.59px;
      }
    }

    &__confirmation{
      display: flex;
      justify-content: center;

      .box_info__button, .box_info__button_accept, .box_info__button_reject, .box_info__button_disabled{
        color: #686868;
        background-color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        margin: 10px;
        padding: 5px 7px;
        text-transform: initial;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-around;
      }
      .box_info__button_accept{
        background-color: #008000;
        color: white;
      }
      .box_info__button_reject{
        background-color: #F9537B;;
        color: white;
      }
    }

    &__step{
      margin-top: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;

      input{
        margin-right: 5px;
      }
    }
  }

  .main_info{
    width: 90.6%;
    margin-left: 4.9%;

    input[type='checkbox'] {
      margin-left: 3px;
      margin-right: 3px;
    }

    .input{
      display: flex;
      margin-top: 30px;
    }

    .revision_title_wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 35px;
      margin-top: 10px;
    }

    .revision__text {
        font-size: 14px;
        line-height: 18px;
        color: #686868;
      }

    .revision__title {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      color: $almostBlack;
      margin-left: 0px;
      margin-bottom: 5px;
    }

    .revision__files{
      margin-top: 30px;
      display: flex;

      .abstract__content{
        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 35px;
      }

      .separator {
        background-color: #CDCDCD;
        height: 1px;
        margin-top: 5px;
        margin-bottom: 15px;
        width: 100%;
      }

      h1{
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: $almostBlack;
        margin-left: 20px;
        margin-bottom: 10px;
      }
      .researchPlan_title, .revision__title{
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        color: $almostBlack;
        margin-top: 30px;
        margin-left: 0px;
      }
      .revision__title{
        line-height: 10px;
      }
    }
    .footer {
      margin-top: 100px;
      display: flex;
      width:100%;
      justify-content: flex-end;
      padding-bottom: 50px;
    }
  }
  .warning {
    color: #ff9800;
    border-bottom-color: $attention;
  }
}
</style>
