<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t("schoolInstitutionData")}}</p>
        <text-warning :text="'considerAllFieldObligatory'" />
      </div>
      <input-default
        :class="[{warning: checkErrorIfContains('name')}, 'mb-7']"
        label= institutionName
        id="name"
        placeholder= institutionNamePlaceholder
        type="text"
        max=100
        v-model="form.name"
        @input="resetErrors"
      />
      <RadioGroup
        :class="[{warning: checkErrorIfContains('category')}, 'mb-1']"
        label= educationalInstitutionCategory
        :options="options"
        v-model="form.category"
        @change="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('responsibleName')}, 'mb-7']"
        label= institutionResponsibleName
        id="name"
        placeholder= institutionResponsibleNamePlaceholder
        type="text"
        max="100"
        v-model="form.responsibleName"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('email')}, 'mb-7']"
        label= schoolEmail
        id="email"
        placeholder= schoolEmailPlaceHolder
        type="text"
        max=100
        v-model="form.email"
        @input="resetErrors"
      />
      <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
    </form>
    <feedback-warning v-if="errors.length" :text="textWarning" :onClick="resetErrors" color="#ff9800" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, ButtonDefault, FeedbackWarning, RadioGroup, TextWarning
} from '@/components/atoms'

export default {
  name: 'FormSchoolData',
  components: {
    InputDefault, ButtonDefault, FeedbackWarning, RadioGroup, TextWarning
  },
  data() {
    return {
      form: {
        name: '',
        category: '',
        responsibleName: '',
        email: ''
      },
      options: [
        { label: this.$t('public'), value: 'public' },
        { label: this.$t('private'), value: 'private' }
      ],
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory'
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation',
      lang: 'lang'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.school_name) {
      this.form.name = this.fairAffiliation.school_name
      this.form.category = this.fairAffiliation.school_type
      this.form.responsibleName = this.fairAffiliation.coordinator_name
      this.form.email = this.fairAffiliation.school_email
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    checkForm() {
      this.resetErrors()
      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
        }
      }
      if (!this.errors.length) {
        if (!this.validateEmail(this.form.email)) {
          this.textWarning = 'invalidEmail'
          return false
        }
        this.saveForm()
      }
    },
    saveForm() {
      try {
        let screen
        const formData = this.$store.getters.fairAffiliation
        if (JSON.parse(window.localStorage.getItem('fairAffiliatonData'))) {
          const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
          if (currentScreen < 1) {
            screen = 1
          } else {
            screen = currentScreen
          }
        } else screen = 1

        const newData = {
          ...formData,
          school_name: this.form.name,
          school_type: this.form.category,
          coordinator_name: this.form.responsibleName,
          school_email: this.form.email,
          currentScreen: screen
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
        return false
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    validateEmail(mail) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
</style>
