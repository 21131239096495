var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modal)?_c('div',{staticClass:"modal__outer",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.$emit('closeModal')}}},[_c('div',{staticClass:"modal__inner"},[_c('header-modal',{staticClass:"title mb-3",attrs:{"title":"Editar situação do projeto"},on:{"closeClicked":function($event){return _vm.$emit('closeModal')}}}),_c('div',{staticClass:"subtitle mb-5"},[_vm._v(_vm._s(_vm.title))]),_c('input-switch',{staticClass:"switch",attrs:{"label":"Projeto compareu à Mostratec?","id":"switch","onChange":function () { return _vm.attended = !_vm.attended; },"defaultState":_vm.attended,"disable":false}}),_c('input-default',{class:[
        {
          disabled: _vm.disable,
          warning: _vm.checkErrorIfContains('averageScore')
        },
        'average',
        'mb-5'
        ],attrs:{"label":"Nota média da banca","type":"text","placeholder":"Insira a nota média da banca","id":"Average","disabled":_vm.disable},on:{"input":_vm.resetWarnings},model:{value:(_vm.fields.averageScore),callback:function ($$v) {_vm.$set(_vm.fields, "averageScore", $$v)},expression:"fields.averageScore"}}),_c('input-default',{class:[
        {
          disabled: _vm.disable,
          warning: _vm.checkErrorIfContains('classification')
        },
      'position',
      'mb-5'
      ],attrs:{"label":"Classificação","type":"number","placeholder":"Insira a classificação","id":"Position","disabled":_vm.disable},on:{"input":_vm.resetWarnings},model:{value:(_vm.fields.classification),callback:function ($$v) {_vm.$set(_vm.fields, "classification", $$v)},expression:"fields.classification"}}),_c('input-text-area',{class:[
      {
        disabled: _vm.disable,
        warning: _vm.checkErrorIfContains('awarding')
      },
      'awarding',
      'mb-7'],attrs:{"label":"Premiação","placeholder":"Insira como os estudantes serão premiados","textMax":500,"allText":_vm.fields.awarding,"disabled":_vm.disable},on:{"input":function($event){_vm.fields.awarding=$event}}}),_c('div',{staticClass:"buttonsContainer"},[_c('button-default',{staticClass:"button__cancel",attrs:{"onClick":function () { return _vm.$emit('closeModal'); }}},[_vm._v(_vm._s(_vm.$t('noCancel')))]),_c('button-default',{staticClass:"button",attrs:{"onClick":_vm.checkReview}},[_vm._v(_vm._s(_vm.$t('yesContinue')))])],1)],1),(_vm.errors.length || _vm.displayPopUp)?_c('feedback-warning',{attrs:{"text":_vm.textWarning,"onClick":_vm.resetWarnings,"color":_vm.feedbackColor}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }