<template>
<div @click.self="$emit('closeModal')" v-if="modal" class="modal__outer">
    <div class="modal__inner">
      <header-modal @closeClicked="$emit('closeModal')" :title="$t('attention')" />
      <div class="modal__warning">
        <img src ="@/assets/warningLgpd.png" class="img">
        <div class="modal__warning__content">
          <p class="text">
            {{$t('acceptanceRequired')}}
            <span class="bold_text">
              {{$t('dataProtectionTerm')}}
            </span>
            {{$t('followTheLink')}}
          </p>
          <button-default
            :onClick="onClick"
            class="button"
            >{{$t('yesContinue')}}
          </button-default>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HeaderModal } from '@/components/organisms/Headers'
import { ButtonDefault } from '@/components/atoms'
import { ModalLgpdTerm } from '@/components/organisms/Modals'

export default {
  name: 'ModalLgpd',
  props: {
    onClick: {
      type: Function,
      required: true
    },
    modal: {
      type: Boolean,
      required: true
    }
  },
  components: { HeaderModal, ButtonDefault, ModalLgpdTerm }
}
</script>

<style lang="scss" scoped>

.modal__warning {
  width: 582px;
  display:flex;
  flex-direction: row;
  .img{
    margin-top: 20px;
  }
  .modal__warning__content{
    margin-left: 30px;
    margin-top: 22.12px;
  }

  .text {
    align-items: center;
    width: 310px;
    height: 110px;
    flex-direction: column;
    font-family: Mulish;
    font-size: 16px;
    color: #0E5671;
  }
  .bold_text{
    font-weight: 650;
  }
  .button{
    max-width: 300px;
    width: 298px;
    height: 40px;
  }
}

.modal__outer {
  @include d-flex-center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 100%;
  left: 0px;
  padding: 0px 10px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    min-height: 221px;
    padding: 25px;
    width: 582px;
    height: 330px;
  }
}
</style>
