<template>
  <button
    :style="{
      background: color,
      maxWidth: large ? '336px' : '235px',
      height: height,
      fontSize: letterSize,
      width: width,
      margin: margin,
      color: letterColor
    }"
    :class="[{ disabled }, 'button']"
    type="submit"
    @click.prevent="onClick"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ButtonDefault',
  props: {
    onClick: {
      type: Function,
      required: false
    },
    color: {
      type: String,
      require: false
    },
    disabled: {
      type: Boolean
    },
    large: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      required: false
    },
    letterSize: {
      type: String,
      required: false
    },
    width: {
      type: String,
      required: false
    },
    margin: {
      type: String,
      required: false
    },
    letterColor: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  background: $primary;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  cursor: pointer;
  height: 50px;

  outline: none;
  width: 100%;

  color: $white;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  transition: all 0.3s;

  &:hover {
    opacity: 0.92;
  }
}

.white {
  background-color: $white;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
  color: $primary;
  transition: all .3s;

  &:hover {
    color: $attention;
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;

  &:hover {
    opacity: 0.5;
  }
}
</style>
