<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('fileUpload')}}</p>
        <p class="form__text">{{$t('filesMustBeSent') + formatDate }}</p>
        <text-warning :text="`considerAllFieldObligatory`" />
      </div>

      <section>
        <div class="separator">
          <h3>{{$t('requiredFiles')}}</h3>
        </div>

        <label class="label">{{$t('revision')}} XX</label>
        <DragFile
          @file="files.revision=$event"
          :attention="cAttention('revision')"
          :type="'pdf'"
          :actualData="files.revision"
          :reference="'revision'"
        />
        <text-info class="text-info mt-3" text=fileFormatMustBePDF />

        <!-- label class="label">Banner</label -->
        <DragFile
          @file="files.banner=$event"
          :attention="cAttention('banner')"
          :type="'pdf'"
          :actualData="files.banner"
          :reference="'banner'"
        />
        <text-info class="text-info mt-3" text=fileFormatMustBePDF />

        <label class="label">Vídeo</label>
        <DragFile
          @file="files.video=$event"
          :attention="cAttention('video')"
          :type="'mp4'"
          :actualData="files.video"
          :reference="'video'"
        />
        <text-info class="text-info mt-3" text="O formato do arquivo deve ser em MP4" />

        <label class="label">{{$t('presentation')}}</label>
        <DragFile
          @file="files.presentWork=$event"
          :attention="cAttention('presentWork')"
          :type="'pdf'"
          :actualData="files.presentWork"
          :reference="'presentWork'"
        />
        <text-info class="text-info mt-3" text=fileFormatMustBePDF />
      </section>

      <section>
        <div class="separator">
          <h3>{{$t('specificFiles')}}</h3>
        </div>
        <p class="form__text">
          {{$t('filesRequestBelowInfo')}} <strong>{{$t('projectData')}}</strong>.
        </p>

        <label class="label">{{$t('continuityProject')}}</label>
        <DragFile
          @file="files.continuousProject=$event"
          :attention="cAttention('continuousProject')"
          :type="'pdf'"
          :actualData="files.continuousProject"
          :reference="'continuousProject'"
        />
        <text-info
          class="text-info mt-3"
          text="Para continuidade de projetos. O formato do arquivo deve ser em PDF"
        />

        <label class="label">{{$t('vertebrates')}}</label>
        <DragFile
          @file="files.vertebrateAnimals=$event"
          :attention="cAttention('vertebrateAnimals')"
          :type="'pdf'"
          :actualData="files.vertebrateAnimals"
          :reference="'vertebrateAnimals'"
        />
        <text-info
          class="text-info mt-3"
          text=vertebrateAnimalsFileFormat
        />

        <!-- div v-if="allDataForm.FormProjectHighData.hasHumans"></div -->
        <label class="label">{{$t('humansNecessaryFile')}}</label>
        <DragFile
          @file="files.humans=$event"
          :attention="cAttention('humans')"
          :type="'pdf'"
          :actualData="files.humans"
          :reference="'humans'"
        />
        <text-info
          class="text-info mt-3"
          text=humanBeingsFileFormat
        />

        <label class="label">{{$t('bios')}}</label>
        <DragFile
          @file="files.biological=$event"
          :attention="cAttention('biological')"
          :type="'pdf'"
          :actualData="files.biological"
          :reference="'biological'"
        />
        <text-info
          class="text-info mt-3"
          text="Para pesquisa com agentes biológicos potencialmente perigosos. O formato do arquivo deve ser em PDF"
        />

        <label class="label">Substâncias com tecidos de seres humanos ou de animais vertebrados</label>
        <DragFile
          @file="files.tissueSubstances=$event"
          :attention="cAttention('tissueSubstances')"
          :type="'pdf'"
          :actualData="files.tissueSubstances"
          :reference="'tissueSubstances'"
        />
        <text-info
          class="text-info mt-3"
          text="Para pesquisa com tecidos de seres humanos ou de animais vertebrados. O formato do arquivo deve ser em PDF"
        />

        <label class="label">{{$t('researchInstituitionIndustry')}}</label>
        <DragFile
          @file="files.researchInstitution=$event"
          :attention="cAttention('researchInstitution')"
          :type="'pdf'"
          :actualData="files.researchInstitution"
          :reference="'researchInstitution'"
        />
        <text-info
          class="text-info mt-3"
          text="Para pesquisa em instituição de pesquisa ou indústria regulamentada. O formato do arquivo deve ser em PDF"
        />

        <label class="label">{{$t('riscs')}}</label>
        <DragFile
          @file="files.risksAnalysis=$event"
          :attention="cAttention('risksAnalysis')"
          :type="'pdf'"
          :actualData="files.risksAnalysis"
          :reference="'risksAnalysis'"
        />
        <text-info class="text-info mt-3" text=fileFormatMustBePDF />

        <label class="label">{{$t('scientist')}}</label>
        <DragFile
          @file="files.cientistQualified=$event"
          :attention="cAttention('cientistQualified')"
          :type="'pdf'"
          :actualData="files.cientistQualified"
          :reference="'cientistQualified'"
        />
        <text-info class="text-info mt-3" text=fileFormatMustBePDF />
      </section>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <div class="form__save_footer">
          <button-default :onClick="checkForm" class="button white mr-5">{{$t('save')}}</button-default>
          <button-default :onClick="checkForm" class="button">{{$t('submitRegistration')}} E6</button-default>
        </div>
      </div>
    </form>

    <modal-send-form
      title="formSelectionRequestModalSendFormTitle"
      :onClick="saveForm"
      @closeModal="() => toggleModal('modal')"
      :modal="modal"
    >
      <div class="text mt-5">
        <p info="teste2">{{$t('affiliationRequestModalSendFormText')}}</p>
      </div>
    </modal-send-form>
    <modal-default
      title="requestSentSuccessfullyTitle"
      @closeModal="() => toggleModal('modal2')"
      :modal="modal2"
    >
      <div class="modal__body">
        <div class="icon">
          <img src="@/assets/email-send.png" alt="Solicitação enviada a MostraTec" />
        </div>
        <div class="text">
          <p slot="text">{{$t('requestSentSuccessfully')}}</p>
        </div>
      </div>
    </modal-default>
    <modal-loading :modal="loading" />
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {

  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo,
  Paragraph,
  DragFile
} from '@/components/atoms'
import {
  ModalSendForm,
  ModalLoading,
  ModalDefault
} from '@/components/organisms/Modals'

export default {
  name: 'FormSelectionUpload',
  components: {
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo,
    DragFile,
    ModalSendForm,
    ModalLoading,
    ModalDefault
  },
  data() {
    return {
      errors: [],
      extensionCheck: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      files: {
        revision: {
          name: '',
          url: ''
        },
        banner: {
          name: '',
          url: ''
        },
        video: {
          name: '',
          url: ''
        },
        presentWork: {
          name: '',
          url: ''
        },
        continuousProject: {
          name: '',
          url: ''
        },
        vertebrateAnimals: {
          name: '',
          url: ''
        },
        humans: {
          name: '',
          url: ''
        },
        biological: {
          name: '',
          url: ''
        },
        tissueSubstances: {
          name: '',
          url: ''
        },
        researchInstitution: {
          name: '',
          url: ''
        },
        risksAnalysis: {
          name: '',
          url: ''
        },
        cientistQualified: {
          name: '',
          url: ''
        }
      },
      firstLoader: false,
      modal: false,
      modal2: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      projectSelection: 'projectSelection'
    })
  },
  methods: {
    cAttention(data) {
      return this.firstLoader && this.errors.includes(data)
    },
    async checkForm() {
      this.firstLoader = true
      this.resetErrors()
      this.textWarning = this.$t('warningAllFieldsAreObligatory')
      for (const key in this.files) {
        if (!this.files[key].url) {
          if (key === 'revision'
            || key === 'banner'
            || key === 'video'
            || key === 'presentWork') {
            this.errors.push(key)
          }
        }
      }
      if (!this.errors.length) {
        this.toggleModal('modal')
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    save() {
      try {
        const formData = this.$store.getters.projectSelection
        formData.advisors.forEach((item) => {
          formData.involveds.push(item)
        })
        formData.sub_areas = { sub_areas: formData.sub_areas }
        formData.continuity_sub_areas = {
          continuity_sub_areas: formData.continuity_sub_areas
        }
        formData.students.forEach((item) => {
          if (item.active) delete item.active
          delete item.attendOtherFair
          let zc
          if (item.zipcode) zc = item.zipcode
          else zc = '0'
          if (item.zipCode) zc = item.zipCode.slice(0, 5) + item.zipCode.slice(6)
          item.zipcode = Number(zc)
          delete item.zipCode
          item.deficiency = { deficiencies: item.deficiency }
          item.special_service = { special_services: item.special_service }
          item.cpf = Number(item.cpf)
          item.phone = Number(item.phone)
          //          item.house_number = Number(item.house_number)
          item.house_number = item.house_number
          if (item.birth) item.birth_date = this.formatDates(item.birth)
          delete item.birth
          delete item.otherSpecialService
        })
        formData.students[0].main = true
        formData.involveds = formData.involveds.filter((item) => item.active)
        formData.involveds.forEach((item) => {
          delete item.active
          delete item.otherSpecialService
          item.deficiency = { deficiencies: item.deficiency }
          item.special_service = { special_services: item.special_service }
          item.cpf = Number(item.cpf)
          item.phone = Number(item.phone)
          item.zipcode = Number(item.zipcode)
          //          item.house_number = Number(item.house_number)
          item.house_number = item.house_number
          item.sex = item.sex.toLowerCase()
        })
        formData.research_institution = {
          ...formData.research_institution,
          research_locations: {
            research_locations: formData.research_institution.research_locations
          },
          experiment_items: {
            experiment_items: formData.research_institution.experiment_items
          },
          phone: Number(formData.research_institution.phone),
          //          house_number: Number(formData.research_institution.house_number),
          house_number: formData.research_institution.house_number,
          zipcode: Number(formData.research_institution.zipCode)
        }
        const newData = {
          ...formData,
          files: { files: this.files }
        }
        this.$http.post('/project', newData)
        this.$store.commit('PROJECT_SELECTION_REQUEST', {})
        window.localStorage.removeItem('projectSelectionData')
      } catch (error) {
        console.log('error por fora: ', error)
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    async saveForm() {
      this.toggleModal('modal')
      this.toggleModal('loading')
      try {
        const formData = this.$store.getters.fairAffiliation
        const newData = {
          ...formData,
          terms: this.read_and_agreed,
          responsible_name: this.form.coordinator_name,
          office: this.form.coordinator_role
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        try {
          await this.$http.post('/fair', newData)
          this.toggleModal('loading')
          this.toggleModal('modal2')
          this.$store.commit('AFILLIATION_REQUEST', {})
          window.localStorage.removeItem('fairAffiliatonData')
          setTimeout(() => {
            this.$router.push('/panel-fair-vacancies')
          }, 4000)
        } catch (error) {
          if (error.response) {
            console.log(error.response.data)
            this.errors.push(1)
            this.textWarning = `Erro no envio do formulário, ${ error.response.data.message }. `
            if (error.response.data.message == 'Contact email already in use') {
              this.textWarning += 'E-mails de contato já em uso em outra solicitação, altere-os no formulário 3 - Contatos'
            }
          }
        }
      } catch (error) {
        console.log('error por fora: ', error)
      } finally {
        if (this.loading) this.toggleModal('loading')
      }
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    formatDates(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      const preFormatedDate = `${ year }-${ month }-${ day }`
      const formatedDate = new Date(preFormatedDate)
      return formatedDate
    }
  }
}
</script>

<style lang="scss" scoped>

.white_button{

  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #1B778B;

}

.white_save_button{

  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #1B778B;
  margin-right: 6px;
}

input[type="file"] {
    display: none;
    width: 570px;
    height: 105px;
}

label{
  display: flex;
  justify-content: center ;
  width: 100%;
  height: 105px;
}

.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__main_title{
    font-family: Mulish;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
  }

  &__file_input_title {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #303030;
  margin-bottom: 0px;

}
  &__bold_blue{
    color: #319CB4;
    font-weight: 600;
  }

   &__bold_dark_blue{
    color: #1B778B;
    font-weight: 700;
  }

  &__green_name {
    position: static;
    width: 100%;
    height: 15px;
    left: 0px;
    top: 34px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #1CD24F;
  }

  &__subtitle{
    font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  width: 444px;
  height: 66px;
  color: #1B778B;
  }

  &__paragraph {
  display: block;
  justify-content: center ;
  margin-top: 10px;
  height: 50px;
}

  &__paragraph_green {
  display: block;
  justify-content: center ;
  margin-top: 51px;
  margin-left: 7px;
  height: 50px;
}

  &__file_input {
  width: 570px;
  height: 105px;
  background: rgba(173, 215, 233, 0.08);

  border: 1px solid #CDCBCB;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center ;
  display: flex;

}
 &__file_input:hover{
    border: 1px solid #319CB4;
  }

  &__file_accept {
  width: 570px;
  height: 105px;
  background: rgba(173, 215, 233, 0.08);
  border: 1px solid #1CD24F;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center ;
  display: flex;

}

  &__file_reject {
  width: 570px;
  height: 105px;
  background: rgba(173, 215, 233, 0.08);
  border: 1px solid #D93025;
  color:#D93025;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center ;
  display: flex;

}

  &__cloud {
  display: flex;
  height: 28px;
  width: 40px;
  margin-left: 119px;
  margin-top: 9px;
  }

  &__reject{
  display: flex;
  height: 27px;
  width: 26px;
  margin-left: 193px;
  margin-top: 9px;
  }

  &__grey_cloud {
  display: flex;
  height: 28px;
  width: 40px;
  margin-left: 119px;
  margin-top: 9px;
  }

  &__file_accepted{
    position: relative;
    bottom: 104%;
    left: 43%;
    width: 26px;
    height: 27px;
    display: block;
  }

  &__title {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #1B778B;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 4px 0px;
    }

  &__divider {
      position: static;
      width: 100%;
      height: 0.7px;
      left: 0px;
      top: 37px;
      background: #3E778D;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin-bottom: 10px;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    max-width: 610px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }

    label {
      align-items: center;
      display: flex;
      color: $primaryDark;
      font-size: 16px;
      font-weight: 600;

      input {
        zoom: 1.5;
        margin-right: 5px;
      }
    }

    .warning {
      color: $attention !important;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
    max-width: 444px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
  &__save_footer {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    width: 415px;
  }

  .separator {
    border-bottom: 1px solid $primary;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $primaryDark;
    font-family: Mulish;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
  }

  .label {
    color: $almostBlack;
    display: flex;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 30px;
    text-align: left;
  }
}
</style>
