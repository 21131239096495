<template>
  <div class="home">
    <InitialRegister />
  </div>
</template>

<script>
import { InitialRegister } from '@/components/templates'

export default {
  name: 'Register',
  components: { InitialRegister }
}
</script>
