<template>
  <div class="home">
    <GetEmailResetPassword />
  </div>
</template>

<script>
import { GetEmailResetPassword } from '@/components/templates'

export default {
  name: 'GetEmailResetPasswordView',
  components: { GetEmailResetPassword }
}
</script>
