import { render, staticRenderFns } from "./CurrentFair.vue?vue&type=template&id=520c1e05&scoped=true&"
import script from "./CurrentFair.vue?vue&type=script&lang=js&"
export * from "./CurrentFair.vue?vue&type=script&lang=js&"
import style0 from "./CurrentFair.vue?vue&type=style&index=0&id=520c1e05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520c1e05",
  null
  
)

export default component.exports