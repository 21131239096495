<template>
  <aside class="inner__wrapper">
    <div class="header">
      <h3>Dados instituição/escola</h3>
    </div>

    <main class="form__main mt-4 main_align">
      <div class="infos">
        <div class="generalData">
          <div class="title">
            <span>Dados gerais</span>
          </div>

          <div class="data">
            <div class="data__left">
              <div class="data__row">
                <h4>Nome da Instituição/escola</h4>
                <span>{{form.school_name}}</span>
              </div>

              <div class="data__row">
                <h4>Nome do responsável da instituição/escola</h4>
                <span>{{form.responsible_name}}</span>
              </div>

              <div class="data__row">
                <h4>Celular</h4>
                <span>{{form.cellphone}}</span>
              </div>
            </div>

            <div class="data__right">
              <div class="data__row">
                <h4>Categoria de instituição de ensino</h4>
                <span>{{form.school_type == 'public' ? 'Pública' : 'Privada'}}</span>
              </div>

              <div v-if="form.landline" class="data__row">
                <h4>Telefone fixo (opcional)</h4>
                <span>{{form.landline}}</span>
              </div>

              <div v-else class="data__row">
                <h4>Telefone fixo (opcional)</h4>
                <span>Não informado</span>
              </div>

              <div class="data__row">
                <h4>E-mail a instituição/escola</h4>
                <span>{{form.email}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="address">
          <div class="title">
            <span>{{$t('address')}}</span>
          </div>

          <div class="data">
            <div class="data__left">
              <div class="data__row">
                <h4>CEP</h4>
                <span>{{form.cep}}</span>
              </div>

              <div class="data__row">
                <h4>Cidade</h4>
                <span>{{form.city}}</span>
              </div>

              <div class="data__row">
                <h4>Número</h4>
                <span>{{form.locate_number}}</span>
              </div>
            </div>

            <div class="data__right">
              <div class="data__row">
                <h4>UF</h4>
                <span>{{form.uf}}</span>
              </div>

              <div class="data__row">
                <h4>{{$t('address')}}</h4>
                <span>{{form.address}}</span>
              </div>

              <div class="data__row">
                <h4>Complemento</h4>
                <span>{{form.complement}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="contacts">
          <div class="title">
            <span>{{$t('contacts')}}</span>
          </div>
          <div v-for="(contact, index) in form.school_contacts" :key="index">
            <div class="data__header">
              <h4>Contato {{index + 1}}</h4>
              <div class="separator"></div>
            </div>
            <div class="data">
              <div class="data__left">
                <div class="data__row">
                  <h4>{{$t('name')}}</h4>
                  <span>{{contact.name}}</span>
                </div>
                <div class="data__row">
                  <h4>Telefone (é opcional ser fixo ou celular)</h4>
                  <span>{{contact.phone}}</span>
                </div>
              </div>
              <div class="data__right">
                <div class="data__row">
                  <h4>E-mail</h4>
                  <span>{{contact.email}}</span>
                </div>
                <div class="data__row">
                  <h4>Cargo</h4>
                  <span>{{contact.office}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </aside>
</template>

<script>
export default {
  name: 'ProjectsInstitution',
  data: () => ({
    form: {
      school_name: '',
      responsible_name: '',
      cellphone: '',
      school_type: '',
      landline: '',
      email: '',
      cep: '',
      uf: '',
      city: '',
      address: '',
      locate_number: '',
      complement: '',
      school_contacts: []
    }
  }),
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.getInfos()
  },
  methods: {
    async getInfos() {
      const { data: schoolData } = await this.$http.get(`/school/${ this.projectInfo.school_id }`)
      console.log('schoolData: ', schoolData)
      this.form = {
        ...this.form,
        school_name: schoolData.school_name,
        responsible_name: schoolData.responsible_name,
        cellphone: schoolData.cellphone || 'Não informado',
        school_type: schoolData.school_type,
        email: schoolData.email,
        cep: schoolData.cep,
        uf: schoolData.uf,
        city: schoolData.city,
        address: schoolData.address,
        locate_number: schoolData.locate_number,
        complement: schoolData.complement ? schoolData.complement : 'Não informado',
        school_contacts: schoolData.school_contacts
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.inner__wrapper {
  background: #fdfdfd;
  border-radius: 10px;
  font-family: Mulish;
  height:100%;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;
    margin-bottom: 25px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }
  .infos {
    padding: 0px 22px 25px;

    .address, .contacts, .generalData {
      .title {
        border-bottom: 0.5px solid $primary;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-bottom: 5px;

        span{
          font-size: 16px;
          line-height: 20px;
          font-weight: 700;
        }
      }
      .data{
        margin-left: 10%;
        display: flex;
        font-size: 16px;
        color: #686868;

        &__row{
          width: 320px;
          margin-bottom: 35px;
          h4{
            color: $almostBlack;
            margin-bottom: 10px;
          }
        }
        &__left{
          float: left;
        }
        &__right{
          float: right;
          margin-left: 18%;
        }
        &__header{
          font-size: 17px;
          line-height: 20px;
          font-weight: 700;
          color: $primary;
          margin-left: 10%;
        }
      }
    }
  }
}
.warning {
  color: $attention;
  border-bottom-color: $attention;
}
.separator {
  background-color: $primary;
  height: 1px;
  margin-top: 5px;
  margin-bottom: 30px;
  width: 81.17%;
}

</style>
