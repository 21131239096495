<template>
  <div class="edit-user">
    <div class="container">
      <div class="form">
        <input
          label="projectName"
          id="projectName"
          placeholder="enterTheProjectName"
          type="text"
          :width="800"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormEditProjectElementary',
  data() {
    return {
      text: 'testandooo'
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-user {
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  .container {
    max-width: 830px;
    padding: 25px 39px;
    border-radius: 20px;
    background-color: $white;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }
    }
  }
}
</style>
