<template>
  <div class="container">
    <heading-three class="titleText">
      {{title}}
    </heading-three>
    <div class="switchAlign">
      <label for="wasOnMostratec" class="left_label">{{firstLabel}}</label>
      <v-switch
        :color="color"
        inset
        value
        disabled
        :input-value="switchValue"
        id="wasOnMostratec"
        :label="secondLabel"
        :v-model="switchValue"
      ></v-switch>
    </div>
  </div>
</template>

<script>
import { HeadingThree } from '@/components/atoms'

export default {
  name: 'TitleSwitchPair',
  components: {
    HeadingThree
  },
  props: {
    title: {
      type: String,
      required: false
    },
    firstLabel: {
      type: String,
      required: false
    },
    secondLabel: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: true
    },
    switchValue: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.container {
  height: fit-content;
  text-align: start;
  margin-trim: 5px;
}

.titleText {
  margin-left: 12px;
}

.switchAlign {
  display:flex;
  width: fit-content;
  align-items: center;
  margin-left: 22px;
  margin-bottom: -12px;

}
.left_label {
  height: fit-content;
  margin-right: 15px;
  color: rgb(207, 196, 196);
}

label {
  color:$almostBlack;
}
</style>
