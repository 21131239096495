<template>
  <div class="breadcrumb">
    <div class="submenu">
      <span class="subMenuTitle">
        Configuração de usuários
      </span>
    </div>
    <div class="items">
      <span :class="{active: active == 'Users'}" @click="menuSelected('Users')">Usuários</span>
      <span :class="{active: active == 'AccessProfile'}" @click="menuSelected('AccessProfile')">Perfis de Acesso</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadcrumbUserConfiguration',
  data: () => ({
    active: 'AccessProfile'
  }),
  watch: {
    '$route.name': function (id) {
      this.menuSelected('AccessProfile')
    }
  },
  mounted() {
    this.menuSelected('AccessProfile')
  },
  methods: {
    menuSelected(tab) {
      this.active = tab
      this.$emit('menuSelected', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  align-items: center;
  background-color: $white;
  border-radius: 0px 29px 29px 0px;
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 20px;
  float: left;
  padding: 10px 20px;

  @media screen and (max-width: 320px) {
    flex-direction: column;
  }

  .submenu {
    @include d-flex-center;

    @media screen and (min-width: 321px) {
      border-right: 0.7px solid #1B778B;
      padding-right: 20px;
    }

    span {
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $primary;
    }

    .subMenuTitle {
      width: 118px
    }
  }

  .items {
    @include d-flex-center;

    @media screen and (min-width: 321px) {
      padding-left: 20px;
    }

    span {
      color: $primary;
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      padding-right: 20px;

      &:hover {
        opacity: 0.7;
      }
    }

    .active {
      font-weight: 600
    }
  }
}
</style>
