<template>
  <div class="wrapper">
    <div class="subwrapper">
      <img class="img_default" :src="require(`@/assets/${this.image}`)" />
      <div
        v-if="this.image === 'enrollmentInProgress.png'"
        class="button_text_pair"
      >
        <span class="enrollment_text">
          {{ $t('enrollmentInProgress')}}
        </span>
        <button-default
          :onClick="go"
          class="button"
        >
         {{$t('clickingHere')}}
        </button-default>
      </div>

      <span v-else class="content">
        {{ imageManager[image].value }}
      </span>
      <template v-if="this.image === 'notAccepted.png' && this.cause">
        <GrayLineTitle
          class="cause"
          :title="$t('cause')"
        />
        <span class="cause_text">
          {{this.cause}}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { ButtonDefault } from '@/components/atoms'
import { GrayLineTitle } from '@/components/molecules/Content'

export default {
  name: 'ImageInformationHorizontal',
  components: { ButtonDefault, GrayLineTitle },
  data() {
    return {
      imageManager: {
        'inAnalysis.png': { value: this.$t('inAnalysis') },
        'waitingPayment.png': { value: this.$t('waitingPaymentText') },
        'pendingAnalysis.png': { value: this.$t('pendingAnalysis') },
        'notAccepted.png': { value: this.$t('notAcceptedText') },
        'subscriptionCanceled.png': { value: this.$t('registrationCanceledText') },
        'time.png': { value: this.$t('waitingAccept') },
        'card.png': { value: this.$t('waitingPaymentText') },
        'done.png': { value: this.$t('registrationCompleteText') }

      },
      routeMap: {
        aluno_feira_afiliada: '/startproject/student_fair',
        aluno_processo_selecao: '/startproject/selection'
      }
    }
  },
  props: {
    image: {
      type: String,
      required: true
    },
    content: String,
    fair_name: String,
    cause: String
  },
  methods: {
    async go() {
      const role = localStorage.getItem('role')
      return this.$router.push(this.routeMap[role])
    }
  }
}
</script>

<style lang="scss" scoped>

.enrollment_text {
  color: $primaryDark;
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  align-self: flex-start;
  width: 100% !important;
  font-size: 18px;
  height: 60px;
  line-height: 20px;
}

.cause {
  margin-top: 90px !important;
  display: block !important;
  width: 100%;
}

.cause_text {
  text-align: left;
  width: 94%;
}

.bold {
  font-weight: 700;
}

.wrapper {
  display: block;
}

.subwrapper {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  height: fit-content;
  align-items: center;
  margin: 15px 20px;
  width: 100%;
  justify-content: center;
;

  & > .button_text_pair {
    display: flex;
    width: 35%;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    justify-content: center;
  }

  & >  .content {
    color: $primaryDark;
    font-family: Mulish;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    margin: 20px 10px;
    align-self: center;
    width: 34% !important;
    font-size: 18px;
    height: 60px;
    line-height: 20px;

  }

  & > .img_default {
    width: 27%;
    height: 27%;
    display: flex;
    align-self: center;
    justify-content: center;
  }

  & > .button {
    margin: 5px 0px;
  }

}

</style>
