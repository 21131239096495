<template>
  <div class="info-wrapper">
    <div class="header">
      <GrayLineTitle
        class="teste"
        :title="title"
        :color="color"
        :hasMoney="true"
        :isMoney="true"
        :price="singlePrice"
      />
    </div>
    <div class="infos">
      <information
        v-for="(item,index) in infos"
        :key="index"
        :title="item.text"
        :content="item.text !== 'Projeto Isento' ? item.value : (radioVModel ? 'Sim' : 'Não')"
      />
    </div>
    <div
      v-if="slug === 'inscricao-cancelada' && rejectReason.length"
      class="margin_alignment"
    >
      <information
        :title="$t('registrationCanceled')"
        :content="rejectReason"
      />
    </div>
    <div class="end_alignment">
      <TextMoneyPair
        title='Quantidade de participantes: '
        :color="quantityColor"
        :value="quantity"
      />
      <TextMoneyPair
        title='Valor total a pagar: '
        :color="priceColor"
        :value="radioVModel ? 'Isento' : value"
      />
    </div>
  </div>
</template>

<script>
import { MoneyDefault } from '@/components/atoms'
import {
  Information,
  GrayLineTitle,
  TextMoneyInfo,
  TextMoneyPair
} from '@/components/molecules'

export default {
  name: 'InfoDefault',
  components: {
    Information,
    GrayLineTitle,
    MoneyDefault,
    TextMoneyInfo,
    TextMoneyPair
  },
  props: {
    infos: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: 'gray'
    },
    color: {
      type: String,
      required: false,
      default: 'gray'
    },
    quantity: {
      type: [String, Number],
      required: true
    },
    slug: {
      type: String,
      required: true
    },
    quantityColor: {
      type: String,
      required: false,
      default: '#1B778B'
    },
    priceColor: {
      type: String,
      required: false,
      default: '#1B778B'
    },
    singlePrice: {
      type: [String, Number],
      required: false
    },
    value: {
      type: [String, Number],
      required: true
    },
    rejectReason: String,
    radioVModel: [String, Boolean]
  },
  methods: {
    checkBadge(infos) {
      return (
        infos === this.$t('researchAreaSingle')
        || infos === this.$t('researchSubAreas')
      )
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    checkArea(infos) {
      return (infos === this.$t('researchAreaSingle'))
    }
  }
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  display: flex;
  flex-direction: column;
  .margin_alignment {
    margin-left: 25px;
  }
  .end_alignment {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
  }
  .infos {
    display: flex;
    gap: 10px;
    margin: 0px 25px;
    margin-bottom: 20px;
    justify-content: space-between
  }
}

</style>
