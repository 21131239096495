<template>
  <div class="header-sub">
    <div class="content">
      <div class="width">{{$t('userSettings')}}</div>
      <div :class="[{ 'bold': index < 2 }, 'menu']" id="users">{{$t('users')}}</div>
      <div :class="[{ 'bold': index == 2 }, 'menu']" id="profileAccess">{{$t('profileAccess')}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderSub',
  props: {
    index: Number
  },
  mounted() {
    const profileAccess = document.querySelector('#profileAccess')
    const users = document.querySelector('#users')
    profileAccess.addEventListener('click', () => {
      this.$emit('newIndex', 2)
    })
    users.addEventListener('click', () => {
      this.$emit('newIndex', 0)
    })
  }
}
</script>
<style lang="scss" scoped>
.header-sub {
  width: 96.5vw;
  margin: 26px 0;
  .content {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 20px;
    width: 441px;
    height: 59px;
    color: $blueStrong;
    background-color: $white;
    border-radius: 0 50px 50px 0;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    .width {
      width: 118px;
      font-weight: 900;
    }
    .menu {
      margin: 0 15px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &.bold {
        font-weight: bold;
      }
      &:hover {
        opacity: 0.8;
      }
    }
    :nth-child(2) {
      display: flex;
      align-items: center;
      padding-left: 15px;
      height: 55%;
      border-left: 1px solid $primary;
    }
    &:before {
      content: "";
      width: 22px;
      height: 100%;
      left: -22px;
      position: absolute;
      background-color: $white;
    }
  }
}
</style>
