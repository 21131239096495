<template>
  <div class="info-wrapper">
    <div class="info-box">
      <heading-three
        class="year"
        :class="{ active: active }"
      >
        {{content}}
      </heading-three>
      <status-with-tooltip
        :statusText="statusDictionary[statusText].name"
        :theme="statusDictionary[statusText].theme"
        :active="active"
      />
    </div>
  </div>
</template>

<script>
import { HeadingThree, StatusWithTooltip } from '@/components/atoms'
import { statusDictionary } from '@/utils/dictionaries'

export default {
  components: { HeadingThree, StatusWithTooltip },
  name: 'YearStatusPair',
  props: {
    content: {
      type: String,
      required: true
    },
    statusText: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  data() {
    return {
      statusDictionary
    }
  }
}
</script>

<style lang="scss" scoped>

.info-wrapper{
  display: flex;
  flex-direction: column;
  width: 98%;
}
.info-box {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.year {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 659;
  font-size: 21px;
  margin: 0;
  text-transform: capitalize;
  color: $textCard;
  &.active {
    color: $blueStrong;
  }
}

</style>
