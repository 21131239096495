<template>
  <div class="warning">
    <img :src="require(`@/assets/warning.png`)" alt="Atenção ao formulário da Liberato" />
    <span>{{$t(text)}}</span>
  </div>
</template>

<script>
export default {
  name: 'TextWarning',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.warning {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;

  span {
    color: $attention;
    margin-left: 10px;
  }
}
</style>
