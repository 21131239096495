<template>
  <div class="container-app">
    <header-default @menuSelected="menuSelected" />
    <div class="templates-container">
      <projects-info
        @navSelected="(value) => navSelected(value)"
        :projectInfo="projectInfo"
        @internalFeedback="(txt) => internalFeedback(txt)"
        :internal_feedback_empty="internal_feedback_empty"
        @project_penalty="(txt) => projectPenalty(txt)"
        :project_penalty_empty="project_penalty_empty"
        class="left"
      />
      <Transition>
        <component
          :is="component"
          :projectInfo="projectInfo"
          @statusChange="(e) => statusChange(e)"
          :internal_feedback="internal_feedback"
          @internalFeedback_empty="(e) => internalFeedback_empty(e)"
          :status="statusSlug"
        />
          <component
          :is="component"
          :projectInfo="projectInfo"
          @statusChange="(e) => statusChange(e)"
          :projectPenalty="projectPenalty"
          @project_penalty_empty="(e) => project_penalty_empty(e)"
          :status="statusSlug"
        />
      </Transition>
    </div>
  </div>
</template>

<script>
import { HeaderDefault } from '@/components/organisms'
import {
  ProjectsInfo,
  ProjectsStudents,
  ProjectsRevision,
  ProjectsInvolveds,
  ProjectsInstitution,
  ProjectsAdvisors,
  ProjectsResearcher,
  Projects,
  ProjectsElementaryRevision,
  ProjectsElementaryInvolveds,
  ProjectsElementary,
  ProjectsElementaryStudents,
  ProjectsContract,
  ProjectsKindergartenRevision,
  ProjectsKindergarten,
  ProjectsKindergartenInvolveds,
  ProjectPayment,
  ProjectsKindergartenStudents
} from '@/components/templates'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectDetails',
  components: {
    HeaderDefault,
    ProjectsInfo,
    ProjectsStudents,
    ProjectsRevision,
    ProjectsInvolveds,
    ProjectsInstitution,
    ProjectsAdvisors,
    ProjectsResearcher,
    Projects,
    ProjectsElementaryRevision,
    ProjectsElementaryInvolveds,
    ProjectsElementary,
    ProjectsElementaryStudents,
    ProjectsContract,
    ProjectsKindergartenRevision,
    ProjectsKindergarten,
    ProjectsKindergartenInvolveds,
    ProjectPayment,
    ProjectsKindergartenStudents
  },
  data: () => ({
    initialComponent: '',
    tabs:
    {
      revision: 'projects-revision',
      elementaryRevision: 'projects-elementary-revision',
      kindergartenRevision: 'projects-kindergarten-revision',
      students: 'projects-students',
      elementaryStudents: 'projects-elementary-students',
      kindergartenStudents: 'projects-kindergarten-students',
      institution: 'projects-institution',
      advisors: 'projects-advisors',
      involveds: 'projects-involveds',
      elementaryInvolveds: 'projects-elementary-involveds',
      kindergartenInvolveds: 'projects-kindergarten-involveds',
      payment: 'project-payment',
      projects: 'projects',
      elementaryProjects: 'projects-elementary',
      kindergartenProjects: 'projects-kindergarten',
      researcher: 'projects-researcher',
      contract: 'projects-contract'
    },
    component: 'projects-revision',
    levelOptions: {
      high: 'projects-revision',
      middle: 'projects-elementary-revision',
      middle_cat_1: 'projects-elementary-revision',
      middle_cat_2: 'projects-elementary-revision',
      middle_cat_3: 'projects-elementary-revision',
      middle_cat_eja: 'projects-elementary-revision',
      grade: 'projects-kindergarten-revision'
    },
    projectInfo: {},
    statusSlug: null,
    internal_feedback: '',
    internal_feedback_empty: false
  }),
  created() {
    this.getInfos()
    this.switchLevels()
  },
  computed: {
    ...mapGetters({
      projectStatus: 'projectsStatusChange'
    })
  },
  methods: {
    menuSelected(e) {
      this.fields = e
    },
    navSelected(value) {
      this.component = this.tabs[value]
    },
    switchLevels() {
      return this.levelOptions[this.projectInfo.level]
    },
    internalFeedback(txt) {
      this.internal_feedback = txt
    },
    internalFeedback_empty(e) {
      this.internal_feedback_empty = e
    },
    projectPenalty(txt) {
      this.internal_feedback = txt
    },
    project_penalty_empty(e) {
      this.internal_feedback_empty = e
    },
    async statusChange(statusObj) {
      try {
        const { internal_status, external_status } = statusObj
        await this.$http.patch(`/status/project/${ this.projectInfo.id }`, {
          internal_status_id: internal_status
        })
        if (external_status) {
          await this.$http.patch(`/status/project/${ this.projectInfo.id }`, {
            status_id: external_status
          })
        }
        this.getInfos()
      } catch (error) {
        console.error(error.message)
      }
    },
    async getInfos() {
      const { data: response } = await this.$http.get(`/project/${ this.$route.params.id }`)
      this.projectInfo = response
      this.statusSlug = this.projectInfo.internal_status ? this.projectInfo.internal_status.slug : 'N/A'
      this.component = this.switchLevels()
    }
  }
}
</script>

<style lang="scss" scoped>
.container-app {
  .templates-container {
    margin-bottom: 3%;
    display: grid;
    grid-template-columns: 30%  70%;
    gap:16px;

    width: 100%;
    min-height: 100vh;
    margin-right: 2%;
    margin-top: 2%;

    .left {
      align-self: stretch;
    }
  }
}
</style>
