<template>
  <div class="indicators__wrapper">
    <button @click="active = !active" :class="{active: active, disabled: !active}">
      <span>Indicadores de solicitações</span>
      <img v-if="!active" src="@/assets/Shape.svg" alt="">
      <img v-else src="@/assets/shape2.png" alt="">
    </button>
    <div v-if="active" class="info__wrapper">
      <div class="infos">
        <p class="infos__item point recieve">
          <span>Recebidas</span>
          <span>{{this.requisicoesTotal}}</span>
        </p>
        <p class="infos__item point attention">
          <span>Pendente de análise</span>
          <span>{{this.indicatorsData.pendente_de_analise}}</span>
        </p>
        <p class="infos__item point analisis">
          <span>Em análise</span>
          <span>{{this.indicatorsData.em_analise}}</span>
        </p>
        <p class="infos__item point rejection">
          <span>Não aceita</span>
          <span>{{this.indicatorsData.nao_aceita}}</span>
        </p>
        <p class="infos__item point accept">
          <span>Aceita</span>
          <span>{{this.indicatorsData.aceita}}</span>
        </p>
        <p class="infos__item point waiting">
          <span>Aguardando resposta</span>
          <span>{{this.indicatorsData.aguardando_resposta}}</span>
        </p>
        <p class="infos__item point sended">
          <span>Resposta enviada</span>
          <span>{{this.indicatorsData.resposta_enviada}}</span>
        </p>
      </div>
      <div>
        <div v-for="item in this.indicatorsData" :key="item.id" class="infos__general" v-if="item.level">
          <p v-if="item.level=='all'" class="infos__item first">
            <span><span class="tooltip">Número de vagas restantes / número de vagas suportadas</span><img src="@/assets/info2-blue.png" alt="" /> Vagas restantes:</span>
            <span>{{item.vagas_restantes}}/{{item.total_disponiveis}}</span>
          </p>
          <p v-else class="infos__item">
            <span><span class="tooltip">Número de vagas concedidas / número de vagas suportadas</span>{{ item.level_name }}</span>
            <span>{{ item.total_granted}}/{{item.total_disponiveis}}</span>
          </p>

        </div>
        
      </div>      
    </div>
  </div>
</template>

<script>
export default {
  name: 'Indicators',
  data: () => ({
    active: false,
    indicatorsData: {},
    vagasRestantes: null,
    suportadasTotal: null,
    requisicoesTotal: null
  }),
  watch: {
    active() {
      this.getIndicatorsData()
    }
  },
  methods: {
    async getIndicatorsData() {
      const { data } = await this.$http.get('/fair/search/indicators')
      this.indicatorsData = data
      const concedidasTotal = (data.numero_de_vagas_concedidas_infantil + data.numero_de_vagas_concedidas_fundamental + data.numero_de_vagas_concedidas_medio_tecnico)
      this.suportadasTotal = (data.numero_de_vagas_suportadas_infantil + data.numero_de_vagas_suportadas_fundamental + data.numero_de_vagas_suportadas_medio_tecnico)
      this.vagasRestantes = (this.suportadasTotal - concedidasTotal)
      this.requisicoesTotal = (parseInt(data.pendente_de_analise) + parseInt(data.em_analise) + parseInt(data.inscricao_em_andamento) + parseInt(data.aguardando_resposta) + parseInt(data.resposta_enviada))
    }
  }
}
</script>

<style lang="scss" scoped>
.indicators__wrapper {
  position: relative;

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    width: 292px;
    height: 38px;
    border: 1px solid #1B778B;
    border-radius: 10px;
    transition: all .3s;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      margin-right: 20px;
    }
  }

  .active {
    color: white;
    background-color: #1B778B;
  }

  .disabled {
    color: #1B778B;
    background-color: white;
  }

  .info__wrapper {
    background: #FFFFFF;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    left: 0px;
    padding: 20px 15px;
    position: absolute;
    top: 45px;
    width: 292px;
    z-index: 3;

    .infos {
      border-bottom: 1px solid #CDCBCB;

      &__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;
        padding: 0 10px;

        span:first-of-type {
          color: $almostBlack;
        }
      }

      .point {
        span:first-of-type {
          align-items: center;
          &::before {
            content: '';
            background-color: red;
            border-radius: 50%;
            display: inline-block;
            height: 10px;
            margin-right: 5px;
            position: relative;
            width: 10px;
          }
        }
      }

      .recieve {
        span:first-of-type {
          &::before {
            background-color:  #686868;
          }
        }
        span:last-of-type {
          color: #686868;
        }
      }

      .attention {
        span:first-of-type {
          &::before {
            background-color: $attention;
          }
        }
        span:last-of-type {
          color: $attention
        }
      }

      .analisis {
        span:first-of-type {
          &::before {
            background-color: #319CB4;
          }
        }
        span:last-of-type {
          color: #319CB4;
        }
      }

      .rejection {
        span:first-of-type {
          &::before {
            background-color: #F9537B;
          }
        }
        span:last-of-type {
          color: #F9537B;
        }
      }

      .accept {
        span:first-of-type {
          &::before {
            background-color: #1CD24F;
          }
        }
        span:last-of-type {
          color: #1CD24F;
        }
      }

      .waiting {
        span:first-of-type {
          &::before {
            background-color: #8E24AA;
          }
        }
        span:last-of-type {
          color: #8E24AA;
        }
      }

      .sended {
        span:first-of-type {
          &::before {
            background-color: #1B778B;
          }
        }
        span:last-of-type {
          color: #1B778B;
        }
      }
    }

    .infos__item {
        span:last-of-type {
          font-size: 16px;
          color: #1B778B;
        }
      }

    .infos__general {
      margin-top: 15px;

      .infos__item {
        span:last-of-type {
          font-size: 16px;
          color: #1B778B;
        }
      }

      .first {
        align-items: center;
        display: flex;
        justify-content: space-between;
        color: #1B778B;
        font-weight: 600;
        position: relative;

        span:first-of-type {
          display: flex;
          align-items: center;
          color: #1B778B;

          img {
            margin-right: 5px;
          }
        }

        &:hover {
          .tooltip {
            visibility: visible;
          }
        }
      }

      .tooltip {
        background-color: #555555;
        border-radius: 2px;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 15px;
        color: #FFFFFF !important;
        padding: 5px 10px;
        position: absolute;
        bottom: 30px;
        left: 0;
        visibility: hidden;
        width: 250px;
        z-index: 1;
      }
    }
  }
}
</style>
