<template>
  <div class="container-app">
    <HeaderDefault />
    <StudentVacanciesTemplate />
  </div>
</template>

<script>
import { HeaderDefault } from '@/components/organisms'
import { StudentVacanciesTemplate } from '@/components/templates'

export default {
  name: 'panel-student-vacancies',
  components: { HeaderDefault, StudentVacanciesTemplate }
}
</script>
