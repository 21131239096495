<template>
  <form @submit.prevent class="form">
    <v-row no-gutters class="flex-column align-start mb-5 form__info">
      <text-info-title text="informations" />
      <span class="text-left">{{$t('editionInformations')}}</span>
      <br>
      <span class="text-left">{{$t('activeEditionWarn')}}</span>
    </v-row>
    <input-default
      :class="{warning: checkErrorIfContains('name')}"
      class="mt-4"
      label="editionName"
      id="name"
      placeholder="editionNamePlaceholder"
      type="text"
      v-model="form.name"
      @input="resetErrors"
    />
    <input-default
      :class="[{warning: checkErrorIfContains('year')}, 'mt-7']"
      label="year"
      id="year"
      placeholder="yearPlaceholder"
      type="number"
      v-model="form.year"
      @input="resetErrors"
    />
    <title-and-info
      :class="[{warning: checkErrorIfContains('format')}, 'mt-7']"
      label="format"
      id="formatQuestion"
      text="editionFormatInfo"
    />
    <input-radio-group
      id="format"
      v-model="form.format"
      :options="format_options"
      @change="resetErrors"
    />
    <div class="divider mt-8"></div>
    <div class="afiliates mt-8 text-left">
      <h3 class="title">{{$t('affiliatedFairs')}}</h3>
      <p class="description">{{$t('editionAffiliatedFairDescription')}}</p>
      <div>
        <h4 class="subtitle mt-6 mb-8">{{$t('mostratecAffiliationRegistrationPeriod')}}</h4> <!-- Primeiro campo -->
        <v-row no-gutters class="mb-3 flex-column flex-sm-row justify-start">
          <div :class="[{warning: checkErrorIfContains('inscriptionStarts')},
          'col col-sm-5 col-md-4 col-lg-3 mb-10 mb-sm-0']">
            <input-calendar data="inscriptionStarts" label="start" @dateChange="dateChange" />
          </div>
          <div :class="[{warning: checkErrorIfContains('inscriptionEnds')}, 'col col-sm-5 col-md-4 col-lg-3']">
            <input-calendar data="inscriptionEnds" label="end" @dateChange="dateChange"/>
          </div>
        </v-row>
        <text-info text="mostratecAffiliationRegistrationPeriodInfo" />
      </div>
      <div>
        <h4 class="subtitle mt-6 mb-8">{{$t('affiliatedFairsAnalysisPeriod')}}</h4>
        <v-row no-gutters class="mb-3 flex-column flex-sm-row justify-start">
          <div :class="[{warning: checkErrorIfContains('analisysStarts')},
          'col col-sm-5 col-md-4 col-lg-3 mb-10 mb-sm-0']">
            <input-calendar data="analisysStarts" label="start" @dateChange="dateChange" />
          </div>
          <div :class="[{warning: checkErrorIfContains('analisysEnds')}, 'col col-sm-5 col-md-4 col-lg-3']">
            <input-calendar data="analisysEnds" label="end" @dateChange="dateChange" />
          </div>
        </v-row>
        <text-info text="affiliatedFairsAnalysisPeriodInfo" />
      </div>
      <div>
        <h4 class="subtitle mt-6 mb-8">{{$t('affiliatedFairsResponsePeriod')}}</h4>
        <v-row no-gutters class="mb-3 flex-column flex-sm-row justify-start">
          <div :class="[{warning: checkErrorIfContains('responseStarts')},
          'col col-sm-5 col-md-4 col-lg-3 mb-10 mb-sm-0']">
            <input-calendar data="responseStarts" label="start" @dateChange="dateChange" />
          </div>
          <div :class="[{warning: checkErrorIfContains('responseEnds')}, 'col col-sm-5 col-md-4 col-lg-3']">
            <input-calendar data="responseEnds" label="end" @dateChange="dateChange" />
          </div>
        </v-row>
        <text-info text="affiliatedFairsResponsePeriodInfo" />
      </div>
      <div>
        <h4 class="subtitle mt-6 mb-8">{{$t('mostratecProjectAfilliateFairRegistrationPeriod')}}</h4>
        <v-row no-gutters class="mb-3 flex-column flex-sm-row justify-start">
          <div :class="[{warning: checkErrorIfContains('projectStarts')}, 'col col-sm-5 col-md-4 col-lg-3 mb-10 mb-sm-0']">
            <input-calendar data="projectStarts" label="start" @dateChange="dateChange" />
          </div>
          <div :class="[{warning: checkErrorIfContains('projectEnds')}, 'col col-sm-5 col-md-4 col-lg-3']">
            <input-calendar data="projectEnds" label="end" @dateChange="dateChange" />
          </div>
        </v-row>
        <text-info text="mostratecProjectAfilliateFairRegistrationPeriodInfo" />
      </div>
      <div>
        <h4 class="subtitle mt-6 mb-8">{{$t('mostratecProjectSelectionRegistrationPeriod')}}</h4>
        <v-row no-gutters class="mb-3 flex-column flex-sm-row justify-start">
          <div :class="[{warning: checkErrorIfContains('selectionProjectStarts')},
          'col col-sm-5 col-md-4 col-lg-3 mb-10 mb-sm-0']">
            <input-calendar data="selectionProjectStarts" label="start" @dateChange="dateChange" />
          </div>
          <div :class="[{warning: checkErrorIfContains('selectionProjectEnds')},
          'col col-sm-5 col-md-4 col-lg-3']">
            <input-calendar data="selectionProjectEnds" label="end" @dateChange="dateChange" />
          </div>
        </v-row>
        {{selectionProjectEnds}}

        <text-info text="mostratecProjectSelectionRegistrationPeriodInfo" />
      </div>
      <div>
        <h4 class="subtitle mt-6 mb-8">{{$t('PeriodInWhichMostratecEventWillTakePlace')}}</h4>
        <v-row no-gutters class="mb-3 flex-column flex-sm-row justify-start">
          <div :class="[{warning: checkErrorIfContains('fairDateStarts')},
          'col col-sm-5 col-md-4 col-lg-3 mb-10 mb-sm-0']">
            <input-calendar data="fairDateStarts" label="Início" @dateChange="dateChange" />
          </div>
          <div :class="[{warning: checkErrorIfContains('fairDateEnds')},
          'col col-sm-5 col-md-4 col-lg-3']">
            <input-calendar data="fairDateEnds" label="Fim" @dateChange="dateChange" />
          </div>
        </v-row>
        <text-info text="PeriodInWhichMostratecEventWillTakePlaceInfo" />
      </div>
    </div>
    <button-default :onClick="checkForm" class="button mt-8">{{$t('save')}}</button-default>
    <feedback-warning v-if="errors.length" :text="textWarning" :onClick="resetErrors" color="#ff9800" />
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, InputRadioGroup, ButtonDefault, TextWarning, TextInfo, TextInfoTitle, FeedbackWarning, BadgeInfo, TitleAndInfo, InputCalendar, InputSelect
} from '@/components/atoms'

export default {
  name: 'General',
  components: {
    InputDefault, InputRadioGroup, ButtonDefault, TextWarning, TextInfo, TextInfoTitle, FeedbackWarning, BadgeInfo, TitleAndInfo, InputCalendar, InputSelect
  },
  data() {
    return {
      form: {
        name: '',
        year: '',
        format: null,
        inscriptionStarts: false,
        inscriptionEnds: false,
        analisysStarts: false,
        analisysEnds: false,
        responseStarts: false,
        responseEnds: false,
        projectStarts: false,
        projectEnds: false,
        selectionProjectStarts: false,
        selectionProjectEnds: false,
        fairDateStarts: false,
        fairDateEnds: false
      },
      startDateSelection: null,
      endDateSelection: null,
      startDateAffilate: null,
      endDateAffiliate: null,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),

      format_options: [
        {
          value: 'presencial',
          text: 'inPerson'
        },
        {
          value: 'virtual',
          text: 'virtual'
        },
        {
          value: 'hibrida',
          text: 'hibrid'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      editionId: 'editionId'
    })
  },
  methods: {
    ...mapActions({
      changeEdition: 'changeEdition'
    }),
    resetErrors() {
      if (this.errors.length) this.errors = []
    },
    dateChange(e, data) {
      this.form[data] = e
      this.resetErrors()
    },
    validateDate(begins, ends) {
      if (begins && ends) {
        if (!this.validateDateFormat(begins) || !this.validateDateFormat(ends)) {
          this.textWarning = 'invalidDateFormat'
          return false
        }
        begins = new Date(begins)

        if (this.form.year !== begins.getFullYear()) {
          this.textWarning = 'invalidEditionYear'
        }
        ends = new Date(ends)
        if (begins > ends) {
          this.textWarning = 'dateError'
          return false
        }
        return true
      }
    },
    validateDateFormat(date) {
      const [year, month, day] = date.split('-')
      return (Number(month) <= 12 && Number(day) <= 31 && year >= 1900)
    },
    checkForm() {
      this.errors = []
      for (const key in this.form) {
        const value = this.form[key]
        if (!value) this.errors.push(key)
      }
      for (const key in this.form) {
        if (key.includes('Starts')) {
          const begins = this.form[key]
          const ends = this.form[key.replace('Starts', 'Ends')]
          if (!this.validateDate(begins, ends)) {
            this.errors.push(key)
            this.errors.push(key.replace('Starts', 'Ends'))
          }
        }
      }
      if (!this.errors.length) {
        if (this.form.year.length != 4) {
          this.errors.push('year')
          this.textWarning = 'Informe um ano válido!'
          return false
        }
        this.$emit('openLoading')
        this.saveForm()
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    dateFormat() {
      this.startDateAffilate = new Date(this.form.projectStarts)
      this.startDateAffilate.setHours(this.startDateAffilate.getHours() + 4)
      this.endDateAffiliate = new Date(this.form.projectEnds)
      this.endDateAffiliate.setHours(this.endDateAffiliate.getHours() + 4)
      this.startDateSelection = new Date(this.form.selectionProjectStarts)
      this.startDateSelection.setHours(this.startDateSelection.getHours() + 4)
      this.endDateSelection = new Date(this.form.selectionProjectEnds)
      this.endDateSelection.setHours(this.endDateSelection.getHours() + 4)
    },
    async saveForm() {
      this.dateFormat()
      const createdEdition = await this.$http.post('/mostratec', {
        active: true,
        name_edition: this.form.name,
        year_edition: this.form.year,
        format_edition: this.form.format,
        fair_register_init: this.form.inscriptionStarts,
        fair_register_end: this.form.inscriptionEnds,
        analisys_init: this.form.analisysStarts,
        analisys_end: this.form.analisysEnds,
        fair_response_init: this.form.responseStarts,
        fair_response_end: this.form.responseEnds,
        project_date_init: this.startDateAffilate,
        project_date_end: this.endDateAffiliate,
        project_selection_date_init: this.startDateSelection,
        project_selection_date_end: this.endDateSelection,
        fair_date_init: this.form.fairDateStarts,
        fair_date_end: this.form.fairDateEnds
      })
      this.$store.commit('CHANGE_EDITION', createdEdition.data.id)
      this.$emit('menuSelected', 'vacancies')
      this.form.name = ''
      this.form.year = ''
      this.form.format = null
      this.form.inscriptionStarts = ''
      this.form.inscriptionEnds = ''
      this.form.analisysStarts = ''
      this.form.analisysEnds = ''
      this.form.responseStarts = ''
      this.form.responseEnds = ''
      this.form.projectStarts = ''
      this.form.projectEnds = ''
      this.form.selectionProjectEnds = ''
      this.form.selectionProjectStarts = ''
      this.form.fairDateStarts = ''
      this.form.fairDateEnds = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;

  &__info {
    span {
      line-height: 22px;
      color: $primary;
    }
  }

  .button {
    align-self: flex-end;
  }

  ::v-deep .warning .input-calendar {
    border-color: $attention !important;
  }

}

.divider {
  background: $primary;
  height: 1px;
}

.afiliates {
  .title {
    color: $primaryDark;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 5px;
    opacity: 0.8;
  }

  .description {
    line-height: 22px;
    color: $primary;
  }

  .subtitle {
    color: $almostBlack;
    line-height: 20px;
  }
}
.warning {
  color: $attention;
  ::v-deep label {
    color: $attention !important;
  }
}
</style>
