<template>
  <div>
    <div class="inner__wrapper">
      <div class="infos">
        <div class="info__item">
          <div class="data__wrapper">
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('fairPeriodicity')}}</h4>
                <span class="capitalize">{{cFairInfo.fair_frequency}}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('periodOfLastFair')}}</h4>
                <span>{{ cFairInfo.last_edition_init | formatDate }} {{$t('to')}} {{ cFairInfo.last_edition_end | formatDate}}</span>
              </div>
              <div class="data__right">
                <h4>{{$t('periodForNextFair')}}</h4>
                <span>{{ cFairInfo.next_edition_init | formatDate }} {{$t('to')}} {{ cFairInfo.next_edition_end | formatDate}}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('nextFairDateIsConfirmated')}}</h4>
                <template v-if="cFairInfo.next_edition_confirmed">
                  <span>{{$t('yes')}}</span>
                </template>
                <template v-else>
                  <span>{{$t('no')}}</span>
                </template>
              </div>
              <div class="data__right">
                <h4>{{$t('nextFairFormat')}}</h4>
                <span class="capitalize">{{cFairInfo.current_edition_format}}</span>
              </div>
            </div>
            <div class="data__row">
              <div class="data__left">
                <h4>{{$t('editionsFairNumber')}}</h4>
                <span>{{cFairInfo.editions_number}}</span>
              </div>
              <div class="data__right">
                <h4>{{$t('doesTheFairHaveCrc')}}</h4>
                <span v-if="cFairInfo.has_crc">{{$t('yes')}}</span>
                <span v-else>{{$t('no')}}</span>
              </div>
            </div>
            <div class="last__row">
              <h4>{{$t('educationLevelsInTheFair')}} </h4>
              <div class="badges">
                <span class="badge__info" v-if="cFairInfo.teaching_level.grade">{{$t('childEducationCompleted')}}</span>
                <span class="badge__info" v-if="cFairInfo.teaching_level.middle_cat_1">{{$t('elementarySchoolCategory1')}}</span>
                <span class="badge__info" v-if="cFairInfo.teaching_level.middle_cat_2">{{$t('elementarySchoolCategory2')}}</span>
                <span class="badge__info" v-if="cFairInfo.teaching_level.middle_cat_3">{{$t('elementarySchoolCategory3')}}</span>
                <span class="badge__info" v-if="cFairInfo.teaching_level.middle_cat_eja">{{$t('elementarySchoolCategoryEja')}}</span>
                <span class="badge__info" v-if="cFairInfo.teaching_level.high">{{$t('highSchoolAndOrTechnical')}}</span>
                <span class="badge__info" v-if="cFairInfo.teaching_level.university">{{$t('universityEducation')}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'LastEdition',
  props: {
    fairInfo: Object
  },
  computed: {
    cFairInfo() {
      if (this.fairInfo) {
        return this.fairInfo
      }
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.inner__wrapper {
  background: #FDFDFD;
  border-radius: 10px;

  .header {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 10px 0px 0px 10px;
    height: 57px;
    padding: 18px 22px;

    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primaryDark;
    }
  }

  .infos {
    padding: 10px 22px 10px;

    .info__item {
      margin-top: 25px;

      .data__wrapper {

        .data__row {
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 30px;
          padding-left: 5%;
          padding-right: 5%;

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          span {
            font-size: 14px;
            line-height: 18px;
            color: #686868;
          }
        }

        .last__row {
          padding-left: 5%;
          padding-right: 5%;
          margin-bottom: 30px;

          h4 {
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: $almostBlack;
          }

          .badges {
            padding-top: 14px;

            .badge__info {
              align-items: center;
              display: inline-flex;
              justify-content: center;
              background: $primary;
              border-radius: 49px;
              opacity: 0.8;

              color: $white;
              font-size: 14px;
              height: 28px;
              padding: 0px 10px;
              margin-right: 5px;
              margin-bottom: 10px
            }
          }
        }
      }
    }
  }
}
</style>
