<template>
  <div>
    <div class="data">
      <div class="data__left">
        <div class="data__row">
          <h4>{{ $t("projectTitle") }}</h4>
          <input-default
            v-if="this.open"
            :class="[
              { warning: checkErrorIfContains('project_title') },
              'mb-7',
              'teste',
            ]"
            id="projectName"
            placeholder="enterTheProjectName"
            type="text"
            max="250"
            v-model="project_title"
            :width="800"
          />
          <span v-else>{{ project_title }}</span>
        </div>
        <div class="data__row">
          <h4>{{ $t("researchAreaSingle") }}</h4>
          <span v-if="level === 'grade'"
            >Não há área de pesquisa para este nível de ensino.</span
          >
          <div v-else>
            <div v-if="(level === 'middle' || level === 'middle_cat_1' || level === 'middle_cat_2' || level === 'middle_cat_3' || level === 'middle_cat_eja')">
              <input-select
                v-if="open"
                id="areaSelect"
                :class="'mb-4'"
                :items="projectAreas"
                v-model="area.value"
                placeholder="Selecione a Área de pesquisa"
                :selected="area.text"
              />
              <label v-else class="area">
                <span class="area_name">{{ area.text }}</span></label
              >
            </div>
            <div v-if="level === 'high'">
              <input-select
                v-if="open"
                id="areaSelect"
                :class="'mb-4'"
                :items="areaOptions"
                v-model="area.value"
                placeholder="Selecione a Área de pesquisa"
                :selected="area.text"
              />
              <label v-else class="area">
                <span class="area_name">{{ area.text }}</span>
              </label>
            </div>
          </div>
        </div>
        <div class="data__row">
          <h4>{{ $t("doesItInvolveHumans") }}</h4>
          <RadioGroup
            v-if="this.open && level === 'high'"
            :class="['mb-2 title_checkbox']"
            :options="generalOptions"
            v-model="human_beings"
          />
          <span v-else>{{ human_beings === true ? "Sim" : "Não" }}</span>
        </div>
        <div class="data__row">
          <h4>Envolve agentes biológicos potencialmente perigosos?</h4>
          <RadioGroup
            v-if="this.open && level === 'high'"
            :class="['mb-2 title_checkbox']"
            :options="generalOptions"
            v-model="danger_biological_substances"
          />
          <span v-else>{{
            danger_biological_substances === true ? "Sim" : "Não"
          }}</span>
        </div>
        <div class="data__row">
          <h4>{{ $t("doesItInvolveControlledSubstances") }}</h4>
          <RadioGroup
            v-if="this.open && level === 'high'"
            :class="['mb-2 title_checkbox']"
            :options="generalOptions"
            v-model="controlled_substances"
          />
          <span v-else>{{
            controlled_substances === true ? "Sim" : "Não"
          }}</span>
        </div>
         <div class="data__row">
          <h4>Qual número da estande?</h4>
          <input-default
            v-if="this.open"
            type="number"
            v-model="booth"
            :width="800"
          />
          <span v-else>{{ booth }}</span>
        </div>
      </div>
      <div class="data__right">
        <div class="data__row">
          <h4>{{ $t("typeOfResearchDevelop") }}</h4>
          <RadioGroup
            v-if="level === 'high' && open"
            :class="['mb-2 btitle_checkbox']"
            :options="typeOptions"
            v-model="research_type"
          />
          <div v-else>
            <span v-if="research_type !== 'Não informado'">{{
              research_type === "cientifica" ? "Científica" : "Tecnológica"
            }}</span>
            <span v-else>{{ research_type }}</span>
          </div>
        </div>
        <div class="data__row">
          <h4>{{ $t("researchSubAreas") }}</h4>
          <div v-if="level === 'high'">
            <div v-if="Object.keys(projectInfo.sub_areas).length === 0">
              Não informado
            </div>
            <div v-else>
              <InputCheckboxProject
                v-if="area && this.open"
                :class="['mb-7 title_checkbox']"
                :dataAreas="areas[area.value]"
                @selecteds="sub_areas = $event"
                :actives="sub_areas_values"
                :one="false"
              />
              <div v-else v-for="(item, index) in sub_areas" :key="index">
                <label v-if="item.text" class="subarea">
                  <span class="subarea_name">{{ item.text }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-else>
            Não há subáreas de pesquisa para este nível de ensino.
          </div>
        </div>
        <div class="data__row">
          <h4>{{ $t("doesItInvolveVertebrateAnimals") }}</h4>
          <RadioGroup
            v-if="this.open && level === 'high'"
            :class="['mb-2 title_checkbox']"
            :options="generalOptions"
            v-model="vertebrate_animals"
          />
          <span v-else>{{ vertebrate_animals === true ? "Sim" : "Não" }}</span>
        </div>
        <div class="data__row">
          <h4>{{ $t("doesItInvolveBiologicalSubstances") }}</h4>
          <RadioGroup
            v-if="this.open && level === 'high'"
            :class="['mb-2 title_checkbox']"
            :options="generalOptions"
            v-model="biological_substances"
          />
          <span v-else>{{
            biological_substances === true ? "Sim" : "Não"
          }}</span>
        </div>
        <div class="data__row">
          <h4>{{ $t("doesItInvolveTissueFromHumans") }}</h4>
          <RadioGroup
            v-if="this.open && level === 'high'"
            :class="['mb-2 title_checkbox']"
            :options="generalOptions"
            v-model="human_or_animals_tissues"
          />
          <span v-else>{{
            human_or_animals_tissues === true ? "Sim" : "Não"
          }}</span>
        </div>
        <div class="data__row">
          <h4>{{ $t("doesItInvolveDangerousSubstances") }}</h4>
          <RadioGroup
            v-if="this.open && level === 'high'"
            :class="['mb-2 title_checkbox']"
            :options="generalOptions"
            v-model="equipments_danger"
          />
          <span v-else>{{ equipments_danger === true ? "Sim" : "Não" }}</span>
        </div>
      </div>
      <feedback-warning
        v-if="errors.length"
        :text="textWarning"
        :onClick="resetErrors"
        color="#ff9800"
      />
    </div>
    <div class="card-vacancies-cancel-and-save">
      <button class="button_cancel" v-if="open" @click="cancelSave">
        CANCELAR
      </button>
      <button-default class="button_save" v-if="open" :onClick="checkForm"
        >Salvar</button-default
      >
    </div>
  </div>
</template>

<script>
import { areaOptionsElementary, areaOptions, areas } from '@/utils/areas'
import {
  InputDefault,
  RadioGroup,
  InputCheckboxProject,
  InputSelect,
  ButtonDefault,
  textWarning,
  FeedbackWarning
} from '@/components/atoms'

export default {
  name: 'ProjectData',
  data() {
    return {
      errors: [],
      booth: null,
      project_title: '',
      research_type: '',
      human_beings: null,
      human_or_animals_tissues: null,
      biological_substances: null,
      danger_biological_substances: null,
      controlled_substances: null,
      equipments_danger: null,
      vertebrate_animals: null,
      level: '',
      area: '',
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      sub_areas: [],
      sub_areas_values: [],
      generalOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ],
      projectAreas: [
        { text: this.$t('art'), value: 'arts' },
        { text: this.$t('bio'), value: 'biology' },
        { text: this.$t('eduPedago'), value: 'education' },
        { text: this.$t('eletronicInfoRobo'), value: 'eletronic' },
        { text: this.$t('enge'), value: 'engineering' },
        { text: this.$t('filoso'), value: 'filosofy' },
        { text: this.$t('fis'), value: 'phisics' },
        { text: this.$t('geo'), value: 'geography' },
        { text: this.$t('his'), value: 'history' },
        { text: this.$t('incAcessTec'), value: 'inclusion' },
        { text: this.$t('qui'), value: 'chemistry' },
        { text: this.$t('lingPortLingStrangLit'), value: 'portuguese' },
        { text: this.$t('mat'), value: 'math' },
        { text: this.$t('meioAmb'), value: 'envirornment' },
        { text: this.$t('saud'), value: 'health' },
        { text: this.$t('socio'), value: 'sociology' },
        { text: this.$t('other'), value: 'other' }
      ],
      areaOptions,
      areas,
      typeOptions: [
        { label: this.$t('scientific'), value: 'cientifica' },
        { label: this.$t('technological'), value: 'tecnologia' }
      ]
    }
  },
  components: {
    InputDefault,
    RadioGroup,
    InputCheckboxProject,
    InputSelect,
    ButtonDefault,
    textWarning,
    FeedbackWarning
  },
  props: {
    projectInfo: Object,
    open: Boolean
  },
  mounted() {
    this.booth = this.projectInfo.booth
    this.level = this.projectInfo.level
    this.project_title = this.projectInfo.project_title
    this.research_type = this.projectInfo.research_type || 'Não informado'
    this.human_beings = this.projectInfo.human_beings
    this.human_or_animals_tissues = this.projectInfo.human_or_animals_tissues === null ? false : this.projectInfo.human_or_animals_tissues
    this.biological_substances = this.projectInfo.biological_substances
    this.danger_biological_substances = this.projectInfo.danger_biological_substances === null ? false : this.projectInfo.danger_biological_substances
    this.controlled_substances = this.projectInfo.controlled_substances
    this.equipments_danger = this.projectInfo.equipments_danger
    this.vertebrate_animals = this.projectInfo.vertebrate_animals
    const selectedArea = this.projectInfo.level === 'middle'
      ? areaOptionsElementary.find(
        (item) => item.value == this.projectInfo.area
      ) || { value: 'other', text: this.projectInfo.area }
      : areaOptions.find((item) => item.value == this.projectInfo.area)
    this.area = selectedArea || 'Não informado'
    const subAreas = this.projectInfo.sub_areas
      ? this.projectInfo.sub_areas.sub_areas
      : 'Não informado'
    if (subAreas !== 'Não informado') {
      areas[selectedArea.value].forEach((item) => {
        if (subAreas.includes(item.value) && item.value !== 'others') {
          this.sub_areas.push(item)
        }
      })
      if (subAreas.includes('others')) {
        const otherIndex = subAreas.indexOf('others') + 1
        const otherSubArea = subAreas[otherIndex]
        this.sub_areas.push({ text: otherSubArea, value: otherSubArea })
      }
    }
    this.sub_areas.forEach((el) => {
      this.sub_areas_values.push(el.value)
    })
  },
  methods: {
    checkForm() {
      this.resetErrors()
      if (!this.project_title) {
        this.errors.push('project_title')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }
      if (!this.errors.length) {
        this.editForm()
      }
    },

    async editForm() {
      const { id } = this.projectInfo
      const body = {
        project_title: this.project_title,
        area: this.area.value,
        research_type:
          this.research_type === 'Não informado' ? null : this.research_type,
        sub_areas: { sub_areas: this.sub_areas },
        vertebrate_animals: this.vertebrate_animals,
        human_beings: this.human_beings,
        biological_substances: this.biological_substances,
        danger_biological_substances: this.danger_biological_substances,
        human_or_animals_tissues: this.human_or_animals_tissues,
        controlled_substances: this.controlled_substances,
        equipments_danger: this.equipments_danger,
        booth: this.booth
      }
      try {
        await this.$http.put(`/project/${ id }`, body)
        location.reload()
      } catch (error) {
        console.log(error.message)
      }
    },
    cancelSave() {
      location.reload()
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    }
  }
}
</script>

<style lang="scss" scoped>
.data__right {
  margin-left: 50px;
}

.area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 17px;
  position: static;
  width: fit-content;
  height: 28px;
  left: 0px;
  top: 0px;
  background: #1b778b;
  opacity: 0.8;
  border-radius: 49px;
  flex: none;
  order: 0;
  flex-grow: 0;
  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.subarea {
  display: flex;
  flex-direction: row;
  padding: 5px 19px;
  width: fit-content;
  height: 28px;
  background: #319cb4;
  border-radius: 49px;
  opacity: 0.8;
  margin-top: 10px;

  &_name {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.data {
  margin-left: 10%;
  margin-top: 35px;
  display: flex;
  font-size: 16px;
  color: #686868;

  &__row {
    width: 320px;
    margin-bottom: 35px;
    h4 {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #303030;
      margin-bottom: 10px;
    }
  }
  &__rowLarge {
    width: 520px;
    margin-bottom: 35px;
    margin-left: 6px;
    h4 {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #303030;
      margin-bottom: 10px;
    }
  }
  &__align {
    width: 520px;
    margin-bottom: 35px;
    margin-left: 46px;
  }
  .warning {
    color: $attention;
    border-bottom-color: $attention;
  }
  .separator {
    background-color: $primary;
    height: 1px;
    margin-top: 5px;
    margin-bottom: 30px;
    width: 81.17%;
  }
}

.card-vacancies-cancel-and-save {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5%;
}

.button_save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  margin: 0px 10px;

  width: 106px;
  height: 36px;
  left: 1222px;
  top: 126px;

  background: #26768d;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-transform: uppercase;

  color: #ffffff;
}

.button_cancel {
  box-sizing: border-box;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  width: 113px;
  height: 36px;
  left: 1110px;
  top: 126px;

  border: 1px solid #26768d;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 600;

  color: #26768d;
}
</style>
