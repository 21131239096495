<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('lastFairEditionData')}}</span>
      </div>
      <div class="form__info">
        <text-warning :text="`considerAllFieldObligatory`" />
      </div>
      <RadioGroup
        :class="[{warning: checkErrorIfContains('fairFrequency')}, 'mb-2']"
        label= fairPeriodicity
        :options="fairFrequencyOptions"
        v-model="form.fairFrequency"
        @change="resetErrors"
      />
      <div>
        <h4 class="subtitle mb-8">{{$t('periodOfLastFair')}}</h4>
        <v-row no-gutters class="flex-column flex-sm-row justify-start">
          <div :class="{warning: checkErrorIfContains('lastFairStarts')}">
            <input-calendar v-model="form.lastFairStarts" data="lastFairStarts" label="lastFairEdStart" @dateChange="dateChange" />
          </div>
          <div :class="{warning: checkErrorIfContains('lastFairEnds')}">
            <input-calendar v-model="form.lastFairEnds" data="lastFairEnds" label="lastFairEdEnd" @dateChange="dateChange"/>
          </div>
        </v-row>
      </div>
      <div class="my-7">
        <h4 class="subtitle mb-8">{{$t('periodForNextFair')}}</h4>
        <v-row no-gutters class="flex-column flex-sm-row justify-start">
          <div :class="{warning: checkErrorIfContains('nextFairStarts')}">
            <input-calendar v-model="form.nextFairStarts" data="nextFairStarts" label="lastFairEdStart" @dateChange="dateChange"/>
          </div>
          <div :class="{warning: checkErrorIfContains('nextFairEnds')}">
            <input-calendar v-model="form.nextFairEnds" data="nextFairEnds" label="lastFairEdEnd" @dateChange="dateChange"/>
          </div>
        </v-row>
      </div>
      <RadioGroup
        :class="[{warning: checkErrorIfContains('nextFairDate')}, 'mb-2']"
        label= whatsIsTheNextFairDate
        :options="nextFairDateOptions"
        v-model="form.nextFairDate"
        @change="resetErrors"
      />
      <RadioGroup
        :class="[{warning: checkErrorIfContains('fairFormat')}, 'mb-2']"
        label= format
        :options="fairFormatOptions"
        v-model="form.fairFormat"
        class="my-1"
        @change="resetErrors"
      />
      <input-masked
      :class="[{warning: checkErrorIfContains('editions')}, 'mb-7']"
          label= editionsFairNumber
          id="editionNumber"
          placeholder="0 .. 999"
          :mask="['###']"
          type="number"
          v-model="form.editions"
          @input="resetErrors"
      />

      <RadioGroup
        :class="[{warning: checkErrorIfContains('crc')}, 'mb-2']"
        label= doesTheFairHaveCrc
        :options="crcOptions"
        v-model="form.crc"
        @change="resetErrors"
      />
      <div class="areas">
        <p :class="[{warning: checkErrorIfContains('levels')}, 'areas__label']">{{$t('checkTheEducationalLevels')}}</p>
        <div class="areas__options">
          <label><input @change="resetErrors" v-model="teaching_level.grade" type="checkbox" >{{$t('childEducationategory0')}};</label>
          <label><input @change="resetErrors" v-model="teaching_level.middle_cat_1" type="checkbox" >{{$t('elementarySchoolCategory1')}};</label>
          <label><input @change="resetErrors" v-model="teaching_level.middle_cat_2" type="checkbox" >{{$t('elementarySchoolCategory2')}};</label>
          <label><input @change="resetErrors" v-model="teaching_level.middle_cat_3" type="checkbox" >{{$t('elementarySchoolCategory3')}};</label>
          <label><input @change="resetErrors" v-model="teaching_level.middle_cat_eja" type="checkbox" >{{$t('elementarySchoolCategoryEja')}};</label>
          <label><input @change="resetErrors" v-model="teaching_level.high" type="checkbox" >{{$t('highSchoolAndOrTechnical')}};</label>
        </div>
      </div>
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
      </div>
    </form>
    <feedback-warning v-if="errors.length" :text="textWarning" :onClick="resetErrors" color="#ff9800" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, ButtonDefault, FeedbackWarning, RadioGroup, TextWarning, InputCalendar, InputMasked

} from '@/components/atoms'

export default {
  name: 'FormLastFairEdition',
  components: {
    InputDefault, ButtonDefault, FeedbackWarning, RadioGroup, TextWarning, InputCalendar, InputMasked
  },
  data: () => ({
    form: {
      fairFrequency: '',
      nextFairDate: '',
      fairFormat: '',
      editions: '',
      crc: null,
      lastFairStarts: false,
      lastFairEnds: false,
      nextFairStarts: false,
      nextFairEnds: false
    },
    teaching_level: {
      grade: false,
      middle_cat_1: false,
      middle_cat_2: false,
      middle_cat_3: false,
      middle_cat_eja: false,
      high: false
    },
    errors: [],
    textWarning: 'warningAllFieldsAreObligatory',
    fairFrequencyOptions: [
      { label: 'yearly', value: 'anual' },
      { label: 'biennial', value: 'bianual' },
      { label: 'triennial', value: 'trienal' }
    ],
    nextFairDateOptions: [
      { label: 'confirmated', value: 'confirmed' },
      { label: 'forecastBecause', value: 'preview' }
    ],
    fairFormatOptions: [
      { label: 'inPerson', value: 'presencial' },
      { label: 'virtual', value: 'virtual' },
      { label: 'hibrid', value: 'hibrida' }
    ],
    crcOptions: [
      { label: 'yes', value: 'yes' },
      { label: 'no', value: 'no' }
    ],
    affiliatedFairsNumber: 0
  }),
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.teaching_level) {
      this.form.fairFrequency = this.fairAffiliation.fair_frequency
      this.form.lastFairStarts = this.fairAffiliation.last_edition_init
      this.form.lastFairEnds = this.fairAffiliation.last_edition_end
      this.form.nextFairStarts = this.fairAffiliation.next_edition_init
      this.form.nextFairEnds = this.fairAffiliation.next_edition_end
      this.form.nextFairDate = this.fairAffiliation.next_edition_confirmed ? 'confirmed' : 'preview'
      this.form.fairFormat = this.fairAffiliation.current_edition_format
      this.form.editions = this.fairAffiliation.editions_number
      this.form.crc = this.fairAffiliation.has_crc ? 'yes' : 'no'
      this.teaching_level = this.fairAffiliation.teaching_level
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      let atLeastOneLevel = 0
      this.resetErrors()
      this.textWarning = 'warningAllFieldsAreObligatory'
      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
        }
      }
      for (const key in this.teaching_level) {
        if (this.teaching_level[key]) {
          atLeastOneLevel += 1
        }
      }
      if (!atLeastOneLevel) {
        this.errors.push('levels')
      }
      if (this.errors.length) {
        return false
      }
      if (!atLeastOneLevel) {
        this.errors.push('levels')
        this.textWarning = this.$t('levelOfEducationError')
        return false
      }
      for (const key in this.form) {
        if (key.includes('Starts')) {
          const begins = this.form[key]
          const ends = this.form[key.replace('Starts', 'Ends')]
          if (!this.validateDate(begins, ends, key)) {
            this.errors.push(key)
            this.errors.push(key.replace('Starts', 'Ends'))
          }
        }
      }
      if (!this.errors.length) {
        this.saveForm()
      }
    },
    saveForm() {
      try {
        let screen
        const formData = this.$store.getters.fairAffiliation
        const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
        if (currentScreen < 5) {
          screen = 5
        } else {
          screen = currentScreen
        }
        const newData = {
          ...formData,
          fair_frequency: this.form.fairFrequency,
          last_edition_init: this.form.lastFairStarts,
          last_edition_end: this.form.lastFairEnds,
          next_edition_init: this.form.nextFairStarts,
          next_edition_end: this.form.nextFairEnds,
          next_edition_confirmed: this.form.nextFairDate === 'confirmed',
          editions_number: +this.form.editions,
          has_crc: this.form.crc === 'yes',
          current_edition_format: this.form.fairFormat,
          teaching_level: this.teaching_level,
          currentScreen: screen
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    dateChange(e, data) {
      this.form[data] = e
      this.resetErrors()
    },
    validateDate(begins, ends, type) {
      if (begins && ends) {
        if (!this.validateDateFormat(begins) || !this.validateDateFormat(ends)) {
          this.textWarning = 'invalidDateFormat'
          return false
        }
        const actualDate = new Date()
        begins = new Date(begins)
        ends = new Date(ends)
        if (begins > ends) {
          this.textWarning = 'dateError'
          return false
        }
        if (type.includes('last') && (begins > actualDate || ends > actualDate)) {
          this.textWarning = 'lastFairError'
          return false
        }
        if (type.includes('next') && (begins < actualDate || ends < actualDate)) {
          this.textWarning = 'nextFairError'
          return false
        }
        return true
      }
    },
    validateDateFormat(date) {
      const [year, month, day] = date.split('-')
      return (Number(month) <= 12 && Number(day) <= 31 && year >= 1900)
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.areas {
  &__label {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: $almostBlack;
  }

  &__options {
    display: flex;
    flex-direction: column;

    label {
      align-items: center;
      display: flex;
      color: $almostBlack;
      font-size: 16px;
      margin-bottom: 5px;

      input {
        zoom: 1.5;
        margin-right: 5px;
      }
    }
  }
}
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }

    .form__title__complement {
      font-weight: 400;
      margin-left: 11px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .subtitle {
    color: $almostBlack;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  ::v-deep .warning .input-calendar {
    border-color: $attention !important
  }
}

.warning {
  color: $attention;
  ::v-deep label {
    color: $attention !important;
  }
}
</style>
