]<template>
  <div class="input__checkbox">
    <template v-for="(data, index) in dataAreas">
      <div class="center" :key="index">
        <input type="checkbox" :id="index" :value="data.value" v-model="selecteds" />
        <span>
          <label :for="index">{{ $t(data.text) }}</label>
        </span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'InputCheckboxProject',
  data() {
    return {
      selecteds: [],
      first: true
    }
  },
  props: {
    dataAreas: Array,
    text: String,
    actives: Array,
    one: {
      Type: Boolean,
      default: true
    }
  },
  mounted() {
    this.selecteds = this.actives
  },
  watch: {
    selecteds() {
      if (this.selecteds.length > 1) {
        if (this.one) {
          this.selecteds.shift()
        }
      }
      this.$emit('selecteds', this.selecteds)
    },
    dataAreas() {
      this.selecteds = []
    },
    actives() {
      this.selecteds = this.actives
    }
  }
}
</script>

<style lang="scss" scoped>
.input__checkbox {
  display: flex;
  flex-direction: column;

  input[type="checkbox"] {
    zoom: 1.8;
  }
  .center {
    display: flex;
    align-items: center;
    margin-top: 11px;
  }

  span {
    flex: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-left: 10px;
    max-width: 728px;
  }
}

.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  &.warning {
    color: $attention !important;
  }
}

.warning {
  span {
    color: $attention;
    border-bottom-color: $attention;
  }
}
</style>
