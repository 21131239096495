<template>
  <div class="wrapper">
      <div class="line">
        <heading-three class="description">{{$t('subtitleDescription')}}</heading-three>
        <status-with-tooltip
          statusText="sketch"
          theme="grey_neutral"
          tooltipText="sketchDescription"
        />
        <status-with-tooltip
          statusText="pendingAcceptStatus"
          theme="warn"
          tooltipText="pendingAcceptDescription"
        />
        <status-with-tooltip
          statusText="notAccept"
          theme="red_reject"
          tooltipText="notAcceptDescription"
        />
        <status-with-tooltip
          statusText="registrationComplete"
          theme="green_accept"
          tooltipText="registrationCompleteDescription"
        />

        <status-with-tooltip
          statusText="pendingRevision"
          theme="warn"
          tooltipText="pendingRevisionDescription"
        />
        <status-with-tooltip
          statusText="underReview"
          theme="blue_analisis"
          tooltipText="underReviewDescription"
        />
        <status-with-tooltip
          statusText="reviewAccepted"
          theme="green_accept"
          tooltipText="reviewAcceptedDescription"
        />
        <status-with-tooltip
          statusText="reviewDenied"
          theme="red_reject"
          tooltipText="reviewDeniedDescription"
        />
        <status-with-tooltip
          statusText="waitingPayment"
          theme="purple_waiting"
          tooltipText="waitingPaymentDescription"
        />
        <status-with-tooltip
          statusText="reviewCanceled"
          theme="red_reject"
          tooltipText="reviewCanceledDescription"
        />
      </div>
  </div>
</template>

<script>
import { HeadingThree, StatusWithTooltip } from '@/components/atoms/'

export default {
  name: 'StudentStatusDescription',
  components: { HeadingThree, StatusWithTooltip }
}
</script>

<style lang="scss" scoped>
.wrapper {
  align-items: flex-end;
  width: 58%;
  display: flex;
  margin-right: 29px;
}
.description {
  width: fit-content;
  font-weight: 75;
}
.line{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: flex-end;
}
</style>
