<template>
  <div v-if="modal" class="modal__outer" @click.self="close">
    <div class="modal__inner">
      <div class="header">
        <div class="close" @click="close">x</div>
      </div>
      <div class="filter">
        <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
        <span>Filtrar</span>
      </div>
      <div class="modal__body">
        <input-select
          :class="['mb-7']"
          id="status"
          :value= form.active
          :items="status"
          :label="'status'"
          @input="form.active=$event"
        />
        <input-default
          :class="['mb-7']"
          label="name"
          id="name"
          placeholder=""
          type="text"
          v-model="form.name"
        />
        <input-default
          :class="['mb-7']"
          label="email"
          id="email"
          placeholder=""
          type="text"
          v-model="form.email"
        />
        <input-select
          :class="['mb-7']"
          id="status"
          :value= form.active
          :items="profile"
          :label="'Perfil'"
          v-model="form.role"
          @input="actualRole=$event"
        />
        <div class="button mt-10 text-center">
          <button-default class="button" :onClick="filter">{{$t('filter')}}</button-default>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InputDefault, ButtonDefault, InputSelect } from '@/components/atoms'
import { HeaderModal } from '@/components/organisms'

export default {
  name: 'ModalFilterUser',
  data() {
    return {
      form: {
        active: '',
        name: '',
        email: '',
        role: ''
      },
      profile: [
        { value: '', text: 'Todos' },
        { value: 'admin', text: 'Admin' },
        { value: 'secretaria', text: 'Secretaria' },
        { value: 'crc', text: 'CRC' },
        { value: 'avaliador', text: 'Avaliador' },
        { value: 'aluno_processo_selecao', text: 'Aluno processo seleção' },
        { value: 'feira_afiliada', text: 'Feira afiliada' },
        { value: 'aluno_feira_afiliada', text: 'Aluno' },
        { value: 'restaurante', text: 'Restaurante' },
        { value: 'credenciamento', text: 'Credenciamento' },
        { value: 'apm_credenciamento', text: 'APM Credenciamento' }
      ],
      status: [
        { value: '', text: 'Todos' },
        { value: true, text: 'Ativo' },
        { value: false, text: 'Inativo' }
      ]
    }
  },
  mounted() {
    document.addEventListener('keyup', (event) => {
      if (event.code === 'Enter' || event.key === 'Enter') {
        return this.filter()
      }
    })
  },
  watch: {
    modal() {
      this.form.active = ''
      this.form.name = ''
      this.form.email = ''
      this.form.role = ''
    }
  },
  components: {
    HeaderModal, InputDefault, ButtonDefault, InputSelect
  },
  props: {
    modal: Boolean
  },
  methods: {
    close() {
      this.$emit('close', false)
    },
    filter() {
      this.$emit('filter', this.form)
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal__outer {
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  left: 0px;
  position: fixed;
  height: 100%;
  top: 0px;
  width: 100%;
  z-index: 5;

  .modal__inner {
    background-color: $white;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding: 25px;
    flex: 1;
    width: 649px;

    .modal__body {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      width: 100%;
      max-height: 100%;

      .search__inputs {
        margin: 5px 0px 15px 0px;

        .search__item {
          .badges {
            margin-top: 15px;

            span {
              align-items: center;
              display: inline-flex;
              justify-content: center;
              background: $primary;
              border-radius: 49px;
              opacity: 0.8;
              color: $white;
              font-weight: bold;
              font-size: 14px;
              min-width: 79px;
              height: 28px;
              padding: 0px 8px;

              &:not(:last-of-type) {
                margin-right: 10px;
              }

              button {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.filter {
  align-items: center;
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: $primary;
  border-bottom: 1px solid $primary;
  padding-bottom: 12px;
}
.close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
</style>
