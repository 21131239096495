<template>
  <div>
    <form @submit.prevent class="form">
      <text-warning text="allFieldsAreObligatory" />
      <input-default
        :class="[{warning: checkErrorIfContains('userName')}, 'mb-7']"
        label="responsibleName"
        id="userName"
        placeholder="resonsablePersonForFillingInPlaceholder"
        type="text"
        max="150"
        v-model="form.userName"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('name')}, 'mb-7']"
        label="fairName"
        id="name"
        placeholder="inputFairName"
        type="text"
        max="150"
        v-model="form.name"
        @input="resetErrors"
      />
      <input-select-location
        :class="[{warning: checkErrorIfContains('country')}, 'mb-7']"
        id="country"
        :items="countries"
        v-model="form.country"
        label=country
        :onEnter="() => setLocalization('country', form.country)"
        @input="resetErrors"
      />
      <input-select-location
        v-if="form.country == 'Brazil'"
        :class="[{warning: checkErrorIfContains('state')}, 'mb-7']"
        id="state"
        :items="states"
        v-model="form.state"
        label="state"
        :onEnter="() => setLocalization('state', form.state)"
        @input="resetErrors"
      />
      <input-select-location
        v-if="form.country == 'Brazil' && form.state && cities"
        :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
        id="state"
        :items="cities"
        v-model="form.city"
        label="city"
        :onEnter="() => setLocalization('city', form.city)"
        @input="resetErrors"
      />
      <input-default
        v-if="form.country && form.country != 'Brazil'"
        :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
        label="City"
        id="city"
        placeholder="Input your city"
        type="text"
        max="150"
        v-model="form.city"
        @input="resetErrors"
      />
      <div v-if="form.country == 'Brazil'">
        <input-masked
          :class="[{warning: checkErrorIfContains('cnpj')}]"
          required
          label="cpfCnpj"
          id="cnpj-cpf"
          placeholder="inputCnpjCpf"
          type="text"
          v-model="form.cnpj"
          @input="resetErrors"
          :mask="['###.###.###-##', '##.###.###/####-##']"
        />
        <text-info class="mb-2" text="initialRegisterCpfCnpjTextInfo" />
      </div>
      <input-default
        v-if="form.country && form.country != 'Brazil'"
        :class="[{warning: checkErrorIfContains('cnpj')}, 'mb-7']"
        label="Identification number"
        id="codigo"
        placeholder=initialRegisterIdentificationNumber
        type="number"
        max="20"
        v-model="form.cnpj"
        @input="resetErrors"
      />
      <input-default
        :class="{warning: checkErrorIfContains('email')}"
        label="email"
        id="email"
        placeholder="inputEmailToConfirmRegistration"
        type="text"
        max="150"
        v-model="form.email"
        @input="resetErrors"
      />
      <text-info class="mb-2" text="initialRegisterEmailTextInfo" />
      <input-default
        :class="[{warning: checkErrorIfContains('password') || checkErrorIfContains('confirmPassword')}]"
        label="passwordLoginLabel"
        id="password"
        placeholder="inputPasswordToLogin"
        type="password"
        max="30"
        v-model="form.password"
        @input="resetErrors"
      />
      <text-info text="initialRegisterPasswordTextInfo" />
      <input-default
        :class="[{warning: checkErrorIfContains('confirmPassword')}, 'mb-7']"
        label="passwordConfirm"
        id="confirmPassword"
        placeholder="passwordConfirm"
        type="password"
        max="30"
        v-model="form.confirmPassword"
        @input="resetErrors"
      />
      <div class="flex">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('submit')}}</button-default>
      </div>
      <modal-default title="E-mail enviado!" @closeModal="closeModal" :modal="modal">
        <div class="modal__body">
          <div class="icon">
            <img src="@/assets/email-send.png" alt="E-mail enviado a MostraTec" />
          </div>
          <div class="text">
            <p slot="text">{{$t('confirmationEmailSent')}}</p>
          </div>
        </div>
      </modal-default>
      <!-- Inserção de Modal -->
      <modal-loading :modal="loading" />
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import jwt_decode from 'jwt-decode'
import cpf from 'node-cpf'
import cnpj from 'node-cnpj'
import {
  InputDefault,
  InputMasked,
  InputSelectLocation,
  ButtonDefault,
  TextWarning,
  TextInfo,
  FeedbackWarning
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'
import validatePassword from '@/utils/validatePassword'
import { ModalDefault } from '../Modals'

export default {
  name: 'FormRegister',
  components: {
    InputDefault,
    InputMasked,
    InputSelectLocation,
    ButtonDefault,
    TextWarning,
    TextInfo,
    FeedbackWarning,
    ModalDefault
  },
  data() {
    return {
      form: {
        name: '',
        country: '',
        state: '',
        city: '',
        cnpj: '',
        email: '',
        password: '',
        confirmPassword: '',
        userName: ''
      },
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      modal: false,
      loading: false,
      timeOut: 0
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang',
      fairAffiliation: 'fairAffiliation'
    })
  },
  watch: {
    'form.state': function (newVal, oldVal) {
      this.cities = []
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ this.form.state }/municipios`
      )
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    }
  },
  methods: {
    ...mapActions({
      changeLang: 'changeLanguage',
      affiliationRequest: 'affiliationRequest'
    }),
    back() {
      this.$router.push('/')
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    closeModal() {
      this.modal = false
      this.$router.push('/login')
    },
    async checkForm() {
      this.resetErrors()
      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
          this.textWarning = 'warningAllFieldsAreObligatory'
        }
      }
      if (this.country != 'Brazil') {
        this.errors = this.errors.filter((item) => item != 'state')
      }
      if (!this.errors.length) {
        if (!this.validateCnpjCPF(this.form.cnpj)) {
          this.textWarning = 'invalidZipCode'
          return false
        }
        // TESTE
        /*
        if (!this.validateDuplicateCnpjCPF(this.form.cnpj)) {
          this.textWarning = 'duplicateCPFCNPJ'
          return false
        }
        */
        // FIM TESTE
        if (!this.validateEmail(this.form.email)) {
          this.textWarning = 'invalidEmail'
          return false
        }
        if (validatePassword(this.form.password)) {
          this.errors.push('password')
          this.textWarning = 'weakPassword'
          return false
        }
        if (!this.validadeConfimation(this.form.confirmPassword)) {
          this.textWarning = 'passwordsDontMatch'
          return false
        }
        await this.saveForm()
      }
    },
    /*
    async validateDuplicateCnpjCPF(value){
      // FUNCAO EM TESTE

      const validator = await this.$http.post('/user/validateDuplicateCpfCNPJ', {
          cpf_cnpj: String(this.form.cnpj).replace(/\D/g, ''),
          form: 'FormRegister'
        })

      //
      if (this.form.country != 'Brazil') {
        return true
      }
      if (cpf.validate(value) || cnpj.validate(value)) {
        return true
      }
      this.errors.push('cnpj')
      return false
    },
    */
    async saveForm() {
      this.loading = true
      // console.log('Aquiiiiiiiiiiiiiiii')
      try {
        const createdUser = await this.$http.post('/user', {
          email: this.form.email,
          password: this.form.password,
          language: this.lang,
          role: 'feira_afiliada',
          fair_name: this.form.name,
          country: this.form.country,
          state: this.form.state || 'International',
          city: this.form.city,
          cpf_cnpj: String(this.form.cnpj).replace(/\D/g, ''),
          name: this.form.userName
        })
        this.$store.commit('AFILLIATION_REQUEST', {
          user_id: createdUser.data.id,
          school_cnpj: this.form.cnpj,
          country: this.form.country,
          state: this.form.state,
          city: this.form.city,
          is_active: true
        })
        this.modal = true
      } catch (error) {
        this.loading = false
        this.modal = false
        if (
          error.response.data.errors[0].source.pointer
          && error.response.data.errors[0].source.pointer === 'email'
        ) {
          this.errors.push('email')
          this.textWarning = 'registrationFailedEmailAlreadyExists'
        }
        if (
          error.response.data.errors[0].source.pointer
          && (error.response.data.errors[0].source.pointer === 'cpf_cnpj'
          || error.response.data.errors[0].source.pointer === 'cpf'
          || error.response.data.errors[0].source.pointer === 'cpf_process_registration')
        ) {
          this.errors.push('cnpj')
          this.textWarning = this.form.country && this.form.country != 'Brazil'
            ? 'registrationFailedIcAlreadyExists'
            : 'registrationFailedCpfAlreadyExists'
        }
      }
      this.loading = false
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    validateEmail(mail) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    validadeConfimation(confirmPassword) {
      if (this.form.password != confirmPassword) {
        this.errors.push('confirmPassword')
        return false
      }
      return true
    },
    validateCnpjCPF(value) {
      if (this.form.country != 'Brazil') {
        return true
      }
      /*
      if (
        !/(^\d{3}\.\d{3}\.\d{3}\-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$)/.test(
          value
        )
      ) {
        this.errors.push('cnpj')
        return false
      }
      */

      if (cpf.validate(value) || cnpj.validate(value)) {
        return true
      }

      this.errors.push('cnpj')
      return false
    },
    setLocalization(item, value) {
      this[item] = this[value]
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
.modal {
  width: 100vw;
  height: 100vh;
}

.modal__body {
  @include d-flex-center;
  margin-top: 30px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $almostBlack;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 7%;
    max-width: 326px;
    text-align: left;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}
</style>
