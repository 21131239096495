<template>
  <div class="wrapper">
    <TitleTextAreaPair
      :title="title"
      :label="label"
      :placeholder="placeholder"
      :textMax="textMax"
      :vModel='content'
      @change="content=$event"
    />
    <div class="footer">
      <button-default :disabled='disabled' :onClick="save">
        {{$t('save')}}
      </button-default>
    </div>
  </div>
</template>

<script>
import { TitleTextAreaPair } from '@/components/molecules'
import { ButtonDefault } from '@/components/atoms'

export default {
  name: 'ProjectSummary',
  components: { TitleTextAreaPair, ButtonDefault },
  props: {
    title: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    textMax: Number,
    save: Function,
    content: String,
    disabled: Boolean
  },
  data() {
    return {
      model: '',
      projectId: ''
    }
  },
  watch: {
    content() {
      this.$emit('change', this.content)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: block;
}

.footer {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
}
</style>
