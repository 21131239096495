<template>
  <div>
    <form @submit.prevent class="form" method="post">
      <div class="form__info">
        <p class="form__title">{{ $t("coexistenceContract") }}</p>
        <text-warning :text="$t('considerAllFieldObligatory')" />
        <text-info :text="$t('coexistenceContractInfo')" />
      </div>
      <div class="accepted mb-7">
        <input class="input" type="checkbox" v-model="form.accepted" />
        <label :class="{ label: true, warning: cAccepted }">{{
          $t("readAndAgree")
        }}</label>
      </div>

      <label class="label">{{ $t("cohabitationAgreementRequired") }}</label>
      <DragFile
        @file="form.files.pdf = $event"
        :attention="cAttention"
        :type="'pdf'"
        :actualData="form.files.pdf"
        :reference="'pdf'"
        @input="
          () => {
            this.resetErrors();
            this.checkErrorIfContains('pdf')
          }
        "
      />
      <text-info
        class="text-info mt-3"
        text=fileFormatMustBePDF
      />

      <div v-if="!isGradeOrMiddleCat1">
        <div v-if="allDataForm.FormProjectData.hasHumans">
          <label class="label">{{ $t("humansNecessaryFile") }}</label>
            <DragFile
              @file="form.files.humans = $event"
              :attention="cAttention2('humans')"
              :type="'pdf'"
              :actualData="form.files.humans"
              :reference="'humans'"
            />
            <text-info class="text-info mt-3" text="humanBeingsFileFormat" />
        </div>
      </div>

      <label class="label"></label>

      <input-default
        :class="{ warning: isURLInvalid }"
        v-model="form.files.banner.url"
        :label="$t('projectBannerLink')"
        :placeholder="$t('placeholderBannerLink')"
        @blur="validateUrl(form.files.banner.url)"
        @input="
          () => {
            this.resetErrors();
            this.$emit('inputLink', this.form.files.banner.url);
          }
        "
        :id="linkId"
      />

      <video-link-upload-section
        :class="{ warning: isURLInvalid }"
        :limitDate="cLimitDate"
        @inputLink="form.presentationLink = $event"
        @upload="form.files.video.url = $event"
        @urlValidation="handleURLValidation($event)"
        :default="form.presentationLink"
        :actualData="form.files.video.url"
      />

      <div class="form__footer">
        <button-default :onClick="back" class="button">{{
          $t("back")
        }}</button-default>
        <div class="two-button">
          <button-default
            :onClick="() => saveFix('temp')"
            :class="{ button: true, disabledButton: projectSaved }"
          >
            {{ $t("save") }}
          </button-default>
          <button-default
            :onClick="() => saveFix('send')"
            :class="{ button: true, disabledButton: projectSaved }"
          >
            {{ $t("send") }}
          </button-default>
        </div>
      </div>
      <div v-if="saveTemp" class="msg">{{ $t("succesfullySaved") }}</div>
    </form>
    <modal-send-form
      title="formSelectionRequestModalSendFormTitle"
      :onClick="saveForm"
      @closeModal="() => toggleModal('modal')"
      :modal="modal"
      :disabled="btnSaveFormDisabled"
    >
      <div class="text mt-5">
        <p info="teste4">{{ $t("affiliationRequestModalSendFormText") }}</p>
      </div>
    </modal-send-form>
    <modal-default
      title="requestSentSuccessfullyTitle"
      @closeModal="() => toggleModal('modal2')"
      :modal="modal2"
    >
      <div class="modal__body">
        <div class="icon">
          <img
            src="@/assets/email-send.png"
            alt="Solicitação enviada a MostraTec"
          />
        </div>
        <div class="text">
          <p slot="text">
            {{ $t("successfullRequest") }}
          </p>
        </div>
      </div>
    </modal-default>
    <modal-loading :modal="loading" />
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import {
  ModalSendForm,
  ModalLoading,
  ModalDefault
} from '@/components/organisms/Modals'
import extractNumber from '@/utils/extractNumber'
import {
  RadioGroup,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo,
  DragFile,
  InputDefault
} from '@/components/atoms'
import VideoLinkUploadSection from '../VideoLinkUploadSection'

export default {
  name: 'FormContract',
  components: {
    RadioGroup,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo,
    DragFile,
    ModalSendForm,
    ModalLoading,
    ModalDefault,
    VideoLinkUploadSection,
    InputDefault
  },
  data() {
    return {
      form: {
        accepted: false,
        coexistenceContract: 'virtual',
        presentationLink: '',
        files: {
          pdf: {
            name: '',
            url: ''
          },
          humans: {
            name: '',
            url: ''
          },
          banner: {
            name: 'banner',
            url: ''
          },
          video: {
            name: '',
            url: ''
          }
        }
      },
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      generalOptions: [{ label: this.$t('inPerson'), value: 'presencial' }, { label: this.$t('virtual'), value: 'virtual' }],
      limitDate: null,
      mostratec: {},
      firstLoader: false,
      temporaryData: {},
      modal: false,
      modal2: false,
      loading: false,
      saveTemp: false,
      control: true,
      projectSaved: false,
      btnSaveFormDisabled: false,
      sended: false,
      countSubmit: 0
    }
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  async mounted() {
    this.mostratec = await this.getMostratec()
    this.limitDate = this.$route.name === 'FormSelection'
      ? this.mostratec.project_selection_date_end
      : this.mostratec.project_date_end

    if (this.allDataForm.FormContract) {
      this.form = { ...this.form, ...this.allDataForm.FormContract }
    }
    this.form.files.humans = this.allDataForm.temporary_data.FormProjectStudents.humans
    this.projectSaved = await this.getProjectSaved()
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormContract) {
        this.form = { ...this.form, ...this.allDataForm.FormContract }
      }
      this.form.files.humans = this.allDataForm.FormProjectHighUpload.humans
    }
  },
  computed: {
    isGradeOrMiddleCat1() {
      const level = this.allDataForm.FormLevelOfEducation.level
      //console.log('FormLevelOfEducation.level:', this.allDataForm.FormLevelOfEducation.level)  // Debug log
      return (level === 'grade' || level === 'middle_cat_1')
      //return true
    },
    cAccepted() {
      return this.firstLoader && !this.form.accepted
    },
    cLive() {
      return this.firstLoader && !this.form.coexistenceContract
    },
    cAttention() {
      return this.firstLoader && !this.form.files.pdf.name
    },
    cLimitDate() {
      return this.formatDate(this.limitDate)
    }
  },
  methods: {
    formatDate(myDate) {
      if (!myDate) return null

      const date = new Date(myDate)

      if (date.getTimezoneOffset() < 0) {
        date.setHours(date.getHours() + date.getTimezoneOffset() / 60)
      } else {
        date.setHours(date.getHours() - date.getTimezoneOffset() / 60)
      }

      const formattedMonth = String(date.getMonth() + 1).length === 2
        ? date.getMonth() + 1
        : `0${ date.getMonth() + 1 }`
      const formattedDay = String(date.getDate()).length === 2
        ? date.getDate()
        : `0${ date.getDate() }`

      return `${ formattedDay }/${ formattedMonth }/${ date.getFullYear() }`
    },
    resetErrors() {
      if (this.errors.length && !this.errors.includes('url')) {
        this.errors = []
      }
    },
    cAttention2(data) {
      return this.errors.includes(data)
    },
    handleURLValidation(e) {
      if (e === true) {
        this.errors = this.errors.filter((err) => err !== 'url')
        this.textWarning = 'warningAllFieldsAreObligatory'
      } else {
        if (!this.errors.includes('url')) {
          this.errors.push('url')
        }
        this.textWarning = 'Insira uma URL válida'
      }
    },
    validateInscription() {
      const deadLine = this.$route.name !== 'FormSelection'
        ? this.mostratec.project_date_end
        : this.mostratec.project_selection_date_end
      const actualDate = new Date()
      const deadLineDate = new Date(deadLine)
      if (actualDate > deadLineDate) return false
      return true
    },
    checkForm(origin) {
      this.resetErrors()
      if (!origin) {
        return false
      }
      const projectData = this.allDataForm.FormProjectData
      this.form.coexistenceContract = 'virtual'

      this.firstLoader = true
      if (this.errors.length) return false
      this.resetErrors()
      if (this.projectSaved) {
        this.textWarning = this.$t('projectAlreadySaved')
        this.errors.push('enviado')
      }
      for (const key in this.form) {
        if (!this.form[key] && key !== 'presentationLink') {
          this.errors.push(key)
        }
      }
      //console.log('projectData.hasHumans')
      //console.log(projectData.hasHumans)
      //console.log('this.form.files.humans')
      //console.log(this.form.files.humans)
      if(!this.isGradeOrMiddleCat1) {
        if (!this.form.files.humans.url && projectData.hasHumans) {
          this.textWarning = this.$t('humansNecessaryFile')
          this.errors.push('humans')
        }
      }
      if (!this.form.presentationLink) {
        this.textWarning = this.$t('videoLinkInsert')
        this.errors.push('Link da apresentação')
      }
      if (!this.form.files.banner.url) {
        this.textWarning = this.$t('placeholderBannerLink')
        this.errors.push('Link do banner')
      }
      if (!this.validateInscription()) {
        // this.textWarning = '** Período de inscrição de projetos encerrado!'
        this.textWarning = this.$t('projectApplicationPeriodClosed')
        this.errors.push(1)
      }
      if (this.form.files.pdf.name === '') {
        this.textWarning = this.$t('cohabitationAgreementRequired')
        this.errors.push('pdf')
      }
      if (!this.errors.length && this.form.accepted) {
        this.temporarySave()
        if (origin === 'send') {
          this.toggleModal('modal')
        }
      }
    },
    async getProjectSaved() {
      const { data: response } = await this.$http.get('/temp')
      if (response) return response.project_saved
      return false
    },
    async temporarySave() {
      this.allDataForm.FormContract = this.form
      this.allDataForm.FormContract.coexistenceContract = 'virtual'

      const body = {
        temporary_data: this.allDataForm,
        form_type: this.allDataForm.FormLevelOfEducation.mostratec_type
      }

      try {
        const { data: response } = await this.$http.post('/temp', body)
        this.temporaryData = response
        if (this.control) {
          if (response) {
            this.saveTemp = true
          }
          setTimeout(() => {
            this.saveTemp = false
          }, 3000)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async saveFix(param) {
      // console.log('Aqui')
      if (!param) {
        return false
      }
      if (this.projectSaved) {
        this.textWarning = this.$t('projectAlreadySaved')
        this.errors.push('enviado')
        return false
      }
      // console.log('Antes CheckForm')
      // console.log(param)
      this.checkForm(param)
    },
    async getMostratec() {
      try {
        const { data: response } = await this.$http.get('/current-mostratec')
        return response
      } catch (error) {
        console.error(error)
      }
    },
    async saveForm() {
      this.countSubmit++
      // console.log(this.countSubmit)
      if (this.sended) {
        console.log('Already sended, return')
        alert('Já foi Enviado')
        return
      }
      console.log('Send first time')
      this.sended = true

      this.loading = true
      this.control = false
      this.toggleModal('loading')

      const infoStudentsAux = this.allDataForm.FormProjectStudents
        || this.allDataForm.FormProjectChildishStudents
      const infoStudentsFilter = infoStudentsAux.infos.filter(
        (item) => item.active
      )

      const infoStudents = []
      const {
        student, advisor, coadvisors, typePayment
      } = this.allDataForm.FormPayment

      infoStudentsFilter.forEach((item) => {
        infoStudents.push({
          zipcode: item.zipCode
            ? String(item.zipCode.replace(/\D/gi, ''))
            : null,
          deficiency: { deficiency: item.levelDeficiency },
          special_service: { special_service: item.specialattendance },
          cpf: String(item.cpf.replace(/\D/gi, '')),
          phone: extractNumber(item.phone),
          //          house_number: Number(extractNumber(item.number)),
          house_number: item.number,
          birth_date: new Date(item.birth_date.split('/').reverse().join('-')),
          full_name: item.fullname,
          sex: this.returnCorrectSex(item.gender),
          t_shirt: item.typeShirt,
          educational_level: this.returnCorrectEducationLevel(item.serie),
          street: item.address,
          fair_science:
            item.attendOtherFair === 'other'
              ? item.attendOther || item.otherFair
              : item.attendOtherFair,
          english_level: this.returnCorrectEnglishLevel(item.englishLevel),
          district: item.neighborhood,
          responsible_name: item.responsibleName,
          responsible_kinship: item.responsibleRelative,
          which_program: item.program,
          other_program: item.programOther,
          t_shirt_size: item.sizeShirt,
          city: item.city,
          complement: item.complement,
          country: item.country,
          email: item.email.trim(),
          state: item.state
        })
      })
      infoStudents[0].main = true

      student.forEach((item) => {
        infoStudents.forEach((item2, index) => {
          if (item.fullname === item2.full_name) {
            infoStudents[index].will_pay = item.willPay
            infoStudents[index].meal = item.meal
          }
        })
      })

      const infoAdvisorsAux = this.allDataForm.FormSelectionAboutAdvisors
        || this.allDataForm.FormProjectChildishAdvisors

      const infoAdvisorsFilter = infoAdvisorsAux.infos.filter(
        (item) => item.active
      )

      const infoAdvisors = []

      infoAdvisorsFilter.forEach((item) => {
        infoAdvisors.push({
          full_name: item.fullname,
          email: item.email.trim(),
          cpf: String(item.cpf.replace(/\D/gi, '')),
          phone: extractNumber(item.phone),
          sex: this.returnCorrectSex(item.gender),
          zipcode: item.zipCode
            ? String(item.zipCode.replace(/\D/gi, ''))
            : null,
          country: item.country,
          state: item.state,
          city: item.city,
          street: item.address,
          // house_number: Number(extractNumber(item.number)),
          house_number: item.number,
          complement: item.complement,
          formation: item.formation,
          scholarship_holder: !!item.scholarship,
          which_program: item.program,
          other_program: item.programOther,
          position: item.discipline,
          deficiency: { deficiency: item.levelDeficiency },
          special_service: { special_service: item.specialattendance },
          t_shirt: item.typeShirt,
          t_shirt_size: item.sizeShirt,
          english_level: this.returnCorrectEnglishLevel(item.englishLevel),
          participated_mostratec: !!item.attendOtherFair,
          district: item.neighborhood,
          type: item.type
        })
      })

      const advisorAndCoadvisors = []

      advisorAndCoadvisors.push(advisor[0])
      if (coadvisors.length) {
        coadvisors.forEach((elem) => {
          advisorAndCoadvisors.push(elem)
        })
      }

      advisorAndCoadvisors.forEach((item) => {
        infoAdvisors.forEach((item2, index) => {
          if (item.fullname === item2.full_name) {
            infoAdvisors[index].will_pay = item.willPay
            infoAdvisors[index].meal = item.meal
          }
        })
      })

      const projectDataAux = this.allDataForm.FormProjectData
        || this.allDataForm.FormProjectChildishData

      const { projectName } = projectDataAux
      const results = projectDataAux.resultsConclusion
      const { summary } = projectDataAux
      let area = ''
      if (projectDataAux.areas) {
        area = projectDataAux.areas[0]
      }
      projectDataAux.problem = projectDataAux.researchProblem
      const body = {
        level: this.allDataForm.FormLevelOfEducation.level,
        project_title:
          this.allDataForm.FormProjectData.projectName
          || this.allDataForm.FormProjectChildishData.projectName,
        project_category: infoStudentsAux.categoryProject,
        results,
        live_presentation: infoStudentsAux.livePresentation,
        //        coexistence_contract: this.form.coexistenceContract,
        coexistence_contract: 'virtual',
        mostratec_edition_active: this.allDataForm.FormLevelOfEducation.edition,
        human_beings: this.allDataForm.FormProjectData.hasHumans,
        summary,
        payment_type: typePayment,
        students: infoStudents,
        involveds: infoAdvisors,
        research_type: null,
        area,
        sub_areas: {},
        research_plan: {
          theme:
            this.allDataForm.FormProjectData.theme
            || this.allDataForm.FormProjectChildishData.theme,
          justification:
            this.allDataForm.FormProjectData.justification
            || this.allDataForm.FormProjectChildishData.justification,
          problem: projectDataAux.problem,
          objective:
            this.allDataForm.FormProjectData.objective
            || this.allDataForm.FormProjectChildishData.objective,
          methodology:
            this.allDataForm.FormProjectData.methodology
            || this.allDataForm.FormProjectChildishData.methodology
        },
        files: this.form.files,
        presentation_link: this.form.presentationLink
      }
      let routeFinally = 'selection'
      if (this.$route.name !== 'FormSelection') {
        body.fair_id = this.allDataForm.FormFair.id
        body.school_id = this.allDataForm.FormSelectionSchool.id
        // routeFinally = 'student_fair'
        routeFinally = 'panel-student-vacancies'
      } else {
        body.school_id = this.allDataForm.FormSelectionSchool.id
      }

      try {
        this.toggleModal('loading')
        await this.$http.post('/project', body)
        this.toggleModal('loading')
        this.toggleModal('modal')
        this.toggleModal('modal2')
        this.loading = false
        this.control = true
        setTimeout(() => {
          // this.$router.push(`/startproject/${ routeFinally }`)
          this.$router.push(`/startproject/${ routeFinally }`).then(() => {
            window.location.reload() // Recarregar a página após a navegação
          })
        }, 4000)
      } catch (error) {
        this.loading = false
        this.cotrol = true
        const status = this.getErrorCpf(error.message)
        if (status === 409) {
          this.toggleModal('modal')
          this.textWarning = `${ this.$t(
            'alreadyInUse'
          ) }`
          this.errors.push('cpf')
        }
        console.log('erro:', error.message)
      }
    },
    returnCorrectEducationLevel(value) {
      if (value === 'Berçário') {
        return 'bercario'
      }
      if (value === 'Maternal') {
        return 'maternal'
      }
      if (value === 'Pré') {
        return 'pre'
      }
      return value
    },
    returnCorrectEnglishLevel(value) {
      if (value === 'avançado') {
        return 'avancado'
      }
      if (value === 'intemerdiario') {
        return 'intermediario'
      }
      return value
    },
    returnCorrectSex(value) {
      if (value === 'masculino') {
        return 'male'
      }
      if (value === 'feminino') {
        return 'female'
      }
      return value
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    },
    getErrorCpf(errors) {
      const getStatusCode = errors.split(' ')
      return Number(getStatusCode[getStatusCode.length - 1])
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
// .input__checkbox {
//   display: flex;
//   flex-direction: column;

input[type="checkbox"] {
  zoom: 1.8;
}
.center {
  display: flex;
  align-items: center;
  margin-top: 11px;
}

span {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-left: 10px;
  max-width: 728px;
}

// }

.label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin-bottom: 20px;
  &.warning {
    color: $attention !important;
  }
}

.warning {
  span {
    color: $attention;
    border-bottom-color: $attention;
  }
}
.accepted {
  display: flex;
  align-items: center;
  margin-top: -20px;
  label {
    margin-left: 10px;
    color: $primary;
  }
}
.two-button {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  :first-child {
    margin-right: 10px;
  }
}
.align-items {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  img {
    width: 80px;
    margin-right: 10px;
  }
}
.msg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 10px;
  border-radius: 7px;
  color: $sucessStrong;
  font-weight: 700;
  background-color: rgba(51, 184, 80, 0.7);
}

.disabledButton {
  color: grey;
}
</style>
