<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{ $t("fileUpload") }}</p>
        <p class="form__text">{{ $t("filesMustBeSent") }}</p>
        <text-warning :text="`considerAllFieldObligatory`" />
      </div>
      <section>
        <div class="separator">
          <h3>{{ $t("requiredFiles") }}</h3>
          <a
            id="link_form"
            href="https://www.mostratec.com.br/formularios/"
            target="_blank"
            >{{ $t("downloadForms") }}</a
          >
        </div>

        <label class="label">{{ $t("reviewForm1") }}</label>
        <DragFile
          @file="files.revision = $event"
          :attention="cAttention('revision')"
          :type="'pdf'"
          :actualData="files.revision"
          :reference="'revision'"
        />
        <text-info class="text-info mt-3" text="fileFormatMustBePDF" />

        <label class="label">{{ $t("rulesOfConduct") }}</label>
        <DragFile
          @file="files.presentWork = $event"
          :attention="cAttention('presentWork')"
          :type="'pdf'"
          :actualData="files.presentWork"
          :reference="'presentWork'"
        />
        <text-info class="text-info mt-3" text="fileFormatMustBePDF" />

        <label class="label">Banner</label>

        <input-default
          :class="{ warning: isURLInvalid }"
          v-model="files.banner.url"
          :label="$t('projectBannerLink')"
          :placeholder="$t('placeholderBannerLink')"
          @blur="validateUrl(files.banner.url)"
          @input="
            () => {
              this.resetErrors();
              this.$emit('inputLink', this.files.banner.url);
            }
          "
          :id="linkId"
        />
      </section>

      <video-link-upload-section
        :limitDate="formatDate(cMostratecInfo)"
        @inputLink="presentationLink = $event"
        @upload="files.video = $event"
        @urlValidation="handleURLValidation($event)"
        :default="presentationLink"
        :actualData="files.video"
      />

      <section>
        <div class="separator">
          <h3>{{ $t("specificFiles") }}</h3>
        </div>
        <p class="form__text">
          {{ $t("filesRequestBelowInfo") }}
          <strong>{{ $t("projectData") }}</strong
          >.
        </p>

        <label class="label">{{ $t("continuityProject") }}</label>
        <DragFile
          @file="files.continuousProject = $event"
          :attention="cAttention('continuousProject')"
          :type="'pdf'"
          :actualData="files.continuousProject"
          :reference="'continuousProject'"
        />
        <text-info class="text-info mt-3" text="projectSevenFileFormat" />

        <label class="label">{{ $t("vertebrates") }}</label>
        <DragFile
          @file="files.vertebrateAnimals = $event"
          :attention="cAttention('vertebrateAnimals')"
          :type="'pdf'"
          :actualData="files.vertebrateAnimals"
          :reference="'vertebrateAnimals'"
        />
        <text-info class="text-info mt-3" text="vertebrateAnimalsFileFormat" />

        <div v-if="allDataForm.FormProjectHighData.hasHumans">
          <label class="label">{{ $t("humansNecessaryFile") }}</label>
          <DragFile
            @file="files.humans = $event"
            :attention="cAttention('humans')"
            :type="'pdf'"
            :actualData="files.humans"
            :reference="'humans'"
          />
          <text-info class="text-info mt-3" text="humanBeingsFileFormat" />
        </div>

        <label class="label">{{ $t("bios") }}</label>
        <DragFile
          @file="files.biological = $event"
          :attention="cAttention('biological')"
          :type="'pdf'"
          :actualData="files.biological"
          :reference="'biological'"
        />
        <text-info
          class="text-info mt-3"
          text="dangerousSubstancesFileFormat"
        />
        <label class="label">{{ $t("tissues") }}</label>
        <DragFile
          @file="files.tissueSubstances = $event"
          :attention="cAttention('tissueSubstances')"
          :type="'pdf'"
          :actualData="files.tissueSubstances"
          :reference="'tissueSubstances'"
        />
        <text-info class="text-info mt-3" text="animalTissueFileFormat" />

        <label class="label">{{ $t("researchInstituitionIndustry") }}</label>
        <DragFile
          @file="files.researchInstitution = $event"
          :attention="cAttention('researchInstitution')"
          :type="'pdf'"
          :actualData="files.researchInstitution"
          :reference="'researchInstitution'"
        />
        <text-info class="text-info mt-3" text="instituitionFileFormat" />

        <label class="label">{{ $t("riscs") }}</label>
        <DragFile
          @file="files.risksAnalysis = $event"
          :attention="cAttention('risksAnalysis')"
          :type="'pdf'"
          :actualData="files.risksAnalysis"
          :reference="'risksAnalysis'"
        />
        <text-info class="text-info mt-3" text="formRiskAssessment" />

        <label class="label">{{ $t("scientist") }}</label>
        <DragFile
          @file="files.cientistQualified = $event"
          :attention="cAttention('cientistQualified')"
          :type="'pdf'"
          :actualData="files.cientistQualified"
          :reference="'cientistQualified'"
        />
        <text-info class="text-info mt-3" text="formQualifiedScientist" />
      </section>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{
          $t("back")
        }}</button-default>
        <div class="form__save_footer">
          <button-default
            :onClick="() => checkForm(true)"
            class="button white mr-5"
            :class="[
              { disabledButton: projectSaved },
              'button',
              'white',
              'mr-5',
            ]"
            >{{ $t("save") }}</button-default
          >
          <button-default
            :onClick="() => checkForm(false)"
            :class="{ button: true, disabledButton: projectSaved }"
            >{{ $t("submitRegistration") }}</button-default
          >
        </div>
      </div>
      <div v-if="message" class="msg">{{ message }}</div>
    </form>

    <modal-send-form
      title="formSelectionRequestModalSendFormTitle"
      :onClick="saveForm"
      @closeModal="() => toggleModal('modal')"
      :modal="modal"
    >
      <div class="text mt-5">
        <p info="teste3">{{ $t("affiliationRequestModalSendFormText") }}</p>
      </div>
    </modal-send-form>
    <modal-default
      title="requestSentSuccessfullyTitle"
      @closeModal="() => toggleModal('modal2')"
      :modal="modal2"
    >
      <div class="modal__body">
        <div class="icon">
          <img
            src="@/assets/email-send.png"
            alt="Solicitação enviada a MostraTec"
          />
        </div>
        <div class="text">
          <p slot="text">{{ $t("requestSentSuccessfully") }}</p>
        </div>
      </div>
    </modal-default>
    <modal-loading :modal="loading" />
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  TextInfo,
  Paragraph,
  DragFile
} from '@/components/atoms'
import {
  ModalSendForm,
  ModalLoading,
  ModalDefault
} from '@/components/organisms/Modals'
import extractNumber from '@/utils/extractNumber'
import VideoLinkUploadSection from '../VideoLinkUploadSection.vue'

export default {
  name: 'FormProjectHighUpload',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    TextInfo,
    Paragraph,
    DragFile,
    ModalSendForm,
    ModalLoading,
    ModalDefault,
    VideoLinkUploadSection
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  data() {
    return {
      errors: [],
      extensionCheck: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      presentationLink: '',
      isContinued: null,
      files: {
        revision: {
          name: '',
          url: ''
        },
        banner: {
          name: 'banner',
          url: ''
        },
        video: {
          name: '',
          url: ''
        },
        presentWork: {
          name: '',
          url: ''
        },
        continuousProject: {
          name: '',
          url: ''
        },
        vertebrateAnimals: {
          name: '',
          url: ''
        },
        humans: {
          name: '',
          url: ''
        },
        biological: {
          name: '',
          url: ''
        },
        tissueSubstances: {
          name: '',
          url: ''
        },
        researchInstitution: {
          name: '',
          url: ''
        },
        risksAnalysis: {
          name: '',
          url: ''
        },
        cientistQualified: {
          name: '',
          url: ''
        }
      },
      message: '',
      firstLoader: false,
      modal: false,
      modal2: false,
      loading: false,
      mostratec: {},
      projectSaved: false,
      sended: false,
      countSubmit: 0
    }
  },
  async mounted() {
    if (this.allDataForm.FormProjectHighUpload) {
      if (this.allDataForm.FormProjectHighUpload) {
        this.files.revision = this.allDataForm.FormProjectHighUpload.revision
        this.files.banner = this.allDataForm.FormProjectHighUpload.banner
        this.files.video = this.allDataForm.FormProjectHighUpload.video
        this.files.presentWork = this.allDataForm.FormProjectHighUpload.presentWork
        this.files.continuousProject = this.allDataForm.FormProjectHighUpload.continuousProject
        this.files.vertebrateAnimals = this.allDataForm.FormProjectHighUpload.vertebrateAnimals
        this.files.humans = this.allDataForm.FormProjectHighUpload.humans
        this.files.biological = this.allDataForm.FormProjectHighUpload.biological
        this.files.tissueSubstances = this.allDataForm.FormProjectHighUpload.tissueSubstances
        this.files.researchInstitution = this.allDataForm.FormProjectHighUpload.researchInstitution
        this.files.risksAnalysis = this.allDataForm.FormProjectHighUpload.risksAnalysis
        this.files.cientistQualified = this.allDataForm.FormProjectHighUpload.cientistQualified
        this.presentationLink = this.allDataForm.FormProjectHighUpload.presentationLink
      }
    }
    this.isContinued = this.allDataForm.FormProjectHighResearcherSummary.form.willContinued
    this.mostratec = await this.getMostratec()
    this.projectSaved = await this.getProjectSaved()
  },
  watch: {
    allDataForm() {
      if (this.allDataForm.FormProjectHighUpload) {
        this.files.revision = this.allDataForm.FormProjectHighUpload.revision
        this.files.banner = this.allDataForm.FormProjectHighUpload.banner
        this.files.video = this.allDataForm.FormProjectHighUpload.video
        this.files.presentWork = this.allDataForm.FormProjectHighUpload.presentWork
        this.files.continuousProject = this.allDataForm.FormProjectHighUpload.continuousProject
        this.files.vertebrateAnimals = this.allDataForm.FormProjectHighUpload.vertebrateAnimals
        this.files.humans = this.allDataForm.FormProjectHighUpload.humans
        this.files.biological = this.allDataForm.FormProjectHighUpload.biological
        this.files.tissueSubstances = this.allDataForm.FormProjectHighUpload.tissueSubstances
        this.files.researchInstitution = this.allDataForm.FormProjectHighUpload.researchInstitution
        this.files.risksAnalysis = this.allDataForm.FormProjectHighUpload.risksAnalysis
        this.files.cientistQualified = this.allDataForm.FormProjectHighUpload.cientistQualified
        this.presentationLink = this.allDataForm.FormProjectHighUpload.presentationLink
      }
    }
  },
  computed: {
    ...mapGetters({
      mostratecInfo: 'mostratecInfo'
    }),
    cMostratecInfo() {
      if (this.mostratecInfo) {
        if (this.$route.name === 'FormSelection') {
          return this.mostratecInfo.project_selection_date_end
        }
        return this.mostratecInfo.project_date_end
      }
      return ''
    }
  },
  methods: {
    formatDate(myDate) {
      if (!myDate) null

      const date = new Date(myDate)
      const formattedMonth = String(date.getMonth() + 1).length === 2
        ? date.getMonth() + 1
        : `0${ date.getMonth() + 1 }`
      const formattedDay = String(date.getDate()).length === 2
        ? date.getDate()
        : `0${ date.getDate() }`

      return `${ formattedDay }/${ formattedMonth }/${ date.getFullYear() }`
    },
    async getProjectSaved() {
      const { data: response } = await this.$http.get('/temp')
      if (response) return response.project_saved
      return false
    },
    async getMostratec() {
      try {
        const { data: response } = await this.$http.get('/current-mostratec')
        return response
      } catch (error) {
        console.error(error)
      }
    },
    cAttention(data) {
      return this.errors.includes(data)
    },
    async checkForm(type) {
      this.resetErrors()
      const projectData = this.allDataForm.FormProjectHighData
      this.firstLoader = true
      this.textWarning = this.$t('warningAllFieldsAreObligatory')
      if (this.projectSaved) {
        this.textWarning = this.$t('projectAlreadySaved')
        this.errors.push('enviado')
        return
      }
      if (!this.files.banner.url) {
        this.textWarning = this.$t('placeholderBannerLink')
        this.errors.push('Link do banner')
      }
      if (!this.files.continuousProject.name && this.isContinued === true) {
        this.textWarning = this.$t('continuityFileInsert')
        this.errors.push('Documento de continuidade')
      }
      if (!this.presentationLink) {
        this.textWarning = this.$t('videoLinkInsert')
        this.errors.push('Link da apresentação')
      }
      for (const key in this.files) {
        if (!this.files[key].url) {
          if (key == 'revision') {
            this.errors.push(key)
          }
          if (
            (key == 'vertebrateAnimals' || key == 'cientistQualified')
            && projectData.hasAnimals
          ) {
            this.errors.push(key)
          }
          if (key == 'humans' && projectData.hasHumans) {
            this.errors.push(key)
          }
          if (key == 'cientistQualified' && projectData.hasBioSubst) {
            this.errors.push(key)
          }
          if (key == 'risksAnalysis' && projectData.controlledSubst) {
            this.errors.push(key)
          }
          if (
            key == 'researchInstitution'
            && projectData.researchOnInstituition
          ) {
            this.errors.push(key)
          }
          if (key == 'risksAnalysis' && projectData.dangerousEquip) {
            this.errors.push(key)
          }
          if (projectData.hasBioSubst) {
            if (key == 'biological' && projectData.dangerousBiologicals) {
              this.errors.push(key)
            }
            if (key == 'tissueSubstances' && projectData.humanOrAnimalTissue) {
              this.errors.push(key)
            }
          }
          if (this.mostratec.format_edition !== 'presencial') {
            if (key == 'banner' || key == 'presentWork') {
              this.errors.push(key)
            }
          }
        }
      }
      const actualDate = new Date()
      const deadLine = window.location.pathname !== '/form-selection'
        ? new Date(this.mostratec.project_date_end)
        : new Date(this.mostratec.project_selection_date_end)
      if (actualDate > deadLine) {
        // this.textWarning = '* Período de inscrição de projetos encerrado!'
        this.textWarning = this.$t('projectApplicationPeriodClosed')
        this.errors.push(1)
        return false
      }

      if (!this.errors.length) {
        if (type) {
          return this.temporarySave(type)
        }
        this.toggleModal('modal')
        this.temporarySave()
      }
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    },
    handleURLValidation(e) {
      if (e === true) {
        this.errors = this.errors.filter((err) => err !== 'url')
        this.textWarning = 'warningAllFieldsAreObligatory'
      } else {
        if (!this.errors.includes('url')) {
          this.errors.push('url')
        }
        this.textWarning = 'Insira uma URL válida'
      }
    },
    async temporarySave(type) {
      this.allDataForm.FormProjectHighUpload = {
        ...this.files,
        presentationLink: this.presentationLink
      }
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
        if (response && type) {
          this.message = this.$t('succesfullySaved')
          setTimeout(() => {
            this.message = ''
          }, 3000)
        }
      } catch (error) {
        console.error(error)
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    async saveForm() {
      this.countSubmit++
      console.log(this.countSubmit)
      if (this.sended) {
        console.log('Already sended, return')
        alert('Já foi Enviado')
        return
      }
      console.log('Send first time')
      this.sended = true

      this.toggleModal('modal')
      this.toggleModal('loading')

      try {
        const studentsAux = this.allDataForm.FormProjectHighStudents.infos
        const students = []

        const {
          student, advisor, coadvisors, typePayment
        } = this.allDataForm.FormPayment

        studentsAux.forEach((item) => {
          if (item.active) {
            students.push({
              main: false,
              zipcode: item.zipCode
                ? String(item.zipCode.replace(/\D/gi, ''))
                : null,
              deficiency: { deficiencies: item.deficiency },
              special_service: { special_services: item.special_service },
              cpf: String(item.cpf.replace(/\D/gi, '')),
              phone: extractNumber(item.phone),
              //              house_number: Number(extractNumber(item.house_number)),
              house_number: item.house_number,
              birth_date: new Date(
                item.birth_date
                  .split('/')
                  .reverse()
                  .join('-')
                  .concat(' 00:00:00')
              ),
              full_name: item.full_name,
              sex: this.returnCorrectSex(item.sex),
              t_shirt: this.returnCorrectTshirt(item.t_shirt),
              educational_level: this.returnCorrectEducationLevel(
                item.educational_level
              ),
              street: item.street,
              fair_science:
                item.attendOtherFair === 'other'
                  ? item.fair_science
                  : this.returnCorrectNewStudentFairScience(
                    item.attendOtherFair
                  ),
              english_level: this.returnCorrectEnglishLevel(item.english_level),
              district: item.district,
              responsible_name: item.responsible_name,
              responsible_kinship: item.responsible_kinship,
              scholarship_holder: item.scholarship_holder === 'yes',
              which_program: item.which_program,
              other_program: item.other_program,
              t_shirt_size: item.t_shirt_size,
              city: item.city,
              complement: item.complement,
              country: item.country,
              email: item.email.trim(),
              state: item.state
            })
          }
        })
        students[0].main = true

        student.forEach((item) => {
          students.forEach((item2, index) => {
            if (item.fullname === item2.full_name) {
              students[index].will_pay = item.willPay
              students[index].meal = item.meal
            }
          })
        })

        const involvedsAux = this.allDataForm.FormProjectHighAdvisors.infos.filter(
          (item) => item.active
        )

        const involveds = []

        involvedsAux.forEach((item) => {
          involveds.push({
            zipcode: item.zipcode
              ? String(item.zipcode.replace(/\D/gi, ''))
              : null,
            deficiency: { deficiencies: item.deficiency },
            special_service: { special_services: item.special_service },
            cpf: String(item.cpf.replace(/\D/gi, '')),
            phone: Number(extractNumber(item.phone)),
            //            house_number: Number(extractNumber(item.house_number)),
            house_number: item.house_number,
            full_name: item.full_name,
            sex: this.returnCorrectSex(item.sex),
            t_shirt: this.returnCorrectTshirt(item.t_shirt),
            street: item.street,
            participated_mostratec: !!item.attendOtherFair,
            english_level: this.returnCorrectEnglishLevel(item.english_level),
            district: item.district,
            which_program: item.which_program,
            other_program: item.other_program,
            t_shirt_size: item.t_shirt_size,
            city: item.city,
            complement: item.complement,
            country: item.country,
            email: item.email.trim(),
            state: item.state,
            type: item.type,
            formation: item.formation,
            position: item.position,
            scholarship_holder: !!item.scholarship_holder
          })
        })

        const advisorAndCoadvisors = []

        advisorAndCoadvisors.push(advisor[0])
        if (coadvisors.length) {
          coadvisors.forEach((elem) => {
            advisorAndCoadvisors.push(elem)
          })
        }

        advisorAndCoadvisors.forEach((item) => {
          involveds.forEach((item2, index) => {
            if (item.fullname === item2.full_name) {
              involveds[index].will_pay = item.willPay
              involveds[index].meal = item.meal
            }
          })
        })
        const researchInstitutionAux = this.allDataForm.FormProjectHighResearcherSummary.researchInstitution
        const research_institution = {
          institution_name: researchInstitutionAux.institutionName,
          phone: extractNumber(researchInstitutionAux.institutionPhone),
          research_locations: {
            locations: researchInstitutionAux.researchLocal
          },
          email: researchInstitutionAux.institutionEmail.trim(),
          experiment_items: { items: researchInstitutionAux.researchItens },
          country: researchInstitutionAux.country,
          zipcode: researchInstitutionAux.zipCode,
          state: researchInstitutionAux.state,
          city: researchInstitutionAux.city,
          street: researchInstitutionAux.address,
          district: researchInstitutionAux.neighborhood,
          //          house_number: extractNumber(researchInstitutionAux.number),
          house_number: researchInstitutionAux.number,
          complement: researchInstitutionAux.complement
        }

        delete this.allDataForm.FormProjectHighResearchPlan.objectives

        const research_plan = {
          ...this.allDataForm.FormProjectHighResearchPlan
        }

        const otherInvolveds = []
        if (this.allDataForm.FormProjectHighOthersInvolveds.othersInvolved) {
          if (this.allDataForm.FormProjectHighOthersInvolveds.infos.length) {
            const otherInvolvedsAux = this.allDataForm.FormProjectHighOthersInvolveds.infos
            otherInvolvedsAux.forEach((item) => {
              otherInvolveds.push({
                zipcode: item.zipCode
                  ? String(item.zipCode.replace(/\D/gi, ''))
                  : null,
                deficiency: { deficiencies: item.deficiency },
                special_service: { special_services: item.special_service },
                cpf: String(item.cpf.replace(/\D/gi, '')),
                phone: extractNumber(item.phone),
                //                house_number: Number(extractNumber(item.house_number)),
                house_number: item.house_number,
                full_name: item.full_name,
                sex: this.returnCorrectSex(item.sex),
                t_shirt: this.returnCorrectTshirt(item.t_shirt),
                street: item.street,
                district: item.district,
                t_shirt_size: item.t_shirt_size,
                city: item.city,
                complement: item.complement,
                country: item.country,
                email: item.email.trim(),
                state: item.state,
                type: item.type
              })
            })
          }
        }
        if (otherInvolveds.length) {
          involveds.push(...otherInvolveds)
        }
        let body = {}
        if (this.$route.name === 'FormViaFair') {
          body = {
            fair_id: this.allDataForm.FormFair.id,
            school_id: this.allDataForm.FormSelectionSchool.id,
            level: this.allDataForm.FormLevelOfEducation.level,
            project_title: this.allDataForm.FormProjectHighData.projectTitle,
            live_presentation:
              this.allDataForm.FormProjectHighStudents.livePresentation,
            mostratec_edition_active:
              this.allDataForm.FormLevelOfEducation.edition,
            summary: this.allDataForm.FormProjectHighProjectAbstract.abstract,
            students,
            involveds,
            payment_type: typePayment,
            research_type: this.allDataForm.FormProjectHighData.typeSearch,
            area: this.allDataForm.FormProjectHighData.area,
            sub_areas: {
              sub_areas: this.allDataForm.FormProjectHighData.sub_areas
            },
            research_plan,
            research_institution,
            human_beings: this.allDataForm.FormProjectHighData.hasHumans,
            vertebrate_animals: this.allDataForm.FormProjectHighData.hasAnimals,
            biological_substances:
              this.allDataForm.FormProjectHighData.hasBioSubst,
            danger_biological_substances:
              this.allDataForm.FormProjectHighData.dangerousBiologicals,
            human_or_animals_tissues:
              this.allDataForm.FormProjectHighData.humanOrAnimalTissue,
            controlled_substances:
              this.allDataForm.FormProjectHighData.controlledSubst,
            equipments_danger:
              this.allDataForm.FormProjectHighData.dangerousEquip,
            research_init: new Date(
              this.allDataForm.FormProjectHighResearcherSummary.form.startProject
                .split('/')
                .reverse()
                .join('-')
                .concat(' 00:00:00')
            ),
            research_end: new Date(
              this.allDataForm.FormProjectHighResearcherSummary.form.endsProject
                .split('/')
                .reverse()
                .join('-')
                .concat(' 00:00:00')
            ),
            experimentation_beginning: new Date(
              this.allDataForm.FormProjectHighResearcherSummary.form.startCollect
                .split('/')
                .reverse()
                .join('-')
                .concat(' 00:00:00')
            ),
            will_continue:
              this.allDataForm.FormProjectHighResearcherSummary.form
                .willContinued,
            continuity_area:
              this.allDataForm.FormProjectHighResearcherSummary.continuity_area,
            continuity_sub_areas: {
              continuity_sub_areas:
                this.allDataForm.FormProjectHighResearcherSummary
            }.continuity_sub_areas,
            files: this.files,
            presentation_link: this.presentationLink
          }
        } else {
          body = {
            school_id: this.allDataForm.FormSelectionSchool.id,
            level: this.allDataForm.FormLevelOfEducation.level,
            project_title: this.allDataForm.FormProjectHighData.projectTitle,
            live_presentation:
              this.allDataForm.FormProjectHighStudents.livePresentation,
            mostratec_edition_active:
              this.allDataForm.FormLevelOfEducation.edition,
            summary: this.allDataForm.FormProjectHighProjectAbstract.abstract,
            students,
            involveds,
            payment_type: typePayment,
            research_type: this.allDataForm.FormProjectHighData.typeSearch,
            area: this.allDataForm.FormProjectHighData.area,
            sub_areas: {
              sub_areas: this.allDataForm.FormProjectHighData.sub_areas
            },
            research_plan,
            research_institution,
            human_beings: this.allDataForm.FormProjectHighData.hasHumans,
            vertebrate_animals: this.allDataForm.FormProjectHighData.hasAnimals,
            biological_substances:
              this.allDataForm.FormProjectHighData.hasBioSubst,
            danger_biological_substances:
              this.allDataForm.FormProjectHighData.dangerousBiologicals,
            human_or_animals_tissues:
              this.allDataForm.FormProjectHighData.humanOrAnimalTissue,
            controlled_substances:
              this.allDataForm.FormProjectHighData.controlledSubst,
            equipments_danger:
              this.allDataForm.FormProjectHighData.dangerousEquip,
            research_init: new Date(
              this.allDataForm.FormProjectHighResearcherSummary.form.startProject
                .split('/')
                .reverse()
                .join('-')
                .concat(' 00:00:00')
            ),
            research_end: new Date(
              this.allDataForm.FormProjectHighResearcherSummary.form.endsProject
                .split('/')
                .reverse()
                .join('-')
                .concat(' 00:00:00')
            ),
            experimentation_beginning: new Date(
              this.allDataForm.FormProjectHighResearcherSummary.form.startCollect
                .split('/')
                .reverse()
                .join('-')
                .concat(' 00:00:00')
            ),
            will_continue:
              this.allDataForm.FormProjectHighResearcherSummary.form
                .willContinued,
            continuity_area:
              this.allDataForm.FormProjectHighResearcherSummary.continuity_area,
            continuity_sub_areas: {
              continuity_sub_areas:
                this.allDataForm.FormProjectHighResearcherSummary
            }.continuity_sub_areas,
            files: this.files,
            presentation_link: this.presentationLink
          }
        }
        await this.$http.post('/project', body)
        this.toggleModal('loading')
        this.toggleModal('modal2')
        this.loading = false
        this.cotrol = true
        setTimeout(() => {
          if (this.$route.name === 'FormSelection') {
            this.$router.push('/startproject/selection')
          } else this.$router.push('/startproject/student_fair')
        }, 4000)
      } catch (error) {
        const { response } = error
        if (response?.status === 409) {
          const splittedMessage = response.data.message.split(' ')
          if (splittedMessage[2] === 'cpf') {
            this.errors.push('cpf repetido')
            this.textWarning = `${ this.$t(
              'alreadyInUse'
            ) }`
          }
        }
      } finally {
        if (this.loading) this.toggleModal('loading')
      }
    },
    returnCorrectTshirt(value) {
      if (value === 'polo') {
        return 'masculina'
      }
      if (value === 'camiseta') {
        return 'infantil'
      }
      return value
    },
    returnCorrectNewStudentFairScience(value) {
      if (typeof value !== 'boolean') {
        return value
      }
      return value ? 'sim' : 'não'
    },
    returnCorrectEducationLevel(value) {
      if (value === 'Berçário') {
        return 'bercario'
      }
      if (value === 'Maternal') {
        return 'maternal'
      }
      if (value === 'Pré') {
        return 'pre'
      }
      return value
    },
    returnCorrectEnglishLevel(value) {
      if (value === 'avançado') {
        return 'avancado'
      }
      if (value === 'intemerdiario') {
        return 'intermediario'
      }
      return value
    },
    returnCorrectSex(value) {
      if (value === 'masculino') {
        return 'male'
      }
      if (value === 'feminino') {
        return 'female'
      }
      return value
    },
    toggleModal(type) {
      this[type] = !this[type]
    },
    resetErrors() {
      if (this.errors.length && !this.errors.includes('url')) {
        this.errors = []
      }
    },
    formatDates(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      const preFormatedDate = `${ year }-${ month }-${ day }`
      const formatedDate = new Date(preFormatedDate)
      return formatedDate
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 600px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
    max-width: 444px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }

  &__save_footer {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    width: 415px;
  }

  .separator {
    border-bottom: 1px solid $primary;
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $primaryDark;
    font-family: Mulish;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
  }

  .label {
    color: $almostBlack;
    display: flex;
    font-weight: 600;
    margin-bottom: 7px;
    margin-top: 30px;
    text-align: left;
  }
}
.msg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 10px;
  border-radius: 7px;
  color: $sucessStrong;
  font-weight: 700;
  background-color: rgba(51, 184, 80, 0.7);
}

.disabledButton {
  color: grey;
}

.separator h3 {
  margin-bottom: 5px;
}

#link_form {
  text-decoration: underline;
}

#link_form:hover {
  color: #ff9800;
}
</style>
