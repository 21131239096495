<template>
  <div>
    <div class="stepper__container">
      <v-stepper v-model="element" alt-labels>
        <v-stepper-header>
          <template v-for="(step, index) in steps">
            <v-stepper-step
              :complete="element > index + 1"
              :step="index + 1"
              :key="`${index}-${step.text}`"
            >
              <p>{{ step.text }}</p>
            </v-stepper-step>
            <v-divider
              :key="`${index}:${step}`"
              v-if="index < steps.length - 1"
            ></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            v-for="(component, index) in components"
            :step="index + 1"
            :key="`${index}`"
          >
            <component
              :is="component"
              :ref="component"
              @changeElement="changeElement"
              @changeElementWrapper="changeElement"
              @getProperForm="getAllDataForm"
              :mostratec_type="cAllDataForm.FormLevelOfEducation.mostratec_type"
              :allDataForm="cAllDataForm"
              :educationLevel="cAllDataForm.FormLevelOfEducation.level"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import {
  FormLevelOfEducation,
  FormFair,
  FormProjectHighOthersInvolveds,
  FormProjectHighResearchPlan,
  FormProjectHighProjectAbstract,
  FormProjectHighResearcherSummary,
  FormProjectHighAdvisors,
  FormPayment
} from './FormProjectSubscribe'

import {
  WrapperFormProjectAboutAdvisors,
  WrapperFormProjectData,
  WrapperFormProjectStudents,
  WrapperFormProjectContract
} from './FormProjectSubscribe/Wrappers'

import { FormSelectionSchool } from './FormSelectionTabs'

export default {
  name: 'FormProjectSubscribeInFair',
  components: {
    FormLevelOfEducation,
    FormSelectionSchool,
    FormFair,
    WrapperFormProjectData,
    WrapperFormProjectStudents,
    WrapperFormProjectAboutAdvisors,
    WrapperFormProjectContract,
    FormProjectHighOthersInvolveds,
    FormProjectHighResearchPlan,
    FormProjectHighProjectAbstract,
    FormProjectHighAdvisors,
    FormPayment,
    FormProjectHighResearcherSummary
  },
  data() {
    return {
      userId: '',
      element: 1,
      randomKey: 0,
      steps: [
        { text: this.$t('levelsOfEducational') },
        { text: this.$t('school') },
        { text: this.$t('fair') },
        { text: this.$t('projectData') },
        { text: this.$t('aboutStudents') },
        { text: this.$t('aboutAdvisors') },
        { text: this.$t('othersInvolved') },
        { text: this.$t('researchPlan') },
        { text: this.$t('projectSummary') },
        { text: this.$t('summaryForTheResearcher') },
        { text: this.$t('registrationFee') },
        { text: this.$t('documents') }
      ],
      components: [
        FormLevelOfEducation.name,
        FormFair.name,
        FormSelectionSchool.name,
        WrapperFormProjectData.name,
        WrapperFormProjectStudents.name,
        FormProjectHighAdvisors.name,
        FormProjectHighOthersInvolveds.name,
        FormProjectHighResearchPlan.name,
        FormProjectHighProjectAbstract.name,
        FormProjectHighResearcherSummary.name,
        FormPayment.name,
        WrapperFormProjectContract.name
      ],
      allDataForm: {
        FormLevelOfEducation: '',
        FormSelectionSchool: '',
        FormFair: '',
        FormProjectData: '',
        FormProjectStudents: '',
        FormSelectionAboutAdvisors: '',
        FormProjectChildishData: '',
        FormProjectChildishStudents: '',
        FormProjectChildishAdvisors: '',
        FormContract: '',
        FormProjectHighData: '',
        FormProjectHighStudents: '',
        FormProjectHighAdvisors: '',
        FormProjectHighOthersInvolveds: '',
        FormProjectHighResearchPlan: '',
        FormProjectHighProjectAbstract: '',
        FormProjectHighResearcherSummary: '',
        FormPayment: '',
        FormProjectHighUpload: ''
      }
    }
  },
  watch: {
    'allDataForm.FormLevelOfEducation.level': {
      handler(after, before) {
        if (this.allDataForm.FormLevelOfEducation.level === 'high') {
          this.components = [
            FormLevelOfEducation.name,
            FormSelectionSchool.name,
            FormFair.name,
            WrapperFormProjectData.name,
            WrapperFormProjectStudents.name,
            FormProjectHighAdvisors.name,
            FormProjectHighOthersInvolveds.name,
            FormProjectHighResearchPlan.name,
            FormProjectHighProjectAbstract.name,
            FormProjectHighResearcherSummary.name,
            FormPayment.name,
            WrapperFormProjectContract.name
          ]

          this.steps = [
            { text: this.$t('levelsOfEducational') },
            { text: this.$t('school') },
            { text: this.$t('fair') },
            { text: this.$t('projectData') },
            { text: this.$t('aboutStudents') },
            { text: this.$t('aboutAdvisors') },
            { text: this.$t('othersInvolved') },
            { text: this.$t('researchPlan') },
            { text: this.$t('projectSummary') },
            { text: this.$t('summaryForTheResearcher') },
            { text: this.$t('registrationFee') },
            { text: this.$t('documents') }
          ]
        }
      },
      deep: true
    }
  },
  async mounted() {
    await this.getUser()
    await this.getAllDataForm()
  },
  methods: {
    changeElement(data) {
      const actual = this.components[this.element - 1]

      if (data.direction === 'up') {
        this.element += 1
        const next = this.components[this.element - 1]
        this.$refs[next]?.[0].activate()
      }
      if (data.direction === 'down') this.element -= 1

      if (data[actual]) {
        this.allDataForm[actual] = data[actual]
      }

      return false
    },
    getUser() {
      this.userId = jwt_decode(localStorage.getItem('token')).uid
    },
    async getAllDataForm() {
      try {
        const { data: response } = await this.$http.get(`/temp/${ this.userId }`)
        if (response) {
          // console.log(response.temporary_data.FormLevelOfEducation.level)
          // alert(response.temporary_data.FormLevelOfEducation.level)
          if (response.temporary_data.FormLevelOfEducation.level !== 'high') {
            this.allDataForm = {
              FormLevelOfEducation: '',
              FormSelectionSchool: '',
              FormFair: '',
              FormProjectData: '',
              FormProjectStudents: '',
              FormSelectionAboutAdvisors: '',
              FormProjectChildishData: '',
              FormProjectChildishStudents: '',
              FormProjectChildishAdvisors: '',
              FormPayment: '',
              FormContract: ''
            }
            this.components = [
              FormLevelOfEducation.name,
              FormSelectionSchool.name,
              FormFair.name,
              WrapperFormProjectData.name,
              WrapperFormProjectStudents.name,
              WrapperFormProjectAboutAdvisors.name,
              FormPayment.name,
              WrapperFormProjectContract.name
            ]

            this.steps = [
              { text: this.$t('levelsOfEducational') },
              { text: this.$t('school') },
              { text: this.$t('fair') },
              { text: this.$t('projectData') },
              { text: this.$t('aboutStudents') },
              { text: this.$t('aboutAdvisors') },
              { text: this.$t('registrationFee') },
              { text: this.$t('coexistenceContract') }
            ]
          }
          let index = 1
          // console.log(response.temporary_data.FormLevelOfEducation)
          if (response.temporary_data.FormLevelOfEducation) {
            this.allDataForm = response.temporary_data
          }
          for (const i in this.allDataForm) {
            if (this.allDataForm[i]) {
              if (this.allDataForm.FormLevelOfEducation.level !== 'high') {
                if (index < this.components.length - 1) {
                  index++
                }
              }
              if (this.allDataForm.FormLevelOfEducation.level === 'high') {
                if (index < this.components.length) {
                  index++
                }
              }
            }
          }
          this.element = index
        }
        return response
      } catch (error) {
        console.error(error)
      }
    }
  },
  computed: {
    cAllDataForm() {
      if (this.allDataForm) {
        return this.allDataForm
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-steeper {
  box-shadow: none !important;
}

.stepper__container {
  ::v-deep .v-stepper__label {
    text-align: center !important;
    font-size: 14px;
    line-height: 120%;
    color: $primaryDark !important;
    opacity: 0.8;
  }

  ::v-deep .v-stepper__step--complete + hr {
    border-color: $primary !important;
  }

  ::v-deep .v-stepper__header {
    box-shadow: none !important;
  }

  ::v-deep .v-stepper {
    box-shadow: none !important;
  }

  p{
    max-width: 7vw;
  }
}
</style>
