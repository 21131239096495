<template>
  <div class="term">
    <div class="left">
      <h3>{{$t('responsibilityTerm')}}</h3>
      <p>{{$t('responsibilityTermParagraph1')}}</p>
      <p>{{$t('responsibilityTermParagraph2')}} </p>
      <p>{{$t('responsibilityTermParagraph3')}}</p>
    </div>
    <div class="right">
      <div>
        <h4>{{$t('resonsablePersonForFillingIn')}}</h4>
        <span>{{cFairInfo.responsible_name}}</span>
      </div>
      <div class="office">
        <h4>{{$t('positionFunctionAtFair')}}:</h4>
        <span>{{cFairInfo.office}}</span>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'TermResponsability',
  props: {
    fairInfo: Object
  },
  computed: {
    cFairInfo() {
      if (this.fairInfo) {
        return this.fairInfo
      }
      return {}
    }
  }
}
</script>
<style lang="scss" scoped>
.term {
  font-family: "Mulish", sans-serif;
  display: flex;
  background: #FDFDFD;
  border-radius: 10px;
  .left {
    flex: 1;
      padding-right: 30px;
    h3 {
      font-size: 18px;
      line-height: 23px;
      color: $primary;
    }
    p {
      margin-top: 30px;
      font-family: "OpenSans", sans-serif;
    }
  }
  .right {
    flex: 1;
    padding-left: 30px;
    border-left: 1px solid #CDCBCB;
    .office {
      margin-top: 39px;
    }
    span {
      margin-top: 13px;
    }
  }
}
</style>
