<template>
  <div>
    <p class="text">{{text}}</p>
    <BigNumber :color="color" >{{number}}</BigNumber>
  </div>
</template>

<script>
import { BigNumber } from '@/components/atoms'

export default {
  name: 'DescriptionNumberPair',
  props: {
    color: {
      type: String,
      required: false,
      default: '#686868'
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    number: {
      type: [Number, String],
      required: false,
      default: '--'
    }
  },
  components: {
    BigNumber
  }
}
</script>

<style lang="scss" scoped>
.text{
  font-family: $font-stack;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: $almostBlack;
  margin-bottom: 0px;
  height: 32px;
  line-height: 16px;
}
</style>
