<template>
  <div>
    <RegistrationSituation
      :title="cIdPlusTitle || $t('sketch')"
      :cause="getSelectedProjectDenyReason()"
      :slug="getSelectedProjectStatusSlug()"
      :statusText="statusDictionary[getSelectedProjectStatusSlug()].name"
      :theme="statusDictionary[getSelectedProjectStatusSlug()].theme"
      :fair_name="selectedProject.fair && selectedProject.fair.fair_name"
    />
    <div
      v-if="selectedProject.internal_status_id === 4"
      class="mensagemMP"
      label="Pagar"

    > 
    {{ $t('paymentDateLimit') }}<br /> {{this.maxMpagoPayDayFormatted  }} 
    </div>
    <div v-if="this.mercadoPagoValidity === true" id="botaoMercadoPago">
      <div
        v-if="selectedProject.internal_status_id === 4"
        class="cho-container button-mercado-pago"
        label="Pagar"
      >
      </div>
    </div>
  </div>
</template>
<script>
import RegistrationSituation from '@/components/organisms/Infos/RegistrationSituation'
import { mapGetters } from 'vuex'
import { statusDictionary } from '@/utils/dictionaries'

export default {
  name: 'SubscriptionRequest',
  components: { RegistrationSituation },
  data() {
    return {
      dataReady: false,
      statusDictionary,
      mercadoPago_id: null,
      mercadoPago_aproved: false,
      maxMpagoPayDay: null,
      mercadoPagoValidity: false // Inicializando com um valor padrão
    }
  },
  computed: {
    ...mapGetters({ selectedProject: 'getSelectedProject' }),
    cIdPlusTitle() {
      let plusTitle = ''
      if (this.selectedProject.project_id && this.selectedProject.project_title) {
        plusTitle = `#${ this.selectedProject.project_id } - ${ this.selectedProject.project_title }`
      }
      return plusTitle
    },    
    // Computed property para exibir a data formatada como toLocaleDateString
    maxMpagoPayDayFormatted() {
      return this.maxMpagoPayDay ? this.maxMpagoPayDay.toLocaleDateString('pt-BR') : ''
    }
  },
  beforeCreate() {
    console.log('beforeCreate()')
          // no mes 8=setembro, 9=outubro e assim por diante
      this.maxMpagoPayDay = new Date(2024,9,14)
      this.maxMpagoPayDay.setHours(23, 59, 59)

      // Obter a data/hora atual no fuso horário GMT-3
      const now = new Date();
      const nowInGMT3 = new Date(
        new Intl.DateTimeFormat('en-US', {
          timeZone: 'America/Sao_Paulo',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        }).format(now)
      );

      // Comparar a data/hora corrente com maxMpagoPayDay
      console.log('this.maxMpagoPayDay:', this.maxMpagoPayDay);
      console.log('Current Date in GMT-3:', nowInGMT3);

      if (nowInGMT3 < this.maxMpagoPayDay) {

        const mercadoPagoScript = document.createElement('script')
        mercadoPagoScript.onload = async () => {
          const mercadoPago = new window.MercadoPago(
            'TEST-a3b8d43a-f115-4a43-92e1-c8bcaff3a98e'
          )
          mercadoPago.checkout({
            preference: {
              id: await this.getIdMercadoPago()
            },
            render: {
              container: '.cho-container',
              label: 'Pagar com Mercado Pago'
            }
          })
        }
        mercadoPagoScript.async = true
        mercadoPagoScript.setAttribute(
          'src',
          'https://sdk.mercadopago.com/js/v2'
        )
      document.body.appendChild(mercadoPagoScript)
    }
  },
  watch: {
    mercadoPago_aproved() {
      console.log('mercadoPago_aproved()')
      if (this.getSelectedProjectStatusSlug() === 'aguardando-pagamento' && this.mercadoPago_aproved === true) {
        window.location.reload()
      }
    }, 

  },
  mounted() {
    // Chama o método imediatamente para verificar a validade ao carregar a página
    this.checkMercadoPagoValidity()

    // Define um intervalo de 1 minuto (60000 ms) para verificar novamente
    setInterval(() => {
      this.checkMercadoPagoValidity()
    }, 10000) // 60000 ms = 1 minuto
  },
  methods: {
    
    // Ver validade para pagamento com MercadoPago
    checkMercadoPagoValidity(){
      // no mes 8=setembro, 9=outubro e assim por diante
      this.maxMpagoPayDay = new Date(2024,9,14)
      this.maxMpagoPayDay.setHours(23, 59, 59)

      // Obter a data/hora atual no fuso horário GMT-3
      const now = new Date();

      // Ajustar a data/hora corrente para GMT-3
      //let nowInGMT3 = new Date(now.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }));
        // Ajustar a data/hora corrente para GMT-3 usando Intl.DateTimeFormat
      const nowInGMT3 = new Date(
        new Intl.DateTimeFormat('en-US', {
          timeZone: 'America/Sao_Paulo',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric'
        }).format(now)
      );

      // Comparar a data/hora corrente com maxMpagoPayDay
      console.log('this.maxMpagoPayDay:', this.maxMpagoPayDay);
      console.log('Current Date in GMT-3:', nowInGMT3);

      if (nowInGMT3 < this.maxMpagoPayDay) {
        this.mercadoPagoValidity= true; // Se a data/hora atual for menor que maxMpagoPayDay
        console.log('this.mercadoPagoValidity ',this.mercadoPagoValidity);
      } else {
        this.mercadoPagoValidity= false; // Se a data/hora atual for maior ou igual a maxMpagoPayDay
        console.log('this.mercadoPagoValidity ',this.mercadoPagoValidity);
        // Remover o nodo <div> com o id "botaoMercadoPago" se mercadoPagoValidity for false
        const botaoMercadoPagoElement = document.getElementById('botaoMercadoPago');
        if (botaoMercadoPagoElement) {
          botaoMercadoPagoElement.remove();
          console.log('Elemento botaoMercadoPago removido.');
        }        
      }
      // Forçar a renderização para garantir que o Vue atualize o DOM corretamente
      this.$forceUpdate();      
    },   
    getSelectedProjectStatusSlug() {
      return this.selectedProject?.status?.slug
      || this.selectedProject?.statusSlug
    },
    getSelectedProjectDenyReason() {
      if (this.getSelectedProjectStatusSlug() === 'reprovado') {
        return this.selectedProject?.fair_justification
      }
      return this.selectedProject?.project_feedback
    },
    async getIdMercadoPago() {
      const mercadoPagoId = await this.$http.get(
        `/mercadopago/${ this.selectedProject.id }`
      )
      this.selectedProject.internal_status_id = mercadoPagoId.data === 'approved' ? 5 : this.selectedProject.internal_status.id
      this.mercadoPago_aproved = mercadoPagoId.data === 'approved'
      return mercadoPagoId.data
    }
  }
}
</script>
<style lang="scss" scoped>
.button-mercado-pago {
  display: flex;
  justify-content: flex-end;
  font-size: 1.2em;
  height: 3rem;
  margin: 0 5% 2% 0
}
.mensagemMP {
  display: flex;
  justify-content: flex-end;
  font-size: 1.2em;
  height: 3rem;
  margin: 0 5% 2% 0
}
</style>
