<template>
  <div class="container__wrapper">
    <header-home class="header__fix" />
    <router-view></router-view>
  </div>
</template>

<script>
import { InitialHome } from '@/components/templates'
import { HeaderHome } from '@/components/organisms'

export default {
  name: 'Home',
  components: { InitialHome, HeaderHome }
}
</script>

<style lang="scss" scoped>
.container__wrapper {
  background-color: #F1F5F6;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px 20px;
  min-height: 100vh;
}

.header__fix {
  border-radius: 29px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  width: 95%;
  z-index: 2;
}

</style>
