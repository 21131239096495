<template>
  <div>
    <form @submit.prevent class="form">
      <text-warning text= "allFieldsAreObligatory" />
      <input-default
        :class="{warning: checkErrorIfContains('name')}"
        label=fullNameStudent
        id="name"
        placeholder= inputName
        type="text"
        v-model="form.name"
        @input="resetErrors"
      />
      <text-info
        class="mb-2"
        text="Você constará como o estudante responsável pela inscrição do seu projeto."
      />
      <input-select
        :class="[{warning: checkErrorIfContains('fairName')}, 'mb-7']"
        id="affiliateFair"
        :items="fairNameItems"
        v-model="form.fairName"
        label= affiliatedFair
        placeholder= selectFairName
      />
      <input-select-location
        :class="[{warning: checkErrorIfContains('country')}, 'mb-7']"
        id="country"
        :items="countries"
        v-model="form.country"
        label=country
        :onEnter="() => setLocalization('country', form.country)"
        @input="resetErrors"
      />
      <input-select-location
        v-if="form.country == 'Brazil'"
        :class="[{warning: checkErrorIfContains('state')}, 'mb-7']"
        id="state"
        :items="states"
        v-model="form.state"
        label= state
        :onEnter="() => setLocalization('state', form.state)"
        @input="resetErrors"
      />
      <input-select-location
        v-if="form.country == 'Brazil' && form.state && cities"
        :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
        id="city"
        :items="cities"
        v-model="form.city"
        label= city
        :onEnter="() => setLocalization('city', form.city)"
        @input="resetErrors"
      />
      <input-default
        v-if="form.country && form.country != 'Brazil'"
        :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
        label="City"
        id="city"
        placeholder="Input your city"
        type="text"
        v-model="form.city"
        @input="resetErrors"
      />
      <div v-if="form.country == 'Brazil'">
        <input-masked
        :class="[{warning: checkErrorIfContains('cpf')}]"
        required
        label=cpfStudent
        id="cnpj-cpf"
        placeholder= inputYourCPF
        type="text"
        v-model="form.cpf"
        @input="resetErrors"
        :mask="['###.###.###-##', '##.###.###/####-##']"
        />
        <text-info class="mb-2" text= initialRegisterCpfCnpjTextInfo />
      </div>
      <input-default
        v-if="form.country && form.country != 'Brazil'"
        :class="[{warning: checkErrorIfContains('cpf')}, 'mb-7']"
        label="Identification number"
        id="cnpj-cpf"
        placeholder=initialRegisterIdentificationNumber
        type="number"
        v-model="form.cpf"
        @input="resetErrors"
      />
      <input-default
        :class="{warning: checkErrorIfContains('email')}"
        label= email
        id="email"
        placeholder= inputEmailToConfirmRegistration
        type="text"
        v-model="form.email"
        @input="resetErrors"
      />
      <text-info class="mb-2" text= initialRegisterEmailTextInfo />
      <input-default
        :class="[{warning: checkErrorIfContains('password')}]"
        label= passwordLoginLabel
        id="password"
        placeholder= inputPasswordToLogin
        type="password"
        v-model="form.password"
        @input="resetErrors"
      />
      <text-info text= initialRegisterPasswordTextInfo />
      <input-default
        :class="[{warning: checkErrorIfContains('confirmPassword')}, 'mb-7']"
        label= passwordConfirm
        id="confirmPassword"
        placeholder= passwordConfirm
        type="password"
        v-model="form.confirmPassword"
        @input="resetErrors"
      />
      <div class="flex">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('submit')}}</button-default>
      </div>
      <modal-default title="E-mail enviado!" @closeModal="closeModal" :modal="modal">
        <div class="modal__body">
          <div class="icon">
            <img src="@/assets/email-send.png" alt="E-mail enviado a MostraTec" />
          </div>
          <div class="text">
            <p slot="text">{{$t('confirmationEmailSent')}}</p>
          </div>
        </div>
      </modal-default>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault,
  InputSelect,
  InputSelectLocation,
  InputMasked,
  ButtonDefault,
  TextWarning,
  TextInfo,
  FeedbackWarning
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'
import validatePassword from '@/utils/validatePassword.js'
import { ModalDefault } from '../Modals'

export default {
  name: 'FormRegisterProjectFair',
  components: {
    InputDefault,
    InputSelect,
    InputSelectLocation,
    ButtonDefault,
    InputMasked,
    TextWarning,
    TextInfo,
    FeedbackWarning,
    ModalDefault
  },
  data() {
    return {
      form: {
        name: '',
        fairName: '',
        country: '',
        state: '',
        city: '',
        cpf: '',
        email: '',
        password: '',
        confirmPassword: ''
      },
      fairNameItems: [],
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      modal: false
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang'
    })
  },
  mounted() {
    this.getFairNameOptions()
  },
  watch: {
    'form.state': function (newVal, oldVal) {
      this.cities = []
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ this.form.state }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    }
  },
  methods: {
    ...mapActions({
      changeLang: 'changeLanguage'
    }),
    back() {
      this.$router.push('/')
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    closeModal() {
      this.modal = false
      this.$router.go(-1)
    },
    async checkForm() {
      this.resetErrors()
      for (const key in this.form) {
        if (!this.form[key]) {
          if (!(key === 'state' && this.form.country !== 'Brazil')) {
            this.errors.push(key)
          }
        }
      }
      if (!this.errors.length) {
        if (!this.validateEmail(this.form.email)) {
          this.textWarning = 'invalidEmail'
          return false
        }
        if (validatePassword(this.form.password)) {
          this.errors.push('password')
          this.textWarning = 'weakPassword'
          return false
        }
        if (!this.validadeConfimation(this.form.confirmPassword)) {
          this.textWarning = 'passwordsDontMatch'
          return false
        }
        await this.saveForm()
      }
      return true
    },

    async saveForm() {
      try {
        await this.$http.post('/user', {
          name: this.form.name,
          email: this.form.email,
          password: this.form.password,
          language: this.lang,
          role: 'aluno_feira_afiliada',
          fair_name: this.form.fairName.name,
          fair_id: this.form.fairName.id,
          cpf: String(this.form.cpf).replace(/\D/g, '')
        })
        this.modal = true
        // this.$emit('lgpdWarning')
      } catch (error) {
        this.modal = false
        if (
          error.response.data.errors[0].source.pointer
          && error.response.data.errors[0].source.pointer === 'email'
        ) {
          this.errors.push('email')
          this.textWarning = 'registrationFailedEmailAlreadyExists'
        }
        if (
          error.response.data.errors[0].source.pointer
          && (error.response.data.errors[0].source.pointer === 'cpf_cnpj'
          || error.response.data.errors[0].source.pointer === 'cpf'
          || error.response.data.errors[0].source.pointer === 'cpf_process_registration')
        ) {
          this.errors.push('cpf')
          this.textWarning = this.form.country && this.form.country !== 'Brazil'
            ? 'registrationFailedIcAlreadyExists'
            : 'registrationFailedCpfAlreadyExists'
        }
      }
    },

    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    validateEmail(mail) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    validadeConfimation(confirmPassword) {
      if (this.form.password !== confirmPassword) {
        this.errors.push('confirmPassword')
        return false
      }
      return true
    },
    async getFairNameOptions() {
      const { data } = await this.$http.get('/fair/search/accepted/name')
      const formatedNames = data.map((i) => ({
        text: i.name,
        value: { id: i.id, name: i.name }
      }))
      this.fairNameItems = formatedNames
    },
    async checkLGPD() {
      const { data } = await this.$http.get(`/user/lgpd/${ this.form.email }`)
      this.modal = true
      return data
    }
  }
}

</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .button {
    align-self: flex-end;
    margin-top: 30px;
  }
}
.modal {
  width: 100vw;
  height: 100vh;
}

.modal__body {
  @include d-flex-center;
  margin-top: 30px;

  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $almostBlack;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 7%;
    max-width: 326px;
    text-align: left;
  }
}
.flex {
  display: flex;
  justify-content: space-between;
}
</style>
