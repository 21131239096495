<template>
  <div class="container-app">
    <header-default @menuSelected="menuSelected"/>
    <ListSolicitations v-if="route == 'ListSolicitations'" :fields="fields"/>
    <router-view :fields="fields"></router-view>
  </div>
</template>

<script>

import { ListSolicitations } from '@/components/templates'
import { HeaderDefault } from '@/components/organisms'

export default {
  name: 'ListSolicitationsView',
  components: {
    ListSolicitations, HeaderDefault
  },
  data() {
    return {
      fields: 'tableListProjectSolicitations'
    }
  },
  computed: {
    route() {
      return this.$route.name
    }
  },
  updated() {
    if (!['tableListProjectSolicitations', 'tableListSolicitations'].includes(this.fields) && this.route === 'List') {
      this.fields = 'tableListProjectSolicitations'
    }
  },
  methods: {
    menuSelected(e) {
      this.fields = e
    }
  }
}
</script>

<style lang="scss">
.container-app {
  background-color: #F1F5F6;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 25px 20px 0px;
}

.badge {
  @include d-flex-center;
  display: inline-flex;
  border-radius: 49px;
  height: 28px;
  padding: 0px 10px;

  font-weight: bold;
  font-size: 12px;
}

.gray_draft {
  background-color: $grayLight;
  color: $specialBlack;
}

.warn {
  background-color: $warning;
  color: #A76F00;
}

.blue_analisis {
  background-color: $blueLight;
  color: $blueStrong;
}

.red_reject {
  background-color: $danger;
  color: $dangerStrong;
}

.orange_inactive {
  background-color:  #f8ad5efd;
  color: #bd5805f1;
}

.green_subscription{
  background-color: #33B850;
  opacity: 80%;
  color:#046619
}

.green_accept {
  background-color: #7CD18E;
  color: $sucessStrong;
}

.purple_waiting {
  background-color: #DDD3E0;
  color: #8E24AA;
}

.blue_send {
  background-color: #C2C8FF;
  color: #0E5671;
}
</style>
