<template>
  <div>
    <div class="container-content">
      <div class="container-header">
        <p class="size-name">{{ this.$t("enrollment") }}</p>
        <p class="size-rate">{{ this.$t("value") }}</p>
        <p class="size-meal">{{ this.$t("food") }}</p>
        <p class="size-final">{{ this.$t("finalValue") }}</p>
      </div>
      <p class="subtitle">{{ this.$t("students") }}</p>
      <div
        class="container-info"
        v-for="(item, index) in studentsList"
        :key="'student' + index"
      >
        <div class="size-name">
          <label :for="`checkboxStudent${index}`">
            <input
              :id="`checkboxStudent${index}`"
              v-model="item.will_pay"
              type="checkbox"
              @change="validateAdvisorsCheckbox(item)"
            />
            {{ item.full_name }}
          </label>
        </div>
        <div class="size-rate" v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            R${{ valueRate.toFixed(2).replace(/\./g, ",") }}
          </p>
          <p v-else class="color-text">R$0,00</p>
        </div>
         <div class="size-rate" v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            US${{ valueRate.toFixed(2) }}
          </p>
          <p v-else class="color-text">US$0.00</p>
        </div>
        <div class="size-meal">
          <input-select
            id="filterMealOptions"
            :class="[{ warning: checkErrorIfContains('studentMeal') }, 'mb-4']"
            :items="filterMealOptions"
            v-model="studentsList[index].meal"
            :value="studentsList[index].meal"
            placeholder="selecione quantidade de refeições"
            @input="() => calculateFinalValue(studentsList[index].meal, index)"
            :disabled="!item.will_pay"
          />
        </div>
        <div v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            R${{
              studentsList[index].finalValue.toFixed(2).replace(/\./g, ",")
            }}
          </p>
          <p v-else class="color-text size-final">R$0,00</p>
        </div>
         <div v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            US${{
              studentsList[index].finalValue.toFixed(2)
            }}
          </p>
          <p v-else class="color-text size-final">US$0.00</p>
        </div>
      </div>
      <p class="subtitle advisors">{{ this.$t("advisor") }}</p>
      <div
        class="container-info"
        v-for="(item, index) in nameAdvisor"
        :key="'advisor' + index"
      >
        <div class="size-name advisors">
          <label :for="`checkboxAdvisor${index}`">
            <input
              :id="`checkboxAdvisor${index}`"
              v-model="item.will_pay"
              type="checkbox"
              @change="check(item, index)"
            />
            {{ item.full_name }}
          </label>
        </div>
        <div class="size-rate" v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            R${{ valueRate.toFixed(2).replace(/\./g, ",") }}
          </p>
          <p v-else class="color-text">R$0,00</p>
        </div>
         <div class="size-rate" v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text">
            US${{ valueRate.toFixed(2) }}
          </p>
          <p v-else class="color-text">US$0,00</p>
        </div>
        <div class="size-meal">
          <input-select
            id="filterMealOptions"
            :class="[{ warning: checkErrorIfContains('advisorMeal') }, 'mb-4']"
            :items="filterMealOptions"
            v-model="nameAdvisor[index].meal"
            :value="nameAdvisor[index].meal"
            placeholder="selecione quantidade de refeições"
            @input="
              () =>
                calculateFinalValue(
                  nameAdvisor[index].meal,
                  index,
                  nameAdvisor[index].type
                )
            "
            :disabled="!item.will_pay || item.isParticipant !== 'yes'"
          />
        </div>
        <div v-if="schoolType !== 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            R${{ nameAdvisor[index].finalValue.toFixed(2).replace(/\./g, ",") }}
          </p>
          <p v-else class="color-text size-final">R$0,00</p>
        </div>
         <div v-if="schoolType === 'foreign'">
          <p v-if="item.will_pay" class="color-text size-final">
            US${{ nameAdvisor[index].finalValue.toFixed(2) }}
          </p>
          <p v-else class="color-text size-final">US$0,00</p>
        </div>
      </div>
      <div v-if="coadvisorList.length">
        <p class="subtitle advisors">{{ this.$t("coAdvisor") }}</p>
        <div
          class="container-info"
          v-for="(item, index) in coadvisorList"
          :key="'coadvisor' + index"
        >
          <div class="size-name">
            <label :for="`checkboxCoadvisor${index}`">
              <input
                :id="`checkboxCoadvisor${index}`"
                v-model="item.will_pay"
                type="checkbox"
                @input="check"
                @change="check(item, index)"
              />
              {{ item.full_name }}
            </label>
          </div>
          <div class="size-rate" v-if="schoolType !== 'foreign'">
            <p v-if="item.will_pay" class="color-text">
              R${{ valueRate.toFixed(2).replace(/\./g, ",") }}
            </p>
            <p v-else class="color-text">R$0,00</p>
          </div>
           <div class="size-rate" v-if="schoolType === 'foreign'">
            <p v-if="item.will_pay" class="color-text">
              US${{ valueRate.toFixed(2) }}
            </p>
            <p v-else class="color-text">US$0,00</p>
          </div>
          <div class="size-meal">
            <input-select
              id="EditionSelect"
              :class="[
                { warning: checkErrorIfContains('coadvisorMeal') },
                'mb-4',
              ]"
              :items="filterMealOptions"
              v-model="item.meal"
              :value="item.meal"
              placeholder="selecione quantidade de refeições"
              @input="() => calculateFinalValue(item.meal, index, item.type)"
              :disabled="!item.will_pay || item.isParticipant !== 'yes'"
            />
          </div>
          <div v-if="schoolType !== 'foreign'">
            <p v-if="item.will_pay" class="color-text size-final">
              R${{
                coadvisorList[index].finalValue.toFixed(2).replace(/\./g, ",")
              }}
            </p>
            <p v-else class="color-text size-final">R$0,00</p>
          </div>
          <div v-if="schoolType === 'foreign'">
            <p v-if="item.will_pay" class="color-text size-final">
              US${{
                coadvisorList[index].finalValue.toFixed(2)
              }}
            </p>
            <p v-else class="color-text size-final">US$0,00</p>
          </div>
        </div>
      </div>
      <div class="values-finals">
        <div class="total-payment" v-if="schoolType !== 'foreign'">
          <p><strong>SUBTOTAL</strong></p>
          <p class="color-text size-final">
            R${{ subtotal.toFixed(2).replace(/\./g, ",") }}
          </p>
        </div>
         <div class="total-payment" v-if="schoolType === 'foreign'">
          <p><strong>SUBTOTAL</strong></p>
          <p class="color-text size-final">
            US${{ subtotal.toFixed(2) }}
          </p>
        </div>
        <div class="discount teste">
          <p class="discount-label"><strong>Desconto: </strong></p>
            <currency-input
              class="currency-input"
              placeholder="Digite seu desconto"
              @input="verifyTotal()"
              type="text"
              v-model="discount"
              :options="{ currency: 'BRL', currencyDisplay: 'hidden', autoDecimalDigits: true }"
            />
        </div>
        <div class="total-payment" v-if="schoolType !== 'foreign'">
          <p><strong>TOTAL</strong></p>
          <p class="color-text size-final">
            R${{ total.toFixed(2).replace(/\./g, ",") }}
          </p>
        </div>
         <div class="total-payment" v-if="schoolType === 'foreign'">
          <p><strong>TOTAL</strong></p>
          <p class="color-text size-final">
            US${{ total.toFixed(2) }}
          </p>
        </div>
      </div>
      <p class="title-type-payment">
        {{ "Forma de Pagamento" }}
      </p>
      <div :class="schoolType !== 'foreign' ? 'type-payment' : 'type-payment2'">
        <div
          class="container-payment"
          v-for="(option, index) in paymentOptions"
          :key="'payment' + index"
        >
          <div
            v-if="schoolType === 'foreign' && index !== 1"
            class="custom-radio"
          >
            <input
              type="radio"
              :id="option.value"
              :value="option.value"
              v-model="typePayment"
            />
            <label v-if="index !== 0" :for="option.value">{{
              $t(option.label)
            }}</label>
            <label v-else :for="option.value" class="mp">
              <img
                src="@/assets/mercado-pago-logo1.png"
                alt="logo-mercado-pago"
              />
            </label>
          </div>
          <div v-else-if="schoolType !== 'foreign'" class="custom-radio">
            <input
              type="radio"
              :id="option.value"
              :value="option.value"
              v-model="typePayment"
            />
            <label v-if="index !== 0" :for="option.value">{{
              $t(option.label)
            }}</label>
            <label v-else :for="option.value" class="mp">
              <img
                src="@/assets/mercado-pago-logo1.png"
                alt="logo-mercado-pago"
              />
            </label>
          </div>
        </div>
      </div>
      <div class="button-save">
        <button-default :width="'15%'" :disabled="disableButton" :onClick="checkForm">{{
          $t("saveInformation")
        }}</button-default>
      </div>
    </div>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>
<script>
import {
  InputSelect,
  ButtonDefault,
  FeedbackWarning
} from '@/components/atoms'
import CurrencyInput from '../../atoms/Inputs/CurrencyInput'

export default {
  name: 'FormPaymentEdit',
  components: {
    InputSelect,
    ButtonDefault,
    FeedbackWarning,
    CurrencyInput
  },
  data() {
    return {
      optionAdvisor: '',
      projectId: '',
      levelEducation: '',
      schoolType: '',
      studentsList: [],
      nameAdvisor: [],
      coadvisorList: [],
      checked: false,
      valueRate: 0,
      finalValue: 0,
      mealOption: 0,
      valorTotalFinal: 0,
      feesList: [],
      typePayment: '',
      discount: Number(this.projectInfo.discount),
      isChange: false,
      value: undefined,
      paymentOptions: [
        { label: 'Mercado Pago', value: 'mercado pago' },
        {
          label: 'Nota de empenho (somente prefeituras)',
          value: 'nota empenho'
        },
        { label: this.$t('payOnAccreditation'), value: 'credenciamento' }
      ],
      schoolTypeOptions: [
        { text: this.$t('public'), value: 'public' },
        { text: this.$t('private'), value: 'private' },
        { text: this.$t('foreign'), value: 'foreign' }
      ],
      // Alterações chamado 463
      filterMealOptions: [
        { text: this.$t('noMeal'), value: 0 },
        { text: this.$t('oneMeals'), value: 1 },
        { text: this.$t('twoMeals'), value: 2 },
        { text: this.$t('threeMeals'), value: 3 },
        { text: this.$t('fourMeals'), value: 4 },
        { text: this.$t('fiveMeals'), value: 5 },
        { text: this.$t('sixMeals'), value: 6 },
        { text: this.$t('sevenMeals'), value: 7 }
      ],
      advisorOptions: [
        { text: 'Cobrar taxa de participação', value: 'yes' },
        { text: 'Não cobrar taxa de participação', value: 'no' }
      ],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      disableButton: false
    }
  },
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  watch: {
    typePayment() {
      if (this.projectInfo.payment_type === 'mercado pago' && this.projectInfo.internal_status_id === 5) {
        this.disableButton = true
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      await this.getFees()
      const { data: response } = await this.$http.get(
        `/payment-details/${ this.projectInfo.id }`
      )
      this.levelEducation = this.projectInfo.level
      this.studentsList = this.projectInfo.students
      this.schoolType = response.foreign ? 'foreign' : response.school_type
      this.typePayment = this.projectInfo.payment_type
      this.discount = response.discount
      this.projectInfo.involveds.map(
        (involved) => {
          if (involved.type === 'orientador') {
            this.nameAdvisor = [...this.nameAdvisor, { ...involved, isParticipant: 'yes' }]
          }

          if (involved.type === 'coorientador') {
            this.coadvisorList = [...this.coadvisorList, { ...involved, isParticipant: 'yes' }]
          }

          return false
        }
      )
      this.calculateFee(this.schoolType)
      this.calculeForFirstTime()
    },
    calculateFee(schoolType) {
      // Dá vontade de bater em quem inventou esse código 
      const {
        national_public_child: nationalPublicCh,
        national_public_middle: nationalPublicMid,
        national_public_middle_cat_1: nationalPublicMidC1,
        national_public_middle_cat_2: nationalPublicMidC2,
        national_public_middle_cat_3: nationalPublicMidC3,
        national_public_middle_cat_eja: nationalPublicMidCEja,
        national_public_high: nationalPublicHighSchool,
        national_private_child: nationalPrivateCh,
        national_private_middle: nationalPrivateMid,
        national_private_middle_cat_1: nationalPrivateMidC1,
        national_private_middle_cat_2: nationalPrivateMidC2,
        national_private_middle_cat_3: nationalPrivateMidC3,
        national_private_middle_cat_eja: nationalPrivateMidCEja,
        national_private_high: nationalPrivateHighSchool,
        foreign_middle: foreignMid,
        foreign_middle_cat_1: foreignMidC1,
        foreign_middle_cat_2: foreignMidC2,
        foreign_middle_cat_3: foreignMidC3,
        foreign_middle_cat_eja: foreignMidCEja,
        foreign_high: foreignHighSchool
      } = this.feesList[0]
      //const nationalChildEducation = nationalChildEdu
      const nationalPublicChild = nationalPublicCh
      const nationalPublicMiddle = nationalPublicMid
      const nationalPublicMiddleCat1 = nationalPublicMidC1
      const nationalPublicMiddleCat2 = nationalPublicMidC2
      const nationalPublicMiddleCat3 = nationalPublicMidC3
      const nationalPublicMiddleCatEja = nationalPublicMidCEja
      const nationalPublicHigh = nationalPublicHighSchool
      const nationalPrivateChild = nationalPrivateCh
      const nationalPrivateMiddle = nationalPrivateMid
      const nationalPrivateMiddleCat1 = nationalPrivateMidC1
      const nationalPrivateMiddleCat2 = nationalPrivateMidC2
      const nationalPrivateMiddleCat3 = nationalPrivateMidC3
      const nationalPrivateMiddleCatEja = nationalPrivateMidCEja
      const nationalPrivateHigh = nationalPrivateHighSchool
      const foreignMiddle = foreignMid
      const foreignMiddleCat1 = foreignMidC1
      const foreignMiddleCat2 = foreignMidC2
      const foreignMiddleCat3 = foreignMidC3
      const foreignMiddleCatEja = foreignMidCEja
      const foreignHigh = foreignHighSchool
      this.typeSchool = schoolType
      /*
      if (this.levelEducation === 'grade') {
        this.valueRate = nationalChildEducation
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      */
      //console.log('calculateFee() this.levelEducation',this.levelEducation)
      if (this.levelEducation === 'grade') {
        const valueFeeByTypeSchool = {
          public: nationalPublicChild,
          private: nationalPrivateChild,
          foreign: foreignMiddle
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddle,
          private: nationalPrivateMiddle,
          foreign: foreignMiddle
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_1') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCat1,
          private: nationalPrivateMiddleCat1,
          foreign: foreignMiddleCat1
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_2') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCat2,
          private: nationalPrivateMiddleCat2,
          foreign: foreignMiddleCat2
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_3') {
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCat3,
          private: nationalPrivateMiddleCat3,
          foreign: foreignMiddleCat3
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
      if (this.levelEducation === 'middle_cat_eja') {
        //console.log('entrou em middle_cat_eja')
        const valueFeeByTypeSchool = {
          public: nationalPublicMiddleCatEja,
          private: nationalPrivateMiddleCatEja,
          foreign: foreignMiddleCatEja
        }
        //console.log('valueFeeByTypeSchool',valueFeeByTypeSchool)
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }


      if (this.levelEducation === 'high') {
        const valueFeeByTypeSchool = {
          public: nationalPublicHigh,
          private: nationalPrivateHigh,
          foreign: foreignHigh
        }
        this.valueRate = valueFeeByTypeSchool[schoolType]
        this.studentsList.forEach((student) => {
          student.rate = this.valueRate
          student.meal > 0 ? student.finalValue = this.calculateMeal(schoolType, student.meal) : student.finalValue = 0
        })
        this.coadvisorList.forEach((coadvisor) => {
          coadvisor.rate = this.valueRate
          coadvisor.meal > 0 ? coadvisor.finalValue = this.calculateMeal(schoolType, coadvisor.meal) : coadvisor.finalValue = 0
        })
        this.nameAdvisor.forEach((advisor) => {
          advisor.rate = this.valueRate
          advisor.meal > 0 ? advisor.finalValue = this.calculateMeal(schoolType, advisor.meal) : advisor.finalValue = 0
        })
      }
    },
    calculateFinalValue(mealOption, index, role) {
      const { national_meal: nationalMeal, foreign_meal: foreignMeal } = this.feesList[0]
      /*
      if (!this.valueRate) {
        this.mealOption = 0
        return false
      }
      */
      if (mealOption === 0) {
        this.mealOption = 0
        this.finalValue = this.valueRate
      }
      // Alteração chamado 463
      if ((mealOption >= 1) && (mealOption <= 7)) {
        if (this.typeSchool !== 'foreign') {
          this.mealOption = nationalMeal * mealOption
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreignMeal * mealOption
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      /*
      if (mealOption === 6) {
        if (this.typeSchool !== 'foreign') {
          this.mealOption = nationalMeal * 6
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreignMeal * 6
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      if (mealOption === 7) {
        if (this.typeSchool !== 'foreign') {
          this.mealOption = nationalMeal * 7
          this.finalValue = this.valueRate + this.mealOption
        } else {
          this.mealOption = foreignMeal * 7
          this.finalValue = this.valueRate + this.mealOption
        }
      }
      */
      if (!role) {
        this.studentsList[index].finalValue = this.finalValue
        this.studentsList[index].mealValue = this.mealOption
      }
      if (role === 'orientador') {
        this.nameAdvisor[index].finalValue = this.finalValue
        this.nameAdvisor[index].mealValue = this.mealOption
      }
      if (role === 'coorientador') {
        this.coadvisorList[index].finalValue = this.finalValue
        this.coadvisorList[index].mealValue = this.mealOption
      }
      return this.finalValue
    },
    calculateMeal(schoolType, meal) {
      const { national_meal: nationalMeal, foreign_meal: foreignMeal } = this.feesList[0]
      let mealValue = 0
      mealValue = this.valueRate
      if (schoolType !== 'foreign' && meal > 0) {
        mealValue += meal * nationalMeal
      }
      if (schoolType === 'foreign' && meal > 0) {
        mealValue += meal * foreignMeal
      }
      return mealValue
    },
    checkWillPay(option, index, role) {
      if (role === 'orientador') {
        this.nameAdvisor[index].will_pay = option === 'yes'
      }
      if (role === 'coorientador') {
        this.coadvisorList[index].will_pay = option === 'yes'
      }
    },
    async getFees() {
      try {
        const response = await this.$http.get('/fees')
        this.feesList = response.data
      } catch (error) {
        console.log('erro:', error)
      }
    },
    async checkForm() {
      this.resetErrors()
      if (!this.schoolType) {
        this.errors.push('schoolType')
        this.textWarning = this.$t('chooseSchoolType')
        return false
      }
      const studentWillPay = this.studentsList.some(
        (student) => student.will_pay
      )
      if (!studentWillPay) {
        this.errors.push('willPay')
        this.textWarning = this.$t('studentMustBeSelected')
        return false
      }
      const allParticipants = [
        ...this.studentsList,
        ...this.nameAdvisor,
        ...this.coadvisorList
      ]
      if (allParticipants) {
        allParticipants.forEach((participant) => {
          if (participant.will_pay && participant.meal === null) {
            if (!participant.role) {
              this.errors.push('studentMeal')
            }
            if (participant.role === 'orientador') {
              this.errors.push('advisorMeal')
            }
            if (participant.role === 'coorientador') {
              this.errors.push('coadvisorMeal')
            }
          }
          if (participant.role === 'orientador') {
            if (participant.will_pay && participant.isParticipant !== 'yes') {
              this.errors.push('isParticipantAdvisor')
            }
          }
          if (participant.role === 'coorientador') {
            if (participant.will_pay && participant.isParticipant !== 'yes') {
              this.errors.push('isParticipantCoadvisor')
            }
          }
        })
        if (
          this.errors.includes('studentMeal')
          || this.errors.includes('advisorMeal')
          || this.errors.includes('coadvisorMeal')
        ) {
          this.textWarning = this.$t('selectNumberOfMeals')
          return false
        }
        if (
          this.errors.includes('isParticipantAdvisor')
          || this.errors.includes('isParticipantCoadvisor')
        ) {
          this.textWarning = this.$t('participationNotSelected')
          return false
        }
      }
      if (!this.typePayment) {
        this.errors.push('typePayment')
        this.textWarning = this.$t('selectPaymentMethod')
        return false
      }
      if (this.total === 0 && this.typePayment === 'mercado pago') {
        this.errors.push('discount')
        this.textWarning = this.$t('cannotPayMercadoPago')
        return false
      }
      await this.updateAndChangeStatus()
      return window.location.reload()
    },
    check(item, index) {
      if (item.will_pay) {
        if (item.role === 'orientador') {
          this.nameAdvisor[index].isParticipant = 'yes'
        }
        if (item.role === 'coorientador') {
          this.coadvisorList[index].isParticipant = 'yes'
        }
      }
      if (!item.will_pay) {
        this.validateAdvisorsCheckbox(item)
        if (item.role === 'orientador') {
          this.nameAdvisor[index].isParticipant = ''
        }
        if (item.role === 'coorientador') {
          this.coadvisorList[index].isParticipant = ''
        }
      }
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    getStudentsAndInvolveds() {
      const students = this.studentsList.map(({ id, will_pay: willPay, meal }) => ({
        id,
        will_pay: willPay,
        meal
      }))
      const involveds = this.nameAdvisor.map(({ id, will_pay: willPay, meal }) => ({
        id,
        will_pay: willPay,
        meal
      }))
      const coInvolveds = this.coadvisorList.map(({ id, will_pay: willPay, meal }) => ({
        id,
        will_pay: willPay,
        meal
      }))
      return { students, involveds, coInvolveds }
    },
    async savePayment() {
      const { students, involveds, coInvolveds } = this.getStudentsAndInvolveds()
      const body = {
        project: [
          {
            id: this.projectInfo.id,
            discount: this.discount,
            payment_type: this.typePayment
            // , total_fee: this.valorTotalFinal
          }
        ],
        students: [...students],
        involveds: [...involveds, ...coInvolveds]
      }
      await this.$http.patch('/edit-payment-details', body)
    },
    async updateAndChangeStatus() {
      if (this.typePayment !== 'mercado pago') {
        await this.savePayment()
        await this.changeStatus(5)
      }
      if (this.typePayment === 'mercado pago') {
        await this.savePayment()
        await this.changeStatus(4)
      }
      return false
    },
    validateAdvisorsCheckbox(item) {
      if (!item.will_pay) {
        item.meal = 0
        if (item.mealValue > 0) {
          item.finalValue -= item.mealValue
          item.mealValue = 0
        }
      }
    },
    calculeForFirstTime() {
      if (this.mealOption === 0) {
        this.studentsList.forEach((student, index) => {
          if (student.meal === 0) {
            this.calculateFinalValue(this.mealOption, index, student.role)
          }
        })
        this.nameAdvisor.forEach((advisor, index) => {
          if (advisor.meal === 0) {
            this.calculateFinalValue(this.mealOption, index, advisor.type)
          }
        })
        this.coadvisorList.forEach((coadvisor, index) => {
          if (coadvisor.meal === 0) {
            this.calculateFinalValue(this.mealOption, index, coadvisor.type)
          }
        })
      }
    },
    verifyTotal() {
      if (this.subtotal < this.discount) {
        this.errors.push('discount')
        this.textWarning = this.$t('discountPayment')
        return false
      }
      return false
    },
    async changeStatus(id) {
      await this.$http.patch(`/status/project/${ this.projectInfo.id }`, {
        status_id: id
      })
      await this.$http.patch(`/status/project/${ this.projectInfo.id }`, {
        internal_status_id: id
      })
    }
  },
  computed: {
    total() {
      const participants = [
        ...this.studentsList,
        ...this.nameAdvisor,
        ...this.coadvisorList
      ]
      const total = participants.reduce(
        (accumulator, { finalValue, will_pay: willPay, meal }) => {
          if (willPay || meal) {
            return accumulator + finalValue
          }
          return accumulator
        },
        0
      )
      //console.log(`this.discount ${ this.discount }`)
      //console.log('Eita é aqui')
      //console.log(`total ${ total }`)
      if (this.discount > total) {
        this.valorTotalFinal = 0
      } else {
        this.valorTotalFinal = total - this.discount
      }
      //console.log(`this.valorTotalFinal ${ this.valorTotalFinal }`)
      return this.valorTotalFinal
      // return (this.discount > total) ? 0 : total - this.discount
    },
    subtotal() {
      const participants = [
        ...this.studentsList,
        ...this.nameAdvisor,
        ...this.coadvisorList
      ]
      const subTotal = participants.reduce(
        (accumulator, { finalValue, will_pay: willPay, meal }) => {
          if (willPay || meal) {
            return accumulator + finalValue
          }
          return accumulator
        },
        0
      )
      return subTotal
    }
  }
}
</script>
<style lang="scss" scoped>
.title-payment {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 160%;
  color: #26768d;
}
.title-project {
  margin: 20px 0 50px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  color: #26768d;
}
.container-content {
  padding: 30px 15px;
}
.select-type {
  max-width: 236px;
  margin-bottom: 30px;
}
.container-header {
  display: flex;
  justify-content: space-between;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 160%;
    color: #2d2d2d;
  }
}
.subtitle {
  margin-top: 30px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #2d2d2d;
}
.container-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
label {
  display: flex;
  align-items: flex-start;
  font-size: 16px !important;
  cursor: pointer;
}
input[type="checkbox"] {
  margin-top: 3px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
}
input[type="checkbox"]:before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 4px;
  background: white;
}
input[type="checkbox"]:checked:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #26768d;
  background-color: #26768d;
  border-radius: 3px;
}
input[type="checkbox"]:checked:after {
  content: "";
  width: 6px;
  height: 12px;
  border: 2px solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.color-text {
  color: #135273;
  font-weight: bold;
}
.size-name {
  width: 350px;
}
.size-rate {
  width: 100px;
}
.size-meal {
  width: 220px;
}
.size-final {
  width: 120px;
}
.advisors {
  margin-bottom: 10px;
}
.coadvisors {
  margin-bottom: 20px;
}
.type-payment {
  display: flex;
  padding-right: 4.4%;
  justify-content: space-between;
}
.type-payment2 {
  display: flex;
  padding-right: 180px;
  gap: 70px;
}
.title-type-payment {
  margin-top: 40px;
  font-weight: bold;
  font-size: 20px;
}
.container-payment {
  display: flex;
  align-items: center;
  label {
    margin-left: 10px;
  }
}
.total-payment {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  gap: 29px;
}
.button-save {
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  padding-right: 4.4%;
}
.custom-radio input {
  display: none;
}
.custom-radio input[type="radio"] {
  appearance: none;
  margin-top: 3px;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #636b6f;
}
.custom-radio input + label:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
  border: 1px solid #636b6f;
  border-radius: 100px;
  background: white;
}
.custom-radio input:checked + label:before {
  content: "";
  width: 18px;
  height: 18px;
  border: 1px solid #26768d;
  background-color: #26768d;
  box-sizing: border-box;
}
.obs {
  margin-bottom: 20px;
}
.discount {
  display: flex;
  flex-direction: column;
  padding-left: 72.53%;
  border-radius: 4px;
  margin-top: 20px;
}
.values-finals {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.currency-input {
  width: 180px;
  padding: 4px 8px;
  border: 1px solid #cdcbcb;
  border-radius: 8px;
}
</style>
