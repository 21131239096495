import { render, staticRenderFns } from "./InputScore.vue?vue&type=template&id=6600e096&scoped=true&"
import script from "./InputScore.vue?vue&type=script&lang=js&"
export * from "./InputScore.vue?vue&type=script&lang=js&"
import style0 from "./InputScore.vue?vue&type=style&index=0&id=6600e096&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6600e096",
  null
  
)

export default component.exports