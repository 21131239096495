<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t("schoolInstitutionData")}} -</span>
        <span class="form__title__complement">{{$t('address')}}</span>
      </div>
      <div class="form__info">
        <text-warning
          :text="'considerAllFieldObligatory'"
        />
      </div>
      <input-select-location
        :class="[{warning: checkErrorIfContains('country')}, 'mb-7']"
        id="country"
        :items="countries"
        v-model="form.country"
        label=country
        :onEnter="() => setLocalization('country', form.country)"
        @input="resetErrors"
      />
      <input-masked
        v-if="form.country == 'Brazil'"
        :class="['mb-7']"
        required
        label= zipCode
        id="zipCode"
        placeholder= inputCepPlaceholder
        type="text"
        max=40
        v-model="form.zipCode"
        @input="resetErrors"
        :mask="['#####-###']"
      />
      <template v-if="!cepValidate">
        <input-select-location
          v-if="form.country == 'Brazil'"
          :class="[{warning: checkErrorIfContains('state')}, 'mb-7']"
          label= state
          id="state"
          type="text"
          max=45
          :items="states"
          :onEnter="() => setLocalization('state', form.state)"
          v-model="form.state"
          @input="form.state = $event"
        />
      </template>
      <template v-else>
        <input-default
          v-if="form.country == 'Brazil'"
          :class="[{warning: checkErrorIfContains('state')}, 'mb-7']"
          label= state
          id="state"
          type="text"
          max=2
          v-model="form.state"
          @input="form.state = $event"
        />
      </template>
      <template v-if="!cepValidate">
        <input-select-location
          v-if="form.country === 'Brazil'"
          :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
          label= city
          id="city"
          type="text"
          max=100
          :items="cities"
          :onEnter="() => setLocalization('city', form.city)"
          v-model="form.city"
          @input="resetErrors"
        />
      </template>
      <template v-else>
        <input-default
          v-if="form.country === 'Brazil'"
          :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
          label= city
          id="city"
          type="text"
          max=100
          v-model="form.city"
          @input="resetErrors"
        />
      </template>
      <input-default
        v-if="form.country == 'Brazil'"
        :class="[{warning: checkErrorIfContains('address')}, 'mb-7']"
        label= address
        id="address"
        placeholder= addressExample
        type="text"
        max=99
        v-model="form.address"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('neighborhood')}, 'mb-7']"
        v-if="form.country === 'Brazil'"
        class="mb-7"
        label='Bairro'
        id="neighborhood"
        max=100
        placeholder= neighborhoodPlaceholder
        type="text"
        v-model="form.neighborhood"
      />
      <input-default
        v-if="form.country == 'Brazil'"
        :class="[{warning: checkErrorIfContains('number')}, 'mb-7']"
        label= number
        id="number"
        placeholder= numberPlaceholder
        type="text"
        max=15
        v-model="form.number"
        @input="resetErrors"
      />
      <input-default
        v-if="form.country == 'Brazil'"
        class="mb-7"
        label= complementOptional
        id="complement"
        placeholder= complementPlaceholder
        type="text"
        max=100
        v-model="form.complement"
      />
      <input-default
        v-if="form.country && form.country != 'Brazil'"
        :class="[{warning: checkErrorIfContains('state')}, 'mb-7']"
        label= state
        disabled: false
        id="state"
        placeholder= statePlaceholder
        type="text"
        max=45
        v-model="form.state"
        @input="resetErrors"
      />
      <input-default
        v-if="form.country && form.country != 'Brazil'"
        :class="[{warning: checkErrorIfContains('city')}, 'mb-7']"
        label= city
        id="city"
        placeholder= cityPlaceholder
        type="text"
        max=100
        v-model="form.city"
        @input="resetErrors"
      />
      <input-default
        v-if="form.country != 'Brazil'"
        :class="[{warning: checkErrorIfContains('address')}, 'mb-7']"
        label= address
        id="address"
        placeholder= addressExample
        type="text"
        max=100
        v-model="form.address"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('neighborhood')}, 'mb-7']"
        v-if="form.country !== 'Brazil'"
        class="mb-7"
        label=neighborhood
        id="neighborhood"
        placeholder= neighborhoodPlaceholder
        type="text"
        max=100
        v-model="form.neighborhood"
      />
      <input-default
        v-if="form.country != 'Brazil'"
        class="mb-7"
        label= numberOptional
        id="number"
        placeholder= numberPlaceholder
        type="text"
        max=15
        v-model="form.number"
      />
      <input-default
        v-if="form.country != 'Brazil'"
        label= complementOptional
        id="complement"
        placeholder= complementPlaceholder
        type="text"
        max=100
        v-model="form.complement"
      />
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, InputSelectLocation, ButtonDefault, FeedbackWarning, TextWarning, InputMasked
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'

export default {
  name: 'FormSchoolDataAddress',
  components: {
    InputDefault, InputSelectLocation, ButtonDefault, FeedbackWarning, TextWarning, InputMasked
  },
  data() {
    return {
      form: {
        country: '',
        state: '',
        city: '',
        zipCode: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: ''
      },
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      cepValidate: false
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.country) {
      this.form.country = this.fairAffiliation.country
      this.form.zipCode = this.fairAffiliation.zipcode
      this.form.state = this.fairAffiliation.state
      this.form.city = this.fairAffiliation.city
      this.form.address = this.fairAffiliation.street
      this.form.number = this.fairAffiliation.house_number
      this.form.complement = this.fairAffiliation.complement
      this.form.number = this.fairAffiliation.number
      this.form.neighborhood = this.fairAffiliation.neighborhood
    }
  },
  watch: {
    'form.state': function (newVal, oldVal) {
      this.cities = []
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ this.form.state }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    'form.zipCode': function (newVal, oldVal) {
      const cep = String(this.form.zipCode).replace(/\D/g, '')
      if (cep.length == 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              this.cepValidate = false
              this.errors.push('zipCode')
              this.states = states
              this.textWarning = 'checkTheCep'
            } else {
              this.form.state = data.uf
              this.form.city = data.localidade
              this.form.address = data.logradouro
              this.form.neighborhood = data.bairro
              this.cepValidate = true
            }
          })
          .catch((err) => {
            this.cepValidate = false
            this.errors.push('zipCode')
            this.textWarning = 'checkTheCep'
          })
      } else if (cep.length < 8) {
        this.cepValidate = false
      }
    },
    'form.country': function () {
      if (this.form.country !== 'Brazil') {
        this.form.state = ''
        this.form.city = ''
        this.form.address = ''
        this.form.neighborhood = ''
      }
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      this.resetErrors()
      if (this.form.country == 'Brazil') {
        for (const key in this.form) {
          if (!this.form[key] && key != 'complement') {
            if (key !== 'zipCode') {
              this.errors.push(key)
            }
          }
        }
      } else {
        if (!this.form.country) {
          this.errors.push('country')
        }
        if (!this.form.state) {
          this.errors.push('state')
        }
        if (!this.form.city) {
          this.errors.push('city')
        }
        if (!this.form.address) {
          this.errors.push('address')
        }
        if (!this.form.neighborhood) {
          this.errors.push('neighborhood')
        }
      }
      if (!this.errors.length) {
        this.saveForm()
      }
    },
    saveForm() {
      try {
        let screen
        const formData = this.$store.getters.fairAffiliation
        const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
        if (currentScreen < 2) {
          screen = 2
        } else {
          screen = currentScreen
        }

        const newData = {
          ...formData,
          country: this.form.country,
          zipcode: String(this.form.zipCode).replace(/\D/g, ''),
          state: this.form.state,
          city: this.form.city,
          street: this.form.address,
          //          house_number: parseInt(this.form.number),
          house_number: this.form.number,
          neighborhood: this.form.neighborhood,
          complement: this.form.complement,
          number: this.form.number ? this.form.number.replace(/\D/g, '') : null,
          currentScreen: screen
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        console.log('erro: ', error)
        this.textWarning = 'checkTheData'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }

    .form__title__complement {
      font-weight: 400;
      margin-left: 11px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
