<template>
  <div class="home">
    <InitialRegisterProjectSelection />
  </div>
</template>

<script>
import { InitialRegisterProjectSelection } from '@/components/templates'

export default {
  name: 'RegisterProjectSelection',
  components: { InitialRegisterProjectSelection }
}
</script>
