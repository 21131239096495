<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('othersInvolved')}}</p>
        <p class="form__text">{{$t('caseThereAreOthersInvolvedDescription')}}</p>
        <text-warning :text="$t('considerAllFieldObligatory')"/>
      </div>

      <RadioGroup
        :class="[{ warning: checkErrorIfContains('othersInvolved') }, 'mb-1']"
        label=caseThereAreOthersInvolved
        :options="generalOptions"
        v-model="form.othersInvolved"
        @change="resetErrors"
      />

      <main class="form__main mt-4" v-if="form.infos.length">
        <div class="form__item mb-5" v-for="(info, index) in form.infos" :key="index">
          <div class="person__checkbox_delete">
            <input-checkbox-title
              :class="[
                { warning: checkErrorIfContains('active' + index) },
                'mb-7 checkbox__person'
              ]"
              :label="$t('person') + `  ${index + 1}`"
              v-model="form.infos[index].active"
              id="advisor"
              @change="resetErrors"
            />

            <div class="icon-delete" @click="deleteInvolved(index)">
              <img src="@/assets/bin-1.png" alt="Delete" />
            </div>
          </div>

          <div v-if="form.infos[index].active">
            <input-default
              label=fullName
              :class="[{ warning: checkErrorIfContains('full_name' + index) }, 'input__space']"
              placeholder=fullNamePlaceholder
              type="text"
              v-model="form.infos[index].full_name"
              @input="resetErrors"
            />

            <input-masked
              v-if="!form.infos[index].country || form.infos[index].country == 'Brazil'"
              :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
              label=cpf
              id="codigo"
              placeholder="xxx.xxx.xxx-xx"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
              :mask="['###.###.###-##']"
            />

            <input-default
              v-else
              :class="[{ warning: checkErrorIfContains('cpf' + index) }, 'input__space']"
              label="Identification number"
              id="codigo"
              placeholder="xxx.xxx.xxx-xx"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{ warning: checkErrorIfContains('sex' + index) }, 'input__space mb-2']"
              label=sex
              :options="sexOptions"
              v-model="form.infos[index].sex"
              @change="resetErrors"
            />

            <input-default
              :class="[{ warning: checkErrorIfContains('phone' + index) }, 'input__space']"
              label=enterInstitutionSchoolPhone
              id="phone"
              placeholder="(00) 00000-0000"
              type="text"
              max="20"
              v-model="form.infos[index].phone"
              @input="resetErrors"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />

            <input-default
              :class="[{ warning: checkErrorIfContains('email' + index) }, 'input__space']"
              label=email
              id="email"
              placeholder=enterTheEmail
              type="text"
              v-model="form.infos[index].email"
              @input="resetErrors"
            />

            <input-default
              :class="[{ warning: checkErrorIfContains('type' + index) }, 'input__space']"
              label=designation
              id="type"
              placeholder=enterDesignation
              type="text"
              v-model="form.infos[index].type"
              @input="resetErrors"
            />

            <text-info
              class="mb-2"
              text=designationExample
            />

            <div class="separator">
              <h3>{{$t('address')}}</h3>
            </div>

            <input-select-location
              :class="[{ warning: checkErrorIfContains('country' + index) }, 'input__space']"
              id="country"
              :items="countries"
              v-model="form.infos[index].country"
              label=country
              @input="resetErrors"
            />

            <input-masked
              v-if="form.infos[index].country == 'Brazil'"
              :class="[{ warning: checkErrorIfContains('zipCode' + index) }, 'input__space']"
              required
              label="CEP"
              id="zipCode"
              :placeholder="$t('inputCepPlaceholder')"
              type="text"
              v-model="form.infos[index].zipCode"
              @input="resetErrors"
              :mask="['#####-###']"
            />

            <template v-if="form.infos[index].cepValidate">
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                label=state
                id="state"
                type="text"
                v-model="form.infos[index].state"
                @input="resetErrors"
              />
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                label=city
                id="city"
                type="text"
                v-model="form.infos[index].city"
                @input="resetErrors"
              />
            </template>
            <template v-else>
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
                id="serie"
                :items="states"
                v-model="form.infos[index].state"
                label=state
                @input="resetErrors"
                :selected="form.infos[index].state"
              />
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
                id="serie"
                :items="cities"
                v-model="form.infos[index].city"
                label=city
                @input="resetErrors"
                :selected="form.infos[index].city"
              />
            </template>
            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('address' + index) }, 'input__space']"
              label=address
              id="address"
              placeholder=addressExample
              type="text"
              max="200"
              v-model="form.infos[index].street"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country === 'Brazil'"
              :class="[{ warning: checkErrorIfContains('district' + index) }, 'input__space']"
              label=neighborhood
              id="neighborhood"
              placeholder=neighborhoodPlaceholder
              type="text"
              max="150"
              v-model="form.infos[index].district"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('house_number' + index) }, 'input__space']"
              label="number"
              id="house_number"
              placeholder=numberPlaceholder
              type="text"
              max="15"
              v-model="form.infos[index].house_number"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{ warning: checkErrorIfContains('complement' + index) }, 'input__space']"
              label=complementOptional
              id="complement"
              placeholder=complementPlaceholder
              type="text"
              max="150"
              v-model="form.infos[index].complement"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{ warning: checkErrorIfContains('state' + index) }, 'input__space']"
              label="State/Province"
              id="state"
              placeholder=statePlaceholder
              type="text"
              max="200"
              v-model="form.infos[index].state"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{ warning: checkErrorIfContains('city' + index) }, 'input__space']"
              label="City"
              id="city"
              placeholder="Input your city"
              type="text"
              max="200"
              v-model="form.infos[index].city"
              @input="resetErrors"
            />

            <div class="separator">
              <h3>{{$t('registrationData')}}</h3>
            </div>

            <RadioGroup
              :class="[
                { warning: checkErrorIfContains('participated_mostratec' + index) },
                'input__space'
              ]"
              label=haveYouParticipatedAnyOtherMostratec
              :options="generalOptions"
              v-model="form.infos[index].participated_mostratec"
              @change="resetErrors"
            />

            <div class="levels">
              <p :class="[{ warning: checkErrorIfContains('deficiency' + index) }, 'title']">
                {{$t('doYouHaveAnyKindOfDisability')}}
              </p>
              <label>
                <input
                  @change="resetErrors, validCheckoboxGroup(form.infos[index], index)"
                  value="nao"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="fisica"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('fis')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="intelectual"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('intellectual')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="auditiva"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('auditory')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="visual"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                  :disabled="form.infos[index].deficiency.includes('nao')"
                />{{$t('visual')}}
              </label>
            </div>
            <div class="levels" v-if="!form.infos[index].deficiency.includes('nao')">
              <p
                :class="[
                  { warning: checkErrorIfContains('special_service' + index) },
                  'title mt-3'
                ]"
              >
                {{$t('willYouNeedAnySpecializedAssistance')}}
              </p>
              <label>
                <input
                  @change="resetErrors, validCheckoboxGroup(form.infos[index], index)"
                  value="nao"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="acessibilidade"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                />{{$t('architecturalAccessibility')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="libras"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                />{{$t('libraInterpreter')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="ledor"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                >{{$t('reader')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="outro"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                  :disabled="form.infos[index].special_service.includes('nao')"
                />{{$t('othersMale')}}
              </label>
              <input-default
                v-if="form.infos[index].special_service.includes('outro')"
                :class="[{ warning: checkErrorIfContains('otherSpecialService' + index) }, 'mt-0']"
                id="city"
                placeholder="enterTheSpecializedService"
                type="text"
                v-model="form.infos[index].otherSpecialService"
                @input="resetErrors"
              />
            </div>

            <input-select
              :class="[{ warning: checkErrorIfContains('t_shirt' + index) }, 'input__space']"
              id="t_shirt"
              :items="typeShirtOptions"
              v-model="form.infos[index].t_shirt"
              label=typeOfShirt
              @input="resetErrors"
            />

            <input-select
              v-if="form.infos[index].t_shirt === 'no'"
              :class="[{ warning: checkErrorIfContains('t_shirt_size' + index) }, 'input__space']"
              id="english_level"
              :items="sizeShirtOptions"
              v-model="form.infos[index].t_shirt_size"
              label=whatSizeShirt
              @input="resetErrors"
            />
          </div>
        </div>
      </main>

      <button-default
        :disabled="form.othersInvolved == 'no' ? true : false"
        :onClick="addNewInvolved"
        class="button__add"
        :style="{
          cursor: form.othersInvolved == 'no' ? 'no-drop' : 'pointer',
          'background-color': form.othersInvolved == 'no' ? '#4c4c4e' : '#1B778B'
        }"
      >
        <div class="buttonText__icon">
          {{$t('addNewInvolved')}}
          <img class="icon-plus" src="@/assets/add-icon.svg" alt="Adicionar" />
        </div>
      </button-default>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndContinue')}}</button-default>
      </div>

      <modal-selection-process
        title=disablePeopleInvolved
        :onClick="() => closeModalAndYes()"
        @closeModal="() => closeModal()"
        :modal="warningModal"
      >
        <div>
          <p class="form__textModal">{{$t('disablePeopleInvolvedParagraph')}}</p>
        </div>
      </modal-selection-process>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
    {{ cStateSelected }}
  </div>
</template>

<script>
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  RadioGroup,
  InputCheckboxTitle,
  InputMasked,
  InputSelectLocation,
  InputSelect,
  TextInfo
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'
import { ModalSelectionProcess } from '@/components/organisms/Modals'
// import { mapGetters, mapActions } from "vuex";

export default {
  name: 'FormProjectHighOthersInvolveds',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    RadioGroup,
    InputCheckboxTitle,
    InputMasked,
    InputSelectLocation,
    InputSelect,
    TextInfo,
    ModalSelectionProcess
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  data() {
    return {
      warningModal: false,
      indexToDelete: false,
      form: {
        othersInvolved: '',
        infos: []
      },
      quantityOptions: [
        { label: this.$t('individual'), value: 1 },
        { label: this.$t('pair'), value: 2 },
        { label: this.$t('trio'), value: 3 }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: 'yes' },
        { label: this.$t('no'), value: 'no' }
      ],
      sexOptions: [
        { label: this.$t('maleSingle'), value: 'male' },
        { label: this.$t('femaleSingle'), value: 'female' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: 'avancado' },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' }// ,
        // { text: 'P', value: 'p' },
        // { text: 'M', value: 'm' },
        // { text: 'G', value: 'g' },
        // { text: 'GG', value: 'gg' }
      ],
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },

  mounted() {
    if (this.allDataForm.FormProjectHighOthersInvolveds) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectHighOthersInvolveds
      }
    }
  },
  computed: {
    zipCodeFirst() {
      if (this.form.infos.length) {
        return this.form.infos[0].zipCode
      }
    },
    zipCodeSecond() {
      if (this.form.infos.lenght > 1) {
        return this.form.infos[1].zipCode
      }
    },
    zipCodeThird() {
      if (this.form.infos.lenght > 2) {
        return this.form.infos[2].zipCode
      }
    },
    cStateSelected() {
      if (this.form.infos[0]) {
        if (this.form.infos[0].state) {
          this.executeGetCities(this.form.infos[0].state)
        }
      }
      return ''
    }
  },
  watch: {
    zipCodeFirst() {
      this.getAddressInfo(0)
    },
    zipCodeSecond() {
      this.getAddressInfo(1)
    },
    zipCodeThird() {
      this.getAddressInfo(2)
    },
    'form.othersInvolved': function () {
      if (this.form.othersInvolved == 'no' && this.form.infos.length) {
        this.warningModal = true
      }
      if (this.form.othersInvolved == 'yes' && !this.form.infos.length) {
        this.addNewInvolved()
      }
    },
    allDataForm() {
      if (this.allDataForm.FormProjectHighOthersInvolveds) {
        this.form = {
          ...this.form,
          ...this.allDataForm.FormProjectHighOthersInvolveds
        }
      }
    }
  },
  methods: {
    validCheckoboxGroup(actualData, index) {
      if (actualData.deficiency.includes('nao')) {
        this.form.infos[index].deficiency = ['nao']
      }
      if (actualData.special_service.includes('nao')) {
        this.form.infos[index].special_service = ['nao']
      }
    },
    executeGetCities(state) {
      this.cities = []
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`)
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    checkForm() {
      this.resetErrors()
      const emails = []

      if (this.form.othersInvolved == 'no') {
        this.temporarySave()
        return this.saveForm()
      } if (!this.form.othersInvolved) {
        this.errors.push('othersInvolved')
      }
      this.form.infos.map((item, index) => {
        for (const key in item) {
          if (typeof item[key] === 'boolean') {
            continue
          }
          if (item[key].length == 0 || !item[key]) {
            if (
              key === 'complement'
              || (key === 'special_service' && item.deficiency.includes('nao'))
              // || (key === 't_shirt_size' && (item.t_shirt === 'no' || !item.t_shirt))
            ) {
            } else {
              this.errors.push(`${ key }${ index }`)
            }
            this.textWarning = this.$t('warningAllFieldsAreObligatory')
          }
        }
        if (item.country != 'Brazil') {
          const indexZipcode = this.errors.indexOf(`zipCode${ index }`)
          if (indexZipcode > -1) {
            this.errors.splice(indexZipcode, 1)
          }
          const indexStreet = this.errors.indexOf(`street${ index }`)
          if (indexStreet > -1) {
            this.errors.splice(indexStreet, 1)
          }
          const indexDistrict = this.errors.indexOf(`district${ index }`)
          if (indexDistrict > -1) {
            this.errors.splice(indexDistrict, 1)
          }
          const indexNumber = this.errors.indexOf(`house_number${ index }`)
          if (indexNumber > -1) {
            this.errors.splice(indexNumber, 1)
          }
          const indexComplement = this.errors.indexOf(`complement${ index }`)
          if (indexComplement > -1) {
            this.errors.splice(indexComplement, 1)
          }
        }
        if (item.deficiency.includes('nao')) {
          const indexSpecial = this.errors.indexOf(`special_service${ index }`)
          if (indexSpecial > -1) {
            this.errors.splice(indexSpecial, 1)
          }
        }
        if (!item.special_service.includes('outro')) {
          const indexProgram = this.errors.indexOf(`otherSpecialService${ index }`)
          if (indexProgram > -1) {
            this.errors.splice(indexProgram, 1)
          }
        }
      })
      if (!this.errors.length) {
        this.form.infos.forEach((value, index) => {
          if (!this.validateEmail(value.email, index)) {
            this.errors.push(`email${ index }`)
            this.textWarning = 'E-mail inválido!'
          }
          emails.push(value.email)
        })

        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'E-mails inseridos devem ser distintos!'
          return false
        }

        if (!this.errors.length) {
          this.resetErrors()
          this.temporarySave()
          this.saveForm()
        }
      }
    },
    async temporarySave() {
      this.allDataForm.FormProjectHighOthersInvolveds = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    saveForm() {
      try {
        const involveds = this.form.infos
        this.$emit('changeElement', {
          direction: 'up',
          FormProjectHighOthersInvolveds: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', { direction: 'down' })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmail(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.infos[index].email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    },
    getAddressInfo(index) {
      const cep = String(this.form.infos[index].zipCode).replace(/\D/g, '')
      if (cep.length == 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              if (this.allDataForm.FormProjectHighOthersInvolveds) {
                this.form.infos[i].cepValidate = true
                return
              }
              this.form.infos[index].cepValidate = false
              this.form.infos[index].state = ''
              this.errors.push('zipCode')
              this.textWarning = 'Informe um CEP válido'
            } else {
              this.form.infos[index].cepValidate = true
              this.form.infos[index].state = data.uf
              this.form.infos[index].city = data.localidade
              this.form.infos[index].district = data.bairro
              this.form.infos[index].street = data.logradouro
              this.resetErrors()
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
            }
          })
          .catch((err) => {
            if (this.allDataForm.FormProjectHighOthersInvolveds) {
              const projectAdvisor = this.allDataForm.FormProjectHighOthersInvolveds.form.infos[index]

              this.form.infos[index].state = projectAdvisor.state
              this.form.infos[index].city = projectAdvisor.city
              this.form.infos[index].district = projectAdvisor.district
              this.form.infos[index].street = projectAdvisor.street
            }
            this.form.infos[index].cepValidate = false
            this.errors.push('zipCode')
            this.textWarning = 'Informe um CEP válido'
          })
      }
    },
    addNewInvolved() {
      this.form.infos.push({
        cepValidate: false,
        active: false,
        type: '',
        full_name: '',
        cpf: '',
        sex: '',
        phone: '',
        email: '',
        country: '',
        zipCode: '',
        state: '',
        city: '',
        street: '',
        district: '',
        house_number: '',
        complement: '',
        participated_mostratec: '',
        deficiency: [],
        special_service: [],
        otherSpecialService: '',
        t_shirt: '',
        t_shirt_size: 'no'
      })
    },
    deleteInvolved(index) {
      this.warningModal = true
      this.indexToDelete = index
      this.resetErrors()
    },
    closeModal() {
      this.warningModal = false
      this.form.othersInvolved = 'yes'
    },
    closeModalAndYes() {
      this.warningModal = false
      if (typeof this.indexToDelete !== 'boolean') {
        this.form.infos.splice(this.indexToDelete, 1)
      } else {
        this.form.infos = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 600px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  .button__add {
    align-self: flex-start;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .buttonText__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;

    .icon-plus {
      margin-left: 5px;
    }
  }

  .person__checkbox_delete {
    display: flex;
    justify-content: space-between;

    .checkbox__person {
      flex: 1;
    }
  }

  .icon-delete {
    max-width: 25px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__main {
    .form__item {
      .input__space {
        margin-top: 30px;
        margin-bottom: 20px;
      }

      .separator {
        border-bottom: 1px solid $primary;
        padding-bottom: 15px;
        margin-top: 30px;

        color: $primaryDark;
        font-family: Mulish;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
      }

      &--info {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        color: $primary;
        max-width: 520px;
      }

      .levels {
        display: flex;
        flex-direction: column;

        .title {
          color: #303030;
          display: flex;
          flex-direction: column;
          font-size: 16px !important;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
        }

        label {
          display: flex;
          color: $almostBlack;
          font-size: 16px;
          margin-bottom: 5px;

          input {
            zoom: 1.5;
            margin-right: 5px;
          }
        }

        .warning {
          color: #ff9800;
        }
      }
    }
  }

  &__textModal {
    color: #18516f;
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    max-width: 425px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
