<template>
  <label class="custom-file-upload">
    <input
      type="file"
      multiple
      :name="reference"
      :id="reference"
      :ref="reference"
      @input="onChange"
      :accept="`.${type}`"
    />
    <img :src="require(`@/assets/pencil-write.png`)" alt=""/>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: 'ButtonIconFileLink',
  data() {
    return {
      fileName: '',
      url: '',
      notIsPdf: `Insira um ${ this.type }!`,
      error: false,
      azureFail: this.$t('azureMessageFail'),
      msgSizeLimit: `Tamanho do arquivo ultrapassa ${ this.limitSize / 1000000 }Mb!`,
      msgFail: ''
    }
  },
  props: {
    reference: String,
    type: String,
    actualData: Object,
    limitSize: {
      type: Number,
      required: true
    }
  },
  methods: {
    async onChange() {
      this.error = false
      const fileNameAux = this.$refs[this.reference].files[0].name.split('.')

      const fData = new FormData()
      fData.append('file', this.$refs[this.reference].files[0])
      if (fileNameAux[fileNameAux.length - 1].toLowerCase() !== this.type) {
        alert(`Formato inválido, insira um ${ this.type }`)
        this.fileName = this.notIsPdf
        this.$emit('file', this.actualData)
      } else {
        try {
          const { size } = this.$refs[this.reference].files[0]
          if (size > this.limitSize) {
            this.error = true
            this.msgFail = this.msgSizeLimit
            this.$emit('emitError', this.msgFail)
            return
          }
          const header = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }

          this.$emit('emitLoading', true)
          const { data: response } = await this.$http.post('/upload', fData, header)

          this.fileName = this.$refs[this.reference].files[0].name
          this.$emit('emitFile', response.data, this.fileName)

          this.error = false
        } catch (error) {
          this.error = true
          this.msgFail = this.azureFail
          this.$emit('emitError', this.msgFail)
          return false
        } finally {
          this.$emit('emitLoading', false)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

input[type="file"] {
  display: none;
}

.custom-file-upload {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 9px;
  color: $primary;

  &:hover {
    opacity: 0.92;
  }
}

</style>
