<template>
  <section>
    <nav class="navigation">
      <div class="nav__item" :class="{active: list == 'all'}" @click="setList('all')">Todas as edições</div>
    </nav>
    <div class="search">
      <div class="search__top col-12">
        <h3 class="search__top--label">Busca
        </h3>
      </div>
      <div class="search__inputs">
        <div class="search__item mt-6">
          <input-select
            id="editionSelect"
            :items="editionOptions"
            v-model="fairEdition"
            label="Edição da feira"
            placeholder="Busque as solicitações de feira por edição"
            @input="() => addFairEdition()"
          />
          <div class="badges" v-if="filtersEdition">
            <span class="badgeFilter" v-for="(item, index) in filtersEdition" :key="`${item}-${index}`">
              {{item}}
              <button @click="removeFilter('filtersEdition', index)"><v-icon medium color="white">mdi-close-circle</v-icon></button>
            </span>
          </div>
        </div>
        <!-- <div class="search__item col-5">
          <input-default
            label="Edição da feira"
            id="fairEdition"
            placeholder="Busque as solicitações de feira por edição"
            type="text"
            v-model="fairEdition"
            :onEnter="addFairEdition"
          />
          <div class="badges" v-if="filtersEdition">
            <span class="badgeFilter" ="(item, index) in filtersEdition" :key="`${item}-${index}`">
              {{item}}
              <button @click="removeFilter('filtersEdition', index)"><v-icon medium color="white">mdi-close-circle</v-icon></button>
            </span>
          </div>
        </div> -->
      </div>
    </div>
    <div class="info">
      <div class="filter">
        <button @click="modalFilterSidebar = !modalFilterSidebar">
          <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
          <span>Filtrar</span>
        </button>
      </div>
    </div>

    <modal-filter-sidebar-edition @closeModal="modalFilterSidebar = !modalFilterSidebar" :modal="modalFilterSidebar"></modal-filter-sidebar-edition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { InputDefault, InputSelect } from '@/components/atoms'
import { ModalFilterSidebarEdition } from '@/components/organisms/Modals'

export default {
  name: 'FilterEdition',
  components: { InputDefault, ModalFilterSidebarEdition, InputSelect },
  computed: {
    ...mapGetters({
      count: 'list/countDone',
      lang: 'lang',
      editionRequestFilter: 'editionRequestFilter'
    })
  },
  data() {
    return {
      fairEdition: '',
      editionOptions: [],
      list: 'all',
      filtersEdition: [],
      modalFilterSidebar: false
    }
  },
  mounted() {
    this.getEditionYears()
  },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage'
      // editionRequestFilter: 'editionRequestFilter',
    }),
    setList(type) {
      this.list = type
    },
    addFairEdition() {
      if (!this.filtersEdition.includes(this.fairEdition)) {
        this.filtersEdition.push(this.fairEdition)
        this.fairEdition = ''
        this.filterRequests()
        this.$emit('filterEditionsList')
      }
    },
    removeFilter(array, index) {
      this[array].splice(index, 1)
      this.filterRequests()
      this.$emit('filterEditionsList')
    },
    async getEditionYears() {
      const { data } = await this.$http.get('/mostratec')
      const formatedYears = data.map((i) => ({
        text: i.name_edition,
        value: i.name_edition
      }))
      this.editionOptions = formatedYears
    },
    filterRequests() {
      const oldFilters = this.$store.getters.editionRequestFilter
      const newFilters = {
        ...oldFilters,
        year_edition: this.filtersEdition
      }
      if (!newFilters.year_edition.length) delete newFilters.year_edition
      this.$store.commit('EDITION_LIST_FILTER', newFilters)
      this.$emit('filterEditionRequestsList')
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  align-items: center;
  background-color: $white;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px;
  margin: 0px -15px;

  .nav__item {
    color: $primaryDark;
    cursor: pointer;
    line-height: 120%;
    opacity: 0.8;
    padding: 0px 20px 15px 20px;
  }

  .active {
    font-weight: 600;
    color: $primaryDark;
    border-bottom: 3px solid $primary;
  }
}

.search {
  &__top {
    display: flex;
    justify-content: space-between;
    line-height: 120%;
    margin-top: 20px;

    &--label {
      font-weight: bold;
      font-size: 1rem;
      line-height: 120%;
      color: $almostBlack;
      opacity: 0.8;
    }

    span {
      font-weight: 600;
      font-size: 1rem;
      color: $primaryDark;
    }
  }

  &__inputs {
    display: flex;
    margin-bottom: 50px;

    .search__item {
      .badges {
        margin-top: 15px;

        span {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          background: $primary;
          border-radius: 49px;
          opacity: 0.8;

          color: $white;
          font-weight: bold;
          font-size: 14px;
          min-width: 79px;
          height: 28px;
          padding: 0px 8px;

          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.info {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labels {
  display: flex;

  .text span {
    font-weight: 600;
    font-size: 14px;
    color: $almostBlack;
    margin-right: 10px;
  }

  .badge {
    margin-right: 15px;

    span {
      margin-left: 5px;
    }
  }
}

.filter button {
  @include d-flex-center;
  background-color: $white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 92px;

  font-weight: 600;
  line-height: 20px;
  color: $primary;

  &:hover {
    background-color: #d6d6d6;
  }
}
</style>
