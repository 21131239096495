<template>
  <div class="container-app">
    <header-default @menuSelected="menuSelected" />
    <div class="templates-container">
      <elementary-projects-info @navSelected="(value) => navSelected(value)" :projectInfo="projectInfo" class="left"/>

      <transition>
        <component :is="component" :projectInfo="projectInfo"/>
      </transition>
    </div>
  </div>
</template>

<script>
import { HeaderDefault } from '@/components/organisms'
import {
  ProjectsElementaryStudents, ProjectsElementaryInvolveds, ProjectsElementaryInstitution, ProjectsElementaryRevision, ProjectsElementary
} from '@/components/templates'

export default {
  name: 'ElementaryProjectDetails',
  components: {
    HeaderDefault, ProjectsElementaryStudents, ProjectsElementaryInvolveds, ProjectsElementaryInstitution, ProjectsElementaryRevision, ProjectsElementary
  },
  data: () => ({
    tabs: {
      revision: 'projects-elementary-revision',
      projects: 'projects-elementary',
      institution: 'projects-elementary-institution',
      involveds: 'projects-elementary-involveds',
      students: 'projects-elementary-students',
      contract: 'projects-elementary-contract'
    },
    component: 'projects-elementary-revision',
    projectInfo: {}
  }),
  created() {
    this.getInfos()
  },
  methods: {
    menuSelected(e) {
      this.fields = e
    },
    navSelected(value) {
      this.component = this.tabs[value]
      console.log(this.component)
    },
    async getInfos() {
      const { data: response } = await this.$http.get(`/project/${ this.$route.params.id }`)
      this.projectInfo = response
      console.log('teste: ', this.projectInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.container-app {
  .templates-container {
    display: grid;
    grid-template-columns: 30%  70%;
    gap:16px;

    width: 100%;
    min-height: 100vh;
    margin-right: 2%;
    margin-top: 2%;

    .left {
      align-self: stretch;
    }
  }
}
</style>
