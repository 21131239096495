<template>
  <div>
    <template v-if="educationLevel === 'grade'">
      <FormProjectChildishAdvisors
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle'">
      <FormSelectionAboutAdvisors
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_1'">
      <FormSelectionAboutAdvisors
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_2'">
      <FormSelectionAboutAdvisors
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_3'">
      <FormSelectionAboutAdvisors
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'middle_cat_eja'">
      <FormSelectionAboutAdvisors
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
    <template v-if="educationLevel === 'high'">
      <FormProjectHighAdvisors
        :allDataForm="allDataForm"
        :mostratec_type="mostratec_type"
        @changeElement="changeElementWrapper"
      />
    </template>
  </div>
</template>
<script>
import {
  FormProjectChildishAdvisors,
  FormSelectionAboutAdvisors,
  FormProjectHighAdvisors
} from '..'

export default {
  name: 'WrapperFormProjectAboutAdvisors',
  data() {
    return {}
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String],
    educationLevel: String
  },
  components: {
    FormProjectChildishAdvisors,
    FormSelectionAboutAdvisors,
    FormProjectHighAdvisors
  },
  methods: {
    changeElementWrapper(data) {
      this.$emit('changeElement', data)
    }
  },
  allDataForm() {
    if (this.allDataForm.FormProjectChildishAdvisors) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectChildishAdvisors
      }
    }
    if (this.allDataForm.FormSelectionAboutAdvisors) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormSelectionAboutAdvisors
      }
    }
    if (this.allDataForm.FormProjectHighAdvisors) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectHighAdvisors
      }
    }
  }
}
</script>
