<template>
  <aside class="wrapperInvolveds">
    <div class="wrapper__analisis-info">
      <div class="payment_box">
        <div>
          <h3 class="payment_title">
            {{$t('projectsPayment')}}
           </h3>
        </div>
      </div>
      <FormPaymentEdit :projectInfo="projectInfo"/>
    </div>

   <!--  <modal-default
      title='editSituation'
      @closeModal="toggleModal"
      :modal="modal"
      :left="true"
    >
      <div class="modal_body">
        <div class="status_styles">
          <status-with-tooltip
            statusText="waitingPayment"
            theme="purple_waiting"
            tooltipText="waitingPaymentDescription"
          />
        </div>
        <div>
          <input-select
            id="select"
            :label="$t('newStatus')"
            :items="selectOptions"
            v-model="statusOption"
            :placeholder="$t('selectStatus')"
          />
        </div>
        <div v-if="statusOption === 'inscricao-cancelada'" class="mt-4">
          <input-text-area
            label="JustificationLabel"
            id="cancel"
            :allText="rejectReason"
            @input="rejectReason=$event"
            placeholder="JustificationCancel"
            :textMax="2000"
          />
        </div>
        <div v-if="statusOption === 'inscricao-efetivada'" class="mt-4">
          <radio-group
            label="Projeto isento?"
            :options="radioOptions"
            v-model="radioVModel"
            @change="resetErrors"
          />
        </div>
        <div class="footer">
          <button-default :onClick="() => save()">
            {{$t('save')}}
          </button-default>
        </div>
      </div>
      <feedback-warning
        v-if="errors.length"
        :text="textWarning"
        :onClick="resetErrors"
        color="#ff9800"
      />
    </modal-default> -->
  </aside>
</template>

<script>
import FormPaymentEdit from '../organisms/Forms/FormPaymentEdit'

export default {
  name: 'ProjectPayment',
  props: {
    projectInfo: {
      type: Object,
      required: true
    }
  },
  components: {
    FormPaymentEdit
  },
  data() {
    return {
      infos: [],
      quantityParticipants: '',
      rejectReason: '',
      role: '',
      taxPrice: '',
      totalPrice: '',
      statusOption: '',
      exemptProject: '',
      status: {},
      modal: false,
      radioVModel: null,
      textWarning: '',
      errors: [],
      radioOptions: [
        { value: false, label: 'Não' },
        { value: true, label: 'Sim' }
      ],
      selectOptions: [
        { text: 'Inscrição efetivada', value: 'inscricao-efetivada' },
        { text: 'Inscrição cancelada', value: 'inscricao-cancelada' }
      ],
      textColor: {
        'aguardando-pagamento': 'blue',
        'inscricao-efetivada': 'green',
        'inscricao-cancelada': 'red'
      }
    }
  },
  async mounted() {
    if (Object.keys(this.projectInfo).length) {
      this.loadInfos()
    }
    this.role = localStorage.getItem('role')
  },
  watch: {
    slug() {
      this.changeStyle()
    },
    projectInfo() {
      this.loadInfos()
    }
  },
  methods: {
    async loadInfos() {
      const {
        infos,
        quantityParticipants,
        totalPrice,
        taxPrice,
        rejectReason,
        exemptProject
      } = await this.getInfos()
      this.status = await this.getStatus()
      this.infos = infos
      this.quantityParticipants = quantityParticipants
      this.totalPrice = totalPrice
      this.statusOption = this.projectInfo.internal_status.slug
      this.rejectReason = rejectReason
      this.taxPrice = taxPrice
      this.exemptProject = exemptProject
    },
    async getStatus() {
      const { data: { data } } = await this.$http.get('/status/project?statusType=projeto')
      return data
    },
    async getInfos() {
      const { data } = await this.$http.get(`/project-payment/${ this.projectInfo.id }`)
      return {
        infos: [
          { text: 'Quantidade de estudantes: ', value: data.quantity_students },
          { text: 'Possui orientador(a): ', value: data.has_advisor ? 'Sim' : 'Não' },
          { text: 'Possiu coorientador(a): ', value: data.has_coadvisor ? 'Sim' : 'Não' },
          { text: 'Projeto isento? ', value: data.exempt_project ? 'Sim' : 'Não' }
        ],
        quantityParticipants: data.quantity_members,
        totalPrice: data.totalFee,
        taxPrice: data.fee_per_participant,
        rejectReason: data.cancel_justification,
        exemptProject: data.exempt_project
      }
    },
    toggleModal() {
      this.modal = !this.modal
    },
    resetErrors() {
      if (this.errors.length) this.errors = []
    },
    slugToID(slug) {
      const status = this.status.find((item) => item.slug === slug)
      return status?.id || null
    },
    async save() {
      if (this.statusOption === 'aguardando-pagamento') {
        this.errors.push('status')
        this.textWarning = this.$t('selectStateBeforeSave')
        return false
      }
      if (this.statusOption === 'inscricao-cancelada' && !this.rejectReason) {
        this.errors.push('justificativa')
        this.textWarning = this.$t('allFieldsAreObligatory')
        return false
      }
      if (this.statusOption === 'inscricao-efetivada' && this.radioVModel === null) {
        this.errors.push('isenção')
        this.textWarning = this.$t('allFieldsAreObligatory')
        return false
      }

      const statusUpdate = await this.slugToID(this.statusOption)
      this.$emit('statusChange', {
        internal_status: statusUpdate,
        external_status: statusUpdate
      })
      await this.$http.patch(
        `/project-payment/${ this.$route.params.id }?isExempt=${ this.radioVModel }`,
        {
          cancelJustification: this.rejectReason
        }
      )
      this.sendEmail()
      return true
    },
    async sendEmail() {
      try {
        const { data: response } = await this.$http.post('/project/send-answer',
          {
            fairProjects: [this.projectInfo.fair_id] || [],
            processSelectionProjects: [this.$route.params.id]
          })
        if (response.status === 200) this.toggleModal()
      } catch (error) {
        console.log('erro: ', error)
        this.textWarning = 'failedToSendEmail'
        this.errors.push('emails')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper__analisis-info{
  background: #fdfdfd;
  border-radius: 10px;
  font-family: Mulish;
  .disabled {
    filter: grayscale(100%) saturate(0%) opacity(70%);
    cursor: alias;
    color: #686868;
  }
  .payment_box {
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    padding-bottom: 10px;
    border-radius: 10px 10px 0px 0px;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 70px;
    .payment_title{
      margin-left: 20px;
      margin-top: 12px;
      align-self: center;
      color: $primary;
    }
    .info__text {
      margin-left: 20px;
      font-size: 16px;
      color: $primary;
    }
  }
  .box {
    margin-top: 30px;
    margin-left: 40px;
    margin-right: 40px;
    align-self: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .footer {
    margin-top: 30px;
    display: flex;
    width: 96.25%;
    justify-content: flex-end;
  }
  .warning {
    color: #ff9800;
    border-bottom-color: $attention;
  }
}
.modal_body{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .status_styles{
    margin-bottom: 20px;
  }
}
</style>
