<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('aboutAdvisors')}}</p>
        <p
          class="form__text"
        >{{$t('informAboutTheAdvisors')}}</p>
        <text-warning :text="$t('considerAllFieldObligatory')"/>
      </div>
      <main class="form__main mt-4">
        <div class="form__item mb-5" v-for="(info, index) in form.infos" :key="index">
          <input-checkbox-title
            :class="[{warning: checkErrorIfContains('active' + index)}, {disable: !form.infos[index].active && index > 0}, 'mb-7']"
            :label="index ? $t('coAdvisor') : $t('advisor')"
            v-model="form.infos[index].active"
            id="advisor"
            @change="resetErrors"
          />

          <div v-if="form.infos[index].active">
            <input-default
              label=fullName
              :class="[{warning: checkErrorIfContains('full_name' + index)}, 'input__space']"
              placeholder=fullNamePlaceholder
              type="text"
              v-model="form.infos[index].full_name"
              @input="resetErrors"
            />

            <input-masked
              v-if="!form.infos[index].country || form.infos[index].country == 'Brazil'"
              :class="[{warning: checkErrorIfContains('cpf' + index)}, 'input__space']"
              label=cpf
              id="codigo"
              placeholder="xxx.xxx.xxx-xx"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
              :mask="['###.###.###-##']"
            />

            <input-default
              v-else
              :class="[{warning: checkErrorIfContains('cpf' + index)}, 'input__space']"
              label="Identification number"
              id="codigo"
              placeholder="Insert your national identification number"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{warning: checkErrorIfContains('sex' + index)}, 'input__space']"
              label=sex
              :options="sexOptions"
              v-model="form.infos[index].sex"
              @change="resetErrors"
            />

            <input-default
              :class="[{warning: checkErrorIfContains('phone' + index)}, 'input__space']"
              label=enterInstitutionSchoolPhone
              id="phone"
              placeholder="(00) 00000-0000"
              type="text"
              max="20"
              v-model="form.infos[index].phone"
              @input="resetErrors"
            />

            <input-default
              :class="[{warning: checkErrorIfContains('email' + index)}, 'input__space']"
              label=email
              id="email"
              placeholder=enterTheEmail
              type="text"
              v-model="form.infos[index].email"
              @input="resetErrors"
            />

            <div class="separator">
              <h3>{{$t('address')}}</h3>
            </div>

            <input-select-location
              :class="[{warning: checkErrorIfContains('country' + index)}, 'input__space']"
              id="country"
              :items="countries"
              v-model="form.infos[index].country"
              label=country
              @input="resetErrors"
            />

            <input-masked
              v-if="form.infos[index].country == 'Brazil'"
              :class="[{warning: checkErrorIfContains('zipcode' + index)}, 'input__space']"
              required
              label="CEP"
              id="zipcode"
              :placeholder="$t('inputCepPlaceholder')"
              type="text"
              v-model="form.infos[index].zipcode"
              @input="resetErrors"
              :mask="['#####-###']"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{warning: checkErrorIfContains('state' + index)}, 'input__space']"
              label=state
              id="state"
              type="text"
              v-model="form.infos[index].state"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{warning: checkErrorIfContains('city' + index)}, 'input__space']"
              label=city
              id="city"
              type="text"
              v-model="form.infos[index].city"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{warning: checkErrorIfContains('street' + index)}, 'input__space']"
              label=address
              id="street"
              placeholder=addressExample
              type="text"
              max="200"
              v-model="form.infos[index].street"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{warning: checkErrorIfContains('house_number' + index)}, 'input__space']"
              label="number"
              id="house_number"
              placeholder=numberPlaceholder
              type="text"
              max="15"
              v-model="form.infos[index].house_number"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country == 'Brazil' && form.infos[index].state"
              :class="[{warning: checkErrorIfContains('complement' + index)}, 'input__space']"
              label= complementOptional
              id="complement"
              placeholder=complementPlaceholder
              type="text"
              max="150"
              v-model="form.infos[index].complement"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{warning: checkErrorIfContains('state' + index)}, 'input__space']"
              label="State/Province"
              id="state"
              placeholder="Input your state/province"
              type="text"
              max="200"
              v-model="form.infos[index].state"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country && form.infos[index].country != 'Brazil'"
              :class="[{warning: checkErrorIfContains('city' + index)}, 'input__space']"
              label="City"
              id="city"
              placeholder="Input your city"
              type="text"
              max="200"
              v-model="form.infos[index].city"
              @input="resetErrors"
            />

            <div class="separator">
              <h3>{{$t('registrationData')}}</h3>
            </div>

            <input-default
              label=graduation
              :class="[{warning: checkErrorIfContains('formation' + index)}, 'input__space']"
              placeholder=enterTheGraduation
              type="text"
              v-model="form.infos[index].formation"
              @input="resetErrors"
            />

            <input-default
              label=actingDiscipline
              :class="[{warning: checkErrorIfContains('position' + index)}, 'input__space']"
              placeholder=enterTheActingDiscipline
              type="text"
              :max=255
              v-model="form.infos[index].position"
              @input="resetErrors"
            />

            <input-select
              :class="[{warning: checkErrorIfContains('english_level' + index)}, 'input__space']"
              id="english_level"
              :items="englishOptions"
              v-model="form.infos[index].english_level"
              label=levelOfKnowledgeInEnglish
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{warning: checkErrorIfContains('scholarship_holder' + index)}, 'mb-2']"
              label=doYouHaveScholarship
              :options="generalOptions"
              v-model="form.infos[index].scholarship_holder"
              @change="resetErrors"
            />

            <RadioGroup
              v-if="form.infos[index].scholarship_holder == 'yes'"
              :class="[{warning: checkErrorIfContains('which_program' + index)}, 'mb-1']"
              label=whichProgram
              :options="programOptions"
              v-model="form.infos[index].which_program"
              @change="resetErrors"
            />

            <input-default
              v-if="form.infos[index].which_program == 'other'"
              :class="[{warning: checkErrorIfContains('other_program' + index)}, 'input__space mt-0']"
              id="city"
              placeholder=enterTheProgramName
              type="text"
              v-model="form.infos[index].other_program"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[{warning: checkErrorIfContains('participated_mostratec' + index)}, 'mb-1']"
              label=haveYouParticipatedAnyOtherMostratec
              :options="generalOptions"
              v-model="form.infos[index].participated_mostratec"
              @change="resetErrors"
            />

            <div class="levels">
              <p :class="[{warning: checkErrorIfContains('deficiency' + index)}, 'title']">{{$t('doYouHaveAnyKindOfDisability')}}</p>
              <label>
                <input
                  @change="resetErrors"
                  value="nao"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="fisica"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                />{{$t('fis')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="intelectual"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                />{{$t('intellectual')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="auditiva"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                />{{$t('auditory')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="visual"
                  v-model="form.infos[index].deficiency"
                  type="checkbox"
                />{{$t('visual')}}
              </label>
            </div>

            <div class="levels">
              <p
                :class="[{warning: checkErrorIfContains('special_service' + index)}, 'title mt-3']"
              >{{$t('willYouNeedAnySpecializedAssistance')}}</p>
              <label>
                <input
                  @change="resetErrors"
                  value="nao"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="acessibilidade"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                />{{$t('architecturalAccessibility')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="libras"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                />{{$t('libraInterpreter')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="ledor"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                >{{$t('reader')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="outro"
                  v-model="form.infos[index].special_service"
                  type="checkbox"
                />{{$t('othersMale')}}
              </label>
              <input-default
                v-if="form.infos[index].special_service.includes('outro')"
                :class="[{warning: checkErrorIfContains('otherSpecialService' + index)}, 'mt-0']"
                id="otherSpecialService"
                placeholder="Insira qual outro tipo de atendimento você precisa"
                type="text"
                v-model="form.infos[index].otherSpecialService"
                @input="resetErrors"
              />
            </div>

            <input-select
              :class="[{warning: checkErrorIfContains('t_shirt' + index)}, 'input__space']"
              id="t_shirt"
              :items="t_shirtOptions"
              v-model="form.infos[index].t_shirt"
              label=typeOfShirt
              @input="resetErrors"
            />

            <input-select
              :class="[{warning: checkErrorIfContains('t_shirt_size' + index)}, 'input__space']"
              id="english_level"
              :items="t_shirt_sizeOptions"
              v-model="form.infos[index].t_shirt_size"
              label=whatSizeShirt
              @input="resetErrors"
            />
          </div>
        </div>
      </main>

      <div class="form__footer">
        <button-default :onClick="back" class="button white">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndContinue')}}</button-default>
      </div>

      <modal-selection-process
        title=disableCoadvisor
        :onClick="() => closeModalAndYes()"
        @closeModal="() => closeModal()"
        :modal="coadvisorModal"
      >
        <div>
          <p class="form__textModal">{{$t('disableCoadvisorModalP1')}}
            <strong>{{$t('whitoutCoadvisor')}}</strong>{{$t('disableCoadvisorModalP2')}}
            <strong>checkbox.</strong>
          </p>
        </div>
      </modal-selection-process>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  RadioGroup,
  InputCheckboxTitle,
  InputMasked,
  InputSelectLocation,
  InputSelect
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'
import { ModalSelectionProcess } from '@/components/organisms/Modals'

export default {
  name: 'FormSelectionAboutAdvisors',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    RadioGroup,
    InputCheckboxTitle,
    InputMasked,
    InputSelectLocation,
    InputSelect,
    ModalSelectionProcess
  },
  data() {
    return {
      coadvisorModal: false,
      form: {
        infos: [
          {
            active: false,
            type: 'orientador',
            full_name: '',
            cpf: '',
            sex: '',
            phone: '',
            email: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            street: '',
            house_number: '',
            complement: '',
            formation: '',
            position: '',
            english_level: '',
            scholarship_holder: '',
            which_program: '',
            other_program: '',
            participated_mostratec: '',
            deficiency: [],
            special_service: [],
            otherSpecialService: '',
            t_shirt: '',
            t_shirt_size: ''
          },
          {
            active: false,
            type: 'coorientador',
            full_name: '',
            cpf: '',
            sex: '',
            phone: '',
            email: '',
            country: '',
            state: '',
            city: '',
            zipcode: '',
            street: '',
            house_number: '',
            complement: '',
            formation: '',
            position: '',
            english_level: '',
            scholarship_holder: '',
            which_program: '',
            other_program: '',
            participated_mostratec: '',
            deficiency: [],
            special_service: [],
            t_shirt: '',
            t_shirt_size: ''
          }
        ]
      },
      quantityOptions: [
        { label: this.$t('individual'), value: 1 },
        { label: this.$t('pair'), value: 2 },
        { label: this.$t('trio'), value: 3 }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: 'yes' },
        { label: this.$t('no'), value: 'no' }
      ],
      sexOptions: [
        { label: this.$t('maleSingle'), value: 'Male' },
        { label: this.$t('femaleSingle'), value: 'Female' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: this.$t('advanced') },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      t_shirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' },
        { text: this.$t('shirt'), value: 'camiseta' },
        { text: this.$t('poloShirt'), value: 'polo' }
      ],
      t_shirt_sizeOptions: [
        { text: 'P', value: 'p' },
        { text: 'M', value: 'm' },
        { text: 'G', value: 'g' },
        { text: 'GG', value: 'gg' }
      ],
      countries,
      states,
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory')
    }
  },
  created() {
    const formData = this.$store.getters.projectSelection
    if (formData.advisors) {
      this.form.infos = formData.advisors
    }
  },
  computed: {
    ...mapGetters({
      projectSelection: 'projectSelection'
    }),
    zipcodeAdvisor() {
      return this.form.infos[0].zipcode
    },
    zipcodeCoAdvisor() {
      return this.form.infos[1].zipcode
    },
    coadvisorOption() {
      return this.form.infos[1].active
    }
  },
  watch: {
    zipcodeAdvisor() {
      this.getAddressInfo(0)
    },
    zipcodeCoAdvisor() {
      this.getAddressInfo(1)
    },
    coadvisorOption() {
      if (!this.form.infos[1].active) {
        this.coadvisorModal = true
      }
    }
  },
  methods: {
    checkForm() {
      this.resetErrors()
      const emails = []
      if (!this.form.infos[0].active) {
        this.textWarning = 'É obrigatório informar um orientador.'
        this.errors.push('active0')
        return false
      }
      this.form.infos.map((item, index) => {
        if (index == 0 || item.active) {
          for (const key in item) {
            if (typeof item[key] === 'boolean') {
              continue
            }
            if ((item[key].length === 0 || !item[key]) && key !== 'complement') {
              this.errors.push(`${ key }${ index }`)
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
            }
          }
          if (item.country != 'Brazil') {
            const indexZipcode = this.errors.indexOf(`zipcode${ index }`)
            if (indexZipcode > -1) {}
            this.errors.splice(indexZipcode, 1)
            const indexAddress = this.errors.indexOf(`street${ index }`)
            if (indexAddress > -1) {
              this.errors.splice(indexAddress, 1)
            }
            const indexNumber = this.errors.indexOf(`house_number${ index }`)
            if (indexNumber > -1) {
              this.errors.splice(indexNumber, 1)
            }
            const indexComplement = this.errors.indexOf(`complement${ index }`)
            if (indexComplement > -1) {
              this.errors.splice(indexComplement, 1)
            }
          }
          if (item.scholarship_holder != 'yes') {
            const indexWhicProgram = this.errors.indexOf(`which_program${ index }`)
            if (indexWhicProgram > -1) {
              this.errors.splice(indexWhicProgram, 1)
            }
          }
          if (item.which_program != 'other') {
            const indexProgram = this.errors.indexOf(`other_program${ index }`)
            if (indexProgram > -1) {
              this.errors.splice(indexProgram, 1)
            }
          }
          if (item.deficiency.includes('nao')) {
            const indexSpecial = this.errors.indexOf(`special_service${ index }`)
            if (indexSpecial > -1) {
              this.errors.splice(indexSpecial, 1)
            }
          }
          if (!item.special_service.includes('outro')) {
            const indexOther = this.errors.indexOf(`otherSpecialService${ index }`)
            if (indexOther > -1) {
              this.errors.splice(indexOther, 1)
            }
          }
        }
      })
      if (!this.errors.length) {
        this.form.infos.forEach((value, index) => {
          if (!this.validateEmail(value.email)) {
            this.errors.push(`email${ index }`)
            this.textWarning = 'E-mail inválido!'
          }
          emails.push(value.email)
        })

        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'E-mails inseridos devem ser distintos!'
          return false
        }
        this.resetErrors()
        this.saveForm()
      }
    },
    saveForm() {
      try {
        const formData = this.$store.getters.projectSelection
        const involveds = this.form.infos
        const newData = {
          ...formData,
          advisors: involveds
        }
        this.$store.commit('PROJECT_SELECTION_REQUEST', newData)
        window.localStorage.setItem(
          'projectSelectionData',
          JSON.stringify(newData)
        )
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
        console.log('error: ', error)
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmail(mail) {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    },
    getAddressInfo(index) {
      const cep = String(this.form.infos[index].zipcode).replace(/\D/g, '')
      if (cep.length == 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              if (this.allDataForm.FormSelectionAboutAdvisors) return
              this.form.infos[index].state = ' '
              this.errors.push('zipcode')
              this.textWarning = 'Informe um CEP válido'
            } else {
              this.form.infos[index].state = data.uf
              this.form.infos[index].city = data.localidade
              this.form.infos[index].street = data.logradouro
              this.resetErrors()
              this.textWarning = this.$t('warningAllFieldsAreObligatory')
            }
          })
          .catch((err) => {
            if (this.allDataForm.FormSelectionAboutAdvisors) {
              const projectAdvisor = this.allDataForm.FormSelectionAboutAdvisors.form.infos[index]

              this.form.infos[index].state = projectAdvisor.state
              this.form.infos[index].city = projectAdvisor.city
              this.form.infos[index].district = projectAdvisor.district
              this.form.infos[index].street = projectAdvisor.street
            }

            this.errors.push('zipcode')
            this.textWarning = 'Informe um CEP válido'
          })
      }
    },
    closeModal() {
      this.coadvisorModal = false
      this.form.infos[1].active = true
    },
    closeModalAndYes() {
      this.coadvisorModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    text-align: left;
    max-width: 600px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__main {
    .form__item {
      .input__space {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .separator {
        border-bottom: 1px solid $primary;
        padding-bottom: 15px;
        margin-top: 30px;

        color: $primaryDark;
        font-family: Mulish;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
      }

      &--info {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        color: $primary;
        max-width: 520px;
      }

      .levels {
        display: flex;
        flex-direction: column;

        .title {
          color: #303030;
          display: flex;
          flex-direction: column;
          font-size: 16px !important;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
        }

        label {
          display: flex;
          color: $almostBlack;
          font-size: 16px;
          margin-bottom: 5px;

          input {
            zoom: 1.5;
            margin-right: 5px;
          }
        }

        .warning {
          color: #ff9800;
        }
      }
    }
  }

  &__textModal {
    color: #18516f;
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    margin-top: 20px;
    max-width: 425px;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
