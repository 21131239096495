<template>
  <label class="label" :for="id">
    {{ $t(label) }}
    <input class="input"
      :class="{ 'is-title': true }"
      :style="{ maxWidth: `${width}px!important` }"
      :id="id"
      :type="type"
      :placeholder="$t(placeholder)"
      :value="value"
      v-on:input="$emit('input', $event.target.value)"
      :maxlength="max || false"
      @keyup.enter="checkOnEnter"
      @blur="$emit('blur')"
      :disabled="disabled"
      :min="0"
    />
  </label>
</template>

<script>

export default {
  name: 'InputDefault',
  props: {
    label: String,
    type: String,
    id: String,
    placeholder: String,
    value: String | Number,
    max: Number | String,
    onEnter: Function,
    disabled: Boolean,
    width: Number
  },
  watch: {
    date() {
      this.$emit('dateChange', this.value)
    }
  },
  methods: {
    checkOnEnter() {
      return this.onEnter ? this.onEnter() : null
    }
  }
}
</script>

<style lang="scss" scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.label {
  color: $almostBlack;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.warning {
  color: $attention;

  .input {
    border-color: $attention;
  }
}

.input {
  background: $white;
  border: 1px solid #CDCBCB;
  border-radius: 10px;
  color: $almostBlack;
  font-weight: 500;
  height: 35px;
  margin-top: 5px;
  max-width: 570px;
  padding: 8px 15px;
  outline: none;

  &::placeholder {
    color: #AFAEAE;
    font-size: 14px;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    box-shadow: 0 0 0 30px white inset;
  }
}

.input_large input {
  max-width: 700px;
}
</style>
