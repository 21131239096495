import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '../router'

Vue.use(AclInstaller)

export default new AclCreate({
  initial: 'public',
  notfound: {
    path: '/access-denied',
    forwardQueryParams: true
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    isPublic: new AclRule('notLogged')
      .or('admin')
      .or('secretaria')
      .or('crc')
      .or('restaurante')
      .or('credenciamento')
      .or('apm_credenciamento')
      .or('feira_afiliada')
      .or('aluno_feira_afiliada')
      .or('aluno_processo_selecao')
      .generate(),
    isAdmin: new AclRule('admin').generate(),
    isSecretary: new AclRule('secretaria').generate(),
    isCrc: new AclRule('crc').generate(),
    isRestaurant: new AclRule('restaurante').generate(),
    isAccreditation: new AclRule('credenciamento').or('apm_credenciamento').or('secretaria').or('admin').generate(),  //.or('secretaria').generate().or('admin').generate(),
    isAdminOrSecretary: new AclRule('secretaria').or('admin').generate(),
    isAdminOrSecretaryOrCrc: new AclRule('secretaria').or('admin').or('crc').generate(),
    isAffiliatedFair: new AclRule('feira_afiliada').generate(),
    isAffiliatedFairStudent: new AclRule('aluno_feira_afiliada').generate(),
    isProcessSelectionStudent: new AclRule('aluno_processo_selecao').generate(),
    isProcessSelectionStudentAndAffiliatedFairStudent:
      new AclRule('aluno_feira_afiliada').or('aluno_processo_selecao').generate()
  },
  middleware: async (acl) => {
    const roleUser = await window.localStorage.getItem('role')
    if (roleUser) {
      acl.change(roleUser)
      //console.log('acl.change(roleUser) ',roleUser)
    } else {
      acl.change('notLogged')
    }
  }
})
