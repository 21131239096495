<template>
  <aside class="wrapperStudents">
    <div class="revision_box"><h3 class="revision_title">{{$t('students')}}</h3></div>

    <div class="info">
      <div>
        <p class="firstQuestion">{{$t('studentsQuantityByProject')}}</p>
        <span class="ml-12 mb-12">{{form.quantityStudents[form.infos.length - 1]}}</span>
      </div>
      <div>
        <p class="secondQuestion">O(s) estudante(s) do projeto pode(m) participar de uma apresentação ao vivo??</p>
        <span>{{form.livePresentation ? 'Sim' : 'Não'}}</span>
      </div>
    </div>

    <ProjectDataStudents
      :projectInfo="projectInfo"
    />
  </aside>
</template>

<script>
import {
  InputCheckboxAccordion
} from '@/components/atoms'

import ProjectDataStudents from '@/components/templates/ProjectDataStudents'

import {
  tShirtSizes,
  tShirtTypes,
  educationalLevels,
  englishLevels,
  scholarshipHolderPrograms,
  scienceFairs
} from '@/utils/dictionaries'

export default {
  name: 'ProjectsStudents',
  components: {
    InputCheckboxAccordion,
    ProjectDataStudents
  },
  data() {
    return {
      form: {
        quantityStudents: ['Solo', 'Dupla', 'Trio', 'Quarteto'],
        livePresentation: null,
        infos: []
      },
      tShirtSizes,
      tShirtTypes,
      educationalLevels,
      englishLevels,
      scholarshipHolderPrograms,
      scienceFairs
    }
  },
  props: {
    projectInfo: {
      type: Object,
      reequired: true
    }
  },
  mounted() {
    if (Object.keys(this.projectInfo).length) this.getInfos()
  },
  methods: {
    getInfos() {
      this.form.project_category = this.projectInfo.project_category
      this.form.livePresentation = this.projectInfo.live_presentation ? 'Sim' : 'Não'
      const studentMain = this.projectInfo.students.find((student) => student.main)
      this.form.infos.push(studentMain)
      const studentsAux = this.projectInfo.students.sort((a, b) => {
        if (a.id > b.id) return 1
        if (a.id < b.id) return -1
        return 0
      })
      studentsAux.forEach((student) => {
        if (!student.is_continuity && !student.main) this.form.infos.push(student)
      })
    },
    formatDate(birth_date) {
      const myDate = new Date(birth_date)
      return `${ myDate.getDate() }/${ myDate.getMonth() + 1 }/${ myDate.getFullYear() }`
    },
    coalesceInformation(info) {
      if (!info && info !== 0) return 'Não informado'
      return info
    },
    deficiencyListPicker(deficiency) {
      const list = deficiency?.deficiency || deficiency?.deficiencies
      return this.defOrServiceFormatted(list)
    },
    serviceListPicker(service) {
      const list = service?.special_service || service?.special_services
      return this.defOrServiceFormatted(list)
    },
    defOrServiceFormatted(defOrService) {
      if (defOrService?.includes('nao') || !defOrService?.length) return 'Não'
      const formattedDefOrService = []
      defOrService.forEach((item) => {
        formattedDefOrService.push(item.charAt(0).toUpperCase() + item.slice(1))
      })
      return formattedDefOrService.join(', ')
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapperStudents {
  height: 100%;
  border-radius: 0px 10px 10px 0px;
  background-color: #FDFDFD;
  font-family: Mulish, sans-serif;
  .revision_box{
    position: absolute;
    width: 68%;
    height: 57px;
    background: #FFFFFF;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    color: #0E5671;
    padding-bottom: 10px;
    border-radius: 10px 10px 0px 0px;
    .revision_title{
      position: absolute;
      left: 22px;
      top: 34%;
      font-family: Mulish;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
    }
  }
  .info{
    margin-top: 80px;
    display: flex;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    .firstQuestion{
      margin-left: 48.5px;
      margin-right: 60px;
      align-items: center;
      color: #303030;
    }
    .secondQuestion{
      align-items: center;
      color: #303030;
    }
    span{
      color: #686868;
    }
  }
  .main_align{
    .accordion_size{
      width: 93%;
      margin-left: 48px;
      padding-left: 24px;
    }
    .content{
      border-top: 1px solid #CDCBCB;
      margin-top: -33px;
      width: 93%;
      margin-left: 48px;
      height: fit-content;
      padding-top: 13px;
      justify-content: space-between;
      display: block;
      padding-left: 12px;
      padding-bottom: 20px;
      background: #FDFDFD;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
      .mainStudent_description{
        color: $primary;
        margin-left: 5px;
        margin-bottom: 15px;
      }
      .content_title{
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        color: $primary;
        margin-left: 35px;
      }
      .contact_info, .address_info, .inscription_info {
        display: flex;
        .data{
          display: flex;
          &__row {
            width: 355px;
            margin-bottom: 35px;
            font-size: 16px;
            color: #686868;
            h4 {
              font-weight: 600;
              color: $almostBlack;
              margin-bottom: 10px;
            }
          }
          &__left{
            width: 45%;
            margin-left: 35px;
            float: left;
          }
          &__right{
            margin-left: 35px;
            float: right;
          }
        }
      }
    }
  }
  .separator {
    background-color: $primary;
    height: 0.5px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 98%;
  }
}
</style>
