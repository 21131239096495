<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__header">
        <span class="form__title">{{$t('currentFair')}}</span>
      </div>
      <div class="form__info">
        <text-warning :text="`considerAllFieldObligatory`" />
      </div>
      <input-default
        :class="[{warning: checkErrorIfContains('fairName')}, 'mb-7']"
        label= fairName
        id="fairName"
        placeholder= inputFairName
        type="text"
        max=100
        v-model="form.fairName"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('site')}, 'mb-7']"
        label= site
        id="site"
        placeholder= sitePlaceholder
        type="text"
        max=100
        v-model="form.site"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('coordinatorName')}, 'mb-7']"
        label= fairCoordenatorName
        id="coordinatorName"
        placeholder= fairCoordenatorNamePlaceholder
        type="text"
        max=100
        v-model="form.coordinatorName"
        @input="resetErrors"
      />
      <input-select
        :class="[{warning: checkErrorIfContains('fairType')}, 'mb-7']"
        id="fairType"
        :items="items"
        v-model="form.fairType"
        label= fairType
        @input="resetErrors"
      />
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('next')}}</button-default>
      </div>
    </form>
    <feedback-warning v-if="errors.length" :text="textWarning" :onClick="resetErrors" color="#ff9800" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputDefault, ButtonDefault, FeedbackWarning, TextWarning, InputSelect
} from '@/components/atoms'

export default {
  name: 'CurrentFair',
  components: {
    InputDefault, ButtonDefault, FeedbackWarning, TextWarning, InputSelect
  },
  data() {
    return {
      form: {
        fairName: '',
        site: '',
        coordinatorName: '',
        fairType: ''
      },
      errors: [],
      textWarning: 'warningAllFieldsAreObligatory',
      select: {},
      items: [
        { text: this.$t('schoolFairType'), value: 'escolar' },
        { text: this.$t('multiSchoolFairType'), value: 'multiescolar' },
        { text: this.$t('townFairType'), value: 'municipal' },
        { text: this.$t('townFairType2'), value: 'outras redes' },
        { text: this.$t('regionalFairType'), value: 'regional' },
        { text: this.$t('stateFairType'), value: 'estadual' },
        { text: this.$t('nationalFairType'), value: 'nacional' },
        { text: this.$t('internationalFairType'), value: 'internacional' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      fairAffiliation: 'fairAffiliation'
    })
  },
  created() {
    if (this.fairAffiliation && this.fairAffiliation.fair_name) {
      this.form.fairName = this.fairAffiliation.fair_name
      this.form.site = this.fairAffiliation.website
      this.form.coordinatorName = this.fairAffiliation.fairs_coordinator_name
      this.form.fairType = this.fairAffiliation.fair_type
    }
  },
  methods: {
    ...mapActions({
      affiliationRequest: 'affiliationRequest'
    }),
    checkForm() {
      this.resetErrors()
      for (const key in this.form) {
        if (!this.form[key]) {
          this.errors.push(key)
        }
      }
      if (!this.errors.length) {
        this.form.site = this.checkOrAddDotAndHttps(this.form.site)
        this.saveForm()
      }
    },
    saveForm() {
      try {
        let screen
        const formData = this.$store.getters.fairAffiliation
        const { currentScreen } = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
        if (currentScreen < 4) {
          screen = 4
        } else {
          screen = currentScreen
        }
        const newData = {
          ...formData,
          fair_name: this.form.fairName,
          website: this.form.site,
          fairs_coordinator_name: this.form.coordinatorName,
          fair_type: this.form.fairType,
          currentScreen: screen
        }
        this.$store.commit('AFILLIATION_REQUEST', newData)
        window.localStorage.setItem('fairAffiliatonData', JSON.stringify(newData))
        this.$emit('changeElement', 'up')
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'checkTheData'
      }
    },
    back() {
      this.$emit('changeElement', 'down')
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateUrl(url) {
      if (!/^(ftp|http|https):\/\/[^ "]+$/.test(url)) {
        this.errors.push('site')
        return false
      }
      return true
    },
    checkOrAddDotAndHttps(url) {
      let output = ''
      const split_url = url.split('.')
      if (split_url.length < 2) {
        output = `${ url }.com`
      } else {
        output = url
      }

      const split2 = output.split('://')
      if (split2[0] === 'http' || split2[0] === 'https' || !output || output.length === 0) {
        output = output
      } else {
        output = `https://${ output }`
      }
      return output
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 40px;
    color: $primary;

    .form__title {
      font-weight: 900;
      margin-bottom: 5px;
    }

    .form__title__complement {
      font-weight: 400;
      margin-left: 11px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 510px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
