<template>
  <div>
    <form @submit.prevent class="form">
      <div class="form__info">
        <p class="form__title">{{$t('aboutStudents')}}</p>
        <p class="form__text">
          {{$t('informTheDataOfTheProject')}}
          <strong>{{$t('numberOfStudentsInTheProject')}}</strong>
        </p>
        <text-warning :text="$t('considerAllFieldObligatory')"/>
      </div>

      <!-- RadioGroup
        :class="[{ warning: checkErrorIfContains('categoryProject') }, 'mb-2']"
        label="projectCategory"
        :options="categoryProject"
        v-model="form.categoryProject"
        @change="resetErrors"
      / -->
      <RadioGroup
        :class="[{ warning: checkErrorIfContains('quantityStudents') }, 'mb-2']"
        label=numberOfStudentsInTheProject
        :options="quantityOption"
        v-model="form.quantityStudents"
        @change="resetErrors"
      />

      <RadioGroup
        :class="[{ warning: checkErrorIfContains('livePresentation') }, 'mb-2']"
        label=ableToParticipateInALivePresentation
        :options="generalOptions"
        v-model="form.livePresentation"
        @change="resetErrors"
      />

      <main class="form__main mt-4">
        <div class="form__item mb-5" v-for="(info, index) of form.infos" :key="index">
          <input-checkbox-title
            v-if="index <= form.quantityStudents - 1"
            :class="[
              { warning: checkErrorIfContains('active' + index) },
              'mb-7',
            ]"
            :label="$t('student') + String(Number(index + 1))"
            v-model="form.infos[index].active"
            id="student2"
            @change="resetErrors"
          />

          <div v-if="form.infos[index].active">
            <p v-if="index == 0" class="form__item--info">{{$t('studentOneInfo')}}</p>
            <input-default
              label=fullNameStudent
              :class="[
                { warning: checkErrorIfContains('fullname' + index) },
                'input__space',
              ]"
              placeholder=fullNamePlaceholder
              type="text"
              max="150"
              v-model="form.infos[index].fullname"
              @input="resetErrors"
              :disabled="isMainStudent(index)"
            />

            <input-masked
              v-if="
                !form.infos[index].country ||
                form.infos[index].country == 'Brazil'
              "
              :class="[
                { warning: checkErrorIfContains('cpf' + index) },
                'input__space',
              ]"
              label=cpfStudent
              id="codigo"
              placeholder="xxx.xxx.xxx-xx"
              type="text"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
              :mask="['###.###.###-##']"
              :disabled="isMainStudent(index)"
            />

            <input-default
              v-else
              :class="[
                { warning: checkErrorIfContains('cpf' + index) },
                'input__space',
              ]"
              label="Identification number"
              id="codigo"
              placeholder="Insert your national identification number"
              type="text"
              max="30"
              v-model="form.infos[index].cpf"
              @input="resetErrors"
            />

            <RadioGroup
              :class="[
                { warning: checkErrorIfContains('gender' + index) },
                'input__space',
              ]"
              label=sex
              :options="genderOptions"
              v-model="form.infos[index].gender"
              @change="resetErrors"
            />

            <input-masked
              label=birthDate
              :class="[
                { warning: checkErrorIfContains('birth_date' + index) },
                'input__space',
              ]"
              placeholder="10/10/2000"
              type="text"
              v-model="form.infos[index].birth_date"
              @input="resetErrors"
              :mask="['##/##/####']"
            />

            <template
              v-if="
                validateIfBigger(form.infos[index].birth_date, index) ||
                form.infos[index].isBetter
              "
            >
              <input-default
                label=responsibleName
                :class="[
                  { warning: checkErrorIfContains('responsibleName' + index) },
                  'input__space',
                ]"
                placeholder=enterTheResponsibleAdultName
                type="text"
                max="150"
                v-model="form.infos[index].responsibleName"
                @input="resetErrors"
              />
              <input-default
                label=relationshipOfResponsible
                :class="[
                  {
                    warning: checkErrorIfContains(
                      'responsibleRelative' + index
                    ),
                  },
                  'input__space',
                ]"
                placeholder=enterParentRelationship
                type="text"
                max="150"
                v-model="form.infos[index].responsibleRelative"
                @input="resetErrors"
              />
            </template>
            <input-default
              :class="[
                { warning: checkErrorIfContains('phone' + index) },
                'input__space',
              ]"
              label=enterInstitutionSchoolPhone
              id="phone"
              placeholder="(00) 00000-0000"
              type="text"
              max="20"
              v-model="form.infos[index].phone"
              @input="resetErrors"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            />

            <input-default
              :class="[
                { warning: checkErrorIfContains('email' + index) },
                'input__space',
              ]"
              label=email
              id="email"
              placeholder=enterTheEmail
              type="email"
              max="150"
              v-model="form.infos[index].email"
              @input="resetErrors"
              :disabled="isMainStudent(index)"
            />

            <div class="separator">
              <h3>{{$t('address')}}</h3>
            </div>

            <input-select-location
              :class="[
                { warning: checkErrorIfContains('country' + index) },
                'input__space',
              ]"
              id="country"
              :items="countries"
              v-model="form.infos[index].country"
              label=country
              @input="resetErrors"
              :selected="form.infos[index].country"
            />

            <input-masked
              v-if="form.infos[index].country == 'Brazil'"
              :class="['input__space']"
              required
              label="CEP"
              id="zipCode"
              :placeholder="$t('inputCepPlaceholder')"
              type="text"
              v-model="form.infos[index].zipCode"
              @input="resetErrors"
              :mask="['#####-###']"
            />

            <template v-if="form.infos[index].cepValidate">
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[
                  { warning: checkErrorIfContains('state' + index) },
                  'input__space',
                ]"
                label=state
                id="state"
                type="text"
                max="100"
                v-model="form.infos[index].state"
                @input="resetErrors"
              />
              <input-default
                v-if="form.infos[index].country == 'Brazil'"
                :class="[
                  { warning: checkErrorIfContains('city' + index) },
                  'input__space',
                ]"
                label=city
                id="city"
                type="text"
                max="150"
                v-model="form.infos[index].city"
                @input="resetErrors"
              />
            </template>
            <template v-else>
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[
                  { warning: checkErrorIfContains('state' + index) },
                  'input__space',
                ]"
                id="class"
                :items="states"
                v-model="form.infos[index].state"
                label=state
                @input="resetErrors"
                :selected="form.infos[index].state"
              />
              <input-select
                v-if="form.infos[index].country == 'Brazil'"
                :class="[
                  { warning: checkErrorIfContains('city' + index) },
                  'input__space',
                ]"
                id="class"
                :items="cities"
                v-model="form.infos[index].city"
                label=city
                @input="resetErrors"
                :selected="form.infos[index].city"
              />
            </template>
            <input-default
              v-if="
                form.infos[index].country == 'Brazil' && form.infos[index].state
              "
              :class="[
                { warning: checkErrorIfContains('address' + index) },
                'input__space',
              ]"
              label=address
              id="address"
              placeholder=addressExample
              type="text"
              max="150"
              v-model="form.infos[index].address"
              @input="resetErrors"
            />

            <input-default
              v-if="form.infos[index].country === 'Brazil'"
              :class="[
                { warning: checkErrorIfContains('neighborhood' + index) },
                'input__space',
              ]"
              label=neighborhood
              id="neighborhood"
              placeholder=neighborhoodPlaceholder
              type="text"
              max="150"
              v-model="form.infos[index].neighborhood"
              @input="resetErrors"
            />

            <input-default
              v-if="
                form.infos[index].country == 'Brazil' && form.infos[index].state
              "
              :class="[
                { warning: checkErrorIfContains('number' + index) },
                'input__space',
              ]"
              label="number"
              id="number"
              placeholder=numberPlaceholder
              type="text"
              max="20"
              v-model="form.infos[index].number"
              @input="resetErrors"
            />

            <input-default
              v-if="
                form.infos[index].country == 'Brazil' && form.infos[index].state
              "
              :class="[
                { warning: checkErrorIfContains('complement' + index) },
                'input__space',
              ]"
              label=complementOptional
              id="complement"
              max="150"
              placeholder=complementPlaceholder
              type="text"
              v-model="form.infos[index].complement"
              @input="resetErrors"
            />

            <input-default
              v-if="
                form.infos[index].country &&
                form.infos[index].country != 'Brazil'
              "
              :class="[
                { warning: checkErrorIfContains('state' + index) },
                'input__space',
              ]"
              label="State/Province"
              id="state"
              placeholder=statePlaceholder
              type="text"
              max="100"
              v-model="form.infos[index].state"
              @input="resetErrors"
            />

            <input-default
              v-if="
                form.infos[index].country &&
                form.infos[index].country != 'Brazil'
              "
              :class="[
                { warning: checkErrorIfContains('city' + index) },
                'input__space',
              ]"
              label="City"
              id="city"
              placeholder="Input your city"
              type="text"
              max="150"
              v-model="form.infos[index].city"
              @input="resetErrors"
            />

            <div class="separator">
              <h3>{{$t('registrationData')}}</h3>
            </div>

            <input-select
              :class="[
                { warning: checkErrorIfContains('serie' + index) },
                'input__space',
              ]"
              id="serie"
              :items="serieOptions"
              v-model="form.infos[index].serie"
              label="class"
              @input="resetErrors"
              :selected="form.infos[index].serie"
            />

            <!-- input-select
              v-if="validIfInputLanguage(form.infos[index].country)"
              :class="[
                { warning: checkErrorIfContains('englishLevel' + index) },
                'input__space',
              ]"
              id="englishLevel"
              :items="englishOptions"
              v-model="form.infos[index].englishLevel"
              label=levelOfKnowledgeInEnglish
              @input="resetErrors"
              :selected="form.infos[index].englishLevel"
            / -->
            

            <RadioGroup
              :class="[
                { warning: checkErrorIfContains('attendOtherFair' + index) },
                'mb-1',
              ]"
              label=haveYouParticipatedAnyScienceFair
              :options="attendOptions"
              v-model="form.infos[index].attendOtherFair"
              @change="resetErrors"
            />

            <input-default
              v-if="form.infos[index].attendOtherFair == 'other'"
              :class="[
                { warning: checkErrorIfContains('otherFair' + index) },
                'input__space mt-0',
              ]"
              id="city"
              placeholder=enterWhichScienceFair
              type="text"
              max="150"
              v-model="form.infos[index].otherFair"
              @input="resetErrors"
            />

            <div class="levels">
              <p
                :class="[{ warning: checkErrorIfContains('levelDeficiency' + index) }, 'title']"
              >{{$t('doYouHaveAnyKindOfDisability')}}</p>
              <label>
                <input
                  @change="
                    resetErrors, validCheckoboxGroup(form.infos[index], index)
                  "
                  value="nao"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="fisica"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                  :disabled="form.infos[index].levelDeficiency.includes('nao')"
                />{{$t('fis')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="intelectual"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                  :disabled="form.infos[index].levelDeficiency.includes('nao')"
                />{{$t('intellectual')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="auditiva"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                  :disabled="form.infos[index].levelDeficiency.includes('nao')"
                />{{$t('auditory')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="visual"
                  v-model="form.infos[index].levelDeficiency"
                  type="checkbox"
                  :disabled="form.infos[index].levelDeficiency.includes('nao')"
                />{{$t('visual')}}
              </label>
            </div>
            <div class="levels" v-if="!form.infos[index].levelDeficiency.includes('nao')">
              <p
                :class="[
                  {
                    warning: checkErrorIfContains('specialattendance' + index),
                  },
                  'title mt-3',
                ]"
              >{{$t('willYouNeedAnySpecializedAssistance')}}</p>
              <label>
                <input
                  @change="
                    resetErrors, validCheckoboxGroup(form.infos[index], index)
                  "
                  value="nao"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                />{{$t('none')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="acessibilidade"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                  :disabled="
                    form.infos[index].specialattendance.includes('nao')
                  "
                />{{$t('architecturalAccessibility')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="libras"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                  :disabled="
                    form.infos[index].specialattendance.includes('nao')
                  "
                />{{$t('libraInterpreter')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="ledor"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                  :disabled="
                    form.infos[index].specialattendance.includes('nao')
                  "
                >{{$t('reader')}}
              </label>
              <label>
                <input
                  @change="resetErrors"
                  value="outro"
                  v-model="form.infos[index].specialattendance"
                  type="checkbox"
                  :disabled="
                    form.infos[index].specialattendance.includes('nao')
                  "
                />{{$t('othersMale')}}
              </label>
              <input-default
                v-if="form.infos[index].specialattendance.includes('outro')"
                :class="[
                  {
                    warning: checkErrorIfContains('attendOtherSpecial' + index),
                  },
                  'mt-0',
                ]"
                id="city"
                placeholder="enterTheSpecializedService"
                type="text"
                v-model="form.infos[index].attendOtherSpecial"
                @input="resetErrors"
              />
            </div>
            <!-- div class="levels" ><label>Shirt1</label></div -->

            <input-select
              :class="[
                { warning: checkErrorIfContains('typeShirt' + index) },
                'input__space',
              ]"
              id="class"
              :items="typeShirtOptions"
              v-model="form.infos[index].typeShirt"
              label=typeOfShirt
              @input="resetErrors"
              :selected="form.infos[index].typeShirt"
            />
            <input-select
              v-if="form.infos[index].typeShirt === 'no'"
              :class="[
                { warning: checkErrorIfContains('sizeShirt' + index) },
                'input__space',
              ]"
              id="englishLevel"
              :items="sizeShirtOptions"
              v-model="form.infos[index].sizeShirt"
              label=whatSizeShirt
              @input="resetErrors"
              :selected="form.infos[index].sizeShirt"
            />
          </div>
        </div>
      </main>
      {{ cZipCode1 }}
      {{ cZipCode2 }}
      {{ cZipCode3 }}
      {{ cZipCode4 }}
      <div class="form__footer">
        <button-default :onClick="back" class="button">{{$t('back')}}</button-default>
        <button-default :onClick="checkForm" class="button">{{$t('saveAndAdvance')}}</button-default>
      </div>
    </form>
    <feedback-warning
      v-if="errors.length"
      :text="textWarning"
      :onClick="resetErrors"
      color="#ff9800"
    />
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode'
import {
  InputDefault,
  ButtonDefault,
  FeedbackWarning,
  TextWarning,
  RadioGroup,
  InputCheckboxTitle,
  InputMasked,
  InputSelectLocation,
  InputSelect
} from '@/components/atoms'
import { countries, states } from '@/utils/locations'

export default {
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  name: 'FormProjectChildishStudents',
  components: {
    InputDefault,
    ButtonDefault,
    FeedbackWarning,
    TextWarning,
    RadioGroup,
    InputCheckboxTitle,
    InputMasked,
    InputSelectLocation,
    InputSelect
  },
  data() {
    return {
      studentLogged: {},
      notRequired: [],
      form: {
        quantityStudents: null,
        livePresentation: '',
        categoryProject: null,
        infos: []
      },
      categoryProject: [
        { label: this.$t('lessThan1Year'), value: 'menos de 1 ano' },
        { label: this.$t('OneTwoYears'), value: '1 a 2 anos' },
        { label: this.$t('ThreeFourYears'), value: '3 a 4 anos' },
        { label: this.$t('FiveSixYears'), value: '5 a 6 anos' }
      ],
      quantityOption: [
        { label: this.$t('individual'), value: 1 },
        { label: this.$t('pair'), value: 2 },
        { label: this.$t('trio'), value: 3 },
        { label: this.$t('quartet'), value: 4 }
      ],
      generalOptions: [
        { label: this.$t('yes'), value: 'yes' },
        { label: this.$t('no'), value: 'no' }
      ],
      genderOptions: [
        { label: this.$t('maleSingle'), value: 'masculino' },
        { label: this.$t('femaleSingle'), value: 'feminino' }
      ],
      programOptions: [
        { label: this.$t('scholarshipCNPq'), value: 'cnpq' },
        { label: this.$t('other'), value: 'other' }
      ],
      attendOptions: [
        { label: this.$t('no'), value: 'no' },
        { label: this.$t('yesOfTheMostratec'), value: 'mostratec' },
        { label: this.$t('otherFemale'), value: 'other' }
      ],
      serieOptions: [
        { text: this.$t('nursery'), value: 'Berçário' },
        { text: this.$t('maternal'), value: 'Maternal' },
        { text: this.$t('kindergarten'), value: 'Pré' }
      ],
      englishOptions: [
        { text: this.$t('basic'), value: 'basico' },
        { text: this.$t('intermediary'), value: 'intemerdiario' },
        { text: this.$t('advanced'), value: 'avancado' },
        { text: this.$t('fluent'), value: 'fluente' }
      ],
      typeShirtOptions: [
        { text: this.$t('tsShirt01'), value: 'tsShirt01' },
        { text: this.$t('tsShirt02'), value: 'tsShirt02' },
        { text: this.$t('tsShirt03'), value: 'tsShirt03' },
        { text: this.$t('tsShirt04'), value: 'tsShirt04' },
        { text: this.$t('tsShirt05'), value: 'tsShirt05' },
        { text: this.$t('tsShirt06'), value: 'tsShirt06' },
        { text: this.$t('tsShirt07'), value: 'tsShirt07' },
        { text: this.$t('tsShirt08'), value: 'tsShirt08' }
      ],
      sizeShirtOptions: [
        { text: this.$t('notApplicable'), value: 'no' }// ,
        // { text: 'P', value: 'p' },
        // { text: 'M', value: 'm' },
        // { text: 'G', value: 'g' },
        // { text: 'GG', value: 'gg' },
        // { text: this.$t('sizeSix'), value: '6' },
        // { text: this.$t('sizeEight'), value: '8' },
        // { text: this.$t('sizeTen'), value: '10' },
        // { text: this.$t('sizeTwelve'), value: '12' },
        // { text: this.$t('sizeFourteen'), value: '14' }
      ],
      countries,
      states,
      cities: [],
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      isBetter: false,
      infoAux: []
    }
  },
  props: {
    allDataForm: [Object, String],
    mostratec_type: [Object, String]
  },
  async mounted() {
    await this.getStudentLogged()
    await this.activeStudents()
    if (this.allDataForm.FormProjectChildishStudents) {
      this.form = {
        ...this.form,
        ...this.allDataForm.FormProjectChildishStudents
      }
    }
  },
  watch: {
    // 'form.quantityStudents': function () {
    //   this.activeStudents()
    // },
    allDataForm() {
      if (this.allDataForm.FormProjectChildishStudents) {
        this.form = this.allDataForm.FormProjectChildishStudents
      }
    },
    cStateSelected() {
      this.executeGetCities(this.form.infos[0].state)
    },
    cStateSelected2() {
      this.executeGetCities(this.form.infos[1].state)
    },
    cStateSelected3() {
      this.executeGetCities(this.form.infos[2].state)
    },
    cStateSelected4() {
      this.executeGetCities(this.form.infos[3].state)
    }
  },
  computed: {
    cZipCode1() {
      if (this.form.infos[0]) {
        this.executeZipcode(this.form.infos[0].zipCode, 0)
      }
      return ''
    },
    cZipCode2() {
      if (this.form.infos[1]) {
        this.executeZipcode(this.form.infos[1].zipCode, 1)
      }
      return ''
    },
    cZipCode3() {
      if (this.form.infos[2]) {
        this.executeZipcode(this.form.infos[2].zipCode, 2)
      }
      return ''
    },
    cZipCode4() {
      if (this.form.infos[3]) {
        this.executeZipcode(this.form.infos[3].zipCode, 3)
      }
      return ''
    },
    cStateSelected() {
      if (this.form.infos[0]) {
        return this.form.infos[0].state
      }
    },
    cStateSelected2() {
      if (this.form.infos[1]) {
        return this.form.infos[1].state
      }
    },
    cStateSelected3() {
      if (this.form.infos[2]) {
        return this.form.infos[2].state
      }
    },
    cStateSelected4() {
      if (this.form.infos[3]) {
        return this.form.infos[3].state
      }
    }
  },
  methods: {
    isMainStudent(index) {
      return index == 0
    },
    validCheckoboxGroup(actualData, index) {
      if (
        actualData.levelDeficiency
        && actualData.levelDeficiency.includes('nao')
      ) {
        this.form.infos[index].levelDeficiency = ['nao']
      }
      if (
        actualData.specialattendance
        && actualData.specialattendance.includes('nao')
      ) {
        this.form.infos[index].specialattendance = ['nao']
      }
    },
    validIfInputLanguage(country) {
      //return country === 'Brazil' && this.form.categoryProject === '3 a 4 anos'
      return country === 'Brazil'
    },
    executeGetCities(state) {
      this.cities = []
      fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ state }/municipios`
      )
        .then((res) => res.json())
        .then((data) => {
          const response = []
          data.map((item) => {
            response.push({ text: item.nome, value: item.nome })
          })
          this.cities = response
        })
    },
    executeZipcode(zipCode, index) {
      const cep = String(zipCode).replace(/\D/g, '')
      if (cep.length == 8) {
        fetch(`https://viacep.com.br/ws/${ cep }/json/`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              if (this.allDataForm.FormProjectChildishStudents) {
                this.form.infos[i].cepValidate = true
                return
              }
              this.form.infos[index].cepValidate = false
              this.errors.push(`zipCode${ index }`)
              this.states = states
              this.textWarning = 'checkTheCep'
            } else {
              if (data.uf) {
                this.form.infos[index].state = data.uf
              }
              if (data.localidade) {
                this.form.infos[index].city = data.localidade
              }
              if (data.logradouro) {
                this.form.infos[index].address = data.logradouro
              }
              if (data.bairro) {
                this.form.infos[index].neighborhood = data.bairro
              }
              this.form.infos[index].cepValidate = true
            }
          })
          .catch((err) => {
            if (this.allDataForm.FormProjectChildishStudents) {
              const projectStudent = this.allDataForm.FormProjectChildishStudents.form.infos[index]

              this.form.infos[index].state = projectStudent.state
              this.form.infos[index].city = projectStudent.city
              this.form.infos[index].neighborhood = projectStudent.district
              this.form.infos[index].address = projectStudent.street
            }
            this.form.infos[index].cepValidate = false
            this.errors.push(`zipCode${ index }`)
            this.textWarning = 'checkTheCep'
          })
      } else if (cep.length < 8) {
        this.form.infos[index].cepValidate = false
      }
    },
    createInfos() {
      let i = 0
      for (i; i <= 3; i++) {
        this.form.infos.push({
          isBetter: false,
          cepValidate: false,
          active: false,
          fullname: i === 0 ? this.studentLogged.fullname : '',
          cpf: i === 0 ? this.studentLogged.cpf : '',
          gender: '',
          birth_date: '',
          responsibleName: '',
          responsibleRelative: '',
          phone: '',
          email: i === 0 ? this.studentLogged.email : '',
          country: '',
          state: '',
          city: '',
          zipCode: '',
          address: '',
          neighborhood: '',
          number: '',
          complement: '',
          serie: '',
          englishLevel: '',
          attendOtherFair: '',
          otherFair: '',
          sizeShirt: 'no',
          typeShirt: '',
          attendOtherSpecial: '',
          levelDeficiency: [],
          specialattendance: []
        })
      }
    },
    activeStudents() {
      let i = 1
      for (i; i <= this.form.infos.length; i++) {
        if (i <= this.form.quantityStudents) {
          this.form.infos[i - 1].active = true
        } else {
          // this.form.infos[i - 1].selected = false;
          this.form.infos[i - 1].active = false
        }
      }
    },
    validateIfBigger(data, index) {
      if (data.length === 10) {
        const dataForm = data
          .split('/')
          .reverse()
          .join('-')
          .concat(' 00:00:00')
        const dataAtual = new Date()
        const birth = new Date(dataForm)
        // Levando em consideração os anos bissextos, um ano não é uma quantidade de tempo constante.
        // Tratamento usando comparação de data
        if (dataAtual.getFullYear() - birth.getFullYear() > 18) this.form.infos[index].isBetter = false
        else if (dataAtual.getFullYear() - birth.getFullYear() == 18) {
          if (dataAtual.getMonth() - birth.getMonth() > 0) this.form.infos[index].isBetter = false
          else if (dataAtual.getDate() - birth.getDate() >= 0) this.form.infos[index].isBetter = false
          else this.form.infos[index].isBetter = true
        } else this.form.infos[index].isBetter = true
      } else {
        this.form.infos[index].isBetter = null
      }
    },
    validateDateFormat(date) {
      const [year, month, day] = date.split('-')
      return (Number(month) <= 12 && Number(day) <= 31 && year >= 1900)
    },
    validateDate(birth) {
      const formattedDate = birth
        .split('/')
        .reverse()
        .join('-')

      const actualDate = new Date().setHours(0, 0, 0, 0, 0, 0)
      const birthDate = new Date(formattedDate.concat(' 00:00:00'))
      if (!this.validateDateFormat(formattedDate)) {
        this.textWarning = 'invalidDateFormat'
        return false
      }
      if (actualDate < birthDate) {
        this.textWarning = 'A data de nascimento informada deve ser menor do que a data atual.'
        return false
      } return true
    },
    checkForm() {
      this.resetErrors()
      if (!this.form.quantityStudents || this.form.quantityStudents == null) {
        this.errors.push('quantityStudents')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }
      if (!this.form.livePresentation || this.form.livePresentation == null) {
        this.errors.push('livePresentation')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }
      /*
      if (!this.form.categoryProject || this.form.categoryProject == null) {
        this.errors.push('categoryProject')
        this.textWarning = this.$t('warningAllFieldsAreObligatory')
      }
      */
      if (this.errors.length) return false
      const emails = []
      // Retirar dados do brasil se for estrangeiro
      for (let i = 0; i < this.form.quantityStudents; i++) {
        if (!this.form.infos[i].active) {
          this.errors.push(`active${ i }`)
          this.textWarning = 'É necessário informar os dados dos estudantes assinalados'
        }
      }
      if (this.errors.length) return false
      this.infoAux = this.form.infos
      this.infoAux.map((item, index) => {
        if (item.active) {
          if (item.country !== 'Brazil') {
            this.notRequired.push('neighborhood')
            this.notRequired.push('address')
            this.notRequired.push('street')
            this.notRequired.push('zipCode')
            this.notRequired.push('number')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'neighborhood'
                && item !== 'address'
                && item !== 'street'
                && item !== 'zipCode'
                && item !== 'number'
            )
          }
          if (!item.isBetter) {
            this.notRequired.push('responsibleName')
            this.notRequired.push('responsibleRelative')
          } else {
            this.notRequired = this.notRequired.filter((item) => item !== 'responsibleName' && item !== 'responsibleRelative')
          }
          if (item.country === 'Brazil' && item.cepValidate) {
            this.notRequired.push('zipCode')
          } else if (item.country === 'Brazil' && !item.cepValidate) {
            this.notRequired = this.notRequired.filter((item) => item !== 'zipCode')
            this.notRequired.push('englishLevel')
          }
          if (
            !(
              item.country === 'Brazil'
              // && this.form.categoryProject === '3 a 4 anos'
            )
          ) {
            this.notRequired.push('englishLevel')
          } 
          /*
          else if (
            item.country === 'Brazil'
            && !this.form.categoryProject === '3 a 4 anos'
          ) {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'englishLevel'
            )
          }
          */
          if (item.attendOtherFair != 'other') {
            this.notRequired.push('otherFair')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'otherFair'
            )
          }
          this.notRequired.push('englishLevel')
          /*
          if (item.typeShirt === 'no') {
            this.notRequired.push('typeShirt')
            this.notRequired.push('sizeShirt')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'sizeShirt'
            )
          }
          */
          if (item.levelDeficiency.includes('nao')) {
            this.notRequired.push('attendOtherSpecial')
            this.notRequired.push('specialattendance')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'specialattendance' && item !== 'attendOtherSpecial'
            )
          }
          if (!item.specialattendance.includes('outro')) {
            this.notRequired.push('attendOtherSpecial')
          } else {
            this.notRequired = this.notRequired.filter(
              (item) => item !== 'attendOtherSpecial'
            )
          }
          this.notRequired.push('complement')
          this.notRequired.push('isBetter')
          this.notRequired.push('cepValidate')
          for (const key in item) {
            if (item[key] == null || !item[key]) {
              if (!this.notRequired.includes(key)) {
                this.errors.push(`${ key }${ index }`)
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
              }
            } else if (item[key].length == 0) {
              if (!this.notRequired.includes(key)) {
                this.errors.push(`${ key }${ index }`)
                this.textWarning = this.$t('warningAllFieldsAreObligatory')
              }
            }
          }
        }
      })
      if (!this.errors.length) {
        this.form.infos.forEach((value, index) => {
          if (value.active) {
            if (!this.validateEmail(value.email, index)) {
              this.errors.push(`email${ index }`)
              this.textWarning = 'E-mail inválido!'
            }
            emails.push(value.email)
            if (
              value.specialattendance
              && value.specialattendance.includes('outro')
            ) {
              this.form.infos[index].specialattendance.push(
                this.form.infos[index].attendOtherSpecial
              )
              this.form.infos[index].specialattendance = [
                ...new Set(
                  this.form.infos[index].specialattendance.filter(Boolean)
                )
              ]
            }
            if (!this.validateDate(value.birth_date)) {
              this.errors.push(`birth_date${ index }`)
            }
          }
        })
        if (this.errors.length) {
          return false
        }
        if (this.hasDuplicates(emails)) {
          this.errors.push('email1')
          this.textWarning = 'E-mails inseridos devem ser distintos!'
          return false
        }

        this.resetErrors()
        this.temporarySave()
        this.saveForm()
      }
    },
    async getStudentLogged() {
      const userLogged = await jwt_decode(localStorage.getItem('token'))
      const { data: userInfoUsers } = await this.$http.get(
        `/user/${ userLogged.uid }`
      )
      this.studentLogged = {
        fullname: userInfoUsers?.user?.name,
        email: userInfoUsers?.user?.email,
        cpf: userInfoUsers?.user?.pre_registration_student_fair_affiliates?.cpf
      }
      this.createInfos()
    },
    async temporarySave() {
      this.allDataForm.FormProjectChildishStudents = this.form
      const body = {
        temporary_data: this.allDataForm,
        form_type: this.mostratec_type
      }
      try {
        const { data: response } = await this.$http.post('/temp', body)
      } catch (error) {
        console.error(error)
      }
    },
    async saveForm() {
      try {
        this.form.infos = this.infoAux
        this.$emit('changeElement', {
          direction: 'up',
          FormProjectChildishStudents: this.form
        })
      } catch (error) {
        this.errors.push(1)
        this.textWarning = 'Verifique os dados informados!'
      }
    },
    back() {
      this.$emit('changeElement', {
        direction: 'down'
      })
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    resetErrors() {
      if (this.errors.length) {
        this.errors = []
      }
    },
    validateEmail(mail, index) {
      const onlyEmail = mail.replace(/ /g, '')
      this.form.infos[index].email = onlyEmail
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(onlyEmail)) {
        this.errors.push('email')
        return false
      }
      return true
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    text-align: left;
    max-width: 464px;

    span {
      font-size: 16px;
      line-height: 22px;
      margin: 5px 0px;
      color: $primary;
    }
  }

  &__title {
    font-weight: 900;
    font-size: 40px;
    line-height: 120%;
    color: $primary;
    margin-bottom: 5px;
  }

  &__text {
    font-family: Mulish;
    font-size: 16px;
    line-height: 22px;
    color: $primary;
    margin: 5px 0px;
  }

  &__main {
    .form__item {
      .input__space {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .separator {
        border-bottom: 1px solid $primary;
        padding-bottom: 15px;
        margin-top: 30px;

        color: $primaryDark;
        font-family: Mulish;
        font-weight: bold;
        font-size: 18px;
        line-height: 120%;
      }

      &--info {
        font-family: Mulish;
        font-size: 16px;
        line-height: 18px;
        color: $primary;
        max-width: 520px;
      }

      .levels {
        display: flex;
        flex-direction: column;

        .title {
          color: #303030;
          display: flex;
          flex-direction: column;
          font-size: 16px !important;
          font-weight: 600;
          line-height: 20px;
          text-align: left;
        }

        label {
          display: flex;
          color: $almostBlack;
          font-size: 16px;
          margin-bottom: 5px;

          input {
            zoom: 1.5;
            margin-right: 5px;
          }
        }

        .warning {
          color: #ff9800;
          border-bottom-color: $attention;
        }
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
  }
}
</style>
