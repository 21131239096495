<template>
  <div class="container-app">
    <div class="wrapper">
      <welcome-home />
    </div>
  </div>
</template>

<script>
import { WelcomeHome } from '@/components/organisms'

export default {
  name: 'InitialHome',
  components: { WelcomeHome }
}
</script>

<style lang="scss" scoped>
$imgUrl: '../../assets/background-login.png';

.container-app {
  background-image:
    linear-gradient(
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0.01)
    ),
    url($imgUrl);
  background-size: contain;
  min-height: 80vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
