<template>
  <div>
    <div class="pagination">
      <div
        v-if="page > 1"
        class="arrow back"
        @click="emitNewPage(1)"
      >
        <img
          :src="require('@/assets/Shape.svg')"
        >
      </div>
      <div
        class="number"
        v-for="(ant, index) in cPrevious"
        :key="index"
        @click="emitNewPage(ant)"
      >
        {{ant}}
      </div>
      <div class="number current-page">{{page}}</div>
      <div
        class="number"
        v-for="(next, index) in cNext"
        :key="`${index}a`"
        @click="emitNewPage(next)"
      >
        {{next}}
      </div>
      <div
        v-if="page < lastPage"
        class="arrow next"
        @click="emitNewPage(lastPage)"
      >
        <img
          :src="require('@/assets/Shape.svg')"
        >
      </div>
    </div>
    <div class="pagination-info">
      {{page == 1 ? '1' : ((page - 1) * perPage) + 1}} - {{page * perPage}} de {{total}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Paginator',
  data() {
    return {

    }
  },
  props: {
    page: {
      type: [String, Number],
      required: true
    },
    lastPage: {
      type: [String, Number],
      required: true
    },
    perPage: {
      type: [String, Number],
      required: true
    },
    total: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    emitNewPage(value) {
      this.$emit('goPage', value)
    }
  },
  computed: {
    cPrevious() {
      if (this.page) {
        const res = []
        let i = this.page - this.perPage

        while (i < this.page) {
          if (i > 0) {
            res.push(i)
          }
          i++
        }
        return res
      }
      return []
    },
    cNext() {
      if (this.page) {
        const res = []
        let i = this.page + 1
        const max = (this.page + 1) + this.perPage
        while (i < max && i <= (Math.ceil(this.total / this.perPage))) {
          res.push(i)
          i++
        }

        return res
      }
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination {
  padding:  0 15px;
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;

  .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: #B5B5B5;
    cursor: pointer;
    margin-bottom: 5px;

    &.current-page {
      cursor: default;
      border-radius: 4px;
      color: $white;
      background-color: #1B778B;
    }
  }

  .arrow {
    cursor: pointer;

    &.back {
      transform: rotate(90deg);
    }

    &.next {
      transform: rotate(-90deg);
    }
  }

}

.pagination-info {
  display: flex;
  justify-content: flex-end;
  padding: 0 15px;
  color: #B5B5B5;
  font-size: 14px;
}

</style>
