import Vue from 'vue'
import Vuetify from 'vuetify'
import Api from '@/services/api'
import App from './App.vue'
import router from './router'
import acl from './services/aclCreate'
import store from './store'
import i18n from './i18n'
import sessionHandler from './sessionHandler';

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

import Filter from './filters/filters'

Vue.mixin({ filters: Filter })
Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(Api)
Vue.use(sessionHandler)

new Vue({
  router,
  acl,
  store,
  i18n,
  vuetify: new Vuetify({
    theme: {
      light: true,
      themes: {
        light: {
          primary: '#1B778B',
          secondary: '#006076',
          accent: '#FBE265',
          error: '#b71c1c',
          warning: '#ffffff',
          info: '#ffffff'
        }
      }
    }
  }),
  created() {
    // Verifica a sessão ao iniciar a aplicação
    this.$checkSingleWindow();
  },
  render: (h) => h(App)
}).$mount('#app')
