<template>
  <div class="feedback--attention" :style="{ backgroundColor: color}">
    <div class="wrapper_text">
      <img class="icon-info" src="@/assets/attention.png" alt="Atenção" />
      <span class="text">{{ $t(text) }}</span>
    </div>
    <a @click="onClick">
      <img class="icon-close" src="@/assets/close.png" alt="Close" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'FeedbackWarning',
  props: {
    text: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      required: true
    },
    color: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback--attention {
  @include d-flex-between;
  border-radius: 17px;
  box-shadow: 0px 3.81047px 3.81047px rgba(0, 0, 0, 0.1);
  color: $white;
  margin-right: 10px;
  max-width: 380px;
  padding: 15px 16px;
  position: fixed;
  bottom: 40px;
  left: 5%;

  .wrapper_text {
    @include d-flex-center;
  }

  .icon-info {
    height: 23px;
    width: 23px;
  }

  .icon-close {
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 0px 15px;
    text-align: left;
  }
}
</style>
