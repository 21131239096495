<template>
  <div class="home">
    <accreditation-template/>
  </div>
</template>

<script>

import { AccreditationTemplate } from '@/components/templates'

export default {
  name: 'AccreditationPanel',
  components: {
    AccreditationTemplate
  }
}
</script>

<style lang="scss" scoped>

</style>
