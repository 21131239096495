module.exports = {
  en: {
    emailOrPassword: 'Verify your email/password',
    emailNotConfirmed: 'Your access e-mail was not confirmed yet. Please, enter your e-mail and confirm it to login.',
    userNotActivated: 'The user is inactive. Contact the events secretary.',
    myPanelFairVacancies: 'My fair panel',
    forgotPassword: 'Forgot your password?',
    clickHereLogin: 'Click here',
    toRecoveryLogin: 'to recovery',
    buttonLogin: 'Login',
    rememberMeLogin: 'Remember me',
    emailLogin: 'Insert your e-mail',
    passwordLogin: 'Insert your password',
    passwordLoginLabel: 'Password',
    projectData: 'Project data',
    abstract: 'Abstract',
    term: 'Consent Term for Personal Data Processing',
    onlineRegistrationTerm: 'On-line registration',
    mostratecCriteria: 'Criteria for participation in MOSTRATEC',
    subscriptionRegistration: 'TO ACCESS ONLINE REGISTRATION, CLICK ON',
    agreed: '"I HAVE READ AND AGREE"',
    participationCriteria: 'AFTER READING ALL THE PARTICIPATION CRITERIA BELOW.',
    forStudent: 'For the student:',
    forProject: 'For the project:',
    highEnrolled: 'a) be enrolled in a High School or High School Technical ',
    levelHigh: 'Professional Education course;',
    yearsOld: 'b) be at least 14 years old;',
    responsible: 'c) be accompanied by a responsible person, if a minor;',
    maxThreeStudents: 'f) be developed by a maximum of 3 students',
    publicity: 'Advertising grant:',
    centralComission: 'a) Finalists give permission to the Central Commission to use information',
    photos: '(photos, videos, folders, among others) for the dissemination of MOSTRATEC.',
    press: 'b) The press (written, spoken and visual) are authorized to use data or images',
    publicizing: 'for the purpose of publicizing the event.',
    colaborate: 'c) The finalists undertake to collaborate with the press and the like to provide',
    informationPublicizing: 'information for the purpose of publicizing the event and your respective',
    yourProjects: 'projects.',
    namePermission: 'd) The finalists give permission to the Central Commission for the release of',
    awarding: 'names (and respective educational institutions) for the purposes of awarding',
    participatingWorks: 'and use of the quantities of participating works for accreditation purposes',
    otherFairs: 'at other fairs, events and with research funding agencies.',
    studentAuthory: '- we emphasize that the project must be authored by the student(s) of the',
    middleLevel: 'high school or technical level professional education;',
    comments: 'Comments:',
    readDocument: ' I declare that I have read, am aware of, and agree with the terms stated above. (mandatory response).',
    establishedCriteria: '- projects must necessarily meet the established criteria, otherwise, they will  ',
    loseSpot: 'lose their place.',
    submitProject: 'd) enroll in the project within the deadlines defined by Mostratec;',
    techProject: 'e) participate only with a scientific or technological project.',
    EJA: 'For elementary, adult education or early childhood education students:',
    schoolBasic: 'a) be enrolled in a basic curricular school;',
    hasAdvisors: 'a) have a supervisor (over 21 years of age);',
    rulesAccordance: 'b) be prepared in accordance with Mostratec\'s scientific research rules;',
    studyAreas: 'c) have, as an object of study, one of the defined areas of knowledge',
    byMostratec: 'by Mostratec;',
    precedingFair: 'preceding the fair.',
    investigation: 'd) focus on one of the following characteristics: research and/or innovation;',
    twelveMonthProject: 'e) present the continuous work of 12 (twelve) months, started in the year',
    projectDeadline: 'b) submit the project within the deadlines established by Mostratec;',
    onlyOneProject: 'd) participate only with a scientific or technological project.',
    projectSummary: 'Project summary',
    ResultsLabel: 'Results and conclusions',
    researchSubAreas: 'Research sub-areas',
    returnInformationLogin: 'Back to information page.',
    initialRegisterHeader1: 'Inform the following data to perform the pre-registration on the Mostratec',
    initialRegisterHeader2: 'Digital. After filling all fields, confirm your registration by acessing the',
    initialRegisterHeader3: 'registered email in the form bellow.',
    initialRegisterHeader4: 'after confirming the data, you will be able to access the system to fill in',
    initialRegisterHeader5: 'the registration form and go along your',
    initialRegisterHeader6: 'solicitation.',
    allFieldsAreObligatory: 'All fields are obligatory.',
    ResearchPlan: 'Research Plan',
    ResearchPlanInfo: 'The following fields must contain an objectives and concise description of your research plan elements.',
    ThemeLabel: 'Theme',
    continuityFileInsert: 'Insert the continuity document',
    ThemeTextInfo: 'Describe the aspect of the subject you want to research, prove or develop',
    JustificationLabel: 'Justification',
    JustificationPlaceholder: 'Enter your research\'s justification',
    JustificationCancel: 'Enter the cancellation justification',
    JustificationTextInfo_parOne: 'Justification, in a research project, is the conviction that the research work is essential to be effective and relevant to society or to some individuals who will benefit from the research. It should present how the information generated by the survey is useful and to whom, how it will aggregate and what decisions can be made based on the data generated.',
    JustificationTextInfo_parTwo: 'The justification highlights the importance of the topic to be studied, justifies the need to carry out such an undertaking and leads to the formulation of the problem. It should be highlighted in the work that there are other works that highlight the importance of the research topic and these should be referenced.',
    ProblemLabel: 'Problem',
    ProblemPlaceholder: 'Enter your research\'s problem',
    ProblemTextInfo: 'The problem stems from a situation that raises questions about the topic and can be defined by the researcher\'s own experience or indicated by professionals linked to the topic. From the identification of the problem, a specific question is elaborated to be answered by the research, thus establishing a study focus to answer the question. The research questions must be capable of answers which must be obtained with scientific and/or technological/engineering methodology.',
    HypothesisLabel: 'Hypothesis (optional)',
    HypothesisPlaceholder: 'Enter your research\'s hypothesis',
    HypothesisTextInfo_parOne: 'The hypothesis is a possible answer to the question established in the research project problem. According to Bello (2009, p. 23), it is a pre-solution to the problem raised in the topic chosen for the research.',
    HypothesisTextInfo_parTwo: 'Depending on the methodological choice made, the research does not necessarily need to establish hypotheses. For survey-type research or technological research, for example, hypothesis does not become a formal mandatory item.',
    ObjectivesLabel: 'Objectives',
    ObjectivesPlaceholder: 'Enter your research\'s objectives',
    ObjectivesTextInfo: 'The definition of objectives determines what the researcher wants to achieve by carrying out the research work and must correspond to the proposed questions.',
    ObjectLabel: 'Objects',
    ObjectPlaceholder: 'Enter your research\'s objects',
    ObjectTextInfo: 'That\'s what will be done. From the object, the research area and classification in scientific or technological research are defined.',
    BibliographicReviewLabel: 'Bibliographic review',
    BibliographicReviewPlaceholder: 'Enter your research\'s bibliographic review',
    BibliographicReviewTextInfo: 'At this time, the researcher searches, locates and reviews the literature where he will obtain bibliographic material that will subsidize the theme of the research work, such as books, scientific articles, magazines, newspapers, technical standards, legislation, etc.',
    MethodologyLabel: 'Methodology',
    MethodologyPlaceholder: 'Enter your research\'s methodology',
    MethodologyTextInfo_parOne: 'The Methodology, which is also called Materials and Methods, is the description of the strategy to be adopted, which contains all the steps and procedures adopted to carry out the research and achieve the objectives. ',
    MethodologyTextInfo_parTwo: 'After carrying out the options, a detailed description of how the research will be carried out (how the data will be collected, questionnaires, interviews, samples, etc.) and how the analysis of the data that will be obtained will be carried out. The schedule, the resources that will be needed, and the assessment should be included.',
    CronogramLabel: 'Cronogram',
    CronogramPlaceholder: 'Enter your\'s research\'s cronogram',
    CronogramTextInfo: 'It is an adequate planning of the time the researcher will have to carry out the work, specifying the activities to be carried out.',
    DataAnalisisLabel: 'Data analisis',
    DataAnalisisPlaceholder: 'Enter your research\'s data analisis',
    DataAnalisisTextInfo: 'Should it show how the analysis will be done, data evaluation, what it will compare with, will it use some statistical method? How will you be able to tell if the results are good or not, will you compare the results with what, with the specifications of some standard or with the results of some author, or with what other type of research?',
    ReferencesLabel: 'References',
    ReferencesPlaceholder: 'Enter your research\'s references',
    ReferencesTextInfo: 'The reference of documents actually cited within the research project is a mandatory item for the elaboration of the Project.',
    affiliationPeriodClosed: 'Affiliation period closed!',
    ifYouMissedTheDeadline: 'If you missed the deadline, we count on your collaboration to encourage scientific research in students in the next edition of Mostratec!',
    ThemePlaceholder: 'Enter your research\'s theme',
    projectTitle: 'Project Title',
    enterTheProjectTitle: 'Enter the project title',
    typeOfResearchDevelop: 'Type of research developed',
    researchAreaSingle: 'Research area',
    isThereOtherSubarea: 'Is there another sub-area?',
    isThereOtherSubareaPlaceholder: 'If yes, enter what is the other sub-area',
    isYourProjectContinuity: 'Is your project continuity?',
    continuityProjectInfo: 'If the project of up to 12 months has been completed and you have decided to follow it up with a new objective, this is considered a continuation project.',
    doesItInvolveVertebrateAnimals: 'Does it involve non-human vertebrate animals?',
    doesItInvolveHumans: 'Does it involve human beings?',
    doesItInvolveHumansInfo: 'If your research involved human beings, such as a questionnaire, interview, other types of interaction, etc., you must fill out form 4, sign all fields as indicated on the form and attach it to the end of the registration.',
    doesItInvolveBiologicalSubstances: 'Does it involve biological substances?',
    doesItInvolveDangerousBiologicalSubstances: 'Does it involve potentially dangerous biological substances?',
    doesItInvolveTissueFromHumans: 'Does it involve tissue from humans or vertebrate animals?',
    doesItInvolveControlledSubstances: 'Does it involve controlled substances?',
    doesItInvolveDangerousSubstances: 'Does it involve dangerous substances and equipment?',
    isTheResearchCarriedInInstituition: 'Is the Research carried out in a research institution or regulated industry?',
    isTheResearchCarriedInInstituitionInfo: 'Example: university, laboratory, etc.',
    scientific: 'Scientific',
    technological: 'Technological',
    scientificInfo: 'Basic or Applied and Engineering Research.',
    technologicalInfo: 'Engineering Research - Materialization of a product or process through a model, prototype or pilot installation. Execution of a work, manufacture of a product, provision of a service, execution of a process.',
    cellularMolecularMicrobiology: 'Cellular and Molecular Biology, Microbiology',
    animalPlantSciences: 'Animal and Plant Sciences',
    environmentalSciences: 'Environmental Sciences',
    healthSciences: 'Health Sciences',
    educationAndHumanities: 'Education and Humanities',
    environmentalAndSanitaryEngineering: 'Environmental and sanitary engineering',
    electricalEngineering: 'Electrical Engineering',
    electronicEngineering: 'Electronic Engineering',
    mechanicalEngineering: 'Mechanical Engineering',
    historyAndSocialSciences: 'History and Social Sciences',
    checkResearchSubAreas: 'Check your project\'s research sub-areas:',
    molecularAndCellBiology: 'Molecular and Cell Biology;',
    genetics: 'Genetics;',
    immunology: 'Immunology;',
    antibiotics: 'Antibiotics;',
    antimicrobials: 'Antimicrobials;',
    bacteriology: 'Bacteriology;',
    virology: 'Virology;',
    mycology: 'Mycology;',
    others: 'Others',
    generalBiochemistry: 'General Biochemistry;',
    metabolism: 'Metabolism;',
    foodChemistry: 'Food chemistry;',
    organicChemistry: 'Organic chemistry;',
    inorganicChemistry: 'Inorganic chemistry;',
    analyticalChemistry: 'Analytical chemistry;',
    generalChemistry: 'General chemistry;',
    chemicalEngineering: 'Chemical engineering;',
    physicoChemical: 'Physicochemical;',
    structuralBiochemistry: 'Structural biochemistry;',
    biologicalDevelopment: 'Biological development (human and animal);',
    ecology: 'Ecology;',
    animalHusbandry: 'Animal husbandry;',
    animalPhysiologyPathology: 'Animal Physiology and Pathology;',
    agricultureAgronomy: 'Agriculture/Agronomy;',
    plantMorphology: 'Plant morphology and development;',
    photosynthesis: 'Photosynthesis;',
    plantPhysiology: 'Plant Physiology;',
    plantEvolutionTheory: 'Plant evolution theory;',
    pollutionAir: 'Pollution and air quality;',
    pollutionWater: 'Pollution and water quality;',
    contaminationSoilQuality: 'Contamination and soil quality;',
    environmentalToxicology: 'Environmental Toxicology;',
    computerEngineering: 'Computer engineering;',
    algorithmsDatabase: 'Algorithms, database;',
    artificialIntelligence: 'Artificial intelligence;',
    communicationNetworks: 'Communication and networks;',
    computerScienceSub: 'Computer Science;',
    graphics: 'Graphics;',
    softwareEngineering: 'Software Engineering;',
    computerSystems: 'Computer systems;',
    programmingLanguages: 'Programming languages;',
    diagnosisAndTreatmentDiseases: 'Diagnosis and treatment of diseases;;',
    epidemiology: 'Epidemiology;',
    physiologyAndPathophysiology: 'Physiology and Pathophysiology;',
    healthAtWork: 'Health at Work;',
    preventionAndProphylaxis: 'Prevention, prophylaxis;',
    nutrition: 'Nutrition;',
    biomedicine: 'Biomedicine;',
    nursing: 'Nursing;',
    dentistry: 'Dentistry;',
    physicalEducation: 'Physical education;',
    climatology: 'Climatology, climate;',
    geochemistryGeophysics: 'Geochemistry, Geophysics;',
    mineralogy: 'Mineralogy;',
    paleontology: 'Paleontology;',
    planetarySciences: 'Planetary Sciences;',
    geology: 'Geology;',
    seismology: 'Seismology;',
    oceanography: 'Oceanography;',
    algebra: 'Álgebra;',
    analyticalMathematics: 'Analytical mathematics;',
    aplicatedMath: 'Aplicated math;',
    geometry: 'Geometry;',
    probabilityStatistics: 'Probability and statistics;',
    atomsMoleculesSolids: 'Atoms, molecules and solids;',
    biologicalPhysics: 'Biological Physics;',
    instrumentationEletronics: 'Instrumentation and electronics;',
    magnetismElectromagnetism: 'Magnetism and Electromagnetism;',
    particleNuclearPhysics: 'Particle and nuclear physics;',
    opticsLaser: 'Optics, Laser;',
    acoustics: 'Acoustics;',
    theoreticalPhysicsAstronomy: 'Theoretical physics, astronomy;',
    theories: 'Theories of knowledge, Methodology, Didactics, Pedagogy;',
    linguistics: 'Linguistics;',
    artisticExpression: 'Artistic Expression;',
    philosophyTheology: 'Philosophy and Theology;',
    bioremediation: 'Bioremediation;',
    ecosystemManagement: 'Ecosystem Management;',
    landWaterForestManagement: 'Management of land, water and forest resources;',
    wasteManagement: 'Waste management, recycling;',
    environmentalPolicy: 'Environmental policy, environmental legislation;',
    environmentalEducational: 'Environmental education;',
    transportEngineering: 'Transport engineering;',
    aerospaceAeronauticalEngineering: 'Aerospace and aeronautical engineering, aerodynamics;',
    civilEngineering: 'Civil engineering and construction;',
    securityEngineering: 'Security Engineering;',
    productDevelopment: 'Product development;',
    materialsScience: 'Materials science;',
    generalEletric: 'General Electric;;',
    generationTransmissionElectricalEnergy: 'Generation, transmission and transformation of electrical energy;',
    eletricDrives: 'Electric drives;',
    renewableEnergy: 'Renewable energy;',
    generalElectronics: 'General Electronics;',
    electronicSystems: 'Electronic Systems and Processes;',
    communicationSystems: 'Communication Systems;',
    industrialAutomation: 'Industrial automation;',
    robotics: 'Robotics;',
    industrialEngineering: 'Industrial engineering;',
    vehicleDevelopment: 'Vehicle development;',
    productAndProcessDevelopment: 'Product and process development;',
    history: 'History;',
    psychology: 'Psychology;',
    sociology: 'Sociology;',
    anthropology: 'Anthropology;',
    economy: 'Economy;',
    policts: 'Politics;',
    management: 'Management;',
    informTheDataOfTheProject: 'Inform the data of the authors of this project. The number of students available for registration corresponds to your answer in the field',
    numberOfStudentsInTheProject: 'Students\'s number in the project.',
    ableToParticipateInALivePresentation: 'Will the student(s) of the project be able to participate in a live presentation?',
    individual: 'Individual',
    pair: 'Pair',
    trio: 'Triplet',
    student: 'Student ',
    studentOneInfo: 'The student 1 is responsible for the pre-registration and registration of the project. Remember that it is through this user\'s pre-registration login that it will be possible to monitor the progress of their registration.',
    fullName: 'Full name',
    fullNameStudent: 'Student full name',
    fullNamePlaceholder: 'Name and surname',
    maleSingle: 'Male',
    femaleSingle: 'Female',
    sex: 'Sex',
    birthDate: 'Birth date',
    responsibleName: 'Name of responsible person',
    relationshipOfResponsible: 'Relationship of the person responsible',
    enterThePhone: 'Insert phone',
    enterTheEmail: 'Enter student email',
    enterTheResponsibleAdultName: 'Enter the name of the responsible adult',
    enterParentRelationship: 'Enter parent\'s relationship',
    registrationData: 'Registration data',
    levelOfKnowledgeInEnglish: 'Level of knowledge in English language',
    basic: 'Basic',
    intermediary: 'Intermediary',
    advanced: 'Advanced',
    fluent: 'Fluent',
    doYouHaveScholarship: 'Do you have a scholarship in a junior scientific initiation program?',
    whichProgram: 'Which program?',
    haveYouParticipatedAnyScienceFair: 'Have you participated in any science fair?',
    doYouHaveAnyKindOfDisability: 'Do you have any kind of disability?',
    none: 'None',
    physical: 'Physical',
    intellectual: 'Intellectual',
    auditory: 'Auditory',
    visual: 'Visual',
    willYouNeedAnySpecializedAssistance: 'Will you need any specialized assistance?',
    architecturalAccessibility: 'Architectural accessibility (The physical space of the fair already has access ramps and elevator)',
    libraInterpreter: 'Libra Interpreter',
    reader: 'Reader',
    othersMale: 'Others',
    typeOfShirt: 'What type of shirt do you choose?',
    whatSizeShirt: 'What size shirt?',
    scholarshipCNPq: 'Scholarship funded by CNPq',
    otherFemale: 'Other',
    enterTheProgramName: 'Enter the program name',
    enterWhichScienceFair: 'Enter which science fair you\'ve done',
    yesOfTheMostratec: 'Yes, of the Mostratec',
    notApplicable: 'Not applicable',
    shirt: 'T-shirt',
    poloShirt: 'Polo shirt',
    p: 'S',
    m: 'M',
    g: 'L',
    gg: 'XL',
    projectsRevision: 'Project review',
    projectsPayment: 'Registration payment',
    isAproved: 'Do you consider this project approved for participation in Mostratec?',
    ifAproved: 'If you approve it, enrolled students will be allowed to pay the participation fee. Otherwise, enter a reason for disapproval in the opinion fields.',
    subscriptionAproved: 'Yes, application approved!',
    subscriptionRejected: 'No, registration reproved!',
    revision: 'Review',
    reviewForm1: 'Review form 1',
    startReview: 'Start review',
    finishReview: 'Finish review',
    editSituation: 'Edit situation',
    video: 'Video',
    continuity: 'Continuity project',
    revisionName: 'Review\'s responsible name',
    nameSurname: 'Name Surname',
    considerations: 'Leave your considerations here',
    opinionToInternal: 'Opinion registered internally to the system in case of disapproval of the project application',
    opinionToStudents: 'Opinion sent to students in case of disapproval of their project registration.',
    presentation: 'Project presentation',
    vertebrates: 'Vertebrate animals',
    bios: 'Potentially dangerous biological substances',
    instituition: 'Research institution or regulated industry',
    scientist: 'Qualified scientist',
    humans: 'Human beings',
    humansNecessaryFile: 'It is necessary to upload the human subjects form.',
    tissues: 'Substances with tissues from humans or vertebrate animals',
    substitute: 'This project will be a substitute',
    riscs: 'Risk assessment',
    banner: 'Banner',
    projectGrade: 'Project grade',
    giveAGrade: 'Give it a grade from 0 to 10',
    projectOpinion: 'Project opinion',
    internalOpinion: 'Internal opinion',
    revisionFiles: 'Review of registered files',
    useCheckbox: 'To facilitate your organization, check the revised files checkbox before approving the project.',
    fairName: 'Fair name',
    country: 'Country',
    state: 'State',
    city: 'City',
    cpfCnpj: 'CPF/CNPJ',
    email: 'E-mail',
    initialRegisterCpfCnpjTextInfo: 'Inform the CPF of the student responsible for registering projects.',
    initialRegisterEmailTextInfo: 'The e-mail inserted above will be used to access Mostratec platform after the pre registration confirmation. It must be different from the email registered at the affiliated fair and cannot be used in more than one project.',
    initialRegisterPasswordTextInfo: 'The password created above will be used to access Mostratec platform after the pre registration confirmation. It must be at least 8 characters long, including lowercase letters, uppercase letters, number and special character (! @ # $ % & * / + - ?).',
    send: 'Send',
    reports: 'Reports',
    manageSubcriptions: 'Manage Subscriptions',
    fairSettings: 'Fair Settings',
    submitSubcriptions: 'Request Subscription',
    notifications: 'Notifications',
    welcome: 'Welcome',
    institutionTitle: 'School/institution data',
    cell: 'Cell phone',
    landline: 'Landline (optional)',
    contact: 'Contact',
    mainContactInfo: 'The contact information for contact 1 will be used for notifications regarding MOSTRATEC.',
    contacts: 'Contacts',
    name: 'Name',
    contactNamePlaceholder: 'Enter the contact name',
    contactPhone: 'Telephone (fixed or mobile, only numbers)',
    office: 'Position/function',
    officePlaceholder: 'Enter the contact office',
    editionRegistration: 'Edition Registration',
    feesDetails: 'Fees',
    allEditions: 'All Editions',
    generalData: 'Register Edition',
    vacancies: 'Vacancies',
    informations: 'Information',
    documents: 'Documents',
    heartyWelcome: 'Hearty Welcome!',
    trackEntries: 'Track your entries.',
    genderedAdvisor: 'Advisor',
    genderedCoAdvisor: 'Co-Advisor',
    assignmentPresentation: 'Assignment Presentation',
    humanBeingsNoun: 'Human Beings',
    risksEvaluation: 'Risk Evaluation',
    affiliatedFairData: 'Affiliated Fair Data',
    type: 'Type',
    schoolInstitution: 'School/Institution', 
    responsibleStudent: 'Responsible Student',
    subscribed: 'Subscribed',
    openRegistrations: 'Open',
    revisionRegistrations: 'Under review',
    inProgress: 'In Progress',
    waitingPayment: 'Waiting Payment',
    registrationComplete: 'Registration complete',
    registrationCanceled: 'Subscription canceled',
    registrationCanceledText: 'Project registration process has been canceled.',
    sketch: 'Sketch',
    requestRegistration: 'Request Registration',
    noProject: 'You still don\'t have any projects registered at Mostratec. Keep an eye on the deadlines and application fee and click Apply to get started!',
    reviewDeniedDescription: 'The project application was not approved by the CRC.',
    pendingAcceptStatus: 'Waiting accept',
    pendingRevision: 'Waiting review',
    pendingAnswer: 'Waiting review',
    underReview: 'Under review',
    reviewAccepted: 'Review accepted',
    reviewDenied: 'Review denied',
    sentAnswer: 'Sent answer',
    obrigatoryFiles: 'Obrigatory Files',
    presentationVideo: 'Presentation Video',
    videoFile: 'Video File',
    videoLink: 'Video Link',
    bannerLink: 'Banner Link',
    projectBannerLink: 'Project banner link',
    projectVideoLink: 'Project video project',
    placeholderBannerLink: 'Insert the link to the project banner',
    videoLinkInsert: 'Insert the link to the presentation',
    editionInformations: 'Register the following requested informations to set a new Mostratec fair edition.',
    activeEditionWarn: 'Upon creating a new edition, it will be automatically set as the active edition.',
    fairStatus: 'Fair Status',
    inactive: 'Inactive',
    active: 'Active',
    editionId: 'Edition identification code',
    editionFairStatusInfo: 'By activating, you are enabling the receipt of registrations for the new edition of the registered fair.',
    vinculatedFair: 'Vinculated fair',
    editionVinculatedFairInfo: 'Select for which mode of fair  the new edition will be setted',
    editionVinculatedFairPlaceholder: 'Ex: Mostratec Júnior',
    editionName: 'Edition Name',
    editionNamePlaceholder: 'type which will be the fair name',
    year: 'Year',
    yearPlaceholder: 'Type the fair year',
    format: 'Format',
    editionFormatInfo: 'Select in which format the fair will take place',
    inPerson: 'In person',
    virtual: 'Virtual',
    hibrid: 'Hibrid',
    affiliatedFair: 'Affiliated Fair',
    editionAffiliatedFairDescription: 'The following fields are related to the registration periods that will be made available for affiliated fairs.',
    mostratecAffiliationRegistrationPeriod: 'Period of registration of affiliation to Mostratec',
    start: 'Start',
    end: 'End',
    mostratecAffiliationRegistrationPeriodInfo: 'Enter the start and end period of the application period for affiliated fairs.',
    affiliatedFairsAnalysisPeriod: 'Analysis period of affiliated fairs',
    affiliatedFairsAnalysisPeriodInfo: 'Enter the starting and ending period of the review period for membership requests.',
    affiliatedFairsResponsePeriod: 'Response period to affiliated fairs',
    affiliatedFairsResponsePeriodInfo: 'Enter the starting and ending period to respond to membership requests.',
    mostratecProjectAfilliateFairRegistrationPeriod: 'Project registration period via Mostratec-affiliated fair.',
    mostratecProjectSelectionRegistrationPeriod: 'Project registration period via the Mostratec selection process.',
    mostratecProjectSelectionRegistrationPeriodInfo: 'Inform the initial and final period for project registration via the selection process',
    mostratecProjectAfilliateFairRegistrationPeriodInfo: 'Inform the initial and final period for project registration via the affiliated fair',
    PeriodInWhichMostratecEventWillTakePlace: 'Period in which the Mostratec event will take place',
    PeriodInWhichMostratecEventWillTakePlaceInfo: 'Enter the starting and ending period to respond to membership requests.',
    save: 'Save',
    edit: 'Edit',
    subscriptionRequest: 'Subscription Request',
    selectionProcess: 'Selection Process',
    appraisers: 'Apraisers',
    allRequests: 'All requests',
    openRequests: 'Open requests',
    affiliatedFairs: 'Affiliated fairs',
    acceptedRequests: 'Accepted requests',
    rejectedRequests: 'Rejected requests',
    search: 'Search',
    fairEdition: 'Fair edition',
    fairType: 'Fair type',
    subscription: 'Subscriptions',
    fairEditionPlaceholder: 'Search fair requests by edition',
    fairTypePlaceholder: 'Ex: Mostratec Elementary School, Mostratec Júnior',
    filter: 'Filter',
    subtitleDescription: 'Subtitle description',
    pendingReview: 'Pending analysis',
    underAnalysis: 'Under analysis',
    notAccepted: 'Not accepted',
    accepted: 'Accepted',
    waitingResponse: 'wainting for an answer',
    code: 'Code',
    requestStatus: 'Request status',
    countryState: 'Country/State',
    organizingInstitution: 'Organizing Institution',
    schoolType: 'School type',
    vacanciesNumberChildish: 'Child vacancies number',
    vacanciesNumberElementary: 'Elementary school vacancies number',
    vacanciesNumberHighSchool: 'High school vacancies number',
    form: 'Form',
    public: 'Public',
    particular: 'Partiular',
    schoolData: 'School data',
    schoolInstitutionData: 'Institution/school data',
    institutionName: 'Institution / school name',
    institutionNamePlaceholder: 'Enter the name of the institution / school',
    institutionResponsibleName: 'Name of the person in charge of the institution / school',
    institutionResponsibleNamePlaceholder: 'Enter the name of the person in charge of the institution/ school',
    educationalInstitutionCategory: 'Educational category',
    private: 'Private',
    emailContactPlaceholder: 'Enter contact email',
    next: 'Next',
    address: 'Address',
    zipCode: 'Zip code',
    uf: 'FU',
    number: 'Number',
    numberOptional: 'Number (Optional)',
    numberPlaceholder: 'Ex: 274',
    complement: 'Complement',
    complementOptional: 'Complement (Optional)',
    complementPlaceholder: 'Ex: Next to the supermarket',
    back: 'Back',
    site: 'Site',
    sitePlaceholder: 'Enter the website URL',
    fairCoordenatorName: 'Name of the fair coordinator',
    fairCoordenatorNamePlaceholder: 'Enter the name of the fair coordinator',
    currentFairFairTypePlaceholder: 'Ex: School',
    currentFair: 'Current fair',
    lastFairEdition: 'Last edition of the fair',
    lastFairEditionData: 'Data from the last edition of the affiliated fair',
    fairStatusChange: 'Change of Fair Status',
    fairStatusChangeWarning: 'Attention! You are changing the status active/inactive of this fair edition! By activating, you are enabling the receipt of registrations for the new edition of the registered fair. by deactivating, you are disabling receiving subscriptions for this edition.',
    affiliationRequestModalSendFormTitle: 'Are you ready to submit the affiliation request form?',
    affiliationRequestModalSendFormText: 'You have reached the end of filling out your project application. We advise you to review your information, because when you send your request it will no longer be possible to change them.',
    formSelectionRequestModalSendFormTitle: 'Ready to submit the application form?',
    formSelectionRequestModalSendFormText: 'You have reached the end of filling out your project application. We advise you to review your information, because when you submit your request, it will no longer be possible to change it (except for the project summary, which can be changed until the end of the application deadline).',
    yesContinue: 'Yes, continue',
    noCancel: 'No, cancel',
    failedToSendEmail: 'Failed to send email',
    regulament: 'Regulation',
    regulamentLink: 'https://www.mostratec.com.br/en/rules-of-mostratec/',
    enter: 'Enter',
    dataGeneral: 'General Data',
    vacanciesDetails: 'Vacancies',
    areYouSureTitle: 'WARNING: Are you sure about this?',
    areYouSureText: 'Upon confirming this message, all participation data for the affiliated fair will be deleted and the fair will be deactivated from Mostratec.',
    weakPassword: 'Weak password!',
    passwordsDontMatch: 'The informed passwords do not match',
    invalidEmail: 'Invalid email adress!',
    invalidZipCode: 'Zip Code not valid!',
    dateError: 'Ending date must greater or equal to the beginning one',
    nextFairError: 'The date of the next edition of the fair must be greater than the current date.',
    lastFairError: 'The date of the last edition of the fair must be less than the current date.',
    levelOfEducationError: 'Check at least one level of education',
    invalidDateFormat: 'Invalid data format',
    invalidEditionYear: 'The fair start year must be greater than or equal to the year of its edition',
    removeContact: 'Upon confirming this message, all contact data will be deleted.',
    sendEmailFairText: 'Upon confirming this message, an email will be sent to this affiliated fair with the result of the analysis.',
    submit: 'submit',
    lastFairEdStart: 'Start',
    lastFairEdEnd: 'End',
    calendarLocale: 'en-US',
    registrationFailedIcAlreadyExists: 'Registration failed, user already exists! Check the Identification Code entered',
    registrationFailedEmailAlreadyExists: 'Failed to register, user already exists! Check the entered e-mail',
    registrationFailedCpfAlreadyExists: 'Registration failed, user already exists! Check the identification number entered',
    exit: 'Exit',
    welcomeHomeHeader1: 'To participate of the ',
    welcomeHomeHeader2: ' fair, select by the options below and perform your pre-registration, where we will validate your data to continue in the desired process.',
    welcomeHomePaymentInfo1: 'Registration is free and you will only pay the participation fee if be accepted!',
    welcomeHomePaymentInfo2: 'We also have exemptions, if needed.',
    fairAffiliation: 'Fair\'s affiliation',
    welcomeHomeFairAffiliationText: 'If you represent any school, state, national or international fair and are a science enthusiast in the country and have good projects, inquire and affiliate your fair with Mostratec!',
    subscribeNow: 'Subscribe now!',
    projectRegistration: 'Project\'s registration',
    affiliatedFairSingle: 'Affiliated Fair',
    welcomeHomeProjectRegistrationText1: 'For projects accredited by an',
    welcomeHomeProjectRegistrationText2: 'or for submition in the',
    welcomeHomeProjectRegistrationText3: ': Soon it will be time to complete your registration, wait!',
    moreInformation: 'For more information, visit',
    illustrationsBy: 'Illustrations:',
    preRegistration: 'Pre-Registration',
    inputName: 'Input your name',
    inputFairName: 'Input the fair\'s name',
    inputCnpjCpf: 'Input your institution\'s CNPJ or CPF',
    inputEmailToConfirmRegistration: 'Input email for pre-registration confirmation',
    inputPasswordToLogin: 'Create a password to login to the system',
    passwordConfirm: 'Confirm your password',
    warningAllFieldsAreObligatory: 'All fields must be filled.',
    warningAllRequiredFieldsAreObligatory: 'All required fields must be filled',
    warningEmailAlreadyUsed: 'Failed to register, E-mail is already in use',
    warningCnjpCpfIdAlreadyUsed: 'Registration failed, identification number is already in use',
    warningTryLater: 'Error, please try again later',
    welcomeProjectHeader1: 'Before we continue, tell us...',
    welcomeProjectHeader2: 'Which of the following types of projects registration do you intend to proceed with?',
    //welcomeProjectRegistrationBySelectionProcess: 'For those who already have a scientific project and are interested in going through the selection process to participate in the fair independently.',
    welcomeProjectRegistrationBySelectionProcess: 'For projects that are interested in going through the selection process to participate in Mostratec independently.',
    byAffiliatedFair: 'By Affiliated Fair',
    //welcomeProjectRegistrationByAffiliatedFair: 'For those who wish to register and participate in the fair through the registered Affiliated Fairs.',
    welcomeProjectRegistrationByAffiliatedFair: 'For projects accredited through an affiliated fair to Mostratec.',
    teachingLevels: 'Teaching levels contemplated:',
    highSchoolTechnical: 'High School / Technical',
    elementarySchool: 'Elementary School',
    childEducation: 'Child education',
    responsibleStudentName: 'Name of the responsible student',
    inputTheResponsibleStudentName: 'Input the name of the student responsible for the project',
    projectRegistartionWarningCnjpCpfIdAlreadyUsed: 'Registration failed, user already exists! Check E-mail and CPF / CNPJ or Identification Number (if foreign)',
    // registrationFailedIcAlreadyExists: 'Registration failed, user already exists! Check the Identification Code entered',
    // registrationFailedEmailAlreadyExists: 'Failed to register, user already exists! Check the entered e-mail',
    registrationFailedCpfAlreadyExists: 'Registration failed, user already exists! Check the CPF / CNPJ entered',
    registrationFailedAlreadyExistsCheckBoth: 'Failed to register, user already exists! Check the e-mail and CPF entered',
    // exit: 'Exit',
    selectFairName: 'Select fair\'s name',
    inputYourCPF: 'Input yor CPF',
    vacanciesRequest: 'Request for vacancies/number of requested projects', // 'Vacancies Request',
    vacanciesRequestResponse: 'Granted vacancies / Project acceptance',
    panelFairVacanciesWaitWillBeAnalyzed: 'Wait. Your request for vacancies has been received by our system and will be analyzed below.',
    panelFairVacanciesWaitUnderAnalysis: 'Wait. Your request for vacancies is being analyzed and soon we will return with the answer by e-mail.',
    panelFairVacanciesUnsignedSubscription: 'Your subscription has not yet been finalized. Proceed with your registration by',
    clickingHere: 'clicking here',
    affiliationRequest: 'Request affiliation',
    thereIsNoData: 'There is no data',
    fairSelect: 'Select a fair',
    panelFairVacanciesDenied: 'Thank you for your request, but it was not possible to grant places for membership in this edition.',
    reason: 'Reason',
    fairPeriodicity: 'Periodicity of the fair',
    periodOfLastFair: 'Period of the last fair',
    periodForNextFair: 'Period for the next fair',
    to: 'to',
    nextFairDateIsConfirmated: 'Is the date for the next fair confirmed?',
    yes: 'Yes',
    no: 'No',
    nextFairFormat: 'Format of the next fair',
    editionsFairNumber: 'Number of editions of the fair',
    doesTheFairHaveCrc: 'Does the fair have a Scientific Review Committee (CRC)?',
    educationLevelsInTheFair: 'Levels of education participating in the fair:',
    childEducationCompleted: 'Child education',
    childEducationCompleted2024: 'Early Childhood Education - Preschool (students aged 4 to 6 years old)',
    elementarySchoolCompleted: 'Elementary School',
    highSchoolOrTechnicalCompleted: 'High School/Technical',
    childEducationategory0: 'Early Childhood Education - Preschool',
    elementarySchoolCategory1: 'Elementary School - Category 1 (1st to 3rd year)',
    elementarySchoolCategory2: 'Elementary School - Category 2 (4th to 6th year)',
    elementarySchoolCategory3: 'Elementary School - Category 3 (7th to 9th year)',
    // elementarySchoolCategory4: 'NO',
    elementarySchoolCategoryEja: 'Elementary School - Category 4 Youth and Adult Education YAE (any year)',
    highSchoolAndOrTechnical: 'High School and/or High School Technical Education',
    universityEducation: 'University education',
    users: 'Users',
    profileAccess: 'Access profiles',
    userSettings: 'User settings',
    status: 'Status',
    school: 'School',
    confirmationEmailSent: 'The confirmation email was sent successfully! To finalize your registration, access the email box informed on the form and confirm the data provided.',
    affiliatedFairStudent: 'Affiliated Fair Student',
    lastEdition: 'Last edition',
    aboutFair: 'About the fair',
    aboutSchool: 'About the School',
    responsibilityTerm: 'Responsibility Term',
    projectsNumber: 'Number of projects',
    teachersNumber: 'Number of teachers',
    schoolsNumber: 'Number of schools',
    male: 'Male',
    female: 'Female',
    totalStudents: 'Total students',
    projectsWithOneStudent: 'Number of projects with 1 student',
    projectsWithTwoOrThreeStudent: 'Number of projects with 2 or 3 students',
    generalInformation: 'General information',
    schoolNumberThatParticipatedOnFair: 'Number of schools that participated in your fair',
    otherAffiliatedFairParticipe: 'Other affiliated fairs participate',
    publicSchoolNumber: 'Number of public schools',
    privateSchoolNumber: 'Number of private schools',
    fairAffiliatesNumber: 'Number of fair affiliates',
    responsibilityTermParagraph1: 'I am aware of the data provided in the affiliation form, and they are in accordance with the fair held.',
    //responsibilityTermParagraph1: 'I am aware of and confirm the accuracy of the data provided in the registration form.',
    responsibilityTermParagraph2: 'I assume the commitment to accredit the project(s) according to the participation criteria and the regulations of Mostratec and/or Mostratec Júnior.',
    responsibilityTermParagraph3: 'I am aware that if the fair selects a project that is in disagreement with the above, the project will not be accepted and the affiliated fair may lose the right to renew the affiliation in the following year.',
    resonsablePersonForFillingIn: 'Name of the person responsible for filling in',
    positionFunctionAtFair: 'Position / Function at the fair',
    vacanciesListed: 'Vacancies listed',
    vacanciesRequested: 'Vacancies requested',
    vacanciesGranted: 'Vacancies granted',
    vacanciesExempt: 'Exempt vacancies',
    researchArea: 'Research areas',
    disabled: 'Disabled',
    deactivatedRequest: 'Your request has been deactivated.',
    requestDate: 'Request date',
    lastUpdate: 'Last update',
    registrationInProgress: 'Registration in progress',
    considerAllFieldObligatory: 'Consider all fields obligatory, unless the text (optional) appears in the title.',
    schoolEmail: 'School\'s E-mail',
    schoolEmailPlaceHolder: 'Input the school\'s e-mail',
    aboutYourFair: 'About the latest edition of the affiliated fair',
    generalInfos: 'General info',
    vacanciesRequestsInPlural: 'Vacancy Requests',
    checkTheData: 'Check the data provided!',
    inputInstitutionCepPlaceholder: 'Enter your institution\'s zip code',
    inputCepPlaceholder: 'Enter the zipcode',
    addressExample: 'Street, Avenue...',
    checkTheCep: 'Please enter a valid zip code',
    contactOneInformation: 'The contact\'s 1 information will be used for notifications regarding the Mostratec fair',
    bestEmail: 'Best Email - (Please provide an email that is frequently accessed, as this will be the means of contact with the affiliated fair)',
    inputContactEmail: 'Enter the contact email',
    main: '(Main)',
    addContact: 'Add contact',
    emailsMustBeDifferent: 'The emails entered must be different!',
    internationalFairType: 'International',
    schoolFairType: 'School (only projects from one school participates)',
    multiSchoolFairType: 'Multischool (projects from different schools participate, which may be from the same network or from different networks, including from different cities);',
    townFairType: 'Town (exclusively projects of the municipal education network participate);',
    townFairType2: 'Town - other networks (more than one school network participates: municipal and/or state and/or private)',
    regionalFairType: 'Regional',
    stateFairType: 'State',
    nationalFairType: 'National',
    whatsIsTheNextFairDate: 'Is the date for the next fair?',
    confirmated: 'Confirmated',
    forecastBecause: 'Expected, as the date is not yet confirmed',
    editionsFairNumberPlaceholder: 'Enter the number of editions',
    checkTheEducationalLevels: 'Check the education levels that participate in your fair:',
    specifyTheFollowingData: 'Provide the following information according to the education level(s) participating in your fair. If your fair does not include any of the education levels mentioned below, filling out the information is not necessary.',
    vacanciesNumberRequested: 'The number of vacancies requested here may not necessarily be the number of projects the fair can accredit. To find out how many projects the fair can recommend to participate in MOSTRATEC, you should wait for the request\'s response.', // 'The number of vacancies requested will not necessarily be the number of vacancies granted.',
    ifYourFairDoesNotIntendCoverAllTypes: 'If your fair does not intend to cover all types of education, it is not necessary to fill in all fields.',
    howManyProjects: 'How many projects?',
    howManyProjectsAbout: 'How many projects:',
    projectsNumberPlaceholder: 'Enter the number of projects',
    schoolsNumberPlaceholder: 'Enter the number of schools',
    teachersNumberPlaceholder: 'Enter the number of teachers',
    studentsNumberPlaceholder: 'Enter the number of students',
    projectsWithOneStudentPlaceholder: 'Enter the number of projects with 1 student',
    projectsWithTwoOrThreeStudentPaceholder: 'Enter the number of projects with 2 or 3 student',
    oneLevelIsNecessary: 'It is necessary to provide data for at least one level of education.',
    allFieldsSelectedMustBeCompleted: 'All fields at the selected level must be completed.',
    thereAreExistingSolicitations: 'There are existing affiliation requests already!',
    yearly: 'Yearly',
    biennial: 'Biennial',
    triennial: 'Triennial',
    totalOfSchoolsInYourFair: 'Total number of schools that participated in your fair',
    DidOthersAffiliatedFairsParticipate: 'Did other affiliated fairs participate in your fair?',
    didOthersAffiliatedFairsParticipateAbout: 'Other affiliated fairs participated',
    affiliatedFairsNumber: 'Number of affiliated fairs',
    affiliatedFairsNumberPlaceholder: 'Enter the number of affiliated fairs',
    vacanciesNumber: 'Number of vacancies/Number of projects',
    vacanciesNumberPlaceholder: 'Enter the desired amount',
    tickTheResearchArea: 'Tick the research area (Optional)',
    biology: 'Biology',
    biologyAndChemistry: 'Biology and Chemistry',
    sciences: 'Sciences (environmental, animal and plant)',
    computerScience: 'Computer Science',
    planetaryAndEarthSciences: 'Planetary and Earth Sciences, Mathematics and Physics',
    socialSciencesBehaviorArt: 'Social Sciences, Behavior and Art',
    engineering: 'Engineering (Electrical, Electronic, Mechanical)',
    engineeringAndMaterials: 'Engineering and Materials',
    environmentalManagement: 'Environmental Management',
    medicineAndHealth: 'Medicine and Health',
    otherResearchArea: 'Other? Enter the research area',
    categoryDesired: 'Inform the category/categories in which your fair would like to participate and the quantity of projects intended', // 'Inform the category(ies) in which your fair will participate and the number of vacancies desired',
    makeSureToFillIn: 'Make sure to fill in the fields.',
    readTheFollowingTerm: 'Read the following responsibility term',
    readAndAgree: 'I read and agree',
    resonsablePersonForFillingInPlaceholder: 'Enter the name of the person in charge',
    positionFunctionAtFairPlaceholder: 'Insert the position/function at the fair',
    sendForm: 'Submit Form',
    makeSureToReadAndAgree: 'Make sure to read and agree before proceeding.',
    requestSentSuccessfully: 'Request sent successfully! You will be redirected to the Fair Panel to follow your request!',
    errorSendingForm: 'Error sending form',
    emailsAlreadyInUseChangeThemOnForm3: 'Contact emails already in use in another request, change them on form 3 - Contacts',
    requestSentSuccessfullyTitle: 'Request sent successfully',
    VacanciesGrantedMustBeProvided: 'All vacancies granted fields must be provided',
    VacanciesGrantedValuesNotValid: 'Entered vacancies granted values not valid',
    requestDisabled: 'Request disabled',
    biochemistryAndChemistry: 'Biochemistry and Chemistry',
    other: 'Other',
    'inscricao em andamento': 'Registration in progress',
    'pendente de analise': 'Pending analysis',
    'em analise': 'Under analysis',
    'aguardando resposta': 'Awaiting response',
    'resposta enviada': 'Response sent',
    whatIsYourEducationalLevel: 'What is your educational level?',
    confirmYourEducationalLevel: 'Confirm your education level to proceed with the application form for your project in the active edition.',
    levelsOfEducational: 'Level of education',
    confirmAndContinue: 'Confirm and continue',
    levelsOfEducationalDescription: 'Enrollments of the Selection Process type are intended solely for secondary and technical education. Confirm if you belong to this level of education to proceed with your application to Mostratec 2021.',
    activeEditionForSubscription: 'Active edition for subscription',
    fillOutTheFormBelow: 'Fill out the form below according to your registration type to register your project.',
    toStartButton: 'TO START',
    automaticSavingModalText1: 'The system has automatic saving, each time you press the "next" button on the form, the previous page will be automatically saved.',
    automaticSavingModalText2: 'We guarantee you that your information is kept securely. To resume filling out the application, simply click on "yes, continue".',
    autoSave: 'Auto save',
    informWhichSchoolYouAreEnrolled: 'Inform which school / institution you are / were enrolled in during the development of the project or register a new one, if yours is not in the system.',
    nameOfSchoolOrInstitution: 'Name of school / institution',
    yourSchoolIsNotInTheSystem: 'Your school / institution is not in the system?',
    newInstitution: 'New Institution',
    institutionNameSingle: 'Institution Name',
    institutionNameSinglePlaceholder: 'Enter institution name',
    educationalInstitutionCategoryCompleted: 'Educational institution category',
    institutionPersonInCharge: 'Name of the person in charge of the institution',
    institutionPersonInChargePlaceholder: 'Enter the person in charge of the institution',
    IfThereIsNoCNPJLinkedToTheSchool: 'If there is no CNPJ linked to the school, then enter the identification number of the person responsible for the institution in the field above.',
    aboutStudents: 'About students',
    aboutAdvisors: 'About the advisors',
    othersInvolved: 'Others involved',
    researchPlan: 'Research plan',
    summaryForTheResearcher: 'Abstract for the researcher',
    fileUpload: 'File upload',
    projectName: 'Project Title',
    enterTheProjectName: 'Enter the project name',
    checkYourProjectResearchArea: 'Check your project\'s research area',
    art: 'Art',
    bio: 'Biology',
    eduPedago: 'Education and pedagogy',
    eletronicInfoRobo: 'Electronics, IT and robotics',
    enge: 'Engineering',
    filoso: 'Philosophy',
    fis: 'Physics',
    geo: 'Geography',
    his: 'History',
    incAcessTec: 'Inclusion, accessibility and technology',
    qui: 'Chemistry',
    lingPortLingStrangLit: 'Portuguese language, foreign language and literature',
    mat: 'Math',
    meioAmb: 'Environment',
    saud: 'Health',
    socio: 'Sociology',
    enterTheOtherSubarea: 'Enter the other subarea',
    enterTheSummary: 'Enter the summary',
    enterTheThema: 'Enter the thema',
    summary: 'Summary',
    theme: 'Theme',
    buttonContinue: 'Continue',
    tc_titulo: 'Consent Form for the Processing of Personal Data in compliance with Brazilian Law No. 13,709, of August 14, 2018, General Data Protection Law - LGPD.',
    tc_aviso: 'Please read all items below carefully before proceeding.',
    tc_p1: 'This form complies with the Personal Data Policy of the Liberato Salzano Vieira da Cunha Technical School Foundation and the General Data Protection Law (LGPD).',
    tc_p2: 'The data controller (Liberato Foundation) is responsible for any data that will be processed.',
    tc_p3: 'I declare to be aware of my RIGHTS regarding the collection and processing of personal data according to the General Data Protection Law (LGPD) No. 13,709, of August 14, 2018, and express MY CONSENT for the collection and processing of DATA in this form.',
    tc_p4: 'I declare to be aware that I will have ACCESS to the personal data collected at any time and that I may request a copy of this data; that I may RECTIFY my personal data collected at any time without restrictions; that I may request the ELIMINATION of the data collected here at any time; that I may contest the processing of the personal data collected here, for reasons related to different and/or particular situations; that I may WITHDRAW MY CONSENT at any time; that I may REQUEST DATA PORTABILITY and have it returned to me.',
    tc_p5: 'Granting of publicity:',
    tc_p6: 'a) I give permission to the MOSTRATEC Central Committee to use the provided information (names, project titles, educational institution, affiliated fairs, abstracts), photos, videos, brochures, among others, to publicize MOSTRATEC;',
    tc_p7: 'b) The media (printed, broadcasted, and visual) are authorized to use data or images for the purpose of promoting the event;',
    tc_p8: 'c) Finalists undertake to collaborate with the media and related entities to provide information for the promotion of the event and their projects;',
    tc_p9: 'd) Finalists give permission to the MOSTRATEC Central Committee for data sharing, such as names, contacts, educational institutions, titles, and abstracts of projects for award purposes, accreditations at other fairs, events, with research funding agencies and for the purpose of promoting research among partner institutions and co-organizers of MOSTRATEC;',
    tc_p10: 'e) The responsible parties for affiliated fairs give permission to the MOSTRATEC Central Committee for the disclosure of the names of the fairs and their organizing institutions, their responsible parties, and their respective contacts, for the purpose of promoting research among partner institutions and co-organizers of MOSTRATEC.',
    linkLei13709: 'https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm',
    DeclareAsRead: 'Warning: Please, declare as read the LGPD Consent Form for Personal Data Processing before proceding.',
    thereAreNoResearchAvaliable: 'There are no research areas available for this level of education.',
    importantTitle: 'Important!',
    revalidationByAdminText: 'After your confirmation, the previous analisis information will be lost and another avaliation will be needed. Wanna continue?',
    profile: 'Profile',
    edition: 'Edition',
    notAccept: 'Not accept',
    termLGPD: 'LGPD term',
    noResults: 'No results',
    neighborhoodPlaceholder: 'Enter the neighborhood',
    statePlaceholder: 'Enter the state',
    cityPlaceholder: 'Enter the city',
    registrationClosed: 'Registration closed',
    caseYouMissedTheOpportunity: 'In case you missed the opportunity, we await your registration in the next edition of Mostratec!',
    informAboutTheAdvisors: 'Inform below the data of the advisor and co-advisor, if any.',
    advisor: 'Advisor',
    coAdvisor: 'Co-advisor',
    graduation: 'Graduation',
    actingDiscipline: 'Acting discipline',
    enterTheGraduation: 'Enter the graduation',
    enterTheActingDiscipline: 'Enter the acting discipline',
    haveYouParticipatedAnyOtherMostratec: 'Have you participated in any other edition of Mostratec?',
    disableCoadvisor: 'Disable co-advisor?',
    disableCoadvisorModalP1: 'Are you sure you want to disable the co-advisor registration for this project? Upon inactivation, you will submit your project',
    whitoutCoadvisor: 'without a co-advisor.',
    disableCoadvisorModalP2: 'To enable it again, just reactivate it by the',
    caseThereAreOthersInvolvedDescription: 'The fields below must be filled in if there are other people involved in the project who will participate in MOSTRATEC, but have not developed the project. Example: Head of Delegation, Parents, Student Observer, etc.',
    caseThereAreOthersInvolved: 'Are there other people involved in this project?',
    addNewInvolved: 'Add new involved',
    person: 'Person',
    designation: 'Designation',
    enterDesignation: 'Enter designation',
    designationExample: 'Example: Director, Head of Delegation, Parents or Guardians, etc.',
    enterTheSpecializedService: 'Enter the specialized service',
    disablePeopleInvolved: 'Disable people involved?',
    disablePeopleInvolvedParagraph: 'Are you sure you want to disable the registration of other people involved in this project? When inactivating, you will lose the content entered in your fields and this action cannot be undone.',
    projectAbstractIntro: 'The abstract will be forwarded to the evaluators and published on the Mostratec website and must present:',
    projectAbstractIntroA: 'a) objective of the project or of the experiment performed;',
    projectAbstractIntroB: 'b) the procedures used;',
    projectAbstractIntroC: 'c) observations / data / results (briefly);',
    projectAbstractIntroD: 'd) conclusions / applications;',
    projectAbstractIntroE: 'e) the keywords.',
    enterTheProjectAbstract: 'Enter the project abstract',
    youCanEditTheAbstract: 'You will be able to edit your project abstract after submitting your application until the application deadline closes.',
    reviewProjectData: 'Review key information from your research project. If any data below is incorrect, please feel free to edit it between the past steps of the application process. Remember that once you send the registration request, you will not be able to change the data.',
    students: 'Students',
    researchProjectStartDate: 'Research project start date',
    plannedEndDate: 'Planned end date',
    willYourProjectBeContinued: 'Will your project be continued?',
    plannedDateForStartingExperimentation: 'Date on which the research continuity stage started experimentation/data collection',
    willYouConductInLaboratory: 'Have you decided to research in a laboratory, institute or regulated industry?',
    willTheContinuityIncludeNewStudents: 'Did the continuation stage of the project include new students?',
    willTheContinuityIncludeNewResearch: 'Did the project continuity stage have another area or sub-area of ​​research?',
    researchInstituitionIndustry: 'Research institution or regulated industry',
    telephone: 'Telephone',
    locationsWhereTheResearchWillBeConducted: 'Locations where the research will be conducted',
    field: 'Countryside',
    atHome: 'At home',
    itemsThatApplyToYourResearchTrials: 'Items that apply to your research trials',
    humanBeings: 'Human beings',
    vertebrateAnimals: 'Vertebrate animals',
    potentiallyHazardousBiologicalAgents: 'Potentially Hazardous Biological Agents',
    hazardousChemicalSubstances: 'Dangerous Chemical Substances, Activities or Equipment',
    substancesControlledByLaw: 'Substances Controlled by Law',
    addNewStudent: 'Add new student',
    disableStudentInvolved: 'Disable involved student?',
    disableStudentInvolvedInfo: 'Are you sure you want to disable the registration of other students involved in this project? When inactivating, you will lose the content entered in your fields and this action cannot be undone.',
    filesMustBeSent: 'Files must be sent by the end of registration.',
    requiredFiles: 'Required files',
    downloadForms: 'Download forms',
    fileFormatMustBePDF: 'The file format must be in PDF',
    workPresentation: 'Work presentation',
    fileFormatMustBeMP4: 'The file format must be MP4',
    dragAndDropTheFile: 'Drag and drop the file or',
    select: 'select',
    invalidFile: 'Invalid file. Try again with correct format',
    specificFiles: 'Specific files',
    filesRequestBelowInfo: 'The files requested below, correspond to your research and were defined according to your answers on the page',
    continuityProject: 'Continuity project',
    riskAssessment: 'Risk assessment',
    qualifiedScientist: 'Qualified scientist',
    projectContinuityFileFormat: 'For project continuity. The file format must be in PDF',
    vertebrateAnimalsFileFormat: 'For research involving vertebrate animals add Form 5. The file format must be in PDF',
    humanBeingsFileFormat: 'For research involving human subjects add Form 4. The file format must be in PDF',
    dangerousSubstancesFileFormat: 'For research with potentially hazardous biological agents add Form 6A. The file format must be in PDF',
    animalTissueFileFormat: 'For research with human or vertebrate animal tissues add Form 6B. The file format must be in PDF',
    instituitionFileFormat: 'For research at a research institution or regulated industry add Form 1C. The file format must be in PDF',
    submitRegistration: 'Submit application',
    ifYouChangeTheEducationalLevel: 'Warning: If you change the level of education, you will lose the data previously saved in the form.',
    saveAndAdvance: 'Save and advance',
    selectEtc: 'Select...',
    selectLevel: 'Select a level',
    confirmWhichOfOurAffiliatedFairsIsYou: 'Confirm which of our affiliated fairs you will request the registration of your project. If your fair does not appear in our system, please contact it.',
    fair: 'Fair',
    fairNamePlaceholder: 'Fair name',
    thereAreNoFairsToYourLevel: 'There are no fairs for your level of education',
    coexistenceContract: 'Coexistence contract',
    //coexistenceContractInfo: 'I am aware of the data informed in the registration form, which is in accordance with the fair held. I undertake to send the coexistence contract file to Mostratec Júnior, whether virtual or in person.',
    coexistenceContractInfo: 'I am aware of and confirm the accuracy of the data provided in the registration form.',
    howWillTheContractBeDelivered: 'How will the contract be delivered?',
    loading: 'Loading',
    resultsAndConclusions: 'Results and conclusions',
    resultsAndConclusionsPlaceholder: 'Enter the research results and conclusions',
    resultsAndConclusionsTextInfo: 'It purpose is to demonstrate what was discovered or understood by the authors from the conclusion of the research.',
    researchProblem: 'Research problem',
    projectCategory: 'Project category',
    neighborhood: 'Neighborhood',
    grade: 'Grade',
    childreanTShirt: 'Children\'s t-shirt',
    elementarySchoolCategory1Single: '1st to 3rd year',
    elementarySchoolCategory2Single: '4th to 6th year',
    elementarySchoolCategory3Single: '7th to 9th year',
    maleTShirt: 'Male T-shirt',
    sizeSix: '4-5 years old - Size 6',
    sizeEight: '5-7 years old - Size 8',
    sizeTen: '7-9 years old - Size 10',
    sizeTwelve: '11-12 years old - Size 12',
    sizeFourteen: '13-14 years old - Size 14',
    //-------------------------------------------------------
    tsShirt01: 'Child - size 8',
    tsShirt02: 'Child - size 10',
    tsShirt03: 'Child - size 14',
    tsShirt04: 'Adult - shirt size S',
    tsShirt05: 'Adult - shirt size M',
    tsShirt06: 'Adult - shirt size L',
    tsShirt07: 'Adult - shirt size XL',
    tsShirt08: 'Adult - shirt size XXL',
    //-------------------------------------------------------
    class: 'Class',
    quartet: 'Quartet',
    nursery: 'Nursery',
    maternal: 'Maternal',
    kindergarten: 'Kindergarten',
    lessThan1Year: 'Less than 1 year',
    OneTwoYears: '1 to 2 years',
    ThreeFourYears: '3 to 4 years',
    FiveSixYears: '5 to 6 years',
    inAnalysis: 'Hold on. Your registration request is under review and we will get back to you via email shortly.',
    advisorIsMandatory: 'Advisor is mandatory',
    landlineOrCellphone: 'Telephone (landline or cell phone)',
    studentResponsibleForPreRegistration: 'Student responsible for pre-registration and project registration.',
    peopleInvolved: 'People Involved',
    advisors: 'Advisors',
    notAcceptedText: 'Thank you for your interest in participating in MOSTRATEC-LIBERATO. Unfortunately, your project was not selected. You can try again next year.',
    registrationCompleteText: 'Your subscription has been successfully completed. We appreciate your participation and see you at MOSTRATEC-LIBERATO!',
    isTimeToRegister: 'It\'s time to register!',
    pendingAnalysis: 'Hold on. Your application has been received by our system and will be reviewed by the Scientific Review Committee - CRC thereafter.',
    waitingReply: 'Awaiting response',
    waitingPaymentText: 'Your application has been accepted by the CRC! To finish and participate in the active edition of Mostratec, we ask you to pay the registration fee.',
    waitingEmailSent: 'Waiting for e-mail to be sent',
    requestApproved: 'Revised project!',
    sendEmailDescription: 'When you want to notify students of their status, just click "send email" and an email will direct them to access the Mostratec platform and check their new status.',
    sendEmail: 'SEND EMAIL',
    emailSent: 'Email sent',
    emailSentStudents: 'Feedback email to students has been sent successfully!',
    statusUpdateAfterAnalysis: 'The status of the requests will be updated according to the analysis performed.',
    presentationLink: 'Presentation video link',
    replySentByEmail: 'Reply sent by email',
    sentEmailDescription: 'Revised project! The email notifying the student(s) and affiliated fair sponsor of the status has already been sent. Now the student(s) or/and the affiliated Fair will be able to access the Mostratec platform, check their new status and manage registrations.',
    choose: 'Select',
    all: 'all',
    draft: 'Draft',
    newStatus: 'New status',
    registrationStatus: 'Registration status',
    noData: 'No data!',
    succesfullySaved: 'Successfully saved!',
    inputValidUrl: 'Input a valid URL',
    loadingMightTakeWhile: 'Loading... It might take a while',
    presentationVideoInformation: 'You can upload your video either as a file or as a YouTube link. You have until the registration deadline to do so.',
    documentsLimit100Videos300: '100MB size limit for documents and 300MB for videos, each file',
    videoFormatMustBeMP4: 'Video format must be MP4',
    admProjects: 'Administrative panel',
    projectRanking: 'Project ranking',
    indicators: 'Indicators',
    highFinalists: 'High School Finalists',
    middleFinalists: 'Elementary School Finalists',
    childishFinalists: 'Junior High School Finalists',
    mostratecOverview: 'Mostratec overview',
    subtitleRS: 'Rio Grande do Sul registered projects',
    subtitleBR: 'Brazil registered projects',
    subtitleEX: 'Foreign registered projects',
    publicSchools: 'Public schools',
    privateSchools: 'Private schools',
    NumberParticipatingProjects: 'Number of participating projects:',
    selectStateBeforeSave: 'Select a status before saving!',
    projectCode: 'Project code',
    // clickingHere: 'Clicking here',
    enrollmentInProgress: 'Your application has not yet been finalized. Proceed with your registration',
    projectApplicationPeriodClosed: 'Project application period closed!',
    errorLoadingInformation: 'Error loading information',
    institutionSchoolPhone: 'Institution/school phone (Numbers only)',
    enterInstitutionSchoolPhone: 'Enter phone number (Numbers only)',
    saveAndContinue: 'Save and continue',
    saveAndSend: 'Save and Send',
    selectSituation: 'Select situation',
    reviewCanceled: 'Registration canceled',
    identificationNumber: 'Identification number',
    initialRegisterIdentificationNumber: 'Input your identification number',
    cpf: 'CPF',
    cpfStudent: 'Student identification number',
    checkYourProjectNewResearchSubarea: 'Check your project\'s new research subarea',
    youCanADDTheVideo: 'You will be able to add the video link until the end of registration',
    orEnterTheYoutubeLink: 'Or enter the youtube video link in the field below.',
    presentationLink: 'Insert the link to the project presentation video',
    projectSevenFileFormat: 'For project continuity add Form 7. The file format must be in PDF',
    formRiskAssessment: 'For risk assessment add Form 3.The file format must be in PDF',
    formQualifiedScientist: 'For Qualified Scientist add Form 2. File format must be in PDF',
    enterUserEmailToPasswordRecover: 'Enter the user e-mail for the password recovery to be accomplished. After clicking "send", check your e-mail inbox.',
    enterRecoverEmail: 'Enter recovery e-mail',
    confirmYourEmail: 'Confirm your e-mail',
    emailSuccessfullySent: 'E-mail successfully sent!',
    passwordRecoveryEmailSent: 'Password recovery e-mail sent',
    passwordRecoveryEmailSuccessfullySentCheckEmailChangePassword: 'The password recovery was successfully sent to your e-mail inbox. Check your e-mail and change the password.',
    acceptanceRequired: 'In order to use the plataform, you must accept the',
    dataProtectionTerm: 'Consent Form for Personal Data Processing.',
    followTheLink: 'Follow the link bellow to continue.',
    attention: 'Warning!',
    descriptionTheme: 'Describe the aspect of the subject you want to address, prove or develop.',
    yae: 'Adult education',
    fileFormatPDF: 'The file extension must be PDF. You are gonna be able to send it after concluding your enrollment, if you do not have it yet.',
    optionalLabel: '(Optional)',
    azureMessageFail: 'Please, try again later. The service is unavailable right now.',
    errorLabel: 'Error',
    errorDuringSaving: 'Error during saving',
    successfullRequest: 'Request successfully sent! You are gonna be redirected to the enrollment initial page!',
    projectAlreadySaved: 'Project already saved!',
    schoolSaved: 'School already saved!',
    responsibleName: 'Name of person in charge',
    responsiblePerson: 'Responsible',
    parentageOfResponsible: 'Parentage of the person responsible',
    adultsName: 'Enter the adult name',
    insertParentage: 'Enter parents parentage',
    registrationType: 'Registration type',
    researchAreaSingular: 'Search area',
    requiredJustify: 'Justification is mandatory',
    selectionSituation: 'Please, select project status',
    enrollment: 'Enrollment',
    value: 'Value',
    food: 'Meal',
    finalValue: 'Final value',
    infoAboutTypePayment: 'How would you like to make the payment?',
    payOnAccreditation: 'Pay on accreditation',
    registrationFeeAndFood: 'Registration fee and Food',
    nationalFormPaymentInfoL1: '',
    nationalFormPaymentInfoL2: '',
    nationalFormPaymentInfoL3: '',
    nationalFormPaymentInfoL4: '',
    foreignFormPaymentInfoL1: '',
    foreignFormPaymentInfoL2: '',
    foreignFormPaymentInfoL3: '',
    foreignFormPaymentInfoL4: '',
    registrationFee: 'Registration fee',
    foreign: 'Foreign',
    noMeal: 'No meal',
    oneMeals: '1 meal',
    twoMeals: '2 meals',
    threeMeals: '3 meals',
    fourMeals: '4 meals',
    fiveMeals: '5 meals',
    sixMeals: '6 meals',
    sevenMeals: '7 meals',
    approvalPayment: 'payment must be made after approval',
    chooseSchoolType: 'Choose school type',
    studentMustBeSelected: 'At least one student must be selected',
    selectNumberOfMeals: 'Select the number of meals',
    participationNotSelected: 'Participation not selected',
    selectPaymentMethod: 'Select payment method',
    discountPayment: 'Discount cannot be greater than the total amount',
    saveInformation: 'Save',
    participateInThisProject: 'Yes, I participate in this project',
    alreadyParticipateInAnotherProject: 'I already participate in another project',
    willNotParticipateInMostratec: 'I will not participate in Mostratec',
    cannotPayMercadoPago: 'Cannot possible to pay with Mercado Pago, Total = BRL 0,00',
    finish: 'Finish',
    projects: 'Projects',
    placeholderSearchBar: 'Search by assigne name, project title or project id',
    buttonAllTickets: 'Print all of tickets',
    commitNote: 'Commitment note (only city halls)',
    rulesOfConduct: 'Rules of conduct',
    alreadyInUse: 'This identification number already in use',
    warningForMultiple: 'WARNING: Register only ONE project per browser session. Opening more than one browser tab / window will cause conflict/mixing of data between projects and will make registration unfeasible.',
    cohabitationAgreementRequired: 'Upload of the cohabitation agreement required.',
    sebraeTitle: 'The following question is only for projects from residents in Brazil',
    sebraeQuestion1: '',
    sebraeQuestion2: '',
    sebraeResponse0: 'My project does not originate from Brazil',
    sebraeResponse1: '',
    sebraeResponse2: '',
    categoryFormSelection: 'Education Level/Category',
    paymentDateLimit: 'The payment deadline via Mercado Pago is on '
  }
}
