<template>
  <div class="container">
    <h3 :class="[{red_heading: color === 'red'},{green_heading: color === 'green'},{colored_heading: color === 'blue'},{heading: !color}]"
      v-if="isMoney === true"
    >
      R$
    </h3>
    <span :class="[{red_content: color === 'red'},{green_content: color === 'green'},{colored_content:  color === 'blue'},{content: !color}]">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'HeadingThree',
  props: {
    isMoney: Boolean,
    color: String
  }
}
</script>

<style lang="scss" scoped>
.colored_heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #1B778B;
}

.green_heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #05AB5B;
}

.red_heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #D93025;
}

.colored_content {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #1B778B;
}

.green_content {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #05AB5B;
}

.red_content {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #D93025;
}

.container {
  display: flex;
  gap: 5px;
}

.heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #303030;
}

.content {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #303030;

}
</style>
