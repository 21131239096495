<template>
  <div>
    <table>
      <thead class="head">
        <tr>
          <template v-for="(item, index) in header">
            <th :key="index">{{ item }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in data">
          <tr :key="item.id">
            <template v-for="(un, indexItem) in item">
                <td :key="indexItem">{{un || '-'}}</td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'GenericTable',
  data() {
    return {
      newPage: 1
    }
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: []
    },
    header: {
      type: Array,
      required: true,
      default: []
    }

  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  thead {
    background-color: transparent;
    color:#1B778B;
    tr {

      th {
        background-color: #DBE9EC;
        &:first-child {
          border-radius: 10px 0 0 0;
        }
        &:last-child {
          border-radius: 0 10px 0 0;
        }
      }
    }
  }

  tbody {
    tr {

      background-color: #F6F6F6;
      &:nth-child(even) {
        background-color: #FFF;
      }
    }
  }

  thead, tbody {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
  }
}

tbody tr td, thead tr th {
  padding: 0 17px;
  height: 61px!important;
}

.center {
  text-align: center;
  cursor: pointer;

  img {
    max-width: 24px;
    width: 100%;
  }
}

.head {
  background-color: red !important;
}
</style>
