<template>
  <div>
      <v-stepper alt-labels>
      <v-stepper-header>
        <template v-for="(step, index) in steps">
          <v-stepper-step :complete="currentStep > (index + 1)" :step="index + 1" :key="index">
            {{step.message}}
          </v-stepper-step>
          <v-divider v-show="index != steps.length" :key="index" />
        </template>
      </v-stepper-header>
    </v-stepper>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    steps: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback--attention {
  @include d-flex-between;
  border-radius: 17px;
  box-shadow: 0px 3.81047px 3.81047px rgba(0, 0, 0, 0.1);
  color: $white;
  margin-right: 10px;
  max-width: 342px;
  padding: 10px 16px;
  position: fixed;
  bottom: 40px;
  left: 5%;

  .wrapper_text {
    @include d-flex-center;
  }

  .icon-info {
    height: 23px;
    width: 23px;
  }

  .icon-close {
    height: 12px;
    width: 12px;
    cursor: pointer;
  }

  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 0px 15px;
    text-align: left;
  }
}

</style>
