<template>
  <div class="wrapper">
    <transition appear mode="out-in">
    <keep-alive>
      <component
        v-if="['tableListSolicitations', 'tableListProjectSolicitations'].includes(fields)"
        :is="fields"
      />
    </keep-alive>
  </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { TableListSolicitations, TableListProjectSolicitations } from '@/components/organisms'

export default {
  name: 'ListSolicitations',
  components: { TableListSolicitations, TableListProjectSolicitations },
  props: { fields: String },
  methods: {
    ...mapActions({
      add: 'list/add',
      changeLang: 'changeLanguage'
    })
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: #FDFDFD;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  margin-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 25px;
  width: 100%;
}
</style>
