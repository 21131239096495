<template>
  <div class="geral">
    <div>
      <div>
        <div class="legend">
          <div
            v-if="appear"
            :class="{disabled: pendingRequests(), button: !pendingRequests()}"
            @click="go"
          >
            {{$t('affiliationRequest')}}
            <img :src="require('@/assets/add-icon.svg')" />
          </div>
          <feedback-warning
            v-if="displayWarning"
            :text="textWarning"
            :onClick="resetWarning"
            color="#ff9800"
          />
          <div class="legends-status">
            <span>{{$t('subtitleDescription')}}:</span>
            <div>
              <Status :status="'pendente de analise'" :active="true" :is_legend="true" />
            </div>
            <div>
              <Status :status="'em analise'" :active="true" :is_legend="true" />
            </div>
            <div>
              <Status :status="'nao aceita'" :active="true" :is_legend="true" />
            </div>
            <div>
              <Status :status="'aceita'" :active="true" :is_legend="true" />
            </div>
            <div>
              <Status :status="'inscricao em andamento'" :active="true" :is_legend="true" />
            </div>
            <div>
              <Status :status="'desativada'" :active="true" :is_legend="true" />
            </div>
            <div>
              <Status :status="'inscricao cancelada'" :active="true" :is_legend="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FALTA RESPONSIVIDADE PARA TELAS MAIORES -->
    <div class="container-box">
      <div class="fairs">
        <template v-if="fairs.length > 0">
          <template v-for="fair in fairs">
            <div :key="fair.id" @click="fairIdSelected(fair)">
              <Card :data="fair" :open="selected" />
            </div>
          </template>
        </template>
        <template v-else>{{$t('thereIsNoData')}}</template>
      </div>
      <div class="fairs-detail">
        <div class="header">
          <template v-for="(menu, item) in menus">
            <div
              :class="{ 'active': index == item }"
              @click="changePanel(item)"
              :key="item"
            >{{ $t(menu) }}</div>
          </template>
        </div>
        <div class="body">
          <template v-if="fairSelected.id">
            <transition appear mode="out-in">
              <keep-alive>
                <component
                  :is="validChange(components[index], fairSelected)"
                  :fairInfo="fairSelected"
                  @changeElement="e => changeElement(e)"
                />
              </keep-alive>
            </transition>
          </template>
          <template v-else>{{$t('fairSelect')}}</template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import jwt_decode from 'jwt-decode'
import { Card } from '@/components/atoms/Cards'
import { Status } from '@/components/atoms/Status'
import { TextWarning, FeedbackWarning } from '@/components/atoms/'
import {
  RequestVacancies,
  CurrentFair,
  LastEdition,
  TermResponsability,
  AboutFair,
  AboutSchool
} from '@/components/organisms/Panels'

export default {
  name: 'panel-fair-vacancies-template',
  data() {
    return {
      currentStatus: '',
      displayWarning: false,
      appear: true,
      selected: 0,
      fairSelected: [],
      fairs: [],
      mostratec: [],
      index: 0,
      menus: [
        'vacanciesRequestResponse',
        'currentFair',
        'lastEdition',
        'aboutFair',
        'aboutSchool',
        'responsibilityTerm'
      ],
      components: [
        'RequestVacancies',
        'CurrentFair',
        'LastEdition',
        'AboutFair',
        'AboutSchool',
        'TermResponsability'
      ],
      textWarning: ''
    }
  },
  components: {
    RequestVacancies,
    CurrentFair,
    LastEdition,
    TermResponsability,
    AboutFair,
    AboutSchool,
    Card,
    Status,
    TextWarning,
    FeedbackWarning
  },
  mounted() {
    this.getMostratec()
    this.getAllFairs()
  },
  methods: {
    validChange(componente, fair) {
      if (fair.status === 'inscricao em andamento') {
        this.index = 0
        return componente
      }
      return componente
    },
    validButton(fairs) {
      const date = new Date()
      fairs.forEach((element) => {
        if (date.getFullYear() === element.year_edition) {
          this.appear = false
        }
      })
    },
    pendingRequests() {
      if (this.fairs.length) {
        const currentMostratecId = this.mostratec.id
        for (const request of this.fairs) {
          if (request.mostratec_id === currentMostratecId) {
            return true
          }
        }
        return false
      }
    },
    go() {
      if (this.pendingRequests()) {
        this.displayWarning = true
        this.textWarning = 'thereAreExistingSolicitations'
      } else this.$router.push({ name: 'FormRequest' })
    },
    resetWarning() {
      if (this.displayWarning) this.displayWarning = false
    },
    changeElement(e) {
      this.currentStatus = e
    },
    async fairIdSelected(fair) {
      try {
        if (fair.id && fair.status !== 'inscricao em andamento') {
          const { data: response } = await this.$http.get(`/fair/${ fair.id }`)
          const { data: response2 } = await this.$http.get(`/fair-projects/${ fair.id }`)

          const fairInfo = {
            ...response,
            acceptedProjects: response2
          }
          this.fairSelected = fairInfo
          this.selected = fair.id
        } else {
          this.fairSelected = fair
          this.selected = fair.id
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getAllFairs() {
      try {
        const { data: response } = await this.$http.get('/fair')
        this.fairs = response
        if (window.localStorage.getItem('fairAffiliatonData')) {
          const user_id = jwt_decode(localStorage.getItem('token'))
          const userInfo = await this.$http.get(`/user/${ user_id.uid }`)
          const subscriptionInProgress = JSON.parse(window.localStorage.getItem('fairAffiliatonData'))
          subscriptionInProgress.status = 'inscricao em andamento'
          subscriptionInProgress.year_edition = this.mostratec.year_edition
          subscriptionInProgress.mostratec_id = this.mostratec.id
          subscriptionInProgress.id = this.fairs.length + 1
          subscriptionInProgress.fair_name = userInfo.data.user.pre_registration_fairs.fair_name
          this.fairs.push(subscriptionInProgress)
        }
        this.validButton(this.fairs)
      } catch (error) {
        console.error(error)
      }
    },
    async getMostratec() {
      try {
        const { data: response } = await this.$http.get('/current-mostratec')
        this.mostratec = response
      } catch (error) {
        console.error(error)
      }
    },
    changePanel(index) {
      this.index = index
    }
  }
}
</script>

<style lang="scss" scoped>
.geral {
  overflow: hidden;
  width: 100%;
  .legend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border-radius: 10px;
    margin-top: 30px;
    min-height: 86px;
    padding-left: 15px;
    white-space: nowrap;
    .button,
    .disabled {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      width: 200px;
      height: 40px;
      font-family: "Mulish", sans-serif;
      color: $white;
      font-weight: 600;
      font-size: 16px;

      img {
        margin-left: 10px;
        height: 15px;
        width: 15px;
      }
    }
    .button {
      background-color: $primary;
    }
    .disabled {
      background-color: #afaeae;
    }
    span {
      font-family: "Mulish", sans-serif;
      font-size: 16px;
      color: $almostBlack;
      margin-right: 15px;
    }
    .legends-status {
      flex-wrap: wrap;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        margin-top: 10px;
        margin: 0 10px;
        margin-bottom: 5px;
      }
    }
  }
}
.container-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 30px 0;
  .fairs {
    width: 100%;
    width: 23vw;
  }
  .fairs-detail {
    width: 100%;
    max-width: 71vw;
    background-color: $white;
    border-radius: 20px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 57px;
      box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
      div {
        cursor: pointer;
        font-size: 14px;
        display: flex;
        font-weight: 400;
        justify-content: center;
        flex: 1;
        align-items: center;
        text-align: center;
        color: $primaryDark;
        height: 100%;
        transition: 0.1s ease-in-out;
        &.active {
          font-weight: bold;
          border-bottom: 3px solid $primary;
        }
      }
    }
    .body {
      padding: 32px 107px;
    }
  }
}
</style>
