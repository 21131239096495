<template>
  <div class="container-app">
    <header-default @menuSelected="menuSelected" />
    <edition-template v-if="route == 'Edition'" :fields="fields" />
    <router-view :fields="fields"></router-view>
  </div>
</template>

<script>
import { EditionTemplate } from '@/components/templates'
import { HeaderDefault } from '@/components/organisms'

export default {
  name: 'Edition',
  components: { EditionTemplate, HeaderDefault },
  data() {
    return {
      fields: 'allEditions'
    }
  },
  computed: {
    route() {
      return this.$route.name
    }
  },
  updated() {
    if (!['general', 'vacancies', 'allEditions'].includes(this.fields) && this.route == 'Edition') {
      this.fields = 'allEditions'
    }
  },
  methods: {
    menuSelected(e) {
      this.fields = e
    }
  }
}
</script>
