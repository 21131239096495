import { INCREMENT } from '../mutation-types'

const state = () => ({
  count: 5
})

const getters = {
  countDone: (state) => state.count
}

const actions = {
  add(context, payload) {
    context.commit(INCREMENT, payload)
  }
}

const mutations = {
  [INCREMENT](state, payload) {
    state.count += payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
