<template>
  <div class="status-wrapper">
    <div :class="`badge ${ active ? theme : 'grey_neutral'}`" :click="$t(click)">
      <v-icon :color="colourPicker[active ? theme : 'grey_neutral']" small>mdi-information-outline</v-icon>
      <span>{{ $t(statusText) }}</span>
    </div>
    <div class="tooltip-wrapper">
      <div :class="`tooltip ${isTooltipWidthSameAsBadge && 'badge-width'}`" v-if="tooltipText">
        <span>{{ $t(tooltipText) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusWithTooltip',
  props: {
    statusText: String,
    tooltipText: String,
    isTooltipWidthSameAsBadge: Boolean,
    theme: String,
    click: Function,
    active: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      colourPicker: {
        grey_neutral: '#303030',
        warn: '#A76F00',
        blue_analisis: '#006076',
        red_reject: '#950000',
        orange_inactive: '#bd5805f1',
        green_accept: '#046619',
        purple_waiting: '#8E24AA',
        blue_send: '#0E5671'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status-wrapper {
  position: relative;
  &:hover {
    & > .tooltip-wrapper {
      visibility: visible;
    }
  }

  & > .tooltip-wrapper {
    position: absolute;
    visibility: hidden;
    z-index: 2;
    & > .tooltip {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 230px;
      background: #555555;
      border-radius: 2px;
      padding: 6px 10px;

      &.badge-width {
        width: 100%;
      }

      ::after {
        content: "";
        position: absolute;
        bottom: 85%;
        left: 18px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555;
        transform: rotate(45deg);
        z-index: -1
      }

      & > span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: white;
      }
    }
  }

  & > .badge {
    @include d-flex-center;
    display: inline-flex;
    border-radius: 49px;
    height: 28px;
    padding: 0px 10px;
    white-space: nowrap;
    font-weight: bold;
    font-size: 12px;

    span {
      margin-left: 5px;
    }
  }

  .grey_neutral {
    background-color: #E5E5E5;
    color: #303030;
  }

  .warn {
    background-color: $warning;
    color: #A76F00;
  }

  .blue_analisis {
    background-color: $blueLight;
    color: $blueStrong;
  }

  .red_reject {
    background-color: $danger;
    color: $dangerStrong;
  }

  .orange_inactive {
    background-color:  #f8ad5efd;
    color: #bd5805f1;
  }

  .green_accept {
    background-color: #7CD18E;
    color: $sucessStrong;
  }

  .purple_waiting {
    background-color: #DDD3E0;
    color: #8E24AA;
  }

  .blue_send {
    background-color: #C2C8FF;
    color: #0E5671;
  }
}
</style>
