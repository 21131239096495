<template>
  <div class="home">
    <form-subscribe-fair-project-template />
  </div>
</template>

<script>
import { FormSubscribeFairProjectTemplate } from '@/components/templates'

export default {
  name: 'FormViaFairView',
  components: {
    FormSubscribeFairProjectTemplate
  }
}
</script>
