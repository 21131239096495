<template>
  <div class="container-app">
    <div class="wrapper">
      <img src="@/assets/padlock.png" alt="Redefinição de senha" class="padlockImage"/>
      <paragraph class="paragraph mb-7">
        Para sua segurança, evite utilizar sua data de nascimento, CPF, matrícula, número de telefone, etc.
      </paragraph>

      <input-default
        :class="[{warning: checkErrorIfContains('password')}, 'mb-7']"
        label='Nova senha'
        id="password"
        placeholder='Insira a nova senha'
        type="password"
        v-model="form.password"
        @input="resetErrors"
      />
      <input-default
        :class="[{warning: checkErrorIfContains('confirmPassword')}, 'mb-7']"
        label='Confirmação da nova senha'
        id="confirmPassword"
        placeholder='Confirme a nova senha'
        type="password"
        v-model="form.confirmPassword"
        @input="resetErrors"
      />
      <div class="flex">
        <button-default :onClick="checkForm" class="button">{{$t('save')}}</button-default>
      </div>

      <feedback-warning
        v-if="errors.length"
        :text="textWarning"
        :onClick="resetErrors"
        color="#ff9800"
      />
      <modal-default title="Senha alterada com sucesso!" @closeModal="() => redirectToPage('/login')" :modal="modal">
        <div class="modal__body">
          <div class="icon">
            <img src="@/assets/email-send.png" alt="Senha alterada com sucesso" />
          </div>
          <div class="text">
            <p slot="text">Sua senha de usuário foi alterada com sucesso! Você será redirecionado para a página de login.</p>
          </div>
        </div>
    </modal-default>
    </div>
  </div>
</template>

<script>
import {
  Paragraph, InputDefault, ButtonDefault, TextWarning, FeedbackWarning
} from '@/components/atoms'
import { ModalDefault } from '@/components/organisms/Modals'
import validatePassword from '@/utils/validatePassword.js'

export default {
  name: 'ResetPassword',
  components: {
    Paragraph,
    InputDefault,
    ButtonDefault,
    TextWarning,
    FeedbackWarning,
    ModalDefault
  },
  data() {
    return {
      form: {
        password: '',
        confirmPassword: ''
      },
      errors: [],
      textWarning: this.$t('warningAllFieldsAreObligatory'),
      modal: false
    }
  },
  methods: {
    resetErrors() {
      this.errors = []
    },
    checkErrorIfContains(type) {
      return this.errors.includes(type)
    },
    toggleModal() {
      this.modal = !this.modal
    },
    redirectToPage(url) {
      this.$router.push(url)
    },
    validateConfirmation() {
      if (this.form.password != this.form.confirmPassword) {
        this.errors.push('confirmPassword')
        this.errors.push('password')
        return false
      }
      return true
    },
    async checkForm() {
      for (const key in this.form) {
        if (!this.form[key]) this.errors.push(key)
      }
      if (this.errors.length) return false
      if (validatePassword(this.form.password)) {
        this.errors.push('password')
        this.textWarning = 'Senha fraca'
        return false
      }
      if (!this.validateConfirmation()) {
        this.textWarning = 'As senhas fornecidas não batem!'
        return false
      }
      await this.saveForm()
    },
    async saveForm() {
      const payload = {
        password: this.form.password,
        token: this.$route.query.token,
        user_id: this.$route.query.i
      }
      try {
        await this.$http.patch('/user/reset-password', payload)
        this.toggleModal()
      } catch (error) {
        console.error(error.message)
        if (error.response.data.message === 'Token not found' || !payload.token) {
          this.textWarning = 'Token de redefinição de senha não é válido!'
          this.errors.push('token')
        }
        if (error.response.data.message === 'Token expired') {
          this.textWarning = 'Token expirado!'
          this.errors.push('token')
        }
      }
    }
  }

}
</script>

<style lang="scss" scoped>
$imgUrl: "../../assets/background-login.png";

.container-app {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)),
  url($imgUrl);
  background-size: contain;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0px;

  .wrapper {
    background-color: #fff;
    opacity: 0.9;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    max-width: 649px;
    padding: 34.5px 89.55px;
    text-align: center;

    .paragraph {
      width: 404px;
      margin: 25px 0px 15px 0px;
      text-align: left;
      color: $primary
    }
    .padlockImage {
      width: 368px;
      height: 274px;
    }
    .flex {
      display: flex;
      justify-content: center;
    }
    .modal{
      &__body{
        text-align: left;
      }
    }
    .warning {
      color: $attention;
      border-bottom-color: $attention;
    }
  }
}
</style>
