<template>
  <section>
    <nav class="navigation">
      <div
        v-if="noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.HIGH}"
        @click="setList(tabsEnum.HIGH)"
      >{{ $t('highFinalists') }}</div>
      <div
        v-if="noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.MIDDLE}"
        @click="setList(tabsEnum.MIDDLE)"
      >{{ $t('middleFinalists') }}</div>
      <div
        v-if="noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.CHILDISH}"
        @click="setList(tabsEnum.CHILDISH)"
      >{{ $t('childishFinalists') }}</div>
      <div
        v-if="!noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.DRAFT}"
        @click="setList(tabsEnum.DRAFT)"
      >{{ $t('inProgress') }}</div>
      <div
        v-if="!noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.ALL}"
        @click="setList(tabsEnum.ALL)"
      >{{ $t('subscribed') }}</div>
      <div
        v-if="!noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.OPEN}"
        @click="setList(tabsEnum.OPEN)"
      >{{ $t('openRegistrations') }}</div>
      <div
        v-if="!noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.IN_REVIEW}"
        @click="setList(tabsEnum.IN_REVIEW)"
      >{{ $t('revisionRegistrations') }}</div>
      <div
        v-if="!noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.WAITING_ANSWER}"
        @click="setList(tabsEnum.WAITING_ANSWER)"
      >{{ $t('waitingReply') }}</div>
      <div
        class="nav__item"
        :class="{active: list == tabsEnum.WAITING_FOR_PAYMENT}"
        @click="setList(tabsEnum.WAITING_FOR_PAYMENT)"
      >{{ $t('waitingPayment') }}</div>
      <div
        v-if="!noStatus"
        class="nav__item"
        :class="{active: list == tabsEnum.EFFECTED}"
        @click="setList(tabsEnum.EFFECTED)"
      >{{ $t('effectivatedRegistrations') }}</div>
    </nav>
    <div class="info">
      <div class="filter">
        <button
          @click="modalFilterSidebar = !modalFilterSidebar"
          v-if="list !== tabsEnum.DRAFT"
        >
          <v-icon color="#1B778B" medium>mdi-filter-outline</v-icon>
          <span>{{ $t('filter') }}</span>
        </button>
      </div>
      <div class="labels">
        <div class="text">
          <span>{{ $t('subtitleDescription') }}</span>
        </div>
        <status-with-tooltip
          statusText="sketch"
          theme="grey_neutral"
          tooltipText="sketchDescription"
          v-on:click.native="setList(tabsEnum.DRAFT)"
        />
        <status-with-tooltip
          statusText="pendingAcceptStatus"
          theme="warn"
          tooltipText="pendingAcceptDescription"
          v-on:click.native="setList(tabsEnum.WAITING_ACCEPTANCE)"
        />
        <status-with-tooltip
          statusText="notAccept"
          theme="red_reject"
          tooltipText="notAcceptDescription"
          v-on:click.native="setList(tabsEnum.DISAPPROVED)"
        />
        <status-with-tooltip
          statusText="pendingRevision"
          theme="warn"
          tooltipText="pendingRevisionDescription"
          v-on:click.native="setList(tabsEnum.OPEN)"
        />
        <status-with-tooltip
          statusText="underReview"
          theme="blue_analisis"
          tooltipText="underReviewDescription"
          v-on:click.native="setList(tabsEnum.IN_REVIEW)"
        />
        <status-with-tooltip
          statusText="reviewAccepted"
          theme="green_accept"
          tooltipText="reviewAcceptedDescription"
          v-on:click.native="setList(tabsEnum.REVIEW_ACCEPTED)"
        />
        <status-with-tooltip
          statusText="reviewDenied"
          theme="red_reject"
          tooltipText="reviewDeniedDescription"
          v-on:click.native="setList(tabsEnum.REVIEW_DENIED)"
        />
       <status-with-tooltip
          statusText="waitingPayment"
          theme="purple_waiting"
          tooltipText="waitingPaymentDescription"
          v-on:click.native="setList(tabsEnum.WAITING_FOR_PAYMENT)"
        />
        <status-with-tooltip
          statusText="registrationComplete"
          theme="green_accept"
          tooltipText="registrationCompleteDescription"
          v-on:click.native="setList(tabsEnum.EFFECTED)"
        />
        <status-with-tooltip
          statusText="pendingAnswer"
          theme="purple_waiting"
          tooltipText="pendingAnswerDescription"
          v-on:click.native="setList(tabsEnum.WAITING_ANSWER)"
        />
        <status-with-tooltip
          statusText="sentAnswer"
          theme="blue_send"
          tooltipText="sentAnswerDescription"
          v-on:click.native="setList(tabsEnum.SENT_ANSWER)"
        />
        <status-with-tooltip
          statusText="disabled"
          theme="orange_inactive"
          tooltipText="disabledDescription"
          v-on:click.native="setList(tabsEnum.DISABLED)"
          isTooltipWidthSameAsBadge
        />
        <status-with-tooltip
          statusText="reviewCanceled"
          theme="red_reject"
          tooltipText="reviewCanceledDescription"
          isTooltipWidthSameAsBadge
          v-on:click.native="setList(tabsEnum.REVIEW_CANCELED)"
        />
      </div>
    </div>

    <modal-filter-sidebar-projects
      @closeModal="modalFilterSidebar = !modalFilterSidebar"
      :modal="modalFilterSidebar"
      @filterProjectRequestsList="$emit('filterProjectRequestsList')"
    ></modal-filter-sidebar-projects>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  InputSelect,
  StatusWithTooltip
} from '@/components/atoms'

import {
  ModalFilterSidebarProjects
} from '@/components/organisms/Modals'

export default {
  name: 'FilterProjects',
  components: { InputSelect, ModalFilterSidebarProjects, StatusWithTooltip },
  mounted() {
    this.setList(this.tabsEnum.ALL)
  },
  data() {
    return {
      list: this.tabsEnum?.ALL,
      filterEditionOptions: [],
      modalFilterSidebar: false,
      textWarning: '',
      errors: []
    }
  },
  computed: {
    ...mapGetters({
      lang: 'lang',
      projectRequestFilter: 'projectRequestFilter'
    }),
    projectRequestFilter: {
      get() {
        return this.projectRequestFilter
      },
      set(newName) {
        return newName
      }
    }
  },
  props: {
    anyCheckboxEnabled: {
      type: Boolean,
      required: true
    },
    tabsEnum: Object,
    noStatus: Boolean
  },
  methods: {
    ...mapActions({
      changeLang: 'changeLanguage',
      projectRequestFilter: 'projectRequestFilter'
    }),
    setList(type) {
      this.list = type
      this.$emit('setList', type)
    },
    filterRequests() {
      const filters = this.$store.getters.projectRequestFilter
      this.$store.commit('PROJECT_LIST_FILTER', filters)
      this.$emit('filterProjectRequestsList')
    }
  }
}
</script>

<style lang="scss" scoped>
.disabledButton {
  cursor: default;
  background-color: darkgray !important;
}

.navigation {
  align-items: center;
  background-color: $white;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-evenly;
  padding-top: 15px;
  margin: 0px -15px 20px -15px;

  .nav__item {
    color: $primaryDark;
    cursor: pointer;
    line-height: 120%;
    opacity: 0.8;
    padding: 0px 20px 15px 20px;

    &.disabled {
      cursor: unset;
      opacity: 0.5;
    }
  }

  .active {
    font-weight: 600;
    color: $primaryDark;
    border-bottom: 3px solid $primary;
  }
}

.top__container {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.indicators {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -50px;
}

.search {
  flex: 1;
  &__top {
    display: flex;
    justify-content: space-between;
    line-height: 120%;

    &--label {
      font-weight: bold;
      font-size: 1rem;
      line-height: 120%;
      color: $almostBlack;
      opacity: 0.8;
    }

    span {
      font-weight: 600;
      font-size: 1rem;
      color: $primaryDark;
    }
  }

  &__inputs {
    display: flex;
    margin-bottom: 50px;

    .search__item {
      .badges {
        margin-top: 15px;

        span {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          background: $primary;
          border-radius: 49px;
          opacity: 0.8;

          color: $white;
          font-weight: bold;
          font-size: 14px;
          min-width: 79px;
          height: 28px;
          padding: 0px 8px;

          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.send {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 229px;
  height: 44.43px;
  left: 1138px;
  bottom: 1015.57px;
  background: $primaryDark;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #fff;
}
.img-icon {
  width: 21px;
  height: 15px;
  margin-left: 7px;
}

.info {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.labels {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  * {
    margin-left: 15px;
    margin-bottom: 12px;
  }

  .text span {
    font-weight: 600;
    font-size: 14px;
    color: $almostBlack;
  }
}

.filter button {
  @include d-flex-center;
  background-color: $white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 92px;

  font-weight: 600;
  line-height: 20px;
  color: $primary;

  &:hover {
    background-color: #d6d6d6;
  }
}

.modal__body {
  @include d-flex-center;
  margin-top: 30px;
  justify-content: flex-start;
  @media screen and (max-width: 580px) {
    flex-direction: column;
  }

  .text {
    color: $primaryDark;
    font-size: 16px;
    font-weight: 600;
    line-height: 20, 08px;
    margin-left: 7%;
    text-align: left;
    max-width: 300px;
  }
}
</style>
