<template>
  <h1>
    <slot></slot>
  </h1>
</template>

<script>
export default {
  name: 'Title'
}
</script>

<style lang="scss" scoped>

</style>
