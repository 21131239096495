<template>
  <div class="view-only-label" v-if="readOnly">
    {{ $t(label) }}
    <div
      class="value-coloured"
      :[type]="true"
    >
      <span>{{ $t(value) }}</span>
    </div>
  </div>
  <input-select v-else
    class="input-coloured"
    :[type]="true"
    :id="id"
    :items="items"
    :label="label"
    :value="value"
    @input="emitInputEvent($event)"
  />
</template>

<script>
import InputSelect from '@/components/atoms/Inputs/InputSelect'
import VueTypes from 'vue-types'

export default {
  name: 'InputSelectColoured',
  components: { InputSelect },
  data() {
    return {
      internalValue: this.value
    }
  },
  props: {
    id: String,
    items: Array,
    type: VueTypes
      .oneOf(['green-success', 'red-warning', 'grey-neutral'])
      .def('grey-neutral')
      .isRequired,
    label: String,
    value: [String, Boolean],
    selected: [String, Number],
    readOnly: Boolean
  },
  methods: {
    emitInputEvent(event) {
      this.internalValue = event
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.view-only-label {
  color: #303030;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.value-coloured {
  display: flex;
  align-items: center;
  padding: 9px 12px;
  border-radius: 10px;
  margin-top: 10px;

  & > span {
    font-weight: 500;
  }
}

.value-coloured[grey-neutral] {
  border: 1px solid #AFAEAE;

  & > span {
    color: #AFAEAE;
  }
}

.value-coloured[green-success] {
  border: 1px solid $success;
  background-color: $successAlpha10;

  & > span {
    color: $success
  }
}

.value-coloured[red-warning] {
  border: 1px solid $warningRed;
  background-color: $warningRedAlpha10;

  & > span {
    color: $warningRed
  }
}

.input-coloured[grey-neutral] {
  ::v-deep .v-input__slot > div.v-select__slot > .v-select__selections > .v-select__selection {
    color: #AFAEAE !important;
  }
}

.input-coloured[green-success] {
  ::v-deep .v-input__slot fieldset {
    background-color: $successAlpha10;
    border: 1px solid $success;
  }

  ::v-deep .v-input__slot > div.v-select__slot > .v-select__selections > .v-select__selection {
    color: $success !important;
  }
}
.input-coloured[red-warning] {
  ::v-deep .v-input__slot fieldset {
    background-color: $warningRedAlpha10;
    border: 1px solid $warningRed;
  }

  ::v-deep .v-input__slot > div.v-select__slot > .v-select__selections > .v-select__selection {
    color: $warningRed !important;
  }
}
</style>
